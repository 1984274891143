import type { OrderSendEmailsRequest } from 'ducks/orders/types';

export type OrderSendEmailsValues = Pick<
  OrderSendEmailsRequest,
  | 'email'
  | 'name'
  | 'cc'
  | 'subject'
  | 'body'
  | 'isSms'
  | 'smsText'
  | 'isReferenceFile'
>;

export const initialOrderSendEmailValues: OrderSendEmailsValues = {
  email: '',
  name: '',
  cc: '',
  subject: '',
  body: '',
  isSms: false,
  smsText: '',
  isReferenceFile: false,
};
