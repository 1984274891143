import { Space, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { useUpdateOrderTranslationFilesMutation } from 'ducks/orders/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setGlobalIsLoading } from 'ducks/view/global';
import { Routes } from 'views/Orders/Routing';
import { useOrderTranslationFilesServices } from '../../useOrderTranslationFilesServices';
import {
  generateOrderTranslationFilesBody,
  validateUpdateOrderTranslationBody,
} from '../../utils';
import styles from './styles.module.scss';

const ActionsSection = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const [notify, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const orderFiles = useAppSelector(
    ({ orderTranslationFiles }) => orderTranslationFiles.orderFiles,
    shallowEqual,
  );
  const { isLoading: isServicesLoading, refetchTranslationFiles } =
    useOrderTranslationFilesServices();
  const [updateOrderTranslationFiles, { isLoading: isUpdateLoading }] =
    useUpdateOrderTranslationFilesMutation();
  const isLoading = isServicesLoading || isUpdateLoading;

  const handleSubmit = async () => {
    if (isLoading || !id) return;

    try {
      dispatch(setGlobalIsLoading(true));

      const body = generateOrderTranslationFilesBody(orderFiles);

      const invalidBodyItems = validateUpdateOrderTranslationBody(body);

      if (invalidBodyItems.length) {
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Przypisz wszystkich tłumaczy do par językowych." />
          ),
        });

        return;
      }

      await updateOrderTranslationFiles({
        id,
        body,
      }).unwrap();

      navigate(Routes.ORDER_DETAILS.replace(':id', id));

      refetchTranslationFiles();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas próby przypisania do zamówienia." />
        ),
      });
    } finally {
      dispatch(setGlobalIsLoading(false));
    }
  };

  return (
    <Space className={styles.space} data-testid="actionsSection">
      {contextHolder}
      <Button onClick={handleSubmit} type="primary" loading={isLoading}>
        <FormattedMessage defaultMessage="Przypisz do zamówienia" />
      </Button>
    </Space>
  );
};

export default ActionsSection;
