import type {
  AddNoteRequest,
  DeleteNoteRequest,
  GetNotesRequest,
  GetNotesResponse,
  UpdateNoteRequest,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import { NotesTag } from './types';

export enum Endpoint {
  GetNotes = '/:object/:objectId/notes',
  AddNote = '/:object/:objectId/notes',
  UpdateNote = '/:object/:objectId/notes/:noteId',
  DeleteNote = '/:object/:objectId/notes/:noteId',
}

export const notesApi = createApi({
  reducerPath: 'notesApi',
  baseQuery: baseQuery(),
  tagTypes: [NotesTag.Notes],
  endpoints: (build) => ({
    getNotes: build.query<GetNotesResponse, GetNotesRequest>({
      query: ({ objectId, object }) => ({
        url: Endpoint.GetNotes.replace(':objectId', `${objectId}`).replace(
          ':object',
          `${object}`,
        ),
        method: 'GET',
      }),
      providesTags: [NotesTag.Notes],
    }),
    addNote: build.mutation<void, AddNoteRequest>({
      query: ({ objectId, object, ...data }) => ({
        url: Endpoint.AddNote.replace(':objectId', `${objectId}`).replace(
          ':object',
          `${object}`,
        ),
        method: 'POST',
        data,
      }),
      invalidatesTags: (_, error) => (error ? [] : [NotesTag.Notes]),
    }),
    updateNote: build.mutation<void, UpdateNoteRequest>({
      query: ({ objectId, object, noteId, ...data }) => ({
        url: Endpoint.UpdateNote.replace(':objectId', `${objectId}`)
          .replace(':noteId', `${noteId}`)
          .replace(':object', `${object}`),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (_, error) => (error ? [] : [NotesTag.Notes]),
    }),
    deleteNote: build.mutation<void, DeleteNoteRequest>({
      query: ({ objectId, object, noteId, ...data }) => ({
        url: Endpoint.DeleteNote.replace(':objectId', `${objectId}`)
          .replace(':noteId', `${noteId}`)
          .replace(':object', `${object}`),
        method: 'DELETE',
        data,
      }),
      invalidatesTags: (_, error) => (error ? [] : [NotesTag.Notes]),
    }),
  }),
});

export const {
  useGetNotesQuery,
  useAddNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
} = notesApi;
