import type { InvoiceAddValues } from '../../config';
import type { FormInstance } from 'antd';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Space, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { useAddInvoiceMutation } from 'ducks/invoices/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setGlobalIsLoading } from 'ducks/view/global';
import { resetInvoicesAddState } from 'ducks/view/invoicesAdd';
import { Routes } from 'views/Invoices/Routing';
import { useInvoiceAddServices } from '../../useInvoiceAddServices';
import styles from './styles.module.scss';

type Props = {
  form: FormInstance<InvoiceAddValues>;
};

const ActionsSection: FC<Props> = ({ form }) => {
  const [notify, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const orderIds = useAppSelector(
    ({ invoicesAdd }) => invoicesAdd.orderIds,
    shallowEqual,
  );
  const { isInvoiceDataFetching } = useInvoiceAddServices();
  const [addInvoice, { isLoading: isAddInvoiceLoading }] =
    useAddInvoiceMutation();
  const isLoading = isInvoiceDataFetching || isAddInvoiceLoading;

  const handleSave = async (send = false) => {
    if (isLoading) return;

    try {
      dispatch(setGlobalIsLoading(true));
      const {
        createdAt: createdAtValue,
        dateSale: dateSaleValue,
        dateDue: dateDueValue,
        ...values
      } = await form.validateFields();

      if (!dateDueValue) return;

      const dateSale = dayjs(dateSaleValue).format() as unknown as Date;
      const createdAt = dayjs(createdAtValue).format() as unknown as Date;

      const dateDue = dayjs()
        .add(dateDueValue, 'day')
        .format() as unknown as Date;

      const response = await addInvoice({
        ...values,
        orderId: orderIds,
        createdAt,
        dateSale,
        dateDue,
      }).unwrap();

      dispatch(resetInvoicesAddState());

      if (send) {
        navigate(Routes.INVOICE_SEND.replace(':id', response.id.toString()));

        return;
      }

      navigate(Routes.INVOICES_VAT);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.errorFields) return;

      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd wystawiania faktury." />
        ),
      });
    } finally {
      dispatch(setGlobalIsLoading(false));
    }
  };

  return (
    <Space className={styles.space} data-testid="actionsSection">
      {contextHolder}
      <Button type="primary" onClick={() => handleSave()} loading={isLoading}>
        <FormattedMessage defaultMessage="Wystaw FV" />
      </Button>
      <Button
        type="primary"
        onClick={() => handleSave(true)}
        loading={isLoading}
      >
        <FormattedMessage defaultMessage="Wystaw FV i wyślij" />
      </Button>
    </Space>
  );
};

export default ActionsSection;
