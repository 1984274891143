import { useMemo } from 'react';
import PreorderHeader from 'views/Preorders/components/PreorderHeader';
import { usePreorderSendServices } from '../../usePreorderSendServices';
import { headerCols } from './config';

const PreorderSendHeader = () => {
  const { preorderData } = usePreorderSendServices();

  const cols = useMemo(
    () => (preorderData ? headerCols({ data: preorderData }) : []),
    [preorderData],
  );

  return <PreorderHeader cols={cols} />;
};

export default PreorderSendHeader;
