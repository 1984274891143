import type { FC } from 'react';
import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/ui/molecules/Modal';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setInvoicesOrderIds } from 'ducks/view/invoicesAdd';
import ClientBillingSection from '../ClientBillingSection';
import NotesSection from '../NotesSection';
import InvoiceOrdersTable from './InvoiceOrdersTable';

type Props = { isOpen: boolean; onClose: () => void };

const InvoiceOrderSelectionModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const { orderIds, selectedOrderIds } = useAppSelector(({ invoicesAdd }) => ({
    orderIds: invoicesAdd.orderIds,
    selectedOrderIds: invoicesAdd.selectedOrderIds,
  }));

  const handleClose = () => {
    dispatch(setInvoicesOrderIds(orderIds));
    onClose();
  };

  const handleSubmit = () => {
    dispatch(setInvoicesOrderIds(selectedOrderIds));
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleSubmit}
      onCancel={handleClose}
      title={
        <FormattedMessage defaultMessage="Wybór zamówień do których ma zostać wystawiona faktura VAT" />
      }
      okText={<FormattedMessage defaultMessage="Wybierz" />}
      disableFormStyles
    >
      <Row gutter={[24, 24]}>
        <ClientBillingSection />
        <NotesSection />
        <Col xs={24}>
          <InvoiceOrdersTable />
        </Col>
      </Row>
    </Modal>
  );
};

export default InvoiceOrderSelectionModal;
