import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import Padlock from 'components/ui/molecules/Padlock';
import { useUnblockPreorder } from 'views/Preorders/useUnblockPreorder';

type Props = {
  activeEditorId: number | null;
  notify: NotificationInstance;
  showUnblockButton?: boolean;
};

const PreorderPadlock: FC<Props> = ({
  activeEditorId,
  notify,
  showUnblockButton,
}) => {
  const { handleUnblock, isLoading } = useUnblockPreorder({
    notify,
    showSuccessNotify: true,
  });

  return (
    <Padlock
      activeEditorId={activeEditorId}
      handleUnblock={handleUnblock}
      isLoading={isLoading}
      showPopover={false}
      showUnblockButton={showUnblockButton}
    />
  );
};

export default PreorderPadlock;
