import type { OrderFilesForList } from 'ducks/orders/types';
import CatLabel from 'components/ui/atoms/CatLabel';
import { NO_DATA } from 'constants/strings';
import { useGetTranslatorsCatsQuery } from 'ducks/translators/service';
import styles from './styles.module.scss';

type Props = Pick<OrderFilesForList, 'catId'>;

const CatCell: React.FC<Props> = ({ catId }) => {
  const { data = [] } = useGetTranslatorsCatsQuery();

  const usedCat = data?.find(({ id }) => id === catId);
  return usedCat ? (
    <div className={styles.wrapper}>
      <CatLabel id={catId} name={usedCat?.name} />
    </div>
  ) : (
    <span>{NO_DATA}</span>
  );
};

export default CatCell;
