import type { AccountingNotes } from 'ducks/invoices/types';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import PopoverDotCell from 'components/ui/atoms/TableCells/PopoverDotCell';
import { DayjsFormat } from 'constants/dayjsFormats';
import { NO_DATA } from 'constants/strings';
import { AccountingNotesStatus } from 'ducks/invoices/types';

type Props = { accountingNotes: AccountingNotes | null };

const AccountingNotesCell: FC<Props> = ({ accountingNotes }) => {
  return (
    <PopoverDotCell
      data-testid="accountingNotesCell"
      content={
        !!accountingNotes && (
          <Space direction="vertical">
            <FormattedMessage
              defaultMessage="Wysyłka: {date}"
              values={{
                date: accountingNotes.dateSent
                  ? dayjs(accountingNotes.dateSent).format(DayjsFormat.DATE)
                  : NO_DATA,
              }}
            />
            <FormattedMessage
              defaultMessage="Numer: {number}"
              values={{ number: accountingNotes.number ?? NO_DATA }}
            />
            <FormattedMessage
              defaultMessage="Typ wysyłki: {shipmentType}"
              values={{
                shipmentType: accountingNotes.shipmentType ?? NO_DATA,
              }}
            />
            {accountingNotes.status === AccountingNotesStatus.PAID ? (
              <FormattedMessage defaultMessage="Zapłacono: TAK" />
            ) : (
              <FormattedMessage defaultMessage="Zapłacono: NIE" />
            )}
          </Space>
        )
      }
    />
  );
};

export default AccountingNotesCell;
