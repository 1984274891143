import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';
import type { InvoiceVat } from 'ducks/invoices/types';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { DayjsFormat } from 'constants/dayjsFormats';
import { NO_DATA } from 'constants/strings';

export const headerCols = ({
  number,
  createdAt,
  clientData,
}: InvoiceVat): HeaderCardCol[] => {
  const createdAtValue = dayjs(createdAt).format(DayjsFormat.DATE);
  const contactPerson = clientData?.name ?? NO_DATA;
  const name = clientData?.name ?? NO_DATA;
  const phone = clientData?.phone ?? NO_DATA;

  return [
    {
      key: 'number',
      firstRow: <FormattedMessage defaultMessage="Numer faktury VAT" />,
      secondRow: <strong>{number}</strong>,
    },
    {
      key: 'createdAt',
      firstRow: <FormattedMessage defaultMessage="Data wystawienia faktury" />,
      secondRow: <strong>{createdAtValue}</strong>,
    },
    {
      key: 'contactPerson',
      firstRow: <FormattedMessage defaultMessage="Osoba kontaktowa" />,
      secondRow: <strong>{contactPerson}</strong>,
    },
    {
      key: 'name',
      firstRow: <FormattedMessage defaultMessage="Firma" />,
      secondRow: <strong>{name}</strong>,
    },
    {
      key: 'phone',
      firstRow: <FormattedMessage defaultMessage="Telefon" />,
      secondRow: <strong>{phone}</strong>,
    },
  ];
};
