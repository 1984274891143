import type { VariantServiceRow } from '../../config';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import localeTimePicker from 'antd/es/date-picker/locale/pl_PL';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import { DayjsFormat } from 'constants/dayjsFormats';
import { ServiceAttributeNames } from 'constants/services';
import { NO_DATA } from 'constants/strings';
import { useAppSelector } from 'ducks/store';
import { getPreorderServiceInitialValue } from 'ducks/view/preorders/utils';

const ATTRIBUTE_NAME = ServiceAttributeNames.ADDITIONAL_TIME;

type Props = { row: VariantServiceRow };

const AdditionalTimePicker: FC<Props> = ({
  row: { id, isActive, preorderVariantServiceValues },
}) => {
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
  const initialValue = getPreorderServiceInitialValue(
    preorderVariantServiceValues,
    ATTRIBUTE_NAME,
  );
  const dayjsValue = initialValue
    ? dayjs(initialValue, DayjsFormat.HOUR_MINUTE)
    : undefined;

  if (!isEditable)
    return <span data-testid="additionalTime">{initialValue ?? NO_DATA}</span>;

  return (
    <FormItem
      name={[id as number, ['attributeName', ATTRIBUTE_NAME].join(',')]}
      component={
        <TimePicker
          data-testid="additionalTimePicker"
          format={DayjsFormat.HOUR_MINUTE}
          value={dayjsValue}
          locale={localeTimePicker}
        />
      }
      rules={[
        {
          required: isActive,
          message: <FormattedMessage defaultMessage="Podaj czas" />,
        },
      ]}
      additionalItemProps={{
        valuePropName: 'date',
        initialValue: initialValue ? dayjsValue : '',
      }}
    />
  );
};

export default AdditionalTimePicker;
