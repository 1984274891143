import type { NotificationInstance } from 'antd/es/notification/interface';
import type { Client } from 'ducks/clients/types';
import type { FC } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import List from 'components/ui/molecules/List';
import CardCommon from '../CardCommon';
import EditContactModal from './components/EditContactModal';
import { contactListItems } from './config';

interface Props {
  data: Client | undefined;
  isLoading: boolean;
  notify: NotificationInstance;
}

const ContactForm: FC<Props> = ({ data, isLoading, notify }) => {
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);

  return (
    <>
      {data && (
        <EditContactModal
          isOpen={isEditContactModalOpen}
          onClose={() => setIsEditContactModalOpen(false)}
          data={data}
          notify={notify}
        />
      )}
      <CardCommon
        title={<FormattedMessage defaultMessage="Dane główne" />}
        isLoading={isLoading}
        openEditModal={() => setIsEditContactModalOpen(true)}
      >
        <List items={contactListItems(data)} isLoading={isLoading} />
      </CardCommon>
    </>
  );
};

export default ContactForm;
