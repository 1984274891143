import type { GetOrdersFilter } from 'ducks/orders/types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Table from 'components/ui/molecules/Table';
import { useGetOrdersQuery } from 'ducks/orders/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { selectInvoicesOrderIds } from 'ducks/view/invoicesAdd';
import useTablePagination from 'hooks/useTablePagination';
import { useInvoiceAddServices } from 'views/Invoices/view/InvoiceAdd';
import { orderColumns, type OrderRow } from './config';

const InvoiceOrdersTable = () => {
  const dispatch = useAppDispatch();
  const selectedOrderIds = useAppSelector(
    ({ invoicesAdd }) => invoicesAdd.selectedOrderIds,
  );
  const { invoiceData } = useInvoiceAddServices();
  const { page, handlePageChange } = useTablePagination<
    OrderRow,
    GetOrdersFilter
  >();
  const senderId = invoiceData?.client.id
    ? invoiceData?.client.id.toString()
    : undefined;
  const { data, isFetching } = useGetOrdersQuery(
    {
      page,
      filter: {
        senderId,
      },
    },
    { skip: !invoiceData },
  );
  const pageSize = data?.perPage;
  const total = isFetching ? 0 : data?.total;

  const handleSelectOrder = (orderId: number) =>
    dispatch(selectInvoicesOrderIds(orderId));

  const modifiedData = useMemo(
    (): OrderRow[] =>
      (data?.data ?? []).map((order) => ({ ...order, key: order.id })),
    [data],
  );

  return (
    <Table
      pagination={{
        current: page,
        pageSize,
        total,
        onChange: handlePageChange,
      }}
      columns={orderColumns({
        selectedOrderIds,
        handleSelectOrder,
      })}
      data={modifiedData}
      isLoading={isFetching}
      cardTitle={<FormattedMessage defaultMessage="Zamówienia" />}
    />
  );
};

export default InvoiceOrdersTable;
