import type { UpdateOrderTranslationFilesRequest } from 'ducks/orders/types';
import type { OrderFile } from 'ducks/view/orderTranslationFiles';

type UpdateOrderTranslationBody = (Omit<
  UpdateOrderTranslationFilesRequest['body'][number],
  'translatorId'
> & { translatorId: number | null })[];

export const validateUpdateOrderTranslationBody = (
  body: UpdateOrderTranslationBody,
) => {
  const invalidBodyItems: UpdateOrderTranslationBody = [];

  body.forEach((item) => {
    const { translatorId } = item;

    if (!translatorId) {
      invalidBodyItems.push(item);
    }
  });

  return invalidBodyItems;
};

export const generateOrderTranslationFilesBody = (
  orderFiles: OrderFile[],
): UpdateOrderTranslationFilesRequest['body'] =>
  orderFiles.flatMap(({ orderFileLangs }) =>
    orderFileLangs.flatMap(({ id: orderFileLangId, orderTranslationFiles }) =>
      orderTranslationFiles.map(
        ({ id: orderTranslationFileId, fileId, translatorId }) => ({
          fileId,
          orderTranslationFileId:
            typeof orderTranslationFileId === 'string'
              ? null
              : orderTranslationFileId,
          translatorId: translatorId as number,
          orderFileLangId,
        }),
      ),
    ),
  );
