import type { UploadFile } from 'antd';
import { Space } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import Switch, { SwitchSkeleton } from 'components/ui/atoms/Switch';
import TruncateText from 'components/ui/atoms/TruncateText';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import Upload, { UploadItem } from 'components/ui/molecules/Upload';
import { FileType } from 'ducks/global/types';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  toggleOrderSendClientEmailUploadedFiles,
  updateOrderSendClientEmailsAttachments,
} from 'ducks/view/orders';
import { createArray } from 'utilities/array';
import { useOrderClientSendServices } from '../../../useOrderClientSendServices';

const AttachmentSwitches = () => {
  const dispatch = useAppDispatch();
  const { attachments, uploadedFiles } = useAppSelector(({ orders }) => ({
    attachments: orders.sendClientEmail.attachments,
    uploadedFiles: orders.sendClientEmail.uploadedFiles,
  }));
  const { orderTranslationFilesData, isLoading } = useOrderClientSendServices();

  const handleToggleUploadedFiles = (file: UploadFile) =>
    dispatch(toggleOrderSendClientEmailUploadedFiles(file));

  const handleToggleAttachments = useCallback(
    (id: number) => dispatch(updateOrderSendClientEmailsAttachments(id)),
    [dispatch],
  );

  const translationsAndOrdersFiles = useMemo(() => {
    if (!orderTranslationFilesData) return [];

    return orderTranslationFilesData.translationsAndOrdersFiles.filter(
      ({ object }) => object === FileType.TRANSLATIONS_FILES,
    );
  }, [orderTranslationFilesData]);

  useEffect(() => {
    if (!translationsAndOrdersFiles.length) return;

    translationsAndOrdersFiles.forEach(({ id }) => handleToggleAttachments(id));
  }, [translationsAndOrdersFiles, handleToggleAttachments]);

  return (
    <div>
      <TitleWrapper>
        <TruncateText strong maxLength={50}>
          <FormattedMessage defaultMessage="Załącznik" />
        </TruncateText>
        <Upload
          onChange={({ file }) => handleToggleUploadedFiles(file)}
          fullWidth={false}
          disabled={isLoading}
        >
          <Button loading={isLoading}>
            <FormattedMessage defaultMessage="Dodaj" />
          </Button>
        </Upload>
      </TitleWrapper>
      <Space className="width-100" direction="vertical">
        {isLoading
          ? createArray(3).map((num) => <SwitchSkeleton key={num} />)
          : translationsAndOrdersFiles.map(({ id, filename }) => (
              <Switch
                key={id}
                data-testid={`switch-${id}`}
                label={filename}
                checked={attachments.some((atch) => atch === id)}
                onChange={() => handleToggleAttachments(id)}
              />
            ))}
        {uploadedFiles.map((file) => (
          <UploadItem
            key={file.uid}
            uploadFile={file}
            onDelete={handleToggleUploadedFiles}
          />
        ))}
      </Space>
    </div>
  );
};

export default AttachmentSwitches;
