import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { PreorderService } from 'ducks/preorders/types';
import type { PreorderVariantServiceSlice } from 'ducks/view/preorders';
import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import TruncateText from 'components/ui/atoms/TruncateText';
import { ServiceType } from 'constants/services';
import ActiveCol from './components/ActiveCol';
import AdditionalTimePicker from './components/AdditionalTimePicker';
import DeleteServiceButton from './components/DeleteServiceButton';
import DifficultPagesNumberInput from './components/DifficultPagesNumberInput';
import GraphicTimeRealizationCol from './components/GraphicTimeRealizationCol';
import PackagesNumberInput from './components/PackagesNumberInput';
import PriceInput from './components/PriceInput';
import SimplePagesNumberInput from './components/SimplePagesNumberInput';
import TypeCol from './components/TypeCol';
import TypeSelect from './components/TypeSelect';

export type VariantServiceValues = PreorderVariantServiceSlice;

export type VariantServiceRow = TableRow &
  PreorderVariantServiceSlice & {
    variantId: string | number;
    service: PreorderService | undefined;
  };

type GetSpecificServiceTypeRenderInput = {
  type: ServiceType;
  graphicRender?: ReactElement;
  additionalRender?: ReactElement;
  shipmentRender?: ReactElement;
  preparatoryRender?: ReactElement;
};

type Props = {
  isEditable: boolean;
};

const getSpecificServiceTypeRender = ({
  type,
  graphicRender,
  additionalRender,
  shipmentRender,
  preparatoryRender,
}: GetSpecificServiceTypeRenderInput) => {
  switch (type) {
    case ServiceType.GRAPHIC_SERVICE:
      return graphicRender;
    case ServiceType.PREPARATORY:
      return preparatoryRender;
    case ServiceType.ADDITIONAL:
      return additionalRender;
    case ServiceType.SHIPMENT:
      return shipmentRender;
    default:
      return <span />;
  }
};

export const columns = ({
  isEditable,
}: Props): ColumnsType<VariantServiceRow> => [
  {
    key: 'type',
    dataIndex: 'type',
    width: 180,
    align: 'right',
    render: (_, { type }) => <TypeCol type={type} />,
  },
  {
    key: 'isActive',
    dataIndex: 'isActive',
    width: 20,
    render: (_, { id, isActive }) => <ActiveCol id={id} isActive={isActive} />,
  },
  {
    key: '3',
    dataIndex: '3',
    align: 'right',
    width: 170,
    render: (_, row) => {
      const { isActive, type } = row;

      if (!isActive) return undefined;

      return getSpecificServiceTypeRender({
        type,
        graphicRender: (
          <TruncateText nowrap>
            <FormattedMessage defaultMessage="Liczba prostych stron" />
          </TruncateText>
        ),
        additionalRender: (
          <TruncateText nowrap>
            <FormattedMessage defaultMessage="Czas" />
          </TruncateText>
        ),
        shipmentRender: (
          <TruncateText nowrap>
            {isEditable ? (
              <FormattedMessage defaultMessage="Wybierz kuriera" />
            ) : (
              <FormattedMessage defaultMessage="Kurier" />
            )}
          </TruncateText>
        ),
      });
    },
  },
  {
    key: '4',
    dataIndex: '4',
    width: 132,
    render: (_, row) => {
      const { isActive, type } = row;

      if (!isActive) return undefined;

      return getSpecificServiceTypeRender({
        type,
        graphicRender: <SimplePagesNumberInput row={row} />,
        additionalRender: <AdditionalTimePicker row={row} />,
        shipmentRender: <TypeSelect row={row} />,
      });
    },
  },
  {
    key: '5',
    dataIndex: '5',
    width: 170,
    align: 'right',
    render: (_, { isActive, type }) =>
      isActive &&
      getSpecificServiceTypeRender({
        type,
        graphicRender: (
          <TruncateText nowrap>
            <FormattedMessage defaultMessage="Liczba trudnych stron" />
          </TruncateText>
        ),
        shipmentRender: (
          <TruncateText nowrap>
            <FormattedMessage defaultMessage="Liczba paczek" />
          </TruncateText>
        ),
      }),
  },
  {
    key: '6',
    dataIndex: '6',
    width: 125,
    render: (_, row) => {
      const { isActive, type } = row;

      if (!isActive) return undefined;

      return getSpecificServiceTypeRender({
        type,
        graphicRender: <DifficultPagesNumberInput row={row} />,
        shipmentRender: <PackagesNumberInput row={row} />,
      });
    },
  },
  {
    key: '7',
    dataIndex: '7',
    width: 70,
    render: (_, { isActive, type }) =>
      isActive &&
      getSpecificServiceTypeRender({
        type,
        graphicRender: (
          <TruncateText nowrap>
            <FormattedMessage defaultMessage="Termin" />
          </TruncateText>
        ),
      }),
  },
  {
    key: '8',
    dataIndex: '8',
    width: 125,
    render: (_, row) => {
      const { isActive, type } = row;

      if (!isActive) return undefined;

      return getSpecificServiceTypeRender({
        type,
        graphicRender: <GraphicTimeRealizationCol row={row} />,
      });
    },
  },
  {
    key: '9',
    dataIndex: '9',
    width: 55,
    render: (_, { isActive, type }) =>
      isActive &&
      getSpecificServiceTypeRender({
        type,
        graphicRender: (
          <TruncateText nowrap>
            <FormattedMessage defaultMessage="Cena" />
          </TruncateText>
        ),
        additionalRender: (
          <TruncateText nowrap>
            <FormattedMessage defaultMessage="Cena" />
          </TruncateText>
        ),
        shipmentRender: (
          <TruncateText nowrap>
            <FormattedMessage defaultMessage="Cena" />
          </TruncateText>
        ),
        preparatoryRender: (
          <TruncateText nowrap>
            <FormattedMessage defaultMessage="Cena" />
          </TruncateText>
        ),
      }),
  },
  {
    key: '10',
    dataIndex: '10',
    width: 125,
    render: (_, { id, isActive, type, priceNetto }) =>
      isActive &&
      getSpecificServiceTypeRender({
        type,
        graphicRender: (
          <PriceInput id={id} isActive={isActive} priceNetto={priceNetto} />
        ),
        additionalRender: (
          <PriceInput id={id} isActive={isActive} priceNetto={priceNetto} />
        ),
        shipmentRender: (
          <PriceInput id={id} isActive={isActive} priceNetto={priceNetto} />
        ),
        preparatoryRender: (
          <PriceInput id={id} isActive={isActive} priceNetto={priceNetto} />
        ),
      }),
  },
  {
    key: '11',
    dataIndex: '11',
    width: isEditable ? 115 : 1,
    render: (_, { id, variantId, isActive, type }) =>
      isActive &&
      isEditable &&
      getSpecificServiceTypeRender({
        type,
        shipmentRender: <DeleteServiceButton id={id} variantId={variantId} />,
      }),
  },
];
