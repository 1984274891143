import type { ServiceAttributeNames } from 'constants/services';
import type { PreorderService } from 'ducks/preorders/types';

export const getServiceAttributeOptions = (
  service: PreorderService,
  attributeName: ServiceAttributeNames,
) => {
  const attribute = service.attributes.find(
    ({ name }) => name === attributeName,
  );

  if (!attribute) return undefined;

  return attribute.options;
};
