import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type {
  GetInvoicesOverdueFilter,
  InvoiceOverdue,
} from 'ducks/invoices/types';
import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import NotesCell from 'components/ui/atoms/TableCells/NotesCell';
import { DayjsFormat } from 'constants/dayjsFormats';
import { getInvoiceOverdueStatus } from 'constants/invoice';
import { NO_DATA } from 'constants/strings';
import AccountingNotesCell from './components/AccountingNotesCell';
import DebtCollectionsCell from './components/DebtCollectionsCell';
import LetterSummonsCell from './components/LetterSummonsCell';

export type InvoiceOverdueRow = TableRow & InvoiceOverdue;

type Props = {
  intl: IntlShape;
};

export const initialInvoicesOverdueFilters: GetInvoicesOverdueFilter = {
  hasDebtCollection: undefined,
  hasAccountingNote: undefined,
  status: undefined,
  hasLetterSummon: undefined,
  isCourt: undefined,
};

export const columns = ({ intl }: Props): ColumnsType<InvoiceOverdueRow> => [
  {
    key: 'id',
    dataIndex: 'id',
    title: <FormattedMessage defaultMessage="Nr" />,
    width: 50,
  },
  {
    key: 'debtCollections',
    dataIndex: 'debtCollections',
    title: <FormattedMessage defaultMessage="W" />,
    align: 'center',
    width: 30,
    render: (_, { debtCollections }) => (
      <DebtCollectionsCell debtCollections={debtCollections} />
    ),
  },
  {
    key: 'accountingNotes',
    dataIndex: 'accountingNotes',
    title: <FormattedMessage defaultMessage="N" />,
    align: 'center',
    width: 30,
    render: (_, { accountingNotes }) => (
      <AccountingNotesCell accountingNotes={accountingNotes} />
    ),
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: <FormattedMessage defaultMessage="Firma" />,
    render: (_, { clientData }) => (
      <span data-testid="name">{clientData?.name ?? NO_DATA}</span>
    ),
  },
  {
    key: 'nip',
    dataIndex: 'nip',
    title: <FormattedMessage defaultMessage="NIP" />,
    render: (_, { clientData }) => (
      <span data-testid="nip">{clientData?.nip ?? NO_DATA}</span>
    ),
  },
  {
    key: 'orderNumber',
    dataIndex: 'orderNumber',
    title: <FormattedMessage defaultMessage="Nr zlecenia" />,
    render: (_, { orders }) => (
      <span data-testid="orders">
        {orders.length
          ? orders.map(({ number }) => number).join(', ')
          : NO_DATA}
      </span>
    ),
  },
  {
    key: 'number',
    dataIndex: 'number',
    title: <FormattedMessage defaultMessage="Nr faktury" />,
  },
  {
    key: 'dateSent',
    dataIndex: 'dateSent',
    title: <FormattedMessage defaultMessage="Wystawiono" />,
    render: (_, { dateSent }) => (
      <span data-testid="dateSent">
        {dayjs(dateSent).format(DayjsFormat.DATE)}
      </span>
    ),
  },
  {
    key: 'dateDue',
    dataIndex: 'dateDue',
    title: <FormattedMessage defaultMessage="Termin p." />,
    render: (_, { dateDue }) => (
      <span data-testid="dateDue">
        {dayjs(dateDue).format(DayjsFormat.DATE)}
      </span>
    ),
  },
  {
    key: 'priceNetto',
    dataIndex: 'priceNetto',
    title: <FormattedMessage defaultMessage="Netto" />,
    render: (_, { priceNetto }) => (
      <span data-testid="priceNetto">
        {priceNetto ? <CurrencyFormatter value={priceNetto} /> : NO_DATA}
      </span>
    ),
  },
  {
    key: 'brutto',
    dataIndex: 'brutto',
    title: <FormattedMessage defaultMessage="Brutto" />,
    render: (_, { priceBrutto }) => (
      <span data-testid="priceBrutto">
        {priceBrutto ? <CurrencyFormatter value={priceBrutto} /> : NO_DATA}
      </span>
    ),
  },
  {
    key: 'paid',
    dataIndex: 'paid',
    title: <FormattedMessage defaultMessage="Zapłacono" />,
    render: (_, { paid }) => (
      <span data-testid="paid">
        <CurrencyFormatter value={paid} />
      </span>
    ),
  },
  {
    key: 'toPaid',
    dataIndex: 'toPaid',
    title: <FormattedMessage defaultMessage="Pozostało" />,
    render: (_, { priceBrutto, paid }) => {
      const toPaid = (priceBrutto ?? 0) - paid;

      return (
        <span data-testid="toPaid">
          <CurrencyFormatter value={toPaid} />
        </span>
      );
    },
  },
  {
    key: 'dateLastContact',
    dataIndex: 'dateLastContact',
    title: <FormattedMessage defaultMessage="Ostatnie przypomnienie" />,
    width: 60,
    render: (_, { dateLastContact }) => (
      <span data-testid="dateLastContact">
        {dateLastContact
          ? dayjs(dateLastContact).format(DayjsFormat.DATE)
          : NO_DATA}
      </span>
    ),
  },
  {
    key: 'notes',
    dataIndex: 'notes',
    title: <FormattedMessage defaultMessage="Notatki" />,
    width: 30,
    render: (_, { id, notes }) => <NotesCell orderId={id} notes={notes} />,
  },
  {
    key: 'status',
    dataIndex: 'status',
    width: 120,
    title: <FormattedMessage defaultMessage="Status" />,
    render: (_, { status }) => (
      <span data-testid="status">
        {getInvoiceOverdueStatus(intl, status)?.label ?? NO_DATA}
      </span>
    ),
  },
  {
    key: 'letterSummons',
    dataIndex: 'letterSummons',
    title: <FormattedMessage defaultMessage="Wezwanie listowne" />,
    align: 'center',
    width: 50,
    render: (_, { letterSummons }) => (
      <LetterSummonsCell letterSummons={letterSummons} />
    ),
  },
  {
    key: 'isCourt',
    dataIndex: 'isCourt',
    title: <FormattedMessage defaultMessage="Sprawa sądowa" />,
    align: 'center',
    width: 40,
    render: (_, { isCourt }) => (
      <span data-testid="isCourt">
        {isCourt ? (
          <FormattedMessage defaultMessage="Tak" />
        ) : (
          <FormattedMessage defaultMessage="Nie" />
        )}
      </span>
    ),
  },
];
