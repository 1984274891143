import type { UploadedFile } from 'ducks/global/types';
import type { FC } from 'react';
import { notification } from 'antd';
import { GoCloudDownload } from 'react-icons/go';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import TruncateText from 'components/ui/atoms/TruncateText';
import { useLazyGetFileQuery } from 'ducks/global/service';
import { downloadFile } from 'utilities/downloadFile';
import styles from './styles.module.scss';

type Props = {
  file: UploadedFile;
};

const DownloadFileButton: FC<Props> = ({ file: { id, object, filename } }) => {
  const [notify, contextHolder] = notification.useNotification();
  const [getFile, { isLoading }] = useLazyGetFileQuery();

  const handleDownloadFile = async () => {
    if (isLoading) return;

    try {
      const response = await getFile({ id, object }).unwrap();

      if (response) {
        const url = URL.createObjectURL(response);

        downloadFile(url, filename);
      }
    } catch (err) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Nie znaleziono pliku lub wystąpił błąd." />
        ),
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Button
        className={styles.button}
        type="link"
        icon={<GoCloudDownload />}
        onClick={handleDownloadFile}
        loading={isLoading}
      >
        <TruncateText data-testid={`file-${id}`}>{filename}</TruncateText>
      </Button>
    </>
  );
};

export default DownloadFileButton;
