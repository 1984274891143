import type { FC } from 'react';
import { Select, Space, Tag } from 'antd';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { NO_DATA } from 'constants/strings';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { useGetTranslatorsSpecializationsQuery } from 'ducks/translators/service';
import { changeOrderFileSpecializations } from 'ducks/view/orders';
import SkeletonColumnPlaceholder from '../SkeletonColumnPlaceholder';
import styles from './styles.module.scss';

interface Props {
  orderFileId: number;
  specializations: number[];
  multiple?: boolean;
}

const SpecializationColumn: FC<Props> = memo(
  ({ orderFileId, specializations, multiple = false }) => {
    const isEditable = useAppSelector((state) => state.orders.isEditable);
    const dispatch = useAppDispatch();
    const { data = [], isLoading } = useGetTranslatorsSpecializationsQuery();
    const mode = multiple ? 'multiple' : undefined;

    const options = useMemo(
      () => data.map(({ id, name }) => ({ value: id, label: name })),
      [data],
    );

    const selectedSpecializations = useMemo(() => {
      if (isEditable) return [];

      return data.filter(({ id }) =>
        specializations.some((spec) => id === spec),
      );
    }, [data, specializations, isEditable]);

    const handleOnChange = (value: number[] | number) => {
      const modifiedValue = Array.isArray(value) ? value : [value];

      dispatch(
        changeOrderFileSpecializations({
          orderFileId,
          specializations: value ? modifiedValue : [],
        }),
      );
    };

    if (isLoading) {
      return <SkeletonColumnPlaceholder size="large" />;
    }

    if (!isEditable) {
      if (!selectedSpecializations.length) return <span>{NO_DATA}</span>;

      return (
        <Space wrap>
          {selectedSpecializations.map(({ id, name }) => (
            <Tag key={id}>{name}</Tag>
          ))}
        </Space>
      );
    }

    return (
      <Select
        className={styles.specializationsSelect}
        value={specializations}
        onChange={handleOnChange}
        mode={mode}
        placeholder={
          <FormattedMessage defaultMessage="Wybierz specjalizację" />
        }
        options={options}
        optionFilterProp="label"
        allowClear
      />
    );
  },
);

export default SpecializationColumn;
