import theme from 'components/layout/Main/styles/values.scss';
import { ClientOrderStatus } from 'ducks/clients/types';
import styles from './styles.module.scss';

type Props = {
  status: ClientOrderStatus;
};

const getStatusCircleColor = (status: ClientOrderStatus) => {
  switch (status) {
    case ClientOrderStatus.IN_PROGRESS:
      return theme.colorLime;
    case ClientOrderStatus.COMPLETED:
      return theme.colorCandleLight;
    case ClientOrderStatus.COMPLAINT:
      return theme.colorTorchRed;

    default:
      return '#ffffff';
  }
};

const StatusCircle: React.FC<Props> = ({ status }) => {
  return (
    <div
      className={styles.statusCircle}
      style={{ backgroundColor: getStatusCircleColor(status) }}
    />
  );
};

export default StatusCircle;
