import type { FormInstance } from 'antd';
import type { FC } from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Loader from 'components/ui/atoms/Loader';
import CardCommon from 'components/ui/molecules/CardCommon';
import TemplateEditor from '../TemplateEditor';

type Props = {
  form: FormInstance;
  emailTemplate: string;
  isLoading?: boolean;
  isRequired?: boolean;
};

const FIELD_KEY = 'body';

const EmailTemplateTextEditor: FC<Props> = ({
  form,
  emailTemplate,
  isLoading = false,
  isRequired = true,
}) => {
  useEffect(() => {
    form.setFieldValue(FIELD_KEY, emailTemplate);
  }, [form, emailTemplate]);

  return (
    <CardCommon>
      <Loader visible={isLoading}>
        <FormItem
          name={FIELD_KEY}
          component={<TemplateEditor />}
          rules={[
            {
              required: isRequired,
              message: (
                <FormattedMessage defaultMessage="Uzupełnij treść wiadomości" />
              ),
            },
          ]}
          withoutMargin
        />
      </Loader>
    </CardCommon>
  );
};

export default EmailTemplateTextEditor;
