import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrderTranslationFilesQuery } from 'ducks/orders/service';
import { useAppDispatch } from 'ducks/store';
import { setOrderTranslationFiles } from 'ducks/view/orderTranslationFiles';
import { useOrdersServices } from 'views/Orders/useOrdersServices';

export const useOrderTranslationFilesServices = () => {
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();
  const {
    orderData,
    isOrderFetching,
    isOrderEmpty,
    isCurrentlyEdited,
    refetch,
  } = useOrdersServices();
  const {
    data: orderTranslationFilesData,
    isFetching: isOrderTranslationFilesFetching,
    refetch: refetchTranslationFiles,
  } = useGetOrderTranslationFilesQuery(
    {
      id,
    },
    { skip: !orderData },
  );
  const isOrderTranslationFilesEmpty =
    !orderTranslationFilesData &&
    !isOrderTranslationFilesFetching &&
    !isOrderFetching;
  const isLoading = isOrderFetching || isOrderTranslationFilesFetching;

  const value = useMemo(
    () => ({
      isCurrentlyEdited,
      orderData,
      isOrderFetching,
      orderTranslationFilesData,
      refetch,
      isOrderTranslationFilesFetching,
      refetchTranslationFiles,
      isOrderEmpty,
      isOrderTranslationFilesEmpty,
      isLoading,
    }),
    [
      isCurrentlyEdited,
      orderData,
      isOrderFetching,
      orderTranslationFilesData,
      refetch,
      isOrderTranslationFilesFetching,
      refetchTranslationFiles,
      isOrderEmpty,
      isOrderTranslationFilesEmpty,
      isLoading,
    ],
  );

  useEffect(() => {
    if (!orderTranslationFilesData) return;

    dispatch(setOrderTranslationFiles(orderTranslationFilesData));
  }, [orderTranslationFilesData, dispatch]);

  return value;
};
