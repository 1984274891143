import type { SwitchProps } from 'antd';
import { Switch as AntdSwitch } from 'antd';
import cn from 'classnames';
import { useRef, type FC, type ReactElement } from 'react';
import styles from './styles.module.scss';

type Props = SwitchProps & {
  label?: ReactElement | string;
};

const Switch: FC<Props> = ({ label, disabled = false, ...props }) => {
  const switchRef = useRef<HTMLButtonElement>(null);

  const handleClickSwitch = () => !disabled && switchRef.current?.click();

  return (
    <div className={styles.wrapper}>
      {label && (
        <span
          className={cn(styles.label, { [styles.labelDisabled]: disabled })}
          role="presentation"
          onClick={handleClickSwitch}
        >
          {label}
        </span>
      )}
      <AntdSwitch {...props} disabled={disabled} ref={switchRef} />
    </div>
  );
};

export default Switch;
