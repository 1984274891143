import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { v4 } from 'uuid';
import Button from 'components/ui/atoms/Button';
import Empty from 'components/ui/atoms/Empty';
import CardCommon from 'components/ui/molecules/CardCommon';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import PreorderVariantTable from 'components/ui/organisms/Tables/PreorderVariantTable';
import { FileType } from 'ducks/global/types';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { addPreorderVariantFile } from 'ducks/view/preorders';
import { mockedUploadedFile } from 'handlers/global/mocks';
import { mockedPreorderServices } from 'handlers/preorders/mocks';
import { usePreorderDraftCalculationServices } from '../usePreorderDraftCalculationServices';
import styles from './styles.module.scss';
import VariantSectionExtraTitle from './VariantSectionExtraTitle';

const VariantSection = () => {
  const dispatch = useAppDispatch();
  const { selectedPriceId, variants } = useAppSelector(
    ({ preorders }) => ({
      selectedPriceId: preorders.selectedPriceId,
      variants: preorders.variants,
    }),
    shallowEqual,
  );
  const { isPricesFetching } = usePreorderDraftCalculationServices();
  const showButton = !isPricesFetching && !!selectedPriceId;
  const variant = variants.length ? variants[0] : undefined;

  const handleAddTranslation = () => {
    const uid = v4() as unknown as number;
    const index = variant?.preorderVariantFiles
      ? variant.preorderVariantFiles.length + 1
      : 1;

    const file = {
      ...mockedUploadedFile,
      id: uid,
      objectId: uid,
      object: FileType.PREORDERS,
      filename: `file-${index}.jpg`,
    };

    dispatch(
      addPreorderVariantFile({
        file,
        preorderServices: mockedPreorderServices.services,
      }),
    );
  };

  return (
    <CardCommon
      className={styles.wrapper}
      title={
        <TitleWrapper>
          <FormattedMessage defaultMessage="Wariant" />
          {showButton && (
            <Button onClick={handleAddTranslation}>
              <FormattedMessage defaultMessage="Dodaj tłumaczenie" />
            </Button>
          )}
          {variant && <VariantSectionExtraTitle variant={variant} />}
        </TitleWrapper>
      }
      isLoading={isPricesFetching}
    >
      {!selectedPriceId && (
        <Empty
          description={<FormattedMessage defaultMessage="Wybierz cennik" />}
        />
      )}
      {selectedPriceId && !variant && (
        <Empty
          description={<FormattedMessage defaultMessage="Dodaj tłumaczenie" />}
        />
      )}
      {variant && <PreorderVariantTable variant={variant} />}
    </CardCommon>
  );
};

export default VariantSection;
