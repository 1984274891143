import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { Row } from 'antd';
import CardCommon from 'components/ui/molecules/CardCommon';
import Summary from './components/Summary/Summary';
import UploadFileAndCalcPostedit from './components/UploadFileAndCalcPostedit';

interface Props {
  notify: NotificationInstance;
}

const UploadFileAndSummarySection: FC<Props> = ({ notify }) => {
  return (
    <CardCommon xs={24} lg={14}>
      <Row gutter={[24, 24]}>
        <UploadFileAndCalcPostedit notify={notify} />
        <Summary />
      </Row>
    </CardCommon>
  );
};

export default UploadFileAndSummarySection;
