/* eslint-disable react/jsx-props-no-spreading */
import type { TextProps } from 'antd/es/typography/Text';
import type { MouseEvent } from 'react';
import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import Copy from 'react-copy-to-clipboard';
import { CheckIcon, CopyIcon } from 'icons';
import Button from '../Button';
import TruncateText from '../TruncateText';
import styles from './styles.module.scss';

const { Text } = Typography;

type Size = 'small' | 'medium';

type Props = TextProps & {
  text: string;
  truncate?: boolean;
  truncateSize?: Size;
  truncateMaxLength?: number;
};

const CopyToClipboardWithText = ({
  text,
  truncate = false,
  truncateSize = 'small',
  truncateMaxLength,
  ...textProps
}: Props) => {
  const [isCopied, setCopied] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleCopyToClipboard = (
    e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setCopied(true);

    if (timeoutId) clearTimeout(timeoutId);

    const newTimeoutId = setTimeout(() => setCopied(false), 5000);

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <Copy text={text}>
      <Button
        onClick={handleCopyToClipboard}
        size="small"
        type="text"
        icon={isCopied ? <CheckIcon data-testid="check-icon" /> : <CopyIcon />}
        className={styles.button}
      >
        {truncate ? (
          <TruncateText
            size={truncateSize}
            maxLength={truncateMaxLength}
            {...textProps}
          >
            {text}
          </TruncateText>
        ) : (
          <Text
            style={{
              fontWeight: 'normal',
            }}
            {...textProps}
          >
            {text}
          </Text>
        )}
      </Button>
    </Copy>
  );
};

export default CopyToClipboardWithText;
