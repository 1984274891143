import { notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { clearSelectedInvoicesVat } from 'ducks/view/invoices';
import { setInvoicesOrderIds } from 'ducks/view/invoicesAdd';
import { Routes } from 'views/Invoices/Routing';

const GenerateCollectiveInvoiceButton = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [notify, contextHolder] = notification.useNotification();
  const selectedInvoicesVat = useAppSelector(
    ({ invoices }) => invoices.selectedInvoicesVat,
  );
  const disabled = !selectedInvoicesVat.length;

  const handleGenerateCollectiveInvoice = async () => {
    if (disabled) return;

    try {
      const checkClientIdsEqual = selectedInvoicesVat.every(
        ({ client }) => client.id === selectedInvoicesVat[0].client.id,
      );

      if (!checkClientIdsEqual) {
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wybrano zamówienia różnych klientów." />
          ),
        });

        return;
      }

      dispatch(setInvoicesOrderIds(selectedInvoicesVat.map(({ id }) => id)));
      navigate(Routes.INVOICE_ADD);

      dispatch(clearSelectedInvoicesVat());
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas wystawiania faktury zbiorczej." />
        ),
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Button
        type="primary"
        onClick={handleGenerateCollectiveInvoice}
        disabled={disabled}
      >
        <FormattedMessage defaultMessage="Wystaw fakturę zbiorczą" />
      </Button>
    </>
  );
};

export default GenerateCollectiveInvoiceButton;
