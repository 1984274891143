import type { OrderFilesAndSericesValues } from 'components/ui/organisms/Tables/OrderFilesAndServicesTable';
import type { OrderFileLangState } from 'ducks/view/orders';
import type { FC } from 'react';
import { memo } from 'react';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import FormItem from 'components/ui/atoms/FormItem';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { updateOrderFiles } from 'ducks/view/orders';
import styles from './styles.module.scss';

type Props = {
  orderFileId: number;
  orderFileLangId: string | number;
} & Pick<OrderFileLangState, 'translatorPriceNetto'>;

const TranslatorPriceNettoColumn: FC<Props> = memo(
  ({ orderFileId, orderFileLangId, translatorPriceNetto }) => {
    const dispatch = useAppDispatch();
    const isEditable = useAppSelector(({ orders }) => orders.isEditable);

    const handleUpdateTranslatorPriceNetto = (value: number) =>
      dispatch(
        updateOrderFiles({
          field: {
            [orderFileId]: {
              [orderFileLangId]: {
                translatorPriceNetto: value,
              },
            },
          },
        }),
      );

    return isEditable ? (
      <FormItem<OrderFilesAndSericesValues>
        component={
          <DecimalInputNumber
            value={translatorPriceNetto}
            onChange={(value) => handleUpdateTranslatorPriceNetto(value ?? 0)}
          />
        }
      />
    ) : (
      <span className={styles.cell}>
        <CurrencyFormatter value={translatorPriceNetto} />
      </span>
    );
  },
);

export default TranslatorPriceNettoColumn;
