import type { InputNumberProps } from 'antd';
import type { FC } from 'react';
import { InputNumber } from 'antd';
import cn from 'classnames';
import styles from './styles.module.scss';

const DEFAULT_DECIMAL_SEPARATOR = '.';
const MIN_VALUE = 0;

type Props = Omit<InputNumberProps, 'onChange'> & {
  onChange?: (value: number | null) => void;
  fullWidth?: boolean;
  allowNullable?: boolean;
};

/**
 * Component makes possible to write decimal with coma (default)
 * it replaces it to dot (default) (antd prefers it that way)
 */
const DecimalInputNumber: FC<Props> = ({
  decimalSeparator = DEFAULT_DECIMAL_SEPARATOR,
  onChange,
  fullWidth = false,
  allowNullable = true,
  className,
  ...props
}) => {
  const handleOnChange = (value: string | number | null) => {
    const newNullableValue = value ? Number(value) : null;
    const newValue = allowNullable ? newNullableValue : Number(value);

    onChange?.(newValue);
  };

  return (
    <InputNumber
      className={cn(className, { [styles.fullWidth]: fullWidth })}
      formatter={(value) => `${value}`.replace(',', decimalSeparator)}
      parser={(value) => Number(value?.replace(',', decimalSeparator))}
      decimalSeparator={decimalSeparator}
      min={MIN_VALUE}
      onChange={handleOnChange}
      {...props}
    />
  );
};

export default DecimalInputNumber;
