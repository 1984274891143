import type { ColumnsType } from 'antd/es/table';
import type { TableRow } from 'components/ui/molecules/Table';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { EditIcon } from 'icons';

export type EmailTemplateRow = {
  title: string;
};

export type PreorderRow = TableRow & EmailTemplateRow;

type ColumnArgs = {
  handleEdit: (id: number) => void;
};

export const columns = ({
  handleEdit,
}: ColumnArgs): ColumnsType<PreorderRow> => [
  {
    title: <FormattedMessage defaultMessage="Nazwa szablonu" />,
    key: 'title',
    dataIndex: 'title',
  },
  {
    title: <span />,
    key: 'action',
    render: ({ id }) => (
      <Button type="link" icon={<EditIcon />} onClick={() => handleEdit(id)}>
        <FormattedMessage defaultMessage="Edytuj" />
      </Button>
    ),
  },
];
