import { useMemo } from 'react';
import PreorderHeader from 'views/Preorders/components/PreorderHeader';
import usePreorderRealizationServices from '../../usePreorderRealizationServices';
import { headerCols } from './config';

const PreorderRealizationHeader = () => {
  const { preorderData } = usePreorderRealizationServices();

  const cols = useMemo(
    () => (preorderData ? headerCols({ data: preorderData }) : []),
    [preorderData],
  );
  return <PreorderHeader cols={cols} />;
};

export default PreorderRealizationHeader;
