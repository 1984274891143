import { Route } from 'react-router-dom';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import PriceDetails from './views/PriceDetails';
import PricesTable from './views/PricesTable';

export enum Routes {
  PRICES = '/',
  PRICE_DETAILS = '/prices/:id',
}

const PricesRouting = () => {
  return (
    <AnimatedRoutes pathLevelWatch={2}>
      <Route
        path={`${Routes.PRICE_DETAILS.replace('/prices', '')}`}
        element={<PriceDetails />}
      />
      <Route path={`${Routes.PRICES}`} element={<PricesTable />} />
    </AnimatedRoutes>
  );
};

export default PricesRouting;
