import {
  Form as AntdForm,
  Button,
  Card,
  Col,
  Input,
  List,
  Row,
  Typography,
  notification,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'components/main/App/Routing';
import TemplateEditor from 'components/ui/organisms/TemplateEditor';
import { templateSnippets } from 'constants/template';
import {
  useGetEmailTemplateQuery,
  useEditEmailTemplateMutation,
  useAddEmailTemplateMutation,
} from 'ducks/settings/service';
import { Routes as SettingsRoutes } from 'views/Settings/Routing';

const { Item, useForm } = AntdForm;
const { Title } = Typography;

const Form: React.FC = () => {
  const { id = '' } = useParams();
  const [notify] = notification.useNotification();
  const navigate = useNavigate();
  const [form] = useForm();
  const { data: emailTemplate, isFetching: isLoadingTemplate } =
    useGetEmailTemplateQuery({ id }, { skip: !id });

  const [updateTemplate, { isLoading: updateTemplateLoading }] =
    useEditEmailTemplateMutation();
  const [addTemplate, { isLoading: addTemplateLoading }] =
    useAddEmailTemplateMutation();

  const isSending = addTemplateLoading || updateTemplateLoading;

  const sectionTitle = id ? (
    <FormattedMessage defaultMessage="Edytuj szablon" />
  ) : (
    <FormattedMessage defaultMessage="Utwórz szablon" />
  );

  const handleSubmit = async () => {
    try {
      const { title, content } = await form.validateFields();

      if (id) {
        await updateTemplate({
          title,
          content,
          id,
        }).unwrap();
      } else {
        await addTemplate({ title, content }).unwrap();
      }

      notify.success({
        message: <FormattedMessage defaultMessage="Szablon został edytowany" />,
      });

      navigate(`${Routes.SETTINGS}${SettingsRoutes.EMAIL_TEMPLATES}`);
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania szablonu." />
        ),
      });
    }
  };

  return (
    <Card className="mb-24" bordered={false} loading={isLoadingTemplate}>
      <Title level={3}>{sectionTitle}</Title>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={14} xl={15}>
          <AntdForm layout="vertical" onFinish={handleSubmit} form={form}>
            <Item
              name="title"
              initialValue={emailTemplate?.title ?? ''}
              label={<FormattedMessage defaultMessage="Nazwa szablonu" />}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="Uzupełnij nazwę szablonu" />
                  ),
                },
              ]}
            >
              <Input />
            </Item>
            <Item
              name="content"
              initialValue={emailTemplate?.content ?? ''}
              label={<FormattedMessage defaultMessage="Treść szablonu" />}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="Uzupełnij treść szablonu" />
                  ),
                },
              ]}
            >
              <TemplateEditor />
            </Item>
            <Button type="primary" htmlType="submit" loading={isSending}>
              <FormattedMessage defaultMessage="Zapisz" />
            </Button>
          </AntdForm>
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={9}>
          <List
            header={
              <Title level={5}>
                <FormattedMessage defaultMessage="Zmienne" />
              </Title>
            }
            footer={null}
            bordered
            dataSource={templateSnippets}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Col>
      </Row>
    </Card>
  );
};
export default Form;
