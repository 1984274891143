import { useMemo } from 'react';
import { useGetUserDetailsQuery } from 'ducks/users/service';
import { useOrdersServices } from 'views/Orders/useOrdersServices';

export const useOrderAssignServices = () => {
  const {
    orderData,
    isOrderLoading,
    isOrderEmpty,
    isCurrentlyEdited,
    refetch,
  } = useOrdersServices();
  const { data: activeUserData, isLoading: isActiveUserLoading } =
    useGetUserDetailsQuery(
      {
        id: Number(orderData?.activeEditorId),
      },
      { skip: !orderData },
    );

  const value = useMemo(
    () => ({
      orderData,
      isOrderLoading,
      isOrderEmpty,
      refetch,
      isCurrentlyEdited,
      activeUserData,
      isActiveUserLoading,
    }),
    [
      orderData,
      isOrderLoading,
      isOrderEmpty,
      refetch,
      isCurrentlyEdited,
      activeUserData,
      isActiveUserLoading,
    ],
  );

  return value;
};
