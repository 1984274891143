import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { PreorderVariantSlice } from 'ducks/view/preorders';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import TruncateText from 'components/ui/atoms/TruncateText';
import { FileTranslationType } from 'constants/fileTranslation';
import { calcPreorderVariantLangPriceNetto } from 'utilities/calcPreorderTotals';
import styles from '../../styles.module.scss';
import ActionsColumn from './components/ActionsColumn';
import FlexibleCheckboxColumn from './components/FlexibleCheckboxColumn';
import LangsColumn from './components/LangsColumn';
import CharactersSelector from './components/SelectorInput/CharactersSelector';
import DiscountSelector from './components/SelectorInput/DiscountSelector';
import TypeSelectColumn from './components/TypeSelectColumn';

export type PreorderTranslationRow = TableRow &
  PreorderVariantSlice['preorderVariantFiles'][number];

type Props = {
  variantId: string | number;
  isEditable: boolean;
};

export const preorderTranslationColumns = ({
  variantId,
  isEditable,
}: Props): ColumnsType<PreorderTranslationRow> => [
  {
    key: 'type',
    dataIndex: 'type',
    title: <FormattedMessage defaultMessage="Typ usługi" />,
    render: (_, { id, type }) => <TypeSelectColumn id={id} type={type} />,
  },
  {
    key: 'file',
    dataIndex: 'file',
    title: <FormattedMessage defaultMessage="Pliki" />,
    render: (_, { id, file }) => (
      <TruncateText data-testid={`filename-${id}`} nowrap maxLength={40}>
        {file.filename}
      </TruncateText>
    ),
  },
  {
    key: 'langs',
    dataIndex: 'langs',
    title: <FormattedMessage defaultMessage="Język" />,
    render: (_, { id: variantFileId, preorderVariantLangs }) => {
      const langs = preorderVariantLangs.map(({ id, langFrom, langTo }) => ({
        id,
        langFrom,
        langTo,
      }));

      return <LangsColumn variantFileId={variantFileId} langs={langs} />;
    },
  },
  {
    key: 'characters',
    dataIndex: 'characters',
    title: <FormattedMessage defaultMessage="Liczba znaków" />,
    render: (_, { id: variantFileId, preorderVariantLangs, type }) => (
      <div className={styles.multilineCell}>
        {preorderVariantLangs.map(({ id, characters }) => (
          <CharactersSelector
            key={id}
            variantId={variantId}
            variantFileId={variantFileId}
            variantLangId={id}
            value={characters}
            isSworn={type === FileTranslationType.SWORN}
          />
        ))}
      </div>
    ),
  },
  {
    key: 'isSpecial',
    dataIndex: 'isSpecial',
    title: <FormattedMessage defaultMessage="Specjalistyczny" />,
    align: 'center',
    render: (_, { id, isSpecial, preorderVariantLangs, type }) =>
      type !== FileTranslationType.CORRECTION &&
      !!preorderVariantLangs.length && (
        <FlexibleCheckboxColumn
          id={id}
          checked={isSpecial}
          field="isSpecial"
          variantId={variantId}
        />
      ),
  },
  {
    key: 'isCorrection',
    dataIndex: 'isCorrection',
    title: <FormattedMessage defaultMessage="Korekta" />,
    align: 'center',
    render: (_, { id, type, isCorrection, preorderVariantLangs }) =>
      !!preorderVariantLangs.length &&
      type === FileTranslationType.NORMAL && (
        <FlexibleCheckboxColumn
          id={id}
          checked={isCorrection}
          field="isCorrection"
          variantId={variantId}
        />
      ),
  },
  {
    key: 'pages',
    dataIndex: 'pages',
    title: <FormattedMessage defaultMessage="Strony" />,
    render: (_, { preorderVariantLangs }) => (
      <div className={styles.multilineCell}>
        {preorderVariantLangs.map(({ id, pages }) => (
          <span key={id} data-testid={`pages-${id}`}>
            {pages}
          </span>
        ))}
      </div>
    ),
  },
  // commented out as recommended in #643
  // {
  //   key: 'timeRealization',
  //   dataIndex: 'timeRealization',
  //   title: <FormattedMessage defaultMessage="Termin" />,
  //   render: (_, { id: variantFileId, preorderVariantLangs, isSpecial }) => (
  //     <div className={styles.multilineCell}>
  //       {preorderVariantLangs.map(({ id, timeRealization, characters }) =>
  //         isEditable ? (
  //           <FormItem<PreorderVariantTableValues>
  //             key={`${id}-${isSpecial}-${characters}`}
  //           name={[variantFileId as number, id as number, 'timeRealization']}
  //             component={
  //               <DecimalInputNumber
  //                 data-testid={`timeRealizationInput-${id}`}
  //                 className={styles.twoDigitsInput}
  //                 max={MAX_TIME_REALIZATION_VALUE}
  //               />
  //             }
  //             additionalItemProps={{
  //               initialValue: timeRealization,
  //               shouldUpdate: (prevVal, curValues) => {
  //                 return (
  //                   timeRealization !==
  //                   curValues?.[variantFileId]?.[id].timeRealization
  //                 );
  //               },
  //             }}
  //           />
  //         ) : (
  //           <span key={id} data-testid={`timeRealization-${id}`}>
  //             <FormattedMessage
  //               defaultMessage="{timeRealization} dni"
  //               values={{ timeRealization }}
  //             />
  //           </span>
  //         ),
  //       )}
  //     </div>
  //   ),
  // },
  {
    key: 'priceNetto',
    dataIndex: 'priceNetto',
    title: <FormattedMessage defaultMessage="Netto" />,
    render: (_, { type, isSpecial, isCorrection, preorderVariantLangs }) => (
      <div className={styles.multilineCell}>
        {preorderVariantLangs.map(
          ({
            id,
            pages,
            priceNetto,
            priceSpecialNetto,
            priceCorrectionNetto,
          }) => (
            <span key={id} data-testid={`priceNetto-${id}`}>
              <CurrencyFormatter
                value={calcPreorderVariantLangPriceNetto({
                  type,
                  pages,
                  priceNetto,
                  priceSpecialNetto,
                  priceCorrectionNetto,
                  isSpecial,
                  isCorrection,
                })}
              />
            </span>
          ),
        )}
      </div>
    ),
  },
  {
    key: 'discount',
    dataIndex: 'discount',
    title: <FormattedMessage defaultMessage="Rabat (%)" />,
    render: (_, { id: variantFileId, preorderVariantLangs }) => (
      <div className={styles.multilineCell}>
        {preorderVariantLangs.map(({ id, discount }) => (
          <DiscountSelector
            key={id}
            variantId={variantId}
            variantFileId={variantFileId}
            variantLangId={id}
            value={discount}
          />
        ))}
      </div>
    ),
  },
  {
    key: 'afterDiscount',
    dataIndex: 'afterDiscount',
    title: <FormattedMessage defaultMessage="Po rabacie" />,
    render: (_, { type, isSpecial, isCorrection, preorderVariantLangs }) => (
      <div className={styles.multilineCell}>
        {preorderVariantLangs.map(
          ({
            id,
            pages,
            priceNetto,
            priceSpecialNetto,
            priceCorrectionNetto,
            discount,
          }) => (
            <span key={id} data-testid={`priceNettoAfterDiscount-${id}`}>
              <CurrencyFormatter
                value={calcPreorderVariantLangPriceNetto({
                  type,
                  pages,
                  priceNetto,
                  priceSpecialNetto,
                  priceCorrectionNetto,
                  isSpecial,
                  isCorrection,
                  discount,
                })}
              />
            </span>
          ),
        )}
      </div>
    ),
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    render: (_, { id: variantFileId, preorderVariantLangs }) =>
      isEditable && (
        <div className={styles.multilineCell}>
          <ActionsColumn
            variantId={variantId}
            variantFileId={variantFileId}
            preorderVariantLangs={preorderVariantLangs}
          />
        </div>
      ),
  },
];
