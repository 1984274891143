import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';
import type { Order } from 'ducks/orders/types';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { DayjsFormat } from 'constants/dayjsFormats';
import { NO_DATA } from 'constants/strings';

type Props = {
  data: Order;
};

export const headerCols = ({
  data: { number, contactPerson, companyName, phone, dateRealization },
}: Props): HeaderCardCol[] => {
  const realizationDate = dateRealization
    ? dayjs(dateRealization).format(DayjsFormat.DATE_AND_TIME)
    : NO_DATA;

  return [
    {
      key: 1,
      firstRow: <FormattedMessage defaultMessage="Numer wyceny" />,
      secondRow: <span data-testid="number">{number ?? NO_DATA}</span>,
    },
    {
      key: 2,
      firstRow: <FormattedMessage defaultMessage="Data realizacji" />,
      secondRow: <span data-testid="dateRealization">{realizationDate}</span>,
    },
    {
      key: 3,
      firstRow: <FormattedMessage defaultMessage="Osoba kontaktowa" />,
      secondRow: (
        <span data-testid="contactPerson">{contactPerson ?? NO_DATA}</span>
      ),
    },
    {
      key: 4,
      firstRow: <FormattedMessage defaultMessage="Firma" />,
      secondRow: <span data-testid="name">{companyName ?? NO_DATA}</span>,
    },
    {
      key: 5,
      firstRow: <FormattedMessage defaultMessage="Telefon" />,
      secondRow: <span data-testid="phone">{phone ?? NO_DATA}</span>,
    },
  ];
};
