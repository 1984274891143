import type { TranslationsAndOrdersFile } from 'ducks/orders/types';
import type { FC } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { assignOrderTranslationUploadedFile } from 'ducks/view/orderTranslationFiles';
import { useTranslationFileItemHelpers } from '../useTranslationFileItemHelpers';

type Props = { translationFile: TranslationsAndOrdersFile };

const TranslationFileItemDescription: FC<Props> = ({ translationFile }) => {
  const dispatch = useAppDispatch();
  const { orderFiles, uploadedOrderFiles } = useAppSelector(
    ({ orderTranslationFiles }) => ({
      orderFiles: orderTranslationFiles.orderFiles,
      uploadedOrderFiles: orderTranslationFiles.uploadedFiles.orders,
    }),
    shallowEqual,
  );
  const { description } = useTranslationFileItemHelpers(translationFile.id);

  const options = uploadedOrderFiles.map(({ id: value, filename }) => ({
    value,
    label: filename,
  }));

  const handleChange = (value: number) => {
    const uploadedOrderFile = uploadedOrderFiles.find(({ id }) => id === value);
    const orderFile = orderFiles.find(
      ({ fileId }) => fileId === uploadedOrderFile?.id,
    );

    if (!orderFile || !uploadedOrderFile) return;

    dispatch(
      assignOrderTranslationUploadedFile({
        orderFileId: orderFile.id,
        translationFile,
      }),
    );
  };

  return (
    description ?? (
      <Select
        key={translationFile.id}
        className="width-100"
        options={options}
        placeholder={
          <FormattedMessage defaultMessage="Wybierz plik źródłowy" />
        }
        onChange={handleChange}
      />
    )
  );
};

export default TranslationFileItemDescription;
