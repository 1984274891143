import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { User } from 'ducks/users/types';
import { Button } from 'antd';
import { capitalize } from 'lodash';
import { FormattedMessage } from 'react-intl';
import FormatDate from 'components/ui/atoms/FormatDate/FormatDate';
import { UserRole } from 'ducks/users/types';
import { EditIcon } from 'icons';

export type UserRow = TableRow &
  Pick<User, 'id' | 'role' | 'createdAt'> & {
    fullName: string;
    allowTime: string;
  };

export const columns = (
  onClickEdit: (id: number) => void,
): ColumnsType<UserRow> => [
  {
    title: <FormattedMessage defaultMessage="Numer" />,
    key: 'number',
    dataIndex: 'number',
  },
  {
    title: <FormattedMessage defaultMessage="Imię i nazwisko" />,
    key: 'fullName',
    dataIndex: 'fullName',
  },
  {
    title: <FormattedMessage defaultMessage="Rola" />,
    key: 'role',
    dataIndex: 'role',
    render: (_, { role }) => capitalize(UserRole[role]),
  },
  {
    title: <FormattedMessage defaultMessage="Godziny pracy" />,
    key: 'allowTime',
    dataIndex: 'allowTime',
  },
  {
    title: <FormattedMessage defaultMessage="Data utworzenia konta" />,
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (_, { createdAt }) => <FormatDate date={createdAt} />,
  },
  {
    key: 'action',
    width: 50,
    render: ({ key, id }) => (
      <Button
        data-testid={`editButton${key}`}
        type="link"
        icon={<EditIcon />}
        onClick={() => onClickEdit(id)}
      >
        <FormattedMessage defaultMessage="Edytuj" />
      </Button>
    ),
  },
];
