import type { VariantServiceRow } from '../../config';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import FormItem from 'components/ui/atoms/FormItem';
import { useAppSelector } from 'ducks/store';

type Props = Pick<VariantServiceRow, 'id' | 'isActive' | 'priceNetto'>;

const PriceInput: FC<Props> = ({ id, isActive, priceNetto }) => {
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);

  return isEditable ? (
    <FormItem
      name={[id as number, 'priceNetto']}
      component={
        <DecimalInputNumber
          data-testid="priceNettoInput"
          allowNullable={false}
        />
      }
      rules={[
        {
          required: isActive,
          message: <FormattedMessage defaultMessage="Podaj cenę" />,
        },
      ]}
      additionalItemProps={{
        initialValue: priceNetto,
      }}
    />
  ) : (
    <span data-testid="priceNetto">
      <CurrencyFormatter value={priceNetto} />
    </span>
  );
};

export default PriceInput;
