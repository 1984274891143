import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Dayjs } from 'dayjs';
import type { AddInvoiceLetterSummonRequest } from 'ducks/invoices/types';
import { DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import { FormattedMessage } from 'react-intl';

export type AddLetterSummonsModalValues = ReplaceType<
  Omit<AddInvoiceLetterSummonRequest, 'id'>,
  Date,
  Dayjs
>;

export const initialValues: Partial<AddLetterSummonsModalValues> = {};

export const formItems: FormItemRecord<AddLetterSummonsModalValues>[] = [
  {
    key: 'dateSent',
    name: 'dateSent',
    label: <FormattedMessage defaultMessage="Data wysłania" />,
    component: (
      <DatePicker
        data-testid="dateSentPicker"
        className="width-100"
        locale={locale}
        allowClear={false}
      />
    ),
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz datę wysłania" />,
      },
    ],
  },
  {
    key: 'dateReceived',
    name: 'dateReceived',
    label: <FormattedMessage defaultMessage="Data odebrania" />,
    component: (
      <DatePicker
        data-testid="dateReceivedPicker"
        className="width-100"
        locale={locale}
        allowClear={false}
      />
    ),
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz datę odebrania" />,
      },
    ],
  },
];
