import type { IntlShape } from 'react-intl';

export enum Gender {
  MALE = 'm',
  FEMALE = 'f',
  OTHER = 'o',
}

type GenderOption = {
  value: Gender;
  label: string;
};

export const genders = (intl: IntlShape): GenderOption[] => [
  {
    value: Gender.MALE,
    label: intl.formatMessage({ defaultMessage: 'mężczyzna' }),
  },
  {
    value: Gender.FEMALE,
    label: intl.formatMessage({ defaultMessage: 'kobieta' }),
  },
  {
    value: Gender.OTHER,
    label: intl.formatMessage({ defaultMessage: 'inna' }),
  },
];
