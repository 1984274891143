import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import { useGetOrderQuery } from 'ducks/orders/service';
import { OrderRequestTypeParam } from 'ducks/orders/types';
import { checkIfCurrentlyEdited } from 'utilities/authorization';
import { Routes } from './Routing';

export const useOrdersServices = () => {
  const { id = '' } = useParams();
  const { pathname } = useLocation();
  const { user } = useSanctum();
  const isOrderShowType = pathname === Routes.ORDER_DETAILS.replace(':id', id);
  const {
    data: orderData,
    isLoading: isOrderLoading,
    isFetching: isOrderFetching,
    isError,
    refetch,
  } = useGetOrderQuery({
    id,
    type: isOrderShowType ? OrderRequestTypeParam.SHOW : undefined,
  });
  const isCurrentlyEdited = checkIfCurrentlyEdited(
    orderData?.activeEditorId,
    user,
  );
  const isOrderEmpty = !orderData && !isOrderLoading && isError;

  const value = useMemo(
    () => ({
      orderData,
      isOrderLoading,
      isOrderFetching,
      isOrderEmpty,
      refetch,
      isCurrentlyEdited,
    }),
    [
      orderData,
      isOrderLoading,
      isOrderFetching,
      isOrderEmpty,
      refetch,
      isCurrentlyEdited,
    ],
  );

  return value;
};
