import type { FormInstance } from 'antd';
import type { FC } from 'react';
import type { OrderSendEmailsValues } from 'views/Orders/views/OrderSendEmails/config';
import { memo } from 'react';
import CardCommon from 'components/ui/molecules/CardCommon';
import SendEmailForm from 'components/ui/organisms/Forms/SendEmailForm';
import { useAppDispatch } from 'ducks/store';
import {
  updateOrderSendEmailsTranslators,
  type OrderSendEmailsTranslatorState,
} from 'ducks/view/orders';

interface Props
  extends Pick<
    OrderSendEmailsTranslatorState,
    'translatorId' | 'isChecked' | 'selectedEmailTemplate'
  > {
  form: FormInstance<OrderSendEmailsValues>;
}

const TranslatorPanelForm: FC<Props> = memo(
  ({ form, translatorId, isChecked, selectedEmailTemplate }) => {
    const dispatch = useAppDispatch();

    const handleOnEmailTemplateChange = (emailTemplate: string) => {
      dispatch(
        updateOrderSendEmailsTranslators({
          translatorId,
          field: { selectedEmailTemplate: emailTemplate ?? '' },
        }),
      );
    };

    return (
      <CardCommon lg={16}>
        <SendEmailForm
          form={form}
          emailTemplate={selectedEmailTemplate}
          onEmailTemplateChange={handleOnEmailTemplateChange}
          isRequired={isChecked}
        />
      </CardCommon>
    );
  },
);

export default TranslatorPanelForm;
