import type { InvoiceSendValues } from '../../config';
import type { FormInstance } from 'antd';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { useSendInvoiceVatMutation } from 'ducks/invoices/service';
import { useAppDispatch } from 'ducks/store';
import { setGlobalIsLoading } from 'ducks/view/global';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';
import { Routes } from 'views/Invoices/Routing';
import { useInvoiceSendServices } from '../../useInvoiceSendServices';
import styles from './styles.module.scss';

type Props = {
  form: FormInstance<InvoiceSendValues>;
  notify: NotificationInstance;
};

const ActionsSection: FC<Props> = ({ form, notify }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { invoiceVatData, isInvoiceVatFetching } = useInvoiceSendServices();
  const [sendInvoiceVat, { isLoading: isSendLoading }] =
    useSendInvoiceVatMutation();
  const isLoading = isInvoiceVatFetching || isSendLoading;

  const handleSend = () => {
    if (isLoading || !invoiceVatData) return;

    const { id, client } = invoiceVatData;

    dispatch(setGlobalIsLoading(true));

    mutationFunctionWithForm<void, InvoiceSendValues>({
      form,
      mutationFn: async ({ isInvoiceVat, ...values }) => {
        const name = client?.name ?? '';
        const invoices = isInvoiceVat ? [id] : [];

        await sendInvoiceVat({
          id,
          body: { ...values, name, invoices },
        }).unwrap();
      },
      onSuccess: () => navigate(Routes.INVOICES_VAT),
      onError: () =>
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas wysyłania faktury VAT." />
          ),
        }),
      onSettled: () => dispatch(setGlobalIsLoading(false)),
    });
  };

  return (
    <div className={styles.space}>
      <Button type="primary" onClick={handleSend} loading={isLoading}>
        <FormattedMessage defaultMessage="Wyślij" />
      </Button>
    </div>
  );
};

export default ActionsSection;
