import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';
import type { Preorder } from 'ducks/preorders/types';
import { FormattedMessage } from 'react-intl';
import { NO_DATA } from 'constants/strings';

type Props = {
  data: Preorder;
};

export const headerCols = ({
  data: { isCompany, contactPerson, name, phone },
}: Props): HeaderCardCol[] => [
  {
    key: 1,
    firstRow: (
      <span>
        <b>
          <FormattedMessage defaultMessage="Osoba kontaktowa:" />
        </b>{' '}
        <span data-testid="contactPerson">{contactPerson ?? NO_DATA}</span>
      </span>
    ),
    secondRow: isCompany && (
      <span>
        <b>
          <FormattedMessage defaultMessage="Firma:" />
        </b>{' '}
        <span data-testid="name">{name ?? NO_DATA}</span>
      </span>
    ),
  },
  {
    key: 2,
    firstRow: (
      <span>
        <b>
          <FormattedMessage defaultMessage="Telefon:" />
        </b>{' '}
        <span data-testid="phone">{phone ?? NO_DATA}</span>
      </span>
    ),
  },
];
