import type { FormValues } from './config';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { ListItemRecord } from 'components/ui/molecules/List';
import type { ResponseFormItem } from 'ducks/settings/types';
import type { ReactElement } from 'react';
import { Button, Card, Form } from 'antd';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DynamicForm from 'components/ui/molecules/DynamicForm';
import List from 'components/ui/molecules/List';
import Modal from 'components/ui/molecules/Modal';
import styles from './SettingsForm.module.scss';

const { useForm } = Form;

type Props = {
  formElements: ResponseFormItem[];
  cardTitle: ReactElement;
  formTitle: ReactElement;
  onSubmit: (values: FormValues) => void;
  listItems: ListItemRecord[];
  isLoadingList: boolean;
  notify: NotificationInstance;
  isLoadingSend: boolean;
  isSuccessSend: boolean;
  isErrorSend: boolean;
};

const SettingsForm: React.FC<Props> = ({
  formElements,
  cardTitle,
  formTitle,
  onSubmit,
  listItems,
  isLoadingList,
  notify,
  isLoadingSend,
  isSuccessSend,
  isErrorSend,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm();

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOnSubmit = async () => {
    if (isLoadingSend) return;

    const values = await form.validateFields();

    await onSubmit(values);

    handleCloseModal();
  };

  useEffect(() => {
    if (isLoadingSend) return;
    if (isSuccessSend) {
      notify.success({
        message: (
          <FormattedMessage defaultMessage="Poprawnie edytowano dane." />
        ),
      });
    }

    if (isErrorSend) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania danych." />
        ),
      });
    }
  }, [isErrorSend, isLoadingSend, isSuccessSend, notify]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={handleCloseModal}
        onOk={handleOnSubmit}
        title={formTitle}
        destroyOnClose
        isLoading={isLoadingSend}
      >
        <Form form={form} layout="vertical">
          <DynamicForm formElements={formElements} />
        </Form>
      </Modal>
      <Card
        className={classnames(styles.card, 'header-solid')}
        bordered={false}
        loading={isLoadingList}
        title={
          <div className={styles.header}>
            {cardTitle}
            <Button onClick={() => setIsModalOpen(true)} type="primary">
              <FormattedMessage defaultMessage="Edytuj" />
            </Button>
          </div>
        }
      >
        <List items={listItems} isLoading={isLoadingList} />
      </Card>
    </>
  );
};

export default SettingsForm;
