import type { Client } from 'ducks/clients/types';
import type { FC } from 'react';
import { notification } from 'antd';
import { useState } from 'react';
import OrdersTab from './components/OrdersTab';
import PreordersTab from './components/PreordersTab';
import TableHeader from './components/TableHeader';
import { SelectedTable } from './config';

type Props = Partial<
  Pick<
    Client,
    | 'ordersCount'
    | 'preordersCount'
    | 'complaintsCount'
    | 'ordersInProgressCount'
  >
>;

const OrdersCard: FC<Props> = ({
  ordersCount,
  preordersCount,
  complaintsCount,
  ordersInProgressCount,
}) => {
  const [notify, contextHolder] = notification.useNotification();
  const [selectedTable, setSelectedTable] = useState(SelectedTable.ORDERS);
  const [selectedLang, setSelectedLang] = useState<string | null>(null);
  const isOrdersTab = selectedTable === SelectedTable.ORDERS;

  return (
    <>
      {contextHolder}
      {isOrdersTab ? (
        <OrdersTab
          selectedLang={selectedLang}
          tableCardTitle={
            <TableHeader
              ordersCount={ordersCount}
              preordersCount={preordersCount}
              complaintsCount={complaintsCount}
              ordersInProgressCount={ordersInProgressCount}
              setSelectedLang={setSelectedLang}
              setSelectedTable={setSelectedTable}
              selectedLang={selectedLang}
              selectedTable={selectedTable}
            />
          }
          notify={notify}
        />
      ) : (
        <PreordersTab
          selectedLang={selectedLang}
          tableCardTitle={
            <TableHeader
              ordersCount={ordersCount}
              preordersCount={preordersCount}
              complaintsCount={complaintsCount}
              ordersInProgressCount={ordersInProgressCount}
              setSelectedLang={setSelectedLang}
              setSelectedTable={setSelectedTable}
              selectedLang={selectedLang}
              selectedTable={selectedTable}
            />
          }
        />
      )}
    </>
  );
};

export default OrdersCard;
