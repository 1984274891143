import type { Client } from 'ducks/clients/types';
import { getIntl } from 'components/main/Intl';

const fullAddress = (
  address: string | null,
  postCode: string | null,
  city: string | null,
) => {
  const street = address
    ? `${getIntl().formatMessage({
        defaultMessage: `ul.`,
      })} ${address}`
    : '';
  return `${street}, ${postCode ?? ''} ${city ?? ''}`;
};

export const getInvoiceClientFullAddress = (client: Client | undefined) => {
  if (client) {
    const { invoiceAddress, invoicePostCode, invoiceCity } = client;

    return fullAddress(invoiceAddress, invoicePostCode, invoiceCity);
  }

  return '';
};

export const getClientFullAddress = (client: Client | undefined) => {
  if (client) {
    const { address, postCode, city } = client;

    return fullAddress(address, postCode, city);
  }

  return '';
};
