import type { PreorderVariantSlice } from 'ducks/view/preorders';
import type { FC } from 'react';
import { Row, Space, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import PreorderVariantSummaryForm from './components/PreorderVariantSummaryForm/PreorderVariantSummaryForm';
import PreorderVariantSummaryPrices from './components/PreorderVariantSummaryPrices';

interface Props {
  variant: PreorderVariantSlice;
}

const { Text } = Typography;

const PreorderVariantSummary: FC<Props> = ({ variant }) => {
  return (
    <Space className="width-100" direction="vertical" size="middle">
      <Text strong>
        <FormattedMessage defaultMessage="Podsumowanie" />
      </Text>
      <Row gutter={[24, 24]} align="top">
        <PreorderVariantSummaryForm variant={variant} />
        <PreorderVariantSummaryPrices variant={variant} />
      </Row>
    </Space>
  );
};

export default PreorderVariantSummary;
