/* eslint-disable max-len */
import type { RuleObject } from 'antd/es/form';
import { getIntl } from 'components/main/Intl';
import {
  MAX_NIP_LENGTH,
  MAX_PESEL_LENGTH,
  MAX_REGON_LENGTH,
} from 'constants/formInputLengths';
import { isValidIBANNumber } from './iBanHelpers';
import {
  INTERNATIONAL_NIP_REGEX,
  NIP_REGEX,
  PESEL_REGEX,
  REGON_REGEX,
  isValidNip,
  isValidPesel,
  isValidRegon,
} from './isValid';

type GetFieldValue = { getFieldValue: (field: string) => string };
type ValidateNipRuleProps = {
  isInternational?: boolean;
};

type ValidateRegonRuleArgs = {
  isInternational?: boolean;
};

const PHONE_REGEX = /^[+\-()\d\s]+$/;
const POSTAL_CODE_REGEX = /^[\w\s-]{1,10}$/;
const URL_REGEX =
  /^(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const validateIBanRule = () => {
  return () => ({
    validator(_: RuleObject, value: string) {
      const result = isValidIBANNumber(value);
      if (result) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error(
          getIntl().formatMessage({
            defaultMessage: 'Niepoprawny kod IBAN',
          }),
        ),
      );
    },
  });
};

export const validatePasswordRule = () => {
  return () => ({
    validator(_: RuleObject, value: string) {
      if (!value) {
        return Promise.resolve();
      }

      if (value.length < 8) {
        return Promise.reject(
          new Error(
            getIntl().formatMessage({
              defaultMessage: 'Hasło musi zawierać minimum 8 znaków',
            }),
          ),
        );
      }

      if (!/[A-Z]/.test(value)) {
        return Promise.reject(
          new Error(
            getIntl().formatMessage({
              defaultMessage: 'Hasło musi zawierać minimum 1 dużą literę',
            }),
          ),
        );
      }

      if (!/[a-z]/.test(value)) {
        return Promise.reject(
          new Error(
            getIntl().formatMessage({
              defaultMessage: 'Hasło musi zawierać minimum 1 małą literę',
            }),
          ),
        );
      }

      if (!/[0-9]/.test(value)) {
        return Promise.reject(
          new Error(
            getIntl().formatMessage({
              defaultMessage: 'Hasło musi zawierać minimum 1 cyfrę',
            }),
          ),
        );
      }

      return Promise.resolve();
    },
  });
};

export const validatePasswordConfirmationRule = () => {
  return ({ getFieldValue }: GetFieldValue) => ({
    validator(_: RuleObject, value: string) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error(
          getIntl().formatMessage({
            defaultMessage: 'Hasła muszą być takie same',
          }),
        ),
      );
    },
  });
};

export const validatePhoneNumberRule = () => {
  return () => ({
    validator(_: RuleObject, phoneNumber: string) {
      if (!phoneNumber) {
        return Promise.resolve();
      }

      if (!PHONE_REGEX.test(phoneNumber)) {
        return Promise.reject(
          getIntl().formatMessage({
            defaultMessage: 'Wprowadź poprawny numer telefonu',
          }),
        );
      }

      return Promise.resolve();
    },
  });
};

export const validateNipRule = ({
  isInternational = false,
}: ValidateNipRuleProps = {}) => {
  return () => ({
    validator(_: RuleObject, value: string) {
      if (!value) {
        return Promise.resolve();
      }

      const nipRegex = isInternational ? INTERNATIONAL_NIP_REGEX : NIP_REGEX;

      if (!nipRegex.test(value)) {
        if (isInternational) {
          return Promise.reject(
            getIntl().formatMessage({
              defaultMessage: 'Nieprawidłowy numer NIP',
            }),
          );
        }

        return Promise.reject(
          getIntl().formatMessage(
            {
              defaultMessage: 'Numer NIP musi zawierać ciąg {max} cyfr',
            },
            { max: MAX_NIP_LENGTH },
          ),
        );
      }

      if (!isValidNip(value, isInternational)) {
        return Promise.reject(
          getIntl().formatMessage({
            defaultMessage: 'Nieprawidłowy numer NIP',
          }),
        );
      }

      return Promise.resolve();
    },
  });
};

export const validateRegonRule = ({
  isInternational = false,
}: ValidateRegonRuleArgs = {}) => {
  return () => ({
    validator(_: RuleObject, value: string) {
      if (!value || isInternational) {
        return Promise.resolve();
      }

      if (!REGON_REGEX.test(value)) {
        return Promise.reject(
          getIntl().formatMessage(
            {
              defaultMessage: 'Numer REGON musi zawierać ciąg {max} cyfr',
            },
            { max: MAX_REGON_LENGTH },
          ),
        );
      }

      if (!isValidRegon(value)) {
        return Promise.reject(
          getIntl().formatMessage({
            defaultMessage: 'Nieprawidłowy numer REGON',
          }),
        );
      }

      return Promise.resolve();
    },
  });
};

export const validatePeselRule = () => {
  return () => ({
    validator(_: RuleObject, value: string) {
      if (!value) {
        return Promise.resolve();
      }

      if (!PESEL_REGEX.test(value)) {
        return Promise.reject(
          getIntl().formatMessage(
            {
              defaultMessage: 'Numer PESEL musi zawierać ciąg {max} cyfr',
            },
            {
              max: MAX_PESEL_LENGTH,
            },
          ),
        );
      }

      if (!isValidPesel(value)) {
        return Promise.reject(
          getIntl().formatMessage({
            defaultMessage: 'Nieprawidłowy numer PESEL',
          }),
        );
      }

      return Promise.resolve();
    },
  });
};

export const validateDiscountRule = () => {
  return () => ({
    validator(_: RuleObject, discount: number) {
      if (!discount) {
        return Promise.resolve();
      }

      if (discount < 0 || discount > 100) {
        return Promise.reject(
          getIntl().formatMessage({
            defaultMessage: 'Rabat musi być w przedziale od 0 do 100',
          }),
        );
      }

      return Promise.resolve();
    },
  });
};

export const validateMinAndMaxSelectRule = (min = 1, max = 5) => {
  return () => ({
    validator(_: RuleObject, value: (string | number)[] | undefined) {
      if (!value || !value.length) {
        return Promise.resolve();
      }

      if (value.length < min) {
        return Promise.reject(
          getIntl().formatMessage(
            {
              defaultMessage: 'Minimum {min} opcje',
            },
            { min },
          ),
        );
      }

      if (value.length > max) {
        return Promise.reject(
          new Error(
            getIntl().formatMessage(
              {
                defaultMessage: 'Maksymalnie można wybrać {max} opcje',
              },
              { max },
            ),
          ),
        );
      }

      return Promise.resolve();
    },
  });
};

export const validatePostalCodeRule = () => {
  return () => ({
    validator(_: RuleObject, value: string) {
      if (!value) {
        return Promise.resolve();
      }

      if (!POSTAL_CODE_REGEX.test(value)) {
        return Promise.reject(
          getIntl().formatMessage({
            defaultMessage: 'Wprowadź poprawny kod pocztowy',
          }),
        );
      }

      return Promise.resolve();
    },
  });
};

export const validateUrlRule = () => {
  return () => ({
    validator(_: RuleObject, value: string) {
      if (!value) {
        return Promise.resolve();
      }

      if (!URL_REGEX.test(value)) {
        return Promise.reject(
          getIntl().formatMessage({
            defaultMessage: 'Podaj prawidłowy adres URL',
          }),
        );
      }

      return Promise.resolve();
    },
  });
};

export const validateMultipleEmails = () => {
  return () => ({
    validator(_: RuleObject, value: string) {
      if (!value) {
        return Promise.resolve();
      }

      if (/\s/g.test(value)) {
        return Promise.reject(
          getIntl().formatMessage({
            defaultMessage: 'Usuń spacje.',
          }),
        );
      }

      const emails: string[] = value.split(',');

      const emailsAreValid = emails.reduce((prevVal, curVal, i, arr) => {
        const trimmedEmail: string = curVal.trim();

        if (EMAIL_REGEX.test(trimmedEmail)) {
          return true;
        }
        arr.splice(1);
        return false;
      }, true);

      if (!emailsAreValid) {
        return Promise.reject(
          getIntl().formatMessage({
            defaultMessage:
              'Któryś z podanych adresów e-mail jest niepoprawny.',
          }),
        );
      }

      return Promise.resolve();
    },
  });
};
