/* eslint-disable no-param-reassign */
import type { InvoicesAddSliceState } from './type';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState: InvoicesAddSliceState = {
  orderIds: [],
  selectedOrderIds: [],
};

const invoicesAddSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoicesOrderIds: (
      state,
      { payload }: PayloadAction<InvoicesAddSliceState['orderIds']>,
    ) => {
      state.orderIds = payload;
      state.selectedOrderIds = payload;
    },
    selectInvoicesOrderIds: (state, { payload }: PayloadAction<number>) => {
      const isAlreadySelected = state.selectedOrderIds.some(
        (id) => id === payload,
      );

      if (isAlreadySelected) {
        state.selectedOrderIds = state.selectedOrderIds.filter(
          (id) => id !== payload,
        );

        return;
      }

      state.selectedOrderIds.push(payload);
    },
    resetInvoicesAddState: () => initialState,
  },
});

export const {
  setInvoicesOrderIds,
  selectInvoicesOrderIds,
  resetInvoicesAddState,
} = invoicesAddSlice.actions;

export default invoicesAddSlice.reducer;
