import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { EditTranslatorOrderRateRequest } from 'ducks/translators/types';
import { Rate } from 'antd';
import { FormattedMessage } from 'react-intl';

export type EditTranslatorOrderRateModalValues = Pick<
  EditTranslatorOrderRateRequest,
  'punctuality' | 'price' | 'content' | 'technical' | 'contact'
>;

export const initialValues: EditTranslatorOrderRateModalValues = {
  punctuality: 1,
  price: 1,
  content: 1,
  technical: 1,
  contact: 1,
};

export const formItems: FormItemRecord<EditTranslatorOrderRateModalValues>[] = [
  {
    key: 'punctuality',
    label: <FormattedMessage defaultMessage="Terminowość:" />,
    name: 'punctuality',
    component: <Rate allowClear={false} />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz ocenę" />,
      },
    ],
  },
  {
    key: 'price',
    label: <FormattedMessage defaultMessage="Cena:" />,
    name: 'price',
    component: <Rate allowClear={false} />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz ocenę" />,
      },
    ],
  },
  {
    key: 'content',
    label: <FormattedMessage defaultMessage="Merytoryczność:" />,
    name: 'content',
    component: <Rate allowClear={false} />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz ocenę" />,
      },
    ],
  },
  {
    key: 'technical',
    label: <FormattedMessage defaultMessage="Umiejętności techniczne:" />,
    name: 'technical',
    component: <Rate allowClear={false} />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz ocenę" />,
      },
    ],
  },
  {
    key: 'contact',
    label: <FormattedMessage defaultMessage="Kontakt:" />,
    name: 'contact',
    component: <Rate allowClear={false} />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz ocenę" />,
      },
    ],
  },
];
