/* eslint-disable react-hooks/exhaustive-deps */
import { Collapse } from 'antd';
import React, {
  Fragment,
  createRef,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import Empty from 'components/ui/atoms/Empty';
import Loader from 'components/ui/atoms/Loader';
import CardCommon from 'components/ui/molecules/CardCommon';
import PreorderVariantTable from 'components/ui/organisms/Tables/PreorderVariantTable';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  setPreorderVariants,
  setTriggerScrollToVariant,
} from 'ducks/view/preorders';
import { scrollToRefs } from 'utilities/scrollToElement';
import { usePreorderDetailsServices } from '../../usePreorderDetailsServices';
import AllFilesModal from './components/AllFilesModal';
import VariantPanelHeader from './components/VariantPanelHeader';
import styles from './styles.module.scss';

const VariantsSection = memo(() => {
  const dispatch = useAppDispatch();
  const { isEditable, variants, scrollToVariant } = useAppSelector(
    ({ preorders }) => ({
      isEditable: preorders.isEditable,
      variants: preorders.variants,
      scrollToVariant: preorders.scrollToVariant,
    }),
    shallowEqual,
  );
  const [activeKeys, setActiveKeys] = useState<string | string[]>([]);
  const [isAllFilesModalVariantId, setAllFilesModalVariantId] = useState<
    string | number | null
  >(null);
  const { preorderVariantsData, isPreorderVariantsLoading } =
    usePreorderDetailsServices();
  const isEmpty = !variants.length && !isPreorderVariantsLoading;
  const defaultActiveKey = variants.length === 1 ? variants[0].id : [];

  const handleOpenAllFilesModal = (variantId: string | number) =>
    setAllFilesModalVariantId(variantId);

  const handleCloseAllFilesModal = () => setAllFilesModalVariantId(null);

  useEffect(() => {
    if (preorderVariantsData.length) {
      dispatch(setPreorderVariants(preorderVariantsData));
    }
  }, [preorderVariantsData]);

  // this section is for auto scroll (from header)
  const myRefs = useRef([]);
  myRefs.current = variants.map(
    (item, index) => myRefs.current[index] ?? createRef(),
  );

  useEffect(() => {
    setActiveKeys((prevValue) =>
      prevValue.includes(scrollToVariant.id)
        ? prevValue
        : [...prevValue, scrollToVariant.id],
    );

    const timeout = setTimeout(() => {
      scrollToRefs(myRefs, isPreorderVariantsLoading, scrollToVariant.index);
      // this value change enables you to use scroll mechanism again
      // when you scroll somewhere else by yourself
      dispatch(setTriggerScrollToVariant({ id: '', index: -1 }));
    }, 500);

    return () => clearTimeout(timeout);
  }, [
    isPreorderVariantsLoading,
    myRefs,
    scrollToVariant.index,
    scrollToVariant.id,
  ]);

  return (
    <>
      {isEditable && (
        <AllFilesModal
          variantId={isAllFilesModalVariantId}
          onClose={handleCloseAllFilesModal}
        />
      )}
      <Loader visible={isPreorderVariantsLoading}>
        <CardCommon
          data-testid="variantsSection"
          title={<FormattedMessage defaultMessage="Warianty" />}
        >
          {isEmpty ? (
            <Empty
              description={<FormattedMessage defaultMessage="Brak wariantów" />}
            />
          ) : (
            <Collapse
              className={styles.collapse}
              bordered={false}
              defaultActiveKey={defaultActiveKey}
              onChange={(x) => setActiveKeys(x)}
              activeKey={activeKeys}
            >
              {variants.map((variant, index) => (
                <Fragment key={variant.id}>
                  <span ref={myRefs.current[index]} />
                  <Collapse.Panel
                    key={variant.id}
                    header={
                      <VariantPanelHeader
                        variant={variant}
                        index={isEditable ? index + 1 : variant.variantIdx}
                        openAllFilesModal={() =>
                          handleOpenAllFilesModal(variant.id)
                        }
                      />
                    }
                  >
                    <PreorderVariantTable key={variant.id} variant={variant} />
                  </Collapse.Panel>
                </Fragment>
              ))}
            </Collapse>
          )}
        </CardCommon>
      </Loader>
    </>
  );
});

export default VariantsSection;
