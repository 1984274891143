import type { CreatePreorderValues } from './config';
import type { Client } from 'ducks/clients/types';
import { Button, Card, Form, Space, notification } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Loader from 'components/ui/atoms/Loader';
import ClientSelector from 'components/ui/organisms/ClientSelector';
import DuplicateClientsModal from 'components/ui/organisms/Modals/DuplicateClientsModal';
import { useCheckDuplicatesMutation } from 'ducks/clients/service';
import { useAddPreorderMutation } from 'ducks/preorders/service';
import { Routes } from 'views/Preorders/Routing';
import { formItems, initialValues } from './config';

const { useForm } = Form;

const PreorderCreate = () => {
  const [notify, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [form] = useForm<CreatePreorderValues>();
  const [duplicatedClients, setDuplicatedClients] = useState<Client[]>([]);
  const [checkDuplicates, { isLoading: isCheckDuplicatesLoading }] =
    useCheckDuplicatesMutation();
  const [addPreorders, { isLoading: isAddLoading }] = useAddPreorderMutation();
  const isLoading = isCheckDuplicatesLoading || isAddLoading;

  const handleCreatePreorder = async (selectedClient?: Client) => {
    if (isLoading) return;

    setDuplicatedClients([]);

    try {
      const values = await form.validateFields();

      const response = await addPreorders({
        ...values,
        clientId: selectedClient ? selectedClient.id : values.clientId,
      }).unwrap();

      form.resetFields();
      navigate(Routes.PREORDER_DETAILS.replace(':id', response.id.toString()));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.errorFields) return;

      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania wyceny." />
        ),
      });
    }
  };

  const handleCheckIfClientExist = async () => {
    if (isLoading) return;

    try {
      const values = await form.validateFields();

      const res = await checkDuplicates({
        name: values.contactPerson,
        email1: values.email,
      }).unwrap();

      if (Array.isArray(res)) {
        setDuplicatedClients(res);

        return;
      }

      handleCreatePreorder();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.errorFields) return;

      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania wyceny." />
        ),
      });
    }
  };

  return (
    <div className="layout-content mb-24">
      <DuplicateClientsModal
        isOpen={!!duplicatedClients.length}
        duplicateClients={duplicatedClients}
        onClose={() => setDuplicatedClients([])}
        onClientClick={handleCreatePreorder}
        onAccept={handleCreatePreorder}
      />
      <Card bordered={false}>
        <h6>
          <FormattedMessage defaultMessage="Klient" />
        </h6>
        {contextHolder}
        <Loader visible={isLoading}>
          <Form
            form={form}
            onFinish={handleCheckIfClientExist}
            layout="vertical"
            initialValues={initialValues}
            autoComplete="off"
          >
            <Space className="width-100" size="large">
              {formItems.map(({ key, ...props }) => (
                <FormItem key={key} {...props} />
              ))}
            </Space>

            <Space direction="vertical" className="width-100" size="middle">
              <FormattedMessage defaultMessage="Przypisz wycenę do wybranego konta klienta" />
              <ClientSelector form={form} />
              <FormattedMessage defaultMessage="osoba kontaktowa zostanie dopisana do tego konta klienta" />
            </Space>
            <Space className="width-100" style={{ justifyContent: 'end' }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                <FormattedMessage defaultMessage="Dodaj" />
              </Button>
            </Space>
          </Form>
        </Loader>
      </Card>
    </div>
  );
};

export default PreorderCreate;
