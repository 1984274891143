import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { useLazyGetPreorderZipFilesQuery } from 'ducks/preorders/service';
import { downloadFile } from 'utilities/downloadFile';

const FILE_EXTENSION = '.zip';

type Props = {
  notify: NotificationInstance;
  preorderId: number | string | undefined;
  fileName: string;
};

const DownloadPreorderZipFilesButton: FC<Props> = ({
  notify,
  preorderId,
  fileName,
}) => {
  const [getPreorderZipFiles, { isLoading }] =
    useLazyGetPreorderZipFilesQuery();

  const handleDownloadZip = async () => {
    if (!preorderId || isLoading) return;

    try {
      const response = await getPreorderZipFiles({
        preorderId,
      }).unwrap();

      const url = URL.createObjectURL(response as File);

      downloadFile(url, `${fileName}${FILE_EXTENSION}`);
    } catch (err) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Nie znaleziono pliku lub wystąpił błąd." />
        ),
      });
    }
  };

  return (
    <Button type="default" onClick={handleDownloadZip} loading={isLoading}>
      <FormattedMessage defaultMessage="Pobierz jako ZIP" />
    </Button>
  );
};

export default DownloadPreorderZipFilesButton;
