import type { ListItemRecord } from 'components/ui/molecules/List';
import type { TranslatorTitle } from 'constants/translatorTitles';
import type { Translator } from 'ducks/translators/types';
import type { IntlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import FormatDate from 'components/ui/atoms/FormatDate';
import LanguageName from 'components/ui/atoms/LanguageName';
import { genders } from 'constants/genders';

export const basicInfoCardListItems = ({
  intl,
  data,
  title,
}: {
  intl: IntlShape;
  data: Translator | undefined;
  title: TranslatorTitle | undefined;
}): ListItemRecord[] => [
  {
    label: <FormattedMessage defaultMessage="Imię:" />,
    description: data?.firstName,
  },
  {
    label: <FormattedMessage defaultMessage="Nazwisko:" />,
    description: data?.lastName,
  },
  {
    label: <FormattedMessage defaultMessage="Płeć:" />,
    description: genders(intl).find(({ value }) => value === data?.gender)
      ?.label,
  },
  {
    label: <FormattedMessage defaultMessage="Tytuł:" />,
    description: title?.label,
  },
  {
    label: <FormattedMessage defaultMessage="Język ojczysty:" />,
    description: <LanguageName countryCode={data?.langNative ?? ''} />,
  },
  {
    label: <FormattedMessage defaultMessage="Umowa:" />,
    component: <CircleCheckbox value={!!data?.hasAgreement} />,
    skeletonRows: 1,
    description: '',
  },
  {
    label: <FormattedMessage defaultMessage="Rejestracja:" />,
    description: data?.createdAt && <FormatDate date={data.createdAt} />,
  },
];
