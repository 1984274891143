import type { ColProps } from 'antd';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { Col } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import Notes from 'components/ui/organisms/Notes';
import { NoteType } from 'ducks/notes/types';
import { scrollToElement } from 'utilities/scrollToElement';
import { useUnblockOrder } from 'views/Orders/useUnblockOrder';
import { useOrderDetailsServices } from 'views/Orders/views/OrderDetails';

const NOTES_ID = 'notes';

type Props = {
  notify: NotificationInstance;
  colProps?: ColProps;
  scroll?: boolean;
};

const OrderNotesSection: FC<Props> = ({ notify, colProps, scroll = true }) => {
  const { id = '' } = useParams();
  const location = useLocation();
  const { orderData, isOrderLoading } = useOrderDetailsServices();
  const { handleUnblock, isLoading: isUnblockLoading } = useUnblockOrder({
    notify,
  });
  const isLoading = isOrderLoading || isUnblockLoading;

  useEffect(() => {
    if (!location.state?.scrollToNotes && !scroll) return undefined;

    const timeout = setTimeout(() => {
      scrollToElement(NOTES_ID);
    }, 500);

    return () => clearTimeout(timeout);
  }, [location.state?.scrollToNotes, scroll]);

  return (
    <Col
      data-testid="notesSection"
      className="width-100"
      xs={24}
      lg={12}
      {...colProps}
    >
      <span id={NOTES_ID} />
      <Notes
        notify={notify}
        objectId={Number(id)}
        noteType={NoteType.ORDERS}
        title={<FormattedMessage defaultMessage="Notatki do zlecenia" />}
        isLoading={isLoading}
        skip={!orderData}
        onSave={handleUnblock}
        isSaveLoading={isUnblockLoading}
      />
    </Col>
  );
};

export default OrderNotesSection;
