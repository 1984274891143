import type { InvoiceFinalRow } from './config';
import type { GetInvoicesVatFilter } from 'ducks/invoices/types';
import { Space } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Table from 'components/ui/molecules/Table';
import { useGetInvoicesFinalQuery } from 'ducks/invoices/service';
import useTablePagination from 'hooks/useTablePagination';
import InvoicesTableToolbar from 'views/Invoices/components/InvoicesTableToolbar';
import { initialInvoicesFilters } from 'views/Invoices/config';
import { columns } from './config';

const InvoicesFinal = () => {
  const intl = useIntl();
  const {
    page,
    handlePageChange,
    searchTerm,
    searchValue,
    handleSearchValue,
    filter,
    filterBy,
    handleFilterBy,
  } = useTablePagination<InvoiceFinalRow, GetInvoicesVatFilter>({
    initialFilters: initialInvoicesFilters,
  });
  const { data, isFetching } = useGetInvoicesFinalQuery({
    page,
    searchTerm,
    filter,
  });
  const pageSize = data?.perPage;
  const total = isFetching ? 0 : data?.total;

  const modifiedData = useMemo(
    (): InvoiceFinalRow[] =>
      (data?.data ?? []).map((invoiceOverdue) => ({
        ...invoiceOverdue,
        key: invoiceOverdue.id,
      })),
    [data],
  );

  return (
    <Space className="layout-content mb-24 width-100" direction="vertical">
      <InvoicesTableToolbar
        searchValue={searchValue}
        onSearchValueChange={handleSearchValue}
        filterBy={filterBy}
        onFilterByChange={handleFilterBy}
      />
      <Table
        cardTitle={<FormattedMessage defaultMessage="Faktury końcowe" />}
        data={modifiedData}
        columns={columns({ intl })}
        isLoading={isFetching}
        pagination={{
          current: page,
          pageSize,
          total,
          onChange: handlePageChange,
        }}
      />
    </Space>
  );
};

export default InvoicesFinal;
