import type { DebtCollections } from 'ducks/invoices/types';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import PopoverDotCell from 'components/ui/atoms/TableCells/PopoverDotCell';
import { DayjsFormat } from 'constants/dayjsFormats';
import { NO_DATA } from 'constants/strings';
import { DebtCollectionsStatus } from 'ducks/invoices/types';

type Props = { debtCollections: DebtCollections | null };

const DebtCollectionsCell: FC<Props> = ({ debtCollections }) => {
  return (
    <PopoverDotCell
      data-testid="debtCollectionsCell"
      content={
        !!debtCollections && (
          <Space direction="vertical">
            <FormattedMessage
              defaultMessage="Przyjęte: {date}"
              values={{
                date: debtCollections.dateReceived
                  ? dayjs(debtCollections.dateReceived).format(DayjsFormat.DATE)
                  : NO_DATA,
              }}
            />
            <FormattedMessage
              defaultMessage="Numer: {number}"
              values={{ number: debtCollections.number ?? NO_DATA }}
            />
            <FormattedMessage
              defaultMessage="Koszt: {price}"
              values={{
                price: debtCollections.price ? (
                  <CurrencyFormatter value={debtCollections.price} />
                ) : (
                  NO_DATA
                ),
              }}
            />
            {debtCollections.status === DebtCollectionsStatus.COMPLETED ? (
              <FormattedMessage defaultMessage="Zakończone: TAK" />
            ) : (
              <FormattedMessage defaultMessage="Zakończone: NIE" />
            )}
          </Space>
        )
      }
    />
  );
};

export default DebtCollectionsCell;
