import type { VariantServiceRow } from '../../config';
import type { FC } from 'react';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import FormItem from 'components/ui/atoms/FormItem';
import { ServiceAttributeNames } from 'constants/services';
import { NO_DATA } from 'constants/strings';
import { useAppSelector } from 'ducks/store';
import { getPreorderServiceInitialValue } from 'ducks/view/preorders/utils';

const ATTRIBUTE_NAME = ServiceAttributeNames.SIMPLE_PAGES_NUMBER;

type Props = { row: VariantServiceRow };

const SimplePagesNumberInput: FC<Props> = ({
  row: { id, preorderVariantServiceValues },
}) => {
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
  const initialValue = getPreorderServiceInitialValue(
    preorderVariantServiceValues,
    ATTRIBUTE_NAME,
  );

  return isEditable ? (
    <FormItem
      name={[id as number, ['attributeName', ATTRIBUTE_NAME].join(',')]}
      component={
        <DecimalInputNumber
          data-testid="simplePagesNumberInput"
          allowNullable={false}
        />
      }
      additionalItemProps={{
        initialValue: initialValue ?? 0,
      }}
    />
  ) : (
    <span data-testid="simplePagesNumber">{initialValue ?? NO_DATA}</span>
  );
};

export default SimplePagesNumberInput;
