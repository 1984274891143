import type { NotificationInstance } from 'antd/es/notification/interface';
import type { Client } from 'ducks/clients/types';
import type { FC } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import List from 'components/ui/molecules/List';
import CardCommon from '../CardCommon';
import EditCorrespondenceModal from './components/EditCorrespondenceModal';
import { correspondenceListItems } from './config';

interface Props {
  data: Client | undefined;
  isLoading: boolean;
  notify: NotificationInstance;
}

const CorrespondenceForm: FC<Props> = ({ data, isLoading, notify }) => {
  const [isEditCorrespondenceModalOpen, setIsEditCorrespondenceModalOpen] =
    useState(false);

  return (
    <>
      {data && (
        <EditCorrespondenceModal
          isOpen={isEditCorrespondenceModalOpen}
          onClose={() => setIsEditCorrespondenceModalOpen(false)}
          data={data}
          notify={notify}
        />
      )}
      <CardCommon
        title={<FormattedMessage defaultMessage="Dane korespondencyjne" />}
        isLoading={isLoading}
        openEditModal={() => setIsEditCorrespondenceModalOpen(true)}
      >
        <List items={correspondenceListItems(data)} isLoading={isLoading} />
      </CardCommon>
    </>
  );
};

export default CorrespondenceForm;
