import { Button, Typography } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { PreorderStatus } from 'constants/preorder';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  setTriggerFormSubmit,
  setTriggerScrollToVariant,
} from 'ducks/view/preorders';
import { Routes } from 'views/Preorders/Routing';
import PreordersHeaderInformations from './PreordersHeaderInformations';
import styles from './styles.module.scss';
import { usePreordersHeaderServices } from './usePreordersHeaderServices';

type HandleTriggerScroll = {
  id: string;
  index: number;
};

const { Text } = Typography;

const ProrderHeader = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { variants, scrollToVariant } = useAppSelector(
    ({ preorders }) => ({
      variants: preorders.variants,
      scrollToVariant: preorders.scrollToVariant,
    }),
    shallowEqual,
  );
  const isEditMode = pathname.includes('edit');
  const { preorderId, preorderData, isPreorderFetching } =
    usePreordersHeaderServices();

  const handleTriggerSubmit = () => dispatch(setTriggerFormSubmit(true));

  const handleTriggerScroll = ({ id, index }: HandleTriggerScroll) =>
    dispatch(setTriggerScrollToVariant({ index, id }));

  const variantsScrollLabels = useMemo(() => {
    if (!variants.length) return [];

    return variants.map(({ id }, index) => ({
      label: `W${index + 1}`,
      id,
    }));
  }, [variants]);

  if (!preorderData) return null;

  return (
    <div className={styles.header}>
      <PreordersHeaderInformations />
      {variants.length > 1 && (
        <div className={styles.variantsBox}>
          {variantsScrollLabels.map(({ label, id }, index) => {
            const isScrollingToVariant = scrollToVariant.id === id.toString();
            const comma = index !== variantsScrollLabels.length - 1 ? ',' : '';

            return (
              <Button
                key={id}
                className={styles.variantButton}
                type="link"
                onClick={() =>
                  handleTriggerScroll({ index, id: id.toString() })
                }
                loading={isScrollingToVariant}
              >
                <Text>
                  {label}
                  {comma}
                </Text>
              </Button>
            );
          })}
        </div>
      )}
      {preorderData.status !== PreorderStatus.IN_REALIZATION && (
        <div className={styles.column}>
          {isEditMode ? (
            <Button
              onClick={handleTriggerSubmit}
              type="primary"
              loading={isPreorderFetching}
            >
              <FormattedMessage defaultMessage="Zapisz" />
            </Button>
          ) : (
            <Link to={Routes.PREORDER_EDIT.replace(':id', String(preorderId))}>
              <Button type="primary">
                <FormattedMessage defaultMessage="Edytuj" />
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default ProrderHeader;
