import { Route } from 'react-router-dom';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import TranslatorPrice from './views/TranslatorPrice';
import TranslatorProfile from './views/TranslatorProfile';
import TranslatorProfileForm from './views/TranslatorProfileForm';
import TranslatorQualification from './views/TranslatorQualification';
import TranslatorsTable from './views/TranslatorsTable';

export enum Routes {
  TRANSLATORS = '/',
  TRANSLATOR_PROFILE = '/translators/:id',
  TRANSLATOR_PROFILE_FORM = '/translators/:id/form',
  TRANSLATOR_PROFILE_PRICE = '/translators/:id/price',
  TRANSLATOR_PROFILE_QUALIFICATION = '/translators/:id/qualification',
}

const TranslatorsRouting = () => {
  return (
    <AnimatedRoutes pathLevelWatch={2}>
      <Route
        path={`${Routes.TRANSLATOR_PROFILE.replace('/translators', '')}`}
        element={<TranslatorProfile />}
      />
      <Route
        path={`${Routes.TRANSLATOR_PROFILE_FORM.replace('/translators', '')}`}
        element={<TranslatorProfileForm />}
      />
      <Route
        path={`${Routes.TRANSLATOR_PROFILE_PRICE.replace('/translators', '')}`}
        element={<TranslatorPrice />}
      />
      <Route
        path={`${Routes.TRANSLATOR_PROFILE_QUALIFICATION.replace(
          '/translators',
          '',
        )}`}
        element={<TranslatorQualification />}
      />
      <Route path={`${Routes.TRANSLATORS}`} element={<TranslatorsTable />} />
    </AnimatedRoutes>
  );
};

export default TranslatorsRouting;
