import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { PreorderSendValues } from 'views/Preorders/views/PreorderSend/config';
import { FormattedMessage } from 'react-intl';
import { validateMultipleEmails } from 'utilities/antdFormRules';

export type SendEmailFormValues = Omit<
  PreorderSendValues,
  'isPresentation' | 'isReferenceFile'
>;

interface Props {
  isRequired: boolean;
}

export const formItems = ({
  isRequired,
}: Props): FormItemRecord<SendEmailFormValues>[] => [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Temat" />,
    name: 'subject',
    rules: [
      {
        required: isRequired,
        message: (
          <FormattedMessage defaultMessage="Uzupełnij temat wiadomości" />
        ),
      },
    ],
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="E-mail" />,
    name: 'email',
    rules: [
      {
        required: isRequired,
        message: <FormattedMessage defaultMessage="Uzupełnij adres e-mail" />,
      },
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
  {
    key: 3,
    label: <FormattedMessage defaultMessage="E-mail dla kopii wiadomości" />,
    name: 'cc',
    rules: [validateMultipleEmails()],
  },
];
