/* eslint-disable react/jsx-props-no-spreading */
import type { EditTranslatorValues } from '../../config';
import type { FormInstance } from 'antd';
import type { FC } from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { POST_EDIT_VALUE } from 'constants/cats';
import { useGetTranslatorsTypesQuery } from 'ducks/translators/service';

interface Props {
  form: FormInstance<EditTranslatorValues>;
}

const { Item, useWatch } = Form;

const TranslatorTypeSection: FC<Props> = ({ form }) => {
  const { data = [] } = useGetTranslatorsTypesQuery();
  const cats = useWatch('cats', form) ?? [];
  const isPostEditDisabled = !cats.includes(POST_EDIT_VALUE);

  useEffect(() => {
    if (Array.isArray(cats)) {
      if (!cats.includes(POST_EDIT_VALUE)) {
        const types =
          (form.getFieldValue('types') as EditTranslatorValues['types']) ?? [];

        form.setFieldValue(
          'types',
          types.filter((value) => value !== POST_EDIT_VALUE),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cats]);

  return (
    <Col span={24} md={12} lg={12} xl={12}>
      <>
        <h6>
          <FormattedMessage defaultMessage="Typ tłumacza" />
        </h6>
        <Item name="types">
          <Checkbox.Group>
            <Row>
              {data.map(
                ({ id, isHidden, name }) =>
                  !isHidden && (
                    <Col key={id} span={12}>
                      <Checkbox
                        value={id}
                        disabled={id === POST_EDIT_VALUE && isPostEditDisabled}
                      >
                        {name}
                      </Checkbox>
                    </Col>
                  ),
              )}
            </Row>
          </Checkbox.Group>
        </Item>
      </>
    </Col>
  );
};

export default TranslatorTypeSection;
