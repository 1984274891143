import type { PaymentRow } from './config';
import type { AddPaymentValues } from 'components/ui/organisms/Modals/AddPaymentModal';
import type { PaymentObject } from 'ducks/payments/types';
import type { FC } from 'react';
import { notification } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import Table from 'components/ui/molecules/Table';
import AddPaymentModal from 'components/ui/organisms/Modals/AddPaymentModal';
import { useGetPaymentsQuery } from 'ducks/payments/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setTriggerScrollToPayments } from 'ducks/view/preorders';
import { PlusIcon } from 'icons';
import { scrollToRef } from 'utilities/scrollToElement';
import { columns } from './config';

type Props = {
  id: number | string;
  paymentObject: PaymentObject;
  isLoading?: boolean;
  initialValues?: Partial<AddPaymentValues>;
  skip?: boolean;
  onSave?: () => Promise<void>;
  isSaveLoading?: boolean;
};

const PaymentsTable: FC<Props> = ({
  id,
  paymentObject,
  isLoading: isInitialLoading = false,
  initialValues,
  skip = false,
  onSave,
  isSaveLoading = false,
}) => {
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const triggerScrollToPayments = useAppSelector(
    ({ preorders }) => preorders.triggerScrollToPayments,
  );
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const { data = [], isFetching: isPaymentsFetching } = useGetPaymentsQuery(
    {
      paymentObject,
      id: Number(id),
    },
    {
      skip,
    },
  );
  const isLoading = isPaymentsFetching || isInitialLoading;

  const modifiedData = useMemo(
    (): PaymentRow[] =>
      data.map(({ id: rowId, ...row }) => ({
        key: rowId,
        id: rowId,
        ...row,
      })),
    [data],
  );

  useEffect(() => {
    if (!ref || !triggerScrollToPayments) return;

    scrollToRef(false, ref);
    dispatch(setTriggerScrollToPayments(false));
  }, [isLoading, triggerScrollToPayments, dispatch]);

  return (
    <>
      <span ref={ref} />
      {contextHolder}
      {!skip && (
        <AddPaymentModal
          id={Number(id)}
          paymentObject={paymentObject}
          isOpen={isPaymentModalOpen}
          onClose={() => setIsPaymentModalOpen(false)}
          notify={notify}
          initialValues={initialValues}
          onSave={onSave}
          isSaveLoading={isSaveLoading}
        />
      )}
      <Table
        data-testid="paymentTable"
        data={modifiedData}
        columns={columns({ intl })}
        isLoading={isLoading}
        cardTitle={<FormattedMessage defaultMessage="Historia płatności" />}
        cardTitleExtra={
          <Button
            onClick={() => setIsPaymentModalOpen(true)}
            icon={<PlusIcon />}
            loading={isLoading}
          >
            <FormattedMessage defaultMessage="Dodaj płatność" />
          </Button>
        }
      />
    </>
  );
};

export default PaymentsTable;
