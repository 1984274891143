/* eslint-disable react/jsx-props-no-spreading */
import type { EditContactFormValues } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { Client } from 'ducks/clients/types';
import type { FC } from 'react';
import { Form, Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal/Modal';
import { useUpdateClientContactMutation } from 'ducks/clients/service';
import { editContactFormInputs } from './config';
import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: Client;
  notify: NotificationInstance;
}

const { Item, useForm } = Form;

const EditContactModal: FC<Props> = ({ isOpen, onClose, data, notify }) => {
  const [form] = useForm<EditContactFormValues>();
  const [updateClientContact, { isLoading }] = useUpdateClientContactMutation();
  const formInputs = [
    {
      inputs: editContactFormInputs.slice(0, 2),
      radioItemName: 'emailDefault',
    },
    {
      inputs: editContactFormInputs.slice(2, 4),
      radioItemName: 'phoneDefault',
    },
  ];
  const initialValues: EditContactFormValues = data;

  const handleOnSubmit = async () => {
    if (isLoading) return;

    try {
      const values = await form.validateFields();

      await updateClientContact({
        ...values,
        clientId: data.id,
      }).unwrap();

      notify.success({
        message: (
          <FormattedMessage defaultMessage="Dane kontaktowe zostały edytowane." />
        ),
      });
      onClose();
      form.setFieldsValue(values);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.errorFields) return;

      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania danych kontaktowych." />
        ),
      });
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Edytuj kontakt" />}
      isLoading={isLoading}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={handleClose}
      okText={<FormattedMessage defaultMessage="Zapisz" />}
      disableFormStyles
      className={styles.form}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        {formInputs.map(({ inputs, radioItemName }) => (
          <Item key={radioItemName} name={radioItemName} style={{ margin: 0 }}>
            <Radio.Group style={{ width: '100%' }}>
              {inputs.map(({ key, ...props }) => (
                <FormItem key={key} {...props} />
              ))}
            </Radio.Group>
          </Item>
        ))}
      </Form>
    </Modal>
  );
};

export default EditContactModal;
