import type { ColumnsType } from 'antd/es/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { TranslatorsOrders } from 'ducks/translators/types';
import { FormattedMessage } from 'react-intl';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import FormatDate from 'components/ui/atoms/FormatDate';
import { FileTranslationType } from 'constants/fileTranslation';
import { NO_DATA } from 'constants/strings';
import OrderRatesCol from './components/OrderRatesCol';

export type OrderRow = TableRow & TranslatorsOrders;

export const columns: ColumnsType<OrderRow> = [
  {
    key: 'dateRealization',
    dataIndex: 'dateRealization',
    title: (
      <FormattedMessage defaultMessage="Data przyjęcia zlecenia przez tłumacza" />
    ),
    render: (_, { dateRealization }) =>
      dateRealization ? <FormatDate date={dateRealization} /> : NO_DATA,
  },
  {
    key: 'orderNumber',
    dataIndex: 'orderNumber',
    title: <FormattedMessage defaultMessage="Numer zlecenia" />,
  },
  {
    key: 'translatorPriceNetto',
    dataIndex: 'translatorPriceNetto',
    title: (
      <FormattedMessage defaultMessage="Cena netto zlecenia (dla tłumacza)" />
    ),
    render: (_, { translatorPriceNetto }) => (
      <CurrencyFormatter value={translatorPriceNetto} />
    ),
  },
  {
    key: 'orderFileType',
    dataIndex: 'orderFileType',
    title: <FormattedMessage defaultMessage="Przysięgłe" />,
    render: (_, { orderFileType }) => (
      <CircleCheckbox value={orderFileType === FileTranslationType.SWORN} />
    ),
  },
  {
    key: 'orderRatesAvg',
    dataIndex: 'orderRatesAvg',
    title: <FormattedMessage defaultMessage="Ocena średnia danego zlecenia" />,
    render: (_, record) => <OrderRatesCol translatorOrder={record} />,
  },
];
