import type { SendClientEmailsValues } from '../../config';
import type { FormInstance } from 'antd';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { useSendClientEmailsMutation } from 'ducks/clients/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setGlobalIsLoading } from 'ducks/view/global';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';
import { Routes } from 'views/Orders/Routing';
import { useOrderClientSendServices } from '../../useOrderClientSendServices';
import styles from './styles.module.scss';

type Props = {
  form: FormInstance<SendClientEmailsValues>;
  notify: NotificationInstance;
};

const ActionsSection: FC<Props> = ({ form, notify }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { attachments, uploadedFiles } = useAppSelector(({ orders }) => ({
    attachments: orders.sendClientEmail.attachments,
    uploadedFiles: orders.sendClientEmail.uploadedFiles,
  }));
  const { orderData, isOrderFetching } = useOrderClientSendServices();
  const [sendClientEmails, { isLoading: isSendLoading }] =
    useSendClientEmailsMutation();
  const isLoading = isOrderFetching || isSendLoading;

  const handleSend = () => {
    if (!orderData || isLoading) return;

    const { id, contactPerson, clientId } = orderData;

    dispatch(setGlobalIsLoading(true));

    mutationFunctionWithForm<void, SendClientEmailsValues>({
      form,
      mutationFn: async ({ isSms, smsText, ...values }) => {
        const name = contactPerson ?? '';

        await sendClientEmails({
          id: clientId,
          ...values,
          orderId: id,
          name,
          isSms,
          smsText: isSms ? smsText : '',
          attachments,
          files: uploadedFiles.map(
            ({ originFileObj }) => originFileObj as File,
          ),
        }).unwrap();
      },
      onSuccess: () => {
        navigate(Routes.ORDER_DETAILS.replace(':id', id.toString()));
      },
      onError: () =>
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas wysyłania wiadomości." />
          ),
        }),
      onSettled: () => dispatch(setGlobalIsLoading(false)),
    });
  };

  return (
    <div data-testid="actionsSection" className={styles.buttonWrapper}>
      <Button
        htmlType="button"
        type="primary"
        onClick={handleSend}
        loading={isLoading}
      >
        <FormattedMessage defaultMessage="Wyślij" />
      </Button>
    </div>
  );
};

export default ActionsSection;
