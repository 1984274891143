/* eslint-disable react/jsx-props-no-spreading */
import type { EditTranslatorValues } from '../../config';
import type { FormInstance } from 'antd';
import type { FC } from 'react';
import { Col, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import { basicInputs } from './config';

interface Props {
  form: FormInstance<EditTranslatorValues>;
}

const { useWatch } = Form;

const BasicSection: FC<Props> = ({ form }) => {
  const intl = useIntl();
  const formOfSettlement = useWatch('formOfSettlement', form) ?? null;

  return (
    <Col span={24} md={8}>
      <>
        <h6>
          <FormattedMessage defaultMessage="Dane podstawowe" />
        </h6>
        {basicInputs({ intl, formOfSettlement }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </>
    </Col>
  );
};

export default BasicSection;
