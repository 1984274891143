import type { GetInvoicesVatFilter } from 'ducks/invoices/types';
import type { FC } from 'react';
import { Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import { invoiceStatuses } from 'constants/invoice';
import styles from './styles.module.scss';

type Option = {
  label: string;
  value: GetInvoicesVatFilter[keyof GetInvoicesVatFilter];
};

type FilterOptions = Record<keyof GetInvoicesVatFilter, Option[]>;

type Props = {
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  filterBy: GetInvoicesVatFilter;
  onFilterByChange: (filter: Partial<GetInvoicesVatFilter>) => void;
};

const InvoicesTableToolbar: FC<Props> = ({
  searchValue,
  onSearchValueChange,
  filterBy,
  onFilterByChange,
}) => {
  const intl = useIntl();

  const filterOptions: FilterOptions = {
    status: [
      {
        value: null,
        label: intl.formatMessage({ defaultMessage: 'Wszystkie' }),
      },
      ...invoiceStatuses(intl),
    ],
  };

  return (
    <div className={styles.wrapper}>
      <Input
        value={searchValue}
        onChange={(e) => onSearchValueChange(e.target.value)}
        placeholder={intl.formatMessage({
          defaultMessage: 'Szukaj',
        })}
        allowClear
      />
      <Select
        options={filterOptions.status}
        value={filterBy.status}
        onChange={(value) =>
          onFilterByChange({
            status: value,
          })
        }
        placeholder={intl.formatMessage({ defaultMessage: 'Status' })}
      />
    </div>
  );
};

export default InvoicesTableToolbar;
