import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { useOrderSendEmailsMutation } from 'ducks/orders/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setGlobalIsLoading } from 'ducks/view/global';
import { Routes } from 'views/Orders/Routing';
import { useOrdersServices } from 'views/Orders/useOrdersServices';
import { validateTranslatorFields } from '../../utils';
import styles from './styles.module.scss';

type Props = {
  notify: NotificationInstance;
};

const ActionsSection: FC<Props> = ({ notify }) => {
  const navigate = useNavigate();
  const translatorEmails = useAppSelector(
    (state) => state.orders.sendEmails.translatorEmails,
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const { orderData, isOrderLoading } = useOrdersServices();
  const [orderSendEmails, { isLoading: isSendLoading }] =
    useOrderSendEmailsMutation();
  const isLoading = isOrderLoading || isSendLoading;

  const handleSubmit = async () => {
    if (!orderData) return;

    try {
      dispatch(setGlobalIsLoading(true));

      const checkedTranslators = translatorEmails.filter(
        ({ isChecked }) => isChecked,
      );
      const notValidTranslators = validateTranslatorFields(checkedTranslators);

      if (notValidTranslators.length) {
        const { name } = notValidTranslators[0];

        notify.error({
          message: (
            <FormattedMessage
              defaultMessage="Uzupełnij wymagane pola u tłumacza {translatorName}."
              values={{ translatorName: <strong>{name}</strong> }}
            />
          ),
        });

        return;
      }

      await Promise.all(
        checkedTranslators.map(
          async ({
            translatorId,
            isSms,
            smsText,
            email,
            subject,
            body,
            cc,
            name,
            isReferenceFile,
            attachments,
            uploadedFiles,
          }) => {
            await orderSendEmails({
              id: orderData.id,
              translatorId,
              subject,
              email,
              cc,
              name,
              body,
              isSms,
              smsText: isSms ? smsText : null,
              isReferenceFile,
              attachments,
              files: uploadedFiles.map(
                ({ originFileObj }) => originFileObj as File,
              ),
            }).unwrap();
          },
        ),
      );

      navigate(Routes.ORDER_DETAILS.replace(':id', orderData.id.toString()));
    } catch (err) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas wysyłania." />
        ),
      });
    } finally {
      dispatch(setGlobalIsLoading(false));
    }
  };

  return (
    <Space className={styles.space} data-testid="actionsSection">
      <Button onClick={handleSubmit} type="primary" loading={isLoading}>
        <FormattedMessage defaultMessage="Wyślij" />
      </Button>
    </Space>
  );
};

export default ActionsSection;
