import type { EditTranslatorValues } from '../../config';
import type { FormInstance } from 'antd';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { GusReturnProps } from 'components/ui/atoms/GusButton';
import type { UpdateTranslatorRequest } from 'ducks/translators/types';
import type { IntlShape } from 'react-intl';
import { InputNumber, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import CountrySelect from 'components/ui/atoms/CountrySelect';
import GusButton from 'components/ui/atoms/GusButton';
import RegionSelect from 'components/ui/atoms/RegionSelect';
import { POLAND_COUNTRY_CODE } from 'constants/countryAndLanguageCodes';
import {
  MAX_INVOICE_DUE_DATE,
  MAX_PESEL_LENGTH,
  MAX_REGON_LENGTH,
  MIN_INVOICE_DUE_DATE,
} from 'constants/formInputLengths';
import {
  FormOfSettlement,
  formOfSettlements,
} from 'constants/formOfSettlements';
import {
  validateNipRule,
  validatePeselRule,
  validatePostalCodeRule,
  validateRegonRule,
} from 'utilities/antdFormRules';

type InvoiceInputValues = Pick<
  UpdateTranslatorRequest,
  | 'formOfSettlement'
  | 'nip'
  | 'regon'
  | 'pesel'
  | 'contactFirstName'
  | 'contactLastName'
  | 'invoiceAddress'
  | 'invoiceCity'
  | 'invoiceName'
  | 'invoiceCountry'
  | 'invoicePostCode'
  | 'invoiceProvince'
  | 'invoiceDueDate'
>;

type InvoiceInputsProps = {
  intl: IntlShape;
  form: FormInstance<EditTranslatorValues>;
  formOfSettlement: InvoiceInputValues['formOfSettlement'];
  nip: string;
  countryCode: string;
  handleCheckNipSuccessCallback: (callback: GusReturnProps) => void;
  invoiceCountry: string | null;
};

export const invoiceInputs = ({
  intl,
  form,
  formOfSettlement,
  nip,
  countryCode,
  handleCheckNipSuccessCallback,
  invoiceCountry,
}: InvoiceInputsProps): FormItemRecord<InvoiceInputValues>[] => {
  const isInvoice = formOfSettlement === FormOfSettlement.INVOICE;
  const isPolandInvoiceCountry = invoiceCountry === POLAND_COUNTRY_CODE;

  return [
    {
      key: 1,
      label: <FormattedMessage defaultMessage="Forma rozliczenia:" />,
      name: 'formOfSettlement',
      component: <Select options={formOfSettlements(intl)} />,
    },
    {
      key: 13,
      label: <FormattedMessage defaultMessage="Kraj:" />,
      name: 'invoiceCountry',
      component: (
        <CountrySelect
          placeholder=""
          onChange={() => form.setFieldValue('invoiceProvince', null)}
        />
      ),
    },
    {
      key: 2,
      label: <FormattedMessage defaultMessage="NIP:" />,
      name: 'nip',
      rules: [
        {
          required: true,
          message: <FormattedMessage defaultMessage="Podaj numer NIP" />,
        },
        validateNipRule({
          isInternational: invoiceCountry !== POLAND_COUNTRY_CODE,
        }),
      ],

      hidden: !isInvoice,
    },
    {
      key: 3,
      component: (
        <GusButton
          onSuccessCallback={handleCheckNipSuccessCallback}
          value={nip}
          className="width-100"
        />
      ),
      hidden: !isInvoice || !isPolandInvoiceCountry,
    },
    {
      key: 4,
      label: <FormattedMessage defaultMessage="REGON:" />,
      name: 'regon',
      rules: [
        validateRegonRule({
          isInternational: invoiceCountry !== POLAND_COUNTRY_CODE,
        }),
      ],
      additionalInputProps:
        invoiceCountry === POLAND_COUNTRY_CODE
          ? {
              maxLength: MAX_REGON_LENGTH,
            }
          : {},
      hidden: !isInvoice,
    },
    {
      key: 5,
      label: <FormattedMessage defaultMessage="PESEL:" />,
      name: 'pesel',
      rules: [validatePeselRule()],
      additionalInputProps: {
        maxLength: MAX_PESEL_LENGTH,
      },
      hidden: isInvoice,
    },
    {
      key: 6,
      label: <FormattedMessage defaultMessage="Imię:" />,
      name: 'contactFirstName',
      rules: [
        {
          required: true,
          message: <FormattedMessage defaultMessage="Podaj imię" />,
        },
      ],
      hidden: !isInvoice,
    },
    {
      key: 7,
      label: <FormattedMessage defaultMessage="Nazwisko:" />,
      name: 'contactLastName',
      rules: [
        {
          required: true,
          message: <FormattedMessage defaultMessage="Podaj nazwisko" />,
        },
      ],
      hidden: !isInvoice,
    },
    {
      key: 8,
      label: <FormattedMessage defaultMessage="Nazwa firmy:" />,
      name: 'invoiceName',
      rules: [
        {
          required: true,
          message: <FormattedMessage defaultMessage="Podaj nazwę firmy" />,
        },
      ],
      hidden: !isInvoice,
    },
    {
      key: 9,
      label: <FormattedMessage defaultMessage="Adres:" />,
      name: 'invoiceAddress',
    },
    {
      key: 10,
      label: <FormattedMessage defaultMessage="Miasto:" />,
      name: 'invoiceCity',
    },
    {
      key: 11,
      label: <FormattedMessage defaultMessage="Kod pocztowy:" />,
      name: 'invoicePostCode',
      rules: [validatePostalCodeRule()],
    },

    {
      key: 12,
      label: <FormattedMessage defaultMessage="Województwo:" />,
      name: 'invoiceProvince',
      hidden: !isPolandInvoiceCountry,
      component: (
        <RegionSelect
          placeholder={
            !countryCode && <FormattedMessage defaultMessage="Wybierz kraj" />
          }
          disabled={!countryCode}
        />
      ),
    },
    {
      key: 14,
      label: <FormattedMessage defaultMessage="Termin płatności:" />,
      name: 'invoiceDueDate',
      component: (
        <InputNumber min={MIN_INVOICE_DUE_DATE} max={MAX_INVOICE_DUE_DATE} />
      ),
    },
  ];
};
