import type { PopoverProps } from 'antd';
import type { ComponentPropsWithoutRef, FC, ReactElement } from 'react';
import { Popover } from 'antd';
import classNames from 'classnames';
import { isValidElement } from 'react';
import styles from './styles.module.scss';

type Props = ComponentPropsWithoutRef<'div'> & {
  content?: PopoverProps['content'];
  filledContent?: ReactElement;
  notFilledContent?: ReactElement;
};

const PopoverDotCell: FC<Props> = ({
  content,
  filledContent,
  notFilledContent,
  className,
  ...props
}) => {
  const showPopover = isValidElement(content);

  return showPopover ? (
    <Popover content={content} placement="bottom" showArrow={false}>
      <div className={styles.dotWrapper}>
        {filledContent ?? (
          <div className={classNames(styles.dotFilled, className)} {...props} />
        )}
      </div>
    </Popover>
  ) : (
    notFilledContent ?? (
      <div className={classNames(styles.dot, className)} {...props} />
    )
  );
};

export default PopoverDotCell;
