import type { OrderTranslationFileState } from 'ducks/view/orderTranslationFiles';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { FileTranslationType } from 'constants/fileTranslation';
import { useAppSelector } from 'ducks/store';

type EnhancedOrderTranslationFile = OrderTranslationFileState & {
  type: FileTranslationType;
  langFrom: string;
  langTo: string;
};

export const useTranslationFileItemHelpers = (id: number) => {
  const orderFiles = useAppSelector(
    ({ orderTranslationFiles }) => orderTranslationFiles.orderFiles,
    shallowEqual,
  );

  const modifiedOrderTranslationFile = useMemo(
    (): EnhancedOrderTranslationFile | null =>
      orderFiles
        .flatMap(({ type, orderFileLangs }) =>
          orderFileLangs.flatMap(
            ({ orderTranslationFiles, langFrom, langTo }) => {
              const orderTranslationFile = orderTranslationFiles.find(
                ({ fileId }) => fileId === id,
              );

              if (orderTranslationFile)
                return { ...orderTranslationFile, type, langFrom, langTo };

              return null;
            },
          ),
        )
        .filter((item) => !!item)[0],
    [id, orderFiles],
  );

  const isFileAssigned = useMemo(
    () => !!modifiedOrderTranslationFile,
    [modifiedOrderTranslationFile],
  );

  const description = useMemo(() => {
    if (!modifiedOrderTranslationFile) return null;

    const { type, langFrom, langTo } = modifiedOrderTranslationFile;

    switch (type) {
      case FileTranslationType.NORMAL:
        return (
          <FormattedMessage
            defaultMessage="tłumaczenie zwykłe {langFrom}-{langTo}"
            values={{ langFrom, langTo }}
          />
        );
      case FileTranslationType.SWORN:
        return (
          <FormattedMessage
            defaultMessage="tłumaczenie przysięgłe {langFrom}-{langTo}"
            values={{ langFrom, langTo }}
          />
        );
      case FileTranslationType.CORRECTION:
        return (
          <FormattedMessage
            defaultMessage="tłumaczenie korekta {langFrom}-{langTo}"
            values={{ langFrom, langTo }}
          />
        );
      default:
        return null;
    }
  }, [modifiedOrderTranslationFile]);

  return { isFileAssigned, description };
};
