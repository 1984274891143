import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { EditTranslatorsQualificationsRequest } from 'ducks/translators/types';
import { isEqual } from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  useDeleteTranslatorsQualificationMutation,
  useEditTranslatorsQualificationsMutation,
  useGetTranslatorsQualificationsQuery,
} from 'ducks/translators/service';
import { Routes } from 'views/Translators/Routing';
import { initialValues, type ListItem } from './config';

const useManageQualificationList = (notify: NotificationInstance) => {
  const { id: translatorId = '' } = useParams();
  const navigate = useNavigate();
  const [umountedAutoScroll, setUmontAutoScroll] = useState(false);
  const [listItems, setListItems] = useState<ListItem[]>([]);
  const {
    data = [],
    isLoading: isInitialLoading,
    isFetching: isDataFetching,
  } = useGetTranslatorsQualificationsQuery({
    translatorId,
  });
  const [editTranslatorsQualifications, { isLoading: isEditLoading }] =
    useEditTranslatorsQualificationsMutation();
  const [deleteTranslatorsQualification, { isLoading: isDeleteLoading }] =
    useDeleteTranslatorsQualificationMutation();
  const isLoading = isDataFetching || isEditLoading || isDeleteLoading;
  const isChanged = useMemo(
    () =>
      !isEqual(
        listItems.flatMap(({ items }) => items),
        data.flatMap(({ items }) => items),
      ),
    [listItems, data],
  );

  const handleAdd = (sectionId: number) => {
    const newItem = { ...initialValues, id: v4() };
    const specificSection = listItems.find(({ id }) => id === sectionId);

    if (!specificSection) {
      setListItems((prev) => [
        ...prev,
        { id: sectionId, name: '', items: [newItem] },
      ]);

      return;
    }

    setListItems((prev) => {
      return prev.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              items: [...section.items, newItem],
            }
          : section,
      );
    });
  };

  const handleSave = async () => {
    if (isLoading) return;

    const newItems: EditTranslatorsQualificationsRequest['items'] =
      listItems.flatMap((listItem) =>
        listItem.items
          .map((item) => {
            return {
              ...item,
              sectionId: listItem.id,
              file: item?.newFile ?? null,
            };
          })
          .filter(({ isNew }) => !!isNew),
      );

    try {
      await editTranslatorsQualifications({
        translatorId: Number(translatorId),
        items: newItems,
      }).unwrap();

      notify.success({
        message: (
          <FormattedMessage defaultMessage="Pomyślnie wprowadzono zmiany" />
        ),
      });
      navigate(Routes.TRANSLATOR_PROFILE.replace(':id', translatorId));
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania" />
        ),
      });
    }
  };

  const handleEdit = (field: Record<number, ListItem>) => {
    if (isLoading) return;

    const id = Object.keys(field)[0];
    const newValues = Object.values(field)[0];

    setListItems((prev) =>
      prev.map((el) => ({
        ...el,
        items: el.items.map((item) =>
          item.id.toString() === id ? { ...item, ...newValues } : item,
        ),
      })),
    );
  };

  const handleAddFileToItem = (id: string, file: File) => {
    setListItems((prev) =>
      prev.map((el) => ({
        ...el,
        items: el.items.map((item) =>
          item.id.toString() === id ? { ...item, newFile: file } : item,
        ),
      })),
    );
  };

  const handleDelete = async (id: number | string, isNew: boolean) => {
    if (isLoading) return;

    if (isNew) {
      setListItems((prev) =>
        prev.map((el) => ({
          ...el,
          items: el.items.filter((item) => item.id !== id),
        })),
      );

      return;
    }

    try {
      setUmontAutoScroll(true);
      await deleteTranslatorsQualification({
        translatorId: Number(translatorId),
        itemId: id as number,
      }).unwrap();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas usuwania" />
        ),
      });
    }
  };

  useEffect(() => {
    if (data && !isDataFetching) {
      setListItems(data);
    }
  }, [data, isDataFetching]);

  return {
    data,
    listItems,
    isInitialLoading,
    isChanged,
    isLoading,
    handleAdd,
    handleEdit,
    handleDelete,
    handleSave,
    handleAddFileToItem,
    umountedAutoScroll,
  };
};

export default useManageQualificationList;
