import type { ClientRow } from './config';
import type { GetClientsFilter, GetClientsSort } from 'ducks/clients/types';
import { Button, notification } from 'antd';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Table from 'components/ui/molecules/Table/Table';
import { useGetClientsQuery } from 'ducks/clients/service';
import { DefaultValue } from 'ducks/types';
import useTablePagination from 'hooks/useTablePagination';
import { PlusIcon } from 'icons';
import AddClientModal from './components/AddClientModal';
import ClientsTableToolbar from './components/ClientsTableToolbar/ClientsTableToolbar';
import { columns, initialFilters } from './config';
import styles from './styles.module.scss';

const ClientsTable = () => {
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  const {
    page,
    handlePageChange,
    searchValue,
    searchTerm,
    handleSearchValue,
    filter,
    filterBy,
    handleFilterBy,
    sortBy,
    handleTableChange,
  } = useTablePagination<ClientRow, GetClientsFilter>({
    initialFilters,
  });
  const sort = sortBy?.order && {
    [sortBy.columnKey as keyof GetClientsSort]: sortBy.order,
  };
  const { data, isFetching } = useGetClientsQuery(
    {
      page,
      searchTerm,
      filter,
      sort,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );
  const pageSize = data?.perPage;
  const total = isFetching ? 0 : data?.total;

  const modifiedClientsData = useMemo((): ClientRow[] => {
    return (data?.data ?? []).map(
      ({ id, emailDefault, email1, email2, ...restProps }) => {
        const email = emailDefault === DefaultValue.FIRST ? email1 : email2;

        return {
          id,
          key: id,
          email,
          ...restProps,
        };
      },
    );
  }, [data?.data]);

  return (
    <div className="layout-content mb-24" data-testid="clients-table">
      <div className={styles.wrapper}>
        {contextHolder}
        <AddClientModal
          isOpen={isAddClientModalOpen}
          onClose={() => setIsAddClientModalOpen(false)}
          onOpen={() => setIsAddClientModalOpen(true)}
          notify={notify}
        />
        <ClientsTableToolbar
          searchValue={searchValue}
          onSearchValueChange={handleSearchValue}
          filterBy={filterBy}
          onFilterByChange={handleFilterBy}
        />
        <Table<ClientRow>
          pagination={{
            current: page,
            pageSize,
            total,
            onChange: handlePageChange,
          }}
          isLoading={isFetching}
          data={modifiedClientsData}
          columns={columns()}
          onChange={handleTableChange}
          locale={{
            triggerDesc: intl.formatMessage({
              defaultMessage: 'Sortuj malejąco',
            }),
            triggerAsc: intl.formatMessage({
              defaultMessage: 'Sortuj rosnąco',
            }),
            cancelSort: intl.formatMessage({
              defaultMessage: 'Anuluj sortowanie',
            }),
          }}
          cardTitle={<FormattedMessage defaultMessage="Lista klientów" />}
          cardTitleExtra={
            <Button
              data-testid="addClientButton"
              icon={<PlusIcon />}
              type="primary"
              onClick={() => setIsAddClientModalOpen(true)}
            >
              <FormattedMessage defaultMessage="Dodaj klienta" />
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default ClientsTable;
