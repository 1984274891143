import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { PreorderVariantTableValues } from 'components/ui/organisms/Tables/PreorderVariantTable';
import { Checkbox } from 'antd';
import { memo, type FC } from 'react';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import FormItem from 'components/ui/atoms/FormItem';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  updatePreorderVariantFile,
  type PreorderVariantFileSlice,
} from 'ducks/view/preorders';

interface Props {
  id: number | string;
  checked: boolean;
  field: keyof PreorderVariantFileSlice;
  variantId: string | number;
}

const FlexibleCheckboxColumn: FC<Props> = memo(
  ({ id, checked, field, variantId }) => {
    const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
    const dispatch = useAppDispatch();

    const handleClick = (e: CheckboxChangeEvent) => {
      dispatch(
        updatePreorderVariantFile({
          variantId,
          field: {
            [id]: {
              [field]: e.target.checked,
            },
          },
        }),
      );
    };

    return isEditable ? (
      <FormItem<PreorderVariantTableValues>
        component={
          <Checkbox
            onChange={handleClick}
            checked={checked}
            data-testid={`${field}Checkbox-${id}`}
          />
        }
        additionalItemProps={{
          valuePropName: 'checked',
        }}
      />
    ) : (
      <CircleCheckbox
        data-testid={`${field}CircleCheckbox-${id}`}
        value={checked}
      />
    );
  },
);

export default FlexibleCheckboxColumn;
