import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { Dispatch, SetStateAction } from 'react';
import { Form, Input, Switch } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/ui/molecules/Modal';
import { useUpdateTranslatorStatusMutation } from 'ducks/translators/service';

const { Item } = Form;

type Props = {
  isOpen: boolean;
  handleModalClose: () => void;
  notify: NotificationInstance;
  translatorId: number | undefined;
  currentUserStatus: boolean;
  disabled: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
};

const EditStatusModal: React.FC<Props> = ({
  isOpen,
  handleModalClose,
  notify,
  translatorId = 0,
  currentUserStatus,
  disabled,
  setDisabled,
}) => {
  const [form] = useForm();
  const intl = useIntl();
  const [updateTranslatorStatus, { isLoading }] =
    useUpdateTranslatorStatusMutation();

  const statusValue = useWatch('status', form);

  const initialValues = {
    status: currentUserStatus,
  };

  const onClose = () => {
    handleModalClose();
    setDisabled(true);
    form.resetFields();
  };

  const handleSubmit = async () => {
    try {
      const { status, statusReason } = await form.validateFields();
      const data = {
        status: Number(status),
        statusReason,
        translatorId,
      };

      updateTranslatorStatus(data).unwrap();
      notify.success({
        message: <FormattedMessage defaultMessage="Status został edytowany." />,
      });
      onClose();
    } catch {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edycji statusu." />
        ),
      });
    }
  };

  const isStatusChanged = (formStatus: boolean) => {
    return formStatus === currentUserStatus
      ? Promise.reject(
          new Error(
            intl.formatMessage({
              defaultMessage: 'Podaj nowy status',
            }),
          ),
        )
      : Promise.resolve();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={<FormattedMessage defaultMessage="Edytuj status" />}
      isLoading={isLoading}
      onOk={handleSubmit}
      okText={<FormattedMessage defaultMessage="Zapisz" />}
      disableFormStyles
      disableModalStyles
      okButtonProps={{
        disabled,
      }}
    >
      <Form form={form} labelCol={{ span: 6 }} initialValues={initialValues}>
        <Item
          name="status"
          label={<FormattedMessage defaultMessage="Aktywny" />}
          valuePropName="checked"
          required
          rules={[
            ({ getFieldValue }) => ({
              validator() {
                return isStatusChanged(getFieldValue('status'));
              },
            }),
          ]}
        >
          <Switch
            data-testid="isAvailableSwitch"
            disabled={isLoading || disabled}
          />
        </Item>
        {!statusValue && (
          <Item
            name="statusReason"
            label={<FormattedMessage defaultMessage="Powód zmiany" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage defaultMessage="Wprowadź powód zmiany" />
                ),
              },
            ]}
          >
            <Input data-testid="reasonInput" />
          </Item>
        )}
      </Form>
    </Modal>
  );
};

export default EditStatusModal;
