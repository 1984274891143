import type { AddPaymentValues } from './config';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { PaymentObject } from 'ducks/payments/types';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import {
  useAddPaymentMutation,
  useGetPaymentsQuery,
} from 'ducks/payments/service';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';
import { formItems, initialAddPaymentValues } from './config';

const { useForm } = Form;

type Props = {
  id: number;
  paymentObject: PaymentObject;
  isOpen: boolean;
  onClose: () => void;
  notify: NotificationInstance;
  initialValues?: Partial<AddPaymentValues>;
  onSave?: () => Promise<void>;
  isSaveLoading?: boolean;
};

const AddPaymentModal: FC<Props> = ({
  id,
  paymentObject,
  isOpen,
  onClose,
  notify,
  initialValues,
  onSave,
  isSaveLoading = false,
}) => {
  const intl = useIntl();
  const [form] = useForm<AddPaymentValues>();
  const [addPayment, { isLoading: isAddLoading }] = useAddPaymentMutation();
  const { data: payments = [] } = useGetPaymentsQuery({
    paymentObject,
    id,
  });
  const usedCurrency = payments[payments.length - 1]?.currency ?? '';
  const isLoading = isAddLoading || isSaveLoading;

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleSubmit = () =>
    mutationFunctionWithForm<void, AddPaymentValues>({
      form,
      mutationFn: async ({ datePayment, ...values }) => {
        const formattedDatePayment = dayjs(datePayment).format();

        await addPayment({
          ...values,
          id,
          paymentObject,
          datePayment: formattedDatePayment,
        }).unwrap();

        await onSave?.();
      },
      onSuccess: () => {
        notify.success({
          message: (
            <FormattedMessage defaultMessage="Dane zapisano poprawnie" />
          ),
        });

        handleClose();
      },
      onError: () => {
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania płatności." />
          ),
        });
      },
    });

  return (
    <Modal
      data-testd="addPaymentModal"
      title={<FormattedMessage defaultMessage="Dodaj płatność" />}
      okText={<FormattedMessage defaultMessage="Dodaj" />}
      cancelText={<FormattedMessage defaultMessage="Anuluj" />}
      open={isOpen}
      onCancel={handleClose}
      onOk={handleSubmit}
      isLoading={isLoading}
      disableModalStyles
      disableFormStyles
    >
      <Form
        form={form}
        initialValues={{ ...initialAddPaymentValues, ...initialValues }}
        layout="vertical"
      >
        {formItems({ intl, form, usedCurrency }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default AddPaymentModal;
