/* eslint-disable react/jsx-props-no-spreading */
import type { TruncateProps } from '../TruncateText/TruncateText';
import type { ReactElement } from 'react';
import isoCountries from 'i18n-iso-countries';
import polishLanguage from 'i18n-iso-countries/langs/pl.json';
import { POLAND_COUNTRY_CODE } from 'constants/countryAndLanguageCodes';
import { NO_DATA } from 'constants/strings';
import TruncateText from '../TruncateText';

isoCountries.registerLocale(polishLanguage);

type Props = Partial<TruncateProps> & {
  countryCode: string | undefined | null;
  showCode?: boolean;
  emptyText?: ReactElement | string;
};

const CountryName: React.FC<Props> = ({
  countryCode,
  emptyText = NO_DATA,
  ...truncateTextProps
}) => {
  const countryName = isoCountries.getName(
    countryCode ?? '',
    POLAND_COUNTRY_CODE,
  );

  return (
    <TruncateText {...truncateTextProps}>
      {countryName ?? emptyText}
    </TruncateText>
  );
};
export default CountryName;
