import type { UpdateClientInvoiceRequest } from 'ducks/clients/types';
import type { ComponentPropsWithoutRef, FC, ReactElement } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';

type Props = ComponentPropsWithoutRef<typeof Select>;

type Option = {
  value: UpdateClientInvoiceRequest['invoiceType'];
  label: ReactElement;
};

const InvoiceTypeSelect: FC<Props> = ({ ...props }) => {
  const options: Option[] = [
    {
      value: null,
      label: <FormattedMessage defaultMessage="Nie określono" />,
    },
    {
      value: 1,
      label: <FormattedMessage defaultMessage="Papierowa" />,
    },
    {
      value: 2,
      label: <FormattedMessage defaultMessage="Elektroniczna" />,
    },
  ];

  return (
    <Select
      placeholder={<FormattedMessage defaultMessage="Wybierz rodzaj faktury" />}
      options={options}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default InvoiceTypeSelect;
