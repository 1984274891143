import type { NotificationInstance } from 'antd/es/notification/interface';
import type { FC } from 'react';
import { Button } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import HeaderCard, {
  generateSkeletonHeaderCols,
} from 'components/ui/organisms/HeaderCard';
import { useGetNotesQuery } from 'ducks/notes/service';
import { NoteType } from 'ducks/notes/types';
import { useAppDispatch } from 'ducks/store';
import { setTriggerOpenAllNotes } from 'ducks/view/global';
import { useClientDetailsServices } from '../../useClientDetailsServices';
import DeleteClientButton from './components/DeleteClientButton';
import DiscountModal from './components/DiscountModal';
import { headerCols } from './config';

type Props = {
  notify: NotificationInstance;
};

const ClientHeader: FC<Props> = ({ notify }) => {
  const dispatch = useAppDispatch();
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const { clientId, clientData, isClientFetching } = useClientDetailsServices();
  const { data: notes = [], isFetching } = useGetNotesQuery(
    {
      object: NoteType.CLIENTS,
      objectId: clientId,
    },
    { skip: !clientData },
  );
  const canDelete = !!clientData?.canDelete;
  const isLoading = isClientFetching || isFetching;

  const openAllNotesModal = useCallback(
    () => dispatch(setTriggerOpenAllNotes(true)),
    [dispatch],
  );

  const cols = useMemo(() => {
    if (isLoading) return generateSkeletonHeaderCols(6);

    if (!clientData) return [];

    return headerCols(notes, openAllNotesModal, clientData);
  }, [clientData, isLoading, notes, openAllNotesModal]);

  return (
    <>
      {clientData && (
        <DiscountModal
          isOpen={isDiscountModalOpen}
          onClose={() => setIsDiscountModalOpen(false)}
          data={clientData}
          notify={notify}
        />
      )}
      <HeaderCard cols={cols} isLoading={isLoading}>
        {canDelete && !isClientFetching && <DeleteClientButton />}
        <Button
          data-testid="editClientDataButton"
          type="primary"
          disabled={isLoading}
          onClick={() => setIsDiscountModalOpen(true)}
        >
          <FormattedMessage defaultMessage="Zmień rabat" />
        </Button>
      </HeaderCard>
    </>
  );
};

export default ClientHeader;
