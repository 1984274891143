import type { HeaderCardCol } from './config';
import { createArray } from 'utilities/array';

export const generateSkeletonHeaderCols = (
  size: number,
  withSecondRow = true,
): HeaderCardCol[] =>
  createArray(size).map((number) => ({
    key: number,
    firstRow: <span />,
    secondRow: withSecondRow && <span />,
  }));
