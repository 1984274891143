import type {
  AddClientContactPersonRequest,
  AddClientRequest,
  CheckDuplicateClientsRequest,
  Client,
  DeleteClientContactPersonRequest,
  GetClientContactPersonsRequest,
  GetClientContactPersonsResponse,
  GetClientDetailsRequest,
  GetClientsRequest,
  GetClientsResponse,
  UpdateClientContactPersonRequest,
  UpdateClientContactRequest,
  UpdateClientCorrespondenceRequest,
  UpdateClientInvoiceRequest,
  UpdateClientDiscountRequest,
  CheckDuplicateClientsResponse,
  GetClientOrdersResponse,
  GetClientOrdersRequest,
  GetClientPreordersResponse,
  GetClientPreordersRequest,
  PostOrderTmRequest,
  DeleteClientRequest,
  SendClientEmailsRequest,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { snakeCase } from 'lodash';
import baseQuery from 'ducks/api';
import { formatFilterParameter, formatSortParameter } from 'utilities/api';
import { convertKeysToSnakeCase } from 'utilities/object';
import { ClientsTag } from './types';

export enum Endpoint {
  GetClients = '/clients',
  AddClient = '/clients',
  CheckDuplicateClients = '/clients/check-duplicates',
  GetClientDetails = '/clients/:clientId',
  DeleteClient = '/clients/:clientId',
  UpdateClientDiscount = '/clients/:clientId/set-discount',
  UpdateClientContact = '/clients/:clientId/contact',
  UpdateClientInvoice = '/clients/:clientId/invoice',
  UpdateClientCorrespondence = '/clients/:clientId/correspondence',
  SendClientEmails = '/clients/:clientId/emails',
  GetClientContactPersons = '/clients/:clientId/contact-persons',
  AddClientContactPerson = '/clients/:clientId/contact-persons',
  UpdateClientContactPerson = '/clients/:clientId/contact-persons/:contactId',
  DeleteClientContactPerson = '/clients/:clientId/contact-persons/:contactId',
  GetClientOrders = '/clients/:clientId/orders',
  GetClientPreorders = '/clients/:clientId/preorders',
  PostOrdersTm = '/orders/:orderId/mt',
}

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  baseQuery: baseQuery(),
  tagTypes: [
    ClientsTag.Clients,
    ClientsTag.ClientContactPersons,
    ClientsTag.Orders,
    ClientsTag.Preorders,
  ],
  endpoints: (build) => ({
    getClients: build.query<GetClientsResponse, GetClientsRequest>({
      query: ({ page, searchTerm, filter, sort }) => ({
        url: Endpoint.GetClients,
        method: 'GET',
        params: {
          page,
          s: searchTerm,
          filter: formatFilterParameter(filter),
          sort: formatSortParameter(sort),
        },
      }),
      providesTags: [ClientsTag.Clients],
    }),
    addClient: build.mutation<void, AddClientRequest>({
      query: (data) => ({
        url: Endpoint.AddClient,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) => (error ? [] : [ClientsTag.Clients]),
    }),
    checkDuplicates: build.mutation<
      CheckDuplicateClientsResponse,
      CheckDuplicateClientsRequest
    >({
      query: (data) => ({
        url: Endpoint.CheckDuplicateClients,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
    }),
    getClientDetails: build.query<Client, GetClientDetailsRequest>({
      query: ({ clientId }) => ({
        url: Endpoint.GetClientDetails.replace(':clientId', `${clientId}`),
        method: 'GET',
      }),
      providesTags: [ClientsTag.Clients],
    }),
    deleteClient: build.mutation<void, DeleteClientRequest>({
      query: ({ clientId }) => ({
        url: Endpoint.DeleteClient.replace(':clientId', clientId.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => (error ? [] : [ClientsTag.Clients]),
    }),
    updateClientDiscount: build.mutation<void, UpdateClientDiscountRequest>({
      query: ({ clientId, ...data }) => ({
        url: Endpoint.UpdateClientDiscount.replace(':clientId', `${clientId}`),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) => (error ? [] : [ClientsTag.Clients]),
    }),
    updateClientContact: build.mutation<void, UpdateClientContactRequest>({
      query: ({ clientId, ...data }) => ({
        url: Endpoint.UpdateClientContact.replace(':clientId', `${clientId}`),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) => (error ? [] : [ClientsTag.Clients]),
    }),
    updateClientInvoice: build.mutation<void, UpdateClientInvoiceRequest>({
      query: ({ clientId, ...data }) => ({
        url: Endpoint.UpdateClientInvoice.replace(':clientId', `${clientId}`),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) => (error ? [] : [ClientsTag.Clients]),
    }),
    updateClientCorrespondence: build.mutation<
      void,
      UpdateClientCorrespondenceRequest
    >({
      query: ({ clientId, ...data }) => ({
        url: Endpoint.UpdateClientCorrespondence.replace(
          ':clientId',
          `${clientId}`,
        ),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) => (error ? [] : [ClientsTag.Clients]),
    }),
    sendClientEmails: build.mutation<void, SendClientEmailsRequest>({
      query: ({ id, ...data }) => {
        const formData = new FormData();
        const { files, attachments, ...restData } = data;

        Object.entries(restData).forEach(([key, value]) => {
          const modifiedValue = value === null ? '' : String(value);

          formData.append(snakeCase(key), modifiedValue);
        });

        if (attachments.length) {
          attachments.forEach((atch) => {
            formData.append(`attachments[]`, String(atch));
          });
        }

        if (files.length) {
          files.forEach((file, index) => {
            formData.append(
              `files[${index}][file]`,
              new Blob([file]),
              file.name,
            );
          });
        }

        return {
          url: Endpoint.SendClientEmails.replace(':clientId', id.toString()),
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      invalidatesTags: (_, error) => (error ? [] : [ClientsTag.Clients]),
    }),
    getClientContactPersons: build.query<
      GetClientContactPersonsResponse,
      GetClientContactPersonsRequest
    >({
      query: ({ clientId }) => ({
        url: Endpoint.GetClientContactPersons.replace(
          ':clientId',
          `${clientId}`,
        ),
        method: 'GET',
      }),
      providesTags: [ClientsTag.ClientContactPersons],
    }),
    addClientContactPerson: build.mutation<void, AddClientContactPersonRequest>(
      {
        query: ({ clientId, ...data }) => ({
          url: Endpoint.AddClientContactPerson.replace(
            ':clientId',
            `${clientId}`,
          ),
          method: 'POST',
          data: convertKeysToSnakeCase(data),
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [ClientsTag.ClientContactPersons],
      },
    ),
    updateClientContactPerson: build.mutation<
      void,
      UpdateClientContactPersonRequest
    >({
      query: ({ clientId, contactId, ...data }) => ({
        url: Endpoint.UpdateClientContactPerson.replace(
          ':clientId',
          `${clientId}`,
        ).replace(':contactId', `${contactId}`),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [ClientsTag.ClientContactPersons],
    }),
    deleteClientContactPerson: build.mutation<
      void,
      DeleteClientContactPersonRequest
    >({
      query: ({ clientId, contactId }) => ({
        url: Endpoint.DeleteClientContactPerson.replace(
          ':clientId',
          `${clientId}`,
        ).replace(':contactId', `${contactId}`),
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [ClientsTag.ClientContactPersons],
    }),
    getClientOrders: build.query<
      GetClientOrdersResponse,
      GetClientOrdersRequest
    >({
      query: ({ clientId }) => ({
        url: Endpoint.GetClientOrders.replace(':clientId', clientId),
        method: 'GET',
      }),
      providesTags: [ClientsTag.Orders],
    }),
    getClientPreorders: build.query<
      GetClientPreordersResponse,
      GetClientPreordersRequest
    >({
      query: ({ clientId }) => ({
        url: Endpoint.GetClientPreorders.replace(':clientId', clientId),
        method: 'GET',
      }),
      providesTags: [ClientsTag.Preorders],
    }),
    postOrderTm: build.mutation<void, PostOrderTmRequest>({
      query: ({ orderId, ...data }) => ({
        url: Endpoint.PostOrdersTm.replace(':orderId', orderId.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: [ClientsTag.Orders],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useAddClientMutation,
  useCheckDuplicatesMutation,
  useGetClientDetailsQuery,
  useDeleteClientMutation,
  useUpdateClientDiscountMutation,
  useUpdateClientContactMutation,
  useUpdateClientInvoiceMutation,
  useUpdateClientCorrespondenceMutation,
  useSendClientEmailsMutation,
  useGetClientContactPersonsQuery,
  useAddClientContactPersonMutation,
  useUpdateClientContactPersonMutation,
  useDeleteClientContactPersonMutation,
  useGetClientOrdersQuery,
  useGetClientPreordersQuery,
  usePostOrderTmMutation,
} = clientsApi;
