import type { FC } from 'react';
import { Image } from 'antd';
import { catIcons } from 'constants/cats';

type Props = {
  id: number;
  name: string;
};

const CatLabel: FC<Props> = ({ id, name }) => {
  const catIcon = catIcons?.[id];

  return (
    <span>
      {catIcon ? (
        <Image preview={false} width={20} height={20} src={catIcon} />
      ) : (
        name
      )}
    </span>
  );
};

export default CatLabel;
