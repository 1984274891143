import type {
  AddSmsTemplateByIdRequest,
  EditSmsTemplateByIdRequest,
  GetSmsTemplateByIdRequest,
  GetSmsTemplateByIdResponse,
  GetSmsTemplatesResponse,
  SmsSendRequest,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import { SmsTag } from './types';

export enum Endpoint {
  GetSmsTemplates = '/sms-templates',
  AddSmsTemplate = '/sms-templates',
  GetSmsTemplate = '/sms-templates/:id',
  EditSmsTemplate = '/sms-templates/:id',
  SendSms = '/sms',
}

export const smsApi = createApi({
  reducerPath: 'smsApi',
  baseQuery: baseQuery(),
  tagTypes: [SmsTag.SMS_TEMPLATES, SmsTag.SMS_SEND],
  endpoints: (build) => ({
    getSmsTemplates: build.query<GetSmsTemplatesResponse, void>({
      query: () => ({
        url: Endpoint.GetSmsTemplates,
        method: 'GET',
      }),
      providesTags: [SmsTag.SMS_TEMPLATES],
    }),
    getSmsTemplateById: build.query<
      GetSmsTemplateByIdResponse,
      GetSmsTemplateByIdRequest
    >({
      query: ({ id }) => ({
        url: Endpoint.GetSmsTemplate.replace(':id', id),
        method: 'GET',
      }),
      providesTags: [SmsTag.SMS_TEMPLATES],
    }),
    addSmsTemplate: build.mutation<void, AddSmsTemplateByIdRequest>({
      query: (data) => ({
        url: Endpoint.AddSmsTemplate,
        method: 'POST',
        data,
      }),
      invalidatesTags: [SmsTag.SMS_TEMPLATES],
    }),
    editSmsTemplate: build.mutation<void, EditSmsTemplateByIdRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.EditSmsTemplate.replace(':id', id),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: [SmsTag.SMS_TEMPLATES],
    }),
    sendSms: build.mutation<void, SmsSendRequest>({
      query: (data) => ({
        url: Endpoint.SendSms,
        method: 'POST',
        data,
      }),
      invalidatesTags: [SmsTag.SMS_SEND],
    }),
  }),
});

export const {
  useGetSmsTemplatesQuery,
  useGetSmsTemplateByIdQuery,
  useEditSmsTemplateMutation,
  useAddSmsTemplateMutation,
  useSendSmsMutation,
} = smsApi;
