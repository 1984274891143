import type { FormInstance } from 'antd';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import { FormattedMessage } from 'react-intl';
import LanguageSelect from 'components/ui/atoms/LanguageSelect';
import { POLAND_COUNTRY_CODE } from 'constants/countryAndLanguageCodes';

export type GenerateLanguagePairsModalValues = {
  langs: string[];
};

export const initialValues: GenerateLanguagePairsModalValues = {
  langs: [],
};

type Props = {
  langs: string[];
  form: FormInstance<GenerateLanguagePairsModalValues>;
};

export const formItems = ({
  langs,
}: Props): FormItemRecord<GenerateLanguagePairsModalValues>[] => [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Języki" />,
    name: 'langs',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz języki" />,
      },
    ],
    component: (
      <LanguageSelect
        mode="multiple"
        allowClear={false}
        placeholder=""
        filterByCountryCode={({
          countryShortCode,
        }: {
          countryShortCode: string;
        }) =>
          !langs.includes(countryShortCode) &&
          countryShortCode !== POLAND_COUNTRY_CODE
        }
      />
    ),
  },
];
