export const mapEnum = <T>(
  enumObject: T,
): { key: keyof T; value: T[keyof T] }[] => {
  return Object.keys(enumObject as T[])
    .filter((key) => typeof enumObject[key as keyof T] === 'number')
    .map((key) => ({
      key: key as keyof T,
      value: enumObject[key as keyof T],
    }));
};
