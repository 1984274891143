import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { Col } from 'antd';
import { useParams } from 'react-router-dom';
import PaymentsTable from 'components/ui/organisms/Tables/PaymentTable';
import { PaymentObject } from 'ducks/payments/types';
import { useUnblockOrder } from 'views/Orders/useUnblockOrder';
import { useOrderDetailsServices } from '../../useOrderDetailsServices';

type Props = {
  notify: NotificationInstance;
};

const PaymentTableSection: FC<Props> = ({ notify }) => {
  const { id = '' } = useParams();
  const { orderData, isOrderLoading } = useOrderDetailsServices();
  const { handleUnblock, isLoading: isUnblockLoading } = useUnblockOrder({
    notify,
    showSuccessNotify: true,
  });
  const isLoading = isOrderLoading || isUnblockLoading;

  return (
    <Col xs={24} lg={12}>
      <PaymentsTable
        id={id}
        paymentObject={PaymentObject.ORDERS}
        isLoading={isLoading}
        skip={!orderData}
        onSave={handleUnblock}
        isSaveLoading={isUnblockLoading}
      />
    </Col>
  );
};

export default PaymentTableSection;
