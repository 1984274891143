import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';
import type { Preorder } from 'ducks/preorders/types';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { DayjsFormat } from 'constants/dayjsFormats';
import { NO_DATA } from 'constants/strings';
import { generateWorkingDaysLabel } from 'utilities/workingDaysLabel';

type Props = {
  data: Preorder;
};

export const headerCols = ({
  data: { dateSent, order, dateCreation },
}: Props): HeaderCardCol[] => {
  const formattedDateCreation = dateCreation
    ? dayjs(dateCreation).format(DayjsFormat.DATE_AND_TIME)
    : NO_DATA;
  const formattedDateSent = dateSent
    ? dayjs(dateSent).format(DayjsFormat.DATE_AND_TIME)
    : NO_DATA;
  const formattedDateOfAcceptanceForRealization = order?.createdAt
    ? dayjs(order.createdAt).format(DayjsFormat.DATE_AND_TIME)
    : NO_DATA;
  const formattedTimeRealization = generateWorkingDaysLabel({
    timeRealization: order?.timeRealization,
  });
  const formattedDateRealization = order?.dateRealization
    ? dayjs(order.dateRealization).format(DayjsFormat.DATE)
    : NO_DATA;

  const formattedFinalDateRealization = order?.dateSent
    ? dayjs(order.dateSent).format(DayjsFormat.DATE)
    : NO_DATA;

  return [
    {
      key: 'dates1',
      firstRow: (
        <span>
          <b>
            <FormattedMessage defaultMessage="Data utworzenia:" />
          </b>{' '}
          {formattedDateCreation}
        </span>
      ),
      secondRow: (
        <span>
          <b>
            <FormattedMessage defaultMessage="Data wysłania:" />
          </b>{' '}
          {formattedDateSent}
        </span>
      ),
    },
    {
      key: 'dates2',
      firstRow: (
        <span>
          <b>
            <FormattedMessage defaultMessage="Data przyjęcia do realizacji:" />
          </b>{' '}
          {formattedDateOfAcceptanceForRealization}
        </span>
      ),
      secondRow: (
        <span>
          <b>
            <FormattedMessage defaultMessage="Termin realizacji:" />
          </b>{' '}
          {formattedTimeRealization} | {formattedDateRealization}
        </span>
      ),
    },
    {
      key: 'dates3',
      firstRow: (
        <span>
          <b>
            <FormattedMessage defaultMessage="Data realizacji:" />
          </b>{' '}
          {formattedFinalDateRealization}
        </span>
      ),
    },
  ];
};
