import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { ParsePaymentFileSlice } from 'ducks/view/finances';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import TruncateText from 'components/ui/atoms/TruncateText';
import { NO_DATA } from 'constants/strings';
import { PaymentTransactionSource } from 'ducks/payments/types';
import ActionsColumn from './components/ActionsColumn';

export type ParsedPaymentFileRow = TableRow & ParsePaymentFileSlice;

type ParsedPaymentFileColumnsProps = {
  truncateFromName?: boolean;
};

export const parsedPaymentFileColumns = ({
  truncateFromName = true,
}: ParsedPaymentFileColumnsProps = {}): ColumnsType<ParsedPaymentFileRow> => [
  {
    title: <FormattedMessage defaultMessage="Dane nadawcy" />,
    dataIndex: 'fromName',
    key: 'fromName',
    render: (_, { id, csv: { fromName } }) => (
      <TruncateText
        data-testid={`fromName-${id}`}
        maxLength={truncateFromName ? 30 : 255}
      >
        {fromName}
      </TruncateText>
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Tytuł przelewu" />,
    dataIndex: 'title',
    key: 'title',
    render: (_, { id, csv: { title } }) => (
      <span data-testid={`title-${id}`}>{title}</span>
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Wpłacona kwota" />,
    dataIndex: 'csvAmount',
    key: 'csvAmount',
    render: (_, { id, csv: { amount, currency } }) => (
      <span data-testid={`csvAmount-${id}`}>
        <CurrencyFormatter value={amount} currency={currency} />
      </span>
    ),
  },
];

export const columns: ColumnsType<ParsedPaymentFileRow> = [
  {
    title: <FormattedMessage defaultMessage="Dane bankowe" />,
    children: [...parsedPaymentFileColumns()],
  },
  {
    title: <FormattedMessage defaultMessage="Dane zamówienia" />,
    children: [
      {
        key: 'clientName',
        dataIndex: 'clientName',
        title: <FormattedMessage defaultMessage="Nazwa klienta" />,
        render: (_, { id, object }) => (
          <span data-testid={`clientName-${id}`}>
            {object ? object.client.name : NO_DATA}
          </span>
        ),
      },
      {
        key: 'invoiceNumber',
        dataIndex: 'invoiceNumber',
        title: <FormattedMessage defaultMessage="Numer faktury" />,
        render: (_, { id, object }) => {
          return (
            <span data-testid={`invoiceNumber-${id}`}>
              {!object && NO_DATA}
              {object && object.objectType === PaymentTransactionSource.INVOICES
                ? object.number
                : NO_DATA}
            </span>
          );
        },
      },
      {
        key: 'orderNumbers',
        dataIndex: 'orderNumbers',
        title: <FormattedMessage defaultMessage="Numery zamówień" />,
        render: (_, { id, object }) => {
          if (!object)
            return <span data-testid={`orderNumbers-${id}`}>{NO_DATA}</span>;

          const { objectType, number, orders } = object;

          if (objectType === PaymentTransactionSource.INVOICES)
            return (
              <span data-testid={`orderNumbers-${id}`}>
                {orders.length
                  ? orders.map((order) => order.number).join(', ')
                  : NO_DATA}
              </span>
            );

          return <span data-testid={`orderNumbers-${id}`}>{number}</span>;
        },
      },
      {
        key: 'amount',
        dataIndex: 'amount',
        title: <FormattedMessage defaultMessage="Kwota faktury" />,
        render: (_, { id, object }) => (
          <span data-testid={`amount-${id}`}>
            {object ? <CurrencyFormatter value={object.amount} /> : NO_DATA}
          </span>
        ),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Przypisanie" />,
    children: [
      {
        dataIndex: 'actions',
        key: 'actions',
        render: (_, { id, object }) => (
          <ActionsColumn id={id} object={object} />
        ),
      },
    ],
  },
];
