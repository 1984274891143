import type { Notes } from 'ducks/orders/types';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'views/Orders/Routing';
import PopoverDotCell from '../PopoverDotCell';
import styles from './styles.module.scss';

type Props = { orderId: number; notes: Notes };

const NotesCell: FC<Props> = ({
  orderId,
  notes: { financialCount, count },
}) => {
  const isEmpty = !financialCount && !count;

  if (isEmpty) return null;

  return (
    <Link
      to={Routes.ORDER_DETAILS.replace(':id', orderId.toString())}
      onClick={(e) => e.stopPropagation()}
      state={{ scrollToNotes: true }}
    >
      <div className={styles.wrapper}>
        {!!financialCount && <PopoverDotCell className={styles.financial} />}
        {!!count && <PopoverDotCell className={styles.general} />}
      </div>
    </Link>
  );
};

export default NotesCell;
