import { Skeleton } from 'antd';
import styles from './styles.module.scss';

export const SwitchSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton className={styles.skeleton} active paragraph={false} />
    </div>
  );
};
