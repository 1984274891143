import type { UserLoginRequest } from 'ducks/auth/types';
import type { FC } from 'react';
import { Card, Col, Image, Layout, Row, notification } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import logo from 'assets/images/logo.png';
import { Routes } from 'components/main/App/Routing';
import { useLoginMutation } from 'ducks/auth/service';
import CodeForm from './components/CodeForm';
import LoginForm from './components/LoginForm';
import styles from './styles.module.scss';

const { Content } = Layout;

const NotAuth: FC = () => {
  const [notify, contextHolder] = notification.useNotification();
  const { authenticated, twoFactorChallenge } = useSanctum();
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [twoFactorSending, setTwoFactorSending] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const navigate = useNavigate();

  const [
    login,
    {
      data: loginData,
      isLoading: isSaving,
      isError: isSaveError,
      isSuccess: isSaveSuccess,
    },
  ] = useLoginMutation();

  const onLoginSubmit = ({ email, password }: UserLoginRequest): void => {
    login({ email, password });
  };

  const onCodeSubmit = (code: string): void => {
    setTwoFactorSending(true);
    twoFactorChallenge(code).catch(() => {
      setTwoFactorSending(false);
      notify.error({
        message: <FormattedMessage defaultMessage="Nieprawidłowy kod" />,
      });
    });
  };

  useEffect(() => {
    if (!loginData || !isSaveSuccess) return;
    if (loginData.qrCode) setQrCode(loginData.qrCode || '');
    setShowCodeForm(true);
  }, [loginData, isSaveSuccess]);

  useEffect(() => {
    if (authenticated) {
      navigate(Routes.PROJECTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    if (!isSaving && isSaveError) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Nieprawidłowa nazwa użytkownika lub hasło." />
        ),
        description: (
          <FormattedMessage defaultMessage="Nie masz odpowiednich uprawnień aby zalogować się do systemu" />
        ),
      });
    }
  }, [notify, isSaving, isSaveError]);

  return (
    <Layout className="layout-default">
      {contextHolder}
      <Content className="signin">
        <Row justify="center">
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Image preview={false} src={logo} rootClassName={styles.logo} />
            <Card
              className={styles.card}
              title={<FormattedMessage defaultMessage="Logowanie" />}
            >
              {showCodeForm && (
                <CodeForm
                  qrCode={qrCode}
                  onFinishCallback={onCodeSubmit}
                  isRequestPending={twoFactorSending}
                />
              )}
              {!showCodeForm && (
                <LoginForm
                  onFinishCallback={onLoginSubmit}
                  isSaving={isSaving}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default NotAuth;
