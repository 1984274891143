import type { OrderTranslationFilesState } from 'ducks/view/orderTranslationFiles';
import type { FC } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import LanguagePair from 'components/ui/atoms/LanguagePair';
import { useAppDispatch } from 'ducks/store';
import { updateOrderTranslationFileLangPair } from 'ducks/view/orderTranslationFiles';
import styles from '../../styles.module.scss';

type Props = {
  orderFileId: number;
  orderFileLangs: OrderTranslationFilesState['orderFiles'][number]['orderFileLangs'];
};

const LangsColumn: FC<Props> = ({ orderFileId, orderFileLangs }) => {
  const dispatch = useAppDispatch();

  const handleUpdateOrderFileLangs = (
    value: number,
    orderFileLangId: number,
    orderTranslationFileId: number | string,
  ) =>
    dispatch(
      updateOrderTranslationFileLangPair({
        orderFileId,
        targetOrderFileLangId: value,
        selectedOrderFileLangId: orderFileLangId,
        orderTranslationFileId,
      }),
    );

  const options = orderFileLangs.map(({ id, langFrom, langTo }) => ({
    value: id,
    label: <LanguagePair langFrom={langFrom} langTo={langTo} />,
  }));

  return (
    <div className={styles.multilineCell}>
      {orderFileLangs.map(({ id: orderFileLangId, orderTranslationFiles }) =>
        orderTranslationFiles.map(({ id }) => (
          <Select
            key={id}
            value={orderFileLangId}
            options={options}
            placeholder={
              <FormattedMessage defaultMessage="Wybierz parę językową" />
            }
            onChange={(value) =>
              handleUpdateOrderFileLangs(value, orderFileLangId, id)
            }
          />
        )),
      )}
    </div>
  );
};

export default LangsColumn;
