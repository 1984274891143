export const POLAND_COUNTRY_CODE = 'PL';

export enum LanguageCode {
  POLAND = 'PL',
  ENGLISH = 'EN',
  GERMAN = 'DE',
  UKRAINE = 'UA',
  RUSSIAN = 'RU',
  ITALIAN = 'IT',
  FRENCH = 'FR',
  SPANISH = 'ES',
  CATALONIA = 'KT',
}
