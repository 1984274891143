import type { OrderFilesAndSericesValues } from 'components/ui/organisms/Tables/OrderFilesAndServicesTable';
import type { OrderFileLangState } from 'ducks/view/orders';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import FormItem from 'components/ui/atoms/FormItem';
import { DayjsFormat } from 'constants/dayjsFormats';
import { useAppSelector } from 'ducks/store';
import styles from './styles.module.scss';

type Props = {
  orderFileId: number;
  orderFileLangId: string | number;
} & Pick<OrderFileLangState, 'dateRealization'>;

const DateRealizationColumn: FC<Props> = ({
  orderFileId,
  orderFileLangId,
  dateRealization,
}) => {
  const isEditable = useAppSelector((state) => state.orders.isEditable);
  const defaultValue = dayjs.tz(dateRealization);
  const formattedDateRealization = dayjs
    .tz(dateRealization)
    .format(DayjsFormat.DATE_AND_TIME);

  return isEditable ? (
    <FormItem<OrderFilesAndSericesValues>
      name={[orderFileId, orderFileLangId as number, 'dateRealization']}
      component={
        <DatePicker
          className="width-100"
          defaultValue={defaultValue}
          showTime
          locale={locale}
          format={DayjsFormat.YEAR_MONTH_DAY_HOUR_MINUTE_DASHED}
        />
      }
      additionalItemProps={{
        valuePropName: 'date',
      }}
    />
  ) : (
    <span className={styles.cell}>{formattedDateRealization}</span>
  );
};

export default DateRealizationColumn;
