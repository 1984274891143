import type { ReactElement } from 'react';
import type { Location } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';

type Props = {
  pathLevelWatch?: number;
  children: ReactElement[];
};

const getPathPart = (location: Location, level: number): string =>
  location.pathname.split('/')[level] || '';

enum Transition {
  FADE_IN = 'fadeIn',
  FADE_OUT = 'fadeOut',
}

const AnimatedRoutes: React.FC<Props> = ({ children, pathLevelWatch = 1 }) => {
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState(Transition.FADE_IN);

  useEffect(() => {
    const current = getPathPart(location, pathLevelWatch);
    const display = getPathPart(displayLocation, pathLevelWatch);

    if (current !== display) {
      setTransitionStage(Transition.FADE_OUT);
    } else {
      setDisplayLocation(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, displayLocation.pathname, pathLevelWatch]);

  return (
    <div
      className={styles[transitionStage]}
      data-testid="animated-routes-wrapper"
      onAnimationEnd={() => {
        if (transitionStage === Transition.FADE_OUT) {
          setTransitionStage(Transition.FADE_IN);
          setDisplayLocation(location);
        }
      }}
    >
      <Routes location={displayLocation}>{children}</Routes>
    </div>
  );
};

export default AnimatedRoutes;
