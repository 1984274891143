import type { ComponentPropsWithoutRef, FC } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { provinces } from 'constants/provinces';

type Props = ComponentPropsWithoutRef<typeof Select>;

const RegionSelect: FC<Props> = ({ ...props }) => {
  const countryRegions = provinces;

  return (
    <Select
      placeholder={<FormattedMessage defaultMessage="Wybierz województwo" />}
      showSearch
      options={countryRegions.map(({ name }) => ({
        value: name,
        label: name,
      }))}
      optionFilterProp="label"
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
      allowClear
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default RegionSelect;
