import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { PreorderStatus } from 'constants/preorder';
import { useAppSelector } from 'ducks/store';
import PreorderHeader from 'views/Preorders/components/PreorderHeader';
import { Routes } from 'views/Preorders/Routing';
import { usePreorderDetailsServices } from '../../usePreorderDetailsServices';
import DeletePreorderButton from './DeletePreorderButton';

const PreorderDetailsHader = () => {
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
  const { preorderData, isPreorderFetching } = usePreorderDetailsServices();
  const isNotInRealization =
    preorderData?.status !== PreorderStatus.IN_REALIZATION;
  const canDelete = !!preorderData?.canDelete;

  return (
    <PreorderHeader showUnblockButton={!isEditable}>
      {canDelete && !isPreorderFetching && <DeletePreorderButton />}
      {!isEditable && isNotInRealization && preorderData && (
        <Link
          to={Routes.PREORDER_EDIT.replace(':id', preorderData.id.toString())}
        >
          <Button type="primary">
            <FormattedMessage defaultMessage="Edytuj" />
          </Button>
        </Link>
      )}
    </PreorderHeader>
  );
};

export default PreorderDetailsHader;
