/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import type { UploadFile, UploadProps } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { FC, ReactElement } from 'react';
import { Upload as AntdUpload } from 'antd';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = UploadProps & {
  children?: ReactElement | null;
  isLoading?: boolean;
  customPreviewUrl?: boolean;
  showControls?: boolean;
  fullWidth?: boolean;
};

const Upload: FC<Props> = ({
  children,
  fileList = [],
  isLoading = false,
  customPreviewUrl = true,
  showControls = false,
  fullWidth = true,
  onChange,
  ...props
}) => {
  const handleChange = (info: UploadChangeParam<UploadFile>) =>
    onChange?.({ ...info, file: { ...info.file, status: 'done' } });

  return (
    <AntdUpload
      data-testid="uploader"
      className={classNames(styles.upload, { [styles.fullWidth]: fullWidth })}
      multiple
      customRequest={() => null}
      fileList={
        customPreviewUrl
          ? fileList.map(({ originFileObj, ...restProps }) => ({
              ...restProps,
              url: originFileObj
                ? URL.createObjectURL(originFileObj as File)
                : '',
              originFileObj,
            }))
          : fileList
      }
      showUploadList={{
        showRemoveIcon: showControls,
      }}
      disabled={isLoading}
      onChange={handleChange}
      {...props}
    >
      {children}
    </AntdUpload>
  );
};

export default Upload;
