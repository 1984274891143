import type { EditTranslatorValues } from '../../config';
import type { FormInstance } from 'antd';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { UpdateTranslatorRequest } from 'ducks/translators/types';
import { Input, Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import CountrySelect from 'components/ui/atoms/CountrySelect';
import RegionSelect from 'components/ui/atoms/RegionSelect';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import {
  validatePhoneNumberRule,
  validatePostalCodeRule,
  validateUrlRule,
} from 'utilities/antdFormRules';

type CorrespondenceInputValues = Pick<
  UpdateTranslatorRequest,
  | 'email1'
  | 'email2'
  | 'emailDefault'
  | 'phone1'
  | 'phone2'
  | 'phoneDefault'
  | 'address'
  | 'city'
  | 'postCode'
  | 'province'
  | 'country'
  | 'websiteUrl'
  | 'facebookUrl'
  | 'linkedinUrl'
>;

type Props = {
  form: FormInstance<EditTranslatorValues>;
  countryCode: string;
};

export const correspondenceInputs = ({
  form,
  countryCode,
}: Props): FormItemRecord<CorrespondenceInputValues>[] => [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="E-mail:" />,
    name: 'email1',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj email" />,
      },
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
    additionalInputProps: {
      addonAfter: (
        <Radio data-testid="email1Radio" value={1} style={{ margin: 0 }} />
      ),
    },
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="E-mail 2:" />,
    name: 'email2',
    rules: [
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
    additionalInputProps: {
      addonAfter: (
        <Radio data-testid="email2Radio" value={2} style={{ margin: 0 }} />
      ),
    },
  },
  {
    key: 3,
    label: <FormattedMessage defaultMessage="Tel:" />,
    name: 'phone1',
    rules: [validatePhoneNumberRule()],
    additionalInputProps: {
      addonAfter: (
        <Radio data-testid="phone1Radio" value={1} style={{ margin: 0 }} />
      ),
    },
  },
  {
    key: 4,
    label: <FormattedMessage defaultMessage="Tel 2:" />,
    name: 'phone2',
    rules: [validatePhoneNumberRule()],
    additionalInputProps: {
      addonAfter: (
        <Radio data-testid="phone2Radio" value={2} style={{ margin: 0 }} />
      ),
    },
  },
  {
    key: 5,
    label: <FormattedMessage defaultMessage="Adres:" />,
    name: 'address',
  },
  {
    key: 6,
    label: <FormattedMessage defaultMessage="Miasto:" />,
    name: 'city',
  },
  {
    key: 7,
    label: <FormattedMessage defaultMessage="Kod pocztowy:" />,
    name: 'postCode',
    rules: [validatePostalCodeRule()],
  },
  {
    key: 9,
    label: <FormattedMessage defaultMessage="Kraj:" />,
    name: 'country',
    component: (
      <CountrySelect
        placeholder=""
        onChange={() => form.setFieldValue('province', null)}
      />
    ),
  },
  {
    key: 8,
    label: <FormattedMessage defaultMessage="Województwo:" />,
    name: 'province',
    hidden: countryCode !== LanguageCode.POLAND,
    component: (
      <RegionSelect
        placeholder={
          !countryCode && <FormattedMessage defaultMessage="Wybierz kraj" />
        }
        disabled={!countryCode}
      />
    ),
  },
  {
    key: 10,
    label: <FormattedMessage defaultMessage="WWW:" />,
    component: <Input addonBefore="https://" />,
    name: 'websiteUrl',
    rules: [validateUrlRule()],
  },
  {
    key: 11,
    label: <FormattedMessage defaultMessage="Facebook:" />,
    component: <Input addonBefore="https://" />,
    name: 'facebookUrl',
    rules: [validateUrlRule()],
  },
  {
    key: 12,
    label: <FormattedMessage defaultMessage="Linkedin:" />,
    component: <Input addonBefore="https://" />,
    name: 'linkedinUrl',
    rules: [validateUrlRule()],
  },
];
