/* eslint-disable react/jsx-props-no-spreading */
import type { OrderTranslationFileRow } from './config';
import { Space } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import Table from 'components/ui/molecules/Table';
import { FileTranslationType } from 'constants/fileTranslation';
import { useAppSelector } from 'ducks/store';
import { useOrderTranslationFilesServices } from '../../useOrderTranslationFilesServices';
import { columns } from './config';
import styles from './styles.module.scss';

const TranslationFilesTable = () => {
  const orderFiles = useAppSelector(
    ({ orderTranslationFiles }) => orderTranslationFiles.orderFiles,
    shallowEqual,
  );
  const { isLoading } = useOrderTranslationFilesServices();
  const sharedTableProps = {
    columns: columns(),
    isLoading,
  };

  const modifiedData = useMemo(
    (): OrderTranslationFileRow[] =>
      orderFiles.map((file) => ({
        ...file,
        key: file.id,
      })),
    [orderFiles],
  );

  const [normalTranslations, swornTranslations, correctionTranslations] =
    useMemo(
      () => [
        modifiedData.filter(({ type }) => type === FileTranslationType.NORMAL),
        modifiedData.filter(({ type }) => type === FileTranslationType.SWORN),
        modifiedData.filter(
          ({ type }) => type === FileTranslationType.CORRECTION,
        ),
      ],
      [modifiedData],
    );

  return (
    <Space className={styles.space} direction="vertical" size="large">
      {!!normalTranslations.length && (
        <Table
          {...sharedTableProps}
          cardTitle={<FormattedMessage defaultMessage="Tłumaczenie zwykłe" />}
          data={normalTranslations}
        />
      )}
      {!!swornTranslations.length && (
        <Table
          {...sharedTableProps}
          cardTitle={
            <FormattedMessage defaultMessage="Tłumaczenie przysięgłe" />
          }
          data={swornTranslations}
        />
      )}
      {!!correctionTranslations.length && (
        <Table
          {...sharedTableProps}
          cardTitle={<FormattedMessage defaultMessage="Korekta" />}
          data={correctionTranslations}
        />
      )}
    </Space>
  );
};

export default TranslationFilesTable;
