import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ONLY_NUMBERS_REGEX } from 'constants/regexes';
import { usePreordersServices } from 'views/Preorders';

export const usePreordersHeaderServices = () => {
  const { pathname } = useLocation();
  const preorderId = Number(pathname.match(ONLY_NUMBERS_REGEX)?.[0]);
  const { preorderData, isPreorderFetching } = usePreordersServices({
    preorderId,
  });

  const values = useMemo(
    () => ({ preorderId, preorderData, isPreorderFetching }),
    [preorderId, preorderData, isPreorderFetching],
  );

  return values;
};
