/* eslint-disable react/style-prop-object */
import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { TranslatorsPriceListItem } from 'ducks/translators/types';
import { FormattedMessage } from 'react-intl';
import LanguagePair from 'components/ui/atoms/LanguagePair';
import CurrencyItem from './components/CurrencyItem';

export type TranslatorPriceTableRow = TableRow & TranslatorsPriceListItem;

type Columns = ColumnsType<TranslatorPriceTableRow>;

const columnWidth = '15%';

const sharedColumns: Columns = [
  {
    title: <FormattedMessage defaultMessage="Tłumaczenie z języka" />,
    width: columnWidth,
    render: (_, { langFrom, langTo }) => (
      <LanguagePair langFrom={langFrom} langTo={langTo} />
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Normalne" />,
    render: (_, { normalFrom, normalTo }) => (
      <CurrencyItem from={normalFrom} to={normalTo} />
    ),
    width: columnWidth,
  },
  {
    title: <FormattedMessage defaultMessage="Specjalistyczne" />,
    render: (_, { specialFrom, specialTo }) => (
      <CurrencyItem from={specialFrom} to={specialTo} />
    ),
    width: columnWidth,
  },
];

export const regularColumns: Columns = [
  ...sharedColumns,
  {
    title: <FormattedMessage defaultMessage="Korekta" />,
    render: (_, { correctionFrom, correctionTo }) => (
      <CurrencyItem from={correctionFrom} to={correctionTo} />
    ),
    width: columnWidth,
  },
];

export const certifiedColumns: Columns = [
  ...sharedColumns,
  {
    title: <FormattedMessage defaultMessage="Uwierzytelnianie" />,
    render: (_, { authFrom, authTo }) => (
      <CurrencyItem from={authFrom} to={authTo} />
    ),
    width: columnWidth,
  },
];
