import type { FormValues } from '../config';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { ListItemRecord } from 'components/ui/molecules/List';
import type { ResponseFormItem } from 'ducks/settings/types';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSendSettingsMutation } from 'ducks/settings/service';
import { FormsIds, getListItems } from '../config';
import SettingsForm from '../SettingsForm';

const { Title } = Typography;

type Props = {
  notify: NotificationInstance;
  queryFormData: ResponseFormItem[];
  isLoading: boolean;
};

const InvoiceForm: React.FC<Props> = ({ notify, queryFormData, isLoading }) => {
  const [sendForm, { isLoading: isSendingLoading, isSuccess, isError }] =
    useSendSettingsMutation();

  const composeDataAndSend = async (values: FormValues) => {
    await sendForm({ sectionId: FormsIds.INVOICE_FORM, ...values });
  };

  return (
    <SettingsForm
      notify={notify}
      formElements={queryFormData}
      formTitle={
        <FormattedMessage defaultMessage="Edytuj dane rozliczeniowe" />
      }
      cardTitle={
        <Title level={4} style={{ margin: 0 }}>
          <FormattedMessage defaultMessage="Dane rozliczeniowe" />
        </Title>
      }
      listItems={getListItems(queryFormData) as ListItemRecord[]}
      isLoadingList={isLoading}
      isLoadingSend={isSendingLoading}
      isSuccessSend={isSuccess}
      isErrorSend={isError}
      onSubmit={composeDataAndSend}
    />
  );
};

export default InvoiceForm;
