import { FormattedMessage, useIntl } from 'react-intl';
import List from 'components/ui/molecules/List';
import { translatorTitles } from 'constants/translatorTitles';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import CardCommon from '../CardCommon';
import { basicInfoCardListItems } from './config';

const BasicInfoCard = () => {
  const intl = useIntl();
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const title = translatorTitles(intl).find(
    ({ value }) => translatorData?.title === value,
  );

  return (
    <CardCommon
      title={<FormattedMessage defaultMessage="Dane podstawowe" />}
      isLoading={isTranslatorFetching}
    >
      <List
        items={basicInfoCardListItems({ intl, data: translatorData, title })}
        isLoading={isTranslatorFetching}
      />
    </CardCommon>
  );
};

export default BasicInfoCard;
