import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import HorizontalCenteredWrapper from 'components/ui/atoms/HorizontalCenteredWrapper';
import { NO_DATA } from 'constants/strings';
import { useTranslatorProfileServices } from 'views/Translators/views/TranslatorProfile';
import CardCommon from '../../CardCommon';

const { Text } = Typography;

const SelectedTypesCard = () => {
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const types = translatorData?.types.filter(({ isMain }) => !!isMain) ?? [];

  return (
    <CardCommon
      data-testid="selectedTypesCard"
      title={<FormattedMessage defaultMessage="Wybrane typy" />}
      isLoading={isTranslatorFetching}
      xs={24}
      md={24}
    >
      <HorizontalCenteredWrapper isLoading={isTranslatorFetching}>
        {types.length ? (
          types.map(({ id, name }, index) => (
            <span key={id}>
              {name}
              {!!types[index + 1] && ','}
            </span>
          ))
        ) : (
          <Text>{NO_DATA}</Text>
        )}
      </HorizontalCenteredWrapper>
    </CardCommon>
  );
};

export default SelectedTypesCard;
