import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Table from 'components/ui/molecules/Table';
import { useGetTranslatorsOrdersQuery } from 'ducks/translators/service';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import { columns } from './config';

const OrderList = () => {
  const { translatorId, translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const { data = [], isFetching } = useGetTranslatorsOrdersQuery(
    {
      translatorId,
    },
    { skip: !translatorData },
  );
  const isLoading = isTranslatorFetching || isFetching;

  const modifiedData = useMemo(
    () =>
      data.map((row) => ({
        ...row,
        key: row.id,
      })),
    [data],
  );

  return (
    <Table
      data-testid="orderTable"
      cardTitle={<FormattedMessage defaultMessage="Lista zleceń" />}
      data={modifiedData}
      columns={columns}
      isLoading={isLoading}
    />
  );
};

export default OrderList;
