import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type {
  TranslatorQualificationFile,
  TranslatorQualificationItem,
} from 'ducks/translators/types';
import { Button } from 'antd';
import { GoCloudDownload } from 'react-icons/go';
import { FormattedMessage } from 'react-intl';

export type TranslatorQualificationRow = TableRow &
  TranslatorQualificationItem & {
    file: TranslatorQualificationFile | undefined;
  };

export const columnsEducation = (
  onClickDownload: (file: TranslatorQualificationFile) => Promise<void>,
): ColumnsType<TranslatorQualificationRow> => [
  {
    title: <FormattedMessage defaultMessage="Szkoła" />,
    key: 'field1',
    dataIndex: 'field1',
  },
  {
    title: <FormattedMessage defaultMessage="Kierunek" />,
    key: 'field2',
    dataIndex: 'field2',
  },
  {
    title: <FormattedMessage defaultMessage="Rok ukończenia" />,
    key: 'field3',
    dataIndex: 'field3',
  },
  {
    title: <FormattedMessage defaultMessage="Plik" />,
    key: 'file',
    dataIndex: 'file',
    render: (_, { key, files }) => {
      if (typeof files === 'undefined' || files.length === 0) return '';
      const file = files[0];

      return (
        <Button
          data-testid={`button-download-${key}`}
          type="link"
          icon={<GoCloudDownload />}
          onClick={() => onClickDownload(file)}
        >
          {file.filename}
        </Button>
      );
    },
  },
];

export const columnsCartificates = (
  onClickDownload: (file: TranslatorQualificationFile) => Promise<void>,
): ColumnsType<TranslatorQualificationRow> => [
  {
    title: <FormattedMessage defaultMessage="Nazwa" />,
    key: 'field1',
    dataIndex: 'field1',
  },
  {
    title: <FormattedMessage defaultMessage="Ocena" />,
    key: 'field2',
    dataIndex: 'field2',
  },
  {
    title: <FormattedMessage defaultMessage="Rok wydania" />,
    key: 'field3',
    dataIndex: 'field3',
  },
  {
    title: <FormattedMessage defaultMessage="Plik" />,
    key: 'file',
    dataIndex: 'file',
    render: (_, { key, files }) => {
      if (typeof files === 'undefined' || files.length === 0) return '';
      const file = files[0];

      return (
        <Button
          data-testid={`button-download-${key}`}
          type="link"
          icon={<GoCloudDownload />}
          onClick={() => onClickDownload(file)}
        >
          {file.filename}
        </Button>
      );
    },
  },
];

export const columnsReferences = (
  onClickDownload: (file: TranslatorQualificationFile) => Promise<void>,
): ColumnsType<TranslatorQualificationRow> => [
  {
    title: <FormattedMessage defaultMessage="Imie i nazwisko" />,
    key: 'field1',
    dataIndex: 'field1',
  },
  {
    title: <FormattedMessage defaultMessage="Firma" />,
    key: 'field2',
    dataIndex: 'field2',
  },
  {
    title: <FormattedMessage defaultMessage="Telefon" />,
    key: 'field3',
    dataIndex: 'field3',
  },
  {
    title: <FormattedMessage defaultMessage="Email" />,
    key: 'field4',
    dataIndex: 'field4',
  },
  {
    title: <FormattedMessage defaultMessage="Plik" />,
    key: 'file',
    dataIndex: 'file',
    render: (_, { key, files }) => {
      if (typeof files === 'undefined' || files.length === 0) return '';
      const file = files[0];

      return (
        <Button
          data-testid={`button-download-${key}`}
          type="link"
          icon={<GoCloudDownload />}
          onClick={() => onClickDownload(file)}
        >
          {file.filename}
        </Button>
      );
    },
  },
];

export const columnsProjects = (
  onClickDownload: (file: TranslatorQualificationFile) => Promise<void>,
): ColumnsType<TranslatorQualificationRow> => [
  {
    title: <FormattedMessage defaultMessage="Temat" />,
    key: 'field1',
    dataIndex: 'field1',
  },
  {
    title: <FormattedMessage defaultMessage="Zleceniodawca" />,
    key: 'field2',
    dataIndex: 'field2',
  },
  {
    title: <FormattedMessage defaultMessage="Rok" />,
    key: 'field3',
    dataIndex: 'field3',
  },
  {
    title: <FormattedMessage defaultMessage="Plik" />,
    key: 'file',
    dataIndex: 'file',
    render: (_, { key, files }) => {
      if (typeof files === 'undefined' || files.length === 0) return '';
      const file = files[0];

      return (
        <Button
          data-testid={`button-download-${key}`}
          type="link"
          icon={<GoCloudDownload />}
          onClick={() => onClickDownload(file)}
        >
          {file.filename}
        </Button>
      );
    },
  },
];

export const columnsOther = (
  onClickDownload: (file: TranslatorQualificationFile) => Promise<void>,
): ColumnsType<TranslatorQualificationRow> => [
  {
    title: <FormattedMessage defaultMessage="Nazwa" />,
    key: 'field1',
    dataIndex: 'field1',
  },
  {
    title: <FormattedMessage defaultMessage="Plik" />,
    key: 'file',
    dataIndex: 'file',
    render: (_, { key, files }) => {
      if (typeof files === 'undefined' || files.length === 0) return '';
      const file = files[0];

      return (
        <Button
          data-testid={`button-download-${key}`}
          type="link"
          icon={<GoCloudDownload />}
          onClick={() => onClickDownload(file)}
        >
          {file.filename}
        </Button>
      );
    },
  },
];
