import type { OrderTranslationFilesState } from 'ducks/view/orderTranslationFiles';
import type { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { NO_DATA } from 'constants/strings';
import { useAppSelector } from 'ducks/store';
import styles from '../../styles.module.scss';

type Props = {
  orderFileLangs: OrderTranslationFilesState['orderFiles'][number]['orderFileLangs'];
};

const TranslationFilesColumn: FC<Props> = ({ orderFileLangs }) => {
  const uploadedTranslationsFiles = useAppSelector(
    ({ orderTranslationFiles }) =>
      orderTranslationFiles.uploadedFiles.translations,
    shallowEqual,
  );

  const getTranslationFile = (id: number) =>
    uploadedTranslationsFiles.find((el) => el.id === id);

  const getFilename = (fileId: number) =>
    getTranslationFile(fileId)?.filename ?? NO_DATA;

  return (
    <div className={styles.multilineCell}>
      {orderFileLangs.map(({ orderTranslationFiles }) =>
        orderTranslationFiles.map(({ id, fileId }) => (
          <div key={id}>{getFilename(fileId)}</div>
        )),
      )}
    </div>
  );
};

export default TranslationFilesColumn;
