import type { TranslatorQualificationRow } from './config';
import type { TranslatorQualificationFile } from 'ducks/translators/types';
import { Button, Col, Row, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Table from 'components/ui/molecules/Table/Table';
import { getFile } from 'ducks/global/service';
import { useGetTranslatorsQualificationsQuery } from 'ducks/translators/service';
import { downloadFile } from 'utilities/downloadFile';
import { Routes } from 'views/Translators/Routing';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import {
  columnsCartificates,
  columnsEducation,
  columnsOther,
  columnsProjects,
  columnsReferences,
} from './config';

const tableMapping = (
  onClickDownload: (props: TranslatorQualificationFile) => Promise<void>,
) => [
  {
    name: 'Wykształcenie',
    title: <FormattedMessage defaultMessage="Wykształcenie" />,
    columns: columnsEducation(onClickDownload),
    scrollIndex: 0,
  },
  {
    name: 'Certyfikaty',
    title: <FormattedMessage defaultMessage="Certyfikaty" />,
    columns: columnsCartificates(onClickDownload),
    scrollIndex: 1,
  },
  {
    name: 'Referencje',
    title: <FormattedMessage defaultMessage="Referencje" />,
    columns: columnsReferences(onClickDownload),
    scrollIndex: 2,
  },
  {
    name: 'Projekty',
    title: <FormattedMessage defaultMessage="Projekty" />,
    columns: columnsProjects(onClickDownload),
    scrollIndex: 3,
  },
  {
    name: 'Inne',
    title: <FormattedMessage defaultMessage="Inne" />,
    columns: columnsOther(onClickDownload),
    scrollIndex: 4,
  },
];
const TranslatorQualifications = () => {
  const { translatorId, translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const { data = [], isLoading: isQualificationsLoading } =
    useGetTranslatorsQualificationsQuery(
      {
        translatorId,
      },
      { skip: !translatorData },
    );
  const isLoading = isTranslatorFetching || isQualificationsLoading;

  const onClickDownload = async (file: TranslatorQualificationFile) => {
    const { id, object, filename } = file;
    const response = await getFile({ id, object });

    if (response) {
      const url = URL.createObjectURL(response as File);

      downloadFile(url, filename);
    }
  };

  return (
    <Space
      direction="vertical"
      size="large"
      className="width-100"
      data-testid="translatorQualifications"
    >
      {tableMapping(onClickDownload).map(
        ({ title, columns, name, scrollIndex }) => (
          <Row
            key={scrollIndex}
            gutter={[0, 24]}
            align="top"
            className="width-100"
          >
            <Col xs={24}>
              <Table<TranslatorQualificationRow>
                cardTitleExtra={
                  <Link
                    to={Routes.TRANSLATOR_PROFILE_QUALIFICATION.replace(
                      ':id',
                      translatorId.toString(),
                    )}
                    state={{ indexOfClicked: scrollIndex }}
                  >
                    <Button data-testid="editDataButton" type="primary">
                      <FormattedMessage defaultMessage="Edytuj" />
                    </Button>
                  </Link>
                }
                cardTitle={title}
                columns={columns}
                data={
                  data
                    .find((type) => type.name === name)
                    ?.items.map((item) => ({
                      ...item,
                      file: item.files?.[0], // we wanna display only 1 file
                      key: item.id,
                    })) ?? []
                }
                isLoading={isLoading}
              />
            </Col>
          </Row>
        ),
      )}
    </Space>
  );
};

export default TranslatorQualifications;
