import type { FC } from 'react';
import { Popconfirm } from 'antd';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { useAppDispatch } from 'ducks/store';
import {
  deleteOrderTranslationFile,
  type OrderTranslationFilesState,
} from 'ducks/view/orderTranslationFiles';
import { TrashIcon } from 'icons';
import styles from '../../styles.module.scss';

type Props = {
  orderFileId: number;
  orderFileLangs: OrderTranslationFilesState['orderFiles'][number]['orderFileLangs'];
};

const ActionsColumn: FC<Props> = ({ orderFileId, orderFileLangs }) => {
  const dispatch = useAppDispatch();

  const handleDelete = async (translationFileId: number | string) =>
    dispatch(deleteOrderTranslationFile({ orderFileId, translationFileId }));

  return (
    <div className={styles.multilineCell}>
      {orderFileLangs.map(({ orderTranslationFiles }) =>
        orderTranslationFiles.map(({ id: translationFileId }) => (
          <Popconfirm
            key={translationFileId}
            title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
            okText={<FormattedMessage defaultMessage="Tak" />}
            cancelText={<FormattedMessage defaultMessage="Nie" />}
            onConfirm={() => handleDelete(translationFileId)}
          >
            <Button size="small" type="link" icon={<TrashIcon />} danger>
              <FormattedMessage defaultMessage="Usuń" />
            </Button>
          </Popconfirm>
        )),
      )}
    </div>
  );
};

export default ActionsColumn;
