import type { UploadOnChangeParam } from 'components/ui/molecules/Upload';
import { imageFileTypes } from 'constants/fileTypes';

type Options = {
  multiple?: boolean;
  fileTypes?: readonly string[];
  onError?: () => void;
};

export const filePicker = (
  files: UploadOnChangeParam,
  { multiple = true, fileTypes = imageFileTypes, onError }: Options = {},
) => {
  const { fileList } = files;

  if (!multiple) {
    const file = fileList[0].originFileObj;

    if (fileList.length >= 2 || !file) return [];

    const { type } = file;

    if (!fileTypes.includes(type)) {
      onError?.();

      return [];
    }

    return [file];
  }

  const hasError = fileList.some(({ type = '' }) => !fileTypes.includes(type));

  if (hasError) {
    onError?.();

    return [];
  }

  return fileList.map(({ originFileObj }) => originFileObj);
};
