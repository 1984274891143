/* eslint-disable react/jsx-props-no-spreading */
import type { EditCorrespondenceFormValues } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { GusReturnProps } from 'components/ui/atoms/GusButton';
import type { Client } from 'ducks/clients/types';
import type { FC } from 'react';
import { Button, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal/Modal';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import { useUpdateClientCorrespondenceMutation } from 'ducks/clients/service';
import { editCorrespondenceFormItems } from './config';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: Client;
  notify: NotificationInstance;
}

const { useForm, useWatch } = Form;

const EditCorrespondenceModal: FC<Props> = ({
  isOpen,
  onClose,
  notify,
  data,
}) => {
  const [form] = useForm<EditCorrespondenceFormValues>();
  const country = useWatch('country', form);
  const [updateClientCorrespondence, { isLoading }] =
    useUpdateClientCorrespondenceMutation();
  const initialValues: EditCorrespondenceFormValues = data ?? {
    country: LanguageCode.POLAND,
  };

  const nip = useWatch('nip', form) ?? '';

  const handleOnSubmit = async () => {
    if (isLoading) return;

    const values = await form.validateFields().catch((err) => err);

    if (values?.errorFields?.length) return;

    try {
      await updateClientCorrespondence({
        ...values,
        country: values.country ?? '',
        clientId: data.id,
      }).unwrap();

      onClose();
      notify.success({
        message: (
          <FormattedMessage defaultMessage="Dane korespondencyjne zostały edytowane." />
        ),
      });
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania danych korespondencyjnych." />
        ),
      });
    }
  };

  const handleOnCancel = () => {
    form.resetFields();
    onClose();
  };

  const handleOnCopy = () => {
    form.setFieldsValue({
      name: data.invoiceName,
      nip: data.invoiceNip,
      address: data.invoiceAddress,
      city: data.invoiceCity,
      postCode: data.invoicePostCode,
      country: data.invoiceCountry,
    });
  };

  const handleCheckNip = ({
    nip: invoiceNip,
    city,
    post_code: postCode,
    street,
    addressNumbers,
  }: GusReturnProps) => {
    form.setFieldsValue({
      nip: invoiceNip,
      city,
      postCode,
      address: `${street} ${addressNumbers.join(', ')}`,
    });
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Edytuj dane korespondencyjne" />}
      isLoading={isLoading}
      open={isOpen}
      footer={[
        <Button
          data-testid="copyInvoiceDataButton"
          key="copy"
          onClick={handleOnCopy}
          disabled={isLoading}
        >
          <FormattedMessage defaultMessage="Skopiuj z danych rozliczeniowych" />
        </Button>,
        <Button key="cancel" onClick={handleOnCancel}>
          <FormattedMessage defaultMessage="Anuluj" />
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleOnSubmit}
          disabled={isLoading}
        >
          <FormattedMessage defaultMessage="Zapisz" />
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        {editCorrespondenceFormItems({
          nip,
          handleCheckNip,
          country,
        }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default EditCorrespondenceModal;
