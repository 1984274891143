import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { AddPriceRequest } from 'ducks/prices/types';
import { FormattedMessage } from 'react-intl';

export type AddPriceModalValues = AddPriceRequest;

export const initialValues: AddPriceModalValues = {
  name: '',
};

export const formItems: FormItemRecord<AddPriceModalValues>[] = [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Nazwa" />,
    name: 'name',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj nazwę" />,
      },
    ],
  },
];
