import type { InvoicesPaymentIndividualRow } from './config';
import type {
  GetPaymentIndividualsFilter,
  GetPaymentIndividualsSort,
} from 'ducks/payments/types';
import { Button, Space } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Table from 'components/ui/molecules/Table';
import { useGetPaymentIndividualsQuery } from 'ducks/payments/service';
import useTablePagination from 'hooks/useTablePagination';
import { getExportUrl } from 'utilities/api';
import { downloadFile } from 'utilities/downloadFile';
import InvoicesPaymentIndividualsToolbar from './components/InvoicesPaymentIndividualsToolbar';
import { columns, initialPaymentIndividualsFilters } from './config';

const InvoicesPaymentIndividuals = () => {
  const intl = useIntl();
  const {
    page,
    handlePageChange,
    filter,
    filterBy,
    handleFilterBy,
    sortBy,
    handleTableChange,
  } = useTablePagination<
    InvoicesPaymentIndividualRow,
    GetPaymentIndividualsFilter
  >({ initialFilters: initialPaymentIndividualsFilters });
  const sort = sortBy?.order
    ? {
        [sortBy.columnKey as keyof GetPaymentIndividualsSort]: sortBy.order,
      }
    : ({
        datePayment: 'descend',
      } as GetPaymentIndividualsSort);
  const { data, isFetching } = useGetPaymentIndividualsQuery({
    page,
    filter,
    sort,
  });
  const pageSize = data?.perPage;
  const total = isFetching ? 0 : data?.total;

  const modifiedData = useMemo(
    (): InvoicesPaymentIndividualRow[] =>
      (data?.data ?? []).map((invoiceVat) => ({
        ...invoiceVat,
        key: invoiceVat.id,
      })),
    [data],
  );

  const handleDownload = () => {
    downloadFile(
      getExportUrl(
        `payment-individuals?${new URLSearchParams(
          JSON.stringify({ ...filterBy }),
        )}`,
      ),
      'export.csv',
    );
  };

  return (
    <Space className="width-100 layout-content mb-24" direction="vertical">
      <InvoicesPaymentIndividualsToolbar
        filterBy={filterBy}
        onFilterByChange={handleFilterBy}
      />
      <Table
        cardTitle={
          <FormattedMessage defaultMessage="Lista wpłat od osób fizycznych" />
        }
        cardTitleExtra={
          <Button type="primary" onClick={handleDownload}>
            <FormattedMessage defaultMessage="Eksportuj do CSV" />
          </Button>
        }
        data={modifiedData}
        columns={columns({ intl })}
        isLoading={isFetching}
        onChange={handleTableChange}
        pagination={{
          current: page,
          pageSize,
          total,
          onChange: handlePageChange,
        }}
      />
    </Space>
  );
};

export default InvoicesPaymentIndividuals;
