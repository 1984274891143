import { Row, Space } from 'antd';
import Empty from 'components/ui/atoms/Empty';
import AccountingNotesSection from './components/AccountingNotesSection';
import ClientDataSection from './components/ClientDataSection';
import CourtSection from './components/CourtSection';
import DebtCollectionsSection from './components/DebtCollectionsSection';
import InvoiceOverdueDetailsHeader from './components/InvoiceOverdueDetailsHeader';
import LetterSummonsSection from './components/LetterSummonsSection';
import NotesSection from './components/NotesSection';
import ObligationDataSection from './components/ObligationDataSection';
import { useInvoiceOverdueDetailsServices } from './useInvoiceOverdueDetailsServices';

const InvoiceOverdueDetails = () => {
  const { isInvoiceOverdueDataEmpty } = useInvoiceOverdueDetailsServices();

  if (isInvoiceOverdueDataEmpty) return <Empty />;

  return (
    <Space
      className="layout-content mb-24 width-100"
      direction="vertical"
      size="large"
    >
      <InvoiceOverdueDetailsHeader />
      <Row gutter={[24, 24]}>
        <ClientDataSection />
        <ObligationDataSection />
        <NotesSection />
        <DebtCollectionsSection />
        <AccountingNotesSection />
        <LetterSummonsSection />
        <CourtSection />
      </Row>
    </Space>
  );
};

export default InvoiceOverdueDetails;
