import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import Loader from 'components/ui/atoms/Loader';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useAppSelector } from 'ducks/store';
import { useOrdersServices } from 'views/Orders/useOrdersServices';
import TranslatorPanelContent from './components/TranslatorPanelContent';
import TranslatorPanelHeader from './components/TranslatorPanelHeader';
import styles from './styles.module.scss';

const TranslatorsCollapseSection = () => {
  const translatorEmails = useAppSelector(
    (state) => state.orders.sendEmails.translatorEmails,
    shallowEqual,
  );
  const { isOrderFetching } = useOrdersServices();

  const defaultActiveKey =
    translatorEmails.length === 1 ? translatorEmails?.[0]?.translatorId : [];

  return (
    <CardCommon title={<FormattedMessage defaultMessage="Tłumacze" />}>
      <Loader visible={isOrderFetching} style={{ marginBottom: '30px' }}>
        {translatorEmails.length > 0 && (
          <Collapse
            className={styles.collapse}
            bordered={false}
            defaultActiveKey={defaultActiveKey}
          >
            {translatorEmails.map((translatorEmail) => (
              <Collapse.Panel
                key={translatorEmail.translatorId}
                header={
                  <TranslatorPanelHeader translatorEmail={translatorEmail} />
                }
              >
                <TranslatorPanelContent translatorEmail={translatorEmail} />
              </Collapse.Panel>
            ))}
          </Collapse>
        )}
      </Loader>
    </CardCommon>
  );
};

export default TranslatorsCollapseSection;
