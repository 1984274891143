import type { HeaderCardCol } from './config';
import type { FC, ReactNode } from 'react';
import { Card, Col, Row, Skeleton, Typography } from 'antd';
import classNames from 'classnames';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
  startChildren?: ReactNode;
  children?: ReactNode;
  cols: HeaderCardCol[];
  isLoading: boolean;
  className?: string;
};

const HeaderCard: FC<Props> = ({
  startChildren,
  children,
  cols,
  isLoading,
  className = '',
}) => {
  return (
    <Card className={classNames(styles.card, className)}>
      <div className={styles.cardTitleWrapper}>
        <Row
          className={styles.row}
          style={{ marginLeft: 0, marginRight: 0 }}
          gutter={[24, 24]}
          align="middle"
        >
          {startChildren && !isLoading && <Col>{startChildren}</Col>}
          {cols.map(({ key, firstRow, secondRow }) => (
            <Col
              key={key}
              className={classNames({
                [styles.col]: cols.some((col) => !!col.secondRow),
              })}
            >
              <div className={styles.colContent}>
                <Text ellipsis style={{ display: 'flex', alignSelf: 'start' }}>
                  <Skeleton
                    loading={isLoading}
                    round
                    paragraph={false}
                    active
                    style={{ width: '75px' }}
                    className={styles.firstSkeleton}
                  >
                    {firstRow}
                  </Skeleton>
                </Text>
                {secondRow && (
                  <Skeleton
                    loading={isLoading}
                    round
                    paragraph={false}
                    active
                    className={styles.secondSkeleton}
                  >
                    {secondRow}
                  </Skeleton>
                )}
              </div>
            </Col>
          ))}
          <div className={styles.additionalContent}>
            {!isLoading && children}
          </div>
        </Row>
      </div>
    </Card>
  );
};

export default HeaderCard;
