import { Space } from 'antd';
import PriceList from './components/PriceList';

const TranslatorPrice = () => {
  return (
    <Space size="large" className="width-100" direction="vertical">
      <PriceList />
    </Space>
  );
};

export default TranslatorPrice;
