import type { ReactElement } from 'react';
import { Layout, Drawer, Affix } from 'antd';
import cn from 'classnames';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import Sidenav, { getMenu } from 'components/layout/Sidenav';

import styles from './styles.module.scss';
import 'antd/dist/reset.css';
import './styles/layout.scss';

const { Header: AntHeader, Content, Sider } = Layout;

type Props = {
  children: ReactElement;
  headerFixed: boolean;
  layoutRtl: boolean;
  sidenavMobileOpened: boolean;
  sidenavColor: string;
  setSidenavMobileOpened: (isOpened: boolean) => void;
};

const Main: React.FC<Props> = ({
  children,
  headerFixed,
  sidenavColor,
  layoutRtl,
  sidenavMobileOpened,
  setSidenavMobileOpened,
}) => {
  const { pathname } = useLocation();

  const intl = useIntl();
  const breadcrumbItemName = useMemo(
    () =>
      getMenu(intl).find(({ url }) => url === `/${pathname.split('/')[1]}`)
        ?.label || '',
    [pathname, intl],
  );

  return (
    <Layout
      className={cn('layout-dashboard', { 'layout-dashboard-rtl': layoutRtl })}
    >
      <Drawer
        title={false}
        placement={layoutRtl ? 'right' : 'left'}
        closable={false}
        onClose={() => setSidenavMobileOpened(false)}
        open={sidenavMobileOpened}
        key={layoutRtl ? 'right' : 'left'}
        width={250}
        className={cn('drawer-sidebar', { 'drawer-sidebar-rtl': layoutRtl })}
      >
        <Layout
          className={cn('layout-dashboard', {
            'layout-dashboard-rtl': layoutRtl,
          })}
        >
          <Sider
            trigger={null}
            width={250}
            theme="light"
            className={cn('sider-primary', 'ant-layout-sider-primary', {
              'active-route': sidenavColor === 'transparent',
            })}
            style={{ background: sidenavColor }}
          >
            <Sidenav />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}
        width={250}
        theme="light"
        className={cn('sider-primary', 'ant-layout-sider-primary', {
          'active-route': sidenavColor === 'transparent',
        })}
        style={{ background: sidenavColor }}
      >
        <Sidenav />
      </Sider>
      <Layout>
        {headerFixed ? (
          <Affix className={styles.fixedHeader}>
            <AntHeader className="ant-header-fixed">
              <Header name={breadcrumbItemName} subName={breadcrumbItemName} />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader>
            <Header name={breadcrumbItemName} subName={breadcrumbItemName} />
          </AntHeader>
        )}
        <Content className="content-ant">{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default Main;
