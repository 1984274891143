import { FormattedMessage } from 'react-intl';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import List from 'components/ui/molecules/List';
import { useGetTranslatorsTypesQuery } from 'ducks/translators/service';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import { listSkeletions } from '../../utils';
import CardCommon from '../CardCommon';

const TranslatorTypeCard = () => {
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const { data: typesData = [], isLoading: isTypesLoading } =
    useGetTranslatorsTypesQuery(undefined, { skip: !translatorData });
  const selectedTypes = translatorData?.types ?? [];
  const types = typesData.filter(({ isHidden }) => !isHidden);
  const isLoading = isTranslatorFetching || isTypesLoading;

  return (
    <CardCommon
      title={<FormattedMessage defaultMessage="Typ tłumacza" />}
      isLoading={isLoading}
      data-testid="translatorTypeCard"
    >
      <List
        items={
          isLoading
            ? listSkeletions
            : types.map(({ id, name }) => ({
                label: name,
                component: (
                  <CircleCheckbox
                    data-testid={`checkbox${name}${id}`}
                    value={selectedTypes.some((tool) => tool.id === id)}
                  />
                ),
                skeletonRows: 1,
              }))
        }
        withoutMargin
        isLoading={isLoading}
      />
    </CardCommon>
  );
};

export default TranslatorTypeCard;
