import type { GetInvoicesVatFilter } from 'ducks/invoices/types';
import type { InvoiceListItemRow } from 'views/Invoices/config';
import { Space } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Table from 'components/ui/molecules/Table';
import { useGetInvoicesVatQuery } from 'ducks/invoices/service';
import { useAppDispatch } from 'ducks/store';
import { setInvoicesOrderIds } from 'ducks/view/invoicesAdd';
import useTablePagination from 'hooks/useTablePagination';
import InvoicesTableToolbar from 'views/Invoices/components/InvoicesTableToolbar';
import { initialInvoicesFilters } from 'views/Invoices/config';
import GenerateCollectiveInvoiceButton from './components/GenerateCollectiveInvoiceButton';
import { columns } from './config';

const InvoicesVat = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {
    page,
    handlePageChange,
    searchTerm,
    searchValue,
    handleSearchValue,
    filter,
    filterBy,
    handleFilterBy,
  } = useTablePagination<InvoiceListItemRow, GetInvoicesVatFilter>({
    initialFilters: initialInvoicesFilters,
  });
  const { data, isFetching } = useGetInvoicesVatQuery({
    page,
    searchTerm,
    filter,
  });
  const pageSize = data?.perPage;
  const total = isFetching ? 0 : data?.total;

  const handleSetInvoicesOrderIds = (orderId: number) =>
    dispatch(setInvoicesOrderIds([orderId]));

  const modifiedData = useMemo(
    (): InvoiceListItemRow[] =>
      (data?.data ?? []).map((invoiceVat) => ({
        ...invoiceVat,
        key: invoiceVat.id,
      })),
    [data],
  );

  return (
    <Space className="width-100 layout-content mb-24" direction="vertical">
      <InvoicesTableToolbar
        searchValue={searchValue}
        onSearchValueChange={handleSearchValue}
        filterBy={filterBy}
        onFilterByChange={handleFilterBy}
      />
      <Table
        cardTitle={<FormattedMessage defaultMessage="Faktury VAT" />}
        data={modifiedData}
        columns={columns({ intl, handleSetInvoicesOrderIds })}
        isLoading={isFetching}
        cardExtra={<GenerateCollectiveInvoiceButton />}
        pagination={{
          current: page,
          pageSize,
          total,
          onChange: handlePageChange,
        }}
      />
    </Space>
  );
};

export default InvoicesVat;
