import { LanguageCode } from './countryAndLanguageCodes';

export enum LanguagePopularityStatus {
  POPULAR = 'popular',
  UNPOPULAR = 'unpopular',
}

export const DEFAULT_LANGUAGE_MULTIPLIER = 1;
export const POPULAR_LANGUAGE_MULTIPLIER = 1;
export const POPULAR_LANGUAGE_SWORN_MULTIPLIER = 1.25;
export const UNPOPULAR_LANGUAGE_SWORN_MULTIPLIER = 1.5;
export const POPULAR_LANGUAGE_SPECIAL_MULTIPLIER = 1.25;
export const UNPOPULAR_LANGUAGE_SPECIAL_MULTIPLIER = 1.5;
export const POPULAR_LANGUAGE_SWORN_SPECIAL_MULTIPLIER = 1.5;
export const UNPOPULAR_LANGUAGE_SWORN_SPECIAL_MULTIPLIER = 1.75;

export const popularLanguages: string[] = [
  LanguageCode.POLAND,
  LanguageCode.ENGLISH,
  LanguageCode.GERMAN,
  LanguageCode.UKRAINE,
  LanguageCode.RUSSIAN,
  LanguageCode.ITALIAN,
  LanguageCode.FRENCH,
  LanguageCode.SPANISH,
];

export const wages = {
  [LanguagePopularityStatus.POPULAR]: 1,
  [LanguagePopularityStatus.UNPOPULAR]: 1.25,
  isSpecialized: 1.25,
  isSworn: 1.25,
  isCorrection: 2,
};

export const pagePerDay = {
  threshold: [36, 100],
  [LanguagePopularityStatus.POPULAR]: [8, 14, 16],
  [LanguagePopularityStatus.UNPOPULAR]: [6, 9, 11],
};

export const minTranslationLangsDays = {
  [LanguagePopularityStatus.POPULAR]: 1,
  [LanguagePopularityStatus.UNPOPULAR]: 2,
};
