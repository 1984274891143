import type { ReactElement } from 'react';
import { Skeleton } from 'antd';
import { RiNumbersFill } from 'react-icons/ri';
import styles from './styles.module.scss';

type Props = {
  stat: number | undefined;
  title: ReactElement;
  statColor: string;
};

const StatTile: React.FC<Props> = ({ stat, title, statColor }) => {
  return (
    <div className={styles.statTile}>
      <p
        style={{
          color: statColor,
        }}
      >
        {stat ?? (
          <Skeleton.Node active>
            <RiNumbersFill style={{ fontSize: 40, color: '#bfbfbf' }} />
          </Skeleton.Node>
        )}
      </p>
      <h5> {title} </h5>
    </div>
  );
};

export default StatTile;
