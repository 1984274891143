/* eslint-disable react/jsx-props-no-spreading */
import type { TruncateProps } from '../TruncateText/TruncateText';
import { Skeleton } from 'antd';
import { useMemo, type FC } from 'react';
import { useGetLanguagesQuery } from 'ducks/global/service';
import TruncateText from '../TruncateText';

interface Props extends Partial<TruncateProps> {
  countryCode: string;
  showCode?: boolean;
}

const LanguageName: FC<Props> = ({
  countryCode,
  showCode = false,
  ...truncateTextProps
}) => {
  const { data = [], isLoading } = useGetLanguagesQuery();

  const languageName = useMemo(() => {
    if (!data.length) return '';

    const language = data.find(({ code }) => code === countryCode);

    return language?.name ?? '';
  }, [data, countryCode]);

  const displayName = useMemo(
    () =>
      showCode
        ? `${languageName} (${countryCode.toUpperCase()})`
        : languageName,
    [showCode, languageName, countryCode],
  );

  if (isLoading) {
    return <Skeleton paragraph={false} active style={{ width: '75px' }} />;
  }

  return <TruncateText {...truncateTextProps}>{displayName}</TruncateText>;
};
export default LanguageName;
