import type { UpdatePreorderValues } from '../../config';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { GusReturnProps } from 'components/ui/atoms/GusButton';
import type { Preorder } from 'ducks/preorders/types';
import type { IntlShape } from 'react-intl';
import { InputNumber, Select, Switch } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CopyToClipboardWithText from 'components/ui/atoms/CopyToClipboard/CopyToClipboardWithText';
import CountryName from 'components/ui/atoms/CountryName';
import CountrySelect from 'components/ui/atoms/CountrySelect';
import GusButton from 'components/ui/atoms/GusButton';
import { POLAND_COUNTRY_CODE } from 'constants/countryAndLanguageCodes';
import { MAX_NIP_LENGTH, MAX_VAT_NUMBER } from 'constants/formInputLengths';
import {
  preorderInvoiceTypes,
  preorderInvoiceTypesObject,
} from 'constants/preorder';
import { NO_DATA } from 'constants/strings';
import { validateNipRule } from 'utilities/antdFormRules';

type Props = {
  intl: IntlShape;
  data: Preorder;
  isCompany: boolean;
  nip: string;
  country: string;
  handleCheckNipCallback: (data: GusReturnProps) => void;
  handleSetPreorderVatRate: (vatRate: number) => void;
  isEditable: boolean;
};

export const formItems = ({
  intl,
  data,
  isCompany,
  nip,
  country,
  handleCheckNipCallback,
  handleSetPreorderVatRate,
  isEditable,
}: Props): FormItemRecord<UpdatePreorderValues>[] => {
  const {
    vatRate,
    invoiceEmail,
    verifiedVies,
    name,
    address,
    city,
    postCode,
    invoiceAuto,
    invoiceType,
    hasInvoiceDataConvergence,
  } = data;
  const isInternational = country !== POLAND_COUNTRY_CODE;

  return [
    {
      key: 'isCompany',
      label: <FormattedMessage defaultMessage="Typ klienta:" />,
      name: 'isCompany',
      component: isEditable ? (
        <Switch
          checkedChildren={<FormattedMessage defaultMessage="Firma" />}
          unCheckedChildren={
            <FormattedMessage defaultMessage="Osoba fizyczna" />
          }
        />
      ) : (
        <strong>
          {isCompany ? (
            <FormattedMessage defaultMessage="Firma" />
          ) : (
            <FormattedMessage defaultMessage="Osoba fizyczna" />
          )}
        </strong>
      ),
      additionalItemProps: { valuePropName: 'checked' },
    },
    {
      key: 'invoiceType',
      label: <FormattedMessage defaultMessage="Rodzaj faktury:" />,
      name: 'invoiceType',
      component: isEditable ? (
        <Select options={preorderInvoiceTypes(intl)} />
      ) : (
        <strong>
          {invoiceType
            ? preorderInvoiceTypesObject(intl)[invoiceType].label
            : NO_DATA}
        </strong>
      ),
    },
    {
      key: 'invoiceEmail',
      label: <FormattedMessage defaultMessage="E-mail:" />,
      name: 'invoiceEmail',
      component: !isEditable && (
        <strong>
          {invoiceEmail ? (
            <CopyToClipboardWithText
              text={invoiceEmail}
              truncate
              truncateSize="medium"
              truncateMaxLength={16}
              strong
            />
          ) : (
            NO_DATA
          )}
        </strong>
      ),
      rules: [
        {
          required: true,
          message: <FormattedMessage defaultMessage="Podaj email" />,
        },
        {
          type: 'email',
          message: (
            <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
          ),
        },
      ],
    },
    {
      key: 'country',
      label: <FormattedMessage defaultMessage="Kraj:" />,
      name: 'country',
      component: isEditable ? (
        <CountrySelect />
      ) : (
        <strong>
          <CountryName countryCode={country} />
        </strong>
      ),
    },
    {
      key: 'nip',
      label: <FormattedMessage defaultMessage="NIP:" />,
      name: 'nip',
      component: !isEditable && (
        <strong data-testid="nip">{nip ?? NO_DATA}</strong>
      ),
      rules: [
        validateNipRule({
          isInternational,
        }),
      ],
      additionalInputProps: { maxLength: MAX_NIP_LENGTH },
      hidden: !isCompany,
    },
    {
      key: 'gusButton',
      component: (
        <GusButton onSuccessCallback={handleCheckNipCallback} value={nip} />
      ),
      hidden: isInternational || !isCompany || !isEditable,
    },
    {
      key: 'vatRate',
      label: <FormattedMessage defaultMessage="VAT:" />,
      name: 'vatRate',
      component: isEditable ? (
        <InputNumber
          data-testid="vatRateInput"
          min={0}
          max={MAX_VAT_NUMBER}
          onChange={(value) => handleSetPreorderVatRate(value ?? 0)}
          addonAfter="%"
        />
      ) : (
        <strong data-testid="vatRate">{`${vatRate}%`}</strong>
      ),
    },
    {
      key: 'vies',
      label: <FormattedMessage defaultMessage="VIES:" />,
      component: (
        <CircleCheckbox data-testid="verifiedVies" value={verifiedVies} />
      ),
    },
    {
      key: 'name',
      label: <FormattedMessage defaultMessage="Nazwa:" />,
      name: 'name',
      component: !isEditable && <strong>{name ?? NO_DATA}</strong>,
      rules: [
        {
          required: true,
          message: <FormattedMessage defaultMessage="Podaj nazwę" />,
        },
      ],
    },
    {
      key: 'address',
      label: <FormattedMessage defaultMessage="Ulica:" />,
      name: 'address',
      component: !isEditable && <strong>{address ?? NO_DATA}</strong>,
    },
    {
      key: 'city',
      label: <FormattedMessage defaultMessage="Miejscowość:" />,
      name: 'city',
      component: !isEditable && <strong>{city ?? NO_DATA}</strong>,
    },
    {
      key: 'postCode',
      label: <FormattedMessage defaultMessage="Kod pocztowy:" />,
      name: 'postCode',
      component: !isEditable && <strong>{postCode ?? NO_DATA}</strong>,
    },
    {
      key: 'invoiceAuto',
      label: <FormattedMessage defaultMessage="Automatyczna FV:" />,
      name: 'invoiceAuto',
      component: isEditable ? (
        <Switch />
      ) : (
        <CircleCheckbox value={invoiceAuto} />
      ),
      additionalItemProps: { valuePropName: 'checked' },
    },
    {
      key: 'hasInvoiceDataConvergence',
      label: <FormattedMessage defaultMessage="Zbieżność danych:" />,
      name: 'hasInvoiceDataConvergence',
      component: isEditable ? (
        <Switch />
      ) : (
        <CircleCheckbox value={hasInvoiceDataConvergence} />
      ),
      additionalItemProps: { valuePropName: 'checked' },
    },
  ];
};
