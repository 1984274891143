import type { FC, ReactElement } from 'react';
import { Button, Card, Col } from 'antd';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import styles from './styles.module.scss';

interface Props {
  children: ReactElement | ReactElement[];
  title: string | ReactElement;
  isLoading: boolean;
  openEditModal: () => void;
  colMd?: number;
}

const CardCommon: FC<Props> = ({
  children,
  title,
  isLoading,
  openEditModal,
  colMd = 8,
}) => (
  <Col span={24} md={colMd} className={styles.cardWrapper}>
    <Card
      data-testid="cardCommon"
      bordered={false}
      className={cn('header-solid h-full', styles.card)}
      bodyStyle={{ paddingTop: 16, paddingBottom: 16 }}
      title={
        <TitleWrapper>
          {title}
          <Button
            data-testid="editDataButton"
            type="primary"
            onClick={openEditModal}
            disabled={isLoading}
          >
            <FormattedMessage defaultMessage="Edytuj" />
          </Button>
        </TitleWrapper>
      }
    >
      {children}
    </Card>
  </Col>
);

export default CardCommon;
