import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import { UsersTag } from 'ducks/users/types';
import { type UserLoginRequest, type UserLoginResponse } from './types';

export enum Endpoint {
  LogIn = '/auth/login',
  LogOut = '/auth/logout',
  TwoFactorChallenge = '/auth/two-factor-challenge',
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery(),
  tagTypes: [UsersTag.CurrentUser],
  endpoints: (build) => ({
    login: build.mutation<UserLoginResponse, UserLoginRequest>({
      query: (data) => ({
        url: Endpoint.LogIn,
        method: 'POST',
        data,
      }),
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: Endpoint.LogOut,
        method: 'POST',
      }),
      invalidatesTags: [UsersTag.CurrentUser],
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
