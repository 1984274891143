/* eslint-disable react/jsx-props-no-spreading */
import EmailTemplateSelect from 'components/ui/atoms/EmailTemplateSelect';
import FormItem from 'components/ui/atoms/FormItem';
import Loader from 'components/ui/atoms/Loader';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setPreorderEmailTemplate } from 'ducks/view/preorders';
import usePreorderRealizationServices from '../../usePreorderRealizationServices';
import { formItems } from './config';

const EmailSettingsSection = () => {
  const dispatch = useAppDispatch();
  const emailTemplate = useAppSelector(
    (state) => state.preorders.emailTemplate,
  );
  const { isPreorderFetching } = usePreorderRealizationServices();

  const handleEmailTemplateChange = (value: string) => {
    dispatch(setPreorderEmailTemplate(value));
  };

  return (
    <CardCommon data-testid="emailSettingsSection" xs={24} lg={14}>
      <Loader visible={isPreorderFetching}>
        {formItems.map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
        <EmailTemplateSelect
          value={emailTemplate}
          onChange={handleEmailTemplateChange}
        />
      </Loader>
    </CardCommon>
  );
};

export default EmailSettingsSection;
