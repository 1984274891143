import { useMemo } from 'react';
import { useGetPricesQuery } from 'ducks/prices/service';

export const usePreorderDraftCalculationServices = () => {
  const { data: pricesData = [], isFetching: isPricesFetching } =
    useGetPricesQuery();

  const value = useMemo(
    () => ({ pricesData, isPricesFetching }),
    [pricesData, isPricesFetching],
  );

  return value;
};
