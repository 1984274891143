import type { GetInvoicesOverdueFilter } from 'ducks/invoices/types';
import type { FC } from 'react';
import { Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import { invoiceOverdueStatuses } from 'constants/invoice';
import styles from './styles.module.scss';

type Option = {
  label: string;
  value: GetInvoicesOverdueFilter[keyof GetInvoicesOverdueFilter];
};

type FilterOptions = Record<
  keyof GetInvoicesOverdueFilter,
  {
    placeholder: string;
    options: Option[];
  }
>;

type Props = {
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  filterBy: GetInvoicesOverdueFilter;
  onFilterByChange: (filter: GetInvoicesOverdueFilter) => void;
};

const InvoicesOverdueToolbar: FC<Props> = ({
  searchValue,
  onSearchValueChange,
  filterBy,
  onFilterByChange,
}) => {
  const intl = useIntl();
  const sharedOptions: Option[] = [
    { label: intl.formatMessage({ defaultMessage: 'Tak' }), value: 1 },
    { label: intl.formatMessage({ defaultMessage: 'Nie' }), value: 0 },
  ];

  const filterOptions: FilterOptions = {
    hasDebtCollection: {
      placeholder: intl.formatMessage({ defaultMessage: 'Windykacja' }),
      options: sharedOptions,
    },
    hasAccountingNote: {
      placeholder: intl.formatMessage({ defaultMessage: 'Nota księgowa' }),
      options: sharedOptions,
    },
    status: {
      placeholder: intl.formatMessage({ defaultMessage: 'Status' }),
      options: invoiceOverdueStatuses(intl),
    },
    hasLetterSummon: {
      placeholder: intl.formatMessage({ defaultMessage: 'Wezwanie listowne' }),
      options: sharedOptions,
    },
    isCourt: {
      placeholder: intl.formatMessage({ defaultMessage: 'Sprawa sądowa' }),
      options: sharedOptions,
    },
  };

  const handleFilterChange = (
    key: keyof GetInvoicesOverdueFilter,
    value: Option['value'],
  ) =>
    onFilterByChange({
      [key]: value,
    });

  return (
    <div className={styles.wrapper}>
      <Input
        value={searchValue}
        onChange={(e) => onSearchValueChange(e.target.value)}
        placeholder={intl.formatMessage({
          defaultMessage: 'Szukaj',
        })}
        allowClear
      />
      {(Object.entries(filterOptions) as Entries<typeof filterOptions>).map(
        ([key, { options, placeholder }]) => (
          <Select
            options={options}
            value={filterBy[key]}
            onChange={(value) => handleFilterChange(key, value)}
            placeholder={placeholder}
            allowClear
          />
        ),
      )}
    </div>
  );
};

export default InvoicesOverdueToolbar;
