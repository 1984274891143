/* eslint-disable react/jsx-props-no-spreading */
import type { InputNumberProps, InputProps, SelectProps } from 'antd';
import type { Rule } from 'antd/es/form';
import type { TextAreaProps } from 'antd/es/input';
import type { ReactElement } from 'react';
import { Input, InputNumber, Select, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { MAX_NIP_LENGTH, MAX_REGON_LENGTH } from 'constants/formInputLengths';
import {
  validateIBanRule,
  validateNipRule,
  validatePhoneNumberRule,
  validatePostalCodeRule,
  validateRegonRule,
} from 'utilities/antdFormRules';
import UploadInput from './components/UploadInput';

const { Password, TextArea } = Input;

export const dictionaryOfNames = {
  COMPANY_NAME: 'company_name',
  NIP: 'nip',
  REGON: 'regon',
  ADDRESS: 'address',
  CONTACT_ADDRESS: 'contact_address',
  POST_CODE: 'post_code',
  CONTACT_POST_CODE: 'contact_post_code',
  CITY: 'city',
  CONTACT_CITY: 'contact_city',
  PHONE: 'phone_number',
  CONTACT_PHONE: 'contact_phone_number',
  FAX: 'fax',
  CONTACT_FAX: 'contact_fax',
  EMAIL: 'email',
  CONTACT_EMAIL: 'contact_email',
  WEBSITE: 'website',
  BANK_NAME: 'bank_name',
  IBAN: 'iban',
  PRESENTATION: 'presentation',
  SMTP_ADRESS: 'smtp_adress',
  SMTP_PORT: 'smtp_port',
  SMTP_PROTOCOL: 'smtp_protocol',
  ENCRYPTION: 'encryption',
  IS_AUTHORIZATION: 'is_authorization',
  USERNAME: 'username',
  PASSWORD: 'password',
  SENDER_EMAIL: 'sender_email',
  SENDER_USERNAME: 'sender_username',
  EXCEPTION_EMAILS: 'exception_emails',
};

export const DictionaryOfInputs = {
  STRING: 'string',
  NUMBER: 'number',
  FILE: 'file',
  PASSWORD: 'password',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  ARRAY: 'array',
};

export type Option = {
  value: string;
  label?: string;
};

export type FieldSettings = {
  [key: string]: {
    label: ReactElement;
    rules?: Rule[];
    addictionalProps?:
      | InputProps
      | InputNumberProps
      | TextAreaProps
      | SelectProps;
  };
};

export type InputFields = {
  [key: string]: ReactElement;
};

export const commonRules = [
  {
    required: true,
    message: <FormattedMessage defaultMessage="Uzupełnij pole" />,
  },
];

export const fieldSettings: FieldSettings = {
  [dictionaryOfNames.COMPANY_NAME]: {
    label: <FormattedMessage defaultMessage="Nazwa firmy" />,
    rules: commonRules,
  },
  [dictionaryOfNames.NIP]: {
    label: <FormattedMessage defaultMessage="NIP" />,
    rules: [...commonRules, validateNipRule()],
    addictionalProps: {
      maxLength: MAX_NIP_LENGTH,
    },
  },
  [dictionaryOfNames.REGON]: {
    label: <FormattedMessage defaultMessage="REGON" />,
    rules: [validateRegonRule()],
    addictionalProps: {
      maxLength: MAX_REGON_LENGTH,
    },
  },
  [dictionaryOfNames.ADDRESS]: {
    label: <FormattedMessage defaultMessage="Adres" />,
    rules: commonRules,
  },
  [dictionaryOfNames.CONTACT_ADDRESS]: {
    label: <FormattedMessage defaultMessage="Adres" />,
    rules: commonRules,
  },
  [dictionaryOfNames.POST_CODE]: {
    label: <FormattedMessage defaultMessage="Kod pocztowy" />,
    rules: [...commonRules, validatePostalCodeRule()],
  },
  [dictionaryOfNames.CONTACT_POST_CODE]: {
    label: <FormattedMessage defaultMessage="Kod pocztowy" />,
    rules: [...commonRules, validatePostalCodeRule()],
  },
  [dictionaryOfNames.CITY]: {
    label: <FormattedMessage defaultMessage="Miejscowość" />,
    rules: commonRules,
  },
  [dictionaryOfNames.CONTACT_CITY]: {
    label: <FormattedMessage defaultMessage="Miejscowość" />,
    rules: commonRules,
  },
  [dictionaryOfNames.PHONE]: {
    label: <FormattedMessage defaultMessage="Nr telefonu" />,
    rules: [...commonRules, validatePhoneNumberRule()],
  },
  [dictionaryOfNames.CONTACT_PHONE]: {
    label: <FormattedMessage defaultMessage="Nr telefonu" />,
    rules: [...commonRules, validatePhoneNumberRule()],
  },
  [dictionaryOfNames.FAX]: {
    label: <FormattedMessage defaultMessage="Faks" />,
    rules: commonRules,
  },
  [dictionaryOfNames.CONTACT_FAX]: {
    label: <FormattedMessage defaultMessage="Faks" />,
    rules: commonRules,
  },
  [dictionaryOfNames.EMAIL]: {
    label: <FormattedMessage defaultMessage="Adres e-mail" />,
    rules: [
      ...commonRules,
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
  [dictionaryOfNames.CONTACT_EMAIL]: {
    label: <FormattedMessage defaultMessage="Adres e-mail" />,
    rules: [
      ...commonRules,
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
  [dictionaryOfNames.WEBSITE]: {
    label: <FormattedMessage defaultMessage="Strona WWW" />,
    rules: commonRules,
  },
  [dictionaryOfNames.BANK_NAME]: {
    label: <FormattedMessage defaultMessage="Nazwa banku" />,

    rules: [
      ...commonRules,
      {
        min: 3,
        message: (
          <FormattedMessage defaultMessage="Nazwa banku musi mieć min. 3 znaki" />
        ),
      },
    ],
  },
  [dictionaryOfNames.IBAN]: {
    label: <FormattedMessage defaultMessage="Numer konta" />,
    rules: [...commonRules, validateIBanRule()],
  },
  [dictionaryOfNames.PRESENTATION]: {
    label: <FormattedMessage defaultMessage="PDF" />,
    rules: commonRules,
  },
  [dictionaryOfNames.SMTP_ADRESS]: {
    label: <FormattedMessage defaultMessage="Adres servera SMTP" />,
    rules: commonRules,
  },
  [dictionaryOfNames.SMTP_PORT]: {
    label: <FormattedMessage defaultMessage="Port servera SMTP" />,
    rules: commonRules,
  },
  [dictionaryOfNames.SMTP_PROTOCOL]: {
    label: <FormattedMessage defaultMessage="Protokół servera SMTP" />,
    rules: commonRules,
  },
  [dictionaryOfNames.ENCRYPTION]: {
    label: <FormattedMessage defaultMessage="Szyfrowanie" />,
    rules: commonRules,
    addictionalProps: {
      options: [
        {
          value: 'none',
          label: 'Brak',
        },
        {
          value: 'ssl',
          label: 'SSL',
        },
        {
          value: 'tls',
          label: 'TLS',
        },
      ],
    },
  },
  [dictionaryOfNames.IS_AUTHORIZATION]: {
    label: <FormattedMessage defaultMessage="Wymagana autoryzacja" />,
  },
  [dictionaryOfNames.USERNAME]: {
    label: <FormattedMessage defaultMessage="Nazwa użytkownika" />,
    rules: [
      ...commonRules,
      {
        min: 3,
        message: (
          <FormattedMessage defaultMessage="Nazwa banku musi mieć min. 3 znaki" />
        ),
      },
    ],
  },
  [dictionaryOfNames.PASSWORD]: {
    label: <FormattedMessage defaultMessage="Hasło" />,
    rules: commonRules,
  },
  [dictionaryOfNames.SENDER_EMAIL]: {
    label: <FormattedMessage defaultMessage="Adres nadawcy" />,
    rules: [
      ...commonRules,
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
  [dictionaryOfNames.SENDER_USERNAME]: {
    label: <FormattedMessage defaultMessage="Nazwa nadawcy" />,
    rules: [
      ...commonRules,
      {
        min: 3,
        message: (
          <FormattedMessage defaultMessage="Nazwa banku musi mieć min. 3 znaki" />
        ),
      },
    ],
  },
  [dictionaryOfNames.EXCEPTION_EMAILS]: {
    label: <FormattedMessage defaultMessage="Adresy e-mail" />,
    rules: [...commonRules],
    addictionalProps: {
      autoSize: {
        minRows: 4,
        maxRows: 8,
      },
    },
  },
};

export const getInput = ({ type, ...addictionalProps }: { type: string }) => {
  switch (type) {
    case DictionaryOfInputs.STRING:
      return <Input {...addictionalProps} />;
    case DictionaryOfInputs.NUMBER:
      return <InputNumber {...addictionalProps} />;
    case DictionaryOfInputs.PASSWORD:
      return <Password data-testid="passwordInput" {...addictionalProps} />;
    case DictionaryOfInputs.SELECT:
      return <Select {...addictionalProps} />;
    case DictionaryOfInputs.CHECKBOX:
      return <Checkbox />;
    case DictionaryOfInputs.ARRAY:
      return <TextArea data-testid="textareaInput" />;
    case DictionaryOfInputs.FILE:
      return <UploadInput {...addictionalProps} />;
    default:
      return null;
  }
};
