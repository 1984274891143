import type { Rule } from 'antd/es/form';
import type { ReactElement } from 'react';
import { Input, Radio } from 'antd';
import { FormattedMessage, type IntlShape } from 'react-intl';
import {
  NotesCategory,
  type AddNoteRequest,
  type UpdateNoteRequest,
} from 'ducks/notes/types';

export type NoteModalValues = Omit<
  AddNoteRequest & UpdateNoteRequest,
  'objectId' | 'object' | 'noteId'
>;

export type NoteModalInput = {
  label: string;
  name: keyof NoteModalValues;
  rules?: Rule[];
  additionalProps?: Record<string, string>;
  component?: ReactElement;
};

export const noteModalInputs = (intl: IntlShape): NoteModalInput[] => [
  {
    label: intl.formatMessage({
      defaultMessage: 'Kategoria notatki',
    }),
    name: 'type',
    component: (
      <Radio.Group>
        <Radio value={NotesCategory.GENERAL}>
          <FormattedMessage defaultMessage="Generalne" />
        </Radio>
        <Radio value={NotesCategory.FINANCIAL}>
          <FormattedMessage defaultMessage="Finansowe" />
        </Radio>
      </Radio.Group>
    ),
    rules: [
      {
        required: true,
        message: intl.formatMessage({
          defaultMessage: 'Wybierz kategorię',
        }),
      },
    ],
  },
  {
    label: intl.formatMessage({
      defaultMessage: 'Tytuł',
    }),
    name: 'title',
    rules: [
      {
        required: true,
        message: intl.formatMessage({
          defaultMessage: 'Podaj tytuł',
        }),
      },
    ],
  },
  {
    label: intl.formatMessage({
      defaultMessage: 'Treść',
    }),
    name: 'content',
    rules: [
      {
        required: true,
        message: intl.formatMessage({
          defaultMessage: 'Podaj treść',
        }),
      },
    ],
    component: <Input.TextArea data-testid="contentTextarea" rows={3} />,
  },
];
