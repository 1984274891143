import type { ReactElement, FC } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Table from 'components/ui/molecules/Table';
import { useGetClientPreordersQuery } from 'ducks/clients/service';
import { preorderColumns } from '../../config';

type Props = {
  selectedLang: string | null;
  tableCardTitle: ReactElement;
};

const PreordersTab: FC<Props> = ({ selectedLang, tableCardTitle }) => {
  const intl = useIntl();
  const { clientId = '' } = useParams();
  const { data = [], isFetching } = useGetClientPreordersQuery(
    {
      clientId,
    },
    { skip: !clientId },
  );

  const modifiedData = useMemo(
    () =>
      data.map(({ langs, ...props }) => ({
        ...props,
        key: props.id,
        searchdump: langs
          .map(({ langFrom, langTo }) => [langFrom, langTo].join(','))
          .join(),
      })),
    [data],
  );

  const filteredData = useMemo(
    () =>
      modifiedData.filter(({ searchdump }) =>
        searchdump.includes(selectedLang ?? ''),
      ),
    [selectedLang, modifiedData],
  );

  return (
    <Table
      data={filteredData}
      columns={preorderColumns({
        intl,
      })}
      isLoading={isFetching}
      cardTitle={tableCardTitle}
    />
  );
};

export default PreordersTab;
