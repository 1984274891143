import { Col, notification } from 'antd';
import { useParams } from 'react-router-dom';
import Notes from 'components/ui/organisms/Notes';
import { NoteType } from 'ducks/notes/types';
import { useInvoiceOverdueDetailsServices } from '../../useInvoiceOverdueDetailsServices';

const NotesSection = () => {
  const { id = '' } = useParams();
  const [notify, contextHolder] = notification.useNotification();
  const { invoiceOverdueData, isInvoiceOverdueLoading } =
    useInvoiceOverdueDetailsServices();

  return (
    <Col xs={24} lg={8}>
      {contextHolder}
      <Notes
        objectId={Number(id)}
        noteType={NoteType.INVOICES}
        notify={notify}
        skip={!invoiceOverdueData}
        isLoading={isInvoiceOverdueLoading}
      />
    </Col>
  );
};

export default NotesSection;
