import type {
  AddInvoiceRequest,
  GetInvoicesAdvanceRequest,
  GetInvoicesAdvanceResponse,
  GetInvoicesDataRequest,
  GetInvoicesDataResponse,
  GetInvoicesFinalRequest,
  GetInvoicesFinalResponse,
  GetInvoicesVatRequest,
  GetInvoicesVatResponse,
  UpdateHasInvoiceAdvanceRequest,
  UpdateHasInvoiceFinalRequest,
  GetInvoicesOverdueRequest,
  GetInvoicesOverdueResponse,
  GetInvoiceOverdueRequest,
  AddInvoiceResponse,
  AddInvoiceDebtCollectionRequest,
  UpdateInvoiceDebtCollectionRequest,
  GetInvoiceDebtCollectionRequest,
  GetInvoiceDebtCollectionResponse,
  GetInvoiceLetterSummonRequest,
  AddInvoiceLetterSummonRequest,
  UpdateInvoiceLetterSummonRequest,
  DeleteInvoiceLetterSummonRequest,
  GetInvoiceLetterSummonResponse,
  GetInvoiceOverdueResponse,
  UpdateInvoiceOverdueRequest,
  GetInvoiceAccountingNotesResponse,
  GetInvoiceAccountingNotesRequest,
  AddInvoiceAccountingNoteRequest,
  UpdateInvoiceAccountingNoteRequest,
  DeleteInvoiceAccountingNoteRequest,
  AddInvoicePaymentRequest,
  GetInvoicesRequest,
  GetInvoicesResponse,
  GetInvoiceVatRequest,
  GetInvoiceVatResponse,
  SendInvoiceVatRequest,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import { formatFilterParameter, formatSortParameter } from 'utilities/api';
import { convertKeysToSnakeCase } from 'utilities/object';
import { InvoicesTag } from './types';

export enum Endpoint {
  GetInvoices = '/invoices',
  GetInvoicesData = '/invoices-data',
  AddInvoice = '/invoices-data',
  AddInvoicePayment = '/invoices/:id/add-payment',
  GetInvoicesVat = '/invoices/to-issue-vat',
  GetInvoiceVat = '/invoices/vat/:id',
  SendInvoiceVat = '/invoices/vat/:id/send',
  GetInvoicesAdvance = '/invoices/to-issue-advance',
  GetInvoicesFinal = '/invoices/to-issue-final',
  UpdateHasInvoiceAdvance = '/orders/:id/has-invoice-advance',
  UpdateHasInvoiceFinal = '/orders/:id/has-invoice-final',
  GetInvoicesOverdue = '/invoices/overdue',
  GetInvoiceOverdue = '/invoices/overdue/:id',
  UpdateInvoiceOverdue = '/invoices/overdue/:id',
  GetInvoiceDebtCollection = '/invoices/:id/debt-collection',
  AddInvoiceDebtCollection = '/invoices/:id/debt-collection',
  UpdateInvoiceDebtCollection = '/invoices/:id/debt-collection/:itemId',
  GetInvoiceLetterSummon = '/invoices/:id/letter-summon',
  AddInvoiceLetterSummon = '/invoices/:id/letter-summon',
  UpdateInvoiceLetterSummon = '/invoices/:id/letter-summon/:itemId',
  DeleteInvoiceLetterSummon = '/invoices/:id/letter-summon/:itemId',
  GetInvoiceAccountingNotes = '/invoices/:id/accounting-note',
  AddInvoiceAccountingNote = '/invoices/:id/accounting-note',
  UpdateInvoiceAccountingNote = '/invoices/:id/accounting-note/:itemId',
  DeleteInvoiceAccountingNote = '/invoices/:id/accounting-note/:itemId',
}

export const invoicesApi = createApi({
  reducerPath: 'invoicesApi',
  baseQuery: baseQuery(),
  tagTypes: [
    InvoicesTag.Invoices,
    InvoicesTag.InvoicesData,
    InvoicesTag.InvoicesOverdue,
    InvoicesTag.InvoicesVat,
    InvoicesTag.InvoicesAdvance,
    InvoicesTag.InvoicesFinal,
    InvoicesTag.InvoiceDebtCollection,
    InvoicesTag.InvoiceLetterSummon,
    InvoicesTag.InvoiceAccountingNote,
  ],
  endpoints: (build) => ({
    getInvoices: build.query<GetInvoicesResponse, GetInvoicesRequest>({
      query: ({ page, searchTerm, filter, sort }) => ({
        url: Endpoint.GetInvoices,
        method: 'GET',
        params: {
          page,
          s: searchTerm,
          filter: formatFilterParameter(filter),
          sort: formatSortParameter(sort),
        },
      }),
      providesTags: [InvoicesTag.Invoices],
    }),
    getInvoicesData: build.query<
      GetInvoicesDataResponse,
      GetInvoicesDataRequest
    >({
      query: ({ ids }) => {
        const queryParams = ids
          .map((id, index) => `id[${index}]=${id}`)
          .join('&');

        return {
          url: `${Endpoint.GetInvoicesData}?${queryParams}`,
          method: 'GET',
        };
      },
      providesTags: [InvoicesTag.InvoicesData],
    }),
    addInvoice: build.mutation<AddInvoiceResponse, AddInvoiceRequest>({
      query: (data) => ({
        url: Endpoint.AddInvoice,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [InvoicesTag.Invoices, InvoicesTag.InvoicesVat],
    }),
    addInvoicePayment: build.mutation<void, AddInvoicePaymentRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.AddInvoicePayment.replace(':id', id.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error, { id }) =>
        error
          ? []
          : [InvoicesTag.Invoices, { type: InvoicesTag.InvoicesOverdue, id }],
    }),
    getInvoicesVat: build.query<GetInvoicesVatResponse, GetInvoicesVatRequest>({
      query: ({ page, searchTerm, filter }) => ({
        url: Endpoint.GetInvoicesVat,
        method: 'GET',
        params: {
          page,
          s: searchTerm,
          filter: formatFilterParameter(filter),
        },
      }),
      providesTags: [InvoicesTag.Invoices, InvoicesTag.InvoicesVat],
    }),
    getInvoiceVat: build.query<GetInvoiceVatResponse, GetInvoiceVatRequest>({
      query: ({ id }) => ({
        url: Endpoint.GetInvoiceVat.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: [InvoicesTag.InvoicesVat],
    }),
    sendInvoiceVat: build.mutation<void, SendInvoiceVatRequest>({
      query: ({ id, body }) => ({
        url: Endpoint.SendInvoiceVat.replace(':id', id.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(body),
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [InvoicesTag.Invoices, InvoicesTag.InvoicesVat],
    }),
    getInvoicesAdvance: build.query<
      GetInvoicesAdvanceResponse,
      GetInvoicesAdvanceRequest
    >({
      query: ({ page, searchTerm, filter }) => ({
        url: Endpoint.GetInvoicesAdvance,
        method: 'GET',
        params: {
          page,
          s: searchTerm,
          filter: formatFilterParameter(filter),
        },
      }),
      providesTags: [InvoicesTag.InvoicesAdvance],
    }),
    getInvoicesFinal: build.query<
      GetInvoicesFinalResponse,
      GetInvoicesFinalRequest
    >({
      query: ({ page, searchTerm, filter }) => ({
        url: Endpoint.GetInvoicesFinal,
        method: 'GET',
        params: {
          page,
          s: searchTerm,
          filter: formatFilterParameter(filter),
        },
      }),
      providesTags: [InvoicesTag.InvoicesFinal],
    }),
    updateHasInvoiceAdvance: build.mutation<
      void,
      UpdateHasInvoiceAdvanceRequest
    >({
      query: ({ id, ...data }) => ({
        url: Endpoint.UpdateHasInvoiceAdvance.replace(':id', id.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [InvoicesTag.InvoicesAdvance, InvoicesTag.InvoicesFinal],
    }),
    updateHasInvoiceFinal: build.mutation<void, UpdateHasInvoiceFinalRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.UpdateHasInvoiceFinal.replace(':id', id.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [InvoicesTag.InvoicesAdvance, InvoicesTag.InvoicesFinal],
    }),
    /* ——————————————————————————Overdue—————————————————————————— */
    getInvoicesOverdue: build.query<
      GetInvoicesOverdueResponse,
      GetInvoicesOverdueRequest
    >({
      query: ({ page, searchTerm, filter }) => ({
        url: Endpoint.GetInvoicesOverdue,
        method: 'GET',
        params: {
          page,
          s: searchTerm,
          filter: formatFilterParameter(filter),
        },
      }),
      providesTags: [InvoicesTag.InvoicesOverdue],
    }),
    getInvoiceOverdue: build.query<
      GetInvoiceOverdueResponse,
      GetInvoiceOverdueRequest
    >({
      query: ({ id }) => ({
        url: Endpoint.GetInvoiceOverdue.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: (_result, _error, { id }) => [
        { type: InvoicesTag.InvoicesOverdue, id },
      ],
    }),
    updateInvoiceOverdue: build.mutation<void, UpdateInvoiceOverdueRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.UpdateInvoiceOverdue.replace(':id', id.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error, { id }) =>
        error
          ? []
          : [
              InvoicesTag.InvoicesOverdue,
              { type: InvoicesTag.InvoicesOverdue, id },
            ],
    }),
    /* ——————————————————————————DebtCollection—————————————————————————— */
    getInvoiceDebtCollection: build.query<
      GetInvoiceDebtCollectionResponse,
      GetInvoiceDebtCollectionRequest
    >({
      query: ({ id }) => ({
        url: Endpoint.GetInvoiceDebtCollection.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: [InvoicesTag.InvoiceDebtCollection],
    }),
    addInvoiceDebtCollection: build.mutation<
      void,
      AddInvoiceDebtCollectionRequest
    >({
      query: ({ id, ...data }) => ({
        url: Endpoint.AddInvoiceDebtCollection.replace(':id', id.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) =>
        error
          ? []
          : [InvoicesTag.InvoicesOverdue, InvoicesTag.InvoiceDebtCollection],
    }),
    updateInvoiceDebtCollection: build.mutation<
      void,
      UpdateInvoiceDebtCollectionRequest
    >({
      query: ({ id, itemId, ...data }) => ({
        url: Endpoint.UpdateInvoiceDebtCollection.replace(
          ':id',
          id.toString(),
        ).replace(':itemId', itemId.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) =>
        error
          ? []
          : [InvoicesTag.InvoicesOverdue, InvoicesTag.InvoiceDebtCollection],
    }),
    /* ——————————————————————————LetterSummon—————————————————————————— */
    getInvoiceLetterSummon: build.query<
      GetInvoiceLetterSummonResponse,
      GetInvoiceLetterSummonRequest
    >({
      query: ({ id }) => ({
        url: Endpoint.GetInvoiceLetterSummon.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: [InvoicesTag.InvoiceLetterSummon],
    }),
    addInvoiceLetterSummon: build.mutation<void, AddInvoiceLetterSummonRequest>(
      {
        query: ({ id, ...data }) => ({
          url: Endpoint.AddInvoiceLetterSummon.replace(':id', id.toString()),
          method: 'POST',
          data: convertKeysToSnakeCase(data),
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [InvoicesTag.InvoiceLetterSummon],
      },
    ),
    updateInvoiceLetterSummon: build.mutation<
      void,
      UpdateInvoiceLetterSummonRequest
    >({
      query: ({ id, itemId, ...data }) => ({
        url: Endpoint.UpdateInvoiceLetterSummon.replace(
          ':id',
          id.toString(),
        ).replace(':itemId', itemId.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error, { id }) =>
        error
          ? []
          : [
              InvoicesTag.InvoiceLetterSummon,
              { type: InvoicesTag.InvoicesOverdue, id },
            ],
    }),
    deleteInvoiceLetterSummon: build.mutation<
      void,
      DeleteInvoiceLetterSummonRequest
    >({
      query: ({ id, itemId }) => ({
        url: Endpoint.DeleteInvoiceLetterSummon.replace(
          ':id',
          id.toString(),
        ).replace(':itemId', itemId.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [InvoicesTag.InvoiceLetterSummon],
    }),
    /* ——————————————————————————AccountingNote—————————————————————————— */
    getInvoiceAccountingNotes: build.query<
      GetInvoiceAccountingNotesResponse,
      GetInvoiceAccountingNotesRequest
    >({
      query: ({ id }) => ({
        url: Endpoint.GetInvoiceAccountingNotes.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: [InvoicesTag.InvoiceAccountingNote],
    }),
    addInvoiceAccountingNote: build.mutation<
      void,
      AddInvoiceAccountingNoteRequest
    >({
      query: ({ id, ...data }) => ({
        url: Endpoint.AddInvoiceAccountingNote.replace(':id', id.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error, { id }) =>
        error
          ? []
          : [
              InvoicesTag.InvoiceAccountingNote,
              { type: InvoicesTag.InvoicesOverdue, id },
            ],
    }),
    updateInvoiceAccountingNote: build.mutation<
      void,
      UpdateInvoiceAccountingNoteRequest
    >({
      query: ({ id, itemId, ...data }) => ({
        url: Endpoint.UpdateInvoiceAccountingNote.replace(
          ':id',
          id.toString(),
        ).replace(':itemId', itemId.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error, { id }) =>
        error
          ? []
          : [
              InvoicesTag.InvoiceAccountingNote,
              { type: InvoicesTag.InvoicesOverdue, id },
            ],
    }),
    deleteInvoiceAccountingNote: build.mutation<
      void,
      DeleteInvoiceAccountingNoteRequest
    >({
      query: ({ id, itemId }) => ({
        url: Endpoint.DeleteInvoiceAccountingNote.replace(
          ':id',
          id.toString(),
        ).replace(':itemId', itemId.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (_, error, { id }) =>
        error
          ? []
          : [
              InvoicesTag.InvoiceAccountingNote,
              { type: InvoicesTag.InvoicesOverdue, id },
            ],
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoicesDataQuery,
  useAddInvoiceMutation,
  useAddInvoicePaymentMutation,
  useGetInvoicesOverdueQuery,
  useGetInvoiceOverdueQuery,
  useUpdateInvoiceOverdueMutation,
  useGetInvoicesVatQuery,
  useGetInvoiceVatQuery,
  useSendInvoiceVatMutation,
  useGetInvoicesAdvanceQuery,
  useGetInvoicesFinalQuery,
  useUpdateHasInvoiceAdvanceMutation,
  useUpdateHasInvoiceFinalMutation,
  useGetInvoiceDebtCollectionQuery,
  useAddInvoiceDebtCollectionMutation,
  useUpdateInvoiceDebtCollectionMutation,
  useGetInvoiceLetterSummonQuery,
  useAddInvoiceLetterSummonMutation,
  useUpdateInvoiceLetterSummonMutation,
  useDeleteInvoiceLetterSummonMutation,
  useGetInvoiceAccountingNotesQuery,
  useAddInvoiceAccountingNoteMutation,
  useUpdateInvoiceAccountingNoteMutation,
  useDeleteInvoiceAccountingNoteMutation,
} = invoicesApi;
