import type { UploadFile } from 'antd';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { Space } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import TruncateText from 'components/ui/atoms/TruncateText';
import CardCommon from 'components/ui/molecules/CardCommon';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import Upload from 'components/ui/molecules/Upload';
import {
  getReferenceFile,
  useSendReferenceFileMutation,
} from 'ducks/orders/service';
import { downloadFile } from 'utilities/downloadFile';
import { useOrdersServices } from 'views/Orders/useOrdersServices';
import styles from './styles.module.scss';

interface Props {
  notify: NotificationInstance;
}

function extractFileName(input: string): string {
  const regex = /orders\/\d+\/(.+)_[a-zA-Z0-9]+(\..+)/;
  const match = input.match(regex);

  if (match && match[1] && match[2]) {
    return `${match[1]}${match[2]}`;
  }
  return input;
}

const ReferenceFileCard: FC<Props> = ({ notify }) => {
  const { id: orderId = '' } = useParams();
  const [isDownloadFileLoading, setIsDownloadFileLoading] = useState(false);
  const { orderData, isOrderLoading, isOrderFetching } = useOrdersServices();
  const [sendReferenceFile, { isLoading, data: preorderResponseData }] =
    useSendReferenceFileMutation();

  const handleAddReferenceFile = async (files: UploadFile[]) => {
    const file = files[0].originFileObj;

    if (files.length >= 2 || !file) return;

    try {
      await sendReferenceFile({
        orderId,
        file: file as File,
      }).unwrap();

      notify.success({
        message: <FormattedMessage defaultMessage="Poprawnie wgrano plik" />,
      });
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd poczas wgrywania pliku." />
        ),
      });
    }
  };

  const handleDownload = async () => {
    try {
      setIsDownloadFileLoading(true);

      const response = await getReferenceFile({ orderId });

      const referenceFilePath = orderData?.referenceFile ?? '';
      const pathArray = referenceFilePath.split('/');
      const fileName = pathArray[pathArray.length - 1] ?? '';

      const url = URL.createObjectURL(response as File);

      downloadFile(url, fileName);
    } catch (err) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Nie znaleziono pliku lub wystąpił błąd." />
        ),
      });
    } finally {
      setIsDownloadFileLoading(false);
    }
  };

  return (
    <CardCommon
      data-testid="referenceFileCard"
      title={
        <TitleWrapper>
          <FormattedMessage defaultMessage="Referencje" />
          <Upload
            onChange={({ fileList }) => handleAddReferenceFile(fileList)}
            multiple={false}
            disabled={isLoading}
            fullWidth={false}
          >
            <Button loading={isOrderLoading || isLoading}>
              <FormattedMessage defaultMessage="Wgraj" />
            </Button>
          </Upload>
        </TitleWrapper>
      }
      isLoading={isOrderLoading}
      customSkeleton
      xs={24}
      lg={12}
    >
      <Space className="width-100" direction="vertical">
        {!!orderData?.referenceFile && (
          <div className={styles.referenceFileItem}>
            <TruncateText maxLength={50} nowrap>
              {extractFileName(orderData?.referenceFile) ??
                extractFileName(preorderResponseData?.referenceFile ?? '')}
            </TruncateText>
            <Button
              onClick={handleDownload}
              loading={isDownloadFileLoading || isOrderFetching}
            >
              <FormattedMessage defaultMessage="Pobierz" />
            </Button>
          </div>
        )}
      </Space>
    </CardCommon>
  );
};

export default ReferenceFileCard;
