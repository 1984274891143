import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Dayjs } from 'dayjs';
import type { UpdateInvoiceDebtCollectionRequest } from 'ducks/invoices/types';
import type { IntlShape } from 'react-intl';
import { DatePicker, Select } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import { FormattedMessage } from 'react-intl';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import { debtCollectionStatuses } from 'constants/invoice';

export type EditDebtCollectionsModalValues = ReplaceType<
  Omit<UpdateInvoiceDebtCollectionRequest, 'id' | 'itemId'>,
  Date,
  Dayjs
>;

type Props = {
  intl: IntlShape;
};

export const formItems = ({
  intl,
}: Props): FormItemRecord<EditDebtCollectionsModalValues>[] => [
  {
    key: 'number',
    name: 'number',
    label: <FormattedMessage defaultMessage="Numer" />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj number" />,
      },
    ],
  },
  {
    key: 'dateReceived',
    name: 'dateReceived',
    label: <FormattedMessage defaultMessage="Data przyjęcia" />,
    component: (
      <DatePicker
        data-testid="dateReceivedPicker"
        className="width-100"
        locale={locale}
      />
    ),
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz datę przyjęcia" />,
      },
    ],
  },
  {
    key: 'price',
    name: 'price',
    label: <FormattedMessage defaultMessage="Koszt" />,
    component: <DecimalInputNumber data-testid="priceInput" fullWidth />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj koszt" />,
      },
    ],
  },
  {
    key: 'status',
    name: 'status',
    label: <FormattedMessage defaultMessage="Status" />,
    component: (
      <Select
        data-testid="statusSelect"
        options={debtCollectionStatuses(intl)}
      />
    ),
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz status" />,
      },
    ],
  },
];
