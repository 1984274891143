import type { ListItemRecord } from 'components/ui/molecules/List';
import type { FormValues } from 'views/Settings/components/SettingsForm/config';
import { Col, Row, Typography, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  useGetSettingsQuery,
  useSendSettingsMutation,
} from 'ducks/settings/service';
import SettingsForm from 'views/Settings/components/SettingsForm';
import {
  FormsIds,
  getListItems,
} from 'views/Settings/components/SettingsForm/config';

const { Title } = Typography;

const ExternalIntegrations = () => {
  const { data: queryFormData = [], isLoading } = useGetSettingsQuery({
    sectionId: FormsIds.EXTERNAL_INTEGRATIONS,
  });
  const [sendForm, { isLoading: isSendingLoading, isSuccess, isError }] =
    useSendSettingsMutation();
  const [notify, contextHolder] = notification.useNotification();

  const composeDataAndSend = async (values: FormValues) => {
    await sendForm({ sectionId: FormsIds.EXTERNAL_INTEGRATIONS, ...values });
  };

  return (
    <Row gutter={[24, 24]}>
      {contextHolder}
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <SettingsForm
          notify={notify}
          formElements={queryFormData}
          formTitle={
            <FormattedMessage defaultMessage="Edytuj ustawienia SMTP" />
          }
          cardTitle={
            <Title level={4} style={{ margin: 0 }}>
              <FormattedMessage defaultMessage="Ustawienia SMTP" />
            </Title>
          }
          listItems={getListItems(queryFormData) as ListItemRecord[]}
          isLoadingList={isLoading}
          isLoadingSend={isSendingLoading}
          isSuccessSend={isSuccess}
          isErrorSend={isError}
          onSubmit={composeDataAndSend}
        />
      </Col>
    </Row>
  );
};

export default ExternalIntegrations;
