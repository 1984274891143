import type { HandleAddProps, ListItem } from './config';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { EditPriceRequest } from 'ducks/prices/types';
import { isEqual, round } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  useDeletePriceItemMutation,
  useEditPriceMutation,
  useGetPriceDetailsQuery,
} from 'ducks/prices/service';
import { initialListItemValues } from './config';
import {
  SPECIALIZED,
  generateSpecializedPrice,
  multiplierPrices,
} from './utils';

const useManagePriceList = (notify: NotificationInstance) => {
  const { id: priceId = '' } = useParams();
  const [listItems, setListItems] = useState<ListItem[]>([]);
  const {
    data,
    isLoading: isInitialLoading,
    isFetching: isDataFetching,
  } = useGetPriceDetailsQuery({
    id: Number(priceId),
  });
  const [editPrice, { isLoading: isEditLoading }] = useEditPriceMutation();
  const [deletePriceItem, { isLoading: isDeleteLoading }] =
    useDeletePriceItemMutation();
  const isLoading = isDataFetching || isEditLoading || isDeleteLoading;
  const isChanged = useMemo(
    () => !isEqual(listItems, data?.items ?? []),
    [listItems, data],
  );

  const handleAdd = (items: HandleAddProps) => {
    if (isLoading || !data) return;

    setListItems((prev) => {
      return [
        ...prev,
        ...items.map((props) => ({
          ...initialListItemValues,
          ...props,
          id: v4(),
          priceId: data.id,
        })),
      ];
    });
  };

  const handleEdit = (field: Record<number, ListItem>) => {
    if (isLoading) return;

    const id = Object.keys(field)[0];
    const newValue = Object.values(field)[0];

    setListItems((prev) =>
      prev.map((item) => {
        if (item.id.toString() === id) {
          const newValueKey = Object.keys(newValue)[0] as keyof ListItem;

          if (newValueKey === 'normal') {
            const normalPrice = newValue.normal;
            const normalSpecialized = generateSpecializedPrice(newValue.normal);
            const prices = multiplierPrices({ normalPrice });

            const specializedPrices = Object.entries(prices).reduce(
              (prevVal, [key, value]) => {
                return {
                  [`${key}${SPECIALIZED}`]: generateSpecializedPrice(value),
                  ...prevVal,
                };
              },
              {},
            );

            return {
              ...item,
              ...newValue,
              normalSpecialized,
              ...prices,
              ...specializedPrices,
            };
          }

          if (!newValueKey.includes(SPECIALIZED)) {
            const price = newValue?.[newValueKey];

            const specializedPrice =
              typeof price === 'number'
                ? generateSpecializedPrice(price)
                : null;

            return {
              ...item,
              [newValueKey]: price,
              [`${newValueKey}SPECIALIZED`]: specializedPrice,
            };
          }

          return {
            ...item,
            ...newValue,
          };
        }

        return item;
      }),
    );
  };

  const handleGeneralPriceChange = (value: number, isPercentage: boolean) => {
    if (isLoading) return;

    setListItems((prev) =>
      prev.map((item) => {
        const clacNormal = round(
          isPercentage
            ? item.normal + (value / 100) * item.normal
            : item.normal + value,
          2,
        );
        const normal = clacNormal < 0 ? 0 : clacNormal;

        const { sworn, correction, redaction, verification } = multiplierPrices(
          { normalPrice: normal },
        );

        return {
          ...item,
          normal,
          sworn,
          correction,
          redaction,
          verification,
        };
      }),
    );
  };

  const handleDelete = async (id: number | string, isNew: boolean) => {
    if (isLoading || !data) return;

    if (isNew) {
      setListItems((prev) => prev.filter((item) => item.id !== id));

      return;
    }

    try {
      await deletePriceItem({ id: data.id, itemId: id as number }).unwrap();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas usuwania." />
        ),
      });
    }
  };

  const handleSave = async () => {
    if (isLoading || !data) return;

    const modifiedItems = listItems.map((item) => {
      if (item.isNew) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { isNew, ...restItemProps } = item;

        return { ...restItemProps, id: null };
      }
      return item;
    }) as EditPriceRequest['items'];

    try {
      const { id, name } = data;

      await editPrice({
        id,
        name,
        items: modifiedItems,
      }).unwrap();

      notify.success({
        message: (
          <FormattedMessage defaultMessage="Pomyślnie wprowadzono zmiany." />
        ),
      });
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania." />
        ),
      });
    }
  };

  useEffect(() => {
    if (data && !isDataFetching) {
      setListItems(data.items);
    }
  }, [data, isDataFetching]);

  return {
    data,
    listItems,
    isLoading,
    isInitialLoading,
    isDeleteLoading,
    isChanged,
    handleAdd,
    handleEdit,
    handleDelete,
    handleSave,
    handleGeneralPriceChange,
  };
};

export default useManagePriceList;
