import type { FC } from 'react';
import { Row, Col, Breadcrumb, Button, Input, Avatar } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import { Routes } from 'components/main/App/Routing';
import { LogoutButton } from 'components/main/AuthProvider';
import { PREORDER_DETAILS_VIEW_REGEX } from 'constants/regexes';
import { useAppDispatch } from 'ducks/store';
import { setTriggerCurrentUserFilter } from 'ducks/view/preorders';
import { HamburgerIcon, LogoutIcon, SearchIcon } from 'icons';
import ProrderHeader from './PreordersHeader/PreordersHeader';
import styles from './styles.module.scss';

type Props = {
  showSearch?: boolean;
  name: string;
  subName: string;
  toggleSidenavMobileOpened: () => void;
};

const Header: FC<Props> = ({
  name,
  subName,
  showSearch = false,
  toggleSidenavMobileOpened,
}) => {
  const { pathname } = useLocation();
  const isPreorderDetails = PREORDER_DETAILS_VIEW_REGEX.test(pathname);
  const dispatch = useAppDispatch();

  const handleTriggerOrderFilters = () => {
    dispatch(setTriggerCurrentUserFilter(true));
  };

  const { user } = useSanctum();
  const intl = useIntl();

  return (
    <Row data-testid="header" gutter={[24, 0]}>
      <Col span={24} md={6}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to={Routes.PROJECTS}>
              <FormattedMessage defaultMessage="Główna" />
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{name}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="ant-page-header-heading">
          <span className="ant-page-header-heading-title">{subName}</span>
        </div>
      </Col>
      {isPreorderDetails && (
        <Col md={10} xs={24}>
          <ProrderHeader />
        </Col>
      )}
      <Col
        span={24}
        md={!isPreorderDetails ? 18 : 8}
        className="header-control"
      >
        <Button
          type="link"
          className="sidebar-toggler"
          onClick={toggleSidenavMobileOpened}
        >
          <HamburgerIcon />
        </Button>
        <LogoutButton icon={<LogoutIcon />} className="btn-sign-in" type="link">
          <FormattedMessage defaultMessage="Wyloguj się" />
        </LogoutButton>
        {!!user && (
          <Link
            to={Routes.PREORDERS}
            className={styles.headerLink}
            onClick={handleTriggerOrderFilters}
          >
            <Avatar>{user.first_name.slice(0, 2).toLocaleUpperCase()}</Avatar>
            <FormattedMessage
              defaultMessage="{username}"
              values={{ username: user.first_name }}
            />
          </Link>
        )}
        {showSearch && (
          <Input
            className="header-search"
            placeholder={intl.formatMessage({
              defaultMessage: 'Wyszukaj...',
            })}
            prefix={<SearchIcon />}
          />
        )}
      </Col>
    </Row>
  );
};

export default Header;
