import type { PreorderRealizationValues } from '../../config';
import type { FormInstance } from 'antd';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { useConvertPreorderMutation } from 'ducks/preorders/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setGlobalIsLoading } from 'ducks/view/global';
import { Routes } from 'views/Preorders/Routing';
import usePreorderRealizationServices from '../../usePreorderRealizationServices';
import styles from './styles.module.scss';

type Props = {
  form: FormInstance<PreorderRealizationValues>;
  notify: NotificationInstance;
};

const ActionsSection: FC<Props> = ({ form, notify }) => {
  const { id: preorderId, variantId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedVariantIds, uploadedFiles } = useAppSelector(
    ({ preorders }) => ({
      selectedVariantIds: preorders.selectedVariantIds,
      uploadedFiles: preorders.uploadedFiles,
    }),
    shallowEqual,
  );
  const { isLoading: isServicesLoading } = usePreorderRealizationServices();
  const [convertPreorder, { isLoading: isConvertLoading }] =
    useConvertPreorderMutation();
  const isLoading = isServicesLoading || isConvertLoading;

  const handleSubmit = async () => {
    if (isLoading || !preorderId || !variantId) return;

    dispatch(setGlobalIsLoading(true));

    try {
      const values = await form.validateFields();

      const files = uploadedFiles.map(
        ({ originFileObj }) => originFileObj as File,
      );

      await convertPreorder({
        ...values,
        preorderId: Number(preorderId),
        variantId: Number(variantId),
        variants: selectedVariantIds,
        files,
      }).unwrap();

      navigate(Routes.PREORDER_DETAILS.replace(':id', preorderId.toString()));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.errorFields) return;

      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas realizacji zamówienia." />
        ),
      });
    } finally {
      dispatch(setGlobalIsLoading(false));
    }
  };

  return (
    <Space
      data-testid="actionsSection"
      className={styles.space}
      style={{ justifyContent: 'end' }}
    >
      <Button type="primary" onClick={handleSubmit} loading={isLoading}>
        <FormattedMessage defaultMessage="Realizuj" />
      </Button>
    </Space>
  );
};

export default ActionsSection;
