import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { AddTranslatorRequest } from 'ducks/translators/types';
import { FormattedMessage } from 'react-intl';

export type AddTranslatorModalValues = AddTranslatorRequest;

export type AddTranslatorModalInput = FormItemRecord<AddTranslatorModalValues>;

export const inputs: AddTranslatorModalInput[] = [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Imię" />,
    name: 'firstName',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj imię" />,
      },
    ],
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="Nazwisko" />,
    name: 'lastName',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj nazwisko" />,
      },
    ],
  },
  {
    key: 3,
    label: <FormattedMessage defaultMessage="E-mail" />,
    name: 'email1',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj email" />,
      },
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
];
