import type {
  CircleCheckboxClassesReturn,
  CircleCheckboxIconSize,
} from './config';
import type { ComponentPropsWithoutRef, FC } from 'react';
import { Avatar } from 'antd';
import cn from 'classnames';
import theme from 'components/layout/Main/styles/values.scss';
import { CheckIcon } from 'icons';
import styles from './styles.module.scss';

const ICON_FONT_SIZE = 22;

type Props = ComponentPropsWithoutRef<typeof Avatar> & {
  value: boolean;
  iconSize?: CircleCheckboxIconSize;
  colorTrue?: string;
  colorFalse?: string;
};

const classes: CircleCheckboxClassesReturn = {
  iconSize: {
    small: styles.iconSizeSmall,
    medium: styles.iconSizeMedium,
  },
};

const CircleCheckbox: FC<Props> = ({
  value,
  iconSize = 'medium',
  colorTrue = theme.colorMenthol,
  colorFalse = theme.philippineGray,
  className,
  ...props
}) => {
  const ariaChecked = value ? 'true' : 'false';
  const backgroundColor = value ? colorTrue : colorFalse;

  return (
    <Avatar
      data-testid="circle-checkbox"
      {...props}
      aria-checked={ariaChecked}
      className={cn(styles.avatar, className, classes.iconSize[iconSize])}
      size="small"
      icon={value && <CheckIcon fontSize={ICON_FONT_SIZE} />}
      style={{
        backgroundColor,
      }}
    />
  );
};

export default CircleCheckbox;
