import type { FileTranslationType } from 'constants/fileTranslation';
import type { FormOfSettlement } from 'constants/formOfSettlements';
import type { Gender } from 'constants/genders';
import type { OrderStatus } from 'constants/orderStatuses';
import type { FileType, UploadedFile } from 'ducks/global/types';
import type {
  DefaultValue,
  PaginationRequest,
  PaginationResponse,
  SortRequest,
} from 'ducks/types';

export enum TranslatorsTag {
  Translators = 'Translators',
  TranslatorsPriceLists = 'TranslatorsPriceLists',
  TranslatorsQualifications = 'TranslatorsQualifications',
  TranslatorsOrders = 'TranslatorsOrders',
}

export type TranslatorCat = {
  id: number;
  name: string;
  isMain: boolean;
};

type TranslatorSubspecialization = {
  id: number;
  name: string;
  parentId: number;
};

export type TranslatorSpecialization = {
  id: number;
  name: string;
  parentId: number | null;
  parent: TranslatorSubspecialization | null;
};

export type TranslatorType = {
  id: number;
  name: string;
  isMain: 0 | 1;
  isHidden: 0 | 1;
};

export type TranslatorPricing = {
  id: number;
  normalFrom: number;
  specialFrom: number;
  correctionFrom: number;
  authFrom: number;
} & Pick<
  Translator,
  | 'firstName'
  | 'lastName'
  | 'name'
  | 'invoiceName'
  | 'formOfSettlement'
  | 'orders'
>;

export type TranslatorCatsTool = {
  id: number;
  name: string;
  isMain: boolean;
};

export type TranslatorRate = {
  punctuality: number | null;
  price: number | null;
  content: number | null;
  technical: number | null;
  contact: number | null;
};

export type TranslatorOrders = {
  recent: number | null;
  all: number | null;
  done: number | null;
  inProgress: number | null;
  complaints: number | null;
};

type Status = 0 | 1;

export type Translator = {
  dateEditTranslator: string | null;
  id: number;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  gender: Gender | null;
  title: number | null;
  langNative: string | null;
  hasAgreement: boolean;
  formOfSettlement: FormOfSettlement | null;
  contactFirstName: string | null;
  contactLastName: string | null;
  email1: string;
  email2: string;
  phone1: string;
  phone2: string;
  emailDefault: DefaultValue;
  phoneDefault: DefaultValue;
  nip: string | null;
  pesel: string | null;
  regon: string | null;
  invoiceName: string | null;
  invoiceAddress: string | null;
  invoiceCity: string | null;
  invoicePostCode: string | null;
  invoiceProvince: string | null;
  invoiceCountry: string | null;
  invoiceDueDate: number;
  address: string | null;
  city: string | null;
  postCode: string | null;
  province: string | null;
  country: string | null;
  websiteUrl: string | null;
  facebookUrl: string | null;
  linkedinUrl: string | null;
  externalUrl: string | null;
  catOther: string | null;
  status: Status;
  statusReason: string;
  vacationFrom: string | null;
  vacationTo: string | null;
  rateAvg: number | null;
  rate: TranslatorRate;
  orders: TranslatorOrders;
  minNormalFrom: number | null;
  minSpecialFrom: number | null;
  minCorrectionFrom: number | null;
  minAuthFrom: number | null;
  minCertifiedNormalFrom: number | null;
  minCertifiedSpecialFrom: number | null;
  minCertifiedCorrectionFrom: number | null;
  minCertifiedAuthFrom: number | null;
  langs: string[];
  specializations: TranslatorSpecialization[];
  cats: TranslatorCat[];
  catsTools: TranslatorCatsTool[];
  types: Omit<TranslatorType, 'hidden'>[];
  createdAt: string;
};

export type GetTranslatorsResponse = PaginationResponse<Translator>;

export type GetTranslatorsSort = SortRequest<'orders'>;

type TranslatorsFilterTypes =
  | 'is_post_edit'
  | 'is_certified'
  | 'is_professional';

export enum TranslatorsFilterPriceTypes {
  SPECIAL = 'special',
  NORMAL = 'normal',
  CORRECTION = 'correction',
  AUTH = 'auth',
}

export type GetTranslatorsFilter = Partial<{
  lang: string[];
  catId: number[];
  type: TranslatorsFilterTypes[];
  status: Status;
  specializations: number[];
  orderFileId: number;
  // checks in translators' price list
  priceType: TranslatorsFilterPriceTypes[];
  orderId: string | number;
}>;

export type GetTranslatorsRequest = PaginationRequest & {
  searchTerm?: string;
  filter?: GetTranslatorsFilter;
  sort?: GetTranslatorsSort | null;
};

export type AddTranslatorRequest = {
  firstName: string;
  lastName: string;
  email1: string;
};

export type AddTranslatorResponse = number;

export type GetTranslatorsCatsResponse = TranslatorCat[];

export type GetTranslatorsSpecializationsResponse = Omit<
  TranslatorSpecialization,
  'parent'
>[];

export type GetTranslatorsTypesResponse = TranslatorType[];

export type GetTranslatorsPricingFilter = Partial<{
  type: FileTranslationType;
  lang: string[];
  status: Status;
  priceType: TranslatorsFilterPriceTypes[];
  catId: number[];
  specializations: number[];
  isPostedit: Binary;
}>;

export type GetTranslatorsPricingRequest = {
  filter?: GetTranslatorsPricingFilter;
};

export type GetTranslatorsPricingResponse = TranslatorPricing[];

export type GetTranslatorDetailsRequest = {
  translatorId: number;
};

export type UpdateTranslatorRequest = Omit<
  Translator,
  'id' | 'specializations' | 'cats' | 'catsTools' | 'types'
> & {
  translatorId: number;
  agreeToSmsContact: 0 | 1;
  catsTools: number[];
  cats: number[];
  specializations: number[];
  types: number[];
};

export type UpdateTranslatorVacationRequest = {
  translatorId: number;
  vacationFrom: string | null;
  vacationTo: string | null;
};

export type TranslatorsPriceListItem = {
  id: number;
  translatorId: number;
  isCertified: boolean;
  langFrom: string;
  langTo: string;
  normalFrom: number;
  normalTo: number;
  specialFrom: number;
  specialTo: number;
  correctionFrom: number;
  correctionTo: number;
  authFrom: number;
  authTo: number;
  createdAt: string;
  updatedAt: string;
};

export type GetTranslatorsPriceListsResponse = TranslatorsPriceListItem[];

export type GetTranslatorsPriceListsRequest = { translatorId: number };

export type UpdateTranslatorStatusRequest = {
  translatorId: number;
  status: number;
  statusReason: string;
};

export type TranslatorsPriceListsRequest = {
  id: number | null;
  isCertified: boolean;
  langFrom: string;
  langTo: string;
  normalFrom: number | null;
  normalTo: number | null;
  specialFrom: number | null;
  specialTo: number | null;
  correctionFrom: number | null;
  correctionTo: number | null;
  authFrom: number | null;
  authTo: number | null;
};

export type AddTranslatorsPriceListsRequest = {
  translatorId: number;
  items: TranslatorsPriceListsRequest[];
};

export type EditTranslatorsPriceListsRequest = {
  translatorId: number;
  items: TranslatorsPriceListsRequest[];
};

export type DeleteTranslatorsPriceListsRequest = {
  translatorId: number;
  itemId: number;
};

export type TranslatorQualificationValues = {
  field1: string;
  field2: string;
  field3: string;
  field4: string;
};

export type TranslatorQualificationFile = {
  id: number;
  filename: string;
  object: FileType.QUALIFICATIONS;
};

export type TranslatorQualificationItem = TranslatorQualificationValues & {
  id: number;
  files?: TranslatorQualificationFile[];
};

export type TranslatorQualification = {
  id: number;
  name: string;
  items: TranslatorQualificationItem[];
};

export type GetTranslatorsQualificationsResponse = TranslatorQualification[];

export type GetTranslatorsQualificationsRequest = {
  translatorId: number | string;
};

export type EditTranslatorsQualificationsRequest = {
  translatorId: number;
  items: (TranslatorQualificationValues & {
    sectionId: number;
    file: File | null;
  })[];
};

export type DeleteTranslatorsQualificationsRequest = {
  translatorId: number;
  itemId: number;
};

export type GetTranslatorsOrdersRequest = {
  translatorId: number;
};

export type EditTranslatorOrderRateRequest = {
  translatorId: number;
  orderId: number;
  punctuality: Rate;
  price: Rate;
  content: Rate;
  technical: Rate;
  contact: Rate;
};

export type TranslatorsOrders = {
  id: number;
  orderId: number;
  orderStatus: OrderStatus;
  orderNumber: string;
  orderFileType: number;
  orderFileLangFrom: string;
  orderFileLangTo: string;
  orderFile: UploadedFile;
  dateRealization: string;
  translatorPriceNetto: number;
  price: number;
  orderRatesAvg: number | null;
  orderRatesContactAvg: Rate | null;
  orderRatesContentAvg: Rate | null;
  orderRatesPriceAvg: Rate | null;
  orderRatesPunctualityAvg: Rate | null;
  orderRatesTechnicalAvg: Rate | null;
  orderTranslationFiles: {
    id: number;
    fileId: number;
    orderFileLangId: number;
    translatorId: number;
    createdAt: string;
    updatedAt: string;
    file: UploadedFile;
  }[];
};

export type GetTranslatorsOrdersResponse = TranslatorsOrders[];
