import type { TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { authApi } from './auth/service';
import { clientsApi } from './clients/service';
import config from './config/slice';
import { globalApi } from './global/service';
import { invoicesApi } from './invoices/service';
import { logsApi } from './logs/service';
import { notesApi } from './notes/service';
import { ordersApi } from './orders/service';
import { paymentsApi } from './payments/service';
import { preordersApi } from './preorders/service';
import { pricesApi } from './prices/service';
import { publicApi } from './public/service';
import { settingsApi } from './settings/service';
import { smsApi } from './sms/service';
import { translatorsApi } from './translators/service';
import { usersApi } from './users/service';
import financesSlice from './view/finances';
import globalSlice from './view/global';
import invoicesSlice from './view/invoices';
import invoicesAddSlice from './view/invoicesAdd';
import ordersSlice from './view/orders';
import orderTranslationFilesSlice from './view/orderTranslationFiles';
import preordersSlice from './view/preorders';

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: {
    config,
    global: globalSlice,
    preorders: preordersSlice,
    orders: ordersSlice,
    orderTranslationFiles: orderTranslationFilesSlice,
    finances: financesSlice,
    invoicesAdd: invoicesAddSlice,
    invoices: invoicesSlice,
    [authApi.reducerPath]: authApi.reducer,
    [globalApi.reducerPath]: globalApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [logsApi.reducerPath]: logsApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [notesApi.reducerPath]: notesApi.reducer,
    [translatorsApi.reducerPath]: translatorsApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [preordersApi.reducerPath]: preordersApi.reducer,
    [pricesApi.reducerPath]: pricesApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [smsApi.reducerPath]: smsApi.reducer,
    [invoicesApi.reducerPath]: invoicesApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [
          'preorders.variants',
          'preorders.realization.files',
          'orders.orderFiles',
          'orders.sendEmails.translatorEmails',
          'finances.parsedPaymentFile',
          'finances.assignPaymentTransactionModal.selectedPaymentTransaction',
        ],
        ignoredActions: [
          'preorders/addPreorderVariant',
          'preorders/updatePreorderVariantService',
          'preorders/addPreorderRealizationFiles',
          'preorders/removePreorderRealizationFiles',
          'orders/setOrderSendEmailsTranslators',
          'orders/updateOrderFilesAndServices',
          'orders/toggleOrderSendEmailsUploadedFiles',
          'finances/setFinancesAssignPaymentTransactionModal',
          'finances/assignFinancesPaymentTransaction',
        ],
      },
    }).concat(
      authApi.middleware,
      globalApi.middleware,
      clientsApi.middleware,
      notesApi.middleware,
      usersApi.middleware,
      logsApi.middleware,
      translatorsApi.middleware,
      paymentsApi.middleware,
      preordersApi.middleware,
      pricesApi.middleware,
      settingsApi.middleware,
      ordersApi.middleware,
      smsApi.middleware,
      invoicesApi.middleware,
      publicApi.middleware,
    ),
});

setupListeners(store.dispatch);

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
