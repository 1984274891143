export const extractLanguageCodesFromFilename = (string: string): string[] => {
  const pattern = /_([a-zA-Z]{2})/g;

  const matches = string.match(pattern);

  if (matches) {
    const codes = matches.map((match) => match.substring(1));

    return codes;
  }

  return [];
};
