import type { Translator } from 'ducks/translators/types';
import { FormOfSettlement } from 'constants/formOfSettlements';
import { NO_DATA } from 'constants/strings';

type GetTranslatorNameInput = Partial<
  Pick<
    Translator,
    'name' | 'invoiceName' | 'firstName' | 'lastName' | 'formOfSettlement'
  >
>;

export const getTranslatorName = ({
  name,
  formOfSettlement,
  invoiceName,
  firstName,
  lastName,
}: GetTranslatorNameInput) => {
  const isInvoice = formOfSettlement === FormOfSettlement.INVOICE;

  if (name) return name;

  if (isInvoice && invoiceName) return invoiceName;

  if (firstName && lastName) return `${firstName} ${lastName}`;

  return NO_DATA;
};
