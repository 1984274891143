import { useMemo } from 'react';
import HeaderCard from 'components/ui/organisms/HeaderCard';
import { useInvoiceOverdueDetailsServices } from '../../useInvoiceOverdueDetailsServices';
import { headerCols, skeletonHeaderCols } from './config';
import styles from './styles.module.scss';

const InvoiceOverdueDetailsHeader = () => {
  const { invoiceOverdueData, isInvoiceOverdueFetching } =
    useInvoiceOverdueDetailsServices();

  const cols = useMemo(() => {
    if (isInvoiceOverdueFetching) return skeletonHeaderCols;

    if (!invoiceOverdueData) return [];

    const { number, dateLastContact, status } = invoiceOverdueData;

    return headerCols({
      number,
      dateLastContact,
      status,
    });
  }, [invoiceOverdueData, isInvoiceOverdueFetching]);

  return (
    <HeaderCard
      className={styles.header}
      cols={cols}
      isLoading={isInvoiceOverdueFetching}
    />
  );
};

export default InvoiceOverdueDetailsHeader;
