/* eslint-disable no-nested-ternary */
import type { ListItemRecord } from 'components/ui/molecules/List';
import type { Translator } from 'ducks/translators/types';
import { FormattedMessage } from 'react-intl';
import CountryName from 'components/ui/atoms/CountryName';
import { FormOfSettlement } from 'constants/formOfSettlements';

export const invoiceCardListItems = (
  data: Translator | undefined,
): ListItemRecord[] => {
  const isInvoice = data?.formOfSettlement === FormOfSettlement.INVOICE;

  return [
    {
      label: <FormattedMessage defaultMessage="Forma rozliczenia:" />,
      description: data ? (
        isInvoice ? (
          <FormattedMessage defaultMessage="Faktura" />
        ) : (
          <FormattedMessage defaultMessage="Rachunek" />
        )
      ) : null,
    },
    {
      label: <FormattedMessage defaultMessage="Termin płatności:" />,
      description: data?.invoiceDueDate,
    },
    {
      label: <FormattedMessage defaultMessage="Imię osoby reprezentującej:" />,
      description: data?.contactFirstName,
      hidden: !isInvoice,
    },
    {
      label: (
        <FormattedMessage defaultMessage="Nazwisko osoby reprezentującej:" />
      ),
      description: data?.contactLastName,
      hidden: !isInvoice,
    },
    {
      label: <FormattedMessage defaultMessage="Nazwa firmy:" />,
      description: data?.invoiceName,
      hidden: !isInvoice,
    },
    {
      label: <FormattedMessage defaultMessage="NIP:" />,
      description: data?.nip,
      hidden: !isInvoice,
    },
    {
      label: <FormattedMessage defaultMessage="REGON:" />,
      description: data?.regon,
      hidden: !isInvoice,
    },
    {
      label: <FormattedMessage defaultMessage="PESEL:" />,
      description: data?.pesel,
      hidden: isInvoice,
    },
    {
      label: <FormattedMessage defaultMessage="Adres:" />,
      description: data?.invoiceAddress,
    },
    {
      label: <FormattedMessage defaultMessage="Miasto:" />,
      description: data?.invoiceCity,
    },
    {
      label: <FormattedMessage defaultMessage="Kod pocztowy:" />,
      description: data?.invoicePostCode,
    },
    {
      label: <FormattedMessage defaultMessage="Kraj:" />,
      description: <CountryName countryCode={data?.invoiceCountry} />,
    },
    {
      label: <FormattedMessage defaultMessage="Województwo:" />,
      description: data?.invoiceProvince,
    },
  ];
};
