import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { GetInvoicesFilter, Invoice } from 'ducks/invoices/types';
import dayjs from 'dayjs';
import { FormattedMessage, type IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { DayjsFormat } from 'constants/dayjsFormats';
import { getInvoiceType } from 'constants/invoice';
import { NO_DATA } from 'constants/strings';
import { Routes } from 'views/Invoices/Routing';

export type InvoiceDocumentRow = TableRow & Invoice;

type Props = {
  intl: IntlShape;
};

export const initialInvoiceDocumentsFilters: GetInvoicesFilter = {
  dateCreationBefore: undefined,
  dateCreationAfter: undefined,
  dateDueBefore: undefined,
  dateDueAfter: undefined,
  type: undefined,
};

export const columns = ({ intl }: Props): ColumnsType<InvoiceDocumentRow> => [
  {
    key: 'id',
    dataIndex: 'id',
    title: <FormattedMessage defaultMessage="LP." />,
    sorter: true,
    width: 40,
  },
  {
    key: 'number',
    dataIndex: 'number',
    title: <FormattedMessage defaultMessage="Nr dokumentu" />,
    sorter: true,
  },
  {
    key: 'contractor',
    dataIndex: 'contractor',
    title: <FormattedMessage defaultMessage="Kontrahent" />,
    width: 145,
    render: (_, { client }) => <span>{client?.name ?? NO_DATA}</span>,
  },
  {
    key: 'orderNumber',
    dataIndex: 'orderNumber',
    title: <FormattedMessage defaultMessage="Nr zlecenia" />,
    render: (_, { orders }) => (
      <span>
        {orders.length
          ? orders.map(({ number }) => number).join(', ')
          : NO_DATA}
      </span>
    ),
  },
  {
    key: 'type',
    dataIndex: 'type',
    title: <FormattedMessage defaultMessage="Rodzaj" />,
    render: (_, { type }) => (
      <span>{getInvoiceType(intl, type)?.label ?? NO_DATA}</span>
    ),
  },
  {
    key: 'createdAt',
    dataIndex: 'createdAt',
    title: <FormattedMessage defaultMessage="Data wystawienia" />,
    width: 60,
    sorter: true,
    render: (_, { createdAt }) => (
      <span>{dayjs(createdAt).format(DayjsFormat.DATE)}</span>
    ),
  },
  {
    key: 'dateDue',
    dataIndex: 'dateDue',
    title: <FormattedMessage defaultMessage="Termin płatności" />,
    width: 60,
    sorter: true,
    render: (_, { dateDue }) => (
      <span>{dateDue ? dayjs(dateDue).format(DayjsFormat.DATE) : NO_DATA}</span>
    ),
  },
  {
    key: 'priceNetto',
    dataIndex: 'priceNetto',
    title: <FormattedMessage defaultMessage="Kwota netto" />,
    sorter: true,
    render: (_, { priceNetto }) => <CurrencyFormatter value={priceNetto} />,
  },
  {
    key: 'priceBrutto',
    dataIndex: 'priceBrutto',
    title: <FormattedMessage defaultMessage="Kwota brutto" />,
    sorter: true,
    render: (_, { priceBrutto }) => <CurrencyFormatter value={priceBrutto} />,
  },
  {
    key: 'payed',
    dataIndex: 'payed',
    title: <FormattedMessage defaultMessage="Zapłacono" />,
    render: (_, { payed }) => <CurrencyFormatter value={payed} />,
  },
  {
    key: 'currentToPay',
    dataIndex: 'currentToPay',
    title: <FormattedMessage defaultMessage="Pozostało" />,
    render: (_, { currentToPay }) => <CurrencyFormatter value={currentToPay} />,
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    title: <FormattedMessage defaultMessage="Akcje" />,
    width: 110,
    align: 'center',
    render: (_, { id }) => (
      <Link to={Routes.INVOICE_SEND.replace(':id', id.toString())}>
        <Button>
          <FormattedMessage defaultMessage="Wyślij FV" />
        </Button>
      </Link>
    ),
  },
];
