import type { SortOrder } from 'antd/lib/table/interface';

export enum DefaultValue {
  FIRST = 1,
  SECOND = 2,
}

export type PaginationRequest = {
  page?: number;
};

export type PaginationResponse<T> = {
  currentPage: number;
  firstPageUrl: string;
  from: number;
  lastPage: number;
  lastPageUrl: string;
  nextPageUrl: string;
  path: string;
  perPage: number;
  prevPageUrl: string | null;
  to: number;
  total: number;
  data: T[];
};

export type SortRequest<T extends string> = Partial<Record<T, SortOrder>>;

type PickNullable<T> = {
  [P in keyof T as null extends T[P] ? P : never]: T[P];
};

type PickNotNullable<T> = {
  [P in keyof T as null extends T[P] ? never : P]: T[P];
};

export type OptionalNullable<T> = {
  [K in keyof PickNullable<T>]?: Exclude<T[K], null>;
} & {
  [K in keyof PickNotNullable<T>]: T[K];
};
