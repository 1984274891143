/* eslint-disable react/jsx-props-no-spreading */
import type {
  FormListData,
  FormListItemRecord,
} from 'components/ui/molecules/FormList/config';
import { Form, Input } from 'antd';
import cn from 'classnames';
import { cloneElement, Fragment } from 'react';
import styles from './styles.module.scss';

interface Props<TData> extends FormListItemRecord<TData> {
  data: FormListData<TData>;
  className?: string;
}

const { Item } = Form;

const FormListItem = <TData,>({
  data,
  cellItems,
  center,
  className,
}: Props<TData>) => {
  return (
    <div
      className={cn(
        styles.wrapper,
        { [styles.wrapperCenter]: center },
        className,
      )}
    >
      {cellItems(data).map(
        ({ name, render, rules, additionalItemProps, hidden }, index) =>
          !hidden && (
            <Fragment key={name ?? index}>
              {!name && !!render && cloneElement(render)}
              {name && (
                <Item
                  name={[data.id, name]}
                  style={{
                    marginBottom: 0,
                  }}
                  initialValue={data[name as keyof typeof data]}
                  rules={rules}
                  className={cn(styles.formItem, {
                    [styles.formItemCenter]: center,
                  })}
                  {...additionalItemProps}
                >
                  {render ? (
                    cloneElement(render, {
                      className: 'width-100',
                    })
                  ) : (
                    <Input className="width-100" />
                  )}
                </Item>
              )}
            </Fragment>
          ),
      )}
    </div>
  );
};

export default FormListItem;
