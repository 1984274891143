import { BrowserRouter } from 'react-router-dom';
import Main from 'components/layout/Main';
import AuthProvider from 'components/main/AuthProvider';
import Loader from 'components/ui/atoms/Loader';
import { useAppSelector } from 'ducks/store';
import Routing from './Routing';

const App: React.FC = () => {
  const isLoading = useAppSelector(({ global }) => global.isLoading);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Main>
          <Loader visible={isLoading}>
            <Routing />
          </Loader>
        </Main>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
