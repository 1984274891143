import type { VariantServiceRow, VariantServiceValues } from './config';
import type {
  PreorderVariantServiceSlice,
  UpdatePreorderVariantServicePayload,
} from 'ducks/view/preorders';
import type { FC } from 'react';
import { Form } from 'antd';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import Empty from 'components/ui/atoms/Empty';
import Table from 'components/ui/molecules/Table';
import { ServiceType, ServiceAttributeNames } from 'constants/services';
import { useGetPreorderServicesQuery } from 'ducks/preorders/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  addPreorderVariantServiceShipment,
  updatePreorderVariantService,
} from 'ducks/view/preorders';
import { PlusIcon } from 'icons';
import { columns } from './config';
import styles from './styles.module.scss';

const { useForm } = Form;

type Props = {
  variantId: number | string;
  variantServices: PreorderVariantServiceSlice[];
};

const PreorderVariantServicesTable: FC<Props> = memo(
  ({ variantId, variantServices }) => {
    const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
    const [form] = useForm<VariantServiceValues>();
    const dispatch = useAppDispatch();
    const { data, isLoading } = useGetPreorderServicesQuery();

    const getSpecificService = useCallback(
      (type: ServiceType) =>
        data?.services.find((service) => service.type === type),
      [data?.services],
    );

    const handleValuesChange = useCallback(
      (field: UpdatePreorderVariantServicePayload['field']) =>
        dispatch(
          updatePreorderVariantService({
            variantId,
            field,
          }),
        ),

      [dispatch, variantId],
    );

    const handleAddServiceShipment = useCallback(() => {
      if (!data?.services) return;

      dispatch(
        addPreorderVariantServiceShipment({
          variantId,
          preorderServices: data.services,
        }),
      );
    }, [data?.services, variantId, dispatch]);

    const isEmpty = useMemo(() => !data && !isLoading, [data, isLoading]);

    const filteredVariantServices = useMemo(
      () => variantServices.filter(({ isActive }) => isEditable || isActive),
      [variantServices, isEditable],
    );

    const modifiedData = useMemo(
      (): VariantServiceRow[] =>
        variantServices.map((order) => ({
          ...order,
          key: order.id,
          variantId,
          service: getSpecificService(order.type),
        })),
      [variantServices, variantId, getSpecificService],
    );

    const variantServicesActives = useMemo(
      () => variantServices.map(({ isActive }) => isActive),
      [variantServices],
    );

    useEffect(() => {
      form.validateFields();
    }, [form, variantServicesActives]);

    /**
     * Checks if ADDITIONAL_TYPE field is empty when selecting ADDITIONAL_TIME
     * if so, sets the first available option for ADDITIONAL_TYPE
     */
    useEffect(() => {
      variantServices.forEach(({ id, preorderVariantServiceValues, type }) =>
        preorderVariantServiceValues.forEach(
          ({ attributeName, attributeValue }) => {
            const additionalType = preorderVariantServiceValues.find(
              (variantServiceValue) =>
                variantServiceValue.attributeName ===
                ServiceAttributeNames.ADDITIONAL_TYPE,
            );

            if (
              attributeName === ServiceAttributeNames.ADDITIONAL_TIME &&
              attributeValue &&
              !additionalType?.attributeValue
            ) {
              const additionalTypeAttributes = getSpecificService(
                type,
              )?.attributes?.find(
                ({ name }) => name === ServiceAttributeNames.ADDITIONAL_TYPE,
              );
              const firstOption = additionalTypeAttributes?.options?.[0];

              if (!firstOption) return;

              handleValuesChange({
                [id]: {
                  [[
                    'attributeName',
                    ServiceAttributeNames.ADDITIONAL_TYPE,
                  ].join(',')]: firstOption,
                },
              });
            }
          },
        ),
      );
    }, [variantServices, variantId, getSpecificService, handleValuesChange]);

    useEffect(() => {
      variantServices.forEach((variantService) => {
        const { id, preorderVariantServiceValues } = variantService;

        form.setFieldValue([id], variantService);

        preorderVariantServiceValues.forEach(
          ({ attributeName, attributeValue }) => {
            form.setFieldValue(
              [id, `attributeName,${attributeName}`],
              attributeValue,
            );
          },
        );
      });
    }, [variantServices, form]);

    if (isEmpty) {
      return (
        <Empty
          description={
            <FormattedMessage defaultMessage="Brak danych o usługach" />
          }
        />
      );
    }

    if (!filteredVariantServices.length) return null;

    return (
      <Form
        className={styles.form}
        form={form}
        layout="vertical"
        autoComplete="off"
        onValuesChange={handleValuesChange}
      >
        <Table
          cardTitle={<FormattedMessage defaultMessage="Inne usługi" />}
          columns={columns({
            isEditable,
          })}
          data={modifiedData}
          isLoading={isLoading}
          showHeader={false}
          onRow={({ type }) => ({
            'data-testid': ServiceType[type],
            id: ServiceType[type],
          })}
        />
        {isEditable && (
          <Button
            className={styles.button}
            type="link"
            size="small"
            icon={<PlusIcon />}
            onClick={handleAddServiceShipment}
          >
            <FormattedMessage defaultMessage="Dodaj wysyłkę" />
          </Button>
        )}
      </Form>
    );
  },
);

export default PreorderVariantServicesTable;
