import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setOrderIsImportant } from 'ducks/view/orders';
import OrderHeader from 'views/Orders/components/OrderHeader';
import { useOrderAssignServices } from '../../useOrderAssignServices';
import { headerCols } from './config';

const OrderAssignHeader = () => {
  const dispatch = useAppDispatch();
  const isImportant = useAppSelector(({ orders }) => orders.isImportant);
  const { isCurrentlyEdited } = useOrderAssignServices();

  const handleOnImportantChange = (value: boolean) =>
    dispatch(setOrderIsImportant(value));

  return (
    <OrderHeader
      cols={headerCols({
        isImportant,
        handleOnImportantChange,
        isCurrentlyEdited,
      })}
    />
  );
};

export default OrderAssignHeader;
