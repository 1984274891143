import type { SendClientEmailsValues } from './config';
import { Form, Row, notification } from 'antd';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'ducks/store';
import { resetOrdersState, setOrderFilesAndServices } from 'ducks/view/orders';
import OrderCurrentStatus from 'views/Orders/components/OrderCurrentStatus';
import OrderEmailTemplateEdtiorSection from 'views/Orders/components/OrderEmailTemplateEdtiorSection';
import OrderNotesSection from 'views/Orders/components/OrderNotesSection';
import { Routes } from 'views/Orders/Routing';
import { checkOrderClientSendRouteAvailable } from 'views/Orders/utils';
import ActionsSection from './components/ActionsSection';
import OrderClientSendHeader from './components/OrderClientSendHeader';
import OrderVariantSection from './components/OrderVariantSection';
import SwitchesSection from './components/SwitchesSection';
import TemplateSettingsSection from './components/TemplateSettingsSection';
import { initialValues } from './config';
import styles from './styles.module.scss';
import { useOrderClientSendServices } from './useOrderClientSendServices';

const { useForm } = Form;

const OrderClientSend = () => {
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = useForm<SendClientEmailsValues>();
  const { orderData, orderTranslationFilesData, refetch } =
    useOrderClientSendServices();

  const hasTranslatorAssigned = useMemo(() => {
    if (!orderTranslationFilesData?.orderFiles?.length) return false;

    return orderTranslationFilesData.orderFiles.some(({ orderFileLangs }) =>
      orderFileLangs.some(({ orderTranslationFiles }) =>
        orderTranslationFiles.some(({ translatorId }) => !!translatorId),
      ),
    );
  }, [orderTranslationFilesData?.orderFiles]);

  useEffect(() => {
    if (!orderData) return;

    const { number, email, emailCc: cc } = orderData;

    form.setFieldsValue({
      email,
      cc,
      subject: intl.formatMessage(
        { defaultMessage: 'Zamówienie nr {number}' },
        { number },
      ),
      smsText: intl.formatMessage({
        defaultMessage:
          'SUPERTŁUMACZ Dzień dobry! Na podaną skrzynkę e-mail wysłano zamówienie',
      }),
    });
  }, [orderData, form, intl]);

  useEffect(() => {
    if (!orderData || !orderTranslationFilesData) return;

    const { id, status } = orderData;

    const isOrderClientSendRouteAvailable = checkOrderClientSendRouteAvailable({
      status,
      orderTranslationFilesData,
    });

    if (!isOrderClientSendRouteAvailable) {
      navigate(Routes.ORDER_DETAILS.replace(':id', id.toString()));
    }
  }, [orderData, orderTranslationFilesData, hasTranslatorAssigned, navigate]);

  useEffect(() => {
    if (orderData) {
      dispatch(setOrderFilesAndServices(orderData));
    }
  }, [orderData, dispatch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    return () => {
      dispatch(resetOrdersState());
    };
  }, [dispatch]);

  return (
    <div className="layout-content mb-24">
      {contextHolder}
      <OrderCurrentStatus>
        <OrderClientSendHeader />
        <Form
          className={styles.form}
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <OrderNotesSection
            notify={notify}
            colProps={{ lg: 24 }}
            scroll={false}
          />
          <Row gutter={[24, 24]}>
            <TemplateSettingsSection form={form} />
            <SwitchesSection />
          </Row>
          <OrderEmailTemplateEdtiorSection form={form} />
          <OrderVariantSection />
          <ActionsSection form={form} notify={notify} />
        </Form>
      </OrderCurrentStatus>
    </div>
  );
};

export default OrderClientSend;
