import type { SendClientEmailsRequest } from 'ducks/clients/types';

export type SendClientEmailsValues = Pick<
  SendClientEmailsRequest,
  'subject' | 'email' | 'cc' | 'isSms' | 'smsText' | 'body' | 'isReferenceFile'
>;

export const initialValues: SendClientEmailsValues = {
  subject: '',
  email: '',
  cc: '',
  body: '',
  isSms: false,
  smsText: '',
  isReferenceFile: false,
};
