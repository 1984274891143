import { Space } from 'antd';
import QualificationList from './components/QualificationList/QualificationList';

const TranslatorQualification = () => {
  return (
    <Space size="large" className="width-100" direction="vertical">
      <QualificationList />
    </Space>
  );
};

export default TranslatorQualification;
