import type { FC } from 'react';
import { notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { useUpdateHasInvoiceAdvanceMutation } from 'ducks/invoices/service';

type Props = {
  id: number;
  hasInvoiceAdvance: Binary;
};

const ActionsCell: FC<Props> = ({ id, hasInvoiceAdvance }) => {
  const [notify, contextHolder] = notification.useNotification();
  const [updateHasInvoiceAdvance, { isLoading }] =
    useUpdateHasInvoiceAdvanceMutation();

  const handleUpdateHasInvoiceAdvance = async () => {
    try {
      await updateHasInvoiceAdvance({
        id,
        hasInvoiceAdvance: !hasInvoiceAdvance,
      }).unwrap();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas wystawiania." />
        ),
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Button loading={isLoading} onClick={handleUpdateHasInvoiceAdvance}>
        {hasInvoiceAdvance ? (
          <FormattedMessage defaultMessage="Wystawiono" />
        ) : (
          <FormattedMessage defaultMessage="Wystaw" />
        )}
      </Button>
    </>
  );
};

export default ActionsCell;
