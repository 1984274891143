import type { PaymentTransactionRow } from './config';
import type {
  GetPaymentTransactionsFilter,
  PaymentTransaction,
} from 'ducks/payments/types';
import { Space } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Table from 'components/ui/molecules/Table';
import { useGetPaymentTransactionsQuery } from 'ducks/payments/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setFinancesAssignPaymentTransactionModal } from 'ducks/view/finances';
import useTablePagination from 'hooks/useTablePagination';
import { columns } from './config';
import SearchFilterToolbar from './SearchFilterToolbar';

const PaymentTransactionsTable = () => {
  const dispatch = useAppDispatch();
  const selectedPaymentTransaction = useAppSelector(
    ({ finances }) =>
      finances.assignPaymentTransactionModal.selectedPaymentTransaction,
  );
  const {
    page,
    handlePageChange,
    filter,
    filterBy,
    handleFilterBy,
    searchValue,
    searchTerm,
    handleSearchValue,
  } = useTablePagination<PaymentTransactionRow, GetPaymentTransactionsFilter>();
  const { data, isFetching } = useGetPaymentTransactionsQuery({
    page,
    searchTerm,
    filter,
  });
  const pageSize = data?.perPage;
  const total = isFetching ? 0 : data?.total;

  const modifiedData: PaymentTransactionRow[] = useMemo(
    () =>
      (data?.data ?? []).map((item) => ({
        ...item,
        key: item.uid,
      })),
    [data?.data],
  );

  const handleChangePaymentTransaction = (
    paymentTransaction: PaymentTransaction,
  ) =>
    dispatch(
      setFinancesAssignPaymentTransactionModal({
        selectedPaymentTransaction: paymentTransaction,
      }),
    );

  return (
    <Space className="width-100" direction="vertical">
      <SearchFilterToolbar
        searchValue={searchValue}
        onSearchValueChange={handleSearchValue}
        filterBy={filterBy}
        onFilterByChange={handleFilterBy}
      />
      <Table
        cardTitle={<FormattedMessage defaultMessage="Dane zamówienia" />}
        pagination={{
          current: page,
          pageSize,
          total,
          onChange: handlePageChange,
        }}
        columns={columns({
          selectedPaymentTransactionUid: selectedPaymentTransaction?.uid,
          handleChangePaymentTransaction,
        })}
        data={modifiedData}
        isLoading={isFetching}
      />
    </Space>
  );
};

export default PaymentTransactionsTable;
