export enum NotesTag {
  Notes = 'Notes',
}

export enum NoteType {
  CLIENTS = 'clients',
  ORDERS = 'orders',
  TRANSLATORS = 'translators',
  PREORDERS = 'preorders',
  INVOICES = 'invoices',
}

export enum NotesCategory {
  GENERAL = 0,
  FINANCIAL = 1,
}

type NoteAuthor = {
  id: number;
  firstName: string;
  lastName: string;
};

export type Note = {
  id: number;
  userId: number;
  title: string;
  content: string;
  createdAt: string;
  user: NoteAuthor;
  numberOfCharacters: number;
  type: NotesCategory;
};

export type GetNotesResponse = Note[];

export type GetNotesRequest = {
  objectId: number;
  object: string;
};

export type AddNoteRequest = GetNotesRequest & {
  title: string;
  content: string;
  type: NotesCategory;
};

export type UpdateNoteRequest = AddNoteRequest & {
  noteId: number;
};

export type DeleteNoteRequest = GetNotesRequest & {
  noteId: number;
};
