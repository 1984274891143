import isoCountries from 'i18n-iso-countries';
import polishLanguage from 'i18n-iso-countries/langs/pl.json';
import { POLAND_COUNTRY_CODE } from 'constants/countryAndLanguageCodes';

isoCountries.registerLocale(polishLanguage);

export const getCountryNameByCode = (
  countryCode: string | null | undefined,
) => {
  const countryName = isoCountries.getName(
    countryCode ?? '',
    POLAND_COUNTRY_CODE,
  );

  return countryName ?? null;
};

const generateCountiresObjects = () =>
  Object.keys(isoCountries.getAlpha2Codes()).map((code) => ({
    countryShortCode: code,
    countryName: getCountryNameByCode(code),
  }));

export const countries = generateCountiresObjects();
