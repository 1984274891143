import { snakeCase } from 'lodash';
import { convertArrayOfObjectsToObject } from './array';

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;

type KeysToSnakeCase<T> = {
  [K in keyof T as CamelToSnakeCase<string & K>]: T[K];
};

export const convertKeysToSnakeCase = <T extends object>(
  obj: T,
): KeysToSnakeCase<T> => {
  return convertArrayOfObjectsToObject(
    Object.entries(obj).map(([key, value]) => ({
      [snakeCase(key)]: value,
    })),
  ) as KeysToSnakeCase<T>;
};

export const getNestedKeyValue = <T>(
  obj: Partial<T>,
): [keyof T & string, T[keyof T]] =>
  Object.entries(obj)[0] as [keyof T & string, T[keyof T]];
