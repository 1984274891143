/* eslint-disable react/jsx-props-no-spreading */
import type { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import {
  formItems,
  initialValues,
  type GeneralPriceChangeModalValues,
} from './config';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onGeneralPriceChange: (value: number, isPercentage: boolean) => void;
}

const { useForm, useWatch } = Form;

const GeneralPriceChangeModal: FC<Props> = ({
  isOpen,
  onClose,
  onGeneralPriceChange,
}) => {
  const [form] = useForm<GeneralPriceChangeModalValues>();
  const isPercentage = useWatch('isPercentage', form);

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleOnSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onGeneralPriceChange(values.normal, values.isPercentage);
        handleClose();
      })
      .catch((err) => err);
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Masowa zmiana cen" />}
      subtitle={
        <FormattedMessage defaultMessage="Zmiana ceny o wartość w PLN lub wartość procentową. Zmieniana jest cena Tłumaczenia zwykłego, która wpływa również na pozostałe ceny. Pamiętaj o sprawdzeniu cen po skorzystaniu z funkcjonalności" />
      }
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={handleClose}
      okText={<FormattedMessage defaultMessage="Edytuj" />}
      disableFormStyles
      disableModalStyles
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        {formItems({ form, isPercentage }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default GeneralPriceChangeModal;
