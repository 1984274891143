import type { IntlShape } from 'react-intl';
import {
  InvoiceType,
  AccountingNotesStatus,
  AccountingNoteShipmentType,
  DebtCollectionsStatus,
  InvoiceOverdueStatus,
  InvoiceStatus,
} from 'ducks/invoices/types';

export enum InvoiceDueDatePayment {
  ONE_WEEK = 7,
  TWO_WEEKS = 14,
  THREE_WEEKS = 21,
  ONE_MONTH = 30,
  ONE_AND_HALF_MONTH = 45,
  TWO_MONTHS = 60,
}

type Option<T> = {
  value: T;
  label: string;
};

export const invoiceTypes = (intl: IntlShape): Option<InvoiceType>[] => [
  {
    value: InvoiceType.VAT,
    label: intl.formatMessage({ defaultMessage: 'VAT' }),
  },
  {
    value: InvoiceType.PROFORMA,
    label: intl.formatMessage({ defaultMessage: 'Proforma' }),
  },
];

export const getInvoiceType = (intl: IntlShape, status: InvoiceType) =>
  invoiceTypes(intl).find(({ value }) => value === status);

export const invoiceDueDatePayments = (
  intl: IntlShape,
): Option<InvoiceDueDatePayment>[] => [
  {
    value: InvoiceDueDatePayment.ONE_WEEK,
    label: intl.formatMessage(
      { defaultMessage: '{days} dni' },
      { days: InvoiceDueDatePayment.ONE_WEEK },
    ),
  },
  {
    value: InvoiceDueDatePayment.TWO_WEEKS,
    label: intl.formatMessage(
      { defaultMessage: '{days} dni' },
      { days: InvoiceDueDatePayment.TWO_WEEKS },
    ),
  },
  {
    value: InvoiceDueDatePayment.THREE_WEEKS,
    label: intl.formatMessage(
      { defaultMessage: '{days} dni' },
      { days: InvoiceDueDatePayment.THREE_WEEKS },
    ),
  },
  {
    value: InvoiceDueDatePayment.ONE_MONTH,
    label: intl.formatMessage(
      { defaultMessage: '{days} dni' },
      { days: InvoiceDueDatePayment.ONE_MONTH },
    ),
  },
  {
    value: InvoiceDueDatePayment.ONE_AND_HALF_MONTH,
    label: intl.formatMessage(
      { defaultMessage: '{days} dni' },
      { days: InvoiceDueDatePayment.ONE_AND_HALF_MONTH },
    ),
  },
  {
    value: InvoiceDueDatePayment.TWO_MONTHS,
    label: intl.formatMessage(
      { defaultMessage: '{days} dni' },
      { days: InvoiceDueDatePayment.TWO_MONTHS },
    ),
  },
];

export const getInvoiceDueDatePayment = (
  intl: IntlShape,
  status: InvoiceDueDatePayment,
) => invoiceDueDatePayments(intl).find(({ value }) => value === status);

export const invoiceDueDatePaymentsObject = (intl: IntlShape) =>
  invoiceDueDatePayments(intl).reduce((acc, { value, label }) => {
    acc[value] = {
      value,
      label,
    };

    return acc;
  }, {} as Record<InvoiceDueDatePayment, Option<InvoiceDueDatePayment>>);

export const invoiceOverdueStatuses = (
  intl: IntlShape,
): Option<InvoiceOverdueStatus>[] => [
  {
    value: InvoiceOverdueStatus.UNPAID_ON_TIME,
    label: intl.formatMessage({ defaultMessage: 'Nieopłacona (w terminie)' }),
  },
  {
    value: InvoiceOverdueStatus.PAID_ON_TIME,
    label: intl.formatMessage({ defaultMessage: 'Opłacona (w terminie)' }),
  },
  {
    value: InvoiceOverdueStatus.NOT_SENT,
    label: intl.formatMessage({ defaultMessage: 'Niewysłane' }),
  },
  {
    value: InvoiceOverdueStatus.SUMMONS,
    label: intl.formatMessage({ defaultMessage: 'Wezwanie' }),
  },
  {
    value: InvoiceOverdueStatus.THIRTY_DAYS,
    label: intl.formatMessage({ defaultMessage: '30 dni' }),
  },
  {
    value: InvoiceOverdueStatus.KRD,
    label: intl.formatMessage({ defaultMessage: 'KRD' }),
  },
  {
    value: InvoiceOverdueStatus.COURT,
    label: intl.formatMessage({ defaultMessage: 'Sąd' }),
  },
  {
    value: InvoiceOverdueStatus.PAID,
    label: intl.formatMessage({ defaultMessage: 'Zapłacone' }),
  },
  {
    value: InvoiceOverdueStatus.JUDGMENT,
    label: intl.formatMessage({ defaultMessage: 'Wyrok' }),
  },
];

export const getInvoiceOverdueStatus = (
  intl: IntlShape,
  status: InvoiceOverdueStatus,
) => invoiceOverdueStatuses(intl).find(({ value }) => value === status);

export const invoiceOverdueStatusesObject = (intl: IntlShape) =>
  invoiceOverdueStatuses(intl).reduce((acc, { value, label }) => {
    acc[value] = {
      value,
      label,
    };

    return acc;
  }, {} as Record<InvoiceOverdueStatus, Option<InvoiceOverdueStatus>>);

export const invoiceStatuses = (intl: IntlShape): Option<InvoiceStatus>[] => [
  {
    value: InvoiceStatus.NOT_PAID,
    label: intl.formatMessage({ defaultMessage: 'Niezapłacono' }),
  },
  {
    value: InvoiceStatus.PAID,
    label: intl.formatMessage({ defaultMessage: 'Zapłacono' }),
  },
];

export const getInvoiceStatus = (intl: IntlShape, status: InvoiceStatus) =>
  invoiceStatuses(intl).find(({ value }) => value === status);

export const invoiceStatusesObject = (intl: IntlShape) =>
  invoiceStatuses(intl).reduce((acc, { value, label }) => {
    acc[value] = {
      value,
      label,
    };

    return acc;
  }, {} as Record<InvoiceStatus, Option<InvoiceStatus>>);

export const debtCollectionStatuses = (
  intl: IntlShape,
): Option<DebtCollectionsStatus>[] => [
  {
    value: DebtCollectionsStatus.IN_PROGRESS,
    label: intl.formatMessage({ defaultMessage: 'W trakcie' }),
  },
  {
    value: DebtCollectionsStatus.COMPLETED,
    label: intl.formatMessage({ defaultMessage: 'Zakończono' }),
  },
];

export const getDebtCollectionStatus = (
  intl: IntlShape,
  status: DebtCollectionsStatus,
) => debtCollectionStatuses(intl).find(({ value }) => value === status);

export const accountingNoteShipmentTypes = (
  intl: IntlShape,
): Option<AccountingNoteShipmentType>[] => [
  {
    value: AccountingNoteShipmentType.EMAIL,
    label: intl.formatMessage({ defaultMessage: 'Email' }),
  },
  {
    value: AccountingNoteShipmentType.POST,
    label: intl.formatMessage({ defaultMessage: 'Poczta' }),
  },
];

export const getAccountingNoteShipmentTypes = (
  intl: IntlShape,
  status: AccountingNoteShipmentType,
) => accountingNoteShipmentTypes(intl).find(({ value }) => value === status);

export const accountingNoteStatuses = (
  intl: IntlShape,
): Option<AccountingNotesStatus>[] => [
  {
    value: AccountingNotesStatus.PAID,
    label: intl.formatMessage({ defaultMessage: 'Zapłacono' }),
  },
  {
    value: AccountingNotesStatus.NOT_PAID,
    label: intl.formatMessage({ defaultMessage: 'Nie zapłacono' }),
  },
];

export const getAccountingNoteStatuses = (
  intl: IntlShape,
  status: AccountingNotesStatus,
) => accountingNoteStatuses(intl).find(({ value }) => value === status);
