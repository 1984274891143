import { Route } from 'react-router-dom';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import PreorderCreate from './views/PreorderCreate';
import PreorderDetails from './views/PreorderDetails';
import PreorderRealization from './views/PreorderRealization';
import PreorderSend from './views/PreorderSend/PreorderSend';
import PreordersTable from './views/PreordersTable';

export enum Routes {
  PREORDERS = '/preorders',
  PREORDER_CREATE = '/preorders/create',
  PREORDER_DETAILS = '/preorders/:id',
  PREORDER_EDIT = '/preorders/:id/edit',
  PREORDER_SEND = '/preorders/:id/send',
  PREORDER_REALIZATION = '/preorders/:id/realization/:variantId',
}

const PreordersRouting = () => {
  return (
    <AnimatedRoutes pathLevelWatch={2}>
      <Route
        path={`${Routes.PREORDERS.replace('/preorders', '')}`}
        element={<PreordersTable />}
      />
      <Route
        path={`${Routes.PREORDER_CREATE.replace('/preorders', '')}`}
        element={<PreorderCreate />}
      />
      <Route
        path={`${Routes.PREORDER_DETAILS.replace('/preorders', '')}`}
        element={<PreorderDetails isEditable={false} />}
      />
      <Route
        path={`${Routes.PREORDER_EDIT.replace('/preorders', '')}`}
        element={<PreorderDetails isEditable />}
      />
      <Route
        path={`${Routes.PREORDER_SEND.replace('/preorders', '')}`}
        element={<PreorderSend />}
      />
      <Route
        path={`${Routes.PREORDER_REALIZATION.replace('/preorders', '')}`}
        element={<PreorderRealization />}
      />
    </AnimatedRoutes>
  );
};

export default PreordersRouting;
