import type { ListItemValues } from './config';
import { Typography } from 'antd';

const { Text } = Typography;

export const getCorrectCellItem = (
  isNew: boolean,
  name: keyof ListItemValues,
  value: string,
) => {
  return isNew
    ? [
        {
          name,
        },
      ]
    : [{ render: <Text>{value ?? '---'}</Text> }];
};
