import { connect } from 'react-redux';
import { toggleSidenavMobileOpened } from 'ducks/config/slice';

const mapStateToProps = () => ({
  user: {},
});

const mapDispatchToProps = {
  toggleSidenavMobileOpened,
};

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
