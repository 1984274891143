import type { FormInstance } from 'antd';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { TranslatorsPriceListItem } from 'ducks/translators/types';
import { Switch } from 'antd';
import { FormattedMessage } from 'react-intl';
import LanguageSelect from 'components/ui/atoms/LanguageSelect';
import { POLAND_COUNTRY_CODE } from 'constants/countryAndLanguageCodes';

export type GenerateTranslationsModalValues = Pick<
  TranslatorsPriceListItem,
  'isCertified'
> & {
  langs: string[];
};

export const initialValues: GenerateTranslationsModalValues = {
  langs: [],
  isCertified: false,
};

type Props = {
  langs: string[];
  form: FormInstance<GenerateTranslationsModalValues>;
};

export const formItems = ({
  langs,
  form,
}: Props): FormItemRecord<GenerateTranslationsModalValues>[] => [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Przysięgłe" />,
    name: 'isCertified',
    additionalItemProps: { valuePropName: 'checked' },
    component: <Switch onChange={() => form.setFieldValue('langs', [])} />,
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="Języki" />,
    name: 'langs',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz języki" />,
      },
    ],
    component: (
      <LanguageSelect
        mode="multiple"
        allowClear={false}
        placeholder=""
        filterByCountryCode={({
          countryShortCode,
        }: {
          countryShortCode: string;
        }) =>
          !langs.includes(countryShortCode) &&
          countryShortCode !== POLAND_COUNTRY_CODE
        }
      />
    ),
  },
];
