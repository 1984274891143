import { Space } from 'antd';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import Switch, { SwitchSkeleton } from 'components/ui/atoms/Switch';
import TruncateText from 'components/ui/atoms/TruncateText';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setPreorderSelectedVariantIds } from 'ducks/view/preorders';
import PreorderAttachmentSwitches from 'views/Preorders/components/PreorderAttachmentSwitches';
import usePreorderRealizationServices from '../../usePreorderRealizationServices';

const VariantsAndAttachmentSection = () => {
  const { variantId = '' } = useParams();
  const dispatch = useAppDispatch();
  const selectedVariantIds = useAppSelector(
    ({ preorders }) => preorders.selectedVariantIds,
    shallowEqual,
  );
  const { preorderData, preorderVariantsData, selectedVariants, isLoading } =
    usePreorderRealizationServices();

  const handleChangeSelectedVariantIds = useCallback(
    (id: number) => dispatch(setPreorderSelectedVariantIds(id)),
    [dispatch],
  );

  useEffect(() => {
    if (!preorderData?.isCompany) return;

    if (preorderData.isCompany && preorderVariantsData.length >= 2) {
      selectedVariants.forEach(({ id }) => handleChangeSelectedVariantIds(id));
    }
  }, [
    preorderData?.isCompany,
    preorderVariantsData.length,
    selectedVariants,
    handleChangeSelectedVariantIds,
  ]);

  return (
    <CardCommon data-testid="variantsAndAttachmentSection" xs={24}>
      <Space className="width-100" direction="vertical" size="middle">
        <PreorderAttachmentSwitches isLoading={isLoading} />
        <div>
          <TruncateText strong maxLength={50}>
            <FormattedMessage defaultMessage="Oferta PDF" />
          </TruncateText>
          {isLoading && <SwitchSkeleton />}
          {!isLoading &&
            selectedVariants.map(({ id, variantIdx }) => (
              <Switch
                key={id}
                data-testid={`variantSwitch-${id}`}
                checked={selectedVariantIds.some(
                  (selectedVariantId) =>
                    selectedVariantId === Number(variantId),
                )}
                label={
                  <FormattedMessage
                    defaultMessage="Wariant {number}"
                    values={{ number: variantIdx }}
                  />
                }
                onChange={() => handleChangeSelectedVariantIds(id)}
              />
            ))}
        </div>
      </Space>
    </CardCommon>
  );
};

export default VariantsAndAttachmentSection;
