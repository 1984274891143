import type { TranslatorPriceTableRow } from './config';
import { Button, Space } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import Table from 'components/ui/molecules/Table';
import { useGetTranslatorsPriceListsQuery } from 'ducks/translators/service';
import { EditIcon } from 'icons';
import { Routes } from 'views/Translators/Routing';
import { certifiedColumns, regularColumns } from './config';

const PriceTable = () => {
  const { id: translatorId = '' } = useParams();
  const { data = [], isLoading } = useGetTranslatorsPriceListsQuery({
    translatorId: Number(translatorId),
  });

  const modifiedData = useMemo(
    (): TranslatorPriceTableRow[] =>
      data.map(({ id, ...restProps }) => {
        return {
          id,
          key: id,
          ...restProps,
        };
      }),
    [data],
  );

  const regularTranslations = useMemo(
    () => modifiedData.filter(({ isCertified }) => !isCertified),
    [modifiedData],
  );

  const certifiedTranslations = useMemo(
    () => modifiedData.filter(({ isCertified }) => isCertified),
    [modifiedData],
  );

  return (
    <Space direction="vertical" size="large" className="width-100">
      <Table<TranslatorPriceTableRow>
        data-testid="priceTable"
        cardTitle={
          <FormattedMessage defaultMessage="Cennik - Tłumaczenie zwykłe" />
        }
        columns={regularColumns}
        data={regularTranslations}
        isLoading={isLoading}
        cardTitleExtra={
          <Link
            to={Routes.TRANSLATOR_PROFILE_PRICE.replace(':id', translatorId)}
            rel="noopener noreferrer"
          >
            <Button type="primary" icon={<EditIcon />} disabled={isLoading}>
              <FormattedMessage defaultMessage="Edytuj cennik" />
            </Button>
          </Link>
        }
      />
      <Table<TranslatorPriceTableRow>
        cardTitle={
          <FormattedMessage defaultMessage="Cennik - Tłumaczenie przysięgłe" />
        }
        columns={certifiedColumns}
        data={certifiedTranslations}
        isLoading={isLoading}
        cardTitleExtra={
          <Link
            to={Routes.TRANSLATOR_PROFILE_PRICE.replace(':id', translatorId)}
            rel="noopener noreferrer"
          >
            <Button type="primary" icon={<EditIcon />} disabled={isLoading}>
              <FormattedMessage defaultMessage="Edytuj cennik" />
            </Button>
          </Link>
        }
      />
    </Space>
  );
};

export default PriceTable;
