import type { ListItemRecord } from 'components/ui/molecules/List';
import type { FormValues } from 'views/Settings/components/SettingsForm/config';
import { Col, Row, notification, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  useGetSettingsQuery,
  useSendSettingsMutation,
} from 'ducks/settings/service';
import {
  FormsIds,
  getListItems,
} from 'views/Settings/components/SettingsForm/config';
import SettingsForm from 'views/Settings/components/SettingsForm/SettingsForm';

const { Title } = Typography;

const QuotationExceptions = () => {
  const { data: queryFormData = [], isLoading } = useGetSettingsQuery({
    sectionId: FormsIds.QUOTATION_EXCEPTIONS,
  });
  const [sendForm, { isLoading: isSendingLoading, isSuccess, isError }] =
    useSendSettingsMutation();
  const [notify, contextHolder] = notification.useNotification();

  const modifiedData = queryFormData?.flatMap(({ value, ...rest }) => {
    return {
      value: typeof value === 'object' ? value.join('\n') : value,
      ...rest,
    };
  });

  const composeDataAndSend = async (values: FormValues) => {
    const items = Object.fromEntries(
      Object.entries(values).map(([key, val]) => {
        if (typeof val === 'string' && val.search(/[\n]/) > 0)
          return [key, val.split('\n')];
        return [key, val];
      }),
    );

    await sendForm({ sectionId: FormsIds.QUOTATION_EXCEPTIONS, ...items });
  };

  return (
    <Row gutter={[24, 24]}>
      {contextHolder}
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <SettingsForm
          notify={notify}
          formElements={modifiedData}
          formTitle={
            <FormattedMessage defaultMessage="Edytuj wykluczone adresy e-mail" />
          }
          cardTitle={
            <Title level={4} style={{ margin: 0 }}>
              <FormattedMessage defaultMessage="Import wycen - wykluczenia" />
            </Title>
          }
          listItems={getListItems(modifiedData) as ListItemRecord[]}
          isLoadingList={isLoading}
          isLoadingSend={isSendingLoading}
          isSuccessSend={isSuccess}
          isErrorSend={isError}
          onSubmit={composeDataAndSend}
        />
      </Col>
    </Row>
  );
};

export default QuotationExceptions;
