import type { InvoiceSendValues } from '../../config';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Switch, { SwitchSkeleton } from 'components/ui/atoms/Switch';
import TruncateText from 'components/ui/atoms/TruncateText';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useInvoiceSendServices } from '../../useInvoiceSendServices';

const SwitchesSection = () => {
  const { invoiceVatData, isInvoiceVatFetching } = useInvoiceSendServices();
  const number = invoiceVatData?.number;

  return (
    <CardCommon data-testid="switchesSection" xs={24} md={10} lg={9}>
      <Space className="width-100" direction="vertical" size="middle">
        <div>
          <TruncateText strong maxLength={50}>
            <FormattedMessage defaultMessage="Faktura VAT" />
          </TruncateText>
          {isInvoiceVatFetching && <SwitchSkeleton />}
          {!isInvoiceVatFetching && number && (
            <FormItem<InvoiceSendValues>
              name="isInvoiceVat"
              component={
                <Switch
                  label={
                    <FormattedMessage
                      defaultMessage="Faktura VAT nr {number}"
                      values={{ number }}
                    />
                  }
                />
              }
              additionalItemProps={{
                valuePropName: 'checked',
              }}
            />
          )}
        </div>
      </Space>
    </CardCommon>
  );
};

export default SwitchesSection;
