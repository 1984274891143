import type { LetterSummons } from 'ducks/invoices/types';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import PopoverDotCell from 'components/ui/atoms/TableCells/PopoverDotCell';
import { DayjsFormat } from 'constants/dayjsFormats';
import { NO_DATA } from 'constants/strings';

type Props = { letterSummons: LetterSummons | null };

const LetterSummonsCell: FC<Props> = ({ letterSummons }) => {
  return (
    <PopoverDotCell
      data-testid="letterSummonsCell"
      filledContent={<FormattedMessage defaultMessage="Tak" />}
      notFilledContent={<FormattedMessage defaultMessage="Nie" />}
      content={
        !!letterSummons && (
          <Space direction="vertical">
            <FormattedMessage
              defaultMessage="Wysłano: {date}"
              values={{
                date: letterSummons.dateSent
                  ? dayjs(letterSummons.dateSent).format(DayjsFormat.DATE)
                  : NO_DATA,
              }}
            />
            <FormattedMessage
              defaultMessage="Odebrano: {date}"
              values={{
                date: letterSummons.dateReceived
                  ? dayjs(letterSummons.dateReceived).format(DayjsFormat.DATE)
                  : NO_DATA,
              }}
            />
          </Space>
        )
      }
    />
  );
};

export default LetterSummonsCell;
