import type { FormListItemRecord } from 'components/ui/molecules/FormList';
import type { TranslatorsPriceListsRequest } from 'ducks/translators/types';
import { Button, Popconfirm } from 'antd';
import { FormattedMessage } from 'react-intl';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import LanguagePair from 'components/ui/atoms/LanguagePair';
import { TrashIcon } from 'icons';
import styles from './styles.module.scss';

export type ListItem = Omit<
  TranslatorsPriceListsRequest,
  'translatorId' | 'id'
> & {
  id: number | string;
};

export type HandleAddProps = {
  langFrom: string;
  langTo: string;
  isCertified: boolean;
}[];

type FormListItem = FormListItemRecord<ListItem>;

type FormListItemActions = {
  handleDelete: (id: number | string) => void;
};

export const initialPriceListItemValues: ListItem = {
  id: '',
  isCertified: false,
  authFrom: null,
  authTo: null,
  correctionFrom: null,
  correctionTo: null,
  normalFrom: null,
  normalTo: null,
  specialFrom: null,
  specialTo: null,
  langFrom: '',
  langTo: '',
};

const MIN_INPUT_NUMBER = 0;
const MAX_INPUT_NUMBER = 99999;

const calcMaxInputNumber = (value: number | null) => value ?? MAX_INPUT_NUMBER;

const calcMinInputNumber = (value: number | null) => value ?? MIN_INPUT_NUMBER;

const sharedFormListItems: FormListItem[] = [
  {
    key: 1,
    title: <FormattedMessage defaultMessage="Język" />,
    cellItems: ({ langFrom, langTo }) => [
      {
        render: <LanguagePair langFrom={langFrom} langTo={langTo} />,
      },
    ],
  },
  {
    key: 2,
    title: <FormattedMessage defaultMessage="Normalne" />,
    cellItems: ({ normalFrom, normalTo }) => [
      {
        name: 'normalFrom',
        render: (
          <DecimalInputNumber
            data-testid="normalFromInput"
            max={calcMaxInputNumber(normalTo)}
          />
        ),
      },
      {
        name: 'normalTo',
        render: (
          <DecimalInputNumber
            data-testid="normalToInput"
            min={calcMinInputNumber(normalFrom)}
          />
        ),
      },
    ],
  },
  {
    key: 3,
    title: <FormattedMessage defaultMessage="Specjalistyczne" />,
    cellItems: ({ specialTo, specialFrom }) => [
      {
        name: 'specialFrom',
        render: (
          <DecimalInputNumber
            data-testid="specialFromInput"
            max={calcMaxInputNumber(specialTo)}
          />
        ),
      },
      {
        name: 'specialTo',
        render: (
          <DecimalInputNumber
            data-testid="specialToInput"
            min={calcMinInputNumber(specialFrom)}
          />
        ),
      },
    ],
  },
];

const sharedActionsItems = ({
  handleDelete,
}: FormListItemActions): FormListItem[] => [
  {
    key: 5,
    title: '',
    cellItems: ({ id }) => [
      {
        render: (
          <Popconfirm
            title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
            okText={<FormattedMessage defaultMessage="Tak" />}
            cancelText={<FormattedMessage defaultMessage="Nie" />}
            onConfirm={() => handleDelete(id)}
          >
            <Button
              data-testid={`deleteButton${id}`}
              size="small"
              type="link"
              icon={<TrashIcon />}
              danger
              className={styles.deleteButton}
            >
              <FormattedMessage defaultMessage="Usuń" />
            </Button>
          </Popconfirm>
        ),
      },
    ],
  },
];

export const regularFormListItems = ({
  handleDelete,
}: FormListItemActions): FormListItem[] => [
  ...sharedFormListItems,
  {
    key: 4,
    title: <FormattedMessage defaultMessage="Korekta" />,
    cellItems: ({ correctionTo, correctionFrom }) => [
      {
        name: 'correctionFrom',
        render: <DecimalInputNumber max={calcMaxInputNumber(correctionTo)} />,
      },
      {
        name: 'correctionTo',
        render: <DecimalInputNumber min={calcMinInputNumber(correctionFrom)} />,
      },
    ],
  },
  ...sharedActionsItems({ handleDelete }),
];

export const certifiedFormListItems = ({
  handleDelete,
}: FormListItemActions): FormListItem[] => [
  ...sharedFormListItems,
  {
    key: 4,
    title: <FormattedMessage defaultMessage="Uwierzytelnienie" />,
    cellItems: ({ authFrom, authTo }) => [
      {
        name: 'authFrom',
        render: <DecimalInputNumber max={calcMaxInputNumber(authTo)} />,
      },
      {
        name: 'authTo',
        render: <DecimalInputNumber min={calcMinInputNumber(authFrom)} />,
      },
    ],
  },
  ...sharedActionsItems({ handleDelete }),
];
