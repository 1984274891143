import type {
  GetPreorderVariantsRequest,
  GetPreorderVariantsResponse,
  GetPreorderRequest,
  GetPreorderResponse,
  GetPreordersRequest,
  GetPreordersResponse,
  AddPreorderRequest,
  AddPreorderResponse,
  UpdatePreorderRequest,
  GetPreorderServicesResponse,
  UnblockPreorderRequest,
  SendReferenceFileRequest,
  SendEmailPreorderRequest,
  ConvertPreorderRequest,
  DeletePreorderCommissionRequest,
  SendReferenceFileResponse,
  SendPreorderNumberRequest,
  UpdateClientPreorderRequest,
  DeletePreorderRequest,
  GetPreorderZipFilesRequest,
  GetPreorderZipFilesResponse,
  DeletePreorderReferenceFileRequest,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { snakeCase } from 'lodash';
import baseQuery, { doRequest } from 'ducks/api';
import { formatFilterParameter, formatSortParameter } from 'utilities/api';
import { convertKeysToSnakeCase } from 'utilities/object';
import { PreordersTag } from './types';

export enum Endpoint {
  GetPreorders = '/preorders',
  GetPreorderServices = '/preorders/services',
  AddPreorder = '/preorders',
  GetPreorder = '/preorders/:id',
  DeletePreorder = '/preorders/:id',
  GetPreorderVariants = '/preorders/:id/variants',
  UpdatePreorder = '/preorders/:id',
  UnblockPreorder = '/preorders/unblock',
  GetReferenceFile = '/preorders/:preorderId/referenceFile',
  SendReferenceFile = '/preorders/:preorderId/referenceFile',
  DeleteReferenceFile = '/preorders/:preorderId/referenceFile',
  SendEmailPreorder = '/preorders/emails',
  ConvertPreorder = '/preorders/:preorderId/variants/:variantId/convert',
  DeletePreorderCommission = '/preorders/:preorderId/variants/:variantId',
  GetPreorderZipFiles = '/preorders/:preorderId/files-archive',
  SendPreorderNumber = '/preorders/number',
  UpdatePreorderClient = '/preorders/:id/client-change',
}

export const preordersApi = createApi({
  reducerPath: 'preordersApi',
  baseQuery: baseQuery(),
  tagTypes: [PreordersTag.Preorders, PreordersTag.PreordersVariants],
  endpoints: (build) => ({
    getPreorders: build.query<GetPreordersResponse, GetPreordersRequest>({
      query: ({ page, searchTerm, filter, sort }) => ({
        url: Endpoint.GetPreorders,
        method: 'GET',
        params: {
          page,
          s: searchTerm,
          sort: formatSortParameter(sort),
          filter: formatFilterParameter(filter),
        },
      }),
      providesTags: [PreordersTag.Preorders],
    }),
    getPreorderServices: build.query<GetPreorderServicesResponse, void>({
      query: () => ({
        url: Endpoint.GetPreorderServices,
        method: 'GET',
      }),
      providesTags: [PreordersTag.Preorders],
    }),
    addPreorder: build.mutation<AddPreorderResponse, AddPreorderRequest>({
      query: ({ ...data }) => ({
        url: Endpoint.AddPreorder,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) => (error ? [] : [PreordersTag.Preorders]),
    }),
    getPreorder: build.query<GetPreorderResponse, GetPreorderRequest>({
      query: ({ id, type }) => ({
        url: Endpoint.GetPreorder.replace(':id', id.toString()),
        method: 'GET',
        params: {
          type,
        },
      }),
      providesTags: (_result, _error, { id }) => [
        { type: PreordersTag.Preorders, id },
      ],
    }),
    deletePreorder: build.mutation<void, DeletePreorderRequest>({
      query: ({ id }) => ({
        url: Endpoint.DeletePreorder.replace(':id', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (_, error, { id }) =>
        error
          ? []
          : [
              { type: PreordersTag.Preorders },
              { type: PreordersTag.Preorders, id },
              { type: PreordersTag.PreordersVariants, id },
            ],
    }),
    getPreorderVariants: build.query<
      GetPreorderVariantsResponse,
      GetPreorderVariantsRequest
    >({
      query: ({ id }) => ({
        url: Endpoint.GetPreorderVariants.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: (_result, _error, { id }) => [
        { type: PreordersTag.PreordersVariants, id },
      ],
    }),
    updatePreorder: build.mutation<void, UpdatePreorderRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.UpdatePreorder.replace(':id', id.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error, { id }) =>
        error ? [] : [{ type: PreordersTag.PreordersVariants, id }],
    }),
    unblockPreorder: build.mutation<void, UnblockPreorderRequest>({
      query: (data) => ({
        url: Endpoint.UnblockPreorder,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error, { preorderId }) =>
        error ? [] : [{ type: PreordersTag.Preorders, id: preorderId }],
    }),
    sendReferenceFile: build.mutation<
      SendReferenceFileResponse,
      SendReferenceFileRequest
    >({
      query: ({ preorderId, file }) => {
        const formData = new FormData();

        formData.append('file', file, file.name);

        return {
          url: Endpoint.SendReferenceFile.replace(':preorderId', preorderId),
          data: formData,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      invalidatesTags: [PreordersTag.Preorders],
    }),
    sendEmailPreorder: build.mutation<void, SendEmailPreorderRequest>({
      query: (data) => {
        const formData = new FormData();
        const { files, variants, ...restData } = data;

        Object.entries(restData).forEach(([key, value]) => {
          const modifiedValue = value === null ? '' : String(value);

          formData.append(snakeCase(key), modifiedValue);
        });

        if (variants.length) {
          variants.forEach((variant) => {
            formData.append(`variants[]`, String(variant));
          });
        }

        if (files.length) {
          files.forEach((file, index) => {
            formData.append(
              `files[${index}][file]`,
              new Blob([file]),
              file.name,
            );
          });
        }

        return {
          url: Endpoint.SendEmailPreorder,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
    }),
    convertPreorder: build.mutation<void, ConvertPreorderRequest>({
      query: ({ preorderId, variantId, ...data }) => {
        const formData = new FormData();
        const { files, variants, ...restData } = data;

        Object.entries(restData).forEach(([key, value]) => {
          const modifiedValue = value === null ? '' : String(value);

          formData.append(snakeCase(key), modifiedValue);
        });

        if (variants.length) {
          variants.forEach((variant) => {
            formData.append(`variants[]`, String(variant));
          });
        }

        if (files.length) {
          files.forEach((file, index) => {
            formData.append(
              `files[${index}][file]`,
              new Blob([file]),
              file.name,
            );
          });
        }

        return {
          url: Endpoint.ConvertPreorder.replace(
            ':preorderId',
            preorderId.toString(),
          ).replace(':variantId', variantId.toString()),
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
    }),
    deletePreorderCommission: build.mutation<
      void,
      DeletePreorderCommissionRequest
    >({
      query: ({ preorderId, variantId }) => ({
        url: Endpoint.DeletePreorderCommission.replace(
          ':preorderId',
          preorderId.toString(),
        ).replace(':variantId', variantId.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: [PreordersTag.Preorders],
    }),
    sendPreorderNumber: build.mutation<void, SendPreorderNumberRequest>({
      query: (data) => ({
        url: Endpoint.SendPreorderNumber,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: [PreordersTag.Preorders],
    }),
    updateClientPreorder: build.mutation<void, UpdateClientPreorderRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.UpdatePreorderClient.replace(':id', id.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error, { id }) =>
        error ? [] : [{ type: PreordersTag.Preorders, id }],
    }),
    getPreorderZipFiles: build.query<
      GetPreorderZipFilesResponse,
      GetPreorderZipFilesRequest
    >({
      query: ({ preorderId }) => ({
        url: Endpoint.GetPreorderZipFiles.replace(
          ':preorderId',
          preorderId.toString(),
        ),
        method: 'GET',
        responseType: 'blob',
      }),
    }),
    deletePreorderReferenceFile: build.mutation<
      void,
      DeletePreorderReferenceFileRequest
    >({
      query: ({ preorderId }) => ({
        url: Endpoint.DeleteReferenceFile.replace(':preorderId', preorderId),
        method: 'DELETE',
      }),
      invalidatesTags: [PreordersTag.Preorders],
    }),
  }),
});

export const getReferenceFile = async ({
  preorderId,
}: Pick<SendReferenceFileRequest, 'preorderId'>): Promise<unknown | null> => {
  const response = await doRequest({
    url: Endpoint.GetReferenceFile.replace(':preorderId', preorderId),
    responseType: 'blob',
  });

  if (!response) return null;

  return response.data as unknown;
};

export const {
  useGetPreordersQuery,
  useGetPreorderServicesQuery,
  useAddPreorderMutation,
  useGetPreorderQuery,
  useDeletePreorderMutation,
  useGetPreorderVariantsQuery,
  useUpdatePreorderMutation,
  useUnblockPreorderMutation,
  useSendReferenceFileMutation,
  useSendEmailPreorderMutation,
  useConvertPreorderMutation,
  useSendPreorderNumberMutation,
  useUpdateClientPreorderMutation,
  useLazyGetPreorderZipFilesQuery,
  useDeletePreorderReferenceFileMutation,
} = preordersApi;
