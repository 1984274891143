import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { UpdateClientContactRequest } from 'ducks/clients/types';
import { Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import { validatePhoneNumberRule } from 'utilities/antdFormRules';

export type EditContactFormValues = Omit<
  UpdateClientContactRequest,
  'clientId'
>;

export type EditContactFormInput = FormItemRecord<EditContactFormValues> & {
  radioValue: EditContactFormValues['emailDefault'];
};

export const editContactFormInputs: EditContactFormInput[] = [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="E-mail:" />,
    name: 'email1',
    radioValue: 1,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj email" />,
      },
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
    additionalInputProps: {
      addonAfter: (
        <Radio data-testid="email1Radio" value={1} style={{ margin: 0 }} />
      ),
    },
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="E-mail 2:" />,
    name: 'email2',
    radioValue: 2,
    rules: [
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
    additionalInputProps: {
      addonAfter: (
        <Radio data-testid="email2Radio" value={2} style={{ margin: 0 }} />
      ),
    },
  },
  {
    key: 3,
    label: <FormattedMessage defaultMessage="Tel:" />,
    name: 'phone1',
    radioValue: 1,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj numer telefonu" />,
      },
      validatePhoneNumberRule(),
    ],
    additionalInputProps: {
      addonAfter: (
        <Radio data-testid="phone1Radio" value={1} style={{ margin: 0 }} />
      ),
    },
  },
  {
    key: 4,
    label: <FormattedMessage defaultMessage="Tel 2:" />,
    name: 'phone2',
    radioValue: 2,
    rules: [validatePhoneNumberRule()],
    additionalInputProps: {
      addonAfter: (
        <Radio data-testid="phone2Radio" value={2} style={{ margin: 0 }} />
      ),
    },
  },
];
