import type { TranslationsKey } from './utilities';
import type { FC, ReactElement } from 'react';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import translations from 'lang';
import {
  defaultLang,
  filterByAvailableLocale,
  setIntl,
  storageKey,
} from './utilities';

export const LangContext = React.createContext({
  lang: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  switchLang: (langKey: string) => {
    // pragma: no cover
  },
});

type Props = {
  children?: ReactElement | ReactElement[];
};

const Intl: FC<Props> = ({ children }) => {
  const [lang, setLang] = useState<TranslationsKey>(
    filterByAvailableLocale(
      localStorage.getItem(storageKey) || navigator.language || defaultLang,
    ),
  );

  const [messages, setMessages] = useState({});

  useLayoutEffect(() => {
    const selectedLang = localStorage.getItem(storageKey);
    if (selectedLang) setLang(filterByAvailableLocale(selectedLang));

    translations[lang].then(setMessages);
    setIntl(lang);
  }, [lang]);

  const switchLang = (langKey: string) => {
    const filteredLangKey = filterByAvailableLocale(langKey);
    setLang(filteredLangKey);
    localStorage.setItem(storageKey, filteredLangKey);
  };

  const memoizedLang = useMemo(() => ({ lang, switchLang }), [lang]);

  return (
    <LangContext.Provider value={memoizedLang}>
      <IntlProvider
        messages={messages}
        locale={lang}
        defaultLocale={defaultLang}
      >
        {children}
      </IntlProvider>
    </LangContext.Provider>
  );
};

export default Intl;
