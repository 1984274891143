export const scrollToElement = (elementId: string, offset = 0) => {
  const element = document.getElementById(elementId);
  const header = document.querySelector('.ant-layout-header');
  const headerHeight = header ? header.getBoundingClientRect().height + 10 : 0;

  if (!element) return;

  const top =
    element.getBoundingClientRect().top +
    window.scrollY +
    -headerHeight +
    offset;

  if (element) {
    window.scrollTo({ top, behavior: 'smooth' });
  }
};

export const scrollToRef = (
  isLoading: boolean,
  itemToScroll: React.MutableRefObject<HTMLElement | null>,
) => {
  if (isLoading || !itemToScroll.current) return;

  const header = document.querySelector('.ant-layout-header');
  const headerHeight = header ? header.getBoundingClientRect().height + 10 : 0;

  window.scrollTo({
    top:
      itemToScroll.current.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      headerHeight,
    behavior: 'smooth',
  });
};

export const scrollToRefs = (
  myRefs: React.MutableRefObject<never[]>,
  isLoading: boolean,
  scrollToVariantIndex: number,
) => {
  if (isLoading || myRefs?.current?.length <= 0 || scrollToVariantIndex < 0)
    return;

  const itemToScroll: { current: HTMLElement } =
    myRefs.current[scrollToVariantIndex];

  scrollToRef(isLoading, itemToScroll);
};
