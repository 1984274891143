import { Form } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import Empty from 'components/ui/atoms/Empty';
import CardCommon from 'components/ui/molecules/CardCommon';
import { PlusIcon } from 'icons';
import styles from '../../styles.module.scss';
import { useInvoiceOverdueDetailsServices } from '../../useInvoiceOverdueDetailsServices';
import AddLetterSummonsModal from './components/AddLetterSummonsModal';
import LetterSummonItem from './components/LetterSummonItem';

const LetterSummonsSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isInvoiceOverdueFetching,
    invoiceLetterSummonData,
    isInvoiceLetterSummonFetching,
  } = useInvoiceOverdueDetailsServices();
  const isLoading = isInvoiceOverdueFetching || isInvoiceLetterSummonFetching;
  const isEmpty = !isLoading && !invoiceLetterSummonData.length;

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <CardCommon
        title={
          <div className={styles.cardCommonTitleWrapper}>
            <FormattedMessage defaultMessage="Wezwanie listowe" />
            <Button
              type="primary"
              icon={<PlusIcon />}
              loading={isLoading}
              onClick={handleOpenModal}
            >
              <FormattedMessage defaultMessage="Dodaj" />
            </Button>
          </div>
        }
        isLoading={isLoading}
      >
        {isEmpty ? (
          <Empty
            description={
              <FormattedMessage defaultMessage="Nie wysłano wezwania listownego" />
            }
          />
        ) : (
          <Form autoComplete="off" layout="vertical">
            {invoiceLetterSummonData.map((letterSummon) => (
              <LetterSummonItem
                key={letterSummon.id}
                letterSummon={letterSummon}
              />
            ))}
          </Form>
        )}
      </CardCommon>
      <AddLetterSummonsModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default LetterSummonsSection;
