import React from 'react';
import { Route } from 'react-router-dom';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import Form from './views/Form';
import List from './views/List';

export enum Routes {
  ADD = '/add-template',
  EDIT = '/:id',
  LIST = '/',
}

const EmailRouting: React.FC = () => {
  return (
    <AnimatedRoutes pathLevelWatch={3}>
      <Route path={`${Routes.LIST}/*`} element={<List />} />
      <Route path={`${Routes.EDIT}/*`} element={<Form />} />
      <Route path={`${Routes.ADD}/*`} element={<Form />} />
    </AnimatedRoutes>
  );
};

export default EmailRouting;
