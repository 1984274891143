import type { UploadFile } from 'antd';
import type { FC } from 'react';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import TruncateText from 'components/ui/atoms/TruncateText';
import Upload, { UploadItem } from 'components/ui/molecules/Upload';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { togglePreorderUploadedFile } from 'ducks/view/preorders';
import styles from './styles.module.scss';

type Props = {
  isLoading: boolean;
};

const PreorderAttachmentSwitches: FC<Props> = ({ isLoading }) => {
  const dispatch = useAppDispatch();
  const files = useAppSelector(({ preorders }) => preorders.uploadedFiles);

  const handleToggleUploadedFile = (file: UploadFile) =>
    dispatch(togglePreorderUploadedFile(file));

  return (
    <Space className="width-100" direction="vertical">
      <div className={styles.wrapper}>
        <TruncateText strong maxLength={50} nowrap>
          <FormattedMessage defaultMessage="Załącznik" />
        </TruncateText>
        <Upload
          onChange={({ file }) => handleToggleUploadedFile(file)}
          disabled={isLoading}
        >
          <Button loading={isLoading}>
            <FormattedMessage defaultMessage="Dodaj" />
          </Button>
        </Upload>
      </div>
      {files.map((file) => (
        <UploadItem
          key={file.uid}
          uploadFile={file}
          onDelete={handleToggleUploadedFile}
        />
      ))}
    </Space>
  );
};

export default PreorderAttachmentSwitches;
