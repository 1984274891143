import type { ListItemRecord } from 'components/ui/molecules/List';
import type { Client } from 'ducks/clients/types';
import { FormattedMessage } from 'react-intl';
import CountryName from 'components/ui/atoms/CountryName';
import { getInvoiceClientFullAddress } from 'utilities/address';

export const invoiceListItems = (
  data: Client | undefined,
): ListItemRecord[] => [
  {
    label: <FormattedMessage defaultMessage="Nazwa:" />,
    description: data?.invoiceName,
  },
  {
    label: <FormattedMessage defaultMessage="NIP:" />,
    description: data?.invoiceNip,
  },
  {
    label: <FormattedMessage defaultMessage="Adres:" />,
    description: getInvoiceClientFullAddress(data),
  },
  {
    label: <FormattedMessage defaultMessage="E-mail:" />,
    description: data?.invoiceEmail,
  },
  {
    label: <FormattedMessage defaultMessage="Kraj:" />,
    description: <CountryName countryCode={data?.invoiceCountry} />,
  },
];
