import type { ListItemRecord } from 'components/ui/molecules/List';
import type { Translator } from 'ducks/translators/types';
import { Badge, Button, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { EditIcon } from 'icons';
import styles from './styles.module.scss';

export const statusCardListItems = ({
  data,
  handleModalOpen,
}: {
  data: Translator | undefined;
  handleModalOpen: () => void;
}): ListItemRecord[] => {
  const isActiveStatus = data?.status === 1;

  return [
    {
      label: (
        <Space align="center">
          <Badge status={isActiveStatus ? 'success' : 'default'} />
          {isActiveStatus ? (
            <FormattedMessage defaultMessage="Aktywny" />
          ) : (
            <FormattedMessage defaultMessage="Nieaktywny" />
          )}
          <Button
            ghost
            className={styles.button}
            onClick={handleModalOpen}
            data-testid="openModalButton"
          >
            <EditIcon />
          </Button>
        </Space>
      ),
      description: (
        <>
          <FormattedMessage defaultMessage="Powód" />:{' '}
          {data?.statusReason || <FormattedMessage defaultMessage="Brak" />}
        </>
      ),
    },
  ];
};
