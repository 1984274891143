/* eslint-disable no-param-reassign */
import type { GlobalSliceState } from './type';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState: GlobalSliceState = {
  isLoading: false,
  triggerOpenAllNotes: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalIsLoading: (
      state,
      { payload }: PayloadAction<GlobalSliceState['isLoading']>,
    ) => {
      state.isLoading = payload;
    },
    resetGlobalState: () => initialState,
    setTriggerOpenAllNotes: (
      state,
      { payload }: PayloadAction<GlobalSliceState['triggerOpenAllNotes']>,
    ) => {
      state.triggerOpenAllNotes = payload;
    },
  },
});

export const { setGlobalIsLoading, setTriggerOpenAllNotes } =
  globalSlice.actions;

export default globalSlice.reducer;
