import type { LetterSummons } from 'ducks/invoices/types';
import type { FC } from 'react';
import FormItem from 'components/ui/atoms/FormItem';
import { formItems } from '../../config';
import styles from './styles.module.scss';

type Props = {
  letterSummon: LetterSummons;
};

const LetterSummonItem: FC<Props> = ({ letterSummon }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {formItems(letterSummon).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </div>
    </div>
  );
};

export default LetterSummonItem;
