import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
};

const TitleWrapper = ({ children }: Props) => {
  return <div className={styles.titleWrapper}>{children}</div>;
};

export default TitleWrapper;
