import type { UpdateOrderValues } from '../../config';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Order } from 'ducks/orders/types';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import TruncateText from 'components/ui/atoms/TruncateText';
import ClientAvatar from 'components/ui/molecules/ClientAvatar';
import { NO_DATA } from 'constants/strings';
import { calcBrutto, calcNetto } from 'utilities/calcPrice';
import { Routes } from 'views/Clients/Routing';

const { Text } = Typography;

type Props = {
  data: Order;
};

export const formItems = ({
  data,
}: Props): FormItemRecord<UpdateOrderValues>[] => {
  const {
    client,
    verifiedKrd,
    priceNetto,
    paid: paidBrutto,
    clientId,
    vatRate,
    isCompany,
  } = data;
  const isClientExist = !!data.client;
  const isDebtor = !!client?.isDebtor;
  const balance = client?.balance;
  const discount = client?.discount;
  const clientName = client?.name;
  const priceBrutto = calcBrutto(priceNetto, vatRate);
  const paidNetto = calcNetto(paidBrutto, vatRate);

  return [
    {
      key: 'contactPerson',
      label: <FormattedMessage defaultMessage="Nazwa:" />,
      component: clientName ? (
        <Link
          to={Routes.CLIENT_DETAILS.replace(
            ':clientId',
            clientId ? clientId?.toString() : '',
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ClientAvatar
            data-testid="name"
            isCompany={isCompany}
            preferredName={clientName}
            strong
            underline
          />
        </Link>
      ) : (
        <TruncateText data-testid="name" strong>
          {NO_DATA}
        </TruncateText>
      ),
    },
    {
      key: 'clientExist',
      label: <FormattedMessage defaultMessage="Istniejący klient:" />,
      component: (
        <CircleCheckbox data-testid="clientExist" value={isClientExist} />
      ),
    },
    {
      key: 'discount',
      label: <FormattedMessage defaultMessage="Stały rabat:" />,
      component: (
        <Text data-testid="balance" strong>
          {discount ? `${discount}%` : NO_DATA}
        </Text>
      ),
    },
    {
      key: 'priceNetto',
      label: <FormattedMessage defaultMessage="Wartość wariantu:" />,
      component: (
        <Text data-testid="priceNetto" strong>
          {priceNetto ? (
            <>
              <CurrencyFormatter value={priceNetto} /> (
              <CurrencyFormatter value={priceBrutto} />)
            </>
          ) : (
            NO_DATA
          )}
        </Text>
      ),
    },
    {
      key: 'balance',
      label: <FormattedMessage defaultMessage="Saldo:" />,
      component: (
        <Text data-testid="balance" strong>
          {balance ? <CurrencyFormatter value={balance} /> : NO_DATA}
        </Text>
      ),
      hidden: !isClientExist,
    },
    {
      key: 'debtor',
      label: <FormattedMessage defaultMessage="Dłużnik:" />,
      component: <CircleCheckbox data-testid="debtor" value={isDebtor} />,
      hidden: !isClientExist,
    },
    {
      key: 'verifiedKrd',
      label: <FormattedMessage defaultMessage="KRD:" />,
      component: (
        <CircleCheckbox data-testid="verifiedKrd" value={verifiedKrd} />
      ),
    },
    {
      key: 'paid',
      label: <FormattedMessage defaultMessage="Wartość płatności:" />,
      component: (
        <Text data-testid="paid" strong>
          {paidBrutto ? (
            <>
              <CurrencyFormatter value={paidNetto} /> (
              <CurrencyFormatter value={paidBrutto} />)
            </>
          ) : (
            NO_DATA
          )}
        </Text>
      ),
    },
  ];
};
