import type { UpdatePreorderValues } from '../../config';
import type { FormInstance } from 'antd';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { UpdatePreorderRequest } from 'ducks/preorders/types';
import type { FC } from 'react';
import { Space } from 'antd';
import { useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { useUpdatePreorderMutation } from 'ducks/preorders/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setGlobalIsLoading } from 'ducks/view/global';
import { setTriggerFormSubmit } from 'ducks/view/preorders';
import { Routes as PreorderRoutes } from 'views/Preorders/Routing';
import { usePreorderDetailsServices } from '../../usePreorderDetailsServices';

type Props = {
  form: FormInstance<UpdatePreorderValues>;
  notify: NotificationInstance;
};

const ActionsSection: FC<Props> = ({ form, notify }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { verifiedVies, selectedPriceId, variants, triggerSubmitForm } =
    useAppSelector(
      ({ preorders }) => ({
        verifiedVies: preorders.verifiedVies,
        selectedPriceId: preorders.selectedPriceId,
        variants: preorders.variants,
        triggerSubmitForm: preorders.triggerSubmitForm,
      }),
      shallowEqual,
    );
  const { preorderData, isPreorderFetching } = usePreorderDetailsServices();
  const [updatePreorder, { isLoading: isUpdateLoading }] =
    useUpdatePreorderMutation();
  const isLoading = isPreorderFetching || isUpdateLoading;

  const handleSubmit = useCallback(
    async (navigateToSend?: boolean) => {
      if (!preorderData || isLoading) return;

      dispatch(setGlobalIsLoading(true));

      try {
        const { clientId } = preorderData;
        const { vatRate, ...values } = await form.validateFields();
        const preorderVariants = variants.map((item, index) => {
          const variantId = item.isNew ? null : item.id;

          return {
            ...item,
            id: variantId,
            variantIdx: index + 1,
            preorderVariantFiles: item.preorderVariantFiles
              .filter(
                (variantFile) => !!variantFile.preorderVariantLangs.length,
              )
              .map(({ preorderVariantLangs, ...variantFile }) => {
                const variantFileId = variantFile.isNew ? null : variantFile.id;

                return {
                  ...variantFile,
                  id: variantFileId,
                  preorderVariantLangs: preorderVariantLangs.map(
                    ({ characters, discount, ...variantLang }) => {
                      const variantLangId =
                        typeof variantLang.id === 'string'
                          ? null
                          : variantLang.id;

                      return {
                        ...variantLang,
                        id: variantLangId,
                        characters: characters ?? 0,
                        discount: discount ?? 0,
                      };
                    },
                  ),
                };
              }),
            preorderVariantServices: item.preorderVariantServices.map(
              ({ priceNetto, ...service }) => {
                const serviceId =
                  typeof service.id === 'string' ? null : service.id;

                return {
                  ...service,
                  priceNetto: priceNetto ?? 0,
                  id: serviceId,
                };
              },
            ),
          };
        }) as UpdatePreorderRequest['preorderVariants'];

        await updatePreorder({
          id: preorderData.id,
          preorder: {
            ...values,
            priceId: selectedPriceId,
            verifiedVies,
            clientId,
            vatRate: vatRate || 0,
          },
          preorderVariants,
        }).unwrap();

        if (navigateToSend) {
          navigate(
            PreorderRoutes.PREORDER_SEND.replace(
              ':id',
              preorderData.id.toString(),
            ),
          );
        } else {
          navigate(
            PreorderRoutes.PREORDER_DETAILS.replace(
              ':id',
              preorderData.id.toString(),
            ),
          );
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error?.errorFields) {
          form.scrollToField(error?.errorFields[0].name);
          return;
        }

        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas edycji wyceny." />
          ),
        });
      } finally {
        dispatch(setGlobalIsLoading(false));
        dispatch(setTriggerFormSubmit(false));
      }
    },
    [
      dispatch,
      form,
      isLoading,
      navigate,
      notify,
      preorderData,
      selectedPriceId,
      variants,
      updatePreorder,
      verifiedVies,
    ],
  );

  useEffect(() => {
    if (triggerSubmitForm) {
      handleSubmit();
    }
  }, [triggerSubmitForm, handleSubmit]);

  return (
    <Space
      data-testid="actionsSection"
      className="width-100"
      style={{ justifyContent: 'end' }}
    >
      <Button
        onClick={() => handleSubmit(true)}
        type="primary"
        loading={isLoading}
      >
        <FormattedMessage defaultMessage="Wyślij" />
      </Button>
      <Button
        onClick={() => handleSubmit(false)}
        type="primary"
        loading={isLoading}
      >
        <FormattedMessage defaultMessage="Zapisz" />
      </Button>
    </Space>
  );
};

export default ActionsSection;
