import type { DefaultOptionType } from 'antd/es/select';
import type { PreorderVariantTableValues } from 'components/ui/organisms/Tables/PreorderVariantTable';
import { Select } from 'antd';
import { memo } from 'react';
import FormItem from 'components/ui/atoms/FormItem';
import { useAppSelector } from 'ducks/store';
import styles from './styles.module.scss';

export enum SelectorWideType {
  TWO_DIGIT,
  FIVE_DIGIT,
}

type Props = {
  dataTestId: string;
  value: number;
  options: DefaultOptionType[] | undefined;
  onSearch: (value: string) => void;
  onSelect: (value: number) => void;
  type?: SelectorWideType;
};

const SelectorInput = memo(
  ({
    dataTestId,
    value,
    options,
    onSearch,
    onSelect,
    type = SelectorWideType.FIVE_DIGIT,
  }: Props) => {
    const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);

    const handleSearch = (newValue: string) => onSearch(newValue);

    const handleSelect = (newValue: number) => onSelect(newValue);

    return isEditable ? (
      <FormItem<PreorderVariantTableValues>
        component={
          <Select
            data-testid={dataTestId}
            className={
              type === SelectorWideType.FIVE_DIGIT
                ? styles.fiveDigitsInput
                : styles.twoDigitsInput
            }
            onSearch={handleSearch}
            onSelect={handleSelect}
            showSearch
            options={options}
            value={value}
          />
        }
      />
    ) : (
      <span data-testid={dataTestId.replace('Input', '')}>{value}</span>
    );
  },
);

export default SelectorInput;
