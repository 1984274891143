import type { ReactElement } from 'react';
import { notification } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Sanctum, useSanctum } from 'react-sanctum';
import Loader from 'components/ui/atoms/Loader/Loader';
import NotAuth from 'views/NotAuth';
import { sanctumConfig } from './config';

type Props = {
  children: ReactElement;
};

const Auth: React.FC<Props> = ({ children }) => {
  const { authenticated, checkAuthentication, signOut } = useSanctum();
  const [notify, contextHolder] = notification.useNotification();

  const logOut = () => {
    notify.error({
      message: (
        <FormattedMessage defaultMessage="Nie masz odpowiednich uprawnień aby zalogować się do systemu" />
      ),
    });
    signOut();
  };

  const checkAuth = async () => {
    try {
      const isAuthenticated = await checkAuthentication();
      if (!isAuthenticated) logOut();
    } catch (error: unknown) {
      logOut();
    }
  };

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const intl = useIntl();
  return (
    <Loader
      text={intl.formatMessage({
        defaultMessage: 'Ładuję aplikację',
      })}
      visible={authenticated === null}
      isAbsolute
    >
      <>
        {contextHolder}
        {authenticated === true && children}
        {authenticated === false && <NotAuth />}
      </>
    </Loader>
  );
};

const AuthProvider: React.FC<Props> = ({ children }) => {
  return (
    <Sanctum checkOnInit={false} config={sanctumConfig}>
      <Auth>{children}</Auth>
    </Sanctum>
  );
};

export { Auth };
export default AuthProvider;
