import type { PreorderVariantSlice } from 'ducks/view/preorders';
import type { FC } from 'react';
import { Tag } from 'antd';
import { useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import theme from 'components/layout/Main/styles/values.scss';
import Button from 'components/ui/atoms/Button';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { useAppSelector } from 'ducks/store';
import { calcVariantTotalRealizationTime } from 'utilities/calcPreorderRealizationTime';
import { calcPreorderVariantPriceTotal } from 'utilities/calcPreorderTotals';
import { calcBrutto } from 'utilities/calcPrice';
import { generateWorkingDaysLabel } from 'utilities/workingDaysLabel';
import { Routes } from 'views/Orders/Routing';
import { usePreorderDetailsServices } from 'views/Preorders/views/PreorderDetails';
import VariantPanelHeaderActions from './components/VariantPanelHeaderActions';
import styles from './styles.module.scss';

type Props = {
  variant: PreorderVariantSlice;
  index: number;
  openAllFilesModal: () => void;
};

const VariantPanelHeader: FC<Props> = ({
  variant,
  index,
  openAllFilesModal,
}) => {
  const { id, onlinePaymentUrl } = variant;
  const { isEditable, vatRate } = useAppSelector(({ preorders }) => ({
    isEditable: preorders.isEditable,
    vatRate: preorders.vatRate,
  }));
  const showOpenAllFilesButton =
    isEditable && !!variant.preorderVariantFiles.length;
  const { preorderData } = usePreorderDetailsServices();

  const [netto, brutto] = useMemo(
    () => [
      calcPreorderVariantPriceTotal(variant),
      calcBrutto(calcPreorderVariantPriceTotal(variant), vatRate),
    ],
    [variant, vatRate],
  );

  const timeRealizationTotal = useMemo(
    () => variant.timeRealization ?? calcVariantTotalRealizationTime(variant),
    [variant],
  );

  return (
    <div className={styles.variantHeader}>
      <div className={styles.titleHeader}>
        <FormattedMessage defaultMessage="Wariant {index}" values={{ index }} />
        {variant.inRealization ? (
          <Link
            to={Routes.ORDER_DETAILS.replace(
              ':id',
              `${preorderData?.order?.id ?? ''}`,
            )}
          >
            <Tag
              className={styles.status}
              color={theme.colorBlue}
              data-testid="status"
            >
              <FormattedMessage defaultMessage="Realizowany" />
            </Tag>
          </Link>
        ) : (
          showOpenAllFilesButton && (
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openAllFilesModal();
              }}
            >
              <FormattedMessage defaultMessage="Wyceń wszystkie pliki" />
            </Button>
          )
        )}
      </div>
      <div className={styles.variantHeaderRightCol}>
        <span>
          <FormattedMessage
            defaultMessage="{netto} netto ({brutto} brutto)"
            values={{
              netto: <CurrencyFormatter value={netto} />,
              brutto: <CurrencyFormatter value={brutto} />,
            }}
          />
        </span>
        {!!timeRealizationTotal && (
          <span>
            {generateWorkingDaysLabel({
              timeRealization: timeRealizationTotal,
            })}
          </span>
        )}
        {onlinePaymentUrl && (
          <CopyToClipboard text={onlinePaymentUrl}>
            <Button
              className={styles.linkPaymentButton}
              onClick={(e) => e.stopPropagation()}
              type="default"
            >
              <FormattedMessage defaultMessage="Link do płatności" />
            </Button>
          </CopyToClipboard>
        )}
        <VariantPanelHeaderActions preorderVariantId={id} />
      </div>
    </div>
  );
};

export default VariantPanelHeader;
