import { useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import { verifyEmployee } from 'utilities/authorization';
import FinanceDocumentList from './view/FinanceDocumentList';
import FinanceImport from './view/FinanceImport';

export enum Routes {
  FINANCES = '/finances',
  FINANCES_DOCUMENT_LIST = '/finances/document-list',
  FINANCES_IMPORT = '/finances/import',
}

const FinancesRouting = () => {
  const { user } = useSanctum();
  const navigate = useNavigate();
  const isEmployee = verifyEmployee(user);

  useEffect(() => {
    if (isEmployee) {
      navigate('/');
    }
  }, [isEmployee, navigate]);

  return (
    <AnimatedRoutes pathLevelWatch={2}>
      <Route
        path={`${Routes.FINANCES_DOCUMENT_LIST.replace(Routes.FINANCES, '')}`}
        element={<FinanceDocumentList />}
      />
      <Route
        path={`${Routes.FINANCES_IMPORT.replace(Routes.FINANCES, '')}`}
        element={<FinanceImport />}
      />
    </AnimatedRoutes>
  );
};

export default FinancesRouting;
