import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import InvoicesHeader from 'views/Invoices/components/InvoicesHeader';
import { useInvoiceAddServices } from '../../useInvoiceAddServices';
import InvoiceOrderSelectionModal from '../InvoiceOrderSelectionModal';

const InvoiceAddHeader = () => {
  const { invoiceData, isInvoiceDataFetching } = useInvoiceAddServices();
  const [isInvoiceOrderModalOpen, setIsInvoiceOrderModalOpen] = useState(false);
  const orders = invoiceData?.orders ?? [];

  return (
    <>
      <InvoicesHeader
        startChildren={
          <strong>{orders.map(({ number }) => number).join(', ')}</strong>
        }
        isLoading={isInvoiceDataFetching}
      >
        <Button onClick={() => setIsInvoiceOrderModalOpen(true)}>
          <FormattedMessage defaultMessage="Edytuj" />
        </Button>
      </InvoicesHeader>
      <InvoiceOrderSelectionModal
        isOpen={isInvoiceOrderModalOpen}
        onClose={() => setIsInvoiceOrderModalOpen(false)}
      />
    </>
  );
};

export default InvoiceAddHeader;
