import type { EditNoteModalValues } from './config';
import type { PaymentIndividual } from 'ducks/payments/types';
import type { FC } from 'react';
import { Form, Input, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { useUpdatePaymentNoteMutation } from 'ducks/payments/service';
import { PaymentObject } from 'ducks/payments/types';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';

const { useForm } = Form;

type Props = {
  isOpen: boolean;
  onClose: () => void;
} & Pick<PaymentIndividual, 'id' | 'note'>;

const EditNoteModal: FC<Props> = ({ isOpen, onClose, id, note }) => {
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm<EditNoteModalValues>();
  const [updatePaymentNote, { isLoading }] = useUpdatePaymentNoteMutation();
  const initialValues: EditNoteModalValues = { note };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleUpdateNote = () => {
    if (isLoading) return;

    mutationFunctionWithForm<void, EditNoteModalValues>({
      form,
      mutationFn: async (values) => {
        await updatePaymentNote({
          id,
          paymentObject: PaymentObject.ORDERS,
          ...values,
        }).unwrap();
      },
      onSuccess: handleClose,
      onError: () =>
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania uwagi." />
          ),
        }),
    });
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Edycja uwagi" />}
      open={isOpen}
      onOk={handleUpdateNote}
      onCancel={handleClose}
      isLoading={isLoading}
      disableFormStyles
      disableModalStyles
    >
      {contextHolder}
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        autoComplete="off"
      >
        <FormItem<EditNoteModalValues>
          name="note"
          label={<FormattedMessage defaultMessage="Uwaga" />}
          component={<Input.TextArea data-testid="noteTextarea" rows={3} />}
          rules={[
            {
              required: true,
              message: <FormattedMessage defaultMessage="Uzupełnij uwagi" />,
            },
          ]}
        />
      </Form>
    </Modal>
  );
};

export default EditNoteModal;
