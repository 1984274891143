import type { CollapseProps } from 'antd';
import type { FC, ReactNode } from 'react';
import { Collapse } from 'antd';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = CollapseProps & {
  children: ReactNode;
};

const CustomCollapse: FC<Props> = ({ children, className, ...props }) => {
  return (
    <Collapse
      className={cn(styles.collapse, className)}
      bordered={false}
      {...props}
    >
      {children}
    </Collapse>
  );
};

export default CustomCollapse;
