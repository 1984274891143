import { Col } from 'antd';
import ReferenceFileCard from './components/ReferenceFileCard';

const OthersSection = () => {
  return (
    <Col xs={24} md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
      <ReferenceFileCard />
    </Col>
  );
};

export default OthersSection;
