import type { GetOrderTranslationFilesResponse } from 'ducks/orders/types';
import { OrderStatus } from 'constants/orderStatuses';

type CheckOrderClientSendRouteAvailabilityInput = {
  status: OrderStatus;
  orderTranslationFilesData: GetOrderTranslationFilesResponse | undefined;
};

const hasTranslatorSelectedInOrderTranslation = (
  data: CheckOrderClientSendRouteAvailabilityInput['orderTranslationFilesData'],
) => {
  if (!data?.orderFiles?.length) return false;

  return data.orderFiles.some(({ orderFileLangs }) =>
    orderFileLangs.some(({ orderTranslationFiles }) =>
      orderTranslationFiles.some(({ translatorId }) => !!translatorId),
    ),
  );
};

export const checkOrderClientSendRouteAvailable = ({
  status,
  orderTranslationFilesData,
}: CheckOrderClientSendRouteAvailabilityInput) => {
  const isReadyStatus = status === OrderStatus.READY;
  const isDoneStatus = status === OrderStatus.DONE;
  const hasTranslatorSelected = hasTranslatorSelectedInOrderTranslation(
    orderTranslationFilesData,
  );

  return isReadyStatus || isDoneStatus || hasTranslatorSelected;
};
