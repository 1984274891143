/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import type {
  ComponentPropsWithoutRef,
  Dispatch,
  FC,
  SetStateAction,
} from 'react';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import { formats, modules } from './config';
import 'react-quill/dist/quill.snow.css';
import styles from './styles.module.scss';

type Props = ComponentPropsWithoutRef<typeof ReactQuill> & {
  value?: string;
  onChange?: (value: string) => void;
  simpleEditor?: boolean;
  setCharactersCount?: Dispatch<SetStateAction<number>>;
};

const TemplateEditor: FC<Props> = ({
  value = '',
  onChange,
  setCharactersCount,
  simpleEditor = false,
  ...props
}) => {
  const handleChange = (newValue: string) => {
    const regex = /^<p><br><\/p>$/;

    if (regex.test(newValue)) {
      onChange?.('');

      return;
    }

    onChange?.(newValue);
  };

  useEffect(() => {
    if (!setCharactersCount) return;

    setCharactersCount(
      value
        ?.trim()
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace(/\s/g, '').length ?? 0,
    );
  }, [value]);

  /**
   * Its for setting initial value or cleaning up empty HTML elements
   */
  useEffect(() => {
    handleChange(value);
  }, []);

  return (
    <div data-testid="templateEditor">
      <ReactQuill
        className={styles.editor}
        value={value}
        theme="snow"
        onChange={handleChange}
        formats={simpleEditor ? [] : formats}
        modules={simpleEditor ? { toolbar: false } : modules}
        {...props}
      />
    </div>
  );
};

export default TemplateEditor;
