import type { AssignAllFilesModalValues } from '../config';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import { useGetTranslatorsCatsQuery } from 'ducks/translators/service';

const CatSelect = () => {
  const { data = [], isLoading } = useGetTranslatorsCatsQuery();
  const nullOption = {
    value: null,
    label: <FormattedMessage defaultMessage="Brak" />,
  };

  const catOptions = useMemo((): NonNullable<SelectProps['options']> => {
    const mainCats = data.filter(({ isMain }) => isMain);

    return mainCats.map(({ id, name }) => ({ value: id, label: name }));
  }, [data]);

  return (
    <FormItem<AssignAllFilesModalValues>
      name="catId"
      component={
        <Select
          placeholder={<FormattedMessage defaultMessage="Wybierz CAT" />}
          options={[...catOptions, nullOption]}
          loading={isLoading}
        />
      }
    />
  );
};

export default CatSelect;
