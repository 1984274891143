import type { InvoiceDataItemRow } from './config';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import CardCommon from 'components/ui/molecules/CardCommon';
import Table from 'components/ui/molecules/Table';
import { useInvoiceAddServices } from '../../useInvoiceAddServices';
import { invoiceDataItemColumns } from './config';
import InvoiceItemsSumPrices from './InvoiceItemsSumPrices';
import styles from './styles.module.scss';

const InvoiceItemsSection = () => {
  const { invoiceData, isInvoiceDataFetching } = useInvoiceAddServices();

  const modifiedData = useMemo((): InvoiceDataItemRow[] => {
    if (!invoiceData || !invoiceData?.items?.length) return [];

    return invoiceData.items.map((item) => ({ ...item, key: item.id }));
  }, [invoiceData]);

  return (
    <CardCommon
      className={styles.wrapper}
      title={<FormattedMessage defaultMessage="Pozycje na fakturze" />}
      xs={24}
    >
      <Table
        columns={invoiceDataItemColumns}
        data={modifiedData}
        isLoading={isInvoiceDataFetching}
      />
      <InvoiceItemsSumPrices />
    </CardCommon>
  );
};

export default InvoiceItemsSection;
