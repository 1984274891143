import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Empty from 'components/ui/atoms/Empty';
import { useAppDispatch } from 'ducks/store';
import { resetOrderTranslationFilesState } from 'ducks/view/orderTranslationFiles';
import OrderCurrentStatus from 'views/Orders/components/OrderCurrentStatus';
import OrderHeader from 'views/Orders/components/OrderHeader';
import ActionsSection from './components/ActionsSection';
import TranslationFileList from './components/TranslationFileList';
import TranslationFilesTable from './components/TranslationFilesTable';
import { useOrderTranslationFilesServices } from './useOrderTranslationFilesServices';

const OrderTranslationFiles = () => {
  const dispatch = useAppDispatch();
  const { isOrderTranslationFilesEmpty, refetch } =
    useOrderTranslationFilesServices();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    return () => {
      dispatch(resetOrderTranslationFilesState());
    };
  }, [dispatch]);

  return (
    <OrderCurrentStatus>
      <OrderHeader />
      {isOrderTranslationFilesEmpty ? (
        <Empty
          description={<FormattedMessage defaultMessage="Brak plików" />}
        />
      ) : (
        <>
          <TranslationFilesTable />
          <TranslationFileList />
          <ActionsSection />
        </>
      )}
    </OrderCurrentStatus>
  );
};

export default OrderTranslationFiles;
