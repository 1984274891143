import type { ColumnsType } from 'antd/es/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { PreorderVariantFile } from 'ducks/preorders/types';
import { FormattedMessage } from 'react-intl';
import LanguageFlag from 'components/ui/atoms/LanguageFlag';
import LanguageName from 'components/ui/atoms/LanguageName';
import DownloadFileButton from 'components/ui/molecules/DownloadFileButton';
import styles from './styles.module.scss';

export type LangFileRow = TableRow &
  PreorderVariantFile & { variantsIdxes: number[] };

export const columns: ColumnsType<LangFileRow> = [
  {
    title: <FormattedMessage defaultMessage="Pliki" />,
    key: 'file',
    dataIndex: 'file',
    render: (_, { file }) => <DownloadFileButton file={file} />,
  },
  {
    title: <FormattedMessage defaultMessage="Język" />,
    key: 'file',
    dataIndex: 'file',
    render: (_, { preorderVariantLangs }) => (
      <div className={styles.multilineCell}>
        {preorderVariantLangs.map(({ id, langFrom, langTo }) => (
          <div key={id} className={styles.languagesWrapper}>
            <LanguageFlag languageCode={langFrom} />
            <LanguageName
              data-testid={`langFrom-${id}`}
              countryCode={langFrom}
              showCode
              ellipsis
            />
            <span>-</span>
            <LanguageName
              data-testid={`langTo-${id}`}
              countryCode={langTo}
              showCode
              ellipsis
            />
            <LanguageFlag languageCode={langTo} />
          </div>
        ))}
      </div>
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Wariant" />,
    key: 'variantsIdxes',
    dataIndex: 'variantsIdx',
    render: (_, { variantsIdxes }) => (
      <div>
        {variantsIdxes.map((idx) => (
          <span key={idx}>
            {idx !== variantsIdxes.length ? `W${idx}, ` : `W${idx}`}
          </span>
        ))}
      </div>
    ),
  },
];
