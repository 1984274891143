import type { UploadFile } from 'antd';
import { Space, notification } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import TruncateText from 'components/ui/atoms/TruncateText';
import CardCommon from 'components/ui/molecules/CardCommon';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import Upload from 'components/ui/molecules/Upload';
import {
  getReferenceFile,
  useDeletePreorderReferenceFileMutation,
  useSendReferenceFileMutation,
} from 'ducks/preorders/service';
import { useAppSelector } from 'ducks/store';
import { downloadFile } from 'utilities/downloadFile';
import { usePreorderDetailsServices } from 'views/Preorders/views/PreorderDetails';
import styles from './styles.module.scss';

const extractFileName = (input: string) => {
  const regex = /preorders\/\d+\/(.+)_[a-zA-Z0-9]+(\..+)/;
  const match = input.match(regex);

  if (match && match[1] && match[2]) {
    return `${match[1]}${match[2]}`;
  }

  return input;
};

const ReferenceFileCard = () => {
  const { id: preorderId = '' } = useParams();
  const [notify, contextHolder] = notification.useNotification();
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
  const [isDownloadFileLoading, setIsDownloadFileLoading] = useState(false);
  const { preorderData, isPreorderFetching } = usePreorderDetailsServices();
  const [sendReferenceFile, { isLoading, data: preorderResponseData }] =
    useSendReferenceFileMutation();

  const [deleteReferenceFile, { isLoading: isDeleteReferenceLoading }] =
    useDeletePreorderReferenceFileMutation();

  const handleAddReferenceFile = async (files: UploadFile[]) => {
    const file = files[0].originFileObj;

    if (files.length >= 2 || !file) return;

    try {
      await sendReferenceFile({
        preorderId,
        file: file as File,
      }).unwrap();

      notify.success({
        message: <FormattedMessage defaultMessage="Poprawnie wgrano plik" />,
      });
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd poczas wgrywania pliku." />
        ),
      });
    }
  };

  const handleDownload = async () => {
    try {
      setIsDownloadFileLoading(true);

      const response = await getReferenceFile({ preorderId });

      const referenceFilePath = preorderData?.referenceFile ?? '';
      const pathArray = referenceFilePath.split('/');
      const fileName = pathArray[pathArray.length - 1] ?? '';

      const url = URL.createObjectURL(response as File);

      downloadFile(url, fileName);
    } catch (err) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Nie znaleziono pliku lub wystąpił błąd." />
        ),
      });
    } finally {
      setIsDownloadFileLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsDownloadFileLoading(true);

      await deleteReferenceFile({ preorderId });
    } catch (err) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Nie udało się usunąć pliku." />
        ),
      });
    } finally {
      setIsDownloadFileLoading(false);
    }
  };

  return (
    <CardCommon
      data-testid="referenceFileCard"
      title={
        isEditable ? (
          <TitleWrapper>
            <FormattedMessage defaultMessage="Referencje" />
            <Upload
              onChange={({ fileList }) => handleAddReferenceFile(fileList)}
              multiple={false}
              disabled={isLoading}
              fullWidth={false}
            >
              <Button loading={isPreorderFetching || isLoading}>
                <FormattedMessage defaultMessage="Wgraj" />
              </Button>
            </Upload>
          </TitleWrapper>
        ) : (
          <FormattedMessage defaultMessage="Referencje" />
        )
      }
      isLoading={isPreorderFetching}
      customSkeleton
    >
      {contextHolder}
      <Space className="width-100" direction="vertical">
        {!!preorderData?.referenceFile && (
          <div className={styles.referenceFileItem}>
            <TruncateText maxLength={50} nowrap>
              {extractFileName(preorderData?.referenceFile) ??
                extractFileName(preorderResponseData?.referenceFile ?? '')}
            </TruncateText>
            <Space>
              <Button
                onClick={handleDelete}
                loading={
                  isDownloadFileLoading ||
                  isPreorderFetching ||
                  isDeleteReferenceLoading
                }
                danger
              >
                <FormattedMessage defaultMessage="Usuń" />
              </Button>
              <Button
                onClick={handleDownload}
                loading={
                  isDownloadFileLoading ||
                  isPreorderFetching ||
                  isDeleteReferenceLoading
                }
              >
                <FormattedMessage defaultMessage="Pobierz" />
              </Button>
            </Space>
          </div>
        )}
      </Space>
    </CardCommon>
  );
};

export default ReferenceFileCard;
