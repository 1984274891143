import type {
  CalcOrderPosteditRequest,
  CalcOrderPosteditResponse,
  EditOrderRequest,
  GetOrderRequest,
  GetOrderResponse,
  GetOrderTranslationFilesRequest,
  GetOrderTranslationFilesResponse,
  GetOrderVariantRequest,
  GetOrderVariantResponse,
  GetOrdersRequest,
  GetOrdersResponse,
  OrderMtRequest,
  OrderSendEmailsRequest,
  SendReferenceFileRequest,
  SendReferenceFileResponse,
  UnblockOrderRequest,
  UpdateOrderStatusRequest,
  UpdateOrderTranslationFilesRequest,
  UploadOrderTranslationFilesRequest,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { snakeCase } from 'lodash';
import baseQuery, { doRequest } from 'ducks/api';
import { formatFilterParameter, formatSortParameter } from 'utilities/api';
import { convertKeysToSnakeCase } from 'utilities/object';
import { OrdersTag } from './types';

export enum Endpoint {
  GetOrders = '/orders',
  GetOrder = '/orders/:id',
  EditOrder = '/orders/:id',
  GetOrderVariant = '/orders/:id/variant',
  UnblockOrder = '/orders/unblock',
  OrderMt = '/orders/:id/mt',
  CalcOrderPostedit = '/orders/calc-postedit',
  OrderSendEmails = '/orders/:id/emails',
  UploadOrderTranslationFiles = '/orders/:id/translation-files',
  GetOrderTranslationFiles = '/orders/:id/translation-files',
  UpdateOrderTranslationFiles = '/orders/:id/translation-files',
  GetReferenceFile = '/orders/:id/referenceFile',
  SendReferenceFile = '/orders/:id/referenceFile',
  UpdateOrderStatus = '/orders/:id/status',
}

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: baseQuery(),
  tagTypes: [OrdersTag.Orders, OrdersTag.OrderTranslationFiles],
  endpoints: (build) => ({
    getOrders: build.query<GetOrdersResponse, GetOrdersRequest>({
      query: ({ page, searchTerm, sort, filter }) => ({
        url: Endpoint.GetOrders,
        method: 'GET',
        params: {
          page,
          s: searchTerm,
          sort: formatSortParameter(sort),
          filter: formatFilterParameter(filter),
        },
      }),
      providesTags: [OrdersTag.Orders, OrdersTag.OrderTranslationFiles],
    }),
    getOrder: build.query<GetOrderResponse, GetOrderRequest>({
      query: ({ id, type }) => ({
        url: Endpoint.GetOrder.replace(':id', id.toString()),
        method: 'GET',
        params: {
          type,
        },
      }),
      providesTags: [OrdersTag.Orders, OrdersTag.OrderTranslationFiles],
    }),
    editOrder: build.mutation<void, EditOrderRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.EditOrder.replace(':id', id.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
    }),
    getOrderVariant: build.query<
      GetOrderVariantResponse,
      GetOrderVariantRequest
    >({
      query: ({ id }) => ({
        url: Endpoint.GetOrderVariant.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: [OrdersTag.Orders],
    }),
    unblockOrder: build.mutation<void, UnblockOrderRequest>({
      query: (data) => ({
        url: Endpoint.UnblockOrder,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) => (error ? [] : [OrdersTag.Orders]),
    }),
    orderMt: build.mutation<void, OrderMtRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.OrderMt.replace(':id', id.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) => (error ? [] : [OrdersTag.Orders]),
    }),
    calcOrderPostedit: build.mutation<
      CalcOrderPosteditResponse,
      CalcOrderPosteditRequest
    >({
      query: ({ file }) => {
        const formData = new FormData();

        formData.append('file', file, file.name);

        return {
          url: Endpoint.CalcOrderPostedit,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        };
      },
      invalidatesTags: (_, error) => (error ? [] : [OrdersTag.Orders]),
    }),
    orderSendEmails: build.mutation<void, OrderSendEmailsRequest>({
      query: ({ id, ...data }) => {
        const formData = new FormData();
        const { files, attachments, ...restData } = data;

        Object.entries(restData).forEach(([key, value]) => {
          const modifiedValue = value === null ? '' : String(value);

          formData.append(snakeCase(key), modifiedValue);
        });

        if (attachments.length) {
          attachments.forEach((atch) => {
            formData.append(`attachments[]`, String(atch));
          });
        }

        if (files.length) {
          files.forEach((file, index) => {
            formData.append(
              `files[${index}][file]`,
              new Blob([file]),
              file.name,
            );
          });
        }

        return {
          url: Endpoint.OrderSendEmails.replace(':id', id.toString()),
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
    }),
    uploadOrderTranslationFiles: build.mutation<
      void,
      UploadOrderTranslationFilesRequest
    >({
      query: ({ id, files }) => {
        const formData = new FormData();

        files.forEach((file, index) => {
          formData.append(`files[${index}][file]`, new Blob([file]), file.name);
        });

        return {
          url: Endpoint.UploadOrderTranslationFiles.replace(
            ':id',
            id.toString(),
          ),
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      invalidatesTags: (_, error) =>
        error ? [] : [OrdersTag.OrderTranslationFiles],
    }),
    getOrderTranslationFiles: build.query<
      GetOrderTranslationFilesResponse,
      GetOrderTranslationFilesRequest
    >({
      query: ({ id }) => ({
        url: Endpoint.GetOrderTranslationFiles.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: [OrdersTag.OrderTranslationFiles],
    }),
    updateOrderTranslationFiles: build.mutation<
      void,
      UpdateOrderTranslationFilesRequest
    >({
      query: ({ id, body }) => ({
        url: Endpoint.UpdateOrderTranslationFiles.replace(':id', id.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(body),
      }),
    }),
    sendReferenceFile: build.mutation<
      SendReferenceFileResponse,
      SendReferenceFileRequest
    >({
      query: ({ orderId, file }) => {
        const formData = new FormData();

        formData.append('file', file, file.name);

        return {
          url: Endpoint.SendReferenceFile.replace(':id', orderId),
          data: formData,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      invalidatesTags: [OrdersTag.Orders],
    }),
    updateOrderStatus: build.mutation<void, UpdateOrderStatusRequest>({
      query: ({ id, ...rest }) => ({
        url: Endpoint.UpdateOrderStatus.replace(':id', id.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(rest),
      }),
      invalidatesTags: (_, error) => (error ? [] : [OrdersTag.Orders]),
    }),
  }),
});

export const getReferenceFile = async ({
  orderId,
}: Pick<SendReferenceFileRequest, 'orderId'>): Promise<unknown | null> => {
  const response = await doRequest({
    url: Endpoint.GetReferenceFile.replace(':id', orderId),
    responseType: 'blob',
  });

  if (!response) return null;

  return response.data as unknown;
};

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useEditOrderMutation,
  useGetOrderVariantQuery,
  useUnblockOrderMutation,
  useCalcOrderPosteditMutation,
  useOrderSendEmailsMutation,
  useUploadOrderTranslationFilesMutation,
  useGetOrderTranslationFilesQuery,
  useUpdateOrderTranslationFilesMutation,
  useSendReferenceFileMutation,
  useUpdateOrderStatusMutation,
} = ordersApi;
