import type { ReactNode } from 'react';
import { Skeleton } from 'antd';
import styles from './styles.module.scss';

type Props = {
  isLoading: boolean;
  children: ReactNode;
};

const HorizontalCenteredWrapper: React.FC<Props> = ({
  children,
  isLoading,
}) => {
  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />
      ) : (
        children
      )}
    </div>
  );
};

export default HorizontalCenteredWrapper;
