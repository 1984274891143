import type { ListItemValues } from './config';
import type { TranslatorQualificationFile } from 'ducks/translators/types';
import { Button, Card, Form, Space, notification } from 'antd';
import { createRef, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Loader from 'components/ui/atoms/Loader';
import FormList from 'components/ui/molecules/FormList';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import { getFile } from 'ducks/global/service';
import { EditIcon, PlusIcon } from 'icons';
import { downloadFile } from 'utilities/downloadFile';
import { scrollToRefs } from 'utilities/scrollToElement';
import { formLists } from './config';
import styles from './styles.module.scss';
import useManageEducationList from './useManageQualificationList';

const { useForm } = Form;

const QualificationList = () => {
  const [notify, contextHolder] = notification.useNotification();
  const location = useLocation();
  const [form] = useForm<ListItemValues>();
  const {
    listItems,
    isChanged,
    isInitialLoading,
    isLoading,
    handleAdd,
    handleEdit,
    handleDelete,
    handleSave,
    handleAddFileToItem,
    umountedAutoScroll,
  } = useManageEducationList(notify);

  const triggerGetFile = async (file: TranslatorQualificationFile) => {
    const { id, object, filename } = file;
    const response = await getFile({ id, object });

    if (response) {
      const url = URL.createObjectURL(response as File);

      downloadFile(url, filename);
    }
  };

  const formListArray = formLists({
    handleDelete,
    handleAddFileToItem,
    triggerGetFile,
  });

  const myRefs = useRef([]);
  myRefs.current = formListArray.map(
    (item, index) => myRefs.current[index] ?? createRef(),
  );

  useEffect(() => {
    if (umountedAutoScroll) return;

    scrollToRefs(myRefs, isLoading, location.state?.indexOfClicked ?? 0);
  }, [
    isLoading,
    myRefs,
    location.state?.indexOfClicked,
    listItems.length,
    umountedAutoScroll,
  ]);

  return (
    <Form form={form} onFinish={handleSave} onValuesChange={handleEdit}>
      {contextHolder}
      <Space direction="vertical" size="large" className="width-100">
        <Card
          bordered={false}
          className={styles.card}
          title={
            <TitleWrapper>
              <FormattedMessage defaultMessage="Kwalifikacje" />
              <Button
                icon={<EditIcon />}
                type="primary"
                loading={isLoading}
                htmlType="submit"
                disabled={!isChanged}
              >
                <FormattedMessage defaultMessage="Zapisz zmiany" />
              </Button>
            </TitleWrapper>
          }
        />

        <Loader visible={isLoading} style={{ marginBottom: '30px' }}>
          <Space direction="vertical" size="large" className="width-100">
            {formListArray.map(({ key, sectionId, title, items }, index) => (
              <Card bordered={false}>
                <span ref={myRefs.current[index]} />
                <FormList<ListItemValues>
                  key={key}
                  title={
                    <Space className={styles.formListTitleWrapper}>
                      {title}
                      <Button
                        type="link"
                        icon={<PlusIcon />}
                        onClick={() => handleAdd(sectionId)}
                      >
                        <FormattedMessage defaultMessage="Dodaj" />
                      </Button>
                    </Space>
                  }
                  form={form}
                  data={
                    listItems.find(({ id }) => id === sectionId)?.items ?? []
                  }
                  items={items}
                  isLoading={isInitialLoading}
                />
              </Card>
            ))}
          </Space>
        </Loader>
      </Space>
    </Form>
  );
};

export default QualificationList;
