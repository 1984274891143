import type { UpdateClientPreorderRequest } from 'ducks/preorders/types';
import type { FC } from 'react';
import { Form, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Modal from 'components/ui/molecules/Modal';
import ClientSelector from 'components/ui/organisms/ClientSelector';
import { useUpdateClientPreorderMutation } from 'ducks/preorders/service';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';

const { useForm } = Form;

type ClientForm = UpdateClientPreorderRequest;

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ChangeClientModal: FC<Props> = ({ isOpen, onClose }) => {
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm<ClientForm>();
  const { id: preorderId = '' } = useParams();
  const [updateData, { isLoading }] = useUpdateClientPreorderMutation();

  const handleClose = () => {
    notify.success({
      message: <FormattedMessage defaultMessage="Klient został edytowany." />,
    });

    onClose();
  };

  const handleSubmit = () => {
    if (isLoading) return;

    mutationFunctionWithForm<void, ClientForm>({
      form,
      mutationFn: async (values) => {
        await updateData({
          ...values,
          id: Number(preorderId),
        }).unwrap();
      },
      onSuccess: handleClose,
      onError: () =>
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania klienta." />
          ),
        }),
    });
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        onCancel={onClose}
        title={<FormattedMessage defaultMessage="Zmień klienta" />}
        onOk={handleSubmit}
        disableModalStyles
        disableFormStyles
        destroyOnClose
        isLoading={isLoading}
      >
        <Form layout="vertical" form={form}>
          <ClientSelector form={form} fullWidth />
        </Form>
      </Modal>
    </>
  );
};

export default ChangeClientModal;
