import type { Dispatch, SetStateAction } from 'react';
import { Radio, Select } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetLanguagesQuery } from 'ducks/global/service';
import { SelectedTable } from '../../config';
import StatTile from '../StatTile';
import { stats } from './config';
import styles from './styles.module.scss';

type Props = {
  ordersCount: number | undefined;
  preordersCount: number | undefined;
  complaintsCount: number | undefined;
  ordersInProgressCount: number | undefined;
  setSelectedLang: Dispatch<SetStateAction<string | null>>;
  setSelectedTable: Dispatch<SetStateAction<SelectedTable>>;
  selectedLang: string | null;
  selectedTable: SelectedTable;
};

const TableHeader: React.FC<Props> = ({
  ordersCount,
  preordersCount,
  complaintsCount,
  ordersInProgressCount,
  setSelectedLang,
  setSelectedTable,
  selectedLang,
  selectedTable,
}) => {
  const { data: langData = [], isFetching } = useGetLanguagesQuery();

  const options = useMemo(
    () =>
      langData.map(({ code, name }) => ({
        value: code,
        label: name,
      })),
    [langData],
  );

  const handleRadioChange = (value: SelectedTable) => {
    setSelectedTable(value);
  };

  return (
    <div className={styles.titleWrapper}>
      <div className={styles.statsWrapper}>
        {stats({
          ordersCount,
          preordersCount,
          complaintsCount,
          ordersInProgressCount,
        }).map(({ stat, title, statColor, id }) => (
          <StatTile key={id} stat={stat} title={title} statColor={statColor} />
        ))}
      </div>
      <div className={styles.filtersWrapper}>
        <Radio.Group
          value={selectedTable}
          onChange={(e) => handleRadioChange(e.target.value)}
          size="large"
        >
          <Radio.Button value={SelectedTable.ORDERS}>
            <FormattedMessage defaultMessage="Zamówienia" />
          </Radio.Button>
          <Radio.Button value={SelectedTable.PREORDERS}>
            <FormattedMessage defaultMessage="Wyceny" />
          </Radio.Button>
        </Radio.Group>

        <Select
          options={options}
          style={{ width: 120 }}
          onChange={(value) => setSelectedLang(value)}
          value={selectedLang}
          placeholder={<FormattedMessage defaultMessage="Język" />}
          allowClear
          showSearch
          optionFilterProp="label"
          loading={isFetching}
        />
      </div>
    </div>
  );
};

export default TableHeader;
