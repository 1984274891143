import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { Form, Select, TimePicker } from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles.module.scss';

dayjs.extend(customParseFormat);

const weekdays = (intl: IntlShape) => [
  {
    value: 1,
    label: intl.formatMessage({
      defaultMessage: 'Poniedziałek',
    }),
  },
  {
    value: 2,
    label: intl.formatMessage({
      defaultMessage: 'Wtorek',
    }),
  },
  {
    value: 3,
    label: intl.formatMessage({
      defaultMessage: 'Środa',
    }),
  },
  {
    value: 4,
    label: intl.formatMessage({
      defaultMessage: 'Czwartek',
    }),
  },
  {
    value: 5,
    label: intl.formatMessage({
      defaultMessage: 'Piątek',
    }),
  },
  {
    value: 6,
    label: intl.formatMessage({
      defaultMessage: 'Sobota',
    }),
  },
  {
    value: 7,
    label: intl.formatMessage({
      defaultMessage: 'Niedziela',
    }),
  },
];

const { Item } = Form;

const LoginPermissionsSection = () => {
  const intl = useIntl();

  return (
    <>
      <h6>
        <FormattedMessage defaultMessage="Uprawnienia logowania" />
      </h6>
      <div className={styles.columns}>
        <Item
          name="allowTime"
          label={<FormattedMessage defaultMessage="Zakres godzinowy" />}
        >
          <TimePicker.RangePicker
            format="HH:mm"
            placement="topLeft"
            placeholder={[
              intl.formatMessage({
                defaultMessage: 'Od',
              }),
              intl.formatMessage({
                defaultMessage: 'Do',
              }),
            ]}
          />
        </Item>
        <Item
          name="allowWeekdays"
          label={<FormattedMessage defaultMessage="Wybór dni tygodnia" />}
        >
          <Select
            mode="multiple"
            options={weekdays(intl).map(({ value, label }) => ({
              value,
              label,
            }))}
            placement="topLeft"
            allowClear
          />
        </Item>
      </div>
    </>
  );
};

export default LoginPermissionsSection;
