import type { ListItemRecord } from 'components/ui/molecules/List';
import type { Translator } from 'ducks/translators/types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CopyToClipboardWithText from 'components/ui/atoms/CopyToClipboard';
import CountryName from 'components/ui/atoms/CountryName';
import { DefaultValue } from 'ducks/types';

export const correspondenceCardListItems = (
  data: Translator | undefined,
): ListItemRecord[] => [
  {
    label: <FormattedMessage defaultMessage="Adres:" />,
    description: data?.address,
  },
  {
    label: <FormattedMessage defaultMessage="Miasto:" />,
    description: data?.city,
  },
  {
    label: <FormattedMessage defaultMessage="Kod pocztowy:" />,
    description: data?.postCode,
  },
  {
    label: <FormattedMessage defaultMessage="Kraj:" />,
    description: <CountryName countryCode={data?.country} />,
  },
  {
    label: <FormattedMessage defaultMessage="Województwo:" />,
    description: data?.province,
  },
  {
    label: <FormattedMessage defaultMessage="E-mail:" />,
    description: data?.email1 ? (
      <CopyToClipboardWithText type="secondary" text={data?.email1} />
    ) : (
      data?.email1
    ),
    avatar: (
      <CircleCheckbox
        data-testid="emailCheckbox1"
        value={data?.emailDefault === DefaultValue.FIRST}
      />
    ),
  },
  {
    label: <FormattedMessage defaultMessage="E-mail 2:" />,
    description: data?.email2 ? (
      <CopyToClipboardWithText type="secondary" text={data?.email2} />
    ) : (
      data?.email2
    ),
    avatar: (
      <CircleCheckbox
        data-testid="emailCheckbox2"
        value={data?.emailDefault === DefaultValue.SECOND}
      />
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Tel:" />,
    description: data?.phone1 ? (
      <CopyToClipboardWithText type="secondary" text={data?.phone1} />
    ) : (
      data?.phone1
    ),
    avatar: (
      <CircleCheckbox
        data-testid="phoneCheckbox1"
        value={data?.phoneDefault === DefaultValue.FIRST}
      />
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Tel 2:" />,
    description: data?.phone2 ? (
      <CopyToClipboardWithText type="secondary" text={data?.phone2} />
    ) : (
      data?.phone2
    ),
    avatar: (
      <CircleCheckbox
        data-testid="phoneCheckbox2"
        value={data?.phoneDefault === DefaultValue.SECOND}
      />
    ),
  },
  {
    label: <FormattedMessage defaultMessage="WWW:" />,
    description: data?.websiteUrl && (
      <Link to={`https://${data.websiteUrl}`} target="_blank" rel="noreferrer">
        {data.websiteUrl}
      </Link>
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Facebook:" />,
    description: data?.facebookUrl && (
      <Link to={`https://${data.facebookUrl}`} target="_blank" rel="noreferrer">
        {data.facebookUrl}
      </Link>
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Linkedin:" />,
    description: data?.linkedinUrl && (
      <Link to={`https://${data.linkedinUrl}`} target="_blank" rel="noreferrer">
        {data.linkedinUrl}
      </Link>
    ),
  },
];
