import type { AddLanguagePairModalValues } from '../config';
import type { FormInstance } from 'antd';
import type { FC } from 'react';
import { Form } from 'antd';
import { TbSwitchVertical } from 'react-icons/tb';
import Button from 'components/ui/atoms/Button';
import styles from './styles.module.scss';

const { useWatch } = Form;

type LangFrom = AddLanguagePairModalValues['langFrom'];
type LangTo<TBoolean extends boolean> =
  AddLanguagePairModalValues<TBoolean>['langTo'];

type Props = {
  form: FormInstance<AddLanguagePairModalValues>;
  langToMultiple: boolean;
};

const SwitchLanguagePairButton: FC<Props> = ({ form, langToMultiple }) => {
  const langToValue = useWatch('langTo', form);
  const disabled = Array.isArray(langToValue) && langToValue.length > 1;

  const handleSwitch = () => {
    if (disabled) return;

    const langFrom: LangFrom = form.getFieldValue('langFrom');
    const langTo: LangTo<typeof langToMultiple> = form.getFieldValue('langTo');

    if (langToMultiple) {
      form.setFieldValue('langFrom', langTo[0]);
      form.setFieldValue('langTo', [langFrom]);

      return;
    }

    form.setFieldValue('langFrom', langTo);
    form.setFieldValue('langTo', langFrom);
  };

  return (
    <Button
      data-testid="switchLanguagePairButton"
      className={styles.switchButton}
      type="link"
      onClick={handleSwitch}
      disabled={disabled}
    >
      <TbSwitchVertical fontSize={40} />
    </Button>
  );
};

export default SwitchLanguagePairButton;
