import type { UserLoginRequest } from 'ducks/auth/types';
import { Button, Form, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../styles.module.scss';

const { Item, useForm } = Form;

type Props = {
  isSaving: boolean;
  onFinishCallback: (values: UserLoginRequest) => void;
};

export const LoginForm: React.FC<Props> = ({ onFinishCallback, isSaving }) => {
  const [form] = useForm();
  return (
    <Form
      data-testid="notAuthLoginFrom"
      onFinish={onFinishCallback}
      form={form}
      layout="vertical"
      className="row-col"
      autoComplete="off"
    >
      <Item
        name="email"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage defaultMessage="To pole jest obowiązkowe" />
            ),
          },
          {
            type: 'email',
            message: (
              <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
            ),
          },
        ]}
      >
        <Input placeholder="Email" />
      </Item>

      <Item
        name="password"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage defaultMessage="To pole jest obowiązkowe" />
            ),
          },
        ]}
      >
        <Input.Password placeholder="Password" />
      </Item>

      <Item>
        <Button
          data-testid="loginFormSubmitButton"
          type="primary"
          htmlType="submit"
          className={styles.fullWidth}
          loading={isSaving}
          disabled={isSaving}
        >
          <FormattedMessage defaultMessage="Zaloguj się" />
        </Button>
      </Item>
    </Form>
  );
};

export default LoginForm;
