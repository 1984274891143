import type { PriceTableRow } from './config';
import { Button, notification } from 'antd';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Table from 'components/ui/molecules/Table';
import {
  useDeletePriceMutation,
  useGetPricesQuery,
} from 'ducks/prices/service';
import { PlusIcon } from 'icons';
import AddPriceModal from './components/AddPriceModal';
import { columns } from './config';

const PricesTable = () => {
  const [notify, contextHolder] = notification.useNotification();
  const [isAddPriceModalOpen, setIsAddPriceModalOpen] = useState(false);
  const { data = [], isFetching } = useGetPricesQuery();
  const [deletePrice, { isLoading: isDeleteLoading }] =
    useDeletePriceMutation();

  const handleDelete = async (id: number) => {
    if (isFetching || isDeleteLoading) return;

    try {
      await deletePrice({ id }).unwrap();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas próby usunięcia cennika." />
        ),
      });
    }
  };

  const modifiedUsersData = useMemo((): PriceTableRow[] => {
    return data.map((price) => {
      return {
        ...price,
        key: price.id,
      };
    });
  }, [data]);

  return (
    <div className="layout-content mb-24">
      {contextHolder}
      <AddPriceModal
        isOpen={isAddPriceModalOpen}
        onClose={() => setIsAddPriceModalOpen(false)}
        notify={notify}
      />
      <Table
        columns={columns({ handleDelete, isDeleteLoading })}
        data={modifiedUsersData}
        isLoading={isFetching}
        cardTitleExtra={
          <Button
            icon={<PlusIcon />}
            type="primary"
            onClick={() => setIsAddPriceModalOpen(true)}
          >
            <FormattedMessage defaultMessage="Dodaj cennik" />
          </Button>
        }
        cardTitle={<FormattedMessage defaultMessage="Cenniki firmowe" />}
      />
    </div>
  );
};

export default PricesTable;
