import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';
import type { InvoiceOverdue } from 'ducks/invoices/types';
import { FormattedMessage } from 'react-intl';
import DateLastContactRow from './components/DateLastContactRow';
import StatusRow from './components/StatusRow';
import styles from './styles.module.scss';

type Props = Pick<InvoiceOverdue, 'number' | 'dateLastContact' | 'status'>;

export const skeletonHeaderCols: HeaderCardCol[] = [
  {
    key: 'number',
    firstRow: <span />,
  },
  {
    key: 'status',
    firstRow: <span />,
  },
  {
    key: 'dateLastContact',
    firstRow: <span />,
  },
];

export const headerCols = ({
  number,
  dateLastContact,
  status,
}: Props): HeaderCardCol[] => [
  {
    key: 'number',
    firstRow: (
      <div className={styles.colWrapper}>
        <FormattedMessage defaultMessage="Numer:" />
        <b>{number}</b>
      </div>
    ),
  },
  {
    key: 'status',
    firstRow: (
      <div className={styles.colWrapper}>
        <FormattedMessage defaultMessage="Status:" />
        <StatusRow status={status} />
      </div>
    ),
  },
  {
    key: 'dateLastContact',
    firstRow: (
      <div className={styles.colWrapper}>
        <FormattedMessage defaultMessage="Ostatnie przypomnienie:" />
        <DateLastContactRow dateLastContact={dateLastContact} />
      </div>
    ),
  },
];
