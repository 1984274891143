import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Switch from 'components/ui/atoms/Switch';
import { proformaTypesObject } from 'constants/preorder';
import { ProformaType } from 'ducks/preorders/types';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { updatePreorderVariantProformaType } from 'ducks/view/preorders';

type Props = {
  proformaType: ProformaType | null;
  variantId: number | string;
};

const generateProformaType = ({
  isFull,
  isHalf,
}: {
  isFull: boolean;
  isHalf: boolean;
}) => {
  if (isFull) return ProformaType.FULL_PAYMENT;

  if (isHalf) {
    return ProformaType.HALF_PAYMENT;
  }

  return undefined;
};

const ProformaSwitches: FC<Props> = ({ proformaType, variantId }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
  const [isFull, setFull] = useState(
    proformaType === ProformaType.FULL_PAYMENT,
  );
  const [isHalf, setHalf] = useState(
    proformaType === ProformaType.HALF_PAYMENT,
  );

  const getLabel = (type: ProformaType) =>
    proformaTypesObject(intl)[type].label;

  const handleChange = ({
    setFunc,
    value,
  }: {
    setFunc: Dispatch<SetStateAction<boolean>>;
    value: boolean;
  }) => {
    if (!isEditable) return;

    setFunc(!value);
  };

  useEffect(() => {
    if (!isEditable) return;

    const proformaTypeValue = generateProformaType({ isFull, isHalf });

    dispatch(
      updatePreorderVariantProformaType({
        variantId,
        proformaType: proformaTypeValue,
      }),
    );
  }, [variantId, isEditable, dispatch, isFull, isHalf]);

  useEffect(() => {
    if (!isEditable) return;

    dispatch(updatePreorderVariantProformaType({ variantId }));
  }, [variantId, isEditable, dispatch]);

  return (
    <>
      <Switch
        defaultChecked={isFull}
        label={getLabel(ProformaType.FULL_PAYMENT)}
        disabled={!isEditable || isHalf}
        onChange={() => handleChange({ setFunc: setFull, value: isFull })}
      />
      <Switch
        defaultChecked={isHalf}
        label={getLabel(ProformaType.HALF_PAYMENT)}
        disabled={!isEditable || isFull}
        onChange={() => handleChange({ setFunc: setHalf, value: isHalf })}
      />
    </>
  );
};

export default ProformaSwitches;
