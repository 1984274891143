import type { FileTranslationType } from 'constants/fileTranslation';
import type { OrderStatus } from 'constants/orderStatuses';
import type { PaymentDetailStatus } from 'constants/payment';
import type { PreorderInvoiceType } from 'constants/preorder';
import type { ServiceType } from 'constants/services';
import type { Client } from 'ducks/clients/types';
import type { FileType, UploadedFile } from 'ducks/global/types';
import type { InvoiceVat } from 'ducks/invoices/types';
import type { Payment } from 'ducks/payments/types';
import type { Preorder, PreorderVariant } from 'ducks/preorders/types';
import type {
  Translator,
  TranslatorSpecialization,
} from 'ducks/translators/types';
import type {
  PaginationRequest,
  PaginationResponse,
  SortRequest,
} from 'ducks/types';
import type { User } from 'ducks/users/types';

export enum OrdersTag {
  Orders = 'Orders',
  OrderTranslationFiles = 'OrderTranslationFiles',
}

export enum OrderRequestTypeParam {
  SHOW = 'show',
}

export type GetOrdersSort = SortRequest<'isPaid'>;

export type GetOrdersFilter = Partial<{
  status: OrderStatus;
  dateCreationBefore: string;
  dateCreationAfter: string;
  senderId: string;
}>;

export type GetOrdersRequest = PaginationRequest &
  Partial<{
    searchTerm: string;
    filter: GetOrdersFilter;
    sort: GetOrdersSort | null;
  }>;

export type GetOrdersResponse = PaginationResponse<OrderListElement>;

export type GetOrderRequest = {
  id: number | string;
  type?: OrderRequestTypeParam | null;
};

export type GetOrderResponse = Order;

export type EditOrderRequest = {
  id: number;
  isImportant?: boolean;
  orderFiles: {
    id: number | null;
    specializations: number[] | null;
    orderFileLangs: {
      id: number | null;
      catId: number | null;
      translatorId: number | null;
      langFrom: string;
      langTo: string;
      isPostedit: Binary;
      isAccepted: boolean;
      dateRealization: string;
      price: number;
      translatorPriceNetto: number;
    }[];
  }[];
  orderServices: {
    id: number | null;
    type: ServiceType;
    priceNetto: number;
    orderServiceValues: {
      id: number | null;
      attributeName: string;
      attributeValue: string;
    }[];
  }[];
};

export type GetOrderVariantRequest = {
  id: number | string;
};

export type GetOrderVariantResponse = PreorderVariant;

export type UnblockOrderRequest = {
  orderId: number;
};

export type OrderMtRequest = {
  id: number | string;
  isMt: boolean;
};

export type CalcOrderPosteditRequest = {
  file: File;
};

export type CalcOrderPosteditResponse = {
  mtqe: number;
  pagesForPostediting: number;
  mtRatio: number;
};

export type OrderSendEmailsRequest = {
  id: string | number;
  translatorId: number;
  email: string;
  name: string;
  cc: string | null;
  subject: string;
  body: string;
  isSms: boolean;
  smsText: string | null;
  isReferenceFile: boolean;
  attachments: number[];
  files: File[];
};

export type GetOrderTranslationFilesRequest = {
  id: number | string;
};

export type GetOrderTranslationFilesResponse = {
  orderFiles: (OrderFile & {
    orderFileLangs: (OrderFileLang & {
      orderTranslationFiles: OrderTranslationFile[];
    })[];
  })[];
  translationsAndOrdersFiles: TranslationsAndOrdersFile[];
};

export type UpdateOrderTranslationFilesRequest = {
  id: number | string;
  body: {
    fileId: number;
    orderTranslationFileId: number | null;
    translatorId: number;
    orderFileLangId: number;
  }[];
};

export type UploadOrderTranslationFilesRequest = {
  id: number | string;
  files: File[];
};

export type UpdateOrderStatusRequest = {
  id: number | string;
  status: number;
};

/* ——————————————————————————REGULAR TYPES—————————————————————————— */

export type OrderListElement = Pick<
  Order,
  | 'number'
  | 'isImportant'
  | 'isCompany'
  | 'clientId'
  | 'id'
  | 'email'
  | 'dateRealization'
  | 'createdAt'
  | 'contactPerson'
  | 'companyName'
  | 'activeEditorId'
  | 'status'
  | 'hasInvoiceDataConvergence'
> & {
  priceNetto: number;
  priceBrutto: number;
  sumPaid: number;
  isPaid: boolean;
  notes: Notes;
  orderFiles: OrderFilesForList[];
  clientId: number;
  paymentStatus: PaymentDetailStatus;
  paidPayments: Payment[];
};

export type OrderFilesForList = {
  type: FileTranslationType;
  translator:
    | {
        id: number;
        firstName: string;
        lastName: string;
      }
    | undefined;
  pages: number;
  langFrom: string;
  langTo: string;
  catId: number;
  dateRealization: string;
  filesCount: number;
  isAccepted: boolean;
  isPostedit: boolean;
  pagesTranslator: number;
  indicator: number;
};

export type OrderInvoice = Pick<
  InvoiceVat,
  | 'id'
  | 'clientId'
  | 'type'
  | 'status'
  | 'clientData'
  | 'number'
  | 'filename'
  | 'amount'
  | 'isCourt'
  | 'isPaid'
  | 'paymentMethod'
  | 'dateDue'
  | 'dateLastContact'
  | 'dateSale'
  | 'dateSent'
  | 'createdAt'
> & {
  comment: string;
  hash: string | null;
  amountBrutto: number;
};

export type Order = {
  onlinePaymentUrl: string;
  id: number;
  clientId: number;
  client: Client | null;
  userId: number;
  user: User;
  priceId: number;
  price: {
    id: number;
    name: string;
  };
  preorderVariantId: number | null;
  preorder: Preorder | null;
  preorderVariant: Omit<
    PreorderVariant,
    'number' | 'preorderVariantFiles' | 'preorderVariantServices'
  > | null;
  activeEditorId: number | null;
  uuid: string;
  number: string;
  isCompany: boolean;
  contactPerson: string;
  companyName: string | null;
  nip: string | null;
  email: string;
  emailCc: string;
  phone: string;
  country: string;
  address: string | null;
  city: string | null;
  postCode: string | null;
  invoiceEmail: string | null;
  invoiceType: PreorderInvoiceType;
  invoiceAuto: boolean;
  verifiedKrd: boolean;
  verifiedVies: boolean;
  vatRate: number;
  priceNetto: number;
  paid: number;
  translatorPriceNetto: number;
  status: OrderStatus;
  dateRealization: Date | null;
  dateTranslationRealizationDefault: string | null;
  dateSent: Date | null;
  timeRealization: number;
  referenceFile: string;
  mqtAverage: number;
  isMt: boolean;
  isSms: boolean;
  pePages: number;
  mtRatio: number;
  repetition: number;
  isImportant: boolean;
  orderFiles: OrderFile[];
  orderFileLangs: OrderFileLang[];
  orderServices: OrderService[];
  orderServiceValues: OrderServiceValue[];
  hasInvoiceDataConvergence: boolean;
  invoices: OrderInvoice[];
  createdAt: Date;
  updatedAt: Date;
};

export type OrderFile = {
  id: number;
  fileId: number;
  orderId: number;
  type: FileTranslationType;
  file: UploadedFile;
  isSpecial: boolean;
  isCorrection: boolean;
  pe: boolean;
  specializations: TranslatorSpecialization[];
};

export type OrderFileLang = {
  id: number;
  orderFileId: number;
  translatorId: number | null;
  translator: Translator | null;
  catId: number | null;
  langFrom: string;
  langTo: string;
  characters: number;
  pages: number;
  pagesTranslator: number;
  isPostedit: Binary;
  isAccepted: boolean;
  dateRealization: string;
  price: number;
  priceSpecialNetto: number;
  priceCorrectionNetto: number;
  translatorPriceNetto: number;
  discount: number;
  timeRealization: number;
};

export type OrderService = {
  id: number;
  orderId: number;
  type: ServiceType;
  vatRate: number;
  priceNetto: number;
};

export type OrderServiceValue = {
  id: number;
  orderServiceId: number;
  attributeName: string;
  attributeValue: string;
};

export type OrderData = {
  id: number;
  isImportant: boolean;
  uuid: string;
  email: string;
  isCompany: boolean;
  phone: string;
  status: OrderStatus;
  contactPerson: string;
  companyName: string;
  dateRealization: Date | null;
  createdAt: Date;
};

export type OrderTranslationFile = {
  id: number;
  fileId: number;
  orderFileId: number;
  translatorId: number | null;
};

export type TranslationsAndOrdersFile = {
  id: number;
  objectId: number;
  object: FileType;
  filename: string;
  file: string;
};

export type Notes = {
  financialCount: number;
  count: number;
};

export type SendReferenceFileRequest = {
  orderId: string;
  file: File;
};
export type SendReferenceFileResponse = {
  referenceFile: string;
};
