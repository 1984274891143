/* eslint-disable react/style-prop-object */
import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { Price } from 'ducks/prices/types';
import { Button, Popconfirm, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import TruncateText from 'components/ui/atoms/TruncateText';
import { EditIcon, TrashIcon } from 'icons';
import { Routes } from 'views/Prices/Routing';
import styles from './styles.module.scss';

export type PriceTableRow = TableRow & Price;

type Columns = ColumnsType<PriceTableRow>;

type Props = {
  handleDelete: (id: number) => void;
  isDeleteLoading: boolean;
};

export const columns = ({ handleDelete, isDeleteLoading }: Props): Columns => [
  {
    title: <FormattedMessage defaultMessage="Nazwa cennika" />,
    key: 'name',
    dataIndex: 'name',
    render: (_, { name }) => <TruncateText maxLength={50}>{name}</TruncateText>,
  },
  {
    title: <FormattedMessage defaultMessage="Akcje" />,
    width: 100,
    align: 'center',
    render: ({ id }) => (
      <Space className={styles.tableActions}>
        <Link to={Routes.PRICE_DETAILS.replace(':id', id.toString())}>
          <Button type="link" icon={<EditIcon />}>
            <FormattedMessage defaultMessage="Edytuj" />
          </Button>
        </Link>
        <Popconfirm
          title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
          okText={<FormattedMessage defaultMessage="Tak" />}
          cancelText={<FormattedMessage defaultMessage="Nie" />}
          onConfirm={() => handleDelete(id)}
          disabled={isDeleteLoading}
        >
          <Button type="link" icon={<TrashIcon />} danger>
            <FormattedMessage defaultMessage="Usuń" />
          </Button>
        </Popconfirm>
      </Space>
    ),
  },
];
