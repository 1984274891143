import type { EditableColumnType } from '../../config';
import type { HTMLAttributes, ReactElement } from 'react';
import { Form, Input, InputNumber, Skeleton } from 'antd';
import CopyToClipboardWithText from 'components/ui/atoms/CopyToClipboard';

interface Props<T>
  extends HTMLAttributes<HTMLElement>,
    Pick<
      EditableColumnType<T>[number],
      'inputType' | 'render' | 'dataIndex' | 'rules'
    > {
  editing: boolean;
  availableCopy: boolean;
  record: T;
  isLoading: boolean;
  index: number;
  children: ReactElement;
}

const EditableCell = <T,>({
  editing,
  dataIndex,
  inputType,
  record,
  isLoading,
  index,
  children,
  render,
  rules,
  availableCopy = false,
  ...restProps
}: Props<T>) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  const generateCellBody = () => {
    if (editing)
      return (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          valuePropName={inputType === 'checkbox' ? 'checked' : undefined}
          rules={rules}
        >
          {(render?.(undefined, record, index) as React.ReactElement) ??
            inputNode}
        </Form.Item>
      );

    if (availableCopy && record?.[dataIndex as keyof typeof record])
      return (
        <CopyToClipboardWithText
          type="secondary"
          text={record?.[dataIndex as keyof typeof record]?.toString() ?? ''}
        />
      );
    return children;
  };

  return (
    <td
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      title={record?.[dataIndex as keyof typeof record]?.toString()}
    >
      <Skeleton loading={isLoading} round paragraph={false} active>
        {generateCellBody()}
      </Skeleton>
    </td>
  );
};

export default EditableCell;
