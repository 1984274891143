/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import type { UploadFile } from 'antd';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { FC } from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import Empty from 'components/ui/atoms/Empty';
import CardCommon from 'components/ui/molecules/CardCommon';
import Upload from 'components/ui/molecules/Upload';
import { useAddFileMutation } from 'ducks/global/service';
import { FileType } from 'ducks/global/types';
import { useGetPreorderServicesQuery } from 'ducks/preorders/service';
import { useGetPricesQuery } from 'ducks/prices/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { addPreorderVariantFile } from 'ducks/view/preorders';
import { UploadIcon } from 'icons';
import { usePreorderDetailsServices } from '../../usePreorderDetailsServices';

interface Props {
  notify: NotificationInstance;
}

const UploadFilesSection: FC<Props> = ({ notify }) => {
  const { id: preorderId = '' } = useParams();
  const selectedPrice = useAppSelector(
    (state) => state.preorders.selectedPrice,
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const { isPreorderFetching } = usePreorderDetailsServices();
  const { data, isLoading: isServicesLoading } = useGetPreorderServicesQuery();
  const { isLoading: isPricesLoading } = useGetPricesQuery();
  const [addFile, { data: fileData, isLoading: isAddFileLoading }] =
    useAddFileMutation();
  const isLoading = isPreorderFetching || isServicesLoading || isPricesLoading;
  const isUploadLoading = isAddFileLoading || isLoading;
  const isLackOfPriceItems = !selectedPrice?.items.length;

  const handleAddVariant = async (files: UploadFile[]) => {
    if (!selectedPrice) return;

    try {
      await addFile({
        object: FileType.PREORDERS,
        id: Number(preorderId),
        files: files
          .map(({ originFileObj }) => originFileObj)
          .filter((file) => file) as File[],
      }).unwrap();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas przesyłania plików." />
        ),
      });
    }
  };

  useEffect(() => {
    if (fileData && data?.services) {
      fileData.forEach((file) =>
        dispatch(
          addPreorderVariantFile({ file, preorderServices: data.services }),
        ),
      );
    }
  }, [fileData, data?.services]);

  return (
    <CardCommon
      data-testid="uploadFilesSection"
      title={<FormattedMessage defaultMessage="Pliki do tłumaczenia" />}
      isLoading={isLoading}
      customSkeleton
    >
      {!selectedPrice ? (
        <Empty
          data-testid="noSelectedPriceEmpty"
          size="small"
          description={
            <FormattedMessage defaultMessage="Aby wgrać pliki należy wybrać cennik" />
          }
        />
      ) : isLackOfPriceItems ? (
        <Empty
          size="small"
          description={
            <FormattedMessage defaultMessage="Brak danych w wybranym cenniku" />
          }
        />
      ) : !data ? (
        <Empty
          size="small"
          description={
            <FormattedMessage defaultMessage="Wystąpił błąd podczas pobierania usług" />
          }
        />
      ) : (
        <Upload
          onChange={({ fileList }) => handleAddVariant(fileList)}
          disabled={isUploadLoading}
        >
          <Button icon={<UploadIcon />} fullWidth loading={isUploadLoading}>
            <FormattedMessage defaultMessage="Wgraj pliki do tłumaczenia" />
          </Button>
        </Upload>
      )}
    </CardCommon>
  );
};

export default UploadFilesSection;
