import { PaymentParseStatus } from 'ducks/payments/types';
import styles from './styles.module.scss';

export const getRowColorByPaymentStatus = (
  status: PaymentParseStatus,
): string => {
  switch (status) {
    case PaymentParseStatus.TRANSFER_TITLE_AMOUNT_MATCH:
      return styles.tableRowStatusAmountMatch;
    case PaymentParseStatus.TRANSFER_TITLE_AMOUNT_SENDER_MATCH:
      return styles.tableRowStatusAmountSenderMatch;
    default:
      return styles.tableRowStatusNoMatch;
  }
};
