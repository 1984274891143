import type { FC } from 'react';
import { Button } from 'antd';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setPreorderAddLanguagePairModal } from 'ducks/view/preorders';
import { PlusIcon } from 'icons';
import styles from './styles.module.scss';

interface Props {
  variantFileId: number | string;
}

const AddLangButton: FC<Props> = memo(({ variantFileId }) => {
  const { selectedPrice } = useAppSelector(
    ({ preorders }) => ({
      isEditable: preorders.isEditable,
      selectedPrice: preorders.selectedPrice,
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const isLackOfPriceItems = !selectedPrice?.items.length;

  const handleOpenAddLanguagePairModal = () => {
    if (isLackOfPriceItems) return;

    dispatch(setPreorderAddLanguagePairModal({ isOpen: true, variantFileId }));
  };

  return (
    <Button
      className={styles.button}
      onClick={handleOpenAddLanguagePairModal}
      type="link"
      size="small"
      icon={<PlusIcon />}
      disabled={isLackOfPriceItems}
    >
      <FormattedMessage defaultMessage="Dodaj parę językową" />
    </Button>
  );
});

export default AddLangButton;
