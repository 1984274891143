type CalcFinalPrice = {
  priceNetto: number;
  discount: number;
  vatRate: number;
};

export const calcNetto = (brutto: number, vat: number) =>
  Number((brutto / (1 + vat / 100)).toFixed(2));

export const calcBrutto = (netto: number, vat: number) =>
  Number((netto * (1 + vat / 100)).toFixed(2));

export const calcDiscount = (
  price: number,
  discount: number,
  toFixed = true,
) => {
  const originalPrise = parseFloat(price.toFixed(2));
  const discountedPrice = originalPrise - originalPrise * (discount / 100);
  const roundedPrice = Math.ceil(discountedPrice * 100) / 100;

  return toFixed ? Number(roundedPrice.toFixed(2)) : roundedPrice;
};

export const calcFinalBrutto = ({
  priceNetto,
  discount,
  vatRate,
}: CalcFinalPrice) => calcBrutto(calcDiscount(priceNetto, discount), vatRate);
