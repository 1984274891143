import type { TranslationsAndOrdersFile } from 'ducks/orders/types';
import { useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'ducks/store';

export type TranslationFile = TranslationsAndOrdersFile & {
  isAssigned: boolean;
};

export const useTranslationFilesHelpers = () => {
  const { orderFiles, uploadedTranslationFiles } = useAppSelector(
    ({ orderTranslationFiles }) => ({
      orderFiles: orderTranslationFiles.orderFiles,
      uploadedTranslationFiles:
        orderTranslationFiles.uploadedFiles.translations,
    }),
    shallowEqual,
  );

  const checkIfIsAssigned = useCallback(
    (id: number) =>
      orderFiles.some(({ orderFileLangs }) =>
        orderFileLangs.some(({ orderTranslationFiles }) =>
          orderTranslationFiles.some(({ fileId }) => fileId === id),
        ),
      ),
    [orderFiles],
  );

  const modifiedTranslationFiles = useMemo(
    (): TranslationFile[] =>
      uploadedTranslationFiles.map((translationFile) => ({
        ...translationFile,
        isAssigned: checkIfIsAssigned(translationFile.id),
      })),
    [uploadedTranslationFiles, checkIfIsAssigned],
  );

  return { modifiedTranslationFiles };
};
