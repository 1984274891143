import type { SendClientEmailsValues } from '../../config';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Switch, { SwitchSkeleton } from 'components/ui/atoms/Switch';
import TruncateText from 'components/ui/atoms/TruncateText';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useOrderClientSendServices } from '../../useOrderClientSendServices';
import AttachmentSwitches from './AttachmentSwitches';
import styles from './styles.module.scss';

const SwitchesSection = () => {
  const { isLoading } = useOrderClientSendServices();

  return (
    <CardCommon
      data-testid="switchesSection"
      className={styles.wrapper}
      xs={24}
      lg={10}
      xl={9}
    >
      <AttachmentSwitches />
      <div>
        <TruncateText strong maxLength={50}>
          <FormattedMessage defaultMessage="Referencje" />
        </TruncateText>
        {isLoading ? (
          <SwitchSkeleton />
        ) : (
          <FormItem<SendClientEmailsValues>
            name="isReferenceFile"
            component={
              <Switch
                label={<FormattedMessage defaultMessage="Prezentacja" />}
              />
            }
            additionalItemProps={{
              valuePropName: 'checked',
            }}
            withoutMargin
          />
        )}
      </div>
    </CardCommon>
  );
};

export default SwitchesSection;
