import type { VariantServiceRow } from '../../config';
import type { FC } from 'react';
import { useIntl } from 'react-intl';
import TruncateText from 'components/ui/atoms/TruncateText';
import { servicesObject } from 'constants/services';

type Props = Pick<VariantServiceRow, 'type'>;

const TypeCol: FC<Props> = ({ type }) => {
  const intl = useIntl();

  return (
    <TruncateText maxLength={50} nowrap>
      {servicesObject(intl)[type].label}
    </TruncateText>
  );
};

export default TypeCol;
