import type { TranslatorRow } from './config';
import type {
  GetTranslatorsFilter,
  GetTranslatorsSort,
} from 'ducks/translators/types';
import { Button, notification } from 'antd';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Table from 'components/ui/molecules/Table';
import { useGetTranslatorsQuery } from 'ducks/translators/service';
import useTablePagination from 'hooks/useTablePagination';
import { PlusIcon } from 'icons';
import { Routes } from 'views/Translators/Routing';
import AddTranslatorModal from './components/AddTranslatorModal';
import TranslatorsTableToolbar from './components/TranslatorsTableToolbar';
import { columns, initialFilters } from './config';
import styles from './styles.module.scss';

const TranslatorsTable = () => {
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [isAddTranslatorModalOpen, setIsAddTranslatorModalOpen] =
    useState(false);
  const {
    page,
    handlePageChange,
    searchValue,
    searchTerm,
    handleSearchValue,
    filter,
    filterBy,
    handleFilterBy,
    sortBy,
    handleTableChange,
  } = useTablePagination<TranslatorRow, GetTranslatorsFilter>({
    initialFilters,
  });

  const sort = sortBy?.order && {
    [sortBy.columnKey as keyof GetTranslatorsSort]: sortBy.order,
  };
  const { data, isFetching } = useGetTranslatorsQuery(
    {
      page,
      searchTerm,
      filter,
      sort,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );
  const pageSize = data?.perPage;
  const total = isFetching ? 0 : data?.total;

  const modifiedTranslatorsData = useMemo((): TranslatorRow[] => {
    return (data?.data ?? []).map((translator) => {
      const { id, emailDefault, phoneDefault, email1, email2, phone1, phone2 } =
        translator;
      const email = emailDefault === 1 ? email1 : email2;
      const phone = phoneDefault === 1 ? phone1 : phone2;

      return {
        ...translator,
        key: id,
        email,
        phone,
      };
    });
  }, [data?.data]);

  return (
    <div className="layout-content mb-24" data-testid="translatorsTable">
      {contextHolder}
      <AddTranslatorModal
        isOpen={isAddTranslatorModalOpen}
        onClose={() => setIsAddTranslatorModalOpen(false)}
        notify={notify}
      />
      <div className={styles.wrapper}>
        <TranslatorsTableToolbar
          searchValue={searchValue}
          onSearchValueChange={handleSearchValue}
          filterBy={filterBy}
          onFilterByChange={handleFilterBy}
        />
        <Table
          pagination={{
            current: page,
            pageSize,
            total,
            onChange: handlePageChange,
          }}
          isLoading={isFetching}
          data={modifiedTranslatorsData}
          columns={columns}
          onChange={handleTableChange}
          onRow={({ id }) => {
            return {
              onClick: () =>
                navigate(
                  Routes.TRANSLATOR_PROFILE.replace(':id', id.toString()),
                ),
            };
          }}
          locale={{
            triggerDesc: intl.formatMessage({
              defaultMessage: 'Sortuj malejąco',
            }),
            triggerAsc: intl.formatMessage({
              defaultMessage: 'Sortuj rosnąco',
            }),
            cancelSort: intl.formatMessage({
              defaultMessage: 'Anuluj sortowanie',
            }),
          }}
          cardTitle={<FormattedMessage defaultMessage="Lista tłumaczy" />}
          cardTitleExtra={
            <Button
              icon={<PlusIcon />}
              type="primary"
              onClick={() => setIsAddTranslatorModalOpen(true)}
            >
              <FormattedMessage defaultMessage="Dodaj tłumacza" />
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default TranslatorsTable;
