/* eslint-disable no-param-reassign */
import type {
  AssignFinancesPaymentTransactionPayload,
  FinancesSliceState,
} from './type';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { ParsePaymentFile } from 'ducks/payments/types';
import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

const initialState: FinancesSliceState = {
  parsedPaymentFile: [],
  assignPaymentTransactionModal: {
    isOpen: false,
    parsedPaymentId: null,
    selectedPaymentTransaction: null,
  },
};

const financesSlice = createSlice({
  name: 'finances',
  initialState,
  reducers: {
    setFinancesParsedPaymentFile: (
      state,
      { payload }: PayloadAction<ParsePaymentFile[]>,
    ) => {
      state.parsedPaymentFile = payload.map((file) => ({
        ...file,
        id: v4(),
      }));
    },
    setFinancesAssignPaymentTransactionModal: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<FinancesSliceState['assignPaymentTransactionModal']>
      >,
    ) => {
      state.assignPaymentTransactionModal = {
        ...state.assignPaymentTransactionModal,
        ...payload,
      };
    },
    assignFinancesPaymentTransaction: (
      state,
      {
        payload: { parsedPaymentId, paymentTransaction },
      }: PayloadAction<AssignFinancesPaymentTransactionPayload>,
    ) => {
      const parsedPaymentFile = state.parsedPaymentFile.find(
        ({ id }) => id === parsedPaymentId,
      );

      if (!parsedPaymentFile) return;

      parsedPaymentFile.object = paymentTransaction;
    },
    resetFinancesState: () => initialState,
  },
});

export const {
  setFinancesParsedPaymentFile,
  setFinancesAssignPaymentTransactionModal,
  assignFinancesPaymentTransaction,
  resetFinancesState,
} = financesSlice.actions;

export default financesSlice.reducer;
