import type { OptionalNullable } from 'ducks/types';

export enum PricesTag {
  Prices = 'Prices',
}

export type GetPricesResponse = Price[];

export type AddPriceRequest = {
  name: string;
};

export type AddPriceResponse = {
  id: number;
};

export type GetPriceDetailsResponse = Price;

export type GetPriceDetailsRequest = { id: number };

export type EditPriceRequest = {
  id: number;
  name: string;
  items: OptionalNullable<PriceItem>[];
};

export type DeletePriceRequest = {
  id: number;
};

export type DeletePriceItemRequest = {
  id: number;
  itemId: number;
};

export type Price = {
  id: number;
  name: string;
  items: PriceItem[];
};

export type PriceItem = {
  id: number;
  priceId: number;
  langFrom: string;
  langTo: string;
  normal: number;
  normalSpecialized: number;
  sworn: number;
  swornSpecialized: number;
  correction: number;
  correctionSpecialized: number;
  redaction: number;
  redactionSpecialized: number;
  verification: number;
  verificationSpecialized: number;
};
