import type { FileFormValues } from './PresentationForm/PresentationForm';
import type { ResponseFormItem } from 'ducks/settings/types';
import { Button, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import {
  dictionaryOfNames,
  fieldSettings,
} from 'components/ui/molecules/DynamicForm/config';
import { getStorageUrl } from 'utilities/api';
import { downloadFile } from 'utilities/downloadFile';

const { Password } = Input;

export enum FormsIds {
  INVOICE_FORM = '1',
  CONTACT_FORM = '2',
  BANK_FORM = '3',
  PRESENTATION_FORM = '4',
  EXTERNAL_INTEGRATIONS = '5',
  QUOTATION_EXCEPTIONS = '6',
}

export type FormValues = FileFormValues & {
  [key: string]: string | boolean | number;
};

const handleDownload = async (filePath: string) => {
  const pathArray = filePath.split('/');
  downloadFile(
    `${getStorageUrl()}/${filePath}`,
    pathArray[pathArray.length - 1],
  );
};

const generateDescription = (item: ResponseFormItem) => {
  if (item.name === dictionaryOfNames.IS_AUTHORIZATION)
    return <CircleCheckbox value={Boolean(item.value)} />;

  if (
    item.name === dictionaryOfNames.PASSWORD &&
    typeof item.value === 'string'
  )
    return (
      <Password
        disabled
        value={item.value}
        style={{ backgroundColor: 'white' }}
      />
    );

  if (item.name === dictionaryOfNames.PRESENTATION)
    return (
      <Button
        type="link"
        onClick={() => handleDownload(`${item.value}`)}
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <FormattedMessage defaultMessage="Pobierz" />
      </Button>
    );

  return String(item.value);
};

export const getListItems = (data: ResponseFormItem[]) =>
  data.map((item) => ({
    label: fieldSettings?.[item.name]?.label ?? '',
    description: generateDescription(item),
  }));

export const commonRules = [
  {
    required: true,
    message: <FormattedMessage defaultMessage="Uzupełnij pole" />,
  },
];
