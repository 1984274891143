import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import axios from 'axios';
import { camelCase, snakeCase } from 'lodash';
import { formatObjectKeys } from 'utilities/api';

type BaseQueryParams = {
  baseUrl?: string;
};

type QueryParams = {
  url: string;
  method?: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  responseType?: AxiosRequestConfig['responseType'];
  headers?: AxiosRequestConfig['headers'];
};

export const doRequest = async (
  {
    url,
    method = 'get',
    data,
    params,
    headers,
    responseType = 'json',
  }: QueryParams,
  baseUrl = process.env.REACT_APP_API_BASE_URL,
) => {
  try {
    const result = await axios({
      headers,
      withCredentials: true,
      url: `${baseUrl}${url}`,
      method,
      data: headers ? data : formatObjectKeys(data, snakeCase),
      params,
      responseType,
    });
    return {
      data:
        responseType !== 'blob'
          ? formatObjectKeys(result.data.data, camelCase)
          : result.data,
    };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

const baseQuery =
  (
    { baseUrl }: BaseQueryParams = {
      baseUrl: process.env.REACT_APP_API_BASE_URL,
    },
  ): BaseQueryFn<QueryParams> =>
  async (params) =>
    doRequest(params, baseUrl);

export default baseQuery;
