import type { PreorderVariantSlice } from 'ducks/view/preorders';
import type { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import FormItem from 'components/ui/atoms/FormItem';
import { MAX_REPETITION_VALUE } from 'constants/formInputLengths';
import { useAppSelector } from 'ducks/store';
import styles from './styles.module.scss';

type Props = {
  title: ReactElement | string;
  repetition?: number;
};

const TableCardTitle: FC<Props> = ({ title, repetition }) => {
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
  const showRepetition = typeof repetition !== 'undefined';

  return (
    <div className={styles.wrapper}>
      <span>{title}</span>
      {showRepetition &&
        (isEditable ? (
          <FormItem<PreorderVariantSlice>
            name="repetition"
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: <FormattedMessage defaultMessage="Podaj liczbę" />,
              },
            ]}
            label={<FormattedMessage defaultMessage="Powtarzalność (%)" />}
            component={
              <DecimalInputNumber
                max={MAX_REPETITION_VALUE}
                addonAfter="%"
                precision={0}
                min={0}
              />
            }
          />
        ) : (
          <span>
            <FormattedMessage
              defaultMessage="Powtarzalność (%): {repetition}%"
              values={{ repetition: repetition ?? 0 }}
            />
          </span>
        ))}
    </div>
  );
};

export default TableCardTitle;
