import { Button } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'components/main/App/Routing';
import Table from 'components/ui/molecules/Table';
import { useGetSmsTemplatesQuery } from 'ducks/sms/service';
import { PlusIcon } from 'icons';
import { Routes as SettingsRoutes } from 'views/Settings/Routing';
import { Routes as EmailTemplatesRoutes } from '../../Routing';
import { columns } from './config';

const List: React.FC = () => {
  const { data = [], isFetching } = useGetSmsTemplatesQuery();
  const navigate = useNavigate();
  const handleEdit = (id: number) =>
    navigate(`${Routes.SETTINGS}${SettingsRoutes.SMS_TEMPLATES}/${id}`);

  const handleAdd = () =>
    navigate(
      `${Routes.SETTINGS}${SettingsRoutes.SMS_TEMPLATES}${EmailTemplatesRoutes.ADD}`,
    );

  const modifiedData = useMemo(
    () =>
      data.map((row) => {
        return {
          key: row.id,
          ...row,
        };
      }),
    [data],
  );

  return (
    <section className="mb-24">
      <Table
        cardTitle={<FormattedMessage defaultMessage="Szablony SMS" />}
        cardTitleExtra={
          <Button type="primary" icon={<PlusIcon />} onClick={handleAdd}>
            <FormattedMessage defaultMessage="Dodaj szablon" />
          </Button>
        }
        data={modifiedData}
        columns={columns({ handleEdit })}
        isLoading={isFetching}
      />
    </section>
  );
};

export default List;
