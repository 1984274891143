import type {
  GetPriceDetailsResponse,
  GetPricesResponse,
} from 'ducks/prices/types';
import { LanguageCode } from 'constants/countryAndLanguageCodes';

export const mockedPrices: GetPricesResponse = [
  {
    id: 1,
    name: 'Price 1',
    items: [
      {
        id: 1,
        priceId: 1,
        langFrom: LanguageCode.POLAND,
        langTo: LanguageCode.ENGLISH,
        normal: 1,
        normalSpecialized: 1.2,
        sworn: 1,
        swornSpecialized: 1.2,
        correction: 1,
        correctionSpecialized: 1.2,
        redaction: 1,
        redactionSpecialized: 1.2,
        verification: 1,
        verificationSpecialized: 1.2,
      },
    ],
  },
  {
    id: 2,
    name: 'Price 2',
    items: [],
  },
  {
    id: 3,
    name: 'Price 3',
    items: [],
  },
];

export const mockedPriceDetails: GetPriceDetailsResponse = {
  id: 1,
  name: 'Custom price list',
  items: [
    {
      id: 1,
      priceId: 1,
      langFrom: LanguageCode.POLAND,
      langTo: LanguageCode.ENGLISH,
      normal: 1,
      normalSpecialized: 1.2,
      sworn: 1,
      swornSpecialized: 1.2,
      correction: 1,
      correctionSpecialized: 1.2,
      redaction: 1,
      redactionSpecialized: 1.2,
      verification: 1,
      verificationSpecialized: 1.2,
    },
    {
      id: 2,
      priceId: 1,
      langTo: LanguageCode.POLAND,
      langFrom: LanguageCode.ENGLISH,
      normal: 5,
      normalSpecialized: 6,
      sworn: 14,
      swornSpecialized: 17,
      correction: 7,
      correctionSpecialized: 8.5,
      redaction: 8,
      redactionSpecialized: 9.2,
      verification: 3,
      verificationSpecialized: 3.8,
    },
    {
      id: 3,
      priceId: 1,
      langFrom: LanguageCode.FRENCH,
      langTo: LanguageCode.GERMAN,
      normal: 5,
      normalSpecialized: 6,
      sworn: 14,
      swornSpecialized: 17,
      correction: 7,
      correctionSpecialized: 8.5,
      redaction: 8,
      redactionSpecialized: 9.2,
      verification: 3,
      verificationSpecialized: 3.8,
    },
    {
      id: 4,
      priceId: 1,
      langFrom: LanguageCode.SPANISH,
      langTo: LanguageCode.ITALIAN,
      normal: 5,
      normalSpecialized: 6,
      sworn: 14,
      swornSpecialized: 17,
      correction: 7,
      correctionSpecialized: 8.5,
      redaction: 8,
      redactionSpecialized: 9.2,
      verification: 3,
      verificationSpecialized: 3.8,
    },
  ],
};
