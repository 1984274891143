/* eslint-disable react/jsx-props-no-spreading */
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { GusReturnProps } from 'components/ui/atoms/GusButton';
import type { Client } from 'ducks/clients/types';
import type { FC } from 'react';
import { Form, Input } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal/Modal';
import DuplicateClientsModal from 'components/ui/organisms/Modals/DuplicateClientsModal';
import {
  useAddClientMutation,
  useCheckDuplicatesMutation,
} from 'ducks/clients/service';
import {
  initialValues,
  type AddClientModalValues,
  addClientModalFormItems,
  hiddenInputs,
} from './config';

const { Item } = Form;

interface AddClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  notify: NotificationInstance;
}

const { useForm, useWatch } = Form;

const AddClientModal: FC<AddClientModalProps> = ({
  isOpen,
  onClose,
  onOpen,
  notify,
}) => {
  const [form] = useForm<AddClientModalValues>();
  const [isDuplicateClientsModalOpen, setIsDuplicateClientsModalOpen] =
    useState(false);
  const [duplicateClients, setDuplicateClients] = useState<Client[]>([]);
  const [checkDuplicates, { isLoading: isCheckDuplicatesLoading }] =
    useCheckDuplicatesMutation();
  const [addClient, { isLoading: isAddClientLoading }] = useAddClientMutation();
  const isLoading = isCheckDuplicatesLoading || isAddClientLoading;
  const nip = useWatch('nip', form);
  const isCompany = useWatch('isCompany', form);
  const country = useWatch('country', form);

  const handleAddClient = async () => {
    if (isLoading) return;

    const values = await form.validateFields();

    try {
      await addClient({
        ...values,
      }).unwrap();

      notify.success({
        message: <FormattedMessage defaultMessage="Klient został dodany." />,
      });

      onClose();
      form.resetFields();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania klienta." />
        ),
      });
    }
  };

  const handleOnSubmit = async () => {
    if (isLoading) return;

    try {
      const values = await form.validateFields();

      const res = await checkDuplicates({
        name: values.name,
        email1: values.email1,
      }).unwrap();

      if (Array.isArray(res)) {
        setDuplicateClients(res);
        setIsDuplicateClientsModalOpen(true);
        onClose();

        return;
      }

      handleAddClient();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.errorFields) return;

      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania klienta." />
        ),
      });
    }
  };

  const handleOnDuplicateClientsModalClose = () => {
    setIsDuplicateClientsModalOpen(false);
    setDuplicateClients([]);
    onOpen();
  };

  const handleCheckNip = (data: GusReturnProps) => {
    form.setFieldsValue({
      ...data,
      address: `${data.street} ${data.addressNumbers.join(', ')}`,
    });
  };

  return (
    <>
      <DuplicateClientsModal
        isOpen={isDuplicateClientsModalOpen}
        onClose={handleOnDuplicateClientsModalClose}
        duplicateClients={duplicateClients}
      />
      <Modal
        data-testid="addClientModal"
        title={<FormattedMessage defaultMessage="Dodaj klienta" />}
        isLoading={isLoading}
        open={isOpen}
        onOk={handleOnSubmit}
        onCancel={onClose}
        okText={<FormattedMessage defaultMessage="Dodaj" />}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          initialValues={initialValues}
        >
          {addClientModalFormItems({
            nipValue: nip,
            handleCheckNip,
            country,
          })
            .filter(
              (item) => !(!isCompany && (item.key === 2 || item.key === 3)),
            )
            .map(({ key, ...props }) => (
              <FormItem key={key} {...props} />
            ))}

          {hiddenInputs.map(({ name }) => (
            <Item hidden name={name}>
              <Input type="hidden" />
            </Item>
          ))}
        </Form>
      </Modal>
    </>
  );
};

export default AddClientModal;
