import { Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useOrderDetailsServices } from '../../useOrderDetailsServices';
import { formItems } from './config';

const InvoiceDataSection = () => {
  const intl = useIntl();
  const { orderData, isOrderLoading } = useOrderDetailsServices();

  return (
    <CardCommon
      data-testid="invoiceDataSection"
      title={
        <FormattedMessage defaultMessage="Dane do faktury dla bieżącego zamówienia" />
      }
      isLoading={isOrderLoading}
      xs={24}
      lg={12}
    >
      <Form layout="vertical" autoComplete="off">
        <div className="gridForm">
          {orderData &&
            formItems({
              data: orderData,
              intl,
            }).map(({ key, ...props }) => <FormItem key={key} {...props} />)}
        </div>
      </Form>
    </CardCommon>
  );
};

export default InvoiceDataSection;
