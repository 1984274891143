import type { SetPreorderSelectedPricePayload } from 'ducks/view/preorders';
import { Select, notification } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  setPreorderSelectedPrice,
  setPreorderSelectedPriceId,
} from 'ducks/view/preorders';
import { usePreorderDraftCalculationServices } from '../usePreorderDraftCalculationServices';

type PreorderSelectedPriceOnError = NonNullable<
  SetPreorderSelectedPricePayload['onError']
>;

const PricesSection = () => {
  const [notify, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const selectedPriceId = useAppSelector(
    ({ preorders }) => preorders.selectedPriceId,
  );
  const { pricesData, isPricesFetching } =
    usePreorderDraftCalculationServices();
  const isLoading = isPricesFetching;

  const handleOnError = useCallback<PreorderSelectedPriceOnError>(
    ({ missingLangs }) => {
      const langFrom = missingLangs[0][0];
      const langTo = missingLangs[0][1];

      notify.error({
        message: (
          <FormattedMessage
            defaultMessage="Brak stawek dla pary językowej {langFrom} - {langTo}. Zmiana cennika niemożliwa."
            values={{ langFrom, langTo }}
          />
        ),
      });
    },
    [notify],
  );

  const handleChangeSelectedPrice = useCallback(
    (priceId: number) => {
      const price = pricesData.find(({ id }) => id === priceId);

      if (!price) {
        dispatch(setPreorderSelectedPriceId(null));

        return;
      }

      dispatch(setPreorderSelectedPriceId(priceId));
      dispatch(
        setPreorderSelectedPrice({
          price,
          onError: handleOnError,
        }),
      );
    },
    [pricesData, handleOnError, dispatch],
  );

  const options = useMemo(
    () =>
      pricesData.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [pricesData],
  );

  useEffect(() => {
    if (!pricesData.length) return;

    if (pricesData.length === 1) {
      handleChangeSelectedPrice(pricesData[0].id);
    }
  }, [pricesData, handleChangeSelectedPrice]);

  return (
    <CardCommon
      title={<FormattedMessage defaultMessage="Cennik" />}
      isLoading={isLoading}
      customSkeleton
      xs={24}
      md={16}
    >
      {contextHolder}
      <Select
        className="width-100"
        options={options}
        value={selectedPriceId}
        onChange={handleChangeSelectedPrice}
        placeholder={<FormattedMessage defaultMessage="Wybierz cennik" />}
        loading={isLoading}
        disabled={isLoading}
      />
    </CardCommon>
  );
};

export default PricesSection;
