import type { PreorderSendValues } from '../../config';
import type { FormInstance } from 'antd';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { useSendEmailPreorderMutation } from 'ducks/preorders/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setGlobalIsLoading } from 'ducks/view/global';
import { Routes } from 'views/Preorders/Routing';
import { usePreorderSendServices } from '../../usePreorderSendServices';
import styles from './styles.module.scss';

type Props = {
  form: FormInstance<PreorderSendValues>;
  notify: NotificationInstance;
};

const ActionsSection: FC<Props> = ({ form, notify }) => {
  const navigate = useNavigate();
  const { selectedVariantIds, uploadedFiles } = useAppSelector(
    (state) => ({
      selectedVariantIds: state.preorders.selectedVariantIds,
      uploadedFiles: state.preorders.uploadedFiles,
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const { preorderData, isLoading: isServicesLoading } =
    usePreorderSendServices();
  const [sendEmail, { isLoading: isSendLoading }] =
    useSendEmailPreorderMutation();
  const isLoading = isServicesLoading || isSendLoading;

  const handleSubmit = async () => {
    if (isLoading || !preorderData) return;

    try {
      dispatch(setGlobalIsLoading(true));
      const { id: preorderId, contactPerson } = preorderData;
      const { isSms, smsText, ...values } = await form.validateFields();

      const files = uploadedFiles.map(
        ({ originFileObj }) => originFileObj as File,
      );

      await sendEmail({
        ...values,
        preorderId,
        orderId: null,
        name: contactPerson ?? '',
        isSms,
        smsText: isSms ? smsText : '',
        isReferenceFile: false,
        variants: selectedVariantIds,
        files,
      }).unwrap();

      navigate(Routes.PREORDER_DETAILS.replace(':id', preorderId.toString()));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.errorFields) {
        form.scrollToField(error?.errorFields[0].name);
        return;
      }

      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas wysyłania wiadomości." />
        ),
      });
    } finally {
      dispatch(setGlobalIsLoading(false));
    }
  };

  return (
    <div data-testid="actionsSection" className={styles.buttonWrapper}>
      <Button
        htmlType="button"
        type="primary"
        onClick={handleSubmit}
        loading={isLoading}
      >
        <FormattedMessage defaultMessage="Wyślij" />
      </Button>
    </div>
  );
};

export default ActionsSection;
