import type { IntlShape } from 'react-intl';
import { Button, Card, Form, Input, Typography, notification } from 'antd';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SmsTemplateSelector from 'components/ui/molecules/SmsTemplateSelector';
import TemplateEditor from 'components/ui/organisms/TemplateEditor';
import { useSendSmsMutation } from 'ducks/sms/service';
import styles from './style.module.scss';

const { Item, useForm } = Form;
const { Title } = Typography;

const regex = /\+48\d{9}/;

const validatePolishNumber = (phoneNumber: string, intl: IntlShape) => {
  return !regex.test(phoneNumber)
    ? Promise.reject(
        new Error(
          intl.formatMessage({
            defaultMessage: 'Podaj numer w formacie "+48xxxxxxxxx"',
          }),
        ),
      )
    : Promise.resolve();
};

const Sms = () => {
  const [charactersCount, setCharactersCount] = useState(0);
  const [notify, context] = notification.useNotification();
  const [form] = useForm();
  const intl = useIntl();

  const [sendSms, { isLoading }] = useSendSmsMutation();

  const handleSubmit = async () => {
    try {
      const { message, phone } = await form.validateFields();

      await sendSms({ message, phone }).unwrap();

      notify.success({
        message: <FormattedMessage defaultMessage="Szablon został edytowany" />,
      });
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania szablonu." />
        ),
      });
    }
  };

  return (
    <Card bordered={false}>
      {context}
      <Title level={5}>
        <FormattedMessage defaultMessage="Wyślij SMS" />
      </Title>
      <SmsTemplateSelector form={form} />

      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Item
          name="phone"
          label={<FormattedMessage defaultMessage="Numer telefonu" />}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage defaultMessage="Uzupełnij treść szablonu" />
              ),
            },
            ({ getFieldValue }) => ({
              validator() {
                return validatePolishNumber(getFieldValue('phone'), intl);
              },
            }),
          ]}
        >
          <Input />
        </Item>
        <Item
          name="message"
          label={<FormattedMessage defaultMessage="Treść szablonu" />}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage defaultMessage="Uzupełnij treść szablonu" />
              ),
            },
          ]}
        >
          <TemplateEditor
            simpleEditor
            setCharactersCount={setCharactersCount}
          />
        </Item>
        <div className={styles.charactersCountWrapper}>
          <FormattedMessage
            defaultMessage="Liczba znaków: {charactersCount}"
            values={{
              charactersCount,
            }}
          />
        </div>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          <FormattedMessage defaultMessage="Wyślij" />
        </Button>
      </Form>
    </Card>
  );
};

export default Sms;
