import type { TranslatorsOrders } from 'ducks/translators/types';
import type { FC } from 'react';
import { Rate } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { OrderStatus } from 'constants/orderStatuses';
import { NO_DATA } from 'constants/strings';
import { EditIcon, PlusIcon } from 'icons';
import EditRatesModal from '../EditTranslatorOrderRateModal';
import styles from './styles.module.scss';

type Props = {
  translatorOrder: TranslatorsOrders;
};

const OrderRatesCol: FC<Props> = ({ translatorOrder }) => {
  const { orderRatesAvg, orderStatus } = translatorOrder;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isEditMode = orderRatesAvg !== null;
  const showEditRateButton = orderStatus === OrderStatus.DONE;

  const handleOpenModal = () => setIsOpenModal(true);

  const handleCloseModal = () => setIsOpenModal(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.rateWrapper}>
        <span>{orderRatesAvg ?? NO_DATA}</span>
        {isEditMode && <Rate disabled allowHalf defaultValue={1} count={1} />}
      </div>
      {showEditRateButton && (
        <Button
          size="small"
          icon={isEditMode ? <EditIcon /> : <PlusIcon />}
          onClick={handleOpenModal}
        >
          {isEditMode ? (
            <FormattedMessage defaultMessage="Edytuj ocenę" />
          ) : (
            <FormattedMessage defaultMessage="Wystaw ocenę" />
          )}
        </Button>
      )}
      <EditRatesModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        translatorOrder={translatorOrder}
      />
    </div>
  );
};

export default OrderRatesCol;
