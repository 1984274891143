import { Route } from 'react-router-dom';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import ClientDetails from './views/ClientDetails/ClientDetails';
import ClientsTable from './views/ClientsTable/ClientsTable';

export enum Routes {
  CLIENTS = '/',
  CLIENT_DETAILS = '/clients/:clientId',
}

const Routing: React.FC = () => {
  return (
    <AnimatedRoutes pathLevelWatch={2}>
      <Route
        path={`${Routes.CLIENT_DETAILS.replace('/clients', '')}`}
        element={<ClientDetails />}
      />
      <Route path={`${Routes.CLIENTS}`} element={<ClientsTable />} />
    </AnimatedRoutes>
  );
};

export default Routing;
