import type { NotificationInstance } from 'antd/es/notification/interface';
import type { FC } from 'react';
import { Form, Input } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/ui/molecules/Modal/Modal';
import { useAddNoteMutation, useUpdateNoteMutation } from 'ducks/notes/service';
import { NotesCategory, type Note, type NoteType } from 'ducks/notes/types';
import { noteModalInputs, type NoteModalValues } from './config';

const { Item, useForm } = Form;

type Props = {
  objectId: number;
  isOpen: boolean;
  onClose: () => void;
  data?: Note | null;
  notify: NotificationInstance;
  object: NoteType;
  onSave?: () => Promise<void>;
  isSaveLoading?: boolean;
};

const NoteModal: FC<Props> = ({
  objectId,
  isOpen,
  onClose,
  data = null,
  notify,
  object,
  onSave,
  isSaveLoading = false,
}) => {
  const intl = useIntl();
  const [form] = useForm<NoteModalValues>();
  const [addNote, { isLoading: isAddLoading }] = useAddNoteMutation();
  const [updateNote, { isLoading: isUpdateLoading }] = useUpdateNoteMutation();
  const isLoading = isAddLoading || isUpdateLoading || isSaveLoading;
  const isEditingNote = !!data;
  const initialValues: NoteModalValues = {
    title: '',
    content: '',
    type: NotesCategory.GENERAL,
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleAddNote = async () => {
    const values = await form.validateFields().catch((err) => err);

    if (values?.errorFields?.length) return;

    try {
      await addNote({ ...values, objectId, object }).unwrap();

      await onSave?.();

      notify.success({
        message: <FormattedMessage defaultMessage="Notatka została dodana." />,
      });

      handleClose();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania notatki." />
        ),
      });
    }
  };

  const handleEditNote = async () => {
    if (!isEditingNote) return;

    const values = await form.validateFields().catch((err) => err);

    if (values?.errorFields?.length) return;

    try {
      await updateNote({
        ...values,
        objectId,
        noteId: data.id,
        object,
      }).unwrap();

      await onSave?.();

      notify.success({
        message: (
          <FormattedMessage defaultMessage="Notatka została edytowana." />
        ),
      });

      handleClose();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania notatki." />
        ),
      });
    }
  };

  const handleOnSubmit = () => {
    if (isLoading) return;

    if (isEditingNote) {
      handleEditNote();

      return;
    }

    handleAddNote();
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, isOpen, form]);

  return (
    <Modal
      title={
        isEditingNote ? (
          <FormattedMessage defaultMessage="Edytuj notatkę" />
        ) : (
          <FormattedMessage defaultMessage="Dodaj notatkę" />
        )
      }
      isLoading={isLoading}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={handleClose}
      okText={
        isEditingNote ? (
          <FormattedMessage defaultMessage="Edytuj" />
        ) : (
          <FormattedMessage defaultMessage="Dodaj" />
        )
      }
      disableFormStyles
    >
      <Form form={form} initialValues={initialValues} layout="vertical">
        {noteModalInputs(intl).map(
          ({ label, name, rules, component, additionalProps }) => (
            <Item
              key={name}
              label={label}
              name={name}
              rules={rules}
              {...additionalProps}
            >
              {component ?? <Input data-testid={`${name}Input`} />}
            </Item>
          ),
        )}
      </Form>
    </Modal>
  );
};

export default NoteModal;
