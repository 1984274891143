import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { TranslatorsPriceListItem } from 'ducks/translators/types';
import { Switch } from 'antd';
import { FormattedMessage } from 'react-intl';
import LanguageSelect from 'components/ui/atoms/LanguageSelect';

export type AddTranslationModalValues = Pick<
  TranslatorsPriceListItem,
  'langFrom' | 'langTo' | 'isCertified'
>;

export const initialValues: AddTranslationModalValues = {
  langFrom: '',
  langTo: '',
  isCertified: false,
};

export const formItems: FormItemRecord<AddTranslationModalValues>[] = [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Tłumaczenie z języka" />,
    name: 'langFrom',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz język" />,
      },
    ],
    component: <LanguageSelect allowClear={false} />,
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="Tłumaczenie na język" />,
    name: 'langTo',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz język" />,
      },
    ],
    component: <LanguageSelect allowClear={false} />,
  },
  {
    key: 3,
    label: <FormattedMessage defaultMessage="Przysięgły" />,
    name: 'isCertified',
    additionalItemProps: { valuePropName: 'checked' },
    component: <Switch />,
  },
];
