import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { InvoiceOverdue } from 'ducks/invoices/types';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { DayjsFormat } from 'constants/dayjsFormats';
import { NO_DATA } from 'constants/strings';
import OrderNumbersRecord from './components/OrderNumbersRecord';

type Props = {
  data: InvoiceOverdue;
};

export const formItems = ({
  data: {
    number,
    dateSent,
    dateDue,
    priceNetto,
    priceBrutto,
    paid,
    currentToPay,
    orders,
  },
}: Props): FormItemRecord<InvoiceOverdue>[] => {
  const dateSentValue = dateSent
    ? dayjs(dateSent).format(DayjsFormat.DATE)
    : NO_DATA;
  const dateDueValue = dateDue
    ? dayjs(dateDue).format(DayjsFormat.DATE)
    : NO_DATA;

  return [
    {
      key: 'number',
      label: <FormattedMessage defaultMessage="Numer faktury:" />,
      component: <strong data-testid="number">{number}</strong>,
    },
    {
      key: 'orders',
      label: <FormattedMessage defaultMessage="Numer zamówień:" />,
      component: <OrderNumbersRecord orders={orders} />,
    },
    {
      key: 'dateSent',
      label: <FormattedMessage defaultMessage="Data wystawienia:" />,
      component: <strong data-testid="dateSent">{dateSentValue}</strong>,
    },
    {
      key: 'dateDue',
      label: <FormattedMessage defaultMessage="Termin płatności:" />,
      component: <strong data-testid="dateDue">{dateDueValue}</strong>,
    },
    {
      key: 'priceNetto',
      label: <FormattedMessage defaultMessage="Kwota netto:" />,
      component: (
        <strong data-testid="priceNetto">
          {priceNetto ? <CurrencyFormatter value={priceNetto} /> : NO_DATA}
        </strong>
      ),
    },
    {
      key: 'priceBrutto',
      label: <FormattedMessage defaultMessage="Kwota brutto:" />,
      component: (
        <strong data-testid="priceBrutto">
          {priceBrutto ? <CurrencyFormatter value={priceBrutto} /> : NO_DATA}
        </strong>
      ),
    },
    {
      key: 'paid',
      label: <FormattedMessage defaultMessage="Zapłacono:" />,
      component: (
        <strong data-testid="paid">
          <CurrencyFormatter value={paid} />
        </strong>
      ),
    },
    {
      key: 'currentToPay',
      label: <FormattedMessage defaultMessage="Pozostało:" />,
      component: (
        <strong data-testid="currentToPay">
          <CurrencyFormatter value={currentToPay} />
        </strong>
      ),
    },
  ];
};
