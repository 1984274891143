import type { VariantServiceRow } from '../../config';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import FormItem from 'components/ui/atoms/FormItem';
import { ServiceAttributeNames } from 'constants/services';
import { NO_DATA } from 'constants/strings';
import { useAppSelector } from 'ducks/store';
import { getPreorderServiceInitialValue } from 'ducks/view/preorders/utils';

const ATTRIBUTE_NAME = ServiceAttributeNames.PACKAGES_NUMBER;
const MIN_PACKAGES_NUMBER = 1;
const MAX_PACKAGES_NUMBER = 1000;

type Props = { row: VariantServiceRow };

const PackagesNumberInput: FC<Props> = ({
  row: { id, isActive, preorderVariantServiceValues },
}) => {
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
  const initialValue = getPreorderServiceInitialValue(
    preorderVariantServiceValues,
    ATTRIBUTE_NAME,
  );

  return isEditable ? (
    <FormItem
      name={[id as number, ['attributeName', ATTRIBUTE_NAME].join(',')]}
      component={
        <DecimalInputNumber
          data-testid="packagesNumberInput"
          min={MIN_PACKAGES_NUMBER}
          max={MAX_PACKAGES_NUMBER}
          allowNullable={false}
        />
      }
      rules={[
        {
          required: isActive,
          message: <FormattedMessage defaultMessage="Podaj liczbę" />,
        },
      ]}
      additionalItemProps={{
        initialValue,
      }}
    />
  ) : (
    <span data-testid="packagesNumber">{initialValue ?? NO_DATA}</span>
  );
};

export default PackagesNumberInput;
