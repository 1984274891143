import type { FC } from 'react';
import styles from './styles.module.scss';

type Option = { value: number; label: string };

type Props = {
  value: number;
  label: string;
  isSelected: boolean;
  onSelect: (option: Option) => void;
};

const ClientSelectorOption: FC<Props> = ({
  value,
  label,
  isSelected,
  onSelect,
}) => {
  const handleSelect = () => onSelect({ value, label });

  return (
    <button
      className={styles.option}
      type="button"
      value={value}
      onClick={handleSelect}
      style={
        isSelected
          ? {
              backgroundColor: '#e6f4ff',
            }
          : {}
      }
    >
      {label}
    </button>
  );
};

export default ClientSelectorOption;
