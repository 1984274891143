import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { GusReturnProps } from 'components/ui/atoms/GusButton';
import type { UpdateClientCorrespondenceRequest } from 'ducks/clients/types';
import { FormattedMessage } from 'react-intl';
import CountrySelect from 'components/ui/atoms/CountrySelect';
import GusButton from 'components/ui/atoms/GusButton';
import { POLAND_COUNTRY_CODE } from 'constants/countryAndLanguageCodes';
import {
  validateNipRule,
  validatePostalCodeRule,
} from 'utilities/antdFormRules';

export type EditCorrespondenceFormValues = Omit<
  UpdateClientCorrespondenceRequest,
  'clientId'
>;

export type EditCorrespondenceFormItem =
  FormItemRecord<EditCorrespondenceFormValues>;

type Args = {
  nip: string;
  handleCheckNip: (data: GusReturnProps) => void;
  country: string | null;
};

export const editCorrespondenceFormItems = ({
  nip,
  handleCheckNip,
  country,
}: Args): EditCorrespondenceFormItem[] => [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Nazwa:" />,
    name: 'name',
  },
  {
    key: 6,
    label: <FormattedMessage defaultMessage="Kraj:" />,
    name: 'country',
    component: <CountrySelect />,
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="NIP:" />,
    name: 'nip',
    rules: [
      validateNipRule({ isInternational: country !== POLAND_COUNTRY_CODE }),
    ],
  },
  {
    key: 6,
    component: <GusButton onSuccessCallback={handleCheckNip} value={nip} />,
    hidden: country !== POLAND_COUNTRY_CODE,
  },
  {
    key: 3,
    label: <FormattedMessage defaultMessage="Ulica:" />,
    name: 'address',
  },
  {
    key: 4,
    label: <FormattedMessage defaultMessage="Miejscowość:" />,
    name: 'city',
  },
  {
    key: 5,
    label: <FormattedMessage defaultMessage="Kod pocztowy:" />,
    name: 'postCode',
    rules: [validatePostalCodeRule()],
  },
];
