import { FileTranslationType } from 'constants/fileTranslation';
import styles from './styles.module.scss';

export const generateTranslatorColor = (indicator: number) => {
  switch (indicator) {
    case 2:
      return styles.greenTranslator;
    case 3:
      return styles.yellowTranslator;
    case 4:
      return styles.redTranslator;
    default:
      return styles.defaultTranslator;
  }
};

export const generateTypeLabel = (type: FileTranslationType) => {
  switch (type) {
    case FileTranslationType.CORRECTION:
      return 'K';
    case FileTranslationType.NORMAL:
      return 'Z';
    case FileTranslationType.SWORN:
      return 'P';
    default:
      return '';
  }
};
