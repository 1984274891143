import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPreorderVariantsQuery } from 'ducks/preorders/service';
import { usePreordersServices } from 'views/Preorders/usePreordersServices';

export const usePreorderSendServices = () => {
  const { id = '' } = useParams();
  const preorderId = Number(id);
  const { preorderData, isPreorderFetching, isPreorderEmpty, refetch } =
    usePreordersServices();
  const {
    data: preorderVariantsData = [],
    isLoading: isPreorderVariantsLoading,
  } = useGetPreorderVariantsQuery(
    {
      id: preorderId,
    },
    { skip: !preorderData },
  );
  const isLoading = isPreorderFetching || isPreorderVariantsLoading;
  const isPreorderVariantsEmpty = !preorderVariantsData.length && !isLoading;

  const value = useMemo(
    () => ({
      preorderData,
      isPreorderFetching,
      isPreorderEmpty,
      refetch,
      preorderVariantsData,
      isPreorderVariantsLoading,
      isPreorderVariantsEmpty,
      isLoading,
    }),
    [
      preorderData,
      isPreorderFetching,
      isPreorderEmpty,
      refetch,
      preorderVariantsData,
      isPreorderVariantsLoading,
      isPreorderVariantsEmpty,
      isLoading,
    ],
  );

  return value;
};
