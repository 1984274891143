import type { SidenavMenuItem } from '../../config';
import type { FC } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from '../../styles.module.scss';

interface Props extends SidenavMenuItem {
  onClick: () => void;
}

const SidenavItem: FC<Props> = ({
  children,
  onClick,
  label,
  icon,
  url,
  subSection,
}) => {
  if (!children && !url && !subSection) {
    return <div className="menu-item-header ant-menu-item ">{label}</div>;
  }

  if (subSection)
    return (
      <>
        {icon && (
          <span className={classNames('icon', styles.icon)}>{icon}</span>
        )}
        {label && <span className="label">{label}</span>}
      </>
    );

  return url ? (
    <NavLink to={url} onClick={onClick}>
      {icon && <span className={classNames('icon', styles.icon)}>{icon}</span>}
      {label && <span className="label">{label}</span>}
    </NavLink>
  ) : (
    <div>{children}</div>
  );
};

export default SidenavItem;
