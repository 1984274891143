import { Col, Row } from 'antd';
import { useRef, useEffect } from 'react';
import { useRefHeightContext } from '../../useRefHeightContext';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import CatCard from './CatCard';
import LanguagesCard from './LanguagesCard';
import SelectedTypesCard from './SelectedTypesCard';
import styles from './styles.module.scss';

const SkillsCard = () => {
  const { isTranslatorFetching } = useTranslatorProfileServices();
  const ref = useRef(null);
  const { setSectionHeight } = useRefHeightContext();

  useEffect(() => {
    if (isTranslatorFetching || !ref.current) return;

    setSectionHeight(
      (ref.current as HTMLElement).getBoundingClientRect().height,
    );
  }, [isTranslatorFetching, setSectionHeight]);

  return (
    <Col md={8}>
      <Row ref={ref} align="top" className={styles.row}>
        <LanguagesCard />
        <CatCard />
        <SelectedTypesCard />
      </Row>
    </Col>
  );
};

export default SkillsCard;
