import { Skeleton } from 'antd';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { createArray } from 'utilities/array';
import { useInvoiceAddServices } from 'views/Invoices/view/InvoiceAdd';
import styles from './styles.module.scss';

const InvoiceItemsSumPrices = () => {
  const { invoiceData, isInvoiceDataFetching } = useInvoiceAddServices();

  return (
    <div className={styles.wrapper}>
      {isInvoiceDataFetching &&
        createArray(2).map((key) => (
          <Skeleton
            key={key}
            active
            paragraph={false}
            style={{ width: '120px' }}
          />
        ))}
      {invoiceData && !isInvoiceDataFetching && (
        <>
          <strong>
            <FormattedMessage
              defaultMessage="Suma netto: {price}"
              values={{
                price: <CurrencyFormatter value={invoiceData.sumPriceNetto} />,
              }}
            />
          </strong>
          <strong>
            <FormattedMessage
              defaultMessage="Suma brutto: {price}"
              values={{
                price: <CurrencyFormatter value={invoiceData.sumPriceBrutto} />,
              }}
            />
          </strong>
        </>
      )}
    </div>
  );
};

export default InvoiceItemsSumPrices;
