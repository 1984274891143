/* eslint-disable no-nested-ternary */
import type { FC } from 'react';
import { Spin, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useGetUserDetailsQuery } from 'ducks/users/service';
import styles from './styles.module.scss';

const { Text } = Typography;

interface Props {
  activeEditorId: number;
}

const PadlockPopoverContent: FC<Props> = ({ activeEditorId }) => {
  const { data, isLoading } = useGetUserDetailsQuery({
    id: activeEditorId,
  });

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Spin className={styles.spin} />
      ) : data ? (
        <Text>
          {data.firstName} {data.lastName}
        </Text>
      ) : (
        <Text>
          <FormattedMessage defaultMessage="Nie udało się znaleźć użytkownika." />
        </Text>
      )}
    </div>
  );
};

export default PadlockPopoverContent;
