import type { EditInvoiceValues } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { GusReturnProps } from 'components/ui/atoms/GusButton';
import type { Client } from 'ducks/clients/types';
import type { FC } from 'react';
import { Form } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal/Modal';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import { useUpdateClientInvoiceMutation } from 'ducks/clients/service';
import { DefaultValue } from 'ducks/types';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';
import { editInvoiceFormItems } from './config';

const { useForm, useWatch } = Form;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data: Client;
  notify: NotificationInstance;
};

const EditInvoiceModal: FC<Props> = ({ isOpen, onClose, notify, data }) => {
  const [form] = useForm<EditInvoiceValues>();
  const [updateClientInvoice, { isLoading }] = useUpdateClientInvoiceMutation();
  const isCompany = useWatch('isCompany', form);
  const nip = useWatch('invoiceNip', form) ?? '';
  const invoiceCountry = useWatch('invoiceCountry', form);

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = () => {
    if (isLoading) return;

    mutationFunctionWithForm<void, EditInvoiceValues>({
      form,
      mutationFn: async (values) => {
        await updateClientInvoice({
          ...values,
          clientId: data.id,
          invoiceCountry: values.invoiceCountry ?? '',
        }).unwrap();
      },
      onSuccess: () => {
        notify.success({
          message: (
            <FormattedMessage defaultMessage="Dane rozliczeniowe zostały edytowane." />
          ),
        });
        onClose();
      },
      onError: () => {
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania danych rozliczeniowych." />
          ),
        });
      },
    });
  };

  const handleCheckNip = ({
    nip: invoiceNip,
    name,
    city,
    post_code: invoicePostCode,
    street,
    addressNumbers,
  }: GusReturnProps) =>
    form.setFieldsValue({
      invoiceNip,
      invoiceName: name,
      invoiceCity: city,
      invoicePostCode,
      invoiceAddress: `${street} ${addressNumbers.join(', ')}`,
    });

  const initialValues = useMemo(
    (): EditInvoiceValues => ({
      ...data,
      invoiceNip: data.invoiceNip ?? '',
      invoiceType: data.invoiceType ?? DefaultValue.SECOND,
      invoiceAuto: data.invoiceAuto ?? false,
      isDebtor: data.isDebtor ?? false,
      isCollectiveInvoices: data.isCollectiveInvoices ?? false,
      invoiceCountry: data.invoiceCountry ?? LanguageCode.POLAND,
      invoiceVatRate: 23,
    }),
    [data],
  );

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Edytuj dane rozliczeniowe" />}
      isLoading={isLoading}
      open={isOpen}
      onOk={handleSubmit}
      onCancel={handleOnClose}
      okText={<FormattedMessage defaultMessage="Zapisz" />}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        {editInvoiceFormItems({
          isCompany,
          nip,
          handleCheckNip,
          invoiceCountry,
        }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default EditInvoiceModal;
