import type { SelectProps } from 'antd';
import type { OrderFilesAndSericesValues } from 'components/ui/organisms/Tables/OrderFilesAndServicesTable';
import type { FC } from 'react';
import { Select, Tag } from 'antd';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import { NO_DATA } from 'constants/strings';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { useGetTranslatorsCatsQuery } from 'ducks/translators/service';
import { updateOrderFiles, type OrderFileLangState } from 'ducks/view/orders';
import SkeletonColumnPlaceholder from '../SkeletonColumnPlaceholder';
import styles from './styles.module.scss';

type Props = {
  orderFileId: number;
  orderFileLangId: OrderFileLangState['id'];
} & Pick<OrderFileLangState, 'catId'>;

const CatColumn: FC<Props> = memo(({ orderFileId, orderFileLangId, catId }) => {
  const dispatch = useAppDispatch();
  const isEditable = useAppSelector(({ orders }) => orders.isEditable);
  const { data = [], isLoading } = useGetTranslatorsCatsQuery();
  const nullOption = {
    value: null,
    label: <FormattedMessage defaultMessage="Brak" />,
  };

  const handleOnChange = (value: OrderFileLangState['catId']) =>
    dispatch(
      updateOrderFiles({
        field: {
          [orderFileId]: {
            [orderFileLangId]: {
              catId: value,
            },
          },
        },
      }),
    );

  const catOptions = useMemo((): NonNullable<SelectProps['options']> => {
    const mainCats = data.filter(({ isMain }) => isMain);

    return mainCats.map(({ id, name }) => ({ value: id, label: name }));
  }, [data]);

  if (isLoading) {
    return <SkeletonColumnPlaceholder />;
  }

  if (!isEditable) {
    const catOption = catOptions.find(({ value }) => value === catId);

    return catOption ? (
      <Tag>{catOption.label}</Tag>
    ) : (
      <span className={styles.cell}>{NO_DATA}</span>
    );
  }

  return (
    <FormItem<OrderFilesAndSericesValues>
      component={
        <Select
          options={[...catOptions, nullOption]}
          onChange={handleOnChange}
          value={catId}
          loading={isLoading}
          placeholder={<FormattedMessage defaultMessage="Wybierz CAT" />}
        />
      }
    />
  );
});

export default CatColumn;
