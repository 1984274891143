import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetTranslatorDetailsQuery } from 'ducks/translators/service';

export const useTranslatorProfileServices = () => {
  const { id = '' } = useParams();
  const translatorId = Number(id);
  const { data: translatorData, isFetching: isTranslatorFetching } =
    useGetTranslatorDetailsQuery({
      translatorId,
    });
  const isTranslatorEmpty = !translatorData && !isTranslatorFetching;

  const value = useMemo(
    () => ({
      translatorId,
      translatorData,
      isTranslatorFetching,
      isTranslatorEmpty,
    }),
    [translatorId, translatorData, isTranslatorFetching, isTranslatorEmpty],
  );

  return value;
};
