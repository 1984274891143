import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { InvoiceClient, InvoiceOverdue } from 'ducks/invoices/types';
import { FormattedMessage } from 'react-intl';
import CountryName from 'components/ui/atoms/CountryName';
import { NO_DATA } from 'constants/strings';

type Props = {
  clientData: InvoiceClient | null;
};

export const formItems = ({
  clientData,
}: Props): FormItemRecord<InvoiceOverdue>[] => {
  const name = clientData?.name ?? NO_DATA;
  const nip = clientData?.nip ?? NO_DATA;
  const street = clientData?.street ?? NO_DATA;
  const postCode = clientData?.postCode ?? NO_DATA;
  const city = clientData?.city ?? NO_DATA;

  return [
    {
      key: 'name',
      label: <FormattedMessage defaultMessage="Nazwa firmy:" />,
      component: <strong data-testid="name">{name}</strong>,
    },
    {
      key: 'nip',
      label: <FormattedMessage defaultMessage="NIP:" />,
      component: <strong data-testid="nip">{nip}</strong>,
    },
    {
      key: 'street',
      label: <FormattedMessage defaultMessage="Ulica numer:" />,
      component: <strong data-testid="street">{street}</strong>,
    },
    {
      key: 'postCode',
      label: <FormattedMessage defaultMessage="Kod pocztowy:" />,
      component: <strong data-testid="postCode">{postCode}</strong>,
    },
    {
      key: 'city',
      label: <FormattedMessage defaultMessage="Miasto:" />,
      component: <strong data-testid="city">{city}</strong>,
    },
    {
      key: 'country',
      label: <FormattedMessage defaultMessage="Państwo:" />,
      component: (
        <strong data-testid="country">
          <CountryName countryCode={clientData?.country} />
        </strong>
      ),
    },
  ];
};
