import { Col } from 'antd';
import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import AllCurrenciesInfo from 'components/ui/molecules/AllCurrenciesInfo';
import List from 'components/ui/molecules/List';
import { MIN_REPETITION_VALUE } from 'constants/formInputLengths';
import { sharedSummaryListItems } from 'constants/summaryList';
import { useAppSelector } from 'ducks/store';
import { useCalcTranslations } from 'hooks/useCalcTranslations';
import {
  calcOrderServicesPriceNettoTotal,
  calculateOrderFilesTotal,
} from 'utilities/calcOrderTotals';
import { calcDiscount } from 'utilities/calcPrice';
import { useOrderAssignServices } from 'views/Orders/views/OrderAssign';
import { summaryListItems } from './config';

const Summary = () => {
  const { orderFiles, orderServices } = useAppSelector(
    (state) => ({
      orderFiles: state.orders.orderFiles,
      orderServices: state.orders.orderServices,
    }),
    shallowEqual,
  );
  const { orderData, isOrderLoading } = useOrderAssignServices();
  const { estimatedPrice, isLoading: isEstimatedPriceLoading } =
    useCalcTranslations({
      orderFiles,
    });
  const repetition = orderData?.repetition ?? MIN_REPETITION_VALUE;

  const { translationsTotal, specialTotal, correctionTotal } = useMemo(
    () => ({
      translationsTotal: calculateOrderFilesTotal(
        orderFiles,
        ({ price }) => price,
      ),
      specialTotal: calculateOrderFilesTotal(
        orderFiles.filter(({ isSpecial }) => isSpecial),
        ({ pages, priceSpecialNetto, discount }) =>
          calcDiscount(pages * priceSpecialNetto, discount),
      ),
      correctionTotal: calculateOrderFilesTotal(
        orderFiles.filter(({ isCorrection }) => isCorrection),
        ({ pages, priceCorrectionNetto, discount }) =>
          calcDiscount(pages * priceCorrectionNetto, discount),
      ),
    }),
    [orderFiles],
  );

  const { graphicTotal, shipmentTotal, preparatoryTotal, additionalTotal } =
    useMemo(
      () => calcOrderServicesPriceNettoTotal(orderServices),
      [orderServices],
    );

  const total = useMemo(() => {
    const servicesTotal = orderServices.reduce(
      (acc, { priceNetto }) => acc + priceNetto,
      0,
    );

    return translationsTotal + servicesTotal;
  }, [orderServices, translationsTotal]);

  return (
    <Col xs={24} sm={12}>
      <List
        items={[
          ...summaryListItems({
            repetition,
            estimatedPrice,
            isEstimatedPriceLoading,
            timeRealizationTotal: orderData?.timeRealization,
          }),
          ...sharedSummaryListItems({
            translationsTotal,
            correctionTotal,
            specialTotal,
            graphicTotal,
            shipmentTotal,
            preparatoryTotal,
            additionalTotal,
            total,
          }),
        ]}
        isLoading={isOrderLoading}
        withoutMargin
      />
      <AllCurrenciesInfo price={total} />
    </Col>
  );
};

export default Summary;
