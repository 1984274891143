import type { TranslatorsOrders } from 'ducks/translators/types';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

type Props = Pick<
  TranslatorsOrders,
  | 'orderFile'
  | 'orderFileLangFrom'
  | 'orderFileLangTo'
  | 'orderTranslationFiles'
>;

const EditTranslatorOrderRateModalSubtitle: FC<Props> = ({
  orderFile,
  orderFileLangFrom,
  orderFileLangTo,
  orderTranslationFiles,
}) => {
  return (
    <div className={styles.wrapper}>
      <span>
        <FormattedMessage
          defaultMessage="Plik źródłowy: {sourceFile}"
          values={{ sourceFile: orderFile.filename }}
        />
      </span>
      <span>
        <FormattedMessage
          defaultMessage="Para językowa: {langFrom} - {langTo}"
          values={{ langFrom: orderFileLangFrom, langTo: orderFileLangTo }}
        />
      </span>
      <span>
        <FormattedMessage
          defaultMessage="Przetłumaczone pliki: {files}"
          values={{
            files: orderTranslationFiles
              .map(({ file }) => file.filename)
              .join(', '),
          }}
        />
      </span>
    </div>
  );
};

export default EditTranslatorOrderRateModalSubtitle;
