import type { UpdatePreorderValues } from '../../config';
import type { FormInstance } from 'antd';
import type { GusReturnProps } from 'components/ui/atoms/GusButton';
import type { FC } from 'react';
import { Form } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import { PreorderStatus } from 'constants/preorder';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setPreorderVatRate } from 'ducks/view/preorders';
import { usePreorderDetailsServices } from '../../usePreorderDetailsServices';
import { formItems } from './config';

const { useWatch } = Form;

type Props = {
  form: FormInstance<UpdatePreorderValues>;
};

const InvoiceSection: FC<Props> = ({ form }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
  const { preorderData, isPreorderFetching } = usePreorderDetailsServices();
  const isNotInRealization =
    preorderData?.status !== PreorderStatus.IN_REALIZATION;
  const isCompany = useWatch('isCompany', form);
  const country = useWatch('country', form);
  const nip = useWatch('nip', form) ?? '';

  const handleCheckNipCallback = ({
    name,
    city,
    post_code: postCode,
    street,
    addressNumbers,
  }: GusReturnProps) =>
    form.setFieldsValue({
      name,
      city,
      postCode,
      address: `${street} ${addressNumbers.join(', ')}`,
    });
  const handleSetPreorderVatRate = (vatRate: number) =>
    dispatch(setPreorderVatRate(vatRate));

  useEffect(() => {
    if (!isEditable || isCompany) return;

    form.setFieldValue('invoiceAuto', false);
  }, [isCompany, isEditable, form]);

  return (
    <CardCommon
      data-testid="invoiceSection"
      title={
        <FormattedMessage defaultMessage="Dane do faktury bieżącego zamówienia" />
      }
      isLoading={isPreorderFetching}
      xs={24}
      md={12}
    >
      <div className="gridForm">
        {preorderData &&
          formItems({
            intl,
            data: preorderData,
            nip,
            isCompany,
            country,
            handleCheckNipCallback,
            handleSetPreorderVatRate,
            isEditable: isEditable && isNotInRealization,
          }).map(({ key, ...props }) => <FormItem key={key} {...props} />)}
      </div>
    </CardCommon>
  );
};

export default InvoiceSection;
