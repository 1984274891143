import type { EditableColumnType } from 'components/ui/molecules/EditableTable/config';
import type { TableRow } from 'components/ui/molecules/Table';
import type { ContactPerson } from 'ducks/clients/types';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { validatePhoneNumberRule } from 'utilities/antdFormRules';

export type ContactPersonRow = TableRow & ContactPerson;

type Args = {
  isSms: boolean | null;
};

export const columns = ({
  isSms,
}: Args): EditableColumnType<ContactPersonRow> => [
  {
    title: <FormattedMessage defaultMessage="Nazwa" />,
    key: 'name',
    dataIndex: 'name',
    editable: true,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj nazwę" />,
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Email" />,
    key: 'email',
    dataIndex: 'email',
    availableCopy: true,
    editable: true,
    rules: [
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Telefon" />,
    key: 'phone',
    dataIndex: 'phone',
    availableCopy: true,
    editable: true,
    rules: [
      validatePhoneNumberRule(),
      isSms
        ? {
            required: true,
            message: (
              <FormattedMessage defaultMessage="Przy zaznaczeniu opcji SMS, telefon jest wymagany" />
            ),
          }
        : {},
    ],
  },
  {
    title: <FormattedMessage defaultMessage="SMS" />,
    key: 'isSms',
    dataIndex: 'isSms',
    editable: true,
    inputType: 'checkbox',
    render: (_, { isSms: smsChecked }) => <Checkbox checked={smsChecked} />,
  },
];
