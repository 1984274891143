import type { SpaceProps } from 'antd';
import type { Translator } from 'ducks/translators/types';
import type { Key, ReactElement } from 'react';
import { Button, Rate, Space, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import LanguageFlag from 'components/ui/atoms/LanguageFlag';
import TruncateText from 'components/ui/atoms/TruncateText';
import { FormOfSettlement } from 'constants/formOfSettlements';
import { EditIcon } from 'icons';
import { getDayAndMonth } from 'utilities/date';
import { getTranslatorName } from 'utilities/translator';
import styles from './styles.module.scss';

const { Text } = Typography;

export type TranslatorHeaderCol = {
  key: Key;
  firstRow: ReactElement | string;
  secondRow: ReactElement | string;
  align?: SpaceProps['align'];
};

type TranslatorHeaderColsProps = {
  data: Translator | undefined;
  openEditVacationModal: () => void;
};

export const translatorHeaderCols = ({
  data,
  openEditVacationModal,
}: TranslatorHeaderColsProps): TranslatorHeaderCol[] => {
  const isInvoice = data?.formOfSettlement === FormOfSettlement.INVOICE;
  const translatorName = getTranslatorName({
    ...data,
  });
  const invoiceDueDate = data?.invoiceDueDate;
  const vacationFrom = data?.vacationFrom;
  const vacationTo = data?.vacationTo;
  const langNative = data?.langNative;
  const formattedVacationFrom = vacationFrom
    ? getDayAndMonth(vacationFrom).join('.')
    : '';
  const formattedVacationTo = vacationTo
    ? getDayAndMonth(vacationTo).join('.')
    : '';

  return [
    {
      key: 1,
      firstRow: (
        <Space>
          <TruncateText ellipsis>{translatorName}</TruncateText>
          <LanguageFlag languageCode={langNative ?? ''} />
          <Text ellipsis type="secondary">
            {isInvoice ? (
              <FormattedMessage
                defaultMessage="FV-{invoiceDueDate}"
                values={{ invoiceDueDate }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="R-{invoiceDueDate}"
                values={{ invoiceDueDate }}
              />
            )}
          </Text>
        </Space>
      ),
      secondRow: (
        <div className={styles.vacationComponentWrapper}>
          <Text>
            <FormattedMessage defaultMessage="Urlop" />:{' '}
          </Text>
          <Button type="text" size="small" onClick={openEditVacationModal}>
            {vacationFrom && vacationTo
              ? `${formattedVacationFrom} - ${formattedVacationTo}`
              : '---'}
            <EditIcon />
          </Button>
        </div>
      ),
      align: 'start',
    },
    {
      key: 2,
      firstRow: <FormattedMessage defaultMessage="Ogólna ocena" />,
      secondRow: data?.rateAvg ? (
        <Space>
          <Text>{data.rateAvg}/5</Text>
          <Rate disabled allowHalf defaultValue={1} count={1} />
        </Space>
      ) : (
        '---'
      ),
    },
    {
      key: 3,
      firstRow: <FormattedMessage defaultMessage="Liczba zleceń" />,
      secondRow: (
        <Text type="success" data-testid="ordersTotal">
          {data?.orders.done ?? '---'} /{' '}
          <span className={styles.allOrdersStat}>
            {data?.orders.all ?? '---'}
          </span>
        </Text>
      ),
    },
    {
      key: 4,
      firstRow: <FormattedMessage defaultMessage="Liczba zleceń w toku" />,
      secondRow: (
        <Text data-testid="ordersInProgres">
          {data?.orders.inProgress ?? '---'}
        </Text>
      ),
    },
    {
      key: 5,
      firstRow: <FormattedMessage defaultMessage="Reklamacje" />,
      secondRow: <Text>{data?.orders.complaints ?? '---'}</Text>,
    },
  ];
};
