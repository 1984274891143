import type {
  OrderFileLangState,
  OrderFileState,
  OrderServiceState,
} from 'ducks/view/orders';
import { ServiceType } from 'constants/services';

type CalcProps = OrderFileLangState & Omit<OrderFileState, 'orderFileLangs'>;

export const calculateOrderFilesTotal = (
  orderFiles: OrderFileState[],
  calc: (props: CalcProps) => number,
) => {
  const total = orderFiles.reduce((acc, { orderFileLangs, ...orderFile }) => {
    const innerSum = orderFileLangs.reduce(
      (innerAcc, orderLang) => innerAcc + calc({ ...orderLang, ...orderFile }),
      0,
    );

    return acc + innerSum;
  }, 0);

  return Number(total.toFixed(2));
};

export const calcOrderServicesPriceNettoTotal = (
  orderServiceState: OrderServiceState[],
) => {
  const calcTotalServiceByType = (serviceType: ServiceType) =>
    orderServiceState
      .filter(({ type }) => type === serviceType)
      .reduce((acc, { priceNetto }) => acc + priceNetto, 0);

  const graphicTotal = calcTotalServiceByType(ServiceType.GRAPHIC_SERVICE);
  const additionalTotal = calcTotalServiceByType(ServiceType.ADDITIONAL);
  const shipmentTotal = calcTotalServiceByType(ServiceType.SHIPMENT);
  const preparatoryTotal = calcTotalServiceByType(ServiceType.PREPARATORY);
  const serviceTotal =
    graphicTotal + additionalTotal + shipmentTotal + preparatoryTotal;

  return {
    graphicTotal,
    additionalTotal,
    shipmentTotal,
    serviceTotal,
    preparatoryTotal,
  };
};
