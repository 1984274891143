import type { ColProps } from 'antd';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Notes from 'components/ui/organisms/Notes';
import { NoteType } from 'ducks/notes/types';
import { useUnblockPreorder } from 'views/Preorders/useUnblockPreorder';
import { usePreorderDetailsServices } from 'views/Preorders/views/PreorderDetails';

type Props = {
  notify: NotificationInstance;
  colProps?: ColProps;
};

const PreorderNotesSection: FC<Props> = ({ notify, colProps }) => {
  const { id: preorderId = '' } = useParams();
  const { preorderData, isPreorderFetching } = usePreorderDetailsServices();
  const { handleUnblock, isLoading: isUnblockLoading } = useUnblockPreorder({
    notify,
  });
  const isLoading = isPreorderFetching || isUnblockLoading;

  return (
    <Col data-testid="notesSection" xs={24} md={24} lg={12} {...colProps}>
      <Notes
        notify={notify}
        objectId={Number(preorderId)}
        noteType={NoteType.PREORDERS}
        title={<FormattedMessage defaultMessage="Notatki do zlecenia" />}
        isLoading={isLoading}
        skip={!preorderData}
        onSave={handleUnblock}
        isSaveLoading={isUnblockLoading}
      />
    </Col>
  );
};

export default PreorderNotesSection;
