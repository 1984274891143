import type { FilterBy } from '../../config';
import type { FC } from 'react';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import LanguageSelect from 'components/ui/atoms/LanguageSelect';
import styles from './styles.module.scss';

interface Props {
  filterBy: FilterBy;
  onFilterByChange: (filterBy: FilterBy) => void;
}

const PriceListDetailsToolbar: FC<Props> = ({ filterBy, onFilterByChange }) => {
  return (
    <Space className={styles.toolbar} wrap>
      <LanguageSelect
        mode="multiple"
        value={filterBy.langs}
        onChange={(value) => onFilterByChange({ langs: value as string[] })}
        style={{ width: 250 }}
        placeholder={<FormattedMessage defaultMessage="Język" />}
        maxTagCount="responsive"
        allowClear
      />
    </Space>
  );
};

export default PriceListDetailsToolbar;
