import type { InvoiceDocumentRow } from './config';
import type { GetInvoicesFilter, GetInvoicesSort } from 'ducks/invoices/types';
import { Space } from 'antd';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import Table from 'components/ui/molecules/Table';
import { useGetInvoicesQuery } from 'ducks/invoices/service';
import useTablePagination from 'hooks/useTablePagination';
import { PlusIcon } from 'icons';
import FinanceDocumentListToolbar from './components/FinanceDocumentListToolbar';
import IssueInvoiceModal from './components/IssueInvoiceModal';
import { columns, initialInvoiceDocumentsFilters } from './config';

const FinanceDocumentList = () => {
  const intl = useIntl();
  const {
    page,
    handlePageChange,
    searchValue,
    handleSearchValue,
    filter,
    filterBy,
    handleFilterBy,
    sortBy,
    handleTableChange,
  } = useTablePagination<InvoiceDocumentRow, GetInvoicesFilter>({
    initialFilters: initialInvoiceDocumentsFilters,
  });
  const sort = sortBy?.order && {
    [sortBy.columnKey as keyof GetInvoicesSort]: sortBy.order,
  };
  const { data, isFetching } = useGetInvoicesQuery({
    page,
    searchTerm: searchValue,
    filter,
    sort,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pageSize = data?.perPage;
  const total = isFetching ? 0 : data?.total;

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  const modifiedData = useMemo(
    (): InvoiceDocumentRow[] =>
      (data?.data ?? []).map((document) => ({ ...document, key: document.id })),
    [data],
  );

  return (
    <Space className="layout-content mb-24 width-100" direction="vertical">
      <FinanceDocumentListToolbar
        searchValue={searchValue}
        onSearchValueChange={handleSearchValue}
        filterBy={filterBy}
        onFilterByChange={handleFilterBy}
      />
      <Table
        cardTitle={<FormattedMessage defaultMessage="Lista dokumentów" />}
        cardTitleExtra={
          <Button icon={<PlusIcon />} type="primary" onClick={handleOpenModal}>
            <FormattedMessage defaultMessage="Wystaw FV" />
          </Button>
        }
        data={modifiedData}
        columns={columns({ intl })}
        isLoading={isFetching}
        onChange={handleTableChange}
        pagination={{
          current: page,
          onChange: handlePageChange,
          pageSize,
          total,
        }}
      />
      <IssueInvoiceModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </Space>
  );
};

export default FinanceDocumentList;
