import type { SorterResult } from 'antd/lib/table/interface';
import { isEmpty } from 'lodash';
import { useState, useCallback } from 'react';
import useDebounce from './useDebounce';

const MIN_SEARCH_VALUE = 2;

type Props<TFilter> = {
  initialFilters?: TFilter;
};

/**
 * Custom hook for managing table pagination, search, filtering, and sorting.
 *
 * @returns - An object containing the state and functions for managing
 * table pagination, search, filtering, and sorting.
 */
const useTablePagination = <TRow, TFilter>({
  initialFilters,
}: Props<TFilter> = {}) => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterBy, setFilterBy] = useState<TFilter>(
    initialFilters ?? ({} as TFilter),
  );
  const [sortBy, setSortBy] = useState<SorterResult<TRow> | null>(null);
  const searchValueDebounced = useDebounce(searchValue, 400);
  const isMinSearchValue = searchValueDebounced.length >= MIN_SEARCH_VALUE;
  const searchTerm = isMinSearchValue ? searchValueDebounced : '';
  const filter = JSON.parse(
    useDebounce(JSON.stringify(filterBy), 400),
  ) as TFilter;

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleSearchValue = useCallback(
    (value: string) => {
      setSearchValue(value);
      handlePageChange(1);
    },
    [handlePageChange],
  );

  const handleFilterBy = useCallback(
    (newFilter: Partial<TFilter>) => {
      setFilterBy((prev) => ({ ...prev, ...newFilter }));
      handlePageChange(1);
    },
    [handlePageChange],
  );

  const handleTableChange = useCallback(
    (
      _pagination: unknown,
      _filters: unknown,
      sorter: SorterResult<TRow> | SorterResult<TRow>[],
    ) => {
      const sortOption = Array.isArray(sorter) ? sorter[0] : sorter;

      if (
        sortOption.columnKey === sortBy?.columnKey &&
        sortOption.order === sortBy?.order
      ) {
        return;
      }

      if (!isEmpty(sortOption)) {
        setSortBy(sortOption);
        handlePageChange(1);
        return;
      }

      if (sortOption.order === undefined) {
        setSortBy(null);
        handlePageChange(1);
      }
    },
    [handlePageChange, sortBy],
  );

  return {
    page,
    handlePageChange,
    searchValue,
    searchTerm,
    handleSearchValue,
    filterBy,
    filter,
    handleFilterBy,
    sortBy,
    handleTableChange,
  };
};

export default useTablePagination;
