import type { ContactPersonRow } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { ContactPerson } from 'ducks/clients/types';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
  useDeleteClientContactPersonMutation,
  useGetClientContactPersonsQuery,
  useUpdateClientContactPersonMutation,
} from 'ducks/clients/service';

export const useContactPersonsApiActions = (notify: NotificationInstance) => {
  const { clientId = '' } = useParams();
  const { data = [], isLoading } = useGetClientContactPersonsQuery({
    clientId: Number(clientId),
  });
  const [updateClientContactPerson, { isLoading: isUpdateLoading }] =
    useUpdateClientContactPersonMutation();
  const [deleteClientContactPerson, { isLoading: isDeleteLoading }] =
    useDeleteClientContactPersonMutation();

  const handleUpdateContact = async (
    values: ContactPerson,
    row: ContactPersonRow,
  ) => {
    if (isUpdateLoading || isDeleteLoading) return;

    try {
      await updateClientContactPerson({
        ...values,
        clientId: Number(clientId),
        contactId: row.id,
      }).unwrap();

      notify.success({
        message: (
          <FormattedMessage defaultMessage="Kontakt został edytowany." />
        ),
      });
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania kontaktu." />
        ),
      });
    }
  };

  const handleDeleteContact = async (row: ContactPersonRow) => {
    if (isUpdateLoading || isDeleteLoading) return;

    try {
      await deleteClientContactPerson({
        clientId: Number(clientId),
        contactId: row.id,
      }).unwrap();

      notify.success({
        message: <FormattedMessage defaultMessage="Kontakt został usunięty." />,
      });
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas usuwania kontaktu." />
        ),
      });
    }
  };

  return {
    data,
    isLoading,
    handleUpdateContact,
    isUpdateLoading,
    handleDeleteContact,
    isDeleteLoading,
  };
};
