/* eslint-disable no-param-reassign */
import type {
  ChangeOrderTranslationFileTranslatorPayload,
  DeleteOrderTranslationFilePayload,
  OrderTranslationFilesState,
  UpdateOrderTranslationFileLangPairPayload,
} from './type';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  GetOrderTranslationFilesResponse,
  TranslationsAndOrdersFile,
} from 'ducks/orders/types';
import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { FileType } from 'ducks/global/types';
import { extractLanguageCodesFromFilename } from './utils';

const initialState: OrderTranslationFilesState = {
  orderFiles: [],
  uploadedFiles: {
    orders: [],
    translations: [],
  },
};

const orderTranslationFilesSlice = createSlice({
  name: 'orderTranslationFiles',
  initialState,
  reducers: {
    setOrderTranslationFiles: (
      state,
      {
        payload: { orderFiles, translationsAndOrdersFiles },
      }: PayloadAction<GetOrderTranslationFilesResponse>,
    ) => {
      const filterFiles = (fileType: FileType) =>
        translationsAndOrdersFiles.filter(({ object }) => object === fileType);

      state.orderFiles = orderFiles;
      state.uploadedFiles.orders = filterFiles(FileType.ORDERS);
      state.uploadedFiles.translations = filterFiles(
        FileType.TRANSLATIONS_FILES,
      );
    },
    /**
     * Assigns the uploaded translation file to the selected source file.
     * If the name of the uploaded translation file includes languages
     * such as _DE_PL, then we check if such language pair DE-PL exists.
     * If it does, we add this translated file to it, and if it doesn't,
     * we add it to the first orderFileLangs in the array.
     */
    assignOrderTranslationUploadedFile: (
      state,
      {
        payload: { orderFileId, translationFile },
      }: PayloadAction<{
        orderFileId: number;
        translationFile: TranslationsAndOrdersFile;
      }>,
    ) => {
      const orderFile = state.orderFiles.find(({ id }) => id === orderFileId);
      const initialTranslationFileValues = {
        ...translationFile,
        id: v4(),
        fileId: translationFile.id,
        orderFileId,
        translatorId: null,
      };

      if (!orderFile) return;

      const [langFrom, langTo] = extractLanguageCodesFromFilename(
        translationFile.filename,
      );

      const matchedLangs = orderFile.orderFileLangs.find(
        (orderFileLang) =>
          orderFileLang.langFrom === langFrom &&
          orderFileLang.langTo === langTo,
      );

      if (matchedLangs) {
        matchedLangs.orderTranslationFiles.push(initialTranslationFileValues);

        return;
      }

      orderFile.orderFileLangs[0].orderTranslationFiles.push(
        initialTranslationFileValues,
      );
    },
    deleteOrderTranslationUploadedFile: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.uploadedFiles.translations =
        state.uploadedFiles.translations.filter(({ id }) => id !== payload);
    },
    /**
     * This function updates the language pair for an order translation file.
     * It retrieves the corresponding objects for the order file,
     * target order file language, and selected order file language.
     * It then finds the specified order translation file within the
     * selected order file language and removes it from the array.
     * Finally, it adds the order translation file to the target order
     * file language's array.
     */
    updateOrderTranslationFileLangPair: (
      state,
      {
        payload: {
          orderFileId,
          targetOrderFileLangId,
          selectedOrderFileLangId,
          orderTranslationFileId,
        },
      }: PayloadAction<UpdateOrderTranslationFileLangPairPayload>,
    ) => {
      const orderFile = state.orderFiles.find(({ id }) => id === orderFileId);

      if (!orderFile) return;

      const targetOrderFileLang = orderFile.orderFileLangs.find(
        ({ id }) => id === targetOrderFileLangId,
      );

      if (!targetOrderFileLang) return;

      const currentlySelectedOrderFileLang = orderFile.orderFileLangs.find(
        ({ id }) => id === selectedOrderFileLangId,
      );

      if (!currentlySelectedOrderFileLang) return;

      const orderTranslationFile =
        currentlySelectedOrderFileLang.orderTranslationFiles.find(
          ({ id }) => id === orderTranslationFileId,
        );

      if (!orderTranslationFile) return;

      currentlySelectedOrderFileLang.orderTranslationFiles =
        currentlySelectedOrderFileLang.orderTranslationFiles.filter(
          ({ id }) => id !== orderTranslationFile.id,
        );

      targetOrderFileLang.orderTranslationFiles.push({
        ...orderTranslationFile,
        translatorId: null,
      });
    },
    deleteOrderTranslationFile: (
      state,
      {
        payload: { orderFileId, translationFileId },
      }: PayloadAction<DeleteOrderTranslationFilePayload>,
    ) => {
      const orderFile = state.orderFiles.find(({ id }) => id === orderFileId);

      if (!orderFile) return;

      const orderFileLang = orderFile.orderFileLangs.find(
        ({ orderTranslationFiles }) =>
          orderTranslationFiles.find(({ id }) => id === translationFileId),
      );

      if (!orderFileLang) return;

      orderFileLang.orderTranslationFiles =
        orderFileLang.orderTranslationFiles.filter(
          ({ id }) => id !== translationFileId,
        );
    },
    changeOrderTranslationFileTranslator: (
      state,
      {
        payload: {
          orderFileId,
          orderFileLangId,
          orderTranslationFileId,
          newTranslatorId,
        },
      }: PayloadAction<ChangeOrderTranslationFileTranslatorPayload>,
    ) => {
      const orderFile = state.orderFiles.find(({ id }) => id === orderFileId);

      if (!orderFile) return;

      const orderFileLang = orderFile.orderFileLangs.find(
        ({ id }) => id === orderFileLangId,
      );

      if (!orderFileLang) return;

      const orderTranslationFile = orderFileLang.orderTranslationFiles.find(
        ({ id }) => id === orderTranslationFileId,
      );

      if (!orderTranslationFile) return;

      orderTranslationFile.translatorId = newTranslatorId;
    },
    resetOrderTranslationFilesState: () => initialState,
  },
});

export const {
  setOrderTranslationFiles,
  assignOrderTranslationUploadedFile,
  deleteOrderTranslationUploadedFile,
  updateOrderTranslationFileLangPair,
  deleteOrderTranslationFile,
  changeOrderTranslationFileTranslator,
  resetOrderTranslationFilesState,
} = orderTranslationFilesSlice.actions;

export default orderTranslationFilesSlice.reducer;
