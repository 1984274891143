import { Button, Form, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../styles.module.scss';

const { Item, useForm } = Form;

type Props = {
  isRequestPending: boolean;
  qrCode?: string;
  onFinishCallback: (code: string) => void;
};

export const CodeForm: React.FC<Props> = ({
  qrCode,
  onFinishCallback,
  isRequestPending,
}) => {
  const [form] = useForm();
  return (
    <Form
      data-testid="notAuthCodeForm"
      onFinish={(values) => onFinishCallback(values.code)}
      form={form}
      layout="vertical"
      className="row-col"
      autoComplete="off"
    >
      {qrCode && (
        <p
          className={styles.qrCodeWrapper}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: qrCode }}
        />
      )}
      <Item
        name="code"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage defaultMessage="To pole jest obowiązkowe" />
            ),
          },
        ]}
      >
        <Input placeholder="Kod" />
      </Item>

      <Item>
        <Button
          type="primary"
          htmlType="submit"
          className={styles.fullWidth}
          loading={isRequestPending}
        >
          <FormattedMessage defaultMessage="Zaloguj się" />
        </Button>
      </Item>
    </Form>
  );
};

export default CodeForm;
