import type { ColProps } from 'antd';
import type { FC, ReactElement } from 'react';
import { Button, Card, Col } from 'antd';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import styles from './styles.module.scss';

interface Props extends Omit<ColProps, 'title'> {
  children: ReactElement | ReactElement[];
  title: string | ReactElement;
  isLoading: boolean;
  openEditModal?: () => void;
}

const CardCommon: FC<Props> = ({
  children,
  title,
  isLoading,
  openEditModal,
  className,
  ...props
}) => (
  <Col
    span={24}
    md={8}
    className={cn(styles.cardWrapper, className)}
    {...props}
  >
    <Card
      data-testid="cardCommon"
      bordered={false}
      className={cn('header-solid h-full', styles.card)}
      bodyStyle={{ paddingTop: 16, paddingBottom: 16 }}
      title={
        openEditModal ? (
          <TitleWrapper>
            {title}
            <Button
              data-testid="editDataButton"
              type="primary"
              onClick={openEditModal}
              disabled={isLoading}
            >
              <FormattedMessage defaultMessage="Edytuj" />
            </Button>
          </TitleWrapper>
        ) : (
          title
        )
      }
    >
      {children}
    </Card>
  </Col>
);

export default CardCommon;
