import type { FC } from 'react';
import type { InvoiceListItemRow } from 'views/Invoices/config';
import { Tag, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { NO_DATA } from 'constants/strings';
import { Routes as ClientRoutes } from 'views/Clients/Routing';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = Pick<InvoiceListItemRow, 'client'>;

const ClientNameCell: FC<Props> = ({ client }) => {
  const isCollectiveInvoices = !!client.isCollectiveInvoices;

  return (
    <span className={styles.wrapper}>
      <Link
        to={ClientRoutes.CLIENT_DETAILS.replace(
          ':clientId',
          client.id.toString(),
        )}
      >
        <Text>{client.clientName ?? NO_DATA}</Text>
      </Link>
      {isCollectiveInvoices && (
        <Tag>
          <FormattedMessage defaultMessage="Faktury zbiorcze" />
        </Tag>
      )}
    </span>
  );
};

export default ClientNameCell;
