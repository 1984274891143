/* eslint-disable camelcase */
import type { LoginHistoryRow } from './config';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Table from 'components/ui/molecules/Table/Table';
import { useGetLogsQuery } from 'ducks/logs/service';
import { columns } from './config';
import styles from './styles.module.scss';

const LoginHistoryTable = () => {
  const { data = [], isLoading } = useGetLogsQuery();

  const modifiedUsersData = useMemo((): LoginHistoryRow[] => {
    return data.map(({ id, firstName, lastName, ...restProps }) => {
      const fullName = `${firstName} ${lastName}`;

      return {
        id,
        key: id,
        fullName,
        ...restProps,
      };
    });
  }, [data]);

  return (
    <Table<LoginHistoryRow>
      data={modifiedUsersData}
      isLoading={isLoading}
      columns={columns()}
      cardTitle={
        <div className={styles.headerWrapper}>
          <FormattedMessage defaultMessage="Historia logowania" />
        </div>
      }
    />
  );
};

export default LoginHistoryTable;
