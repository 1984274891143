import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from 'components/main/App';
import Intl from 'components/main/Intl';
import store from 'ducks/store';
import { customizeRenderEmpty } from 'utilities/customizeRenderEmpty';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Intl>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <App />
        </ConfigProvider>
      </Intl>
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
