/* eslint-disable react/jsx-props-no-spreading */
import { BsFillUnlockFill } from 'react-icons/bs';

type Props = {
  size?: number;
};

const UnlockIcon: React.FC<Props> = ({ size, ...props }) => (
  <BsFillUnlockFill size={size} {...props} />
);

export default UnlockIcon;
