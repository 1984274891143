import type { FileTranslationType } from 'constants/fileTranslation';
import type { OrderFileLangState } from 'ducks/view/orders';
import type { FC } from 'react';
import { Skeleton } from 'antd';
import { memo, useState } from 'react';
import Button from 'components/ui/atoms/Button';
import { NO_DATA } from 'constants/strings';
import { useAppSelector } from 'ducks/store';
import { useGetTranslatorDetailsQuery } from 'ducks/translators/service';
import { getTranslatorPriceNettoPerPage } from 'ducks/view/orders/utils';
import { ChevronDown } from 'icons';
import TranslatorsModal from '../TranslatorsModal';
import TranslatorColumnPlaceholder from './components/TranslatorColumnPlaceholder';
import styles from './styles.module.scss';

type Props = {
  orderFileId: number;
  isCorrection: boolean;
  isSpecial: boolean;
  orderFileLang: OrderFileLangState;
  type: FileTranslationType;
};

const TranslatorColumn: FC<Props> = memo(
  ({ orderFileId, isCorrection, isSpecial, orderFileLang, type }) => {
    const { translatorId, pagesTranslator, translatorPriceNetto } =
      orderFileLang;
    const isEditable = useAppSelector((state) => state.orders.isEditable);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { data, isFetching } = useGetTranslatorDetailsQuery(
      {
        translatorId: Number(translatorId),
      },
      { skip: translatorId === null },
    );
    const translatorPriceNettoPerPage = getTranslatorPriceNettoPerPage(
      translatorPriceNetto,
      pagesTranslator,
    );

    if (isFetching) {
      if (isEditable) {
        return <Skeleton.Button style={{ width: '110px' }} active />;
      }

      return <Skeleton active paragraph={false} style={{ width: '110px' }} />;
    }

    if (!isEditable) {
      return data && translatorId ? (
        <TranslatorColumnPlaceholder
          data={data}
          translatorPriceNettoPerPage={translatorPriceNettoPerPage}
          isCorrection={isCorrection}
          isSpecial={isSpecial}
          type={type}
        />
      ) : (
        <span className={styles.cell}>{NO_DATA}</span>
      );
    }

    return (
      <>
        <Button
          className={styles.button}
          size="small"
          type="text"
          onClick={() => setIsModalOpen(true)}
        >
          {data && translatorId ? (
            <TranslatorColumnPlaceholder
              data={data}
              translatorPriceNettoPerPage={translatorPriceNettoPerPage}
              isCorrection={isCorrection}
              isSpecial={isSpecial}
              type={type}
            />
          ) : (
            NO_DATA
          )}
          <ChevronDown />
        </Button>
        <TranslatorsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          orderFileId={orderFileId}
          orderFileLang={orderFileLang}
          isCorrection={isCorrection}
          isSpecial={isSpecial}
          type={type}
        />
      </>
    );
  },
);

export default TranslatorColumn;
