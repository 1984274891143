import { Checkbox } from 'antd';
import { useEffect, type FC } from 'react';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import FormItem from 'components/ui/atoms/FormItem';
import { useAppDispatch } from 'ducks/store';
import { updateOrderFiles } from 'ducks/view/orders';
import styles from './styles.module.scss';

type Props = {
  isEditable: boolean;
  isAccepted: boolean;
  isDisabled: boolean;
  id: number;
  orderFileId: number;
};

const AcceptedColumn: FC<Props> = ({
  isAccepted,
  id,
  orderFileId,
  isEditable,
  isDisabled,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAccepted) return;

    dispatch(
      updateOrderFiles({
        field: {
          [orderFileId]: {
            [id]: {
              isAccepted: true,
            },
          },
        },
      }),
    );
  }, [dispatch, id, isAccepted, orderFileId]);

  return isEditable ? (
    <FormItem
      name={[orderFileId, id, 'isAccepted']}
      component={<Checkbox disabled={isDisabled} />}
      additionalItemProps={{
        valuePropName: 'checked',
      }}
    />
  ) : (
    <div className={styles.cell}>
      <CircleCheckbox key={id} value={isAccepted} />
    </div>
  );
};

export default AcceptedColumn;
