import type { NotificationInstance } from 'antd/es/notification/interface';
import type { GetNotesResponse, Note, NoteType } from 'ducks/notes/types';
import type { Dispatch, SetStateAction } from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import Timeline from 'components/ui/molecules/Timeline';
import NoteItem from '../NoteItem';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  data: GetNotesResponse;
  objectId: number;
  notify: NotificationInstance;
  noteType: NoteType;
  setSelectedNote: Dispatch<SetStateAction<Note | null>>;
};

const ShowAllNotesModal: React.FC<Props> = ({
  isOpen,
  isLoading,
  onClose,
  data,
  objectId,
  noteType,
  notify,
  setSelectedNote,
}) => {
  return (
    <Modal
      zIndex={900}
      open={isOpen}
      onCancel={onClose}
      title={<FormattedMessage defaultMessage="Wszystkie notatki" />}
      destroyOnClose
      footer={null}
      width={920}
    >
      <div className={styles.wrapper}>
        <Timeline
          noCard
          data={data}
          isLoading={isLoading}
          emptyText={<FormattedMessage defaultMessage="Brak notatek" />}
          renderItems={(note) => (
            <NoteItem
              objectId={objectId}
              object={noteType}
              key={note.id}
              note={note}
              handleSelectNote={(editNote) => setSelectedNote(editNote)}
              notify={notify}
            />
          )}
        />
      </div>
    </Modal>
  );
};

export default ShowAllNotesModal;
