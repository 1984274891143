import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import CatLabel from 'components/ui/atoms/CatLabel';
import HorizontalCenteredWrapper from 'components/ui/atoms/HorizontalCenteredWrapper';
import { NO_DATA } from 'constants/strings';
import { useTranslatorProfileServices } from 'views/Translators/views/TranslatorProfile';
import CardCommon from '../../CardCommon';

const { Text } = Typography;

const CatCard = () => {
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const cats = translatorData?.cats.filter(({ isMain }) => isMain) ?? [];

  return (
    <CardCommon
      title={<FormattedMessage defaultMessage="CAT" />}
      isLoading={isTranslatorFetching}
      data-testid="catCard"
      xs={24}
      md={24}
    >
      <HorizontalCenteredWrapper isLoading={isTranslatorFetching}>
        {cats.length ? (
          cats.map(({ id, name }, index) => (
            <div key={id}>
              <CatLabel id={id} name={name} />
              {!!cats[index + 1] && ' ,'}
            </div>
          ))
        ) : (
          <Text>{NO_DATA}</Text>
        )}
      </HorizontalCenteredWrapper>
    </CardCommon>
  );
};

export default CatCard;
