import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';
import type { Client } from 'ducks/clients/types';
import type { Note } from 'ducks/notes/types';
import { Tag, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import theme from 'components/layout/Main/styles/values.scss';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import TruncateText from 'components/ui/atoms/TruncateText';
import { NO_DATA } from 'constants/strings';
import styles from './styles.module.scss';

const { Text } = Typography;

const MAX_NOTES_LENGTH = 3;

export const headerCols = (
  notes: Note[],
  openAllNotesModal: () => void,
  data: Client,
): HeaderCardCol[] => {
  const { name, mt, balance, isDebtor, isCollectiveInvoices, discount } = data;
  const balanceType = balance < 0 ? 'danger' : 'success';

  const items = notes.slice(0, MAX_NOTES_LENGTH);
  const counter = notes.length - MAX_NOTES_LENGTH;

  return [
    {
      key: 'name',
      firstRow: <span data-testid="name">{name}</span>,
      secondRow: (
        <Text className={styles.colName}>
          <CircleCheckbox
            data-testid="mtCheckbox"
            value={mt}
            colorTrue={theme.colorViridian}
          />
          <FormattedMessage defaultMessage="TM" />
        </Text>
      ),
    },
    {
      key: 'balance',
      firstRow: <FormattedMessage defaultMessage="Saldo" />,
      secondRow: (
        <Text type={balanceType} ellipsis>
          <CurrencyFormatter value={balance} />
        </Text>
      ),
    },
    {
      key: 'isDebtor',
      firstRow: <FormattedMessage defaultMessage="Dłużnik" />,
      secondRow: (
        <CircleCheckbox
          data-testid="isDebtorCheckbox"
          value={isDebtor}
          colorTrue={theme.colorTorchRed}
        />
      ),
    },
    {
      key: 'isCollectiveInvoices',
      firstRow: <FormattedMessage defaultMessage="Faktury zbiorcze" />,
      secondRow: (
        <CircleCheckbox
          data-testid={
            isCollectiveInvoices
              ? 'clientCollectiveInvoicesChecked'
              : 'clientCollectiveInvoicesUnchecked'
          }
          value={isCollectiveInvoices}
        />
      ),
    },
    {
      key: 'discount',
      firstRow: <FormattedMessage defaultMessage="Rabat" />,
      secondRow: <span data-testid="discount">{discount}%</span>,
    },
    {
      key: 'notes',
      firstRow: <FormattedMessage defaultMessage="Notatki" />,
      secondRow: !notes.length ? (
        NO_DATA
      ) : (
        <div data-testid="clientNote">
          {items.map((note) => (
            <Tag
              key={note.id}
              onClick={openAllNotesModal}
              className={styles.tag}
            >
              <TruncateText maxLength={15}>{note.title}</TruncateText>
            </Tag>
          ))}
          {counter > 0 && (
            <Tag
              color="processing"
              onClick={openAllNotesModal}
              className={styles.tag}
            >
              {`+${counter}`}
            </Tag>
          )}
        </div>
      ),
    },
  ];
};
