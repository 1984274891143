import type { AddPaymentModalValues } from './config';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Form, notification } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { useAddInvoicePaymentMutation } from 'ducks/invoices/service';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';
import { formItems, initialValues } from './config';

const { useForm } = Form;

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const AddPaymentModal: FC<Props> = ({ isOpen, onClose }) => {
  const { id = '' } = useParams();
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm<AddPaymentModalValues>();
  const [addInvoicePayment, { isLoading }] = useAddInvoicePaymentMutation();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleAddPayment = () => {
    if (isLoading || !id) return;

    mutationFunctionWithForm<void, AddPaymentModalValues>({
      form,
      mutationFn: async ({ datePayment, ...values }) => {
        const formattedDatePayment = dayjs(
          datePayment,
        ).format() as unknown as Date;

        await addInvoicePayment({
          id,
          ...values,
          datePayment: formattedDatePayment,
        }).unwrap();
      },
      onSuccess: handleClose,
      onError: () =>
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania płatności." />
          ),
        }),
    });
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Dodaj wpłatę" />}
      okText={<FormattedMessage defaultMessage="Dodaj" />}
      open={isOpen}
      onOk={handleAddPayment}
      onCancel={handleClose}
      isLoading={isLoading}
      disableModalStyles
      disableFormStyles
    >
      {contextHolder}
      <Form
        form={form}
        initialValues={initialValues}
        autoComplete="off"
        layout="vertical"
      >
        {formItems({ intl }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default AddPaymentModal;
