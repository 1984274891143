import type { OrderInvoice } from 'ducks/orders/types';
import type { FC } from 'react';
import { notification } from 'antd';
import { GoCloudDownload } from 'react-icons/go';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import TruncateText from 'components/ui/atoms/TruncateText';
import { NO_DATA } from 'constants/strings';
import { useLazyDownloadInvoiceQuery } from 'ducks/public/service';
import { downloadFile } from 'utilities/downloadFile';
import styles from './styles.module.scss';

type Props = Pick<OrderInvoice, 'number' | 'hash' | 'filename'>;

const DownloadFvButton: FC<Props> = ({ number, hash, filename }) => {
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const [downloadInvoice, { isLoading }] = useLazyDownloadInvoiceQuery();

  const handleDownloadFile = async () => {
    if (isLoading || !hash) return;

    try {
      const response = await downloadInvoice({
        hash,
      }).unwrap();

      if (response) {
        const url = URL.createObjectURL(response);

        downloadFile(
          url,
          filename || intl.formatMessage({ defaultMessage: 'Brak nazwy' }),
        );
      }
    } catch (err) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Nie znaleziono pliku lub wystąpił błąd." />
        ),
      });
    }
  };

  return (
    <>
      {contextHolder}
      {hash ? (
        <Button
          className={styles.button}
          type="link"
          icon={<GoCloudDownload />}
          onClick={handleDownloadFile}
          loading={isLoading}
        >
          <TruncateText>{number}</TruncateText>
        </Button>
      ) : (
        <span>{NO_DATA}</span>
      )}
    </>
  );
};

export default DownloadFvButton;
