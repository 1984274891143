import type { VariantServiceRow } from '../../config';
import type { FC } from 'react';
import { Popconfirm } from 'antd';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { useAppDispatch } from 'ducks/store';
import { deletePreorderVariantService } from 'ducks/view/preorders';
import { TrashIcon } from 'icons';

type Props = Pick<VariantServiceRow, 'id' | 'variantId'>;

const DeleteServiceButton: FC<Props> = ({ variantId, id }) => {
  const dispatch = useAppDispatch();

  const handleDeleteService = () =>
    dispatch(deletePreorderVariantService({ variantId, id }));

  return (
    <Popconfirm
      title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
      okText={<FormattedMessage defaultMessage="Tak" />}
      cancelText={<FormattedMessage defaultMessage="Nie" />}
      onConfirm={handleDeleteService}
    >
      <Button
        data-testid={`deleteButton-${id}`}
        size="small"
        type="link"
        icon={<TrashIcon />}
        danger
      >
        <FormattedMessage defaultMessage="Usuń" />
      </Button>
    </Popconfirm>
  );
};

export default DeleteServiceButton;
