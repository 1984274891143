import type { FC } from 'react';
import { Select } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import { useGetEmailTemplatesQuery } from 'ducks/settings/service';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const EmailTemplateSelect: FC<Props> = ({ value, onChange }) => {
  const { data, isLoading } = useGetEmailTemplatesQuery();

  const memoizedOptions = useMemo(() => {
    if (!data) return [];

    return data.map(({ title, content }) => ({
      value: content,
      label: title,
    }));
  }, [data]);

  return (
    <FormItem
      label={<FormattedMessage defaultMessage="Szablony" />}
      component={
        <Select
          data-testid="emailTemplateSelect"
          value={value}
          onChange={onChange}
          options={memoizedOptions}
          loading={isLoading}
          allowClear
        />
      }
    />
  );
};

export default EmailTemplateSelect;
