import { financesImportFileTypes } from 'constants/fileTypes';

export const getFinancesImportFileTypesString = () =>
  financesImportFileTypes
    .map((fileType) =>
      fileType.startsWith('.') && fileType.lastIndexOf('.') === 0
        ? fileType
        : '',
    )
    .filter((fileType) => fileType)
    .join(' / ');
