import type { EditDebtCollectionsModalValues } from './config';
import type { DebtCollections } from 'ducks/invoices/types';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Form, notification } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { useUpdateInvoiceDebtCollectionMutation } from 'ducks/invoices/service';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';
import { formItems } from './config';

const { useForm } = Form;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  debtCollection: DebtCollections;
};

const EditDebtCollectionsModal: FC<Props> = ({
  isOpen,
  onClose,
  debtCollection,
}) => {
  const intl = useIntl();
  const { id = '' } = useParams();
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm<EditDebtCollectionsModalValues>();
  const [updateInvoiceDebtCollection, { isLoading }] =
    useUpdateInvoiceDebtCollectionMutation();
  const initialValues: EditDebtCollectionsModalValues = {
    ...debtCollection,
    dateReceived: dayjs(debtCollection.dateReceived ?? undefined),
    number: debtCollection.number ?? '',
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleUpdateDebtCollection = () => {
    if (isLoading || !id) return;

    mutationFunctionWithForm<void, EditDebtCollectionsModalValues>({
      form,
      mutationFn: async ({ dateReceived, ...values }) => {
        const formattedDateReceived = dayjs(
          dateReceived,
        ).format() as unknown as Date;

        await updateInvoiceDebtCollection({
          id,
          itemId: debtCollection.id,
          ...values,
          dateReceived: formattedDateReceived,
        }).unwrap();
      },
      onSuccess: handleClose,
      onError: () =>
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania windykacji." />
          ),
        }),
    });
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Windykacja" />}
      open={isOpen}
      onOk={handleUpdateDebtCollection}
      onCancel={handleClose}
      isLoading={isLoading}
      disableModalStyles
      disableFormStyles
    >
      {contextHolder}
      <Form
        form={form}
        initialValues={initialValues}
        autoComplete="off"
        layout="vertical"
      >
        {formItems({ intl }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default EditDebtCollectionsModal;
