/* eslint-disable no-param-reassign */
import type { ConfigState } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState: ConfigState = {
  headerFixed: true,
  sidenavMobileOpened: false,
  sidenavColor: 'transparent',
  layoutRtl: false,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setHeaderFixed: (state, { payload }: PayloadAction<boolean>) => {
      state.headerFixed = payload;
    },
    setSidenavMobileOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.sidenavMobileOpened = payload;
    },
    toggleSidenavMobileOpened: (state) => {
      state.sidenavMobileOpened = !state.sidenavMobileOpened;
    },
    setSidenavColor: (state, { payload }: PayloadAction<string>) => {
      state.sidenavColor = payload;
    },
    setLayoutRtl: (state, { payload }: PayloadAction<boolean>) => {
      state.layoutRtl = payload;
    },
  },
});

export const {
  setHeaderFixed,
  setSidenavMobileOpened,
  toggleSidenavMobileOpened,
  setSidenavColor,
  setLayoutRtl,
} = configSlice.actions;

export default configSlice.reducer;
