import type { ListItemRecord } from 'components/ui/molecules/List';
import type { Client } from 'ducks/clients/types';
import { FormattedMessage } from 'react-intl';
import CountryName from 'components/ui/atoms/CountryName';
import { getClientFullAddress } from 'utilities/address';

export const correspondenceListItems = (
  data: Client | undefined,
): ListItemRecord[] => [
  {
    label: <FormattedMessage defaultMessage="Nazwa:" />,
    description: data?.name,
  },
  {
    label: <FormattedMessage defaultMessage="NIP:" />,
    description: data?.nip,
  },
  {
    label: <FormattedMessage defaultMessage="Adres:" />,
    description: getClientFullAddress(data),
  },
  {
    label: <FormattedMessage defaultMessage="Kraj:" />,
    description: <CountryName countryCode={data?.country} />,
  },
];
