import type {
  AddFileResponse,
  CheckNipResponse,
  CheckVatResponse,
  Language,
  UploadedFile,
} from 'ducks/global/types';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import { FileType } from 'ducks/global/types';

export const mockedCheckedNip: CheckNipResponse = {
  nip: '123456789',
  regon: '232313216',
  name: 'test-name',
  province: '',
  city: '',
  propertyNumber: '',
  apartmentNumber: '',
  zipCode: '',
  street: '',
};

export const mockedCheckVat: CheckVatResponse = {
  isValid: true,
};

export const mockedUploadedFile: UploadedFile = {
  id: 1,
  file: 'file',
  filename: 'file.jpg',
  object: FileType.QUALIFICATIONS,
  objectId: 1,
};

export const mockedFile: UploadedFile = mockedUploadedFile;

export const mockedAddFileResponse: AddFileResponse = [mockedUploadedFile];

export const mockedLanguages: Language[] = [
  {
    id: 1,
    name: 'hiszpański',
    code: LanguageCode.SPANISH,
  },
  {
    id: 2,
    name: 'norweski',
    code: 'NO',
  },
  {
    id: 3,
    name: 'polski',
    code: LanguageCode.POLAND,
  },
  {
    id: 4,
    name: 'angielski',
    code: LanguageCode.ENGLISH,
  },
];
