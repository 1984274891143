import type { VariantServiceRow } from '../../config';
import type { FC } from 'react';
import { calcServiceGraphicTimeRealization } from 'utilities/calcServiceGraphicTimeRealization';
import { generateWorkingDaysLabel } from 'utilities/workingDaysLabel';

type Props = { row: VariantServiceRow };

const GraphicTimeRealizationCol: FC<Props> = ({ row }) => {
  const time = calcServiceGraphicTimeRealization([row], true);

  return (
    <span data-testid="graphicTimeRealization" style={{ whiteSpace: 'nowrap' }}>
      {generateWorkingDaysLabel({ timeRealization: time, prefixLabel: '+' })}
    </span>
  );
};

export default GraphicTimeRealizationCol;
