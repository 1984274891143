import type { OrderFilesAndSericesValues } from 'components/ui/organisms/Tables/OrderFilesAndServicesTable';
import type { FC } from 'react';
import { Checkbox } from 'antd';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import FormItem from 'components/ui/atoms/FormItem';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { updateOrderFiles, type OrderFileLangState } from 'ducks/view/orders';
import { booleanToBinary } from 'utilities/boolean';
import styles from './styles.module.scss';

type Props = {
  orderFileId: number;
  orderFileLangId: string | number;
} & Pick<OrderFileLangState, 'isPostedit' | 'catId'>;

const PosteditColumn: FC<Props> = ({
  orderFileId,
  orderFileLangId,
  isPostedit,
  catId,
}) => {
  const dispatch = useAppDispatch();
  const isEditable = useAppSelector(({ orders }) => orders.isEditable);
  const disabled = !catId;

  const handleOnChange = (checked: boolean) =>
    dispatch(
      updateOrderFiles({
        field: {
          [orderFileId]: {
            [orderFileLangId]: {
              isPostedit: booleanToBinary(checked),
            },
          },
        },
      }),
    );

  return isEditable ? (
    <FormItem<OrderFilesAndSericesValues>
      component={
        <Checkbox
          checked={!!isPostedit}
          onChange={({ target }) => handleOnChange(target.checked)}
          disabled={disabled}
        />
      }
    />
  ) : (
    <div className={styles.cell}>
      <CircleCheckbox
        key={orderFileLangId}
        data-testid="isPosteditCircleCheckbox"
        value={!!isPostedit}
      />
    </div>
  );
};

export default PosteditColumn;
