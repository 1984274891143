/* eslint-disable react/jsx-props-no-spreading */
import type { EditPriceNameModalValues } from './config';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { GetPriceDetailsResponse } from 'ducks/prices/types';
import type { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { useEditPriceMutation } from 'ducks/prices/service';
import { formItems, initialValues } from './config';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  notify: NotificationInstance;
  items: GetPriceDetailsResponse['items'];
}

const { useForm } = Form;

const EditPriceNameModal: FC<Props> = ({ isOpen, onClose, notify, items }) => {
  const { id: priceId = '' } = useParams();
  const [form] = useForm<EditPriceNameModalValues>();
  const [updatePrice, { isLoading }] = useEditPriceMutation();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleOnSubmit = async () => {
    if (isLoading) return;

    try {
      const values = await form.validateFields();

      await updatePrice({
        ...values,
        id: Number(priceId),
        items,
      }).unwrap();

      notify.success({
        message: <FormattedMessage defaultMessage="Edytowano nazwę." />,
      });
      handleClose();
      form.resetFields();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.errorFields) return;

      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edycji." />
        ),
      });
    }
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Edytuj nazwę" />}
      isLoading={isLoading}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={handleClose}
      okText={<FormattedMessage defaultMessage="Edytuj" />}
      disableFormStyles
      disableModalStyles
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
      >
        {formItems.map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default EditPriceNameModal;
