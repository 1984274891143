import type { ExchangeRatesRequest } from 'ducks/global/types';
import type { FC } from 'react';
import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { CURRENCY_TYPE_ARRAY } from 'constants/currency';
import { useExchangeRatesMutation } from 'ducks/global/service';
import useDebounce from 'hooks/useDebounce';
import styles from './styles.module.scss';

interface Props {
  price: number;
}

const AllCurrenciesInfo: FC<Props> = ({ price }) => {
  const [exchangeRates, { data = [], isLoading, isError }] =
    useExchangeRatesMutation();
  const priceDebounced = useDebounce(price, 1000);

  useEffect(() => {
    if (!priceDebounced) return;

    const currencies: ExchangeRatesRequest['currencies'] =
      CURRENCY_TYPE_ARRAY.map((currencyType) => ({
        currency: currencyType,
        value: priceDebounced,
      }));

    exchangeRates({ currencies });
  }, [exchangeRates, priceDebounced]);

  if (isError) return null;

  return (
    <div className={styles.currencyWrapper}>
      <p className={styles.currencyTitle}>
        <FormattedMessage defaultMessage="Razem (waluty obce):" />
      </p>
      {isLoading && <Skeleton active paragraph={{ rows: 0 }} />}
      {data
        .map(({ result, currency }) => ({
          result,
          currency,
        }))
        .map(({ currency, result }) => (
          <div key={currency} className={styles.currencyItem}>
            <CurrencyFormatter value={result} currency={currency} />
          </div>
        ))}
    </div>
  );
};

export default AllCurrenciesInfo;
