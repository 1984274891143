import type { FileTranslationType } from 'constants/fileTranslation';
import type { FC } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import AssignAllFilesModal from '../../../AssignAllFilesModal';

type Props = {
  type: FileTranslationType;
  showAssignAllFiles: boolean;
};

const OrderTranslationsTableTitleExtra: FC<Props> = ({
  type,
  showAssignAllFiles,
}) => {
  const [isAllFilesOpen, setIsAllFilesOpen] = useState(false);

  const handleOpenAllFilesModal = () => setIsAllFilesOpen(true);

  const handleCloseAllFilesModal = () => setIsAllFilesOpen(false);

  if (!showAssignAllFiles) return null;

  return (
    <>
      <AssignAllFilesModal
        isOpen={isAllFilesOpen}
        onClose={handleCloseAllFilesModal}
        type={type}
      />
      <Button type="primary" onClick={handleOpenAllFilesModal}>
        <FormattedMessage defaultMessage="Zleć wszystkie pliki" />
      </Button>
    </>
  );
};

export default OrderTranslationsTableTitleExtra;
