import type { SendEmailPreorderRequest } from 'ducks/preorders/types';

export type PreorderSendValues = Pick<
  SendEmailPreorderRequest,
  | 'email'
  | 'cc'
  | 'subject'
  | 'body'
  | 'isSms'
  | 'smsText'
  | 'isPresentation'
  | 'isReferenceFile'
>;

export const initialValues: PreorderSendValues = {
  email: '',
  cc: '',
  subject: '',
  body: '',
  isSms: false,
  smsText: 'SUPERTŁUMACZ Dzień dobry! Na podaną skrzynkę e-mail wysłano wycenę',
  isPresentation: false,
  isReferenceFile: false,
};
