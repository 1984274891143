import type { TranslatorRow } from '../../config';
import type { BadgeProps } from 'antd';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Badge, Popover, Space } from 'antd';
import { Link } from 'react-router-dom';
import CopyToClipboardWithText from 'components/ui/atoms/CopyToClipboard';
import FormatDate from 'components/ui/atoms/FormatDate';
import TruncateText from 'components/ui/atoms/TruncateText';
import { getTranslatorName } from 'utilities/translator';
import { Routes } from 'views/Translators/Routing';
import styles from './styles.module.scss';

interface Props {
  translatorRow: TranslatorRow;
}

const TranslatorColumn: FC<Props> = ({ translatorRow }) => {
  const {
    id,
    email,
    phone,
    vacationFrom,
    vacationTo,
    status,
    langNative,
    dateEditTranslator,
  } = translatorRow;
  const translatorName = getTranslatorName(translatorRow);
  const nameWithNativeLang = langNative
    ? `${translatorName} (${langNative})`
    : translatorName;

  const currentDate = dayjs();
  const diffTodayToVacationStart = -currentDate.diff(
    dayjs(vacationFrom),
    'day',
  );
  const diffTodayToVacationFinish = -currentDate.diff(dayjs(vacationTo), 'day');

  const hasVacations =
    diffTodayToVacationStart <= 0 && diffTodayToVacationFinish >= 0;

  const getCorrectStatus = (): BadgeProps['status'] => {
    if (hasVacations) return 'error';

    return status ? 'success' : 'default';
  };

  return (
    <div
      className={styles.wrapper}
      style={{ fontWeight: dateEditTranslator ? 'bold' : 'normal' }}
    >
      <Space>
        <Link to={Routes.TRANSLATOR_PROFILE.replace(':id', id.toString())}>
          <TruncateText
            style={{
              backgroundColor: dateEditTranslator ? '#f5f5b0' : 'transparent',
            }}
            maxLength={20}
          >
            {nameWithNativeLang}
          </TruncateText>
        </Link>
        {hasVacations ? (
          <Popover
            content={
              <>
                <FormatDate hideTime date={vacationFrom ?? ''} /> -{' '}
                <FormatDate hideTime date={vacationTo ?? ''} />{' '}
              </>
            }
          >
            <Badge data-testid="vacationBadge" status={getCorrectStatus()} />
          </Popover>
        ) : (
          <Badge data-testid="nonVacationBadge" status={getCorrectStatus()} />
        )}
      </Space>
      <CopyToClipboardWithText type="secondary" truncate text={email} />
      {phone && (
        <CopyToClipboardWithText type="secondary" truncate text={phone} />
      )}
    </div>
  );
};

export default TranslatorColumn;
