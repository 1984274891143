import type { ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

type Props = {
  text?: string | ReactNode;
  children?: ReactNode;
  visible?: boolean;
  isAbsolute?: boolean;
  style?: React.CSSProperties;
  testId?: string;
};

const Loader: React.FC<Props> = ({
  text,
  children,
  visible = false,
  isAbsolute = false,
  style,
  testId = 'loader-spin',
}) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 50 }} spin data-testid={testId} />
  );

  return (
    <div
      className={cn(styles.wrapper, { [styles.absolute]: isAbsolute })}
      style={style}
    >
      <Spin indicator={antIcon} tip={text} spinning={visible}>
        {children}
      </Spin>
    </div>
  );
};

export default Loader;
