import type { AddPreorderVariantLangPayload } from 'ducks/view/preorders';
import type { FC } from 'react';
import { notification } from 'antd';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import AddLanguagePairModal from 'components/ui/organisms/Modals/AddLanguagePairModal';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  addPreorderVariantLang,
  setPreorderAddLanguagePairModal,
  updatePreorderVariantDiscounts,
} from 'ducks/view/preorders';

interface Props {
  variantId: number | string;
}

const PreorderVariantModals: FC<Props> = memo(({ variantId }) => {
  const [notify, contextHolder] = notification.useNotification();
  const {
    isEditable,
    addLanguagePairModal: { isOpen, variantFileId },
  } = useAppSelector(({ preorders }) => ({
    isEditable: preorders.isEditable,
    addLanguagePairModal: preorders.modals.addLanguagePairModal,
  }));
  const dispatch = useAppDispatch();

  const handleCloseAddLanguagePairModal = () =>
    dispatch(
      setPreorderAddLanguagePairModal({
        isOpen: false,
        variantFileId: null,
      }),
    );

  const handleAddPreorderVariantLang = useCallback(
    ({
      langFrom,
      langTo,
    }: Pick<AddPreorderVariantLangPayload, 'langFrom' | 'langTo'>) => {
      if (!variantFileId) return;

      dispatch(
        addPreorderVariantLang({
          langFrom,
          langTo,
          variantId,
          variantFileId,
          onError: ({ isDuplicate }) => {
            if (isDuplicate) {
              notify.error({
                message: (
                  <FormattedMessage defaultMessage="Ta para językowa już istnieje." />
                ),
              });

              return;
            }
            notify.error({
              message: <FormattedMessage defaultMessage="Usługa niemożliwa." />,
            });
          },
        }),
      );
      dispatch(updatePreorderVariantDiscounts({ variantId }));
    },
    [variantFileId, variantId, dispatch, notify],
  );

  if (!isEditable) return null;

  return (
    <>
      {contextHolder}
      <AddLanguagePairModal<true>
        isOpen={isOpen}
        onAdd={handleAddPreorderVariantLang}
        onClose={handleCloseAddLanguagePairModal}
        langToMultiple
      />
    </>
  );
});

export default PreorderVariantModals;
