import type { User } from 'ducks/users/types';
import { Button, notification } from 'antd';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Table from 'components/ui/molecules/Table/Table';
import { useGetUsersQuery } from 'ducks/users/service';
import { PlusIcon } from 'icons';
import AddUserModal from './components/AddUserModal/AddUserModal';
import EditUserModal from './components/EditUserModal/EditUserModal';
import { columns, type UserRow } from './config';

const UsersTable = () => {
  const { data = [], isLoading } = useGetUsersQuery();
  const [notify, contextHolder] = notification.useNotification();
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const modifiedUsersData = useMemo((): UserRow[] => {
    return data.map(
      ({
        id,
        firstName,
        lastName,
        allowTimeFrom,
        allowTimeTo,
        ...restProps
      }) => {
        const fullName = `${firstName} ${lastName}`;
        const allowTime = `${allowTimeFrom} - ${allowTimeTo}`;

        return { id, key: id, fullName, allowTime, ...restProps };
      },
    );
  }, [data]);

  const handleOnClickEdit = (userId: number) => {
    const specificUser = data.find((user) => user.id === userId);

    setSelectedUser(specificUser ?? null);
    setIsEditUserModalOpen(true);
  };

  const handleOnModalClose = () => {
    setSelectedUser(null);
    setIsEditUserModalOpen(false);
  };

  return (
    <>
      {contextHolder}
      <AddUserModal
        isOpen={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
        notify={notify}
      />
      {selectedUser && (
        <EditUserModal
          isOpen={isEditUserModalOpen}
          onClose={handleOnModalClose}
          userData={selectedUser}
          notify={notify}
        />
      )}
      <Table<UserRow>
        isLoading={isLoading}
        data={modifiedUsersData}
        columns={columns(handleOnClickEdit)}
        cardTitleExtra={
          <Button
            data-testid="addUserButton"
            icon={<PlusIcon />}
            type="primary"
            onClick={() => setIsAddUserModalOpen(true)}
          >
            <FormattedMessage defaultMessage="Dodaj użytkownika" />
          </Button>
        }
        cardTitle={<FormattedMessage defaultMessage="Lista pracowników" />}
      />
    </>
  );
};

export default UsersTable;
