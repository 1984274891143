import type { ParsedPaymentFileRow } from './config';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import Table from 'components/ui/molecules/Table';
import { useAppSelector } from 'ducks/store';
import AssignPaymentTransactionModal from './components/AssignPaymentTransactionModal';
import { columns } from './config';
import styles from './styles.module.scss';
import { getRowColorByPaymentStatus } from './utils';

const PaymentFileAndTransactionsTable = () => {
  const parsedPaymentFile = useAppSelector(
    ({ finances }) => finances.parsedPaymentFile,
    shallowEqual,
  );

  const modifiedData = useMemo(
    (): ParsedPaymentFileRow[] =>
      parsedPaymentFile.map((item) => ({
        ...item,
        key: item.id,
      })),
    [parsedPaymentFile],
  );

  return (
    <>
      <div
        data-testid="paymentFileAndTransactionsTable"
        className={styles.wrapper}
      >
        <Table
          cardTitle={
            <FormattedMessage defaultMessage="Przypisywanie wpłat do faktur" />
          }
          columns={columns}
          data={modifiedData}
          onRow={({ status }) => ({
            className: getRowColorByPaymentStatus(status),
          })}
        />
      </div>
      <AssignPaymentTransactionModal />
    </>
  );
};

export default PaymentFileAndTransactionsTable;
