export const formats = ['bold', 'italic', 'underline', 'color', 'list', 'link'];

const options = [
  'bold',
  'italic',
  'link',
  'underline',
  { color: [] },
  { list: 'ordered' },
  { list: 'bullet' },
];

export const modules = {
  toolbar: {
    container: options,
  },
};
