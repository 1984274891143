import type { AddLanguagePairModalValues } from './config';
import { Form } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import LanguageSelect from 'components/ui/atoms/LanguageSelect';
import Modal from 'components/ui/molecules/Modal';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import SwitchLanguagePairButton from './SwitchLanguagePairButton';

const { useForm } = Form;

type Props<TMultiple extends boolean> = {
  isOpen: boolean;
  langToMultiple?: boolean;
  onClose: () => void;
  onAdd: (props: AddLanguagePairModalValues<TMultiple>) => void;
};

const AddLanguagePairModal = <TMultiple extends boolean>({
  isOpen,
  onClose,
  onAdd,
  langToMultiple = false as TMultiple,
}: Props<TMultiple>) => {
  const [form] = useForm<AddLanguagePairModalValues<typeof langToMultiple>>();
  const langToMode = langToMultiple ? 'multiple' : undefined;

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleOnSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onAdd(values);
        handleClose();
      })
      .catch((err) => err);
  };

  useEffect(() => {
    if (!isOpen) return;

    form.setFieldValue('langFrom', LanguageCode.POLAND);

    if (langToMultiple) {
      form.setFieldValue('langTo', [LanguageCode.ENGLISH]);

      return;
    }

    form.setFieldValue('langTo', LanguageCode.ENGLISH);
  }, [form, isOpen, langToMultiple]);

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Dodaj parę językową" />}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={handleClose}
      okText={<FormattedMessage defaultMessage="Dodaj" />}
      disableFormStyles
      disableModalStyles
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <FormItem<AddLanguagePairModalValues>
          label={<FormattedMessage defaultMessage="Tłumaczenie z języka" />}
          name="langFrom"
          component={
            <LanguageSelect data-testid="langFromSelect" allowClear={false} />
          }
          rules={[
            {
              required: true,
              message: <FormattedMessage defaultMessage="Wybierz język" />,
            },
          ]}
        />
        <SwitchLanguagePairButton form={form} langToMultiple={langToMultiple} />
        <FormItem<AddLanguagePairModalValues>
          label={<FormattedMessage defaultMessage="Tłumaczenie na język" />}
          name="langTo"
          component={
            <LanguageSelect
              data-testid="langToSelect"
              allowClear={false}
              mode={langToMode}
            />
          }
          rules={[
            {
              required: true,
              message: <FormattedMessage defaultMessage="Wybierz język" />,
            },
          ]}
        />
      </Form>
    </Modal>
  );
};

export default AddLanguagePairModal;
