import type { PreorderVariantSlice } from 'ducks/view/preorders';
import type { FC } from 'react';
import { Col } from 'antd';
import { useMemo } from 'react';
import FormItem from 'components/ui/atoms/FormItem';
import { useAppSelector } from 'ducks/store';
import { useCalcTranslations } from 'hooks/useCalcTranslations';
import { calcVariantTotalRealizationTime } from 'utilities/calcPreorderRealizationTime';
import styles from '../../styles.module.scss';
import ProformaSwitches from './components/ProformaSwitches';
import {
  preorderVariantSummarySystemItems,
  preorderVariantSummaryFormItems,
} from './config';

type Props = {
  variant: PreorderVariantSlice;
};

const PreorderVariantSummaryForm: FC<Props> = ({ variant }) => {
  const { proformaType, timeRealization, comment, preorderVariantFiles } =
    variant;
  const { isEditable, isDraftCalculation } = useAppSelector(
    ({ preorders }) => ({
      isEditable: preorders.isEditable,
      isDraftCalculation: preorders.isDraftCalculation,
    }),
  );

  const { estimatedPrice, isLoading: isEstimatedPriceLoading } =
    useCalcTranslations({
      preorderVariantFiles,
    });

  const systemTimeRealization = useMemo(
    () => calcVariantTotalRealizationTime(variant),
    [variant],
  );

  return (
    <Col xs={24} md={12}>
      {!isDraftCalculation &&
        preorderVariantSummarySystemItems({
          systemTimeRealization,
          estimatedPrice,
          isEstimatedPriceLoading,
        }).map(({ key, label }) => (
          <div key={key} className={styles.formItem}>
            {label}
          </div>
        ))}
      {!isDraftCalculation &&
        preorderVariantSummaryFormItems({
          isEditable,
          timeRealization,
          comment,
        }).map(({ key, ...props }) => (
          <FormItem key={key} className={styles.formItem} {...props} />
        ))}
      <ProformaSwitches proformaType={proformaType} variantId={variant.id} />
    </Col>
  );
};

export default PreorderVariantSummaryForm;
