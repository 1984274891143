import type { SendInvoiceVatRequest } from 'ducks/invoices/types';

export type InvoiceSendValues = Omit<
  SendInvoiceVatRequest['body'],
  'invoices' | 'name'
> & {
  isInvoiceVat: true;
};

export const initialValues: InvoiceSendValues = {
  email: '',
  cc: '',
  subject: '',
  body: '',
  isSms: false,
  smsText: '',
  isInvoiceVat: true,
};
