import { FormattedDate, FormattedTime } from 'react-intl';

type Props = {
  date: Date | string;
  hideTime?: boolean;
};

const FormatDate: React.FC<Props> = ({ date, hideTime = false }) => (
  <>
    <FormattedDate value={date} year="numeric" month="long" day="2-digit" />

    {!hideTime && (
      <>
        {', '} <FormattedTime value={date} />
      </>
    )}
  </>
);

export default FormatDate;
