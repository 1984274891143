import type { InvoiceAddValues } from '../../config';
import type { FormInstance } from 'antd';
import type { FC } from 'react';
import { Form, Input } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useInvoiceAddServices } from '../../useInvoiceAddServices';
import {
  initialInvoiceSettingsValues,
  invoiceSettingsformItems,
} from './config';
import { generateInvoiceSettingsComment } from './utils';

type Props = {
  form: FormInstance<InvoiceAddValues>;
};

const InvoiceSettingsSection: FC<Props> = ({ form }) => {
  const intl = useIntl();
  const { invoiceData, isInvoiceDataFetching } = useInvoiceAddServices();

  useEffect(() => {
    const orders = invoiceData?.orders ?? [];

    form.setFieldValue('comment', generateInvoiceSettingsComment(intl, orders));
  }, [invoiceData?.orders, form, intl]);

  return (
    <CardCommon
      title={<FormattedMessage defaultMessage="Ustawienia faktury" />}
      xs={24}
      isLoading={isInvoiceDataFetching}
    >
      <Form
        form={form}
        initialValues={initialInvoiceSettingsValues}
        autoComplete="off"
        layout="vertical"
      >
        <div className="gridForm">
          {invoiceData &&
            invoiceSettingsformItems({
              intl,
              vatRate: invoiceData.vatRate,
              currency: invoiceData.currency,
              paid: invoiceData.paid,
              sumPriceBrutto: invoiceData.sumPriceBrutto,
            }).map(({ key, ...props }) => <FormItem key={key} {...props} />)}
        </div>
        <FormItem<InvoiceAddValues>
          label={<FormattedMessage defaultMessage="Uwagi" />}
          name="comment"
          component={<Input.TextArea data-testid="comment" rows={8} />}
        />
      </Form>
    </CardCommon>
  );
};

export default InvoiceSettingsSection;
