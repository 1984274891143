import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import { LogsTag, type GetLogsResponse } from './types';

export enum Endpoint {
  GetLogs = '/logs',
}

export const logsApi = createApi({
  reducerPath: 'logsApi',
  baseQuery: baseQuery(),
  tagTypes: [LogsTag.Logs],
  endpoints: (build) => ({
    getLogs: build.query<GetLogsResponse['data'], void>({
      query: () => ({
        url: Endpoint.GetLogs,
        params: { page: 1 },
        method: 'GET',
      }),
      transformResponse: (response: GetLogsResponse) => response?.data ?? [],
      providesTags: [LogsTag.Logs],
    }),
  }),
});

export const { useGetLogsQuery } = logsApi;
