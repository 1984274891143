import { Space } from 'antd';
import OrderFilesAndServicesTable from 'components/ui/organisms/Tables/OrderFilesAndServicesTable';
import { useOrderDetailsServices } from '../../useOrderDetailsServices';
import styles from './styles.module.scss';

const OrdersTab = () => {
  const { isOrderLoading } = useOrderDetailsServices();

  return (
    <Space
      data-testid="ordersTab"
      className={styles.wrapper}
      direction="vertical"
      size="large"
    >
      <OrderFilesAndServicesTable isLoading={isOrderLoading} />
    </Space>
  );
};

export default OrdersTab;
