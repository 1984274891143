import type {
  AddEmailTemplateRequest,
  EditEmailTemplateRequest,
  GetEmailTemplateRequest,
  GetEmailTemplateResponse,
  GetEmailTemplatesResponse,
  GetSettingsRequest,
  GetSettingsResponse,
  SendPresentationRequest,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import { SettingsTag } from './types';

export enum Endpoint {
  GetOptions = '/options/:sectionId',
  SendOptions = '/options/:sectionId',
  SendPresentation = '/options/:sectionId',
  GetEmailTemplates = '/email-template',
  AddEmailTemplate = '/email-template',
  GetEmailTemplate = '/email-template/:id',
  EditEmailTemplate = '/email-template/:id',
}

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: baseQuery(),
  tagTypes: [SettingsTag.SETTINGS, SettingsTag.EMAIL_TEMPLATES],
  endpoints: (build) => ({
    getSettings: build.query<GetSettingsResponse, GetSettingsRequest>({
      query: ({ sectionId }) => ({
        url: Endpoint.GetOptions.replace(':sectionId', sectionId),
        method: 'GET',
      }),
      providesTags: [SettingsTag.SETTINGS],
    }),
    sendSettings: build.mutation<void, GetSettingsRequest>({
      query: ({ sectionId, ...data }) => ({
        url: Endpoint.SendOptions.replace(':sectionId', sectionId),
        method: 'PATCH',
        data: { ...data },
      }),
      invalidatesTags: [SettingsTag.SETTINGS],
    }),
    sendPresentation: build.mutation<void, SendPresentationRequest>({
      query: ({ sectionId, file, key }) => {
        const formData = new FormData();

        formData.append(key, new Blob([file]), file.name);

        return {
          url: Endpoint.SendPresentation.replace(':sectionId', sectionId),
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      invalidatesTags: [SettingsTag.SETTINGS],
    }),
    getEmailTemplates: build.query<GetEmailTemplatesResponse, void>({
      query: () => ({
        url: Endpoint.GetEmailTemplates,
        method: 'GET',
      }),
      providesTags: [SettingsTag.EMAIL_TEMPLATES],
    }),
    getEmailTemplate: build.query<
      GetEmailTemplateResponse,
      GetEmailTemplateRequest
    >({
      query: ({ id }) => ({
        url: Endpoint.GetEmailTemplate.replace(':id', id),
        method: 'GET',
      }),
      providesTags: [SettingsTag.EMAIL_TEMPLATES],
    }),
    addEmailTemplate: build.mutation<void, AddEmailTemplateRequest>({
      query: (data) => ({
        url: Endpoint.AddEmailTemplate,
        method: 'POST',
        data,
      }),
      invalidatesTags: [SettingsTag.EMAIL_TEMPLATES],
    }),
    editEmailTemplate: build.mutation<void, EditEmailTemplateRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.EditEmailTemplate.replace(':id', id),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: [SettingsTag.EMAIL_TEMPLATES],
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useSendSettingsMutation,
  useSendPresentationMutation,
  useGetEmailTemplatesQuery,
  useGetEmailTemplateQuery,
  useAddEmailTemplateMutation,
  useEditEmailTemplateMutation,
} = settingsApi;
