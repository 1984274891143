import type { FormInstance } from 'antd';
import type { FC } from 'react';
import Loader from 'components/ui/atoms/Loader';
import CardCommon from 'components/ui/molecules/CardCommon';
import SendEmailForm from 'components/ui/organisms/Forms/SendEmailForm/SendEmailForm';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setInvoiceEmailTemplate } from 'ducks/view/invoices';
import { useInvoiceSendServices } from '../../useInvoiceSendServices';

type Props = {
  form: FormInstance;
};

const TemplateSettingsSection: FC<Props> = ({ form }) => {
  const dispatch = useAppDispatch();
  const emailTemplate = useAppSelector(
    ({ invoices }) => invoices.emailTemplate,
  );
  const { isInvoiceVatFetching } = useInvoiceSendServices();

  const handleEmailTemplateChange = (value: string) =>
    dispatch(setInvoiceEmailTemplate(value));

  return (
    <CardCommon xs={24} md={14} lg={15}>
      <Loader visible={isInvoiceVatFetching}>
        <SendEmailForm
          form={form}
          emailTemplate={emailTemplate}
          onEmailTemplateChange={handleEmailTemplateChange}
        />
      </Loader>
    </CardCommon>
  );
};

export default TemplateSettingsSection;
