import type { AddUserModalFormItem } from '../AddUserModal/config';
import type { Dayjs } from 'dayjs';
import type { EditUserRequest } from 'ducks/users/types';
import { addUserModalFormItems } from '../AddUserModal/config';

export type EditUserModalValues = Omit<EditUserRequest, 'userId' | 'number'> & {
  allowTime?: Dayjs[];
};

export type EditUserModalFormItem = Omit<AddUserModalFormItem, 'name'> & {
  name: keyof EditUserModalValues;
};

type EditField = keyof EditUserRequest;

const editFields: EditField[] = [
  'firstName',
  'lastName',
  'email',
  'role',
  'isActive',
];

export const editUserModalFormItems: EditUserModalFormItem[] =
  addUserModalFormItems.filter(({ name }) =>
    editFields.includes(name as EditField),
  ) as EditUserModalFormItem[];
