import type { PreorderVariantTableValues } from './config';
import type {
  UpdatePreorderVariantFilePayload,
  UpdatePreorderVariantPayload,
  PreorderVariantSlice,
  PreorderVariantFileSlice,
} from 'ducks/view/preorders';
import type { FC } from 'react';
import { Divider, Form, Space } from 'antd';
import classNames from 'classnames';
import { memo, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  updatePreorderVariant,
  updatePreorderVariantDiscounts,
  updatePreorderVariantFile,
} from 'ducks/view/preorders';
import { getNestedKeyValue } from 'utilities/object';
import PreorderVariantModals from './components/PreorderVariantModals';
import PreorderVariantServicesTable from './components/PreorderVariantServicesTable';
import PreorderVariantSummary from './components/PreorderVariantSummary';
import PreorderVariantTranslationsTable from './components/PreorderVariantTranslationsTable';
import styles from './styles.module.scss';

const { useForm } = Form;

type VariantFileField = UpdatePreorderVariantFilePayload['field'];

type Field = UpdatePreorderVariantPayload['field'] | VariantFileField;

type Props = {
  variant: PreorderVariantSlice;
};

const PreorderVariantTable: FC<Props> = memo(({ variant }) => {
  const {
    id,
    preorderVariantFiles,
    preorderVariantServices,
    repetition,
    ...restVariant
  } = variant;
  const dispatch = useAppDispatch();
  const [form] = useForm<PreorderVariantTableValues>();
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
  const initialValues: Partial<PreorderVariantTableValues> = {
    ...restVariant,
    repetition,
  };

  const handleSetVariantFormValues = useCallback(
    (variantFile: PreorderVariantFileSlice) => {
      form.setFieldValue([variantFile.id], variantFile);

      variantFile.preorderVariantLangs.forEach((variantLang) => {
        form.setFieldValue([variantFile.id, variantLang.id], variantLang);
      });
    },
    [form],
  );

  const handleValuesChange = useCallback(
    (field: Field) => {
      const [, value] = getNestedKeyValue(field);
      const isPreorderVariantLevel =
        value === null || typeof value !== 'object';

      if (isPreorderVariantLevel)
        dispatch(
          updatePreorderVariant({
            id,
            field,
          }),
        );

      if (!isPreorderVariantLevel)
        dispatch(
          updatePreorderVariantFile({
            variantId: id,
            field: field as VariantFileField,
          }),
        );

      dispatch(updatePreorderVariantDiscounts({ variantId: id }));
    },
    [dispatch, id],
  );

  useEffect(() => {
    preorderVariantFiles.forEach(handleSetVariantFormValues);
  }, [preorderVariantFiles, handleSetVariantFormValues]);

  return (
    <>
      <Space
        className={classNames(styles.wrapper, {
          [styles.wrapperEditable]: isEditable,
        })}
        direction="vertical"
        size="large"
      >
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={handleValuesChange}
          autoComplete="off"
        >
          <PreorderVariantTranslationsTable
            variantId={id}
            variantFiles={preorderVariantFiles}
            repetition={repetition}
          />
        </Form>
        <PreorderVariantServicesTable
          variantId={id}
          variantServices={preorderVariantServices}
        />
        <Divider style={{ margin: 0 }} />
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={handleValuesChange}
          layout="vertical"
          autoComplete="off"
        >
          <PreorderVariantSummary variant={variant} />
        </Form>
      </Space>
      <PreorderVariantModals variantId={id} />
    </>
  );
});

export default PreorderVariantTable;
