import type { FC } from 'react';
import type { InvoiceListItemRow } from 'views/Invoices/config';
import { Checkbox } from 'antd';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { toggleSelectedInvoicesVat } from 'ducks/view/invoices';

type Props = {
  invoiceVat: InvoiceListItemRow;
};

const CheckboxCell: FC<Props> = ({ invoiceVat }) => {
  const dispatch = useAppDispatch();
  const selectedInvoicesVat = useAppSelector(
    ({ invoices }) => invoices.selectedInvoicesVat,
  );

  const isChecked = useMemo(
    () => selectedInvoicesVat.some(({ id }) => id === invoiceVat.id),
    [invoiceVat.id, selectedInvoicesVat],
  );

  const handleChange = () => dispatch(toggleSelectedInvoicesVat(invoiceVat));

  return <Checkbox checked={isChecked} onChange={handleChange} />;
};

export default CheckboxCell;
