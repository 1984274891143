/* eslint-disable react/jsx-props-no-spreading */
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useOrderDetailsServices } from '../../useOrderDetailsServices';
import { formItems } from './config';

const ClientSection = () => {
  const { orderData, isOrderLoading } = useOrderDetailsServices();

  return (
    <CardCommon
      data-testid="clientSection"
      title={<FormattedMessage defaultMessage="Profil klienta" />}
      isLoading={isOrderLoading}
    >
      <Form layout="vertical" autoComplete="off">
        <div className="gridForm">
          {orderData &&
            formItems({
              data: orderData,
            }).map(({ key, ...props }) => <FormItem key={key} {...props} />)}
        </div>
      </Form>
    </CardCommon>
  );
};

export default ClientSection;
