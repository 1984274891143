import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type {
  GetInvoicesVatFilter,
  InvoiceListItem,
} from 'ducks/invoices/types';
import { FormattedMessage, type IntlShape } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { invoiceStatusesObject } from 'constants/invoice';
import { NO_DATA } from 'constants/strings';
import { InvoiceStatus } from 'ducks/invoices/types';

export type InvoiceListItemRow = TableRow & InvoiceListItem;

type Columns = ColumnsType<InvoiceListItemRow>;

type Props = {
  intl: IntlShape;
  priceColumn?: Columns[number];
};

export const initialInvoicesFilters: GetInvoicesVatFilter = {
  status: null,
};

export const invoiceListItemColumns = ({
  intl,
  priceColumn,
}: Props): Columns => [
  {
    key: 'number',
    dataIndex: 'number',
    title: <FormattedMessage defaultMessage="Numer zamówienia" />,
    width: 60,
  },
  {
    key: 'clientName',
    dataIndex: 'clientName',
    title: <FormattedMessage defaultMessage="Nazwa klienta" />,
    render: (_, { client }) => client.clientName ?? NO_DATA,
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: <FormattedMessage defaultMessage="Status" />,
    // TODO: Remove this when the status for invoices is ready
    render: (_, { status }) =>
      invoiceStatusesObject(intl)[
        status in InvoiceStatus ? status : InvoiceStatus.NOT_PAID
      ].label,
    // render: (_, { status }) => invoiceStatusesObject(intl)[status].label,
  },
  priceColumn ?? {
    key: 'priceNetto',
    dataIndex: 'priceNetto',
    title: <FormattedMessage defaultMessage="Wartość zamówienia" />,
    render: (_, { priceNetto }) => <CurrencyFormatter value={priceNetto} />,
  },
  {
    key: 'payed',
    dataIndex: 'payed',
    title: <FormattedMessage defaultMessage="Zapłacono" />,
    render: (_, { payed }) => <CurrencyFormatter value={payed} />,
  },
];
