import type { LangFileRow } from './config';
import type { FC } from 'react';
import { notification } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import DownloadPreorderZipFilesButton from 'components/ui/molecules/DownloadPreorderZipFilesButton';
import Table from 'components/ui/molecules/Table';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import { usePreorderDetailsServices } from '../../usePreorderDetailsServices';
import { columns } from './config';

type Props = {
  preorderNumber: string | undefined;
};

const LangFilesTable: FC<Props> = ({ preorderNumber }) => {
  const intl = useIntl();
  const { id: preorderId = '' } = useParams();
  const [notify, contextHolder] = notification.useNotification();
  const { preorderVariantsData, isPreorderVariantsLoading } =
    usePreorderDetailsServices();

  const modifiedData: LangFileRow[] = useMemo(
    () =>
      Object.values(
        preorderVariantsData
          .flatMap(({ variantIdx, preorderVariantFiles }) =>
            preorderVariantFiles.map((file) => ({
              ...file,
              variantIdx,
            })),
          )
          .reduce((prevVal, curVal) => {
            const fileName = curVal.file.filename;
            const variantIndexes = prevVal?.[fileName]?.variantsIdxes;
            const variantsIdxes = prevVal?.[fileName]?.variantsIdxes
              ? [...variantIndexes, curVal?.variantIdx]
              : [curVal?.variantIdx];

            return {
              ...prevVal,
              [fileName]: {
                key: curVal.id,
                variantsIdxes,
                ...curVal,
              },
            };
          }, {} as { [key: string]: LangFileRow }),
      ),
    [preorderVariantsData],
  );

  return (
    <>
      {contextHolder}
      <Table
        data-testid="langFilesTable"
        cardTitle={
          <TitleWrapper>
            <FormattedMessage defaultMessage="Pliki" />
            {!!modifiedData.length && (
              <DownloadPreorderZipFilesButton
                notify={notify}
                preorderId={preorderId}
                fileName={intl.formatMessage(
                  {
                    defaultMessage: 'wycena_{name}',
                  },
                  { name: preorderNumber },
                )}
              />
            )}
          </TitleWrapper>
        }
        columns={columns}
        data={modifiedData}
        isLoading={isPreorderVariantsLoading}
        hidePagination
      />
    </>
  );
};

export default LangFilesTable;
