import type { TranslationsAndOrdersFile } from 'ducks/orders/types';
import type { FC } from 'react';
import { Popconfirm, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import TruncateText from 'components/ui/atoms/TruncateText';
import { useDeleteFileMutation } from 'ducks/global/service';
import { FileType } from 'ducks/global/types';
import { useAppDispatch } from 'ducks/store';
import { deleteOrderTranslationUploadedFile } from 'ducks/view/orderTranslationFiles';
import { TrashIcon } from 'icons';
import styles from './styles.module.scss';
import TranslationFileItemDescription from './TranslationFileItemDescription';
import { useTranslationFileItemHelpers } from './useTranslationFileItemHelpers';

type Props = {
  translationFile: TranslationsAndOrdersFile;
};

const TranslationFileItem: FC<Props> = ({ translationFile }) => {
  const { id: translationFileId, filename } = translationFile;
  const dispatch = useAppDispatch();
  const [notify, contextHolder] = notification.useNotification();
  const [deleteFile, { isLoading }] = useDeleteFileMutation();
  const { isFileAssigned } = useTranslationFileItemHelpers(translationFileId);
  const hideDeleteButton = !isFileAssigned;

  const handleDelete = async () => {
    if (isLoading || !hideDeleteButton) return;

    try {
      await deleteFile({
        id: translationFileId,
        object: FileType.TRANSLATIONS_FILES,
      }).unwrap();

      dispatch(deleteOrderTranslationUploadedFile(translationFileId));

      notify.success({
        message: <FormattedMessage defaultMessage="Plik został usunięty." />,
      });
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas usuwania pliku." />
        ),
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      {contextHolder}
      <div className={styles.content}>
        <TruncateText maxLength={50}>{filename}</TruncateText>
        <TranslationFileItemDescription translationFile={translationFile} />
      </div>
      {hideDeleteButton && (
        <Popconfirm
          title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
          okText={<FormattedMessage defaultMessage="Tak" />}
          cancelText={<FormattedMessage defaultMessage="Nie" />}
          onConfirm={handleDelete}
          disabled={isLoading}
        >
          <Button
            loading={isLoading}
            size="small"
            type="link"
            icon={<TrashIcon />}
            danger
          >
            <FormattedMessage defaultMessage="Usuń" />
          </Button>
        </Popconfirm>
      )}
    </div>
  );
};

export default TranslationFileItem;
