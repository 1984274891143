import { memo } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch } from 'ducks/store';
import {
  updatePreorderVariantDiscounts,
  updatePreorderVariantFile,
} from 'ducks/view/preorders';
import SelectorInput from '../SelectorInput';
import { generateCharacterOptions } from './utils';

type Props = {
  variantId: string | number;
  variantFileId: string | number;
  variantLangId: string | number;
  value: number;
  isSworn: boolean;
};

const CharactersSelector = memo(
  ({
    variantId,
    variantFileId,
    variantLangId,

    value,
    isSworn,
  }: Props) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const generatedOptions = generateCharacterOptions({ isSworn, intl });
    const options = [
      // Filter is for showing characters value instead of label
      ...generatedOptions.filter((option) => option.value !== value),
      { value, label: value },
    ];

    const handleUpdateCharacters = (characters: number) => {
      dispatch(
        updatePreorderVariantFile({
          variantId,
          field: {
            [variantFileId]: {
              [variantLangId]: { characters },
            },
          },
        }),
      );
      dispatch(updatePreorderVariantDiscounts({ variantId }));
    };

    const handleSelectSearch = (newValue: string) => {
      const numberValue = Number(newValue);

      if (!newValue || Number.isNaN(numberValue)) return;

      handleUpdateCharacters(numberValue);
    };

    const handleSelect = (newValue: number) => handleUpdateCharacters(newValue);

    return (
      <SelectorInput
        dataTestId={`charactersInput-${variantLangId}`}
        value={value}
        onSearch={handleSelectSearch}
        onSelect={handleSelect}
        options={options}
      />
    );
  },
);

export default CharactersSelector;
