import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { GetPreordersFilter, PreorderData } from 'ducks/preorders/types';
import type { IntlShape } from 'react-intl';
import { Button, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import CopyToClipboard from 'components/ui/atoms/CopyToClipboard';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import FormatDate from 'components/ui/atoms/FormatDate';
import ActiveEditorDotCell from 'components/ui/atoms/TableCells/ActiveEditorDotCell';
import PaymentDollarCell from 'components/ui/atoms/TableCells/PaymentDollarCell';
import ClientAvatar from 'components/ui/molecules/ClientAvatar';
import { PreorderStatus, preorderStatusesObject } from 'constants/preorder';
import { NO_DATA } from 'constants/strings';
import { EditIcon } from 'icons';
import { Routes } from 'views/Preorders/Routing';
import styles from './styles.module.scss';

export type PreorderRow = TableRow & PreorderData;

type Props = {
  intl: IntlShape;
};

export const initialFilters: GetPreordersFilter = {
  status: undefined,
  dateCreationBefore: undefined,
  dateCreationAfter: undefined,
  senderId: undefined,
};

export const columns = ({ intl }: Props): ColumnsType<PreorderRow> => [
  {
    title: <span />,
    key: 'activeEditorId',
    dataIndex: 'activeEditorId',
    width: 45,
    render: (_, { activeEditorId }) => (
      <ActiveEditorDotCell activeEditorId={activeEditorId} />
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Numer" />,
    key: 'number',
    dataIndex: 'number',
    width: 60,
    render: (_, { number }) => number ?? NO_DATA,
  },
  {
    title: <FormattedMessage defaultMessage="Płatność" />,
    key: 'isPaid',
    dataIndex: 'isPaid',
    sorter: true,
    width: 55,
    render: (_, { id, paymentStatus }) => (
      <PaymentDollarCell
        to={Routes.PREORDER_DETAILS.replace(':id', id.toString())}
        paymentStatus={paymentStatus}
      />
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Klient" />,
    key: 'client',
    dataIndex: 'client',
    sorter: true,
    width: 165,
    render: (_, { isCompany, name, contactPerson }) => {
      return (
        <ClientAvatar
          isCompany={isCompany}
          name={name}
          contactPerson={contactPerson}
        />
      );
    },
  },
  {
    title: <FormattedMessage defaultMessage="E-mail" />,
    key: 'email',
    dataIndex: 'email',
    width: 260,
    render: (_, { email }) => <CopyToClipboard text={email} />,
  },
  {
    title: <FormattedMessage defaultMessage="Telefon" />,
    key: 'phone',
    dataIndex: 'phone',
    width: 165,
    render: (_, { phone }) =>
      phone ? <CopyToClipboard text={phone} /> : NO_DATA,
  },
  {
    title: <FormattedMessage defaultMessage="Cena netto" />,
    key: 'price',
    dataIndex: 'price',
    sorter: true,
    render: (_, { priceMin, priceMax }) => {
      if (!priceMin || !priceMax) return NO_DATA;

      if (priceMin === priceMax) {
        return <CurrencyFormatter value={priceMin} />;
      }

      return (
        <span>
          <CurrencyFormatter value={priceMin} />
          (<CurrencyFormatter value={priceMax} />)
        </span>
      );
    },
  },
  {
    title: <FormattedMessage defaultMessage="Cena brutto" />,
    key: 'priceBrutto',
    dataIndex: 'priceBrutto',
    render: (_, { priceBruttoMin, priceBruttoMax }) => {
      if (!priceBruttoMin || !priceBruttoMax) return NO_DATA;

      if (priceBruttoMin === priceBruttoMax) {
        return <CurrencyFormatter value={priceBruttoMin} />;
      }

      return (
        <span>
          <CurrencyFormatter value={priceBruttoMin} />
          (<CurrencyFormatter value={priceBruttoMax} />)
        </span>
      );
    },
  },
  {
    title: <FormattedMessage defaultMessage="Status" />,
    key: 'status',
    dataIndex: 'status',
    sorter: true,
    defaultSortOrder: 'ascend',
    render: (_, { status }) => preorderStatusesObject(intl)[status].label,
  },
  {
    title: <FormattedMessage defaultMessage="Data wysyłania" />,
    key: 'dateSent',
    dataIndex: 'dateSent',
    sorter: true,
    render: (_, { dateSent }) =>
      dateSent ? <FormatDate date={dateSent} /> : NO_DATA,
  },
  {
    title: <FormattedMessage defaultMessage="Akcje" />,
    key: 'actions',
    width: 150,
    align: 'center',
    render: ({ id, status }) => (
      <Space
        className={styles.tableActions}
        onClick={(e) => e.stopPropagation()}
      >
        {status !== PreorderStatus.IN_REALIZATION && (
          <Link to={Routes.PREORDER_EDIT.replace(':id', id.toString())}>
            <Button type="link" icon={<EditIcon />}>
              <FormattedMessage defaultMessage="Edytuj" />
            </Button>
          </Link>
        )}
      </Space>
    ),
  },
];
