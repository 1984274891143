/* eslint-disable react/jsx-props-no-spreading */
import type { ResponseFormItem } from 'ducks/settings/types';
import { Form } from 'antd';
import { DictionaryOfInputs, fieldSettings, getInput } from './config';

const { Item } = Form;

type Props = {
  formElements: ResponseFormItem[];
};

const DynamicForm: React.FC<Props> = ({ formElements }) => {
  return (
    <>
      {formElements.map(({ id, name, value, type }) => (
        <Item
          name={name}
          key={id}
          initialValue={value}
          label={fieldSettings?.[name]?.label ?? ''}
          rules={fieldSettings?.[name]?.rules ?? []}
          valuePropName={
            type === DictionaryOfInputs.CHECKBOX ? 'checked' : 'value'
          }
        >
          {getInput({
            type,
            ...fieldSettings?.[name]?.addictionalProps,
          })}
        </Item>
      ))}
    </>
  );
};

export default DynamicForm;
