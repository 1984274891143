import type {
  AddPriceRequest,
  AddPriceResponse,
  DeletePriceItemRequest,
  DeletePriceRequest,
  EditPriceRequest,
  GetPriceDetailsRequest,
  GetPriceDetailsResponse,
  GetPricesResponse,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import { convertKeysToSnakeCase } from 'utilities/object';
import { PricesTag } from './types';

export enum Endpoint {
  GetPrices = '/prices',
  AddPrice = '/prices',
  DeletePrice = '/prices/:id',
  GetPriceDetails = '/prices/:id',
  EditPrice = '/prices/:id',
  DeletePriceItem = '/prices/:id/price-lists/:itemId',
}

export const pricesApi = createApi({
  reducerPath: 'pricesApi',
  baseQuery: baseQuery(),
  tagTypes: [PricesTag.Prices],
  endpoints: (build) => ({
    getPrices: build.query<GetPricesResponse, void>({
      query: () => ({
        url: Endpoint.GetPrices,
        method: 'GET',
      }),
      providesTags: [PricesTag.Prices],
    }),
    addPrice: build.mutation<AddPriceResponse, AddPriceRequest>({
      query: (data) => ({
        url: Endpoint.AddPrice,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) => (error ? [] : [PricesTag.Prices]),
    }),
    deletePrice: build.mutation<void, DeletePriceRequest>({
      query: ({ id }) => ({
        url: Endpoint.DeletePrice.replace(':id', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => (error ? [] : [PricesTag.Prices]),
    }),
    getPriceDetails: build.query<
      GetPriceDetailsResponse,
      GetPriceDetailsRequest
    >({
      query: ({ id }) => ({
        url: Endpoint.GetPriceDetails.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: [PricesTag.Prices],
    }),
    editPrice: build.mutation<void, EditPriceRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.EditPrice.replace(':id', id.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) => (error ? [] : [PricesTag.Prices]),
    }),
    deletePriceItem: build.mutation<void, DeletePriceItemRequest>({
      query: ({ id, itemId }) => ({
        url: Endpoint.DeletePriceItem.replace(':id', id.toString()).replace(
          ':itemId',
          itemId.toString(),
        ),
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => (error ? [] : [PricesTag.Prices]),
    }),
  }),
});

export const {
  useGetPricesQuery,
  useAddPriceMutation,
  useGetPriceDetailsQuery,
  useEditPriceMutation,
  useDeletePriceMutation,
  useDeletePriceItemMutation,
} = pricesApi;
