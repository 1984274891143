import { FormattedMessage } from 'react-intl';
import { NO_DATA } from 'constants/strings';

type Args = {
  timeRealization: number | undefined;
  suffixLabel?: string;
  prefixLabel?: string;
};

export const generateWorkingDaysLabel = ({
  timeRealization,
  suffixLabel,
  prefixLabel,
}: Args) => {
  if (!timeRealization) return <span>{NO_DATA}</span>;

  if (timeRealization >= 5)
    return (
      <FormattedMessage
        defaultMessage="{prefixLabel}{timeRealizationTotal} dni roboczych{suffixLabel}"
        values={{
          timeRealizationTotal: timeRealization,
          suffixLabel,
          prefixLabel,
        }}
      />
    );

  if (timeRealization >= 2)
    return (
      <FormattedMessage
        defaultMessage="{prefixLabel}{timeRealizationTotal} dni robocze{suffixLabel}"
        values={{
          timeRealizationTotal: timeRealization,
          suffixLabel,
          prefixLabel,
        }}
      />
    );

  return (
    <FormattedMessage
      defaultMessage="{prefixLabel}{timeRealizationTotal} dzień roboczy{suffixLabel}"
      values={{
        timeRealizationTotal: timeRealization,
        suffixLabel,
        prefixLabel,
      }}
    />
  );
};
