import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { useAppDispatch } from 'ducks/store';
import { setOrderAssignAddLanguagesModal } from 'ducks/view/orders';
import { PlusIcon } from 'icons';
import styles from './styles.module.scss';

interface Props {
  orderFileId: number;
}

const AddLangButton: FC<Props> = ({ orderFileId }) => {
  const dispatch = useAppDispatch();

  const handleOpenAddLanguageModal = () => {
    dispatch(setOrderAssignAddLanguagesModal({ isOpen: true, orderFileId }));
  };

  return (
    <Button
      className={styles.button}
      type="link"
      size="small"
      icon={<PlusIcon />}
      onClick={handleOpenAddLanguageModal}
    >
      <FormattedMessage defaultMessage="Dodaj parę językową" />
    </Button>
  );
};

export default AddLangButton;
