import type { FC } from 'react';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import { useAppSelector } from 'ducks/store';
import styles from './styles.module.scss';

type Size = 'small' | 'medium' | 'large';

interface Props {
  size?: Size;
}

const SkeletonColumnPlaceholder: FC<Props> = ({ size = 'medium' }) => {
  const isEditable = useAppSelector((state) => state.orders.isEditable);

  return isEditable ? (
    <Skeleton.Button
      className={classNames(styles.skeletonButton, {
        [styles.skeletonButtonSmall]: size === 'small',
        [styles.skeletonButtonLarge]: size === 'large',
      })}
      active
      style={{ width: '100%' }}
    />
  ) : (
    <Skeleton paragraph={false} active style={{ width: '110px' }} />
  );
};

export default SkeletonColumnPlaceholder;
