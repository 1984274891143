import type { FormInstance } from 'antd';

type MutationFunctionWithForm<TResponse, TForm> = {
  form: FormInstance<TForm>;
  mutationFn: (values: TForm) => Promise<TResponse>;
  onSuccess?: (response: TResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
};

export const mutationFunctionWithForm = async <TResponse, TForm>({
  form,
  mutationFn,
  onSuccess,
  onError,
  onSettled,
}: MutationFunctionWithForm<TResponse, TForm>) => {
  try {
    const values = await form.validateFields();

    const response = await mutationFn(values);

    onSuccess?.(response);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error?.errorFields) return;

    onError?.();
  } finally {
    onSettled?.();
  }
};
