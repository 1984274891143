import { Skeleton } from 'antd';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { NO_DATA } from 'constants/strings';
import { useGetPaymentsQuery } from 'ducks/payments/service';
import { PaymentObject } from 'ducks/payments/types';
import { useAppDispatch } from 'ducks/store';
import { setTriggerScrollToPayments } from 'ducks/view/preorders';
import { calcPaymentsTotal } from 'utilities/calcPaymentsTotal';
import { usePreorderDetailsServices } from 'views/Preorders/views/PreorderDetails';
import styles from './styles.module.scss';

const PaymentsTotalSection = () => {
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();
  const { preorderData } = usePreorderDetailsServices();
  const { data: paymentsData = [], isFetching } = useGetPaymentsQuery(
    {
      paymentObject: PaymentObject.PREORDERS,
      id: Number(id),
    },
    { skip: !preorderData },
  );
  const currency = paymentsData[paymentsData.length - 1]?.currency;

  const handleClick = () => dispatch(setTriggerScrollToPayments(true));

  const totalPayments = useMemo(
    () => calcPaymentsTotal({ paymentsData }),
    [paymentsData],
  );

  return isFetching ? (
    <Skeleton active paragraph={false} style={{ width: '75px' }} />
  ) : (
    <button onClick={handleClick} type="button" className={styles.button}>
      {totalPayments && !!paymentsData ? (
        <CurrencyFormatter value={totalPayments} currency={currency} />
      ) : (
        NO_DATA
      )}
    </button>
  );
};

export default PaymentsTotalSection;
