import type { OrderSendEmailsTranslatorState } from 'ducks/view/orders';

export const validateTranslatorFields = (
  translators: OrderSendEmailsTranslatorState[],
) => {
  const notValidTranslators: OrderSendEmailsTranslatorState[] = [];

  translators.forEach((translator) => {
    const { subject, email, body } = translator;

    if (!subject || !email || !body) {
      notValidTranslators.push(translator);
    }
  });

  return notValidTranslators;
};
