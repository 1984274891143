import type { GetPaymentIndividualsFilter } from 'ducks/payments/types';
import type { FC } from 'react';
import type { RangeDateChange } from 'utilities/date';
import { DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import { generateRangeDateValue } from 'utilities/date';
import styles from './styles.module.scss';

const { RangePicker } = DatePicker;

type Props = {
  filterBy: GetPaymentIndividualsFilter;
  onFilterByChange: (filter: GetPaymentIndividualsFilter) => void;
};

const InvoicesPaymentIndividualsToolbar: FC<Props> = ({
  filterBy: { datePaymentBefore, datePaymentAfter },
  onFilterByChange,
}) => {
  const dateValue = generateRangeDateValue(datePaymentBefore, datePaymentAfter);

  const handleDatePaymentChange = ([after, before]: RangeDateChange) =>
    onFilterByChange({
      datePaymentAfter: after,
      datePaymentBefore: before,
    });

  return (
    <div className={styles.wrapper}>
      <RangePicker
        locale={locale}
        value={dateValue}
        onChange={(_, values) => handleDatePaymentChange(values)}
      />
    </div>
  );
};

export default InvoicesPaymentIndividualsToolbar;
