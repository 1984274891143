export const generateOtherCatObject = ({
  cat,
  id,
}: {
  cat: string | null | undefined;
  id: number;
}) => ({
  id,
  name: cat,
  isMain: true,
});
