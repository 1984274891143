import { FormattedMessage } from 'react-intl';
import List from 'components/ui/molecules/List';
import { useRefHeightContext } from '../../useRefHeightContext';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import CardCommon from '../CardCommon';
import { contactListItems } from './config';

const ContactCard = () => {
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const { sectionHeight } = useRefHeightContext();

  return (
    <CardCommon
      title={<FormattedMessage defaultMessage="Kontakt" />}
      data-testid="contactCard"
      isLoading={isTranslatorFetching}
      md={8}
      style={{
        maxHeight: sectionHeight,
      }}
    >
      <List
        items={contactListItems(translatorData)}
        isLoading={isTranslatorFetching}
      />
    </CardCommon>
  );
};

export default ContactCard;
