import type { LanguageCode } from 'constants/countryAndLanguageCodes';
import type { FileTranslationType } from 'constants/fileTranslation';
import type { PaymentDetailStatus } from 'constants/payment';
import type { PreorderInvoiceType, PreorderStatus } from 'constants/preorder';
import type { ServiceAttributeNames, ServiceType } from 'constants/services';
import type { Client } from 'ducks/clients/types';
import type { UploadedFile } from 'ducks/global/types';
import type { Order } from 'ducks/orders/types';
import type { Payment } from 'ducks/payments/types';
import type {
  PaginationRequest,
  PaginationResponse,
  SortRequest,
} from 'ducks/types';

export enum PreordersTag {
  Preorders = 'Preorders',
  PreordersVariants = 'PreordersVariants',
}

export enum PreorderRequestTypeParam {
  SHOW = 'show',
}

export enum ProformaType {
  FULL_PAYMENT = 0,
  HALF_PAYMENT = 1,
}

export type GetPreordersSort = SortRequest<
  'client' | 'price' | 'dateSent' | 'status' | 'isPaid'
>;

export type GetPreordersFilter = Partial<{
  status: PreorderStatus[];
  dateCreationBefore: string;
  dateCreationAfter: string;
  senderId: string;
}>;

export type GetPreordersRequest = PaginationRequest &
  Partial<{
    searchTerm: string;
    filter: GetPreordersFilter;
    sort: GetPreordersSort | null;
  }>;

export type GetPreordersResponse = PaginationResponse<PreorderData>;

export type GetPreorderServicesResponse = {
  services: PreorderService[];
};

export type AddPreorderRequest = {
  clientId: number | null;
  companyName: string | null;
  email: string;
  contactPerson: string;
};

export type AddPreorderResponse = {
  id: number;
  clientId: number | null;
  companyName: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type GetPreorderRequest = {
  id: number;
  type?: PreorderRequestTypeParam | null;
};

export type GetPreorderResponse = Preorder;

export type DeletePreorderRequest = {
  id: string | number;
};

export type GetPreorderVariantsRequest = {
  id: number;
};

export type GetPreorderVariantsResponse = PreorderVariant[];

export type UpdateClientPreorderRequest = {
  id: number;
  clientId: number;
};

export type UpdatePreorderRequest = {
  id: number;
  preorder: {
    priceId: number | null;
    clientId: number | null;
    contactPerson: string;
    email: string;
    emailCc: string | null;
    invoiceEmail: string;
    phone: string | null;
    isSms: boolean;
    hasInvoiceDataConvergence: boolean;
    isCompany: boolean;
    name: string | null;
    nip: string | null;
    address: string | null;
    city: string | null;
    postCode: string | null;
    country: string;
    invoiceType: PreorderInvoiceType;
    invoiceAuto: boolean;
    verifiedKrd: boolean;
    verifiedVies: boolean;
    vatRate: number;
  };
  preorderVariants: {
    id: number | null;
    proformaType: ProformaType | null;
    isCertified: boolean;
    timeRealization: number;
    comment: string | null;
    repetition: number | null;
    preorderVariantFiles: {
      id: number | null;
      type: FileTranslationType;
      fileId: number;
      isActive: boolean;
      isSpecial: boolean;
      isCorrection: boolean;
      preorderVariantLangs: {
        id: number | null;
        langFrom: string;
        langTo: string;
        characters: number;
        pages: number;
        discount: number;
        priceNetto: number;
        priceCorrectionNetto: number;
        priceSpecialNetto: number;
        timeRealization: number;
      }[];
    }[];
    preorderVariantServices: {
      id: number | null;
      type: ServiceType;
      isActive: boolean;
      vatRate: number;
      priceNetto: number;
      preorderVariantServiceValues: {
        id: number | null;
        attributeName: ServiceAttributeNames;
        attributeValue: string;
      }[];
    }[];
  }[];
};

export type UnblockPreorderRequest = {
  preorderId: number;
};

export type SendReferenceFileRequest = {
  preorderId: string;
  file: File;
};

export type SendReferenceFileResponse = {
  referenceFile: string;
};

export type SendEmailPreorderRequest = {
  preorderId: number;
  orderId: number | null;
  email: string;
  name: string;
  cc: string | null;
  subject: string;
  body: string;
  variants: number[];
  isSms: boolean;
  smsText: string | null;
  isPresentation: boolean;
  isReferenceFile: boolean;
  files: File[];
};

export type SendPreorderNumberRequest = {
  preorderId: number;
};

export type ConvertPreorderRequest = {
  preorderId: number;
  variantId: number;
  email: string;
  cc: string | null;
  subject: string;
  body: string;
  variants: number[];
  files: File[];
};

export type DeletePreorderCommissionRequest = {
  preorderId: number;
  variantId: number;
};

export type GetPreorderZipFilesRequest = {
  preorderId: number | string;
};

export type GetPreorderZipFilesResponse = unknown;

export type DeletePreorderReferenceFileRequest = {
  preorderId: string;
};

/* ——————————————————————————REGULAR TYPES—————————————————————————— */

export type PreorderData = {
  id: number;
  uuid: string;
  activeEditorId: number | null;
  number: string | null;
  email: string;
  isCompany: boolean;
  phone: string;
  priceMin: number | null;
  priceMax: number | null;
  priceBruttoMin: number | null;
  priceBruttoMax: number | null;
  status: PreorderStatus;
  contactPerson: string;
  name: string;
  paymentStatus: PaymentDetailStatus;
  paidPayments: Payment[];
  preorderVariants: PreorderVariant[];
  onlinePaymentUrl: string;
  dateSent: Date | null;
  dateCreation: Date | null;
};

export type Preorder = {
  id: number;
  clientId: number | null;
  priceId: number;
  uuid: string;
  number: string;
  isCompany: boolean;
  contactPerson: string;
  name: string | null;
  nip: string | null;
  email: string;
  emailCc: string | null;
  invoiceEmail: string | null;
  phone: string | null;
  country: LanguageCode | null;
  verifiedKrd: boolean;
  verifiedVies: boolean;
  vatRate: number;
  referenceFile: string | null;
  originalMessage: string;
  expectedRealizationDate: Date | null;
  activeEditorId: number | null;
  senderId: number;
  emailMessage: string | null;
  status: PreorderStatus;
  dateSent: Date | null;
  dateCreation: Date | null;
  client: Client | null;
  order: Order | null;
  isSms: boolean;
  invoiceType: PreorderInvoiceType;
  invoiceAuto: boolean;
  address: string | null;
  postCode: string | null;
  city: string | null;
  hasInvoiceDataConvergence: boolean;
  canDelete: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type PreorderVariant = {
  id: number;
  variantIdx: number;
  number: number;
  preorderId: number;
  proformaType: ProformaType | null;
  onlinePaymentUrl: string | null;
  repetition: number;
  isCertified: boolean;
  timeRealization: number;
  comment: string;
  preorderVariantFiles: PreorderVariantFile[];
  preorderVariantServices: PreorderVariantService[];
  inRealization: boolean;
};

export type PreorderVariantFile = {
  id: number;
  fileId: number;
  preorderVariantId: number;
  type: FileTranslationType;
  isActive: boolean;
  isSpecial: boolean;
  isCorrection: boolean;
  file: UploadedFile;
  preorderVariantLangs: PreorderVariantLang[];
};

export type PreorderVariantLang = {
  id: number;
  preorderVariantFileId: number;
  langFrom: string;
  langTo: string;
  characters: number;
  pages: number;
  discount: number;
  priceNetto: number;
  priceCorrectionNetto: number;
  priceSpecialNetto: number;
  priceFinalNetto: number;
  timeRealization: number;
};

export type PreorderVariantService = {
  id: number;
  preorderVariantId: number;
  type: ServiceType;
  vatRate: number;
  priceNetto: number;
  isActive: boolean;
  preorderVariantServiceValues: PreorderVariantServiceValue[];
};

export type PreorderVariantServiceValue = {
  id: number;
  preorderVariantServiceId: number;
  attributeName: ServiceAttributeNames;
  attributeValue: string;
};

export type PreorderService = {
  type: ServiceType;
  attributes: {
    name: ServiceAttributeNames;
    options?: string[];
  }[];
};
