import type { Client } from 'ducks/clients/types';
import type { Order } from 'ducks/orders/types';
import type {
  PaginationRequest,
  PaginationResponse,
  SortRequest,
} from 'ducks/types';

export enum PaymentsTag {
  Payments = 'Payments',
  PaymentTransactions = 'PaymentTransactions',
  PaymentIndividuals = 'PaymentIndividuals',
}

export enum PaymentObject {
  PREORDERS = 'preorders',
  ORDERS = 'orders',
}

export enum PaymentTransactionSource {
  ORDERS = 'orders',
  INVOICES = 'invoices',
}

export enum PaymentType {
  BANK_TRANSFER = 1,
  ONLINE_TRANSFER = 2,
  PAYPAL = 3,
  REFUND = 4,
}

export enum CurrencyType {
  PLN = 'PLN',
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
}

export enum PaymentStatus {
  SUCCESS = 1,
  IN_PROGRESS = 2,
  FAILURE = 3,
  CANCELED = 4,
  REJECTED = 5,
}

export enum PaymentParseStatus {
  TRANSFER_TITLE_AMOUNT_MATCH = 1,
  TRANSFER_TITLE_AMOUNT_SENDER_MATCH = 2,
  NO_MATCH = 3,
}

export type GetPaymentsRequest = {
  id: number;
  paymentObject: PaymentObject;
};

export type GetPaymentsResponse = Payment[];

export type AddPaymentRequest = {
  id: number;
  paymentObject: PaymentObject;
  amount: number;
  datePayment: string;
  type: PaymentType;
};

export type AddPaymentResponse = {
  id: number;
  objectId: number;
  amount: number;
  object: PaymentObject;
  type: PaymentType;
  datePayment: string;
  currency: CurrencyType;
};

export type GetPaymentTransactionsFilter = Partial<{
  amountFrom: number;
  amountTo: number;
}>;

export type GetPaymentTransactionsRequest = PaginationRequest & {
  searchTerm?: string;
  filter?: GetPaymentTransactionsFilter;
};

export type GetPaymentTransactionsResponse =
  PaginationResponse<PaymentTransaction>;

export type AddPaymentTransactionsRequest = {
  transactions: ParsePaymentFile[];
};

export type ParsePaymentFileRequest = {
  file: File;
};

export type ParsePaymentFileResponse = ParsePaymentFile[];

export type GetPaymentIndividualsFilter = Partial<{
  datePaymentBefore: string;
  datePaymentAfter: string;
}>;

export type GetPaymentIndividualsSort = SortRequest<
  keyof Pick<PaymentIndividual, 'datePayment'>
>;

export type GetPaymentIndividualsRequest = PaginationRequest &
  Partial<{
    filter: GetPaymentIndividualsFilter;
    sort: GetPaymentIndividualsSort | null;
  }>;

export type GetPaymentIndividualsResponse =
  PaginationResponse<PaymentIndividual>;

export type UpdatePaymentNoteRequest = {
  id: number;
  paymentObject: PaymentObject;
  note: string;
};

/* ——————————————————————————REGULAR TYPES—————————————————————————— */

export type ParsePaymentFile = {
  status: PaymentParseStatus;
  object: PaymentTransaction | null;
  csv: {
    amount: number;
    currency: CurrencyType;
    date: Date;
    dateCurrency: Date;
    description: string;
    fromAddress: string;
    fromName: string;
    title: string;
    typeTransaction: string;
  };
};

export type Payment = {
  id: number;
  objectId: number;
  object: PaymentObject;
  datePayment: string;
  status: PaymentStatus;
  type: PaymentType;
  amount: number;
  externalId: null;
  currency: CurrencyType;
};

export type PaymentTransaction = {
  id: number;
  uid: string;
  clientId: number;
  number: string;
  amount: number;
  objectType: PaymentTransactionSource;
  client: Client;
  orders: Order[];
};

export type PaymentIndividual = {
  id: number;
  type: PaymentType;
  isCompany: boolean;
  number: string;
  datePayment: Date;
  amount: number;
  currency: CurrencyType;
  note: string;
};
