import { useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import { verifyEmployee } from 'utilities/authorization';
import Company from './views/Company';
import EmailRouting from './views/EmailTemplates/Routing';
import ExternalIntegrations from './views/ExternalIntegrations';
import QuotationExceptions from './views/QuotationExceptions/QuotationExceptions';
import SmsRouting from './views/SmsTemplates/Routing';

export enum Routes {
  COMPANY = '/company',
  EXTERNAL_INTEGRATIONS = '/external-integrations',
  QUOTATION_EXCEPTIONS = '/quotation-exceptions',
  EMAIL_TEMPLATES = '/email-templates',
  SMS_TEMPLATES = '/sms-templates',
}

const SettingsRouting = () => {
  const { user } = useSanctum();
  const navigate = useNavigate();
  const isEmployee = verifyEmployee(user);

  useEffect(() => {
    if (isEmployee) {
      navigate('/');
    }
  }, [isEmployee, navigate]);

  return (
    <AnimatedRoutes pathLevelWatch={2}>
      <Route path={`${Routes.COMPANY}`} element={<Company />} />
      <Route
        path={`${Routes.EXTERNAL_INTEGRATIONS}`}
        element={<ExternalIntegrations />}
      />
      <Route
        path={`${Routes.QUOTATION_EXCEPTIONS}`}
        element={<QuotationExceptions />}
      />
      <Route path={`${Routes.EMAIL_TEMPLATES}/*`} element={<EmailRouting />} />
      <Route path={`${Routes.SMS_TEMPLATES}/*`} element={<SmsRouting />} />
    </AnimatedRoutes>
  );
};

export default SettingsRouting;
