import { Collapse } from 'antd';
import Loader from 'components/ui/atoms/Loader';
import CardCommon from 'components/ui/molecules/CardCommon';
import CustomCollapse from 'components/ui/molecules/CustomCollapse';
import PreorderVariantTable from 'components/ui/organisms/Tables/PreorderVariantTable';
import { useGetOrderVariantQuery } from 'ducks/orders/service';
import PanelHeader from 'views/Orders/views/OrderDetails/components/PreordersTab/components/PanelHeader';
import { useOrderClientSendServices } from '../../useOrderClientSendServices';
import styles from './styles.module.scss';

const OrderVariantSection = () => {
  const { orderData, isOrderFetching } = useOrderClientSendServices();
  const { data: orderVariantData, isFetching: isOrderVariantFetching } =
    useGetOrderVariantQuery(
      {
        id: Number(orderData?.id),
      },
      {
        skip: !orderData,
      },
    );
  const isLoading = isOrderFetching || isOrderVariantFetching;

  return (
    <CardCommon>
      <div className={styles.wrapper}>
        <Loader visible={isLoading}>
          {orderVariantData && (
            <CustomCollapse defaultActiveKey={orderVariantData.id}>
              <Collapse.Panel
                key={orderVariantData.id}
                header={<PanelHeader variant={orderVariantData} />}
              >
                <PreorderVariantTable variant={orderVariantData} />
              </Collapse.Panel>
            </CustomCollapse>
          )}
        </Loader>
      </div>
    </CardCommon>
  );
};

export default OrderVariantSection;
