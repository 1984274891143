import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { GetTranslatorsFilter, Translator } from 'ducks/translators/types';
import { Avatar, Rate, Space, Tag, Tooltip, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import theme from 'components/layout/Main/styles/values.scss';
import Button from 'components/ui/atoms/Button';
import CatLabel from 'components/ui/atoms/CatLabel';
import LanguageFlag from 'components/ui/atoms/LanguageFlag';
import LanguageName from 'components/ui/atoms/LanguageName';
import { FormOfSettlement } from 'constants/formOfSettlements';
import { NO_DATA } from 'constants/strings';
import { BriefcaseIcon, EditIcon, PersonIcon } from 'icons';
import { sortAlphabeticallyByProp } from 'utilities/array';
import { Routes } from 'views/Translators/Routing';
import TranslatorColumn from './components/TranslatorColumn';
import styles from './styles.module.scss';

const { Text } = Typography;

const MAX_SPECIALIZATIONS_LENGTH = 3;

export type TranslatorRow = TableRow &
  Translator & {
    email: string;
    phone: string;
  };

export const initialFilters: GetTranslatorsFilter = {
  lang: undefined,
  catId: undefined,
  type: undefined,
  status: 1,
};

const generateIconAndLabelCompany = (
  formOfSettlement: FormOfSettlement | null,
  invoiceDueDate: number,
) => {
  switch (formOfSettlement) {
    case FormOfSettlement.INVOICE:
      return {
        icon: <BriefcaseIcon />,
        label: (
          <FormattedMessage
            defaultMessage="FV-{invoiceDueDate}"
            values={{ invoiceDueDate }}
          />
        ),
        backgroundColor: theme.colorMenthol,
      };

    case FormOfSettlement.BILL:
      return {
        icon: <PersonIcon />,
        label: (
          <FormattedMessage
            defaultMessage="R-{invoiceDueDate}"
            values={{ invoiceDueDate }}
          />
        ),
        backgroundColor: theme.colorMayaBlue,
      };

    case FormOfSettlement.USE_ME:
      return {
        icon: <PersonIcon />,
        label: (
          <FormattedMessage
            defaultMessage="U-{invoiceDueDate}"
            values={{ invoiceDueDate }}
          />
        ),
        backgroundColor: theme.colorMayaBlue,
      };

    default:
      return {
        icon: <PersonIcon />,
        label: <span>{NO_DATA}</span>,
        backgroundColor: theme.colorMayaBlue,
      };
  }
};

export const columns: ColumnsType<TranslatorRow> = [
  {
    key: 'company',
    dataIndex: 'company',
    render: (_, { formOfSettlement, invoiceDueDate }) => {
      const { icon, label, backgroundColor } = generateIconAndLabelCompany(
        formOfSettlement,
        invoiceDueDate,
      );

      return (
        <Space>
          <Avatar
            size="small"
            icon={icon}
            style={{
              backgroundColor,
            }}
          />
          <Text ellipsis type="secondary">
            {label}
          </Text>
        </Space>
      );
    },
  },
  {
    title: <FormattedMessage defaultMessage="Tłumacz" />,
    key: 'translator',
    dataIndex: 'translator',
    render: (_, translatorRow) => {
      return <TranslatorColumn translatorRow={translatorRow} />;
    },
  },
  {
    title: <FormattedMessage defaultMessage="Języki" />,
    key: 'languages',
    dataIndex: 'languages',
    align: 'center',
    render: (_, { langs, langNative }) => {
      if (!langs.length) return NO_DATA;

      return (
        <Space className={styles.languagesSpace}>
          {langs.map((lang) => (
            <Space key={lang}>
              <LanguageFlag languageCode={lang} />
              <LanguageName countryCode={lang} maxLength={15} size="small" />
              {lang === langNative ? <span>(N)</span> : null}
            </Space>
          ))}
        </Space>
      );
    },
  },
  {
    title: <FormattedMessage defaultMessage="Specjalizacje" />,
    key: 'specializations',
    dataIndex: 'specializations',
    width: 300,
    render: (_, { specializations }) => {
      if (!specializations.length) return NO_DATA;

      const visibleSpecs = specializations
        .slice(0, MAX_SPECIALIZATIONS_LENGTH)
        .sort((a, b) => sortAlphabeticallyByProp(a, b, 'name'));
      const hiddenSpecs = specializations.slice(MAX_SPECIALIZATIONS_LENGTH);

      return (
        <Space>
          {visibleSpecs.sort().map(({ name }) => (
            <Tag key={name}>{name}</Tag>
          ))}
          {!!hiddenSpecs.length && (
            <Tooltip
              title={hiddenSpecs.map(({ name }) => (
                <div key={name}>
                  {name}
                  <br />
                </div>
              ))}
            >
              <Tag>...</Tag>
            </Tooltip>
          )}
        </Space>
      );
    },
  },
  {
    title: <FormattedMessage defaultMessage="Typ" />,
    key: 'type',
    dataIndex: 'type',
    render: (_, { types }) => {
      if (!types.length) return NO_DATA;

      return (
        <div className={styles.typeCell}>
          {types.map(({ name }) => name).join(', \n')}
        </div>
      );
    },
  },
  {
    title: <FormattedMessage defaultMessage="Ocena" />,
    key: 'rate',
    dataIndex: 'rate',
    width: 150,
    render: (_, { rateAvg }) => {
      if (!rateAvg) return NO_DATA;

      return (
        <Space className={styles.biggerPaddingRightCell}>
          <Text>{rateAvg.toFixed(2)}/5</Text>
          <Rate disabled allowHalf defaultValue={1} count={1} />
        </Space>
      );
    },
  },
  {
    title: <FormattedMessage defaultMessage="CAT" />,
    key: 'cats',
    dataIndex: 'cats',
    render: (_, { cats }) => {
      const mainCats = cats.filter(({ isMain }) => isMain);

      if (!mainCats.length) return NO_DATA;

      return (
        <div style={{ whiteSpace: 'pre' }}>
          {mainCats.map(({ name, id }, index) => (
            <>
              <CatLabel id={id} name={name} />
              {!!cats[index + 1] && ','}
            </>
          ))}
        </div>
      );
    },
  },
  {
    title: <FormattedMessage defaultMessage="Zlecenia" />,
    key: 'orders',
    dataIndex: 'orders',
    sorter: true,
    render: (_, { orders: { all, inProgress, complaints } }) => (
      <>
        <Text type="success">{all}</Text>
        <Text>/</Text>
        <Text type="warning">{inProgress}</Text>
        <Text>/</Text>
        <Text type="danger">{complaints}</Text>
      </>
    ),
  },
  {
    key: 'action',
    width: 50,
    render: ({ key, id }) => (
      <Space
        size="middle"
        data-testid="buttonsActions"
        onClick={(e) => e.stopPropagation()}
      >
        <Link to={Routes.TRANSLATOR_PROFILE_FORM.replace(':id', id.toString())}>
          <Button
            data-testid={`editButton${key}`}
            type="link"
            icon={<EditIcon />}
          >
            <FormattedMessage defaultMessage="Edytuj" />
          </Button>
        </Link>
      </Space>
    ),
  },
];
