import { notification } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import ConfirmModal from 'components/ui/molecules/ConfirmModal';
import { useDeleteClientMutation } from 'ducks/clients/service';
import { Routes } from 'views/Clients/Routing';
import { useClientDetailsServices } from 'views/Clients/views/ClientDetails/useClientDetailsServices';

const DeleteClientButton = () => {
  const navigate = useNavigate();
  const [notify, contextHolder] = notification.useNotification();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { clientId } = useClientDetailsServices();
  const [deleteClient, { isLoading }] = useDeleteClientMutation();

  const handleOpenConfirmModal = () => setIsConfirmModalOpen(true);

  const handleCloseConfirmModal = () => setIsConfirmModalOpen(false);

  const handleDelete = async () => {
    if (isLoading) return;

    try {
      await deleteClient({ clientId }).unwrap();

      handleCloseConfirmModal();
      navigate(Routes.CLIENTS);
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas usuwania klienta." />
        ),
      });
    }
  };

  return (
    <>
      {contextHolder}
      <ConfirmModal
        open={isConfirmModalOpen}
        title={<FormattedMessage defaultMessage="Usuń klienta" />}
        description={
          <FormattedMessage defaultMessage="Czy na pewno chcesz usunąć klienta?" />
        }
        isLoading={isLoading}
        okText={<FormattedMessage defaultMessage="Usuń" />}
        okButtonProps={{
          danger: true,
        }}
        onOk={handleDelete}
        onClose={handleCloseConfirmModal}
      />
      <Button
        type="primary"
        onClick={handleOpenConfirmModal}
        loading={isLoading}
        danger
      >
        <FormattedMessage defaultMessage="Usuń klienta" />
      </Button>
    </>
  );
};

export default DeleteClientButton;
