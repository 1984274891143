import { Space, notification } from 'antd';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import Button from 'components/ui/atoms/Button';
import ConfirmModal from 'components/ui/molecules/ConfirmModal';
import { useAddPaymentTransactionsMutation } from 'ducks/payments/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { resetFinancesState } from 'ducks/view/finances';

const ActionsSection = () => {
  const [notify, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const parsedPaymentFile = useAppSelector(
    ({ finances }) => finances.parsedPaymentFile,
    shallowEqual,
  );
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [addPaymentTransaction, { isLoading }] =
    useAddPaymentTransactionsMutation();

  const isNotAssigned = useMemo(
    () => parsedPaymentFile.some(({ object }) => !object),
    [parsedPaymentFile],
  );

  const handleCloseConfirmModal = () => setIsConfirmModalOpen(false);

  const onSave = async () => {
    handleCloseConfirmModal();

    try {
      const filteredparsedPaymentFile = parsedPaymentFile.filter(
        ({ object }) => object,
      );

      await addPaymentTransaction({
        transactions: filteredparsedPaymentFile,
      }).unwrap();

      dispatch(resetFinancesState());
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas zapisywania transakcji." />
        ),
      });
    }
  };

  const handleSave = async () => {
    if (isLoading) return;

    if (isNotAssigned) {
      setIsConfirmModalOpen(true);

      return;
    }

    await onSave();
  };

  return (
    <>
      {contextHolder}
      <Space
        data-testid="actionsSection"
        className="width-100"
        style={{ justifyContent: 'end' }}
      >
        <Button onClick={handleSave} loading={isLoading}>
          <FormattedMessage defaultMessage="Zapisz" />
        </Button>
      </Space>
      <ConfirmModal
        open={isConfirmModalOpen}
        title={<FormattedMessage defaultMessage="Nieprzypisano wpłat" />}
        description={
          <FormattedMessage defaultMessage="Nieprzypisano wszystkich wpłat. Czy na pewno chcesz zapisać?" />
        }
        onOk={onSave}
        onClose={handleCloseConfirmModal}
      />
    </>
  );
};

export default ActionsSection;
