import { notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Switch from 'components/ui/atoms/Switch';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useUpdateInvoiceOverdueMutation } from 'ducks/invoices/service';
import { useInvoiceOverdueDetailsServices } from '../../useInvoiceOverdueDetailsServices';

const CourtSection = () => {
  const { id = '' } = useParams();
  const [notify, contextHolder] = notification.useNotification();
  const { invoiceOverdueData, isInvoiceOverdueFetching } =
    useInvoiceOverdueDetailsServices();
  const [updateInvoiceOverdue, { isLoading: isUpdateLoading }] =
    useUpdateInvoiceOverdueMutation();
  const isChecked = !!invoiceOverdueData?.isCourt;
  const isLoading = isInvoiceOverdueFetching || isUpdateLoading;

  const handleUpdateIsCourt = async (value: boolean) => {
    if (isLoading) return;

    try {
      await updateInvoiceOverdue({ id, isCourt: value }).unwrap();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania sprawy sądowej." />
        ),
      });
    }
  };

  return (
    <CardCommon title={<FormattedMessage defaultMessage="Sprawa sądowa" />}>
      {contextHolder}
      <Switch
        checked={isChecked}
        onChange={(value) => handleUpdateIsCourt(value)}
        loading={isLoading}
      />
    </CardCommon>
  );
};

export default CourtSection;
