import { Form, Row, notification } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Empty from 'components/ui/atoms/Empty';
import { useAppDispatch } from 'ducks/store';
import { resetInvoicesState } from 'ducks/view/invoices';
import InvoiceEmailTemplateEdtiorSection from 'views/Invoices/components/InvoiceEmailTemplateEdtiorSection';
import ActionsSection from './components/ActionsSection';
import InvoiceSendHeader from './components/InvoiceSendHeader';
import NotesSection from './components/NotesSection';
import SwitchesSection from './components/SwitchesSection';
import TemplateSettingsSection from './components/TemplateSettingsSection';
import { initialValues } from './config';
import styles from './styles.module.scss';
import { useInvoiceSendServices } from './useInvoiceSendServices';

const { useForm } = Form;

const InvoiceSend = () => {
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { invoiceVatData, isInvoiceVatEmpty } = useInvoiceSendServices();

  useEffect(() => {
    if (!invoiceVatData) return;

    const { number } = invoiceVatData;

    form.setFieldsValue({
      email: invoiceVatData.client?.invoiceEmail ?? '',
      subject: intl.formatMessage(
        { defaultMessage: 'Faktura VAT nr {number}' },
        { number },
      ),
    });
  }, [invoiceVatData, form, intl]);

  useEffect(() => {
    return () => {
      dispatch(resetInvoicesState());
    };
  }, [dispatch]);

  return (
    <div className="layout-content mb-24">
      {contextHolder}
      {isInvoiceVatEmpty ? (
        <Empty
          description={<FormattedMessage defaultMessage="Brak faktury VAT" />}
        />
      ) : (
        <Form
          className={styles.form}
          form={form}
          initialValues={initialValues}
          layout="vertical"
          autoComplete="off"
        >
          <InvoiceSendHeader />
          <NotesSection />
          <Row gutter={[24, 24]}>
            <TemplateSettingsSection form={form} />
            <SwitchesSection />
          </Row>
          <InvoiceEmailTemplateEdtiorSection form={form} />
          <ActionsSection form={form} notify={notify} />
        </Form>
      )}
    </div>
  );
};

export default InvoiceSend;
