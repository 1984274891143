import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useGetInvoicesDataQuery } from 'ducks/invoices/service';
import { useAppSelector } from 'ducks/store';

export const useInvoiceAddServices = () => {
  const orderIds = useAppSelector(
    ({ invoicesAdd }) => invoicesAdd.orderIds,
    shallowEqual,
  );
  const { data: invoiceData, isFetching: isInvoiceDataFetching } =
    useGetInvoicesDataQuery({ ids: orderIds }, { skip: !orderIds.length });
  const isInvoiceDataEmpty = !invoiceData && !isInvoiceDataFetching;

  const value = useMemo(
    () => ({
      invoiceData,
      isInvoiceDataFetching,
      isInvoiceDataEmpty,
    }),
    [invoiceData, isInvoiceDataFetching, isInvoiceDataEmpty],
  );

  return value;
};
