import type { PreorderData } from 'ducks/preorders/types';
import type {
  DefaultValue,
  PaginationRequest,
  PaginationResponse,
  SortRequest,
} from 'ducks/types';

export enum ClientsTag {
  Clients = 'Clients',
  ClientContactPersons = 'ClientContactPersons',
  Orders = 'Orders',
  Preorders = 'Preorders',
}

export type Client = {
  id: number;
  canDelete: boolean;
  isCompany: boolean;
  name: string;
  email1: string;
  email2: string;
  emailDefault: DefaultValue;
  phone1: string;
  phone2: string;
  phoneDefault: DefaultValue;
  address: string;
  postCode: string;
  city: string;
  country: string;
  nip: string | null;
  regon: string | null;
  isTrusted: boolean;
  isDebtor: boolean;
  balance: number;
  discount: number;
  invoiceAuto: boolean;
  invoiceType: DefaultValue | null;
  invoiceName: string | null;
  invoiceNip: string | null;
  invoiceAddress: string | null;
  invoicePostCode: string | null;
  invoiceCity: string | null;
  invoiceEmail: string | null;
  invoiceCountry: string | null;
  createdAt: string;
  updatedAt: string;
  ordersCount: number;
  ordersInProgressCount: number;
  efficiency: number;
  mt: boolean;
  preordersCount: number;
  complaintsCount: number;
  isCollectiveInvoices: boolean;
};

export type GetClientsResponse = PaginationResponse<Client>;

export type GetClientsFilter = Partial<{
  is_company: 0 | 1;
}>;

export type GetClientsSort = SortRequest<
  'preordersCount' | 'ordersCount' | 'complaintsCount' | 'discount' | 'balance'
>;

export type GetClientsRequest = PaginationRequest & {
  searchTerm?: string;
  filter?: GetClientsFilter;
  sort?: GetClientsSort | null;
};

export type AddClientRequest = {
  isCompany: boolean | null;
  name: string;
  email1: string;
  phone1?: string;
  nip?: string;
  address: string | null;
  city: string | null;
  postCode: string | null;
  country: string | null;
};

export type CheckDuplicateClientsRequest = {
  name?: string;
  email1?: string;
  email2?: string;
  phone1?: string;
  phone2?: string;
  nip?: string;
};

export type CheckDuplicateClientsResponse = Client[] | undefined;

export type GetClientDetailsRequest = {
  clientId: number;
};

export type DeleteClientRequest = {
  clientId: number;
};

export type UpdateClientDiscountRequest = {
  clientId: number;
  discount: number;
};

export type UpdateClientContactRequest = {
  clientId: number;
  email1: string | null;
  email2: string | null;
  phone1: string | null;
  phone2: string | null;
  emailDefault: DefaultValue;
  phoneDefault: DefaultValue;
};

export type UpdateClientInvoiceRequest = {
  clientId: number;
  isCompany: boolean;
  invoiceEmail: string | null;
  invoiceNip: string;
  invoiceName: string | null;
  invoiceAddress: string | null;
  invoiceCity: string | null;
  invoicePostCode: string | null;
  invoiceCountry: string | null;
  invoiceType: DefaultValue | null;
  invoiceAuto: boolean;
  isDebtor: boolean;
  invoiceVatRate: number | null;
  isCollectiveInvoices: boolean;
};

export type UpdateClientCorrespondenceRequest = {
  clientId: number;
  name: string | null;
  nip: string | null;
  address: string | null;
  city: string | null;
  postCode: string | null;
  country: string | null;
};

export type GetClientContactPersonsRequest = {
  clientId: number;
};

export type ContactPerson = {
  id: number;
  name: string;
  email: string;
  phone: string;
  isSms: boolean;
  createdAt: string;
};

export type GetClientContactPersonsResponse = ContactPerson[];

export type AddClientContactPersonRequest = {
  clientId: number;
  name: string;
  email?: string;
  phone?: string;
  isSms: boolean;
};

export type UpdateClientContactPersonRequest = AddClientContactPersonRequest & {
  contactId: number;
};

export type DeleteClientContactPersonRequest = {
  clientId: number;
  contactId: number;
};

export enum ClientOrderStatus {
  COMPLETED,
  IN_PROGRESS,
  COMPLAINT,
}

export type ClientOrder = {
  id: number;
  invoiceStatus: boolean;
  number: string;
  priceNetto: number;
  status: ClientOrderStatus;
  isMt: boolean;
  dateRealization: string | null;
  langs: {
    langFrom: string;
    langTo: string;
  }[];
  createdAt: Date;
};

export type GetClientOrdersRequest = {
  clientId: string;
};

export type GetClientPreordersRequest = {
  clientId: string;
};

export type GetClientPreordersResponse = ClientPreorderData[];

export type GetClientOrdersResponse = ClientOrder[];

export type ClientPreorderData = PreorderData & {
  langs: {
    langFrom: string;
    langTo: string;
  }[];
};

export type PostOrderTmRequest = {
  isMt: boolean;
  orderId: number;
};

export type SendClientEmailsRequest = {
  id: number | string;
  orderId: number;
  email: string;
  name: string;
  cc: string | null;
  subject: string;
  body: string;
  isSms: boolean;
  smsText: string | null;
  isReferenceFile: boolean;
  attachments: number[];
  files: File[];
};
