import type { EditableColumnType } from './config';
import type { TableRow } from '../Table';
import type { FormInstance, TableProps } from 'antd';
import type { ReactElement } from 'react';
import { Space, Button, Popconfirm } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EditIcon, TrashIcon } from 'icons';
import Table from '../Table/Table';
import EditableCell from './components/EditableCell/EditableCell';

interface Props<T> extends TableProps<T> {
  data: T[];
  columns: EditableColumnType<T>;
  onSave: (values: T, row: T) => Promise<void>;
  onDelete: (row: T) => Promise<void>;
  form: FormInstance;
  cardHeader?: ReactElement | ReactElement[];
  isLoading?: boolean;
  isSaveLoading?: boolean;
  isDeleteLoading?: boolean;
  cardExtra?: ReactElement | ReactElement[];
  cardTitleExtra?: ReactElement | ReactElement[];
  cardTitle?: ReactElement | ReactElement[];
}

const EditableTable = <T extends TableRow>({
  data,
  columns,
  onSave,
  onDelete,
  form,
  cardHeader,
  cardExtra,
  cardTitleExtra,
  cardTitle,
  isLoading = true,
  isSaveLoading = false,
  isDeleteLoading = false,
}: Props<T>) => {
  const [editingKey, setEditingKey] = useState<keyof T | string>('');
  const isEditing = (record: T) => record.key === editingKey;

  const handleEdit = (record: Partial<T> & { key: React.Key }) => {
    form.setFieldsValue(record);
    setEditingKey(record.key as keyof T);
  };

  const handleCancel = () => {
    setEditingKey('');
  };

  const handleOnSave = async (record: T) => {
    if (isSaveLoading) return;

    const values = await form.validateFields().catch((err) => err);

    if (values?.errorFields?.length) return;

    try {
      await onSave(values, record);

      handleCancel();
    } catch (err) {
      throw Error;
    }
  };

  const handleOnDelete = (record: T) => {
    if (isDeleteLoading) return;
    onDelete(record).then(() => handleCancel());
  };

  const editableColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        ...col,
        isLoading: isSaveLoading,
        editing: isEditing(record),
      }),
    };
  }) as EditableColumnType<T>;

  return (
    <Table
      cardHeader={cardHeader}
      cardExtra={cardExtra}
      cardTitleExtra={cardTitleExtra}
      cardTitle={cardTitle}
      data={data}
      isLoading={isLoading}
      columns={[
        ...editableColumns,
        {
          render: (_, record: T) => {
            const editable = isEditing(record);
            return editable ? (
              <Space>
                <Button
                  disabled={isSaveLoading}
                  type="link"
                  onClick={handleCancel}
                >
                  <FormattedMessage defaultMessage="Anuluj" />
                </Button>
                <Button
                  disabled={isSaveLoading}
                  type="link"
                  onClick={() => handleOnSave(record)}
                >
                  <FormattedMessage defaultMessage="Zapisz" />
                </Button>
              </Space>
            ) : (
              <Space>
                <Popconfirm
                  title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
                  okText={<FormattedMessage defaultMessage="Tak" />}
                  cancelText={<FormattedMessage defaultMessage="Nie" />}
                  onConfirm={() => handleOnDelete(record)}
                >
                  <Button
                    disabled={isDeleteLoading}
                    type="link"
                    icon={<TrashIcon />}
                    danger
                  >
                    <FormattedMessage defaultMessage="Usuń" />
                  </Button>
                </Popconfirm>
                <Button
                  disabled={isDeleteLoading}
                  type="link"
                  icon={<EditIcon />}
                  onClick={() => handleEdit(record)}
                >
                  <FormattedMessage defaultMessage="Edytuj" />
                </Button>
              </Space>
            );
          },
        },
      ]}
      components={{
        body: {
          cell: EditableCell<T>,
        },
      }}
    />
  );
};

export default EditableTable;
