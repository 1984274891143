import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { DebtCollections } from 'ducks/invoices/types';
import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { DayjsFormat } from 'constants/dayjsFormats';
import { getDebtCollectionStatus } from 'constants/invoice';
import { NO_DATA } from 'constants/strings';

type Props = {
  intl: IntlShape;
  debtCollection: DebtCollections;
};

export const formItems = ({
  intl,
  debtCollection: { number, dateReceived, price, status },
}: Props): FormItemRecord<DebtCollections>[] => {
  const dateReceivedValue = dateReceived
    ? dayjs(dateReceived).format(DayjsFormat.DATE)
    : NO_DATA;
  const statusValue = getDebtCollectionStatus(intl, status)?.label ?? NO_DATA;

  return [
    {
      key: 'number',
      label: <FormattedMessage defaultMessage="Numer:" />,
      component: <strong data-testid="number">{number ?? NO_DATA}</strong>,
    },
    {
      key: 'dateReceived',
      label: <FormattedMessage defaultMessage="Data przyjęcia:" />,
      component: (
        <strong data-testid="dateReceived">{dateReceivedValue}</strong>
      ),
    },
    {
      key: 'price',
      label: <FormattedMessage defaultMessage="Koszt:" />,
      component: (
        <strong data-testid="price">
          <CurrencyFormatter value={price} />
        </strong>
      ),
    },
    {
      key: 'status',
      label: <FormattedMessage defaultMessage="Status:" />,
      component: <strong data-testid="status">{statusValue}</strong>,
    },
  ];
};
