import type { ModalProps } from 'antd';
import type { FC, ReactElement, ReactNode } from 'react';
import { Modal as AntdModal, Space, Typography } from 'antd';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import Loader from 'components/ui/atoms/Loader';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = ModalProps & {
  children: ReactNode;
  subtitle?: ReactElement | string;
  isLoading?: boolean;
  disableModalStyles?: boolean;
  disableFormStyles?: boolean;
};

const Modal: FC<Props> = ({
  children,
  subtitle,
  isLoading = false,
  disableModalStyles = false,
  disableFormStyles = false,
  className,
  ...props
}) => {
  return (
    <>
      {props.open && <RemoveScrollBar />}
      <AntdModal
        closable={false}
        className={cn(className, {
          [styles.modalContainer]: !disableModalStyles,
          [styles.modalForm]: !disableFormStyles,
        })}
        okButtonProps={{ disabled: isLoading }}
        okText={<FormattedMessage defaultMessage="Zapisz" />}
        cancelText={<FormattedMessage defaultMessage="Anuluj" />}
        {...props}
      >
        <Loader visible={isLoading}>
          <Space className="width-100" direction="vertical" size="middle">
            {subtitle && <Text type="secondary">{subtitle}</Text>}
            {children}
          </Space>
        </Loader>
      </AntdModal>
    </>
  );
};

export default Modal;
