import { Menu } from 'antd';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import logo from 'assets/images/logo.png';
import { Routes } from 'components/main/App/Routing';
import { setSidenavMobileOpened } from 'ducks/config/slice';
import { useAppDispatch } from 'ducks/store';
import SidenavItem from './components/SidenavItem';
import { getMenu, getItem } from './config';
import styles from './styles.module.scss';

const Sidenav = () => {
  const { user } = useSanctum();
  const intl = useIntl();
  const menu = getMenu(intl, user?.role as number);
  const dispatch = useAppDispatch();

  const handleCloseSidenav = () => {
    dispatch(setSidenavMobileOpened(false));
  };

  return (
    <>
      <NavLink to={Routes.PROJECTS}>
        <div className={styles.logoWrapper}>
          <img className={styles.logoImage} src={logo} alt="Logo" />
        </div>
      </NavLink>
      <Menu
        className={styles.nav}
        data-testid="sidenavMenu"
        theme="light"
        mode="inline"
        items={menu.map(({ key, children, label, icon, url, subSection }) =>
          getItem(
            <SidenavItem
              key={key}
              onClick={handleCloseSidenav}
              label={label}
              icon={icon}
              url={!subSection ? url : ''}
              subSection={subSection}
            >
              {children}
            </SidenavItem>,
            key,
            subSection?.map(({ url: childUrl, label: childLabel }) => {
              return getItem(
                <SidenavItem
                  key={childUrl}
                  url={childUrl}
                  label={childLabel}
                  onClick={handleCloseSidenav}
                />,
                childUrl,
              );
            }),
          ),
        )}
      />
    </>
  );
};

export default Sidenav;
