import type { NotificationInstance } from 'antd/es/notification/interface';
import { useState, type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import List from 'components/ui/molecules/List';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import CardCommon from '../CardCommon';
import { statusCardListItems } from './config';
import EditStatusModal from './EditStatusModal';

type Props = {
  notify: NotificationInstance;
};

const StatusCard: FC<Props> = ({ notify }) => {
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [hasStatusBeenEdited, setHasStatusBeenEdited] = useState(false);
  const status = !!translatorData?.status;

  const handleModalClose = () => setEditModalOpen(false);

  const handleModalOpen = () => setEditModalOpen(true);

  return (
    <CardCommon
      data-testid="statusCard"
      title={<FormattedMessage defaultMessage="Status" />}
      isLoading={isTranslatorFetching}
      xs={24}
      sm={12}
      md={24}
    >
      <EditStatusModal
        isOpen={isEditModalOpen}
        handleModalClose={handleModalClose}
        notify={notify}
        translatorId={translatorData?.id}
        currentUserStatus={status}
        disabled={hasStatusBeenEdited}
        setDisabled={setHasStatusBeenEdited}
      />
      <List
        items={statusCardListItems({
          data: translatorData,
          handleModalOpen,
        })}
        isLoading={isTranslatorFetching}
      />
    </CardCommon>
  );
};

export default StatusCard;
