import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Order } from 'ducks/orders/types';
import type { IntlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CopyToClipboardWithText from 'components/ui/atoms/CopyToClipboard';
import CountryName from 'components/ui/atoms/CountryName';
import Switch from 'components/ui/atoms/Switch';
import TruncateText from 'components/ui/atoms/TruncateText';
import { preorderInvoiceTypesObject } from 'constants/preorder';
import { NO_DATA } from 'constants/strings';

type Props = {
  data: Order;
  intl: IntlShape;
};

export const formItems = ({ data, intl }: Props): FormItemRecord<Order>[] => {
  const {
    isCompany,
    invoiceType,
    invoiceEmail,
    companyName,
    country,
    nip,
    city,
    postCode,
    vatRate,
    address,
    verifiedVies,
    invoiceAuto,
    hasInvoiceDataConvergence,
  } = data;
  const invoiceTypeValue = preorderInvoiceTypesObject(intl)[invoiceType].label;

  return [
    {
      key: 'isCompany',
      label: <FormattedMessage defaultMessage="Typ klienta" />,
      component: (
        <strong>
          {isCompany ? (
            <FormattedMessage defaultMessage="Firma" />
          ) : (
            <FormattedMessage defaultMessage="Osoba fizyczna" />
          )}
        </strong>
      ),
    },
    {
      key: 'invoiceType',
      label: <FormattedMessage defaultMessage="Rodzaj faktury" />,
      component: <strong>{invoiceTypeValue}</strong>,
    },
    {
      key: 'invoiceEmail',
      label: <FormattedMessage defaultMessage="E-mail:" />,
      component: (
        <strong>
          {invoiceEmail ? (
            <CopyToClipboardWithText
              data-testid="invoiceEmail"
              text={invoiceEmail}
              truncate
              truncateSize="medium"
              truncateMaxLength={16}
              strong
            />
          ) : (
            NO_DATA
          )}
        </strong>
      ),
    },
    {
      key: 'country',
      label: <FormattedMessage defaultMessage="Kraj:" />,
      component: <CountryName countryCode={country} strong />,
    },
    {
      key: 'nip',
      label: <FormattedMessage defaultMessage="NIP:" />,
      component: <strong data-testid="nip">{nip ?? NO_DATA}</strong>,
      hidden: !isCompany,
    },
    {
      key: 'vatRate',
      label: <FormattedMessage defaultMessage="VAT:" />,
      name: 'vatRate',
      component: <strong data-testid="vatRate">{`${vatRate}%`}</strong>,
    },
    {
      key: 'verifiedVies',
      label: <FormattedMessage defaultMessage="VIES:" />,
      component: (
        <CircleCheckbox data-testid="verifiedVies" value={verifiedVies} />
      ),
    },
    {
      key: 'companyName',
      label: <FormattedMessage defaultMessage="Nazwa:" />,
      component: (
        <TruncateText data-testid="companyName" strong>
          {companyName ?? NO_DATA}
        </TruncateText>
      ),
    },
    {
      key: 'address',
      label: <FormattedMessage defaultMessage="Ulica:" />,
      component: (
        <TruncateText data-testid="address" strong>
          {address ?? NO_DATA}
        </TruncateText>
      ),
    },
    {
      key: 'city',
      label: <FormattedMessage defaultMessage="Miejscowość:" />,
      component: (
        <TruncateText data-testid="city" strong>
          {city ?? NO_DATA}
        </TruncateText>
      ),
    },
    {
      key: 'postCode',
      label: <FormattedMessage defaultMessage="Kod pocztowy:" />,
      component: (
        <TruncateText data-testid="postCode" strong>
          {postCode ?? NO_DATA}
        </TruncateText>
      ),
    },
    {
      key: 'invoiceAuto',
      label: <FormattedMessage defaultMessage="FV automatyczna:" />,
      component: <Switch checked={invoiceAuto} disabled />,
    },
    {
      key: 'hasInvoiceDataConvergence',
      label: <FormattedMessage defaultMessage="Zbieżność danych" />,
      component: <Switch checked={hasInvoiceDataConvergence} disabled />,
    },
  ];
};
