/* eslint-disable react/jsx-props-no-spreading */
import type { TextProps } from 'antd/es/typography/Text';
import type { FC, ReactElement } from 'react';
import { Tooltip, Typography } from 'antd';
import cn from 'classnames';
import styles from './styles.module.scss';

type Size = 'small' | 'medium';

export interface TruncateProps extends TextProps {
  children: ReactElement | string;
  size?: Size;
  maxLength?: number;
  nowrap?: boolean;
}

const { Text } = Typography;

const TruncateText: FC<TruncateProps> = ({
  children,
  size = 'medium',
  maxLength = 20,
  className,
  nowrap = false,
  ...textProps
}) => {
  const text =
    typeof children === 'string'
      ? children
      : String(children?.props?.defaultMessage?.[0]?.value);
  const isLongText = text.replace(' ', '').length > maxLength;
  const isLastCharacterSpace = text.charAt(maxLength - 1) === ' ';

  return (
    <Tooltip title={isLongText ? text : ''}>
      <Text
        {...textProps}
        className={cn(className, {
          [styles.sizeSmall]: size === 'small',
          [styles.nowrap]: nowrap,
        })}
      >
        {isLongText
          ? `${text.slice(
              0,
              isLastCharacterSpace ? maxLength - 1 : maxLength,
            )}...`
          : text}
      </Text>
    </Tooltip>
  );
};

export default TruncateText;
