import { FormattedMessage } from 'react-intl';
import List from 'components/ui/molecules/List';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import CardCommon from '../CardCommon';
import { correspondenceCardListItems } from './config';

const CorrespondenceCard = () => {
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();

  return (
    <CardCommon
      data-testid="correspondenceCard"
      title={<FormattedMessage defaultMessage="Dane korespondencyjne" />}
      isLoading={isTranslatorFetching}
      md={12}
    >
      <List
        items={correspondenceCardListItems(translatorData)}
        isLoading={isTranslatorFetching}
      />
    </CardCommon>
  );
};

export default CorrespondenceCard;
