import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import { PreorderStatus } from 'constants/preorder';
import { useAppSelector } from 'ducks/store';
import { usePreorderDetailsServices } from '../../usePreorderDetailsServices';
import { formItems } from './config';

const ContactPersonSection = () => {
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
  const { preorderData, isPreorderFetching } = usePreorderDetailsServices();
  const isNotInRealization =
    preorderData?.status !== PreorderStatus.IN_REALIZATION;

  return (
    <CardCommon
      data-testid="clientSection"
      title={<FormattedMessage defaultMessage="Osoba kontaktowa" />}
      isLoading={isPreorderFetching}
      xs={24}
    >
      <div className="gridForm">
        {preorderData &&
          formItems({
            data: preorderData,
            isEditable: isEditable && isNotInRealization,
          }).map(({ key, ...props }) => <FormItem key={key} {...props} />)}
      </div>
      {/* commented as recommended in #481 */}
      {/* <Col xs={24} md={6}>
          <FormItem
            label={
              <FormattedMessage defaultMessage="Treść wiadomości e-mail:" />
            }
            component={<Text>{preorderData?.emailMessage ?? NO_DATA}</Text>}
          />
        </Col> */}
    </CardCommon>
  );
};

export default ContactPersonSection;
