export const provinces = [
  {
    name: 'Dolnośląskie',
  },
  {
    name: 'Kujawsko-pomorskie',
  },
  {
    name: 'Łódzkie',
  },
  {
    name: 'Lubelskie',
  },
  {
    name: 'Lubuskie',
  },
  {
    name: 'Małopolskie',
  },
  {
    name: 'Mazowieckie',
  },
  {
    name: 'Opolskie',
  },
  {
    name: 'Podkarpackie',
  },
  {
    name: 'Podlaskie',
  },
  {
    name: 'Pomorskie',
  },
  {
    name: 'Śląskie',
  },
  {
    name: 'Świętokrzyskie',
  },
  {
    name: 'Warmińsko-mazurskie',
  },
  {
    name: 'Wielkopolskie',
  },
  {
    name: 'Zachodniopomorskie',
  },
];
