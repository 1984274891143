import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { UploadOnChangeParam } from 'components/ui/molecules/Upload';
import type { FC } from 'react';
import { Col, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import List from 'components/ui/molecules/List';
import Upload from 'components/ui/molecules/Upload';
import { jsonFileTypes } from 'constants/fileTypes';
import { useCalcOrderPosteditMutation } from 'ducks/orders/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { changeOrderAssignResults } from 'ducks/view/orders';
import { filePicker } from 'utilities/filePicker';
import { useOrderAssignServices } from 'views/Orders/views/OrderAssign/useOrderAssignServices';
import { listItems, skeletonListItems } from './config';
import styles from './styles.module.scss';

interface Props {
  notify: NotificationInstance;
}

const UploadFileAndCalcPostedit: FC<Props> = ({ notify }) => {
  const results = useAppSelector((state) => state.orders.assign.results);
  const dispatch = useAppDispatch();
  const { orderData, isOrderLoading } = useOrderAssignServices();
  const [calcOrderPostedit, { isLoading: isCalcPosteditLoading }] =
    useCalcOrderPosteditMutation();
  const isLoading = isOrderLoading || isCalcPosteditLoading;

  const handleUploadFile = async (files: UploadOnChangeParam) => {
    const pickedFile = filePicker(files, {
      multiple: false,
      fileTypes: jsonFileTypes,
      onError: () => {
        notify.error({
          message: <FormattedMessage defaultMessage="Wybierz plik .json" />,
        });
      },
    })[0];

    if (!pickedFile) return;

    try {
      const response = await calcOrderPostedit({
        file: pickedFile as File,
      }).unwrap();

      dispatch(changeOrderAssignResults(response));

      notify.success({
        message: <FormattedMessage defaultMessage="Poprawnie wgrano plik." />,
      });
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd poczas wgrywania pliku." />
        ),
      });
    }
  };

  return (
    <Col xs={24} sm={12}>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <Upload
            onChange={handleUploadFile}
            listType="picture-card"
            isLoading={isLoading}
            multiple={false}
            accept={jsonFileTypes.toString()}
          >
            {isLoading ? (
              <Spin />
            ) : (
              <FormattedMessage defaultMessage="Wgraj plik .json" />
            )}
          </Upload>
          <List
            items={
              orderData
                ? listItems({
                    results,
                    data: orderData,
                  })
                : skeletonListItems
            }
            isLoading={isLoading}
            withoutMargin
          />
        </div>
      </div>
    </Col>
  );
};

export default UploadFileAndCalcPostedit;
