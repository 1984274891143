export const imageFileTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
] as const;

export const jsonFileTypes = ['application/json'] as const;

export const csvFileTypes = [
  '.csv',
  'text/csv',
  'application/csv',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
] as const;

export const xmlFileTypes = ['.xml', 'text/xml', 'application/xml'] as const;

export const financesImportFileTypes = [
  ...xmlFileTypes,
  ...csvFileTypes,
] as const;
