import { Col, notification } from 'antd';
import Notes from 'components/ui/organisms/Notes';
import { NoteType } from 'ducks/notes/types';
import { useInvoiceAddServices } from '../../useInvoiceAddServices';

const NotesSection = () => {
  const [notify, contextHolder] = notification.useNotification();
  const { invoiceData, isInvoiceDataFetching } = useInvoiceAddServices();

  return (
    <Col xs={24} lg={10}>
      {contextHolder}
      <Notes
        objectId={invoiceData?.client.id}
        noteType={NoteType.ORDERS}
        notify={notify}
        skip={!invoiceData?.client.id}
        isLoading={isInvoiceDataFetching}
      />
    </Col>
  );
};

export default NotesSection;
