import type { FormInstance } from 'antd';
import { Form, Select } from 'antd';
import { useState, type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetSmsTemplatesQuery } from 'ducks/sms/service';

const { Item } = Form;

type Props = {
  form: FormInstance;
  formPropName?: string;
};

const SmsTemplateSelector: FC<Props> = ({ form, formPropName = 'message' }) => {
  const [smsTemplateId, setSmsTemplateId] = useState<null | number>(null);

  const { data: smsTemplates = [], isLoading: smsTemplatesLoading } =
    useGetSmsTemplatesQuery();

  const options = smsTemplates?.flatMap(({ title, id }) => [
    {
      label: title,
      value: id,
    },
  ]);

  const handleChange = (value: number) => {
    setSmsTemplateId(value);

    const selectedTemplate = smsTemplates.find(({ id }) => id === value);

    if (!selectedTemplate) return;

    form.setFieldsValue({
      [formPropName]: selectedTemplate?.content,
    });
  };

  return (
    <Item>
      <Select
        options={options}
        loading={smsTemplatesLoading}
        placeholder={<FormattedMessage defaultMessage="Wybierz szablon SMS" />}
        onChange={handleChange}
        value={smsTemplateId}
      />
    </Item>
  );
};

export default SmsTemplateSelector;
