import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { OrderSendEmailsTranslatorState } from 'ducks/view/orders';
import type { FC } from 'react';
import { Checkbox, Space } from 'antd';
import { memo } from 'react';
import LanguagePair from 'components/ui/atoms/LanguagePair';
import { useAppDispatch } from 'ducks/store';
import { updateOrderSendEmailsTranslators } from 'ducks/view/orders';
import styles from './styles.module.scss';

type Props = {
  translatorEmail: OrderSendEmailsTranslatorState;
};

const TranslatorPanelHeader: FC<Props> = memo(
  ({ translatorEmail: { isChecked, name, langs, translatorId } }) => {
    const dispatch = useAppDispatch();

    const handleChange = (e: CheckboxChangeEvent) =>
      dispatch(
        updateOrderSendEmailsTranslators({
          translatorId,
          field: { isChecked: e.target.checked },
        }),
      );

    return (
      <Space size="middle" wrap>
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          checked={isChecked}
          onChange={handleChange}
        />
        <span>{name}</span>
        <div className={styles.languagesWrapper}>
          <span>(</span>
          {langs.map(({ langFrom, langTo }, index) => (
            <div key={`${langFrom}-${langTo}`} className={styles.languageItem}>
              <Space className="width-100">
                <LanguagePair langFrom={langFrom} langTo={langTo} />
              </Space>
              {index !== langs.length - 1 && <span>,</span>}
            </div>
          ))}
          <span>)</span>
        </div>
      </Space>
    );
  },
);

export default TranslatorPanelHeader;
