import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';
import type { FC, ReactNode } from 'react';
import { Tag, notification } from 'antd';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import theme from 'components/layout/Main/styles/values.scss';
import HeaderCard, {
  generateSkeletonHeaderCols,
} from 'components/ui/organisms/HeaderCard';
import { preorderStatusesObject } from 'constants/preorder';
import { NO_DATA } from 'constants/strings';
import { usePreordersServices } from 'views/Preorders/usePreordersServices';
import PreorderPadlock from '../PreorderPadlock';
import { headerCols } from './config';
import styles from './styles.module.scss';

type Props = {
  children?: ReactNode;
  cols?: HeaderCardCol[];
  showUnblockButton?: boolean;
};

const PreorderHeader: FC<Props> = ({
  children,
  cols = [],
  showUnblockButton = false,
}) => {
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const { preorderData, isPreorderFetching } = usePreordersServices();
  const activeEditorId = preorderData?.activeEditorId ?? null;
  const number = preorderData?.number ?? NO_DATA;
  const showStatus = preorderData?.status !== undefined;
  const status = showStatus
    ? preorderStatusesObject(intl)[preorderData.status].label
    : NO_DATA;

  const primaryCols = useMemo(
    () =>
      preorderData
        ? headerCols({ data: preorderData })
        : generateSkeletonHeaderCols(4),
    [preorderData],
  );

  return (
    <>
      {contextHolder}
      <HeaderCard
        cols={[...primaryCols, ...cols]}
        isLoading={isPreorderFetching}
        startChildren={
          <div className={styles.startChildrenWrapper}>
            <PreorderPadlock
              activeEditorId={activeEditorId}
              notify={notify}
              showUnblockButton={showUnblockButton}
            />
            <span className={styles.number} data-testid="number">
              {number}
            </span>
            <Tag className={styles.status} color={theme.colorBlue}>
              {status}
            </Tag>
          </div>
        }
      >
        {children}
      </HeaderCard>
    </>
  );
};

export default PreorderHeader;
