import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export enum DayjsFormat {
  DATE = 'DD.MM.YYYY',
  YEAR_MONTH_DAY = 'YYYY.MM.DD',
  YEAR_MONTH_DAY_DASHED = 'YYYY-MM-DD',
  YEAR_MONTH_DAY_HOUR_MINUTE_DASHED = 'YYYY-MM-DD HH:mm',
  DATE_AND_TIME = 'DD.MM.YYYY HH:mm',
  HOUR_MINUTE = 'HH:mm',
}

export enum DayjsTimezone {
  WARSAW = 'Europe/Warsaw',
}
