import type { PreorderSendValues } from '../../config';
import type { FormInstance } from 'antd';
import type { FC } from 'react';
import Loader from 'components/ui/atoms/Loader';
import CardCommon from 'components/ui/molecules/CardCommon';
import SendEmailForm from 'components/ui/organisms/Forms/SendEmailForm/SendEmailForm';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setPreorderEmailTemplate } from 'ducks/view/preorders';
import { usePreorderSendServices } from '../../usePreorderSendServices';

type Props = {
  form: FormInstance<PreorderSendValues>;
};

const TemplateSettingsSection: FC<Props> = ({ form }) => {
  const dispatch = useAppDispatch();
  const emailTemplate = useAppSelector(
    ({ preorders }) => preorders.emailTemplate,
  );
  const { isPreorderFetching } = usePreorderSendServices();

  const handleEmailTemplateChange = (value: string) =>
    dispatch(setPreorderEmailTemplate(value));

  return (
    <CardCommon data-testid="templateSettingsSection" xs={24} lg={14} xl={15}>
      <Loader visible={isPreorderFetching}>
        <SendEmailForm
          form={form}
          emailTemplate={emailTemplate}
          onEmailTemplateChange={handleEmailTemplateChange}
        />
      </Loader>
    </CardCommon>
  );
};

export default TemplateSettingsSection;
