import type { AssignAllFilesModalValues } from '../config';
import { Select } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import { useGetTranslatorsSpecializationsQuery } from 'ducks/translators/service';

const SpecializationSelect = () => {
  const { data = [], isLoading } = useGetTranslatorsSpecializationsQuery();

  const options = useMemo(
    () => data.map(({ id, name }) => ({ value: id, label: name })),
    [data],
  );

  return (
    <FormItem<AssignAllFilesModalValues>
      name="specializations"
      component={
        <Select
          placeholder={
            <FormattedMessage defaultMessage="Wybierz specjalizację" />
          }
          options={options}
          optionFilterProp="label"
          allowClear
          loading={isLoading}
        />
      }
    />
  );
};

export default SpecializationSelect;
