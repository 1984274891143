import type { UpdatePreorderValues } from '../../config';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Preorder } from 'ducks/preorders/types';
import { Checkbox, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import TruncateText from 'components/ui/atoms/TruncateText';
import ClientAvatar from 'components/ui/molecules/ClientAvatar';
import { NO_DATA } from 'constants/strings';
import { Routes } from 'views/Clients/Routing';
import PaymentsTotalSection from './PaymentsTotalSection';

const { Text } = Typography;

type Props = {
  data: Preorder;
  variantTotalPrice: number | null;
  isEditable: boolean;
};

export const formItems = ({
  data,
  variantTotalPrice,
  isEditable,
}: Props): FormItemRecord<UpdatePreorderValues>[] => {
  const { verifiedKrd, client, clientId, isCompany, contactPerson } = data;
  const isClientExist = !!data.client;
  const isDebtor = !!client?.isDebtor;
  const balance = client?.balance;
  const discount = client?.discount ?? 0;
  const clientName = client?.name ?? '';

  return [
    {
      key: 'name',
      label: <FormattedMessage defaultMessage="Nazwa:" />,
      component: clientName ? (
        <Link
          to={Routes.CLIENT_DETAILS.replace(
            ':clientId',
            clientId ? clientId?.toString() : '',
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ClientAvatar
            data-testid="name"
            isCompany={isCompany}
            name={clientName}
            contactPerson={contactPerson}
            preferredName={clientName}
            strong
            underline
          />
        </Link>
      ) : (
        <TruncateText data-testid="name" strong>
          {NO_DATA}
        </TruncateText>
      ),
    },
    {
      key: 'clientExist',
      label: <FormattedMessage defaultMessage="Istniejący klient:" />,
      component: (
        <CircleCheckbox data-testid="clientExist" value={isClientExist} />
      ),
    },
    {
      key: 'discount',
      label: <FormattedMessage defaultMessage="Stały rabat:" />,
      component: <Text data-testid="discount" strong>{`${discount}%`}</Text>,
    },
    {
      key: 'variantTotalPrice',
      label: <FormattedMessage defaultMessage="Wartość wariantu:" />,
      component: (
        <span>
          {variantTotalPrice ? (
            <CurrencyFormatter value={variantTotalPrice} />
          ) : (
            NO_DATA
          )}
        </span>
      ),
    },
    {
      key: 'balance',
      label: <FormattedMessage defaultMessage="Saldo:" />,
      component: (
        <Text data-testid="balance" strong>
          {balance ? <CurrencyFormatter value={balance} /> : NO_DATA}
        </Text>
      ),
      hidden: !isClientExist,
    },
    {
      key: 'isDebtor',
      label: <FormattedMessage defaultMessage="Dłużnik:" />,
      component: <CircleCheckbox data-testid="isDebtor" value={isDebtor} />,
      hidden: !isClientExist,
    },
    {
      key: 'verifiedKrd',
      label: <FormattedMessage defaultMessage="KRD:" />,
      name: 'verifiedKrd',
      component: isEditable ? (
        <Checkbox data-testid="verifiedKrdCheckbox" />
      ) : (
        <CircleCheckbox data-testid="verifiedKrd" value={verifiedKrd} />
      ),
      additionalItemProps: { valuePropName: 'checked' },
    },
    {
      key: 16,
      label: <FormattedMessage defaultMessage="Wartość płatności:" />,
      component: <PaymentsTotalSection />,
    },
  ];
};
