import type { TextProps } from 'antd/es/typography/Text';
import type { FC } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Avatar, Space } from 'antd';
import { Link } from 'react-router-dom';
import TruncateText from 'components/ui/atoms/TruncateText';
import { NO_DATA } from 'constants/strings';
import { BriefcaseIcon, PersonIcon } from 'icons';
import styles from './styles.module.scss';

type Props = {
  isCompany: boolean;
  name?: string | null;
  contactPerson?: string | null;
  link?: LinkProps['to'];
  strong?: boolean;
  preferredName?: string;
} & TextProps;

const ClientAvatar: FC<Props> = ({
  isCompany,
  name,
  contactPerson,
  link,
  preferredName,
  ...textProps
}) => {
  const displayName =
    preferredName ?? ((isCompany ? name : contactPerson) || NO_DATA);

  const avatarComponent = (
    <Space>
      <Avatar
        size="small"
        icon={isCompany ? <BriefcaseIcon /> : <PersonIcon />}
        className={
          isCompany ? styles.companyBackground : styles.clientBackground
        }
      />
      <TruncateText maxLength={15} {...textProps}>
        {displayName}
      </TruncateText>
    </Space>
  );

  if (!link) return avatarComponent;

  return (
    <Link to={link} onClick={(e) => e.stopPropagation()}>
      {avatarComponent}
    </Link>
  );
};

export default ClientAvatar;
