export enum TemplateSnippet {
  NUMBER = '%numer_zlecenia%',
  PAYMENT_LINK = '%link_platnosci%',
  PAYMENT_LINK_PAYPAL = '%link_platnosci_PayPal%',
  FILE_LIST = '%lista_plikow%',
  NETTO_VALUE = '%wartosc_netto%',
  BRUTTO_VALUE = '%wartosc_brutto%',
  REALIZATION_DATE = '%termin_realizacji%',
  ADDITIONAL_SERVICES = '%uslugi_dodatkowe%',
  TRANSLATOR_NETTO_VALUE = '%stawka_netto_tlumacz%',
  TRANSLATOR_FILE_LIST = '%lista_plikow_tlumacz%',
  PREORDER_NETTO_VALUE = '%wartość_netto_wycena%',
  PREORDER_BRUTTO_VALUE = '%wartość_brutto_wycena%',
  PREORDER_PAYMENT_LINK = '%link_platnosci_wycena%',
  PREORDER_REALIZATION_DATE = '%termin_realizacji_wycena%',
  PREORDER_ADDITIONAL_SERVICES = '%uslugi_dodatkowe_wycena%',
  PREORDER_FILE_LIST = '%lista_plikow_wycena%',
  PREORDER_FILE_LIST_WITHOUT_PRICE = '%lista_plikow_bez_ceny_wycena%',
  PREORDER_VARIANTS_NUMBER = '%liczba_wariantow%',
}

export const templateSnippets = Object.values(TemplateSnippet);

export const mappedTemplateSnippets = templateSnippets.reduce(
  (acc, snippetKey) => {
    acc[snippetKey] = '';

    return acc;
  },
  {} as Record<TemplateSnippet, string>,
);
