import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { LetterSummons } from 'ducks/invoices/types';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { DayjsFormat } from 'constants/dayjsFormats';
import { NO_DATA } from 'constants/strings';

export const formItems = ({
  dateSent,
  dateReceived,
}: LetterSummons): FormItemRecord<LetterSummons>[] => {
  const dateSentValue = dayjs(dateSent).format(DayjsFormat.DATE);
  const dateReceivedValue = dateReceived
    ? dayjs(dateReceived).format(DayjsFormat.DATE)
    : NO_DATA;

  return [
    {
      key: 'dateSent',
      label: <FormattedMessage defaultMessage="Data wysłania:" />,
      component: <strong data-testid="dateSent">{dateSentValue}</strong>,
    },
    {
      key: 'dateReceived',
      label: <FormattedMessage defaultMessage="Data odebrania:" />,
      component: (
        <strong data-testid="dateReceived">{dateReceivedValue}</strong>
      ),
    },
  ];
};
