import type { InvoiceOverdueRow } from './config';
import type { GetInvoicesOverdueFilter } from 'ducks/invoices/types';
import { Space } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Table from 'components/ui/molecules/Table';
import { useGetInvoicesOverdueQuery } from 'ducks/invoices/service';
import useTablePagination from 'hooks/useTablePagination';
import { Routes } from 'views/Invoices/Routing';
import InvoicesOverdueToolbar from './components/InvoicesOverdueToolbar';
import { columns, initialInvoicesOverdueFilters } from './config';
import { getRowColorByInvoiceOverdueStatus } from './utils';

const InvoicesOverdue = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    page,
    handlePageChange,
    searchValue,
    handleSearchValue,
    filter,
    filterBy,
    handleFilterBy,
  } = useTablePagination<InvoiceOverdueRow, GetInvoicesOverdueFilter>({
    initialFilters: initialInvoicesOverdueFilters,
  });
  const { data, isFetching } = useGetInvoicesOverdueQuery({ page, filter });
  const pageSize = data?.perPage;
  const total = isFetching ? 0 : data?.total;

  const modifiedData = useMemo(
    (): InvoiceOverdueRow[] =>
      (data?.data ?? []).map((invoiceOverdue) => ({
        ...invoiceOverdue,
        key: invoiceOverdue.id,
      })),
    [data],
  );

  return (
    <Space className="layout-content mb-24 width-100" direction="vertical">
      <InvoicesOverdueToolbar
        searchValue={searchValue}
        onSearchValueChange={handleSearchValue}
        filterBy={filterBy}
        onFilterByChange={handleFilterBy}
      />
      <Table
        cardTitle={
          <FormattedMessage defaultMessage="Faktury przeterminowane" />
        }
        data={modifiedData}
        columns={columns({ intl })}
        isLoading={isFetching}
        pagination={{
          current: page,
          pageSize,
          total,
          onChange: handlePageChange,
        }}
        onRow={({ id, status }) => ({
          className: getRowColorByInvoiceOverdueStatus(status),
          onClick: () =>
            navigate(
              Routes.INVOICE_OVERDUE_DETAILS.replace(':id', id.toString()),
            ),
        })}
      />
    </Space>
  );
};

export default InvoicesOverdue;
