import type { AddPaymentValues } from '../../config';
import type { FormInstance } from 'antd';
import type { FC, ReactElement } from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import FormItem from 'components/ui/atoms/FormItem';
import { useAppSelector } from 'ducks/store';
import { calcPreorderVariantPriceTotal } from 'utilities/calcPreorderTotals';
import { calcBrutto } from 'utilities/calcPrice';
import styles from './styles.module.scss';

export type AddPaymentAmountOption = { label: ReactElement; value: number };

type Props = {
  form: FormInstance<AddPaymentValues>;
};

/**
 * When we have variants from preorder or orders, we select with
 * the values of individual variants; otherwise, we provide input number.
 *
 * If there is only one option, then we set the default to its amount.
 */
const AddPaymentAmountColumn: FC<Props> = ({ form }) => {
  const { preorderVariants, orderVariants, vatRate } = useAppSelector(
    ({ preorders, orders }) => ({
      preorderVariants: preorders.variants,
      orderVariants: orders.variants,
      vatRate: orders.vatRate ?? preorders.vatRate,
    }),
    shallowEqual,
  );

  useEffect(() => {
    const variants = preorderVariants.length ? preorderVariants : orderVariants;

    if (variants.length !== 1) return;

    const amount = calcBrutto(
      calcPreorderVariantPriceTotal(variants[0]),
      vatRate,
    );

    form.setFieldValue('amount', amount);
  }, [form, orderVariants, preorderVariants, vatRate]);

  return (
    <FormItem<AddPaymentValues>
      className={styles.wrapper}
      name="amount"
      component={
        <DecimalInputNumber data-testid="amountInput" className="width-100" />
      }
      rules={[
        {
          required: true,
          message: <FormattedMessage defaultMessage="Uzupełnij kwotę" />,
        },
      ]}
    />
  );
};

export default AddPaymentAmountColumn;
