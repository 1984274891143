import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { OrderListElement } from 'ducks/orders/types';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';

export type OrderRow = TableRow & OrderListElement;

type Props = {
  selectedOrderIds: number[];
  handleSelectOrder: (orderId: number) => void;
};

export const orderColumns = ({
  selectedOrderIds,
  handleSelectOrder,
}: Props): ColumnsType<OrderRow> => [
  {
    key: 'checkbox',
    dataIndex: 'checkbox',
    width: 20,
    align: 'center',
    render: (_, { id }) => {
      const checked = selectedOrderIds.some((orderId) => orderId === id);

      return (
        <Checkbox
          data-testid={`checkbox-${id}`}
          checked={checked}
          onChange={() => handleSelectOrder(id)}
        />
      );
    },
  },
  {
    key: 'number',
    dataIndex: 'number',
    title: <FormattedMessage defaultMessage="Numer zamówienia" />,
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: <FormattedMessage defaultMessage="Status" />,
  },
  {
    key: 'priceBrutto',
    dataIndex: 'priceBrutto',
    title: <FormattedMessage defaultMessage="Wartość zamówienia" />,
    render: (_, { priceBrutto }) => <CurrencyFormatter value={priceBrutto} />,
  },
  {
    key: 'sumPaid',
    dataIndex: 'sumPaid',
    title: (
      <FormattedMessage defaultMessage="Zapłacono (wartość sumaryczna wpłat)" />
    ),
    render: (_, { sumPaid }) => <CurrencyFormatter value={sumPaid} />,
  },
];
