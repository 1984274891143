import type { FormInstance } from 'antd';
import type { FC } from 'react';
import { Space } from 'antd';
import { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Loader from 'components/ui/atoms/Loader';
import CardCommon from 'components/ui/molecules/CardCommon';
import TemplateEditor from 'components/ui/organisms/TemplateEditor';
import { NO_DATA } from 'constants/strings';
import {
  mappedTemplateSnippets,
  TemplateSnippet,
  templateSnippets,
} from 'constants/template';
import { useGetLanguagesQuery } from 'ducks/global/service';
import {
  useGetPreorderQuery,
  useGetPreorderVariantsQuery,
} from 'ducks/preorders/service';
import { useAppSelector } from 'ducks/store';
import { calcPreorderVariantPriceTotal } from 'utilities/calcPreorderTotals';
import { calcBrutto } from 'utilities/calcPrice';
import {
  generateEmailSnippetAdditionalServices,
  generateEmailSnippetHyperLink,
  generateEmailSnippetPreorderFileList,
  generateEmailSnippetPreorderVariantsNumber,
  getEmailSnippetRealizationDate,
} from 'utilities/emailTemplateSnippetValues';
import { formatPrice } from 'utilities/price';

const FIELD_KEY = 'body';

type PreorderSnippetValues = Record<TemplateSnippet, string> | undefined;

type Props = {
  form: FormInstance;
};

const PreorderEmailTemplateEdtiorSection: FC<Props> = ({ form }) => {
  const { id: paramId = '', variantId } = useParams();
  const preorderId = Number(paramId);
  const intl = useIntl();
  const { emailTemplate } = useAppSelector(
    ({ preorders }) => ({
      emailTemplate: preorders.emailTemplate,
    }),
    shallowEqual,
  );
  const { data: preorder, isLoading: isPreorderLoading } = useGetPreorderQuery({
    id: preorderId,
  });
  const { data: variants = [], isLoading: isVariantsLoading } =
    useGetPreorderVariantsQuery(
      {
        id: preorderId,
      },
      {
        skip: !preorder,
      },
    );
  const { data: languages = [] } = useGetLanguagesQuery();
  const isLoading = isPreorderLoading || isVariantsLoading;
  const firstVariant = variants[0];

  const selectedVariants = useMemo(
    () => variants.filter(({ id }) => id === Number(variantId)),
    [variants, variantId],
  );

  const [selectedVariantFiles, selectedVariantServices] = useMemo(
    () => [
      selectedVariants
        .flatMap(({ preorderVariantFiles }) => preorderVariantFiles)
        .filter(({ isActive }) => isActive),
      selectedVariants.flatMap(
        ({ preorderVariantServices }) => preorderVariantServices,
      ),
    ],
    [selectedVariants],
  );

  const preorderSnippetValues = useMemo((): PreorderSnippetValues => {
    if (!preorder) return undefined;
    const { number, vatRate } = preorder;

    const paymentLink = selectedVariants[0]?.onlinePaymentUrl ?? NO_DATA;
    const fileList = generateEmailSnippetPreorderFileList({
      intl,
      vatRate,
      variantFiles: selectedVariantFiles,
      languages,
    });
    const nettoValue = selectedVariants.reduce(
      (acc, variant) => acc + calcPreorderVariantPriceTotal(variant),
      0,
    );
    const bruttoValue = calcBrutto(nettoValue, vatRate);
    const timeRealizationTotal = selectedVariants.reduce(
      (acc, { timeRealization }) => acc + timeRealization,
      0,
    );
    const realizationDate = getEmailSnippetRealizationDate(
      intl,
      timeRealizationTotal,
    );
    const additionalServices = generateEmailSnippetAdditionalServices({
      intl,
      from: 'preorder',
      services: selectedVariantServices,
    });
    const preorderNettoValue = firstVariant
      ? calcPreorderVariantPriceTotal(firstVariant)
      : 0;
    const preorderBruttoValue = calcBrutto(preorderNettoValue, vatRate);
    const preorderFileList = (withoutPrice = false) =>
      generateEmailSnippetPreorderFileList({
        intl,
        vatRate,
        variantFiles: firstVariant?.preorderVariantFiles ?? [],
        languages,
        withoutPrice,
      });
    const preorderAdditionalServices = generateEmailSnippetAdditionalServices({
      intl,
      from: 'preorder',
      services: firstVariant?.preorderVariantServices ?? [],
    });
    const preorderRealizationDate = getEmailSnippetRealizationDate(
      intl,
      firstVariant?.timeRealization ?? 0,
    );
    const preorderPaymentLink = firstVariant?.onlinePaymentUrl ?? NO_DATA;
    const preorderVariantsNumber = generateEmailSnippetPreorderVariantsNumber(
      intl,
      variantId ? selectedVariants.length : variants.length,
    );

    return {
      ...mappedTemplateSnippets,
      [TemplateSnippet.NUMBER]: number ?? NO_DATA,
      [TemplateSnippet.PAYMENT_LINK]: paymentLink,
      [TemplateSnippet.FILE_LIST]: fileList,
      [TemplateSnippet.NETTO_VALUE]: formatPrice(nettoValue),
      [TemplateSnippet.BRUTTO_VALUE]: formatPrice(bruttoValue),
      [TemplateSnippet.REALIZATION_DATE]: realizationDate,
      [TemplateSnippet.ADDITIONAL_SERVICES]: additionalServices,
      [TemplateSnippet.PREORDER_NETTO_VALUE]: formatPrice(preorderNettoValue),
      [TemplateSnippet.PREORDER_BRUTTO_VALUE]: formatPrice(preorderBruttoValue),
      [TemplateSnippet.PREORDER_PAYMENT_LINK]: generateEmailSnippetHyperLink(
        intl.formatMessage({ defaultMessage: 'zapłać online' }),
        preorderPaymentLink,
      ),
      [TemplateSnippet.PREORDER_FILE_LIST]: preorderFileList(),
      [TemplateSnippet.PREORDER_FILE_LIST_WITHOUT_PRICE]:
        preorderFileList(true),
      [TemplateSnippet.PREORDER_ADDITIONAL_SERVICES]:
        preorderAdditionalServices,
      [TemplateSnippet.PREORDER_REALIZATION_DATE]: preorderRealizationDate,
      [TemplateSnippet.PREORDER_VARIANTS_NUMBER]: preorderVariantsNumber,
    };
  }, [
    intl,
    preorder,
    firstVariant,
    variants,
    variantId,
    selectedVariants,
    selectedVariantFiles,
    selectedVariantServices,
    languages,
  ]);

  useEffect(() => {
    form.setFieldValue(FIELD_KEY, emailTemplate);
  }, [emailTemplate, form]);

  useEffect(() => {
    if (!emailTemplate || !preorderSnippetValues) return;

    const hasSnippetInEmailTemplate = Object.values(TemplateSnippet).some(
      (snippet) => emailTemplate.includes(snippet),
    );

    if (!hasSnippetInEmailTemplate) return;

    const modifiedTemplate = templateSnippets.reduce(
      (accumulator, currentValue) =>
        accumulator.replace(currentValue, preorderSnippetValues[currentValue]),
      emailTemplate,
    );

    form.setFieldValue(FIELD_KEY, modifiedTemplate);
  }, [emailTemplate, preorderSnippetValues, form]);

  return (
    <CardCommon
      data-testid="emailContentSection"
      xs={24}
      disableListStyles={false}
    >
      <Loader visible={isLoading}>
        <Space className="width-100" direction="vertical" size="middle">
          <FormItem
            name={FIELD_KEY}
            additionalItemProps={{
              style: { margin: 0 },
            }}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage defaultMessage="Uzupełnij treść wiadomości" />
                ),
              },
            ]}
            component={<TemplateEditor />}
          />
        </Space>
      </Loader>
    </CardCommon>
  );
};

export default PreorderEmailTemplateEdtiorSection;
