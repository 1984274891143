import type { ComponentPropsWithoutRef, FC } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { countries } from 'utilities/country';

type Props = ComponentPropsWithoutRef<typeof Select>;

const CountrySelect: FC<Props> = ({ ...props }) => {
  return (
    <Select
      placeholder={<FormattedMessage defaultMessage="Wybierz kraj" />}
      showSearch
      options={countries.map(({ countryShortCode, countryName }) => ({
        value: countryShortCode,
        label: countryName,
      }))}
      optionFilterProp="label"
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
      allowClear
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default CountrySelect;
