import { useMemo } from 'react';
import {
  useGetOrderTranslationFilesQuery,
  useGetOrderVariantQuery,
} from 'ducks/orders/service';
import { useGetPaymentsQuery } from 'ducks/payments/service';
import { PaymentObject } from 'ducks/payments/types';
import { useOrdersServices } from 'views/Orders/useOrdersServices';

export const useOrderDetailsServices = () => {
  const {
    orderData,
    isOrderLoading,
    isOrderEmpty,
    isCurrentlyEdited,
    refetch,
  } = useOrdersServices();
  const { data: orderVariantData, isLoading: isOrderVariantLoading } =
    useGetOrderVariantQuery(
      {
        id: Number(orderData?.id),
      },
      {
        skip: !orderData,
      },
    );
  const { data: paymentsData = [], isLoading: isPaymentsLoading } =
    useGetPaymentsQuery(
      {
        id: Number(orderData?.id),
        paymentObject: PaymentObject.ORDERS,
      },
      { skip: !orderData },
    );
  const {
    data: orderTranslationFilesData,
    isFetching: isOrderTranslationFilesFetching,
  } = useGetOrderTranslationFilesQuery(
    {
      id: Number(orderData?.id),
    },
    { skip: !orderData },
  );
  const isOrderVariantEmpty =
    !orderVariantData && !isOrderLoading && !isOrderVariantLoading;

  const value = useMemo(
    () => ({
      isCurrentlyEdited,
      orderData,
      isOrderLoading,
      isOrderEmpty,
      refetch,
      orderVariantData,
      isOrderVariantLoading: isOrderLoading || isOrderVariantLoading,
      isOrderVariantEmpty,
      paymentsData,
      isPaymentsLoading: isOrderLoading || isPaymentsLoading,
      orderTranslationFilesData,
      isOrderTranslationFilesFetching,
    }),
    [
      isCurrentlyEdited,
      orderData,
      isOrderLoading,
      isOrderEmpty,
      refetch,
      orderVariantData,
      isOrderVariantLoading,
      isOrderVariantEmpty,
      paymentsData,
      isPaymentsLoading,
      orderTranslationFilesData,
      isOrderTranslationFilesFetching,
    ],
  );

  return value;
};
