import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { ReactElement, FC } from 'react';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Table from 'components/ui/molecules/Table';
import {
  useGetClientOrdersQuery,
  usePostOrderTmMutation,
} from 'ducks/clients/service';
import { orderColumns } from '../../config';

type Props = {
  selectedLang: string | null;
  tableCardTitle: ReactElement;
  notify: NotificationInstance;
};

const OrdersTab: FC<Props> = ({ selectedLang, tableCardTitle, notify }) => {
  const { clientId = '' } = useParams();
  const { data = [], isFetching } = useGetClientOrdersQuery(
    {
      clientId,
    },
    { skip: !clientId },
  );
  const [postOrderTm, { isLoading: postTmMutationLoading }] =
    usePostOrderTmMutation();

  const handleCheckboxClick = async (id: number, checked: boolean) => {
    try {
      await postOrderTm({ orderId: id, isMt: checked }).unwrap();

      notify.success({
        message: (
          <FormattedMessage defaultMessage="Pomyślnie wprowadzono zmiany" />
        ),
      });
    } catch {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wsytąpił błąd, spróbuj ponownie" />
        ),
      });
    }
  };

  const modifiedData = useMemo(
    () =>
      data.map(({ langs, ...props }) => ({
        ...props,
        key: props.id,
        searchdump: langs
          .map(({ langFrom, langTo }) => [langFrom, langTo].join(','))
          .join(),
      })),
    [data],
  );

  const filteredData = useMemo(
    () =>
      modifiedData.filter(({ searchdump }) =>
        searchdump.includes(selectedLang ?? ''),
      ),
    [selectedLang, modifiedData],
  );

  return (
    <Table
      columns={orderColumns({
        onCheckboxClick: handleCheckboxClick,
        postTmMutationLoading,
      })}
      data={filteredData}
      isLoading={isFetching}
      cardTitle={tableCardTitle}
    />
  );
};

export default OrdersTab;
