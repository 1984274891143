import type { UploadFile } from 'antd';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { TrashIcon } from 'icons';
import styles from './styles.module.scss';

interface Props {
  uploadFile: UploadFile;
  onDelete?: (uploadFile: UploadFile) => void;
}

export const UploadItem: FC<Props> = ({ uploadFile, onDelete }) => {
  return (
    <div className={styles.wrapper}>
      {uploadFile.originFileObj && (
        <a
          href={URL.createObjectURL(uploadFile.originFileObj as File)}
          target="_blank"
          rel="noreferrer"
        >
          {uploadFile.name}
        </a>
      )}
      {onDelete && (
        <Button
          onClick={() => onDelete(uploadFile)}
          type="link"
          size="small"
          danger
          icon={<TrashIcon />}
        >
          <FormattedMessage defaultMessage="Usuń" />
        </Button>
      )}
    </div>
  );
};
