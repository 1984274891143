import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AllCurrenciesInfo from 'components/ui/molecules/AllCurrenciesInfo';
import CardCommon from 'components/ui/molecules/CardCommon';
import List from 'components/ui/molecules/List';
import {
  sharedSummaryListItems,
  summaryListTimeRealizationItem,
} from 'constants/summaryList';
import { calcVariantTotalRealizationTime } from 'utilities/calcPreorderRealizationTime';
import {
  calcPreorderVariantFilesCorrectionTotal,
  calcPreorderVariantFilesPriceNettoTotal,
  calcPreorderVariantFilesSpecialTotal,
  calcPreorderVariantPriceTotal,
  calcPreorderVariantServicesPriceNettoTotal,
} from 'utilities/calcPreorderTotals';
import usePreorderRealizationServices from '../../usePreorderRealizationServices';

const SummarySection = () => {
  const { variantId } = useParams();
  const { preorderVariantsData, isLoading } = usePreorderRealizationServices();

  const specificVariantExist = useMemo(
    () => preorderVariantsData.some(({ id }) => id === Number(variantId)),
    [preorderVariantsData, variantId],
  );

  const selectedVariants = useMemo(
    () => preorderVariantsData.filter(({ id }) => id === Number(variantId)),
    [preorderVariantsData, variantId],
  );

  const totals = useMemo(
    () => ({
      timeRealizationTotal: selectedVariants.reduce(
        (acc, preorderVariant) =>
          acc + preorderVariant.timeRealization ||
          calcVariantTotalRealizationTime(preorderVariant),
        0,
      ),
      translationsTotal: selectedVariants.reduce(
        (acc, { preorderVariantFiles }) =>
          acc + calcPreorderVariantFilesPriceNettoTotal(preorderVariantFiles),
        0,
      ),
      specialTotal: selectedVariants.reduce(
        (acc, { preorderVariantFiles }) =>
          acc + calcPreorderVariantFilesSpecialTotal(preorderVariantFiles),
        0,
      ),
      correctionTotal: selectedVariants.reduce(
        (acc, { preorderVariantFiles }) =>
          acc + calcPreorderVariantFilesCorrectionTotal(preorderVariantFiles),
        0,
      ),
      graphicTotal: selectedVariants.reduce(
        (acc, { preorderVariantServices }) =>
          acc +
          calcPreorderVariantServicesPriceNettoTotal(preorderVariantServices)
            .graphicTotal,
        0,
      ),
      shipmentTotal: selectedVariants.reduce(
        (acc, { preorderVariantServices }) =>
          acc +
          calcPreorderVariantServicesPriceNettoTotal(preorderVariantServices)
            .shipmentTotal,
        0,
      ),
      preparatoryTotal: selectedVariants.reduce(
        (acc, { preorderVariantServices }) =>
          acc +
          calcPreorderVariantServicesPriceNettoTotal(preorderVariantServices)
            .preparatoryTotal,
        0,
      ),
      additionalTotal: selectedVariants.reduce(
        (acc, { preorderVariantServices }) =>
          acc +
          calcPreorderVariantServicesPriceNettoTotal(preorderVariantServices)
            .additionalTotal,
        0,
      ),
      total: selectedVariants.reduce(
        (acc, preorderVariant) =>
          acc + calcPreorderVariantPriceTotal(preorderVariant),
        0,
      ),
    }),
    [selectedVariants],
  );

  if (!specificVariantExist && !isLoading) return null;

  return (
    <CardCommon data-testid="summarySection" xs={24}>
      <List
        isLoading={isLoading}
        items={[
          summaryListTimeRealizationItem(totals.timeRealizationTotal),
          ...sharedSummaryListItems(totals),
        ]}
        withoutMargin
      />
      <AllCurrenciesInfo price={totals.total} />
    </CardCommon>
  );
};

export default SummarySection;
