import type {
  AddFileRequest,
  AddFileResponse,
  CalcTranslationsRequest,
  CalcTranslationsResponse,
  CheckNipRequest,
  CheckNipResponse,
  CheckVatRequest,
  CheckVatResponse,
  DeleteFileRequest,
  ExchangeRatesRequest,
  ExchangeRatesResponse,
  GetFileRequest,
  GetFileResponse,
  GetLanguagesResponse,
  UploadedFile,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery, { doRequest } from 'ducks/api';
import { convertKeysToSnakeCase } from 'utilities/object';
import { GlobalTag } from './types';

export enum Endpoint {
  CheckNip = '/nip-check',
  CheckVat = '/vat-check',
  GetFile = '/files/:object/:id',
  AddFile = '/files/:object/:id',
  DeleteFile = '/files/:object/:id',
  GetLanguages = '/languages',
  CalcTranslations = '/calc-translations',
  ExchangeRates = '/exchange-rates',
}

export const globalApi = createApi({
  reducerPath: 'globalApi',
  baseQuery: baseQuery(),
  tagTypes: [GlobalTag.Global, GlobalTag.Languages],
  endpoints: (build) => ({
    checkNip: build.mutation<CheckNipResponse, CheckNipRequest>({
      query: (data) => ({
        url: Endpoint.CheckNip,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
    }),
    checkVat: build.mutation<CheckVatResponse, CheckVatRequest>({
      query: (data) => ({
        url: Endpoint.CheckVat,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
    }),
    getFile: build.query<GetFileResponse, GetFileRequest>({
      query: ({ object, id }) => ({
        url: Endpoint.GetFile.replace(':object', object).replace(
          ':id',
          id.toString(),
        ),
        method: 'GET',
        responseType: 'blob',
      }),
    }),
    addFile: build.mutation<AddFileResponse, AddFileRequest>({
      query: ({ object, id, ...data }) => {
        const formData = new FormData();

        data.files.forEach((file, index) => {
          formData.append(`files[${index}][file]`, new Blob([file]), file.name);
        });

        return {
          url: Endpoint.AddFile.replace(':object', object).replace(
            ':id',
            id.toString(),
          ),
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
    }),
    deleteFile: build.mutation<void, DeleteFileRequest>({
      query: ({ object, id }) => ({
        url: Endpoint.DeleteFile.replace(':object', object).replace(
          ':id',
          id.toString(),
        ),
        method: 'DELETE',
      }),
    }),
    getLanguages: build.query<GetLanguagesResponse, void>({
      query: () => ({
        url: Endpoint.GetLanguages,
        method: 'GET',
      }),
      providesTags: [GlobalTag.Languages],
    }),
    calcTranslations: build.mutation<
      CalcTranslationsResponse,
      CalcTranslationsRequest
    >({
      query: (data) => ({
        url: Endpoint.CalcTranslations,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
    }),
    exchangeRates: build.mutation<ExchangeRatesResponse, ExchangeRatesRequest>({
      query: (data) => ({
        url: Endpoint.ExchangeRates,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
    }),
  }),
});

export const getFile = async ({
  id,
  object,
}: Pick<UploadedFile, 'id' | 'object'>): Promise<unknown | null> => {
  const response = await doRequest({
    url: Endpoint.GetFile.replace(':object', object).replace(
      ':id',
      id.toString(),
    ),
    responseType: 'blob',
  });

  if (!response) return null;

  return response.data as unknown;
};

export const {
  useCheckNipMutation,
  useCheckVatMutation,
  useLazyGetFileQuery,
  useAddFileMutation,
  useDeleteFileMutation,
  useGetLanguagesQuery,
  useCalcTranslationsMutation,
  useExchangeRatesMutation,
} = globalApi;
