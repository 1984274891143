import type { OrderTranslationFilesState } from 'ducks/view/orderTranslationFiles';
import type { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { NO_DATA } from 'constants/strings';
import { useAppSelector } from 'ducks/store';
import sharedStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

type Props = {
  fileId: number;
  orderFileLangs: OrderTranslationFilesState['orderFiles'][number]['orderFileLangs'];
};

const SourceFilesColumn: FC<Props> = ({ fileId, orderFileLangs }) => {
  const uploadedOrderFiles = useAppSelector(
    ({ orderTranslationFiles }) => orderTranslationFiles.uploadedFiles.orders,
    shallowEqual,
  );

  const getOrderFile = (id: number) =>
    uploadedOrderFiles.find((el) => el.id === id);

  const filename = getOrderFile(fileId)?.filename ?? NO_DATA;

  return (
    <div className={styles.wrapper}>
      <span>{filename}</span>
      <div className={sharedStyles.translationFilesWrapper}>
        {orderFileLangs.map(({ orderTranslationFiles }) =>
          orderTranslationFiles.map((orderTranslationFile) => (
            <div key={orderTranslationFile.id}>{filename}</div>
          )),
        )}
      </div>
    </div>
  );
};

export default SourceFilesColumn;
