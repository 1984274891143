import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { useAppDispatch } from 'ducks/store';
import {
  setFinancesAssignPaymentTransactionModal,
  type ParsePaymentFileSlice,
} from 'ducks/view/finances';

type Props = {
  id: string;
  object: ParsePaymentFileSlice['object'];
};

const ActionsColumn: FC<Props> = ({ id, object }) => {
  const dispatch = useAppDispatch();
  const isAssigned = !!object;

  const handleOpenAssignPaymentModal = () =>
    dispatch(
      setFinancesAssignPaymentTransactionModal({
        isOpen: true,
        parsedPaymentId: id,
        selectedPaymentTransaction: object,
      }),
    );

  return (
    <Button onClick={handleOpenAssignPaymentModal}>
      {isAssigned ? (
        <FormattedMessage defaultMessage="Przypisano wpłatę" />
      ) : (
        <FormattedMessage defaultMessage="Przypisz wpłatę" />
      )}
    </Button>
  );
};

export default ActionsColumn;
