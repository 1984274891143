/* eslint-disable react/jsx-props-no-spreading */
import type { EditTranslatorValues } from './config';
import { Button, Card, Empty, Form, Row, Space, notification } from 'antd';
import { useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/ui/atoms/Loader';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import {
  useGetTranslatorDetailsQuery,
  useGetTranslatorsSpecializationsQuery,
  useUpdateTranslatorMutation,
} from 'ducks/translators/service';
import { Routes } from 'views/Translators/Routing';
import BasicSection from './components/BasicSection';
import CatSection from './components/CatSection';
import CatToolsSection from './components/CatToolsSection';
import ContactFormSection from './components/CorrespondenceFormSection';
import InvoiceSection from './components/InvoiceSection';
import SpecializationsSection from './components/SpecializationsSection';
import TranslatorTypeSection from './components/TranslatorTypeSection';

const { useForm } = Form;

const LoaderOverlay = ({
  children,
  isLoading,
}: {
  children: React.ReactNode;
  isLoading: boolean;
}) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!isLoading) return <>{children}</>;
  return (
    <Loader testId="formLoader" visible={isLoading}>
      {children}
    </Loader>
  );
};

const TranslatorProfileForm = () => {
  const { id: idParam = '' } = useParams();
  const navigate = useNavigate();
  const [form] = useForm<EditTranslatorValues>();
  const [notify, contextHolder] = notification.useNotification();
  const translatorId = Number(idParam);
  const { data, isLoading: isDataLoading } = useGetTranslatorDetailsQuery({
    translatorId,
  });
  const { data: specializationsData = [], isLoading: specializationsLoading } =
    useGetTranslatorsSpecializationsQuery();

  const [updateTranslator, { isLoading: isUpdateLoading }] =
    useUpdateTranslatorMutation();
  const isEmpty = !data && !isDataLoading;
  const isLoading = isDataLoading || isUpdateLoading || specializationsLoading;

  const handleOnSubmit = async (values: EditTranslatorValues) => {
    if (isLoading || !data) return;

    try {
      const {
        gender,
        title,
        langNative,
        formOfSettlement,
        invoiceCity,
        invoiceProvince,
        city,
        province,
        catOther,
        otherCheckbox,
      } = values;

      await updateTranslator({
        ...values,
        gender: gender ?? null,
        title: title ?? null,
        langNative: langNative ?? null,
        formOfSettlement: formOfSettlement ?? null,
        invoiceCity: invoiceCity ?? null,
        invoiceProvince: invoiceProvince ?? null,
        city: city ?? null,
        province: province ?? null,
        translatorId,
        catOther: otherCheckbox ? catOther : null,
      }).unwrap();

      notify.success({
        message: (
          <FormattedMessage defaultMessage="Tłumacz został edytowany." />
        ),
      });
      form.setFieldValue('otherCheckbox', !!catOther);
      navigate(Routes.TRANSLATOR_PROFILE.replace(':id', idParam));
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edycji tłumacza." />
        ),
      });
    }
  };

  const initialValues = useMemo((): EditTranslatorValues | undefined => {
    if (!data) return undefined;

    const { cats, specializations, catsTools, types, langNative, catOther } =
      data;

    return {
      ...data,
      langNative: langNative?.toUpperCase() ?? '',
      agreeToSmsContact: 1,
      catsTools: catsTools.map(({ id }) => id),
      cats: cats.map(({ id }) => id),
      types: types.map(({ id }) => id),
      specializations: specializations.map(({ id }) => id),
      otherCheckbox: !!catOther,
      country: data.country ?? LanguageCode.POLAND,
      invoiceCountry: data.invoiceCountry ?? LanguageCode.POLAND,
      catOther,
    };
  }, [data]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <div className="layout-content mb-24" data-testid="translatorProfileForm">
      {contextHolder}
      {isEmpty ? (
        <Empty
          data-testid="translatorProfileEmpty"
          description={
            <FormattedMessage defaultMessage="Brak danych o tłumaczu" />
          }
        />
      ) : (
        <Card bordered={false}>
          <Form
            form={form}
            onFinish={handleOnSubmit}
            layout="vertical"
            initialValues={initialValues}
            autoComplete="off"
            scrollToFirstError
          >
            <Space direction="vertical" size="large" className="width-100">
              <LoaderOverlay isLoading={isLoading}>
                {!isLoading && (
                  <>
                    <Row gutter={[24, 24]} align="top">
                      <BasicSection form={form} />
                      <InvoiceSection form={form} />
                      <ContactFormSection form={form} />
                    </Row>
                    <Row gutter={[24, 24]} align="top">
                      <SpecializationsSection
                        specialization={specializationsData}
                      />
                      <TranslatorTypeSection form={form} />
                    </Row>
                    <Row gutter={[24, 24]} align="top">
                      <CatSection />
                      <CatToolsSection form={form} />
                    </Row>
                    <Space
                      className="width-100"
                      style={{ justifyContent: 'end' }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isUpdateLoading}
                        disabled={isLoading}
                      >
                        <FormattedMessage defaultMessage="Zapisz" />
                      </Button>
                    </Space>
                  </>
                )}
              </LoaderOverlay>
            </Space>
          </Form>
        </Card>
      )}
    </div>
  );
};

export default TranslatorProfileForm;
