import type { CalcTranslationsRequest } from 'ducks/global/types';
import type { OrderFileState } from 'ducks/view/orders';
import type { PreorderVariantFileSlice } from 'ducks/view/preorders';
import { useEffect, useMemo } from 'react';
import { useCalcTranslationsMutation } from 'ducks/global/service';
import useDebounce from './useDebounce';

type Props = {
  translations?: CalcTranslationsRequest;
  preorderVariantFiles?: PreorderVariantFileSlice[];
  orderFiles?: OrderFileState[];
};

/**
 * This hook uses the useCalcTranslationsMutation endpoint to
 * calculate the estimated price based on provided props
 * such as preorderVariantFiles or orderFiles.
 *
 * @example - If i want to calc the estimated price for preorderVariantFiles
 * const { estimatedPrice, isLoading } = useCalcTranslations({
 *  preorderVariantFiles,
 * });
 * @returns
 */
export const useCalcTranslations = ({
  translations,
  preorderVariantFiles,
  orderFiles,
}: Props) => {
  const [calcTranslations, { data, isLoading }] = useCalcTranslationsMutation();

  const estimatedPrice = data?.price ?? 0;

  const translationsList = useMemo((): CalcTranslationsRequest => {
    if (translations) return translations;

    if (preorderVariantFiles) {
      return preorderVariantFiles.flatMap(
        ({ preorderVariantLangs, type, isCorrection, isSpecial }) =>
          preorderVariantLangs.map(({ langFrom, langTo, characters }) => {
            return {
              type,
              isCorrection,
              isSpecial,
              langFrom,
              langTo,
              characters,
            };
          }),
      );
    }

    if (orderFiles) {
      return orderFiles.flatMap(
        ({ type, isCorrection, isSpecial, orderFileLangs }) =>
          orderFileLangs.map(({ langFrom, langTo, characters }) => {
            return {
              isCorrection,
              isSpecial,
              type,
              langFrom,
              langTo,
              characters,
            };
          }),
      );
    }

    return [];
  }, [translations, preorderVariantFiles, orderFiles]);

  const translationsListDebounced = useDebounce(translationsList, 1000);

  useEffect(() => {
    if (!translationsListDebounced.length) return;

    calcTranslations(translationsListDebounced);
  }, [translationsListDebounced, calcTranslations]);

  return { estimatedPrice, isLoading };
};
