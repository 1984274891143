import type { TranslatorPricingRow } from './config';
import type { FileTranslationType } from 'constants/fileTranslation';
import type { OrderFileLangState } from 'ducks/view/orders';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import Modal from 'components/ui/molecules/Modal';
import Table from 'components/ui/molecules/Table';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { useGetTranslatorsPricingQuery } from 'ducks/translators/service';
import { updateOrderFiles } from 'ducks/view/orders';
import { getTranslatorPriceNetto } from 'ducks/view/orders/utils';
import { getTranslatorPriceFrom } from '../../utils';
import { columns, initialFilters } from './config';
import styles from './styles.module.scss';
import { generatePriceListFilters } from './utils';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  orderFileId: number;
  isCorrection: boolean;
  isSpecial: boolean;
  orderFileLang: OrderFileLangState;
  type: FileTranslationType;
};

const TranslatorsModal: FC<Props> = memo(
  ({
    isOpen,
    onClose,
    orderFileId,
    orderFileLang,
    isCorrection,
    isSpecial,
    type,
  }) => {
    const {
      langFrom,
      langTo,
      pagesTranslator,
      catId,
      translatorId,
      isPostedit,
    } = orderFileLang;
    const dispatch = useAppDispatch();
    const orderFiles = useAppSelector(
      ({ orders }) => orders.orderFiles,
      shallowEqual,
    );
    const specializations = orderFiles.find(
      ({ id }) => id === orderFileId,
    )?.specializations;
    const { data = [], isFetching } = useGetTranslatorsPricingQuery(
      {
        filter: {
          ...initialFilters,
          lang: [langFrom, langTo],
          type,
          priceType: generatePriceListFilters(isSpecial, isCorrection),
          catId: catId ? [catId] : undefined,
          specializations,
          isPostedit,
        },
      },
      {
        skip: !isOpen,
      },
    );

    const modifiedData = useMemo(
      (): TranslatorPricingRow[] =>
        data.map((translator) => {
          const { normalFrom, specialFrom, correctionFrom } = translator;
          const priceFrom = getTranslatorPriceFrom({
            type,
            normalFrom,
            specialFrom,
            correctionFrom,
            isCorrection,
            isSpecial,
          });

          return {
            ...translator,
            key: translator.id,
            priceFrom,
          };
        }),
      [data, type, isSpecial, isCorrection],
    );

    const handleSelectTranslator = (id: number, priceFrom: number) => {
      const translatorPriceNetto = getTranslatorPriceNetto(
        priceFrom,
        pagesTranslator,
      );

      dispatch(
        updateOrderFiles({
          field: {
            [orderFileId]: {
              [orderFileLang.id]: {
                translatorId: id,
                translatorPriceNetto,
              },
            },
          },
        }),
      );

      onClose();
    };

    return (
      <Modal
        open={isOpen}
        onCancel={onClose}
        title={<FormattedMessage defaultMessage="Wybierz tłumacza" />}
        okButtonProps={{
          hidden: true,
        }}
      >
        <div className={styles.wrapper}>
          <Table
            data={modifiedData}
            columns={columns({
              handleSelectTranslator,
              translatorId,
            })}
            isLoading={isFetching}
          />
        </div>
      </Modal>
    );
  },
);

export default TranslatorsModal;
