import type { ConvertPreorderRequest } from 'ducks/preorders/types';

export type PreorderRealizationValues = Pick<
  ConvertPreorderRequest,
  'email' | 'cc' | 'subject' | 'body'
>;

export const initialValues: PreorderRealizationValues = {
  email: '',
  cc: '',
  subject: '',
  body: '',
};
