import type { FormInstance } from 'antd';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Payment } from 'ducks/payments/types';
import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { DatePicker, Select } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import { FormattedMessage } from 'react-intl';
import { currencyTypes } from 'constants/currency';
import { paymentTypes } from 'constants/payment';
import { CurrencyType } from 'ducks/payments/types';
import AddPaymentAmountColumn from './components/AddPaymentAmountColumn';

export type AddPaymentValues = Pick<
  Payment,
  'datePayment' | 'amount' | 'type' | 'currency'
>;

type Props = {
  intl: IntlShape;
  form: FormInstance<AddPaymentValues>;
  usedCurrency: CurrencyType;
};

export const initialAddPaymentValues: Partial<AddPaymentValues> = {
  datePayment: dayjs() as unknown as string,
  currency: CurrencyType.PLN,
};

export const formItems = ({
  intl,
  form,
  usedCurrency,
}: Props): FormItemRecord<AddPaymentValues>[] => [
  {
    key: 'datePayment',
    label: <FormattedMessage defaultMessage="Data płatności" />,
    name: 'datePayment',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz datę" />,
      },
    ],
    component: (
      <DatePicker
        data-testid="datePaymentDatePicker"
        className="width-100"
        locale={locale}
      />
    ),
  },
  {
    key: 'amount',
    label: <FormattedMessage defaultMessage="Kwota płatności" />,
    name: 'amount',
    component: <AddPaymentAmountColumn form={form} />,
  },
  {
    key: 'currency',
    label: <FormattedMessage defaultMessage="Waluta" />,
    name: 'currency',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz walutę" />,
      },
    ],
    component: (
      <Select
        data-testid="currencySelect"
        options={currencyTypes(intl).filter(({ value }) =>
          usedCurrency ? value === usedCurrency : true,
        )}
      />
    ),
  },
  {
    key: 'type',
    label: <FormattedMessage defaultMessage="Metoda płatności" />,
    name: 'type',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz metodę płatności" />,
      },
    ],
    component: <Select data-testid="typeSelect" options={paymentTypes(intl)} />,
  },
];
