import type { InvoiceOverdue } from 'ducks/invoices/types';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { NO_DATA } from 'constants/strings';
import { Routes } from 'views/Orders/Routing';
import styles from './styles.module.scss';

type Props = Pick<InvoiceOverdue, 'orders'>;

const OrderNumbersRecord: FC<Props> = ({ orders }) => {
  const isEmpty = !orders.length;
  const lastIndex = orders.length - 1;

  return (
    <div className={styles.wrapper}>
      {isEmpty
        ? NO_DATA
        : orders.map(({ id, number }, index) => (
            <>
              <Link
                key={number}
                to={Routes.ORDER_DETAILS.replace(':id', id.toString())}
              >
                {number}
              </Link>
              {lastIndex !== index && <span>,&nbsp;</span>}
            </>
          ))}
    </div>
  );
};

export default OrderNumbersRecord;
