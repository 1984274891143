import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { ClientOrder, ClientPreorderData } from 'ducks/clients/types';
import type { IntlShape } from 'react-intl';
import { Checkbox, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import FormatDate from 'components/ui/atoms/FormatDate';
import StatusCircle from 'components/ui/atoms/StatusCircle';
import { preorderStatusesObject } from 'constants/preorder';
import { NO_DATA } from 'constants/strings';
import { Routes as OrderRoutes } from 'views/Orders/Routing';
import { Routes as PreorderRoutes } from 'views/Preorders/Routing';
import styles from './styles.module.scss';

export type OrderRow = TableRow &
  Omit<ClientOrder, 'langs'> & {
    searchdump: string;
    key: number;
  };

export type PreorderRow = TableRow &
  Omit<ClientPreorderData, 'langs'> & {
    searchdump: string;
    key: number;
  };

type OrderProps = {
  onCheckboxClick: (id: number, checked: boolean) => void;
  postTmMutationLoading: boolean;
};

type PrerderProps = {
  intl: IntlShape;
};

export enum SelectedTable {
  ORDERS,
  PREORDERS,
}

export const orderColumns = ({
  onCheckboxClick,
  postTmMutationLoading,
}: OrderProps): ColumnsType<OrderRow> => [
  {
    title: <FormattedMessage defaultMessage="Faktura" />,
    key: 'invoiceStatus',
    dataIndex: 'invoiceStatus',
    render: (_, { invoiceStatus }) => (
      <CircleCheckbox value={!!invoiceStatus} />
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Numer" />,
    key: 'number',
    dataIndex: 'number',
    render: (_, { number, id }) => (
      <Link
        className={styles.link}
        to={OrderRoutes.ORDER_DETAILS.replace(':id', id.toString())}
      >
        {number}
      </Link>
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Cena netto" />,
    key: 'priceNetto',
    align: 'right',
    render: (_, { priceNetto }) => <CurrencyFormatter value={priceNetto} />,
  },
  {
    title: <FormattedMessage defaultMessage="Status" />,
    key: 'status',
    dataIndex: 'status',
    align: 'center',
    render: (_, { status }) => <StatusCircle status={status} />,
  },
  {
    title: <FormattedMessage defaultMessage="Data przyjęcia" />,
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (_, { createdAt }) => <FormatDate date={createdAt} />,
  },
  {
    title: <span />,
    width: 75,
    align: 'center',
    render: (_, { id, isMt }) => (
      <Space>
        <Checkbox
          disabled={postTmMutationLoading}
          onChange={(e) => onCheckboxClick(id, e.target.checked)}
          checked={!!isMt}
        >
          <FormattedMessage defaultMessage="TM" />
        </Checkbox>
      </Space>
    ),
  },
];

export const preorderColumns = ({
  intl,
}: PrerderProps): ColumnsType<PreorderRow> => [
  {
    title: <FormattedMessage defaultMessage="Numer" />,
    key: 'number',
    dataIndex: 'number',
    render: (_, { number, id }) => (
      <Link
        className={styles.link}
        to={PreorderRoutes.PREORDER_DETAILS.replace(':id', id.toString())}
      >
        {number ?? NO_DATA}
      </Link>
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Cena" />,
    key: 'priceMin',
    dataIndex: 'priceMin',
    align: 'right',
    render: (_, { priceMin, priceMax }) => {
      if (!priceMin || !priceMax) return NO_DATA;

      return (
        <span>
          <CurrencyFormatter value={priceMin} /> (
          <CurrencyFormatter value={priceMax} />)
        </span>
      );
    },
  },
  {
    title: <FormattedMessage defaultMessage="Status" />,
    key: 'status',
    dataIndex: 'status',
    render: (_, { status }) => preorderStatusesObject(intl)[status].label,
  },
  {
    title: <FormattedMessage defaultMessage="Data wysyłania" />,
    key: 'dateRealization',
    dataIndex: 'dateRealization',
    render: (_, { dateSent }) =>
      dateSent ? <FormatDate date={dateSent} /> : NO_DATA,
  },
];
