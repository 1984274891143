import type { GetTranslatorsSpecializationsResponse } from 'ducks/translators/types';
import type { FC } from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { sortAlphabeticallyByProp } from 'utilities/array';

const { Item } = Form;

interface Props {
  specialization: GetTranslatorsSpecializationsResponse;
}

const SpecializationsSection: FC<Props> = ({ specialization }) => {
  const mainSpecializations = useMemo(
    () => specialization.filter(({ parentId }) => !parentId),
    [specialization],
  );

  return (
    <Col span={24} xl={12}>
      <h6>
        <FormattedMessage defaultMessage="Specjalizacje" />
      </h6>
      <Item name="specializations">
        <Checkbox.Group>
          <Row>
            {mainSpecializations
              .sort((a, b) => sortAlphabeticallyByProp(a, b, 'name'))
              .map(({ id, name }) => (
                <Col key={id} span={12}>
                  <Checkbox value={id}>{name}</Checkbox>
                </Col>
              ))}
          </Row>
        </Checkbox.Group>
      </Item>
    </Col>
  );
};

export default SpecializationsSection;
