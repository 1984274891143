import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { GusReturnProps } from 'components/ui/atoms/GusButton';
import type { UpdateClientInvoiceRequest } from 'ducks/clients/types';
import { InputNumber, Switch } from 'antd';
import { FormattedMessage } from 'react-intl';
import CountrySelect from 'components/ui/atoms/CountrySelect';
import GusButton from 'components/ui/atoms/GusButton';
import { POLAND_COUNTRY_CODE } from 'constants/countryAndLanguageCodes';
import { MAX_NIP_LENGTH } from 'constants/formInputLengths';
import {
  validateNipRule,
  validatePostalCodeRule,
} from 'utilities/antdFormRules';
import InvoiceTypeSelect from './components/InvoiceTypeSelect';

export type EditInvoiceValues = Omit<UpdateClientInvoiceRequest, 'clientId'>;

type EditInvoiceFormItem = FormItemRecord<EditInvoiceValues>;

type EditInvoiceFormItemsProps = {
  isCompany: boolean;
  nip: string;
  handleCheckNip: (data: GusReturnProps) => void;
  invoiceCountry: string | null;
};

export const editInvoiceFormItems = ({
  isCompany,
  nip,
  handleCheckNip,
  invoiceCountry,
}: EditInvoiceFormItemsProps): EditInvoiceFormItem[] => [
  {
    key: 'isCompany',
    label: <FormattedMessage defaultMessage="Typ klienta:" />,
    additionalItemProps: { valuePropName: 'checked' },
    name: 'isCompany',
    component: (
      <Switch
        data-testid="isCompanySwitch"
        checkedChildren={<FormattedMessage defaultMessage="Firma" />}
        unCheckedChildren={<FormattedMessage defaultMessage="Osoba fizyczna" />}
      />
    ),
  },
  {
    key: 'invoiceType',
    label: <FormattedMessage defaultMessage="Rodzaj faktury:" />,
    name: 'invoiceType',
    component: <InvoiceTypeSelect />,
  },

  {
    key: 'invoiceEmail',
    label: <FormattedMessage defaultMessage="E-mail:" />,
    name: 'invoiceEmail',
    rules: [
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
  {
    key: 'invoiceCountry',
    label: <FormattedMessage defaultMessage="Kraj:" />,
    name: 'invoiceCountry',
    component: <CountrySelect placement="topLeft" />,
  },
  {
    key: 'invoiceNip',
    label: <FormattedMessage defaultMessage="NIP:" />,
    name: 'invoiceNip',
    rules: [
      {
        required: isCompany,
        message: <FormattedMessage defaultMessage="Podaj numer NIP" />,
      },
      validateNipRule({
        isInternational: invoiceCountry !== POLAND_COUNTRY_CODE,
      }),
    ],
    additionalInputProps:
      invoiceCountry !== POLAND_COUNTRY_CODE
        ? {}
        : { maxLength: MAX_NIP_LENGTH },
    hidden: !isCompany,
  },
  {
    key: 'gusButton',
    component: <GusButton onSuccessCallback={handleCheckNip} value={nip} />,
    hidden: invoiceCountry !== POLAND_COUNTRY_CODE || !isCompany,
  },
  {
    key: 'invoiceName',
    label: <FormattedMessage defaultMessage="Nazwa:" />,
    name: 'invoiceName',
  },
  {
    key: 8,
    label: <FormattedMessage defaultMessage="Ulica:" />,
    name: 'invoiceAddress',
  },
  {
    key: 'invoiceCity',
    label: <FormattedMessage defaultMessage="Miejscowość:" />,
    name: 'invoiceCity',
  },
  {
    key: 'invoicePostCode',
    label: <FormattedMessage defaultMessage="Kod pocztowy:" />,
    name: 'invoicePostCode',
    rules: [validatePostalCodeRule()],
  },
  {
    key: 'invoiceVatRate',
    label: <FormattedMessage defaultMessage="Vat:" />,
    name: 'invoiceVatRate',
    component: (
      <InputNumber
        className="width-100"
        precision={0}
        max={100}
        min={0}
        addonAfter="%"
      />
    ),
  },
  {
    key: 'invoiceAuto',
    label: (
      <FormattedMessage defaultMessage="Automatyczne wystawianie faktur:" />
    ),
    name: 'invoiceAuto',
    component: <Switch />,
    additionalItemProps: { valuePropName: 'checked' },
  },
  {
    key: 'isCollectiveInvoices',
    label: <FormattedMessage defaultMessage="Faktury zbiorcze:" />,
    name: 'isCollectiveInvoices',
    component: <Switch />,
  },
  {
    key: 'isDebtor',
    label: <FormattedMessage defaultMessage="Dłużnik:" />,
    name: 'isDebtor',
    component: <Switch />,
  },
];
