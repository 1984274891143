/* eslint-disable react/jsx-props-no-spreading */
import type { AddUserModalValues } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal/Modal';
import { useAddUserMutation } from 'ducks/users/service';
import styles from '../../styles.module.scss';
import { addUserModalFormItems } from './config';

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  notify: NotificationInstance;
}

const { Item, useForm } = Form;

const AddUserModal: FC<AddUserModalProps> = ({ isOpen, onClose, notify }) => {
  const [addUser, { isLoading }] = useAddUserMutation();
  const [form] = useForm<AddUserModalValues>();

  const handleOnSubmit = async () => {
    if (isLoading) return;

    const values = await form.validateFields();

    try {
      await addUser(values).unwrap();

      notify.success({
        message: (
          <FormattedMessage defaultMessage="Użytkownik został dodany." />
        ),
      });

      onClose();
      form.resetFields();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania użytkownika." />
        ),
      });
    }
  };

  return (
    <Modal
      data-testid="addUserModal"
      title={<FormattedMessage defaultMessage="Dodaj użytkownika" />}
      isLoading={isLoading}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={onClose}
      okText={<FormattedMessage defaultMessage="Dodaj" />}
      disableFormStyles
    >
      <Form form={form} layout="vertical">
        <div className={styles.columns}>
          {addUserModalFormItems.map(({ key, ...props }) => (
            <FormItem key={key} {...props} />
          ))}
        </div>
        <Item
          label={<FormattedMessage defaultMessage="Stopka e-mail (HTML)" />}
          name="emailFooter"
        >
          <Input.TextArea rows={4} />
        </Item>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
