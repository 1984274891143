import type { OrderSendEmailsTranslatorState } from 'ducks/view/orders';
import type { FC } from 'react';
import type { OrderSendEmailsValues } from 'views/Orders/views/OrderSendEmails/config';
import { Space } from 'antd';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Switch from 'components/ui/atoms/Switch';
import TruncateText from 'components/ui/atoms/TruncateText';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useOrderAssignServices } from 'views/Orders/views/OrderAssign';
import AttachmentSwitches from './AttachmentSwitches';

type Props = Pick<
  OrderSendEmailsTranslatorState,
  'translatorId' | 'attachments' | 'uploadedFiles'
>;

const TranslatorPanelSwitches: FC<Props> = memo(
  ({ translatorId, attachments, uploadedFiles }) => {
    const { orderData } = useOrderAssignServices();

    const translatorAssignedFilesIds = orderData?.orderFileLangs
      .filter(
        ({ translatorId: fileTranslatorId }) =>
          fileTranslatorId === translatorId,
      )
      .map(({ orderFileId }) => orderFileId);

    const translatorAssignedFiles = translatorAssignedFilesIds?.map((id) => {
      return orderData?.orderFiles.find(({ id: fileId }) => {
        return id === fileId;
      })?.file;
    });

    return (
      <CardCommon lg={8}>
        <Space className="width-100" direction="vertical" size="middle">
          <AttachmentSwitches
            translatorId={translatorId}
            attachments={attachments}
            files={translatorAssignedFiles}
            uploadedFiles={uploadedFiles}
          />
          <div>
            <TruncateText strong maxLength={50}>
              <FormattedMessage defaultMessage="Referencje" />
            </TruncateText>
            <FormItem<OrderSendEmailsValues>
              name="isReferenceFile"
              additionalItemProps={{
                valuePropName: 'checked',
              }}
              component={
                <Switch
                  label={<FormattedMessage defaultMessage="Prezentacja" />}
                />
              }
            />
          </div>
        </Space>
      </CardCommon>
    );
  },
);

export default TranslatorPanelSwitches;
