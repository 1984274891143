import type { InvoiceAddValues } from './config';
import { Form, Row, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import Empty from 'components/ui/atoms/Empty';
import { useAppSelector } from 'ducks/store';
import ActionsSection from './components/ActionsSection';
import ClientBillingSection from './components/ClientBillingSection';
import InvoiceAddHeader from './components/InvoiceAddHeader';
import InvoiceItemsSection from './components/InvoiceItemsSection';
import InvoiceSettingsSection from './components/InvoiceSettingsSection';
import NotesSection from './components/NotesSection';
import { useInvoiceAddServices } from './useInvoiceAddServices';

const { useForm } = Form;

const InvoiceAdd = () => {
  const [form] = useForm<InvoiceAddValues>();
  const orderIds = useAppSelector(
    ({ invoicesAdd }) => invoicesAdd.orderIds,
    shallowEqual,
  );
  const { isInvoiceDataEmpty } = useInvoiceAddServices();
  const isLackOfOrderIds = !orderIds.length;

  if (isLackOfOrderIds)
    return (
      <Empty
        description={<FormattedMessage defaultMessage="Nie wybrano zamówień" />}
      />
    );

  return (
    <div className="layout-content mb-24">
      {isInvoiceDataEmpty ? (
        <Empty />
      ) : (
        <Space className="width-100" direction="vertical" size="large">
          <InvoiceAddHeader />
          <Row gutter={[24, 24]}>
            <ClientBillingSection />
            <NotesSection />
            <InvoiceItemsSection />
            <InvoiceSettingsSection form={form} />
          </Row>
          <ActionsSection form={form} />
        </Space>
      )}
    </div>
  );
};

export default InvoiceAdd;
