import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { AssignOrderAllFilesPayload } from 'ducks/view/orders';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import CatSelect from './CatSelect';
import SpecializationSelect from './SpecializationSelect';

export type AssignAllFilesModalValues = ReplaceType<
  Omit<AssignOrderAllFilesPayload, 'orderFileId'>,
  Binary,
  boolean
>;

type Props = {
  catId: number | null;
};

export const initialValues: Partial<AssignAllFilesModalValues> = {
  isPostedit: false,
};

export const formItems = ({
  catId,
}: Props): FormItemRecord<AssignAllFilesModalValues>[] => [
  {
    key: 'specializations',
    label: <FormattedMessage defaultMessage="Wybierz specjalizację" />,
    component: <SpecializationSelect />,
  },
  {
    key: 'catId',
    label: <FormattedMessage defaultMessage="Wybierz CAT" />,
    component: <CatSelect />,
  },
  {
    key: 'isPostedit',
    label: <FormattedMessage defaultMessage="Postedycja" />,
    name: 'isPostedit',
    component: <Checkbox disabled={!catId} />,
    additionalItemProps: { valuePropName: 'checked' },
  },
];
