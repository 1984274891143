import type { Language } from 'ducks/global/types';
import type {
  GetOrderTranslationFilesResponse,
  TranslationsAndOrdersFile,
} from 'ducks/orders/types';
import type {
  PreorderVariantFile,
  PreorderVariantService,
} from 'ducks/preorders/types';
import type { OrderFileState, OrderServiceState } from 'ducks/view/orders';
import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { DayjsFormat } from 'constants/dayjsFormats';
import {
  FileTranslationType,
  fileTranslationTypesObject,
} from 'constants/fileTranslation';
import {
  ServiceAttributeNames,
  ServiceType,
  servicesObject,
} from 'constants/services';
import { NO_DATA } from 'constants/strings';
import { getOrderServiceInitialValue } from 'ducks/view/orders/utils';
import { getPreorderServiceInitialValue } from 'ducks/view/preorders/utils';
import { calcBrutto, calcFinalBrutto } from './calcPrice';
import { formatPrice } from './price';

type GetServiceLabel = {
  intl: IntlShape;
  shipmentType: string | undefined;
} & Pick<OrderServiceState, 'type' | 'priceNetto' | 'vatRate'>;

type GenerateEmailSnippetPreorderFileList = {
  intl: IntlShape;
  vatRate: number;
  variantFiles: PreorderVariantFile[];
  languages: Language[];
  withoutPrice?: boolean;
};

type GenerateEmailSnippetOrderFileList = {
  intl: IntlShape;
  vatRate: number;
  orderFiles: OrderFileState[];
  languages: Language[];
};

type GenerateEmailSnippetAdditionalServices = {
  intl: IntlShape;
} & (
  | { from: 'order'; services: OrderServiceState[] }
  | { from: 'preorder'; services: PreorderVariantService[] }
);

const getLanguageName = (
  intl: IntlShape,
  languages: Language[],
  langCode: string,
  genitiveCase = false,
) => {
  const language = languages.find(({ code }) => code === langCode);

  if (language)
    return `${language.name}${
      genitiveCase ? intl.formatMessage({ defaultMessage: 'ego' }) : ''
    }`;

  return langCode;
};

const getServiceLabel = ({
  intl,
  type,
  priceNetto,
  vatRate,
  shipmentType,
}: GetServiceLabel) => {
  const { label } = servicesObject(intl)[type];
  const brutto = formatPrice(calcBrutto(priceNetto, vatRate));

  switch (type) {
    case ServiceType.GRAPHIC_SERVICE:
      return intl.formatMessage(
        {
          defaultMessage: '{label} - koszt usługi graficznej to {brutto}',
        },
        {
          label,
          brutto,
        },
      );
    case ServiceType.ADDITIONAL:
      return intl.formatMessage(
        {
          defaultMessage:
            '{label} – koszt tłumaczenia ekspresowego to {brutto}',
        },
        { label, brutto },
      );
    case ServiceType.SHIPMENT:
      return intl.formatMessage(
        {
          defaultMessage: '{label} {shipmentType} - koszt wysyłki to {brutto}',
        },
        {
          label,
          shipmentType: shipmentType ?? '',
          brutto,
        },
      );
    case ServiceType.PREPARATORY:
      return intl.formatMessage(
        {
          defaultMessage: '{label} – koszt prac przygotowawczych to {brutto}',
        },
        {
          label,
          brutto,
        },
      );
    default:
      return '';
  }
};

export const generateEmailSnippetAdditionalServices = ({
  intl,
  from,
  services,
}: GenerateEmailSnippetAdditionalServices) => {
  if (from === 'preorder') {
    const activeServices = services.filter(({ isActive }) => isActive);

    return `<ul>${activeServices
      .map(
        ({ type, priceNetto, vatRate, preorderVariantServiceValues }) =>
          `<li>${getServiceLabel({
            intl,
            type,
            priceNetto,
            vatRate,
            shipmentType: getPreorderServiceInitialValue(
              preorderVariantServiceValues,
              ServiceAttributeNames.TYPE,
            ),
          })}</li>`,
      )
      .join('')}</ul>`;
  }

  return `<ul>${services
    .map(
      ({ type, priceNetto, vatRate, orderServiceValues }) =>
        `<li>${getServiceLabel({
          intl,
          type,
          priceNetto,
          vatRate,
          shipmentType: getOrderServiceInitialValue(
            orderServiceValues,
            ServiceAttributeNames.TYPE,
          ),
        })}</li>`,
    )
    .join('')}</ul>`;
};

const getFileListTranslationLabel = (
  intl: IntlShape,
  type: FileTranslationType,
) => {
  switch (type) {
    case FileTranslationType.SWORN:
      return intl.formatMessage({
        defaultMessage: 'tłumaczenia przysięgłego',
      });
    case FileTranslationType.CORRECTION:
      return intl.formatMessage({
        defaultMessage: 'korekty',
      });
    default:
      return intl.formatMessage({
        defaultMessage: 'tłumaczenia zwykłego',
      });
  }
};

export const generateEmailSnippetPreorderFileList = ({
  intl,
  vatRate,
  variantFiles,
  languages,
  withoutPrice = false,
}: GenerateEmailSnippetPreorderFileList) =>
  `<ul>${variantFiles
    .map(
      ({ file, type, preorderVariantLangs }) =>
        `<li>${file.filename} - ${preorderVariantLangs
          .map(({ langFrom, langTo, priceFinalNetto, discount }) => {
            const translationLabel = getFileListTranslationLabel(intl, type);
            const brutto = formatPrice(
              calcFinalBrutto({
                priceNetto: priceFinalNetto,
                discount,
                vatRate,
              }),
            );
            const values = {
              langFrom: getLanguageName(intl, languages, langFrom, true),
              langTo: getLanguageName(intl, languages, langTo),
              translationLabel,
              brutto,
            };

            return withoutPrice
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      'koszt {translationLabel} z języka {langFrom} na język {langTo}',
                  },
                  values,
                )
              : intl.formatMessage(
                  {
                    defaultMessage:
                      'koszt {translationLabel} z języka {langFrom} na język {langTo} to {brutto} brutto',
                  },
                  values,
                );
          })
          .join(', ')}</li>`,
    )
    .join('')}</ul>` || NO_DATA;

export const generateEmailSnippetOrderFileList = ({
  intl,
  vatRate,
  orderFiles,
  languages,
}: GenerateEmailSnippetOrderFileList) =>
  `<ul>${orderFiles
    .map(
      ({ file, type, orderFileLangs }) =>
        `<li>${file.filename} - ${orderFileLangs
          .map(({ langFrom, langTo, price }) =>
            intl.formatMessage(
              {
                defaultMessage:
                  'koszt {translationLabel} z języka {langFrom} na język {langTo} to {brutto} brutto',
              },
              {
                langFrom: getLanguageName(intl, languages, langFrom, true),
                langTo: getLanguageName(intl, languages, langTo),
                translationLabel: getFileListTranslationLabel(intl, type),
                brutto: formatPrice(calcBrutto(price, vatRate)),
              },
            ),
          )
          .join(', ')}</li>`,
    )
    .join('')}</ul>` || NO_DATA;

export const getEmailSnippetRealizationDate = (
  intl: IntlShape,
  timeRealization: number,
) => {
  if (timeRealization === 0) return NO_DATA;

  if (timeRealization >= 5)
    return intl.formatMessage(
      { defaultMessage: '{timeRealization} dni roboczych' },
      { timeRealization },
    );

  if (timeRealization >= 2)
    return intl.formatMessage(
      { defaultMessage: '{timeRealization} dni robocze' },
      { timeRealization },
    );

  return intl.formatMessage(
    { defaultMessage: '{timeRealization} dzień roboczy' },
    { timeRealization },
  );
};

const getTranslationFile = (
  fileId: number,
  translationsAndOrdersFiles: TranslationsAndOrdersFile[],
) => translationsAndOrdersFiles.find(({ id }) => id === fileId);

export const generateEmailSnippetTranslatorFileList = (
  intl: IntlShape,
  orderFiles: GetOrderTranslationFilesResponse['orderFiles'],
  translationsAndOrdersFiles: TranslationsAndOrdersFile[],
) =>
  orderFiles
    .map(({ type, orderFileLangs }) =>
      orderFileLangs
        .map(
          ({ orderTranslationFiles, langFrom, langTo, dateRealization }) =>
            `<ul>${orderTranslationFiles
              .map(({ fileId }) => {
                const file = getTranslationFile(
                  fileId,
                  translationsAndOrdersFiles,
                );
                const typeLabel = fileTranslationTypesObject(intl, true)[
                  type
                ].label.toLowerCase();
                const date = dayjs
                  .tz(dateRealization)
                  .format(DayjsFormat.DATE_AND_TIME);

                if (!file) return '';

                return `<li>
              ${intl.formatMessage(
                {
                  defaultMessage:
                    '{filename} - {type}, {langFrom} – {langTo}, termin realizacji: {date}',
                },
                {
                  type: typeLabel,
                  filename: file.filename,
                  langFrom,
                  langTo,
                  date,
                },
              )}
            </li>`;
              })
              .join('')}</ul>`,
        )
        .join(''),
    )
    .join('');

export const generateEmailSnippetPreorderVariantsNumber = (
  intl: IntlShape,
  variantsNumber: number,
) => {
  if (variantsNumber >= 5)
    return intl.formatMessage(
      { defaultMessage: '{variantsNumber} wariantów oferty' },
      { variantsNumber },
    );

  if (variantsNumber >= 2)
    return intl.formatMessage(
      { defaultMessage: '{variantsNumber} warianty oferty' },
      { variantsNumber },
    );

  return intl.formatMessage(
    { defaultMessage: '{variantsNumber} wariant oferty' },
    { variantsNumber },
  );
};

export const generateEmailSnippetHyperLink = (text: string, link: string) =>
  `<a href="${link}" rel="noopener noreferrer" target="_blank">${text}</a>`;
