/* eslint-disable react/jsx-props-no-spreading */
import type { GenerateTranslationsModalValues } from './config';
import type { HandleAddProps, ListItem } from '../../config';
import type { FC } from 'react';
import { Form } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import { formItems, initialValues } from './config';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (props: HandleAddProps) => void;
  listItems: ListItem[];
}

const { useForm, useWatch } = Form;

const GenerateTranslationsModal: FC<Props> = ({
  isOpen,
  onClose,
  onAdd,
  listItems,
}) => {
  const [form] = useForm<GenerateTranslationsModalValues>();
  const isCertifiedWatch = useWatch('isCertified', form);
  const langs = useMemo(
    () =>
      listItems
        .filter(({ isCertified }) => isCertified === isCertifiedWatch)
        .flatMap(({ langFrom, langTo }) => [langFrom, langTo]),
    [listItems, isCertifiedWatch],
  );

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleOnSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const items: HandleAddProps = values.langs.flatMap((lang) => {
          return [
            {
              langFrom: LanguageCode.POLAND,
              langTo: lang,
              isCertified: values.isCertified,
            },
            {
              langFrom: lang,
              langTo: LanguageCode.POLAND,
              isCertified: values.isCertified,
            },
          ];
        });

        onAdd(items);
        handleClose();
      })
      .catch((err) => err);
  };

  return (
    <Modal
      title={
        isCertifiedWatch ? (
          <FormattedMessage defaultMessage="Generuj tłumaczenia przysięgłe" />
        ) : (
          <FormattedMessage defaultMessage="Generuj tłumaczenia zwykłe" />
        )
      }
      subtitle={
        <FormattedMessage defaultMessage="Wygenerowane zostaną pary językowe z wykorzystaniem języka polskiego, np. po wybraniu języka EN będą to pary PL-EN, EN-PL" />
      }
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={handleClose}
      okText={<FormattedMessage defaultMessage="Generuj" />}
      disableFormStyles
      disableModalStyles
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        {formItems({ langs, form }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default GenerateTranslationsModal;
