import type { IntlShape } from 'react-intl';

export enum OrderStatus {
  NEW = 0,
  PARTIALLY_ASSIGNED = 1,
  ASSIGNED = 2,
  READY = 3,
  DONE = 4,
  COMPLAINT = 5,
  VERIFICATION = 6,
}

type OrderStatusOption = {
  value: OrderStatus;
  label: string;
};

export const orderStatuses = (intl: IntlShape): OrderStatusOption[] => [
  {
    value: OrderStatus.NEW,
    label: intl.formatMessage({ defaultMessage: 'Niezlecone' }),
  },
  {
    value: OrderStatus.PARTIALLY_ASSIGNED,
    label: intl.formatMessage({ defaultMessage: 'Częściowo zlecone' }),
  },
  {
    value: OrderStatus.ASSIGNED,
    label: intl.formatMessage({ defaultMessage: 'Zlecone' }),
  },
  {
    value: OrderStatus.READY,
    label: intl.formatMessage({ defaultMessage: 'Gotowe do wysłania' }),
  },
  {
    value: OrderStatus.DONE,
    label: intl.formatMessage({ defaultMessage: 'Zrealizowane' }),
  },
  {
    value: OrderStatus.COMPLAINT,
    label: intl.formatMessage({ defaultMessage: 'Reklamacja' }),
  },
  {
    value: OrderStatus.VERIFICATION,
    label: intl.formatMessage({ defaultMessage: 'Weryfikacja' }),
  },
];

export const orderStatusesObject = (intl: IntlShape) =>
  orderStatuses(intl).reduce((acc, { value, label }) => {
    acc[value] = {
      value,
      label,
    };

    return acc;
  }, {} as Record<OrderStatus, OrderStatusOption>);
