import type { PreorderSendValues } from './config';
import { Form, Row, notification } from 'antd';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PreorderStatus } from 'constants/preorder';
import { useSendPreorderNumberMutation } from 'ducks/preorders/service';
import { useAppDispatch } from 'ducks/store';
import {
  setPreorderIsSendMode,
  resetPreordersState,
} from 'ducks/view/preorders';
import PreorderCurrentStatus from 'views/Preorders/components/PreorderCurrentStatus';
import PreorderEmailTemplateEdtiorSection from 'views/Preorders/components/PreorderEmailTemplateEdtiorSection';
import PreorderNotesSection from 'views/Preorders/components/PreorderNotesSection';
import { Routes } from 'views/Preorders/Routing';
import VariantsSection from '../PreorderDetails/components/VariantsSection';
import ActionsSection from './components/ActionsSection';
import PreorderSendHeader from './components/PreorderSendHeader';
import SwitchesSection from './components/SwitchesSection';
import TemplateSettingsSection from './components/TemplateSettingsSection';
import { initialValues } from './config';
import { usePreorderSendServices } from './usePreorderSendServices';

const { useForm } = Form;

const PreorderSend = () => {
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm<PreorderSendValues>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { preorderData, refetch } = usePreorderSendServices();
  const [sendPreorderNumber] = useSendPreorderNumberMutation();

  useEffect(() => {
    if (!preorderData || preorderData?.number) return;

    sendPreorderNumber({ preorderId: preorderData?.id });
  }, [preorderData, sendPreorderNumber]);

  useEffect(() => {
    if (!preorderData) return;

    const { id, status } = preorderData;
    const inRealization = status === PreorderStatus.IN_REALIZATION;

    if (inRealization) {
      navigate(Routes.PREORDER_DETAILS.replace(':id', id.toString()));
    }
  }, [preorderData, navigate]);

  useEffect(() => {
    if (!preorderData) return;

    const {
      number,
      email,
      emailCc: cc,
      isCompany,
      client,
      isSms,
    } = preorderData;
    const isPresentation = isCompany && !!client;

    form.setFieldsValue({
      email,
      cc,
      isPresentation,
      isSms: !!isSms,
      subject: intl.formatMessage(
        { defaultMessage: 'Wycena tłumaczenia nr {number}' },
        { number },
      ),
    });
  }, [preorderData, form, intl]);

  useEffect(() => {
    dispatch(setPreorderIsSendMode(true));

    return () => {
      dispatch(resetPreordersState());
    };
  }, [dispatch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="layout-content mb-24">
      {contextHolder}
      <PreorderCurrentStatus>
        <PreorderSendHeader />
        <Form form={form} initialValues={initialValues} layout="vertical">
          <Row gutter={[24, 24]}>
            <PreorderNotesSection notify={notify} colProps={{ lg: 24 }} />
            <Row
              className="width-100"
              gutter={[24, 24]}
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              <TemplateSettingsSection form={form} />
              <SwitchesSection />
            </Row>
            <PreorderEmailTemplateEdtiorSection form={form} />
            <VariantsSection />
            <ActionsSection form={form} notify={notify} />
          </Row>
        </Form>
      </PreorderCurrentStatus>
    </div>
  );
};

export default PreorderSend;
