import type { UploadFile } from 'antd';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { ListItemRecord } from 'components/ui/molecules/List';
import type { ResponseFormItem } from 'ducks/settings/types';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSendPresentationMutation } from 'ducks/settings/service';
import { FormsIds, getListItems } from '../config';
import SettingsForm from '../SettingsForm';

const { Title } = Typography;

export type FileFormValues = {
  presentation: {
    file: UploadFile;
    fileList: UploadFile[];
  };
};

type Props = {
  notify: NotificationInstance;
  queryFormData: ResponseFormItem[];
  isLoading: boolean;
};

const PresentationForm: React.FC<Props> = ({
  notify,
  queryFormData,
  isLoading,
}) => {
  const [sendForm, { isLoading: isSendingLoading, isSuccess, isError }] =
    useSendPresentationMutation();

  const composeDataAndSend = async (values: FileFormValues) => {
    await sendForm({
      sectionId: FormsIds.PRESENTATION_FORM,
      file: values.presentation.file.originFileObj as File,
      key: 'presentation',
    });
  };

  return (
    <SettingsForm
      notify={notify}
      formElements={queryFormData}
      formTitle={<FormattedMessage defaultMessage="Edytuj prezentację PDF" />}
      cardTitle={
        <Title level={4} style={{ margin: 0 }}>
          <FormattedMessage defaultMessage="Prezentacja PDF" />
        </Title>
      }
      listItems={getListItems(queryFormData) as ListItemRecord[]}
      isLoadingList={isLoading}
      isLoadingSend={isSendingLoading}
      isSuccessSend={isSuccess}
      isErrorSend={isError}
      onSubmit={composeDataAndSend}
    />
  );
};

export default PresentationForm;
