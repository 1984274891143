import type { OrderTranslationRow } from './config';
import type { OrderFilesAndSericesValues } from '../../config';
import type { AddLanguagePairModalValues } from 'components/ui/organisms/Modals/AddLanguagePairModal';
import type { UpdateOrderFilesPayload } from 'ducks/view/orders';
import type { FC } from 'react';
import { Form, Space, notification } from 'antd';
import { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import Table from 'components/ui/molecules/Table';
import AddLanguagePairModal from 'components/ui/organisms/Modals/AddLanguagePairModal';
import { FileTranslationType } from 'constants/fileTranslation';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  addOrderFileLangPair,
  setOrderAssignAddLanguagesModal,
  updateOrderFiles,
} from 'ducks/view/orders';
import { useOrderAssignServices } from 'views/Orders/views/OrderAssign';
import OrderTranslationsTableTitleExtra from './components/OrderTranslationsTableTitleExtra';
import { orderTranslationColumns } from './config';

const { useForm } = Form;

type Props = {
  isLoading: boolean;
  showAssignAllFiles?: boolean;
};

const OrderTranslationsTable: FC<Props> = ({
  isLoading,
  showAssignAllFiles = false,
}) => {
  const [notify, contextHolder] = notification.useNotification();
  const { orderData } = useOrderAssignServices();
  const { isEditable, orderFiles, addLanguagesModal } = useAppSelector(
    (state) => ({
      isEditable: state.orders.isEditable,
      orderFiles: state.orders.orderFiles,
      addLanguagesModal: state.orders.assign.addLanguagesModal,
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const [form] = useForm<OrderFilesAndSericesValues>();

  const handleValuesChange = (field: UpdateOrderFilesPayload['field']) =>
    dispatch(updateOrderFiles({ field }));

  const modifiedData = useMemo(
    (): OrderTranslationRow[] =>
      orderFiles?.map((order) => ({
        ...order,
        key: order.id,
      })),
    [orderFiles],
  );

  const [normalTranslations, swornTranslations, correctionTranslations] =
    useMemo(
      () => [
        modifiedData.filter(({ type }) => type === FileTranslationType.NORMAL),
        modifiedData.filter(({ type }) => type === FileTranslationType.SWORN),
        modifiedData.filter(
          ({ type }) => type === FileTranslationType.CORRECTION,
        ),
      ],
      [modifiedData],
    );

  const handleCloseAddLanguageModal = () => {
    dispatch(
      setOrderAssignAddLanguagesModal({
        isOpen: false,
        orderFileId: null,
      }),
    );
  };

  const handleAddLanguagePair = (values: AddLanguagePairModalValues) => {
    const { orderFileId } = addLanguagesModal;

    if (orderFileId) {
      dispatch(
        addOrderFileLangPair({
          ...values,
          dateTranslationRealizationDefault:
            orderData?.dateTranslationRealizationDefault,
          orderFileId,
          onError: () => {
            notify.error({
              message: (
                <FormattedMessage defaultMessage="Ta para językowa już istnieje." />
              ),
            });
          },
        }),
      );
    }
  };

  useEffect(() => {
    orderFiles.forEach((orderFile) => {
      form.setFieldValue([orderFile.id], orderFile);

      orderFile.orderFileLangs.forEach((orderFileLang) => {
        form.setFieldValue([orderFile.id, orderFileLang.id], orderFileLang);
      });
    });
  }, [form, orderFiles]);

  return (
    <Form form={form} onValuesChange={handleValuesChange}>
      {contextHolder}
      <AddLanguagePairModal
        isOpen={addLanguagesModal.isOpen}
        onAdd={handleAddLanguagePair}
        onClose={handleCloseAddLanguageModal}
      />
      <Space className="width-100" direction="vertical" size="large">
        {!!normalTranslations.length && (
          <Table
            hidePagination
            cardTitle={<FormattedMessage defaultMessage="Tłumaczenie zwykłe" />}
            columns={orderTranslationColumns({
              isEditable,
            })}
            data={normalTranslations}
            isLoading={isLoading}
            cardTitleExtra={
              <OrderTranslationsTableTitleExtra
                type={normalTranslations[0].type}
                showAssignAllFiles={showAssignAllFiles}
              />
            }
          />
        )}
        {!!swornTranslations.length && (
          <Table
            hidePagination
            cardTitle={
              <FormattedMessage defaultMessage="Tłumaczenie przysięgłe" />
            }
            columns={orderTranslationColumns({
              isEditable,
            })}
            data={swornTranslations}
            isLoading={isLoading}
            cardTitleExtra={
              <OrderTranslationsTableTitleExtra
                type={swornTranslations[0].type}
                showAssignAllFiles={showAssignAllFiles}
              />
            }
          />
        )}
        {!!correctionTranslations.length && (
          <Table
            hidePagination
            cardTitle={<FormattedMessage defaultMessage="Korekta" />}
            columns={orderTranslationColumns({
              isEditable,
            })}
            data={correctionTranslations}
            isLoading={isLoading}
            cardTitleExtra={
              <OrderTranslationsTableTitleExtra
                type={correctionTranslations[0].type}
                showAssignAllFiles={showAssignAllFiles}
              />
            }
          />
        )}
      </Space>
    </Form>
  );
};

export default OrderTranslationsTable;
