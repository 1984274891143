import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { InvoiceDataItem } from 'ducks/invoices/types';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';

export type InvoiceDataItemRow = TableRow & InvoiceDataItem;

export const invoiceDataItemColumns: ColumnsType<InvoiceDataItemRow> = [
  {
    key: 'name',
    dataIndex: 'name',
    title: <FormattedMessage defaultMessage="Nazwa" />,
  },
  {
    key: 'unit',
    dataIndex: 'unit',
    title: <FormattedMessage defaultMessage="Jednostka" />,
  },
  {
    key: 'amount',
    dataIndex: 'amount',
    title: <FormattedMessage defaultMessage="Ilość" />,
  },
  {
    key: 'netto',
    dataIndex: 'netto',
    title: <FormattedMessage defaultMessage="Cena netto" />,
    render: (_, { netto }) => <CurrencyFormatter value={netto} />,
  },
  {
    key: 'discount',
    dataIndex: 'discount',
    title: <FormattedMessage defaultMessage="Rabat" />,
  },
  {
    key: 'vatRate',
    dataIndex: 'vatRate',
    title: <FormattedMessage defaultMessage="Stawka VAT" />,
    render: (_, { vatRate }) => <span>{vatRate}%</span>,
  },
  {
    key: 'priceNetto',
    dataIndex: 'priceNetto',
    title: <FormattedMessage defaultMessage="Wartość netto" />,
    render: (_, { priceNetto }) => <CurrencyFormatter value={priceNetto} />,
  },
  {
    key: 'priceBrutto',
    dataIndex: 'priceBrutto',
    title: <FormattedMessage defaultMessage="Wartość brutto" />,
    render: (_, { priceBrutto }) => <CurrencyFormatter value={priceBrutto} />,
  },
];
