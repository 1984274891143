import type { FormListItemRecord } from 'components/ui/molecules/FormList';
import type {
  TranslatorQualification,
  TranslatorQualificationFile,
  TranslatorQualificationItem,
} from 'ducks/translators/types';
import type { Key, ReactElement } from 'react';
import { Button, Popconfirm } from 'antd';
import { FormattedMessage } from 'react-intl';
import TruncateText from 'components/ui/atoms/TruncateText';
import Upload from 'components/ui/molecules/Upload';
import { NO_DATA } from 'constants/strings';
import { TrashIcon, UploadIcon } from 'icons';
import styles from './styles.module.scss';
import { getCorrectCellItem } from './utils';

export type ListItem = Omit<TranslatorQualification, 'items'> & {
  items: (Omit<TranslatorQualificationItem, 'id'> & {
    id: string | number;
    newFile?: File | null;
    isNew?: boolean;
  })[];
};

export type ListItemValues = ListItem['items'][number];

type FormLists = {
  key: Key;
  sectionId: number;
  title: ReactElement | string;
  items: FormListItemRecord<ListItemValues>[];
};

type Props = {
  handleDelete: (id: number | string, isNew: boolean) => void;
  handleAddFileToItem: (id: string, file: File) => void;
  triggerGetFile: (file: TranslatorQualificationFile) => Promise<void>;
};

export const initialValues: ListItemValues = {
  id: '',
  field1: '',
  field2: '',
  field3: '',
  field4: '',
  newFile: null,
  isNew: true,
};

const sharedFormListItems = ({
  handleDelete,
  handleAddFileToItem,
  triggerGetFile,
}: Props): FormLists['items'] => [
  {
    key: 9,
    title: <FormattedMessage defaultMessage="Plik" />,
    cellItems: ({ files, newFile, isNew }) => {
      const file = files?.[0];

      return [
        {
          // no button with request to new added file
          render: !isNew ? (
            <Button
              type="link"
              onClick={() =>
                file &&
                triggerGetFile({
                  id: file.id,
                  object: file.object,
                  filename: file.filename,
                })
              }
            >
              <TruncateText maxLength={12} style={{ whiteSpace: 'nowrap' }}>
                {newFile?.name ?? file?.filename ?? NO_DATA}
              </TruncateText>
            </Button>
          ) : (
            <TruncateText maxLength={12} style={{ whiteSpace: 'nowrap' }}>
              {newFile?.name ?? file?.filename ?? NO_DATA}
            </TruncateText>
          ),
        },
      ];
    },
  },
  {
    key: 10,
    title: '',
    width: 50,
    cellItems: ({ id, newFile, isNew }) => [
      !!isNew && !newFile
        ? {
            render: (
              <Upload
                onChange={(info) =>
                  handleAddFileToItem(
                    id as string,
                    info.file.originFileObj as File,
                  )
                }
                fileList={[]}
              >
                <Button
                  size="small"
                  type="link"
                  icon={<UploadIcon />}
                  className={styles.deleteButton}
                >
                  <FormattedMessage defaultMessage="Dodaj plik" />
                </Button>
              </Upload>
            ),
          }
        : {},
      {
        render: (
          <Popconfirm
            title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
            okText={<FormattedMessage defaultMessage="Tak" />}
            cancelText={<FormattedMessage defaultMessage="Nie" />}
            onConfirm={() => handleDelete(id, !!isNew)}
          >
            <Button
              size="small"
              type="link"
              icon={<TrashIcon />}
              danger
              className={styles.deleteButton}
            >
              <FormattedMessage defaultMessage="Usuń" />
            </Button>
          </Popconfirm>
        ),
      },
    ],
  },
];

export const formLists = ({
  handleDelete,
  handleAddFileToItem,
  triggerGetFile,
}: Props): FormLists[] => [
  {
    key: 1,
    sectionId: 1,
    title: <FormattedMessage defaultMessage="Wykształcenie" />,
    items: [
      {
        key: 1,
        title: <FormattedMessage defaultMessage="Szkoła" />,
        cellItems: ({ isNew, field1 }) =>
          getCorrectCellItem(!!isNew, 'field1', field1),
      },
      {
        key: 2,
        title: <FormattedMessage defaultMessage="Kierunek" />,
        cellItems: ({ isNew, field2 }) =>
          getCorrectCellItem(!!isNew, 'field2', field2),
      },
      {
        key: 3,
        title: <FormattedMessage defaultMessage="Rok ukończenia" />,
        cellItems: ({ isNew, field3 }) =>
          getCorrectCellItem(!!isNew, 'field3', field3),
      },
      ...sharedFormListItems({
        handleDelete,
        handleAddFileToItem,
        triggerGetFile,
      }),
    ],
  },
  {
    key: 2,
    sectionId: 2,
    title: <FormattedMessage defaultMessage="Certyfikaty" />,
    items: [
      {
        key: 1,
        title: <FormattedMessage defaultMessage="Nazwa" />,
        cellItems: ({ isNew, field1 }) =>
          getCorrectCellItem(!!isNew, 'field1', field1),
      },
      {
        key: 2,
        title: <FormattedMessage defaultMessage="Ocena" />,
        cellItems: ({ isNew, field2 }) =>
          getCorrectCellItem(!!isNew, 'field2', field2),
      },
      {
        key: 3,
        title: <FormattedMessage defaultMessage="Rok wydania" />,
        cellItems: ({ isNew, field3 }) =>
          getCorrectCellItem(!!isNew, 'field3', field3),
      },
      ...sharedFormListItems({
        handleDelete,
        handleAddFileToItem,
        triggerGetFile,
      }),
    ],
  },
  {
    key: 3,
    sectionId: 3,
    title: <FormattedMessage defaultMessage="Referencje" />,
    items: [
      {
        key: 1,
        title: (
          <FormattedMessage
            defaultMessage="Imię i nazwisko
        "
          />
        ),
        cellItems: ({ isNew, field1 }) =>
          getCorrectCellItem(!!isNew, 'field1', field1),
      },
      {
        key: 2,
        title: <FormattedMessage defaultMessage="Firma" />,
        cellItems: ({ isNew, field2 }) =>
          getCorrectCellItem(!!isNew, 'field2', field2),
      },
      {
        key: 3,
        title: <FormattedMessage defaultMessage="Telefon" />,
        cellItems: ({ isNew, field3 }) =>
          getCorrectCellItem(!!isNew, 'field3', field3),
      },
      {
        key: 4,
        title: <FormattedMessage defaultMessage="E-mail" />,
        cellItems: ({ isNew, field4 }) =>
          getCorrectCellItem(!!isNew, 'field4', field4),
      },
      ...sharedFormListItems({
        handleDelete,
        handleAddFileToItem,
        triggerGetFile,
      }),
    ],
  },
  {
    key: 4,
    sectionId: 4,
    title: <FormattedMessage defaultMessage="Projekty" />,
    items: [
      {
        key: 1,
        title: <FormattedMessage defaultMessage="Temat" />,
        cellItems: ({ isNew, field1 }) =>
          getCorrectCellItem(!!isNew, 'field1', field1),
      },
      {
        key: 2,
        title: <FormattedMessage defaultMessage="Zleceniodawca" />,
        cellItems: ({ isNew, field2 }) =>
          getCorrectCellItem(!!isNew, 'field2', field2),
      },
      {
        key: 3,
        title: <FormattedMessage defaultMessage="Rok" />,
        cellItems: ({ isNew, field3 }) =>
          getCorrectCellItem(!!isNew, 'field3', field3),
      },
      ...sharedFormListItems({
        handleDelete,
        handleAddFileToItem,
        triggerGetFile,
      }),
    ],
  },
  {
    key: 5,
    sectionId: 5,
    title: <FormattedMessage defaultMessage="Inne" />,
    items: [
      {
        key: 1,
        title: <FormattedMessage defaultMessage="Nazwa" />,
        cellItems: ({ isNew, field1 }) =>
          getCorrectCellItem(!!isNew, 'field1', field1),
      },
      ...sharedFormListItems({
        handleDelete,
        handleAddFileToItem,
        triggerGetFile,
      }),
    ],
  },
];
