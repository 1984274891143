export enum LogsTag {
  Logs = 'Logs',
}

export type LogRecord = {
  id: number;
  firstName: string;
  lastName: string;
  action: string;
  ip: string;
  date: string;
};

export type GetLogsResponse = {
  currentPage: number;
  data: LogRecord[];
};
