import type { DownloadInvoiceRequest, DownloadInvoiceResponse } from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';

export enum Endpoint {
  DownloadInvoice = '/public/invoices/download/:hash',
}

export const publicApi = createApi({
  reducerPath: 'publicApi',
  baseQuery: baseQuery(),
  tagTypes: [],
  endpoints: (build) => ({
    downloadInvoice: build.query<
      DownloadInvoiceResponse,
      DownloadInvoiceRequest
    >({
      query: ({ hash }) => ({
        url: Endpoint.DownloadInvoice.replace(':hash', hash),
        method: 'GET',
        responseType: 'blob',
      }),
    }),
  }),
});

export const { useLazyDownloadInvoiceQuery } = publicApi;
