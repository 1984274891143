/* eslint-disable react-hooks/exhaustive-deps */
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { Select } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useGetPricesQuery } from 'ducks/prices/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  setPreorderSelectedPrice,
  setPreorderSelectedPriceId,
} from 'ducks/view/preorders';
import { usePreorderDetailsServices } from '../../usePreorderDetailsServices';

interface Props {
  notify: NotificationInstance;
}

const PricesSection: FC<Props> = ({ notify }) => {
  const selectedPriceId = useAppSelector(
    (state) => state.preorders.selectedPriceId,
  );
  const dispatch = useAppDispatch();
  const { isPreorderFetching } = usePreorderDetailsServices();
  const { data = [], isLoading: isPricesLoading } = useGetPricesQuery();
  const isLoading = isPricesLoading || isPreorderFetching;

  const handleChangeSelectedPrice = (value: number | null) => {
    const price = data.find(({ id }) => id === value);

    if (price) {
      dispatch(
        setPreorderSelectedPrice({
          price,
          onError: ({ missingLangs }) => {
            const langFrom = missingLangs[0][0];
            const langTo = missingLangs[0][1];

            notify.error({
              message: (
                <FormattedMessage
                  defaultMessage="Brak stawek dla pary językowej {langFrom} - {langTo}. Zmiana cennika niemożliwa."
                  values={{ langFrom, langTo }}
                />
              ),
            });
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (data.length) {
      if (data.length === 1) {
        dispatch(setPreorderSelectedPriceId(data[0].id));
      }
      handleChangeSelectedPrice(selectedPriceId);
    }
  }, [selectedPriceId, data]);

  return (
    <CardCommon
      data-testid="pricesSection"
      title={<FormattedMessage defaultMessage="Cennik dla klienta" />}
      isLoading={isLoading}
      customSkeleton
    >
      <Select
        options={data.map(({ id, name }) => ({
          value: id,
          label: name,
        }))}
        value={data.length ? selectedPriceId : null}
        onChange={(value) => dispatch(setPreorderSelectedPriceId(value))}
        className="width-100"
        placeholder={<FormattedMessage defaultMessage="Wybierz cennik" />}
        loading={isLoading}
        disabled={isLoading}
      />
    </CardCommon>
  );
};

export default PricesSection;
