import type { InvoiceAddValues } from '../../config';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { GetInvoicesDataResponse } from 'ducks/invoices/types';
import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { DatePicker, Select } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { invoiceDueDatePayments } from 'constants/invoice';
import { paymentTypes } from 'constants/payment';
import { PaymentType } from 'ducks/payments/types';

export const initialInvoiceSettingsValues: InvoiceAddValues = {
  createdAt: dayjs(),
  dateSale: dayjs(),
  dateDue: null,
  paymentMethod: PaymentType.ONLINE_TRANSFER,
  comment: '',
};

type Props = {
  intl: IntlShape;
} & Pick<
  GetInvoicesDataResponse,
  'vatRate' | 'currency' | 'paid' | 'sumPriceBrutto'
>;

export const invoiceSettingsformItems = ({
  intl,
  vatRate,
  currency,
  paid,
  sumPriceBrutto,
}: Props): FormItemRecord<InvoiceAddValues>[] => {
  const toPay = sumPriceBrutto - paid;

  return [
    {
      key: 'createdAt',
      label: <FormattedMessage defaultMessage="Data wystawienia:" />,
      name: 'createdAt',
      component: (
        <DatePicker
          data-testid="createdAt"
          locale={locale}
          allowClear={false}
        />
      ),
      rules: [
        {
          required: true,
          message: (
            <FormattedMessage defaultMessage="Wybierz datę wystawienia" />
          ),
        },
      ],
    },
    {
      key: 'dateSale',
      label: <FormattedMessage defaultMessage="Data sprzedaży:" />,
      name: 'dateSale',
      component: (
        <DatePicker data-testid="dateSale" locale={locale} allowClear={false} />
      ),
      rules: [
        {
          required: true,
          message: <FormattedMessage defaultMessage="Wybierz datę sprzedaży" />,
        },
      ],
    },
    {
      key: 'vatRate',
      label: <FormattedMessage defaultMessage="Stawka VAT:" />,
      component: <strong data-testid="vatRate">{vatRate}%</strong>,
    },
    {
      key: 'currency',
      label: <FormattedMessage defaultMessage="Waluta:" />,
      component: <strong data-testid="currency">{currency}</strong>,
    },
    {
      key: 'paid',
      label: <FormattedMessage defaultMessage="Zapłacono:" />,
      component: (
        <strong data-testid="paid">
          <CurrencyFormatter value={paid} currency={currency} />
        </strong>
      ),
    },
    {
      key: 'paymentMethod',
      label: <FormattedMessage defaultMessage="Metoda płatności:" />,
      name: 'paymentMethod',
      component: (
        <Select data-testid="paymentMethod" options={paymentTypes(intl)} />
      ),
      rules: [
        {
          required: true,
          message: (
            <FormattedMessage defaultMessage="Wybierz metodę płatności" />
          ),
        },
      ],
    },
    {
      key: 'dateDue',
      label: <FormattedMessage defaultMessage="Termin płatności:" />,
      name: 'dateDue',
      component: (
        <Select
          data-testid="dueDatePayment"
          options={invoiceDueDatePayments(intl)}
        />
      ),
      rules: [
        {
          required: true,
          message: (
            <FormattedMessage defaultMessage="Wybierz termin płatności" />
          ),
        },
      ],
    },
    {
      key: 'toPay',
      label: <FormattedMessage defaultMessage="Do zapłaty:" />,
      component: (
        <strong data-testid="toPay">
          <CurrencyFormatter value={toPay} currency={currency} />
        </strong>
      ),
    },
  ];
};
