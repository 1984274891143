import type { UploadOnChangeParam } from 'components/ui/molecules/Upload';
import { notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import CardCommon from 'components/ui/molecules/CardCommon';
import Upload from 'components/ui/molecules/Upload';
import { financesImportFileTypes } from 'constants/fileTypes';
import { useParsePaymentFileMutation } from 'ducks/payments/service';
import { useAppDispatch } from 'ducks/store';
import { setFinancesParsedPaymentFile } from 'ducks/view/finances';
import { UploadIcon } from 'icons';
import { filePicker } from 'utilities/filePicker';
import { getFinancesImportFileTypesString } from './utils';

const UploadPaymentFile = () => {
  const [notify, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const [parsePaymentFile, { isLoading }] = useParsePaymentFileMutation();

  const handleUpload = async (files: UploadOnChangeParam) => {
    if (isLoading) return;

    const pickedFile = filePicker(files, {
      multiple: false,
      fileTypes: financesImportFileTypes,
      onError: () => {
        notify.error({
          message: (
            <FormattedMessage
              defaultMessage="Wybierz plik {fileType}"
              values={{
                fileType: <strong>{getFinancesImportFileTypesString()}</strong>,
              }}
            />
          ),
        });
      },
    })[0];

    if (!pickedFile) return;

    try {
      const response = await parsePaymentFile({
        file: pickedFile as File,
      }).unwrap();

      dispatch(setFinancesParsedPaymentFile(response));

      notify.success({
        message: <FormattedMessage defaultMessage="Poprawnie wgrano plik." />,
      });
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd poczas wgrywania pliku." />
        ),
      });
    }
  };

  return (
    <CardCommon
      data-testid="uploadPaymentFile"
      title={<FormattedMessage defaultMessage="Wgraj plik wyciągu bankowego" />}
    >
      {contextHolder}
      <Upload
        onChange={handleUpload}
        multiple={false}
        accept={financesImportFileTypes.toString()}
        disabled={isLoading}
      >
        <Button loading={isLoading} icon={<UploadIcon />}>
          <FormattedMessage defaultMessage="Wgraj" />
        </Button>
      </Upload>
    </CardCommon>
  );
};

export default UploadPaymentFile;
