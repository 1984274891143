import type { UpdatePreorderValues } from '../../config';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Preorder } from 'ducks/preorders/types';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CopyToClipboard from 'components/ui/atoms/CopyToClipboard';
import TruncateText from 'components/ui/atoms/TruncateText';
import { NO_DATA } from 'constants/strings';
import { validatePhoneNumberRule } from 'utilities/antdFormRules';

type Props = {
  data: Preorder;
  isEditable: boolean;
};

export const formItems = ({
  data,
  isEditable,
}: Props): FormItemRecord<UpdatePreorderValues>[] => {
  const { contactPerson, email, phone, isSms, isCompany } = data;

  return [
    {
      key: 'contactPerson',
      label: <FormattedMessage defaultMessage="Nazwa:" />,
      name: 'contactPerson',
      component: !isEditable && (
        <TruncateText data-testid="contactPerson" strong>
          {contactPerson ?? NO_DATA}
        </TruncateText>
      ),
      rules: [
        {
          required: !isCompany,
          message: <FormattedMessage defaultMessage="Podaj osobę kontaktową" />,
        },
      ],
    },
    {
      key: 'email',
      label: <FormattedMessage defaultMessage="E-mail:" />,
      name: 'email',
      component: !isEditable && (
        <CopyToClipboard
          data-testid="email"
          text={email}
          truncate
          truncateSize="medium"
          truncateMaxLength={16}
        />
      ),
      rules: [
        {
          required: true,
          message: <FormattedMessage defaultMessage="Podaj email" />,
        },
        {
          type: 'email',
          message: (
            <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
          ),
        },
      ],
    },
    {
      key: 'phone',
      label: <FormattedMessage defaultMessage="Telefon:" />,
      name: 'phone',
      component:
        !isEditable &&
        (phone ? (
          <CopyToClipboard
            data-testid="phone"
            text={phone}
            truncate
            truncateSize="medium"
          />
        ) : (
          <span data-testid="phone">{NO_DATA}</span>
        )),
      rules: [validatePhoneNumberRule()],
    },
    {
      key: 'isSms',
      label: <FormattedMessage defaultMessage="SMS:" />,
      name: 'isSms',
      component: isEditable ? (
        <Checkbox data-testid="isSmsChexkbox" />
      ) : (
        <CircleCheckbox data-testid="isSms" value={isSms} />
      ),
      additionalItemProps: { valuePropName: 'checked' },
    },
  ];
};
