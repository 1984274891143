import type { PreorderRealizationValues } from './config';
import { Col, Form, Row, Space, notification } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Empty from 'components/ui/atoms/Empty';
import { PreorderStatus } from 'constants/preorder';
import { useAppDispatch } from 'ducks/store';
import { resetPreordersState } from 'ducks/view/preorders';
import PreorderCurrentStatus from 'views/Preorders/components/PreorderCurrentStatus';
import PreorderEmailTemplateEdtiorSection from 'views/Preorders/components/PreorderEmailTemplateEdtiorSection';
import { Routes } from 'views/Preorders/Routing';
import ActionsSection from './components/ActionsSection';
import EmailSettingsSection from './components/EmailSettingsSection';
import PreorderRealizationHeader from './components/PreorderRealizationHeader';
import SummarySection from './components/SummarySection';
import VariantsAndAttachmentSection from './components/VariantsAndAttachmentSection';
import { initialValues } from './config';
import usePreorderRealizationServices from './usePreorderRealizationServices';

const { useForm } = Form;

const PreorderRealization = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm<PreorderRealizationValues>();
  const dispatch = useAppDispatch();
  const { preorderData, isSelectedPreorderVariantsEmpty, refetch } =
    usePreorderRealizationServices();

  useEffect(() => {
    if (!preorderData) return;

    const { id, status } = preorderData;
    const inRealization = status === PreorderStatus.IN_REALIZATION;

    if (inRealization) {
      navigate(Routes.PREORDER_DETAILS.replace(':id', id.toString()));
    }
  }, [preorderData, navigate]);

  useEffect(() => {
    if (!preorderData) return;

    const { email, emailCc: cc, number, status } = preorderData;

    const subject =
      status === PreorderStatus.SENT
        ? intl.formatMessage(
            {
              defaultMessage:
                'Tłumaczenie nr {number} zostało przyjęte do realizacji',
            },
            { number },
          )
        : '';

    form.setFieldsValue({
      email,
      cc,
      subject,
    });
  }, [form, preorderData, intl]);

  useEffect(() => {
    return () => {
      dispatch(resetPreordersState());
    };
  }, [dispatch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="layout-content mb-24">
      {contextHolder}
      <PreorderCurrentStatus>
        <PreorderRealizationHeader />
        {isSelectedPreorderVariantsEmpty ? (
          <Empty
            description={
              <FormattedMessage defaultMessage="Brak danych o wariancie" />
            }
          />
        ) : (
          <Form form={form} initialValues={initialValues} layout="vertical">
            <Space direction="vertical" size="large" className="width-100">
              <Row gutter={[24, 24]}>
                <Row
                  className="width-100"
                  gutter={[24, 24]}
                  style={{ marginLeft: 0, marginRight: 0 }}
                >
                  <EmailSettingsSection />
                  <Col xs={24} lg={10}>
                    <Row className="h-full" gutter={[24, 24]}>
                      <VariantsAndAttachmentSection />
                      <SummarySection />
                    </Row>
                  </Col>
                </Row>
                <PreorderEmailTemplateEdtiorSection form={form} />
                <ActionsSection form={form} notify={notify} />
              </Row>
            </Space>
          </Form>
        )}
      </PreorderCurrentStatus>
    </div>
  );
};

export default PreorderRealization;
