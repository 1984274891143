export const MAX_NIP_LENGTH = 10;
export const MAX_INTERNATIONAL_NIP_LENGTH = 12;
export const MAX_REGON_LENGTH = 9;
export const MAX_PESEL_LENGTH = 11;

export const MIN_INVOICE_DUE_DATE = 0;
export const MAX_INVOICE_DUE_DATE = 60;

export const DEFAULT_VAT_NUMBER = 23;
export const MAX_VAT_NUMBER = 30;

export const MIN_REPETITION_VALUE = 0;
export const MAX_REPETITION_VALUE = 100;

export const MAX_DISCOUNT_VALUE = 100;

export const MIN_TIME_REALIZATION_VALUE = 1;
export const MAX_TIME_REALIZATION_VALUE = 1000;
