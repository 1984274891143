import type { AllFilesModalValues } from './config';
import type { AddPreorderVariantLangToAllFilesPayload } from 'ducks/view/preorders';
import type { FC } from 'react';
import { Form, notification } from 'antd';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { fileTranslationTypesObject } from 'constants/fileTranslation';
import { useAppDispatch } from 'ducks/store';
import { addPreorderVariantLangToAllFiles } from 'ducks/view/preorders';
import { formItems, initialValues } from './config';

const { useForm, useWatch } = Form;

type AllFilesOnError = NonNullable<
  AddPreorderVariantLangToAllFilesPayload['onError']
>;

type Props = {
  variantId: string | number | null;
  onClose: () => void;
};

const AllFilesModal: FC<Props> = ({ variantId, onClose }) => {
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const [form] = useForm<AllFilesModalValues>();
  const isOpen = !!variantId;
  const type = useWatch('type', form);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleOnError = useCallback<AllFilesOnError>(
    ({ isEmpty, isLackOfPrice }) => {
      if (isEmpty) {
        const typeLabel = fileTranslationTypesObject(intl)[type].label;

        notify.error({
          message: (
            <FormattedMessage
              defaultMessage="Brak pliku do tłumaczenia {type}."
              values={{
                type: <strong>{typeLabel}</strong>,
              }}
            />
          ),
        });

        return;
      }

      if (isLackOfPrice) {
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Brak cennika dla wybranej pary językowej." />
          ),
        });

        return;
      }

      notify.error({
        message: <FormattedMessage defaultMessage="Usługa niemożliwa." />,
      });
    },
    [type, notify, intl],
  );

  const handleSubmit = () => {
    if (!variantId) return;

    form.validateFields().then((values) => {
      dispatch(
        addPreorderVariantLangToAllFiles({
          ...initialValues,
          ...values,
          variantId,
          onError: handleOnError,
        }),
      );
      handleClose();
    });
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Wyceń wszystkie pliki" />}
      open={isOpen}
      onOk={handleSubmit}
      onCancel={handleClose}
      disableModalStyles
      disableFormStyles
    >
      {contextHolder}
      <Form form={form} initialValues={initialValues} layout="vertical">
        {formItems({ intl, type }).map(({ key, ...rest }) => (
          <FormItem key={key} {...rest} />
        ))}
      </Form>
    </Modal>
  );
};

export default AllFilesModal;
