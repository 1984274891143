import type { ListItemRecord } from 'components/ui/molecules/List';
import type { Order } from 'ducks/orders/types';
import type { OrdersSliceState } from 'ducks/view/orders';
import { FormattedMessage } from 'react-intl';
import { NO_DATA } from 'constants/strings';

interface Props {
  results: OrdersSliceState['assign']['results'];
  data: Order;
}

export const skeletonListItems: ListItemRecord[] = [
  {
    label: '',
    skeletonRows: 1,
  },
  {
    label: '',
    skeletonRows: 1,
  },
  {
    label: '',
    skeletonRows: 1,
  },
];

export const listItems = ({ results }: Props): ListItemRecord[] => {
  return [
    {
      label: <FormattedMessage defaultMessage="Średnia MTQE:" />,
      skeletonRows: 1,
      component: <span>{results ? `${results.mtqe}%` : NO_DATA}</span>,
    },
    {
      label: <FormattedMessage defaultMessage="Liczba stron do PE:" />,
      skeletonRows: 1,
      component: <span>{results?.pagesForPostediting ?? NO_DATA}</span>,
    },
    {
      label: <FormattedMessage defaultMessage="Stosunek MT:" />,
      skeletonRows: 1,
      component: <span>{results ? `${results.mtRatio}%` : NO_DATA}</span>,
    },
  ];
};
