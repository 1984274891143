import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Order, OrderInvoice } from 'ducks/orders/types';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import DownloadFvButton from 'components/ui/molecules/DownloadFvButton';
import { DayjsFormat } from 'constants/dayjsFormats';

type Props = {
  orderInvoice: OrderInvoice;
};

export const formItems = ({ orderInvoice }: Props): FormItemRecord<Order>[] => {
  const { amount, amountBrutto, createdAt, number, hash, filename } =
    orderInvoice;
  const formattedCreatedAt = dayjs(createdAt).format(DayjsFormat.DATE);

  return [
    {
      key: 'name',
      label: <FormattedMessage defaultMessage="Nazwa" />,
      component: (
        <DownloadFvButton number={number} hash={hash} filename={filename} />
      ),
    },
    {
      key: 'price',
      label: <FormattedMessage defaultMessage="Wartość" />,
      component: (
        <strong>
          <CurrencyFormatter value={amount} /> (
          <CurrencyFormatter value={amountBrutto} />)
        </strong>
      ),
    },
    {
      key: 'date',
      label: <FormattedMessage defaultMessage="Data wystawienia" />,
      component: <strong>{formattedCreatedAt}</strong>,
    },
  ];
};
