import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import { useGetPreorderQuery } from 'ducks/preorders/service';
import { PreorderRequestTypeParam } from 'ducks/preorders/types';
import { checkIfCurrentlyEdited } from 'utilities/authorization';
import { Routes } from './Routing';

type Props = {
  preorderId?: number | string;
};

export const usePreordersServices = ({ preorderId }: Props = {}) => {
  const { id: preorderIdParam = '' } = useParams();
  const { pathname } = useLocation();
  const { user } = useSanctum();
  const correctPreorderId = preorderId ? String(preorderId) : preorderIdParam;
  const isPreorderShowType =
    pathname === Routes.PREORDER_DETAILS.replace(':id', correctPreorderId);
  const {
    data: preorderData,
    isLoading: isPreorderLoading,
    isFetching: isPreorderFetching,
    isError,
    refetch,
  } = useGetPreorderQuery({
    id: Number(correctPreorderId),
    type: isPreorderShowType ? PreorderRequestTypeParam.SHOW : undefined,
  });
  const isCurrentlyEdited = checkIfCurrentlyEdited(
    preorderData?.activeEditorId,
    user,
  );
  const isPreorderEmpty = !preorderData && !isPreorderLoading && isError;

  const value = useMemo(
    () => ({
      preorderData,
      isPreorderLoading,
      isPreorderFetching,
      refetch,
      isPreorderEmpty,
      isCurrentlyEdited,
    }),
    [
      preorderData,
      isPreorderLoading,
      isPreorderFetching,
      refetch,
      isPreorderEmpty,
      isCurrentlyEdited,
    ],
  );

  return value;
};
