import type { PreorderVariantSummaryValues } from '../../config';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { PreorderVariantSlice } from 'ducks/view/preorders';
import { Input, Skeleton } from 'antd';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import {
  MAX_TIME_REALIZATION_VALUE,
  MIN_TIME_REALIZATION_VALUE,
} from 'constants/formInputLengths';
import { NO_DATA } from 'constants/strings';

type SystemProps = {
  systemTimeRealization: number;
  estimatedPrice: number;
  isEstimatedPriceLoading: boolean;
};

type EditableProps = Pick<
  PreorderVariantSlice,
  'timeRealization' | 'comment'
> & {
  isEditable: boolean;
};

const getTimeRealizationLabel = (timeRealization: number) =>
  timeRealization === 1 ? (
    <FormattedMessage defaultMessage="dzień roboczy" />
  ) : (
    <FormattedMessage defaultMessage="dni roboczych" />
  );

export const preorderVariantSummaryFormItems = ({
  isEditable,
  timeRealization,
  comment,
}: EditableProps): FormItemRecord<PreorderVariantSummaryValues>[] => [
  {
    key: 1,
    label: isEditable ? (
      <FormattedMessage defaultMessage="Czas realizacji - ustawiony przez pracownika" />
    ) : (
      <FormattedMessage
        defaultMessage="Czas realizacji - ustawiony przez pracownika: {timeRealization}"
        values={{
          timeRealization: (
            <b style={{ marginLeft: '4px' }}>
              {timeRealization} {getTimeRealizationLabel(timeRealization)}
            </b>
          ),
        }}
      />
    ),
    name: 'timeRealization',
    component: isEditable ? (
      <DecimalInputNumber
        data-testid="timeRealizationInput"
        min={MIN_TIME_REALIZATION_VALUE}
        max={MAX_TIME_REALIZATION_VALUE}
        precision={0}
      />
    ) : (
      <span />
    ),
  },
  {
    key: 2,
    label: (
      <FormattedMessage
        defaultMessage="<b>Uwagi do PDF</b>"
        values={{
          b: (chunk) => <b>{chunk}</b>,
        }}
      />
    ),
    name: 'comment',
    component: isEditable ? (
      <Input.TextArea data-testid="commentTextarea" rows={4} />
    ) : (
      <span data-testid="comment">{comment ?? NO_DATA}</span>
    ),
  },
];

export const preorderVariantSummarySystemItems = ({
  systemTimeRealization,
  estimatedPrice,
  isEstimatedPriceLoading,
}: SystemProps) => [
  {
    key: 1,
    label: isEstimatedPriceLoading ? (
      <Skeleton paragraph={false} active style={{ width: 50 }} />
    ) : (
      <FormattedMessage
        defaultMessage="Szacunkowo za tłumaczenie: {estimatedPrice}"
        values={{
          estimatedPrice: (
            <b style={{ marginLeft: '4px' }}>
              <CurrencyFormatter value={estimatedPrice} />
            </b>
          ),
        }}
      />
    ),
  },
  {
    key: 2,
    label: (
      <FormattedMessage
        defaultMessage="Czas realizacji - obliczony przez system: {timeRealization}"
        values={{
          timeRealization: (
            <b>
              {systemTimeRealization}{' '}
              {getTimeRealizationLabel(systemTimeRealization)}
            </b>
          ),
        }}
      />
    ),
  },
];
