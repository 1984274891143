import type { ListItemRecord } from 'components/ui/molecules/List';
import type { Translator } from 'ducks/translators/types';
import { FormattedMessage } from 'react-intl';
import RateItem from './components/RateItem';

export const rateCardListItems = (
  data: Translator | undefined,
): ListItemRecord[] => [
  {
    label: <FormattedMessage defaultMessage="Ogólna:" />,
    component: <RateItem rate={data?.rateAvg} />,
    skeletonRows: 1,
  },
  {
    label: <FormattedMessage defaultMessage="Terminowość:" />,
    component: <RateItem rate={data?.rate.punctuality} />,
    skeletonRows: 1,
  },
  {
    label: <FormattedMessage defaultMessage="Cena:" />,
    component: <RateItem rate={data?.rate.price} />,
    skeletonRows: 1,
  },
  {
    label: <FormattedMessage defaultMessage="Merytoryczność:" />,
    component: <RateItem rate={data?.rate.content} />,
    skeletonRows: 1,
  },
  {
    label: <FormattedMessage defaultMessage="Umiejętności techniczne:" />,
    component: <RateItem rate={data?.rate.technical} />,
    skeletonRows: 1,
  },
  {
    label: <FormattedMessage defaultMessage="Kontakt:" />,
    component: <RateItem rate={data?.rate.contact} />,
    skeletonRows: 1,
  },
];
