import type { AddLetterSummonsModalValues } from './config';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Form, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { useAddInvoiceLetterSummonMutation } from 'ducks/invoices/service';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';
import { formItems, initialValues } from './config';

const { useForm } = Form;

type Props = { isOpen: boolean; onClose: () => void };

const AddLetterSummonsModal: FC<Props> = ({ isOpen, onClose }) => {
  const { id = '' } = useParams();
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm<AddLetterSummonsModalValues>();
  const [addInvoiceLetterSummon, { isLoading }] =
    useAddInvoiceLetterSummonMutation();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleAddLetterSummon = () => {
    if (isLoading) return;

    mutationFunctionWithForm<void, AddLetterSummonsModalValues>({
      form,
      mutationFn: async ({ dateReceived, dateSent }) => {
        const formattedDateReceived = dayjs(
          dateReceived,
        ).format() as unknown as Date;
        const formattedDateSent = dayjs(dateSent).format() as unknown as Date;

        await addInvoiceLetterSummon({
          id,
          dateReceived: formattedDateReceived,
          dateSent: formattedDateSent,
        }).unwrap();
      },
      onSuccess: handleClose,
      onError: () =>
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania wezwania listowego." />
          ),
        }),
    });
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Wezwanie listowe" />}
      okText={<FormattedMessage defaultMessage="Dodaj" />}
      open={isOpen}
      onOk={handleAddLetterSummon}
      onCancel={handleClose}
      isLoading={isLoading}
      disableModalStyles
      disableFormStyles
    >
      {contextHolder}
      <Form
        form={form}
        initialValues={initialValues}
        autoComplete="off"
        layout="vertical"
      >
        {formItems.map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default AddLetterSummonsModal;
