import type { FormInstance } from 'antd';
import { Select } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import { useGetClientsQuery } from 'ducks/clients/service';
import useDebounce from 'hooks/useDebounce';
import ClientSelectorDropdown from './ClientSelectorDropdown';

type Option = { value: number; label: string };

type Props = {
  form: FormInstance;
  fullWidth?: boolean;
};

const ClientSelector = ({ form, fullWidth = false }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const searchTermDebounced = useDebounce(searchTerm, 500);
  const { data, isFetching } = useGetClientsQuery(
    { searchTerm: searchTermDebounced },
    { skip: !searchTermDebounced },
  );

  const options = useMemo(
    () =>
      (data?.data ?? []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [data],
  );

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const handleSelect = useCallback(
    (option: Option) => {
      if (selectedOption?.value === option.value) {
        setSelectedOption(null);
        form.setFieldValue('clientId', null);
      } else {
        setSelectedOption(option);
        form.setFieldValue('clientId', option.value);
      }

      setSearchTerm('');
      handleClose();
    },
    [selectedOption, form],
  );

  const MemoizedDropdown = useCallback(
    () => (
      <ClientSelectorDropdown
        isFetching={isFetching}
        options={options}
        selectedOption={selectedOption}
        onSelect={handleSelect}
      />
    ),
    [isFetching, options, selectedOption, handleSelect],
  );

  return (
    <FormItem
      name="clientId"
      label={<FormattedMessage defaultMessage="Klient" />}
      withoutMargin
      component={
        <Select
          style={{ width: fullWidth ? '100%' : '200px' }}
          open={isOpen}
          loading={isFetching}
          value={selectedOption?.label ?? searchTerm}
          onFocus={handleOpen}
          onSearch={setSearchTerm}
          options={options}
          showSearch
          searchValue={searchTerm}
          onDropdownVisibleChange={(visible) => setIsOpen(visible)}
          dropdownRender={MemoizedDropdown}
        />
      }
    />
  );
};

export default ClientSelector;
