import { notification } from 'antd';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch } from 'ducks/store';
import {
  resetOrdersState,
  setOrderSendEmailsTranslators,
} from 'ducks/view/orders';
import OrderCurrentStatus from 'views/Orders/components/OrderCurrentStatus';
import OrderHeader from 'views/Orders/components/OrderHeader';
import OrderNotesSection from 'views/Orders/components/OrderNotesSection';
import { useOrdersServices } from 'views/Orders/useOrdersServices';
import ActionsSection from './components/ActionsSection/ActionsSection';
import TranslatorsCollapseSection from './components/TranslatorsCollapseSection';

const OrderSendEmails = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [notify, contextHolder] = notification.useNotification();
  const { orderData, refetch } = useOrdersServices();

  useEffect(() => {
    if (orderData) {
      dispatch(
        setOrderSendEmailsTranslators({
          order: orderData,
          onInitialValuesChange: ({ number }) => ({
            subject: intl.formatMessage(
              {
                defaultMessage: 'Tłumaczenie nr {number}',
              },
              { number },
            ),
          }),
        }),
      );
    }

    return () => {
      dispatch(resetOrdersState());
    };
  }, [orderData, dispatch, intl]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="layout-content mb-24">
      {contextHolder}
      <OrderCurrentStatus>
        <OrderHeader />
        <OrderNotesSection
          notify={notify}
          colProps={{ lg: 24 }}
          scroll={false}
        />
        <TranslatorsCollapseSection />
        <ActionsSection notify={notify} />
      </OrderCurrentStatus>
    </div>
  );
};

export default OrderSendEmails;
