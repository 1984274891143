import { Col, Row, notification } from 'antd';
import { useEffect } from 'react';
import { OrderStatus } from 'constants/orderStatuses';
import { useAppDispatch } from 'ducks/store';
import {
  setOrderIsAssigned,
  setOrderIsEditable,
  resetOrdersState,
  setOrderFilesAndServices,
} from 'ducks/view/orders';
import OrderCurrentStatus from 'views/Orders/components/OrderCurrentStatus';
import OrderNotesSection from 'views/Orders/components/OrderNotesSection';
import ActionsSection from './components/ActionsSection';
import ClientSection from './components/ClientSection';
import ContactPerson from './components/ContactPerson';
import InvoiceDataSection from './components/InvoiceDataSection';
import InvoicesSection from './components/InvoicesSection';
import LangFilesTable from './components/LangFilesTable';
import OrderDetailsHeader from './components/OrderDetailsHeader';
import PaymentTableSection from './components/PaymentTableSection';
import ReferenceFileCard from './components/ReferenceFileCard';
import TabsSection from './components/TabsSection';
import { useOrderDetailsServices } from './useOrderDetailsServices';

const OrderDetails = () => {
  const [notify, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const { orderData, refetch } = useOrderDetailsServices();

  useEffect(() => {
    const isAssigned = orderData?.status === OrderStatus.ASSIGNED;

    dispatch(setOrderIsAssigned(isAssigned));
    dispatch(setOrderIsEditable(false));

    if (orderData) {
      dispatch(setOrderFilesAndServices(orderData));
    }
  }, [orderData, dispatch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    return () => {
      dispatch(resetOrdersState());
    };
  }, [dispatch]);

  return (
    <div className="layout-content mb-24">
      {contextHolder}
      <OrderCurrentStatus>
        <OrderDetailsHeader />
        <Row gutter={[24, 24]} align="top">
          <Col xs={24} lg={12}>
            <Row gutter={[24, 24]} align="top">
              <ContactPerson />
              <ClientSection />
            </Row>
          </Col>
          <OrderNotesSection notify={notify} />
        </Row>
        <TabsSection />
        <Row gutter={[24, 24]}>
          <PaymentTableSection notify={notify} />
          <ReferenceFileCard notify={notify} />
        </Row>
        <Row gutter={[24, 24]}>
          <InvoiceDataSection />
          <Col xs={24} lg={12}>
            <LangFilesTable />
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <InvoicesSection />
        </Row>
        <ActionsSection />
      </OrderCurrentStatus>
    </div>
  );
};

export default OrderDetails;
