import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export type RangeDateChange = [after: string, before: string];

type GenerateRangeDateValueOutput = [Dayjs | null, Dayjs | null];

export const getDayAndMonth = (date: Date | string): [string, string] => {
  const day = dayjs(date).format('DD');
  const month = dayjs(date).format('MM');

  return [day, month];
};

export const generateRangeDateValue = (
  before: string | undefined,
  after: string | undefined,
): GenerateRangeDateValueOutput => [
  before ? dayjs(before) : null,
  after ? dayjs(after) : null,
];
