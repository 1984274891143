import type {
  AddUserRequest,
  ChangeUserPasswordRequest,
  CurrentUser,
  EditUserRequest,
  GetUserDetailsRequest,
  User,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import { convertKeysToSnakeCase } from 'utilities/object';
import { UsersTag } from './types';

export enum Endpoint {
  GetCurrentUser = '/users/current',
  GetUsers = '/users',
  GetUserDetails = '/users/:id',
  AddUser = '/users',
  EditUser = '/users/:id',
  ChangeUserPassword = '/users/change-password/:id',
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQuery(),
  tagTypes: [UsersTag.CurrentUser, UsersTag.Users],
  endpoints: (build) => ({
    getCurrentUser: build.query<CurrentUser, void>({
      query: () => ({
        url: Endpoint.GetCurrentUser,
        method: 'GET',
      }),
      providesTags: [UsersTag.CurrentUser],
    }),
    getUsers: build.query<User[], void>({
      query: () => ({
        url: Endpoint.GetUsers,
        method: 'GET',
      }),
      providesTags: [UsersTag.Users],
    }),
    getUserDetails: build.query<User, GetUserDetailsRequest>({
      query: ({ id }) => ({
        url: Endpoint.GetUserDetails.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: [UsersTag.Users],
    }),
    addUser: build.mutation<User, AddUserRequest>({
      query: (data) => ({
        url: Endpoint.AddUser,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: [UsersTag.Users],
    }),
    editUser: build.mutation<User, EditUserRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.EditUser.replace(':id', id.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: [UsersTag.Users],
    }),
    changeUserPassword: build.mutation<User, ChangeUserPasswordRequest>({
      query: ({ id, ...data }) => ({
        url: Endpoint.ChangeUserPassword.replace(':id', id.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useGetUsersQuery,
  useGetUserDetailsQuery,
  useAddUserMutation,
  useEditUserMutation,
  useChangeUserPasswordMutation,
} = usersApi;
