import type { OrderRow } from './config';
import type { GetOrdersFilter } from 'ducks/orders/types';
import type { GetPreordersSort } from 'ducks/preorders/types';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Table from 'components/ui/molecules/Table';
import { useGetOrdersQuery } from 'ducks/orders/service';
import useTablePagination from 'hooks/useTablePagination';
import { Routes } from 'views/Orders/Routing';
import { columns, initialFilters } from './config';
import OrdersTableToolbar from './OrdersTableToolbar';

const OrdersList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    page,
    handlePageChange,
    searchValue,
    searchTerm,
    handleSearchValue,
    filter,
    filterBy,
    handleFilterBy,
    handleTableChange,
    sortBy,
  } = useTablePagination<OrderRow, GetOrdersFilter>({ initialFilters });
  const sort = sortBy?.order && {
    [sortBy.columnKey as keyof GetPreordersSort]: sortBy.order,
  };
  const { data: ordersData, isFetching } = useGetOrdersQuery(
    {
      page,
      searchTerm,
      sort,
      filter,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );
  const pageSize = ordersData?.perPage;
  const total = isFetching ? 0 : ordersData?.total;

  const modifiedData = useMemo(
    (): OrderRow[] =>
      (ordersData?.data ?? []).map((order) => ({
        ...order,
        key: order.id,
      })),
    [ordersData],
  );

  return (
    <div className="layout-content mb-24">
      <OrdersTableToolbar
        searchValue={searchValue}
        onSearchValueChange={handleSearchValue}
        filterBy={filterBy}
        onFilterByChange={handleFilterBy}
      />
      <Table
        pagination={{
          current: page,
          pageSize,
          total,
          onChange: handlePageChange,
        }}
        cardTitle={<FormattedMessage defaultMessage="Lista zamówień" />}
        onChange={handleTableChange}
        isLoading={isFetching}
        data={modifiedData}
        columns={columns({ intl })}
        onRow={({ id }) => ({
          onClick: () =>
            navigate(Routes.ORDER_DETAILS.replace(':id', id.toString())),
        })}
      />
    </div>
  );
};

export default OrdersList;
