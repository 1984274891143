import { useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import { verifyEmployee } from 'utilities/authorization';
import InvoiceAdd from './view/InvoiceAdd';
import InvoiceOverdueDetails from './view/InvoiceOverdueDetails';
import InvoicesAdvance from './view/InvoicesAdvance';
import InvoiceSend from './view/InvoiceSend';
import InvoicesFinal from './view/InvoicesFinal';
import InvoicesOverdue from './view/InvoicesOverdue';
import InvoicesPaymentIndividuals from './view/InvoicesPaymentIndividuals';
import InvoicesVat from './view/InvoicesVat';

export enum Routes {
  INVOICES = '/invoices',
  INVOICE_DETAILS = '/invoices/:id',
  INVOICE_ADD = '/invoices/add',
  INVOICE_SEND = '/invoices/:id/send',
  INVOICES_VAT = '/invoices/vat',
  INVOICES_FINAL = '/invoices/final',
  INVOICES_ADVANCE = '/invoices/advance',
  INVOICES_OVERDUE = '/invoices/overdue',
  INVOICES_PAYMENT_INDIVIDUALS = '/invoices/payment-individuals',
  INVOICE_OVERDUE_DETAILS = '/invoices/overdue/:id',
}

const InvoicesRouting = () => {
  const { user } = useSanctum();
  const navigate = useNavigate();
  const isEmployee = verifyEmployee(user);

  useEffect(() => {
    if (isEmployee) {
      navigate('/');
    }
  }, [isEmployee, navigate]);

  return (
    <AnimatedRoutes pathLevelWatch={2}>
      <Route
        path={Routes.INVOICE_ADD.replace(Routes.INVOICES, '')}
        element={<InvoiceAdd />}
      />
      <Route
        path={Routes.INVOICE_SEND.replace(Routes.INVOICES, '')}
        element={<InvoiceSend />}
      />
      <Route
        path={Routes.INVOICES_VAT.replace(Routes.INVOICES, '')}
        element={<InvoicesVat />}
      />
      <Route
        path={Routes.INVOICES_FINAL.replace(Routes.INVOICES, '')}
        element={<InvoicesFinal />}
      />
      <Route
        path={Routes.INVOICES_ADVANCE.replace(Routes.INVOICES, '')}
        element={<InvoicesAdvance />}
      />
      <Route
        path={Routes.INVOICES_OVERDUE.replace(Routes.INVOICES, '')}
        element={<InvoicesOverdue />}
      />
      <Route
        path={Routes.INVOICES_PAYMENT_INDIVIDUALS.replace(Routes.INVOICES, '')}
        element={<InvoicesPaymentIndividuals />}
      />
      <Route
        path={Routes.INVOICE_OVERDUE_DETAILS.replace(Routes.INVOICES, '')}
        element={<InvoiceOverdueDetails />}
      />
      <Route
        path={Routes.INVOICE_OVERDUE_DETAILS.replace(Routes.INVOICES, '')}
        element={<div>INVOICE_OVERDUE_DETAILS</div>}
      />
    </AnimatedRoutes>
  );
};

export default InvoicesRouting;
