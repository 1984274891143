import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPreorderVariantsQuery } from 'ducks/preorders/service';
import { usePreordersServices } from 'views/Preorders/usePreordersServices';

const usePreorderRealizationServices = () => {
  const { id = '', variantId = '' } = useParams();
  const preorderId = Number(id);
  const { preorderData, isPreorderFetching, refetch } = usePreordersServices();
  const {
    data: preorderVariantsData = [],
    isLoading: isPreorderVariantsLoading,
  } = useGetPreorderVariantsQuery(
    {
      id: preorderId,
    },
    { skip: !preorderData },
  );
  const isLoading = isPreorderFetching || isPreorderVariantsLoading;

  const selectedVariants = useMemo(
    () =>
      preorderVariantsData.filter(
        (variant) => variant.id === Number(variantId),
      ),
    [preorderVariantsData, variantId],
  );

  const value = useMemo(
    () => ({
      preorderData,
      isPreorderFetching,
      refetch,
      preorderVariantsData,
      isPreorderVariantsLoading,
      selectedVariants,
      isSelectedPreorderVariantsEmpty:
        !selectedVariants.length &&
        !isPreorderVariantsLoading &&
        !isPreorderFetching,
      isLoading,
    }),
    [
      preorderData,
      isPreorderFetching,
      refetch,
      preorderVariantsData,
      isPreorderVariantsLoading,
      selectedVariants,
      isLoading,
    ],
  );

  return value;
};

export default usePreorderRealizationServices;
