import type {
  Client,
  ClientOrder,
  ContactPerson,
  GetClientsResponse,
} from 'ducks/clients/types';
import { DefaultValue } from 'ducks/types';
import { mockedPagination } from 'handlers/utils';

export const mockedClient: Client = {
  id: 1,
  canDelete: true,
  isCompany: false,
  name: 'Stracke and Sons',
  email1: 'email@gmail.com',
  email2: '',
  emailDefault: DefaultValue.FIRST,
  phone1: '123456789',
  phone2: '',
  phoneDefault: DefaultValue.FIRST,
  address: '',
  postCode: '',
  city: '',
  country: '',
  nip: null,
  regon: null,
  isTrusted: false,
  isDebtor: false,
  balance: 819.48,
  discount: 80,
  invoiceAuto: false,
  invoiceType: 1,
  invoiceName: 'invoice-name',
  invoiceNip: '2314578560',
  invoiceAddress: null,
  invoicePostCode: '65235-7718',
  invoiceCity: null,
  invoiceEmail: null,
  invoiceCountry: null,
  createdAt: '',
  updatedAt: '',
  ordersCount: 1,
  ordersInProgressCount: 1,
  efficiency: 1,
  mt: true,
  preordersCount: 1,
  complaintsCount: 1,
  isCollectiveInvoices: false,
};

export const mockedClientsResponse: GetClientsResponse =
  mockedPagination(mockedClient);

export const mockedContactPerson: ContactPerson = {
  id: 1,
  name: 'contact-person-name',
  email: 'email@gmail.com',
  phone: '123456789',
  isSms: true,
  createdAt: 'date',
};

const dummyStatusGenerator = (index: number) => {
  if (index % 3 === 0) return 0;
  if (index % 2 === 1) return 1;
  return 2;
};

const dummyOrdersData = (index: number): ClientOrder => ({
  id: index,
  invoiceStatus: index % 2 === 0,
  status: dummyStatusGenerator(index),
  dateRealization: `${new Date()}`,
  number: index.toString(),
  priceNetto: index + 10,
  isMt: index % 2 === 0,
  langs: [
    {
      langFrom: 'PL',
      langTo: 'NO',
    },
  ],
  createdAt: new Date(),
});

export const mockedClientOrdersAndPreorders = Array(20)
  .join()
  .split(',')
  .map((_, index) => dummyOrdersData(index));
