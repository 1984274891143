import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type {
  GetPaymentIndividualsFilter,
  PaymentIndividual,
} from 'ducks/payments/types';
import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { DayjsFormat } from 'constants/dayjsFormats';
import { getPaymentType } from 'constants/payment';
import { NO_DATA } from 'constants/strings';
import ActionsCell from './components/ActionsCell';

export type InvoicesPaymentIndividualRow = TableRow & PaymentIndividual;

type Props = {
  intl: IntlShape;
};

export const initialPaymentIndividualsFilters: GetPaymentIndividualsFilter = {
  datePaymentBefore: undefined,
  datePaymentAfter: undefined,
};

export const columns = ({
  intl,
}: Props): ColumnsType<InvoicesPaymentIndividualRow> => [
  {
    key: 'id',
    dataIndex: 'id',
    title: <FormattedMessage defaultMessage="LP" />,
    width: 20,
  },
  {
    key: 'datePayment',
    dataIndex: 'datePayment',
    title: <FormattedMessage defaultMessage="Data odnotowania płatności" />,
    sorter: true,
    defaultSortOrder: 'descend',
    render: (_, { datePayment }) => dayjs(datePayment).format(DayjsFormat.DATE),
  },
  {
    key: 'number',
    dataIndex: 'number',
    title: <FormattedMessage defaultMessage="Numer zamówienia" />,
  },
  {
    key: 'amount',
    dataIndex: 'amount',
    title: <FormattedMessage defaultMessage="Kwota brutto" />,
    render: (_, { amount }) => <CurrencyFormatter value={amount} />,
  },
  {
    key: 'type',
    dataIndex: 'type',
    title: <FormattedMessage defaultMessage="Metoda płatnośc" />,
    render: (_, { type }) => getPaymentType(intl, type)?.label ?? NO_DATA,
  },
  {
    key: 'note',
    dataIndex: 'note',
    title: <FormattedMessage defaultMessage="Uwagi" />,
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    title: <FormattedMessage defaultMessage="Akcje" />,
    width: 65,
    align: 'center',
    render: (_, { id, note }) => <ActionsCell id={id} note={note} />,
  },
];
