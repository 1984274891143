/* eslint-disable react/jsx-props-no-spreading */
import type { SendEmailFormValues } from './config';
import { Input, type FormInstance } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import EmailTemplateSelect from 'components/ui/atoms/EmailTemplateSelect';
import FormItem from 'components/ui/atoms/FormItem';
import Switch from 'components/ui/atoms/Switch';
import SmsTemplateSelector from 'components/ui/molecules/SmsTemplateSelector';
import { formItems } from './config';
import styles from './styles.module.scss';

interface Props {
  form: FormInstance<SendEmailFormValues>;
  emailTemplate: string;
  onEmailTemplateChange: (value: string) => void;
  isRequired?: boolean;
}

const SendEmailForm: FC<Props> = ({
  form,
  emailTemplate,
  onEmailTemplateChange,
  isRequired = true,
}) => {
  const isSms = useWatch('isSms', form);

  const handleIsSmsChange = () => {
    if (isSms) return;

    form.setFieldValue('smsText', '');
  };

  return (
    <>
      {formItems({ isRequired }).map(({ key, ...props }) => (
        <FormItem key={key} {...props} />
      ))}
      <EmailTemplateSelect
        value={emailTemplate}
        onChange={onEmailTemplateChange}
      />
      <div
        className={styles.wrapper}
        style={{
          justifyContent: isSms ? 'space-between' : 'flex-end',
        }}
      >
        <div className={styles.label}>
          <FormItem
            name="isSms"
            additionalItemProps={{ valuePropName: 'checked' }}
            component={
              <Switch
                data-testid="isSmsSwitch"
                label={<FormattedMessage defaultMessage="SMS" />}
                onChange={handleIsSmsChange}
              />
            }
          />
        </div>
        {isSms ? (
          <div className={styles.itemWrapper}>
            <SmsTemplateSelector form={form} formPropName="smsText" />
            <FormItem
              name="smsText"
              component={
                isSms ? (
                  <Input.TextArea data-testid="smsTextTextarea" rows={3} />
                ) : (
                  <span />
                )
              }
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SendEmailForm;
