import type { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { LockIcon } from 'icons';
import styles from './styles.module.scss';

type Props = {
  canUnblock: boolean;
  showUnblockButton: boolean;
  handleUnblock: () => void;
  isLoading: boolean;
};

const PadlockLockedButton: FC<Props> = ({
  canUnblock,
  showUnblockButton,
  handleUnblock,
  isLoading,
}) => {
  return showUnblockButton ? (
    <div
      className={cn(styles.padlockButtonWrapper, {
        [styles.padlockButtonWrapperLoading]: isLoading,
      })}
    >
      <LockIcon data-testid="lockIcon" />
      {canUnblock && (
        <Button size="small" onClick={handleUnblock} loading={isLoading}>
          <FormattedMessage defaultMessage="Odblokuj" />
        </Button>
      )}
    </div>
  ) : (
    <LockIcon data-testid="lockIcon" />
  );
};

export default PadlockLockedButton;
