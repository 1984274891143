import { FormattedMessage } from 'react-intl';
import List from 'components/ui/molecules/List';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import CardCommon from '../CardCommon';
import { rateCardListItems } from './config';
import styles from './styles.module.scss';

const RateCard = () => {
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();

  return (
    <CardCommon
      data-testid="rateCard"
      title={<FormattedMessage defaultMessage="Ocena" />}
      className={styles.cardCommon}
      isLoading={isTranslatorFetching}
      xs={24}
      sm={12}
      md={24}
    >
      <List
        items={rateCardListItems(translatorData)}
        isLoading={isTranslatorFetching}
        withoutMargin
      />
    </CardCommon>
  );
};

export default RateCard;
