import { Button } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import { PreorderStatus } from 'constants/preorder';
import { useAppSelector } from 'ducks/store';
import { calcPreorderVariantPriceTotal } from 'utilities/calcPreorderTotals';
import { usePreorderDetailsServices } from '../../usePreorderDetailsServices';
import ChangeClientModal from './ChangeClientModal';
import { formItems } from './config';

const ClientSection = () => {
  const { isEditable, variants } = useAppSelector(
    ({ preorders }) => ({
      isEditable: preorders.isEditable,
      variants: preorders.variants,
    }),
    shallowEqual,
  );
  const [isChangeClientModalOpen, setChangeClientModalOpen] = useState(false);
  const { preorderData, isPreorderFetching } = usePreorderDetailsServices();
  const isNotInRealization =
    preorderData?.status !== PreorderStatus.IN_REALIZATION;
  const shouldShowVariantDetails = variants.length === 1;
  const variantTotalPrice = shouldShowVariantDetails
    ? calcPreorderVariantPriceTotal(variants[0])
    : null;

  return (
    <CardCommon
      data-testid="clientSection"
      title={
        <TitleWrapper>
          <FormattedMessage defaultMessage="Profil klienta" />
          {isEditable && (
            <Button
              type="primary"
              onClick={() => setChangeClientModalOpen(true)}
            >
              <FormattedMessage defaultMessage="Zmień klienta" />
            </Button>
          )}
        </TitleWrapper>
      }
      isLoading={isPreorderFetching}
      xs={24}
    >
      <ChangeClientModal
        isOpen={isChangeClientModalOpen}
        onClose={() => setChangeClientModalOpen(false)}
      />
      <div className="gridForm">
        {preorderData &&
          formItems({
            data: preorderData,
            variantTotalPrice,
            isEditable: isEditable && isNotInRealization,
          }).map(({ key, ...props }) => <FormItem key={key} {...props} />)}
      </div>
      {/* commented as recommended in #481 */}
      {/* <Col xs={24} md={6}>
          <FormItem
            label={
              <FormattedMessage defaultMessage="Treść wiadomości e-mail:" />
            }
            component={<Text>{preorderData?.emailMessage ?? NO_DATA}</Text>}
          />
        </Col> */}
    </CardCommon>
  );
};

export default ClientSection;
