import type { VariantServiceRow } from '../../config';
import type { FC } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import { ServiceAttributeNames } from 'constants/services';
import { NO_DATA } from 'constants/strings';
import { useAppSelector } from 'ducks/store';
import { getPreorderServiceInitialValue } from 'ducks/view/preorders/utils';
import { getServiceAttributeOptions } from '../../utils';

const ATTRIBUTE_NAME = ServiceAttributeNames.TYPE;

type Props = { row: VariantServiceRow };

const TypeSelect: FC<Props> = ({
  row: { id, isActive, preorderVariantServiceValues, service },
}) => {
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
  const initialValue = getPreorderServiceInitialValue(
    preorderVariantServiceValues,
    ATTRIBUTE_NAME,
  );
  const options =
    service && getServiceAttributeOptions(service, ATTRIBUTE_NAME);

  if (!isEditable)
    return <span data-testid="type">{initialValue ?? NO_DATA}</span>;

  return options ? (
    <FormItem
      name={[id as number, ['attributeName', ATTRIBUTE_NAME].join(',')]}
      component={
        <Select
          data-testid="typeSelect"
          options={options.map((value) => ({
            value,
            label: value,
          }))}
          placeholder={<FormattedMessage defaultMessage="Wybierz kuriera" />}
          disabled={!isEditable}
        />
      }
      rules={[
        {
          required: isActive,
          message: <FormattedMessage defaultMessage="Wybierz kuriera" />,
        },
      ]}
      additionalItemProps={{
        initialValue,
      }}
    />
  ) : null;
};

export default TypeSelect;
