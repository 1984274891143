import type { ColumnsType } from 'antd/lib/table';
import type { InvoiceListItemRow } from 'views/Invoices/config';
import { FormattedMessage, type IntlShape } from 'react-intl';
import { invoiceListItemColumns } from 'views/Invoices/config';
import ActionsCell from './components/ActionsCell';

type Props = {
  intl: IntlShape;
};

export const columns = ({ intl }: Props): ColumnsType<InvoiceListItemRow> => [
  ...invoiceListItemColumns({ intl }),
  {
    key: 'actions',
    dataIndex: 'actions',
    title: <FormattedMessage defaultMessage="Akcje" />,
    width: 100,
    align: 'center',
    render: (_, { id, hasInvoiceAdvance }) => (
      <ActionsCell id={id} hasInvoiceAdvance={hasInvoiceAdvance} />
    ),
  },
];
