import { Col, notification } from 'antd';
import Notes from 'components/ui/organisms/Notes';
import { NoteType } from 'ducks/notes/types';
import { useInvoiceSendServices } from '../../useInvoiceSendServices';

const NotesSection = () => {
  const [notify, contextHolder] = notification.useNotification();
  const { invoiceVatData, isInvoiceVatFetching } = useInvoiceSendServices();
  const clientId = invoiceVatData?.clientId;
  const skip = !invoiceVatData?.clientId;

  return (
    <Col xs={24}>
      {contextHolder}
      <Notes
        objectId={clientId}
        noteType={NoteType.CLIENTS}
        notify={notify}
        skip={skip}
        isLoading={isInvoiceVatFetching}
      />
    </Col>
  );
};

export default NotesSection;
