import { Space, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import HorizontalCenteredWrapper from 'components/ui/atoms/HorizontalCenteredWrapper';
import LanguageFlag from 'components/ui/atoms/LanguageFlag';
import { NO_DATA } from 'constants/strings';
import { useTranslatorProfileServices } from 'views/Translators/views/TranslatorProfile';
import CardCommon from '../../CardCommon';

const { Text } = Typography;

const LanguagesCard = () => {
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const langs = translatorData?.langs ?? [];

  return (
    <CardCommon
      title={<FormattedMessage defaultMessage="Języki" />}
      isLoading={isTranslatorFetching}
      data-testid="languagesCard"
      xs={24}
      md={24}
    >
      <HorizontalCenteredWrapper isLoading={isTranslatorFetching}>
        {langs.length ? (
          langs.map((countryShortCode) => (
            <Space key={countryShortCode} size={2}>
              <LanguageFlag languageCode={countryShortCode} />
              {countryShortCode === translatorData?.langNative && (
                <Text>
                  <FormattedMessage defaultMessage="(N)" />
                </Text>
              )}
            </Space>
          ))
        ) : (
          <Text>{NO_DATA}</Text>
        )}
      </HorizontalCenteredWrapper>
    </CardCommon>
  );
};

export default LanguagesCard;
