import type { ListItemRecord } from 'components/ui/molecules/List';
import type { Order } from 'ducks/orders/types';
import { Skeleton } from 'antd';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { summaryListTimeRealizationItem } from 'constants/summaryList';

type Props = Pick<Order, 'repetition'> & {
  estimatedPrice: number;
  isEstimatedPriceLoading: boolean;
  timeRealizationTotal: number | undefined;
};

export const summaryListItems = ({
  repetition,
  estimatedPrice,
  isEstimatedPriceLoading,
  timeRealizationTotal,
}: Props): ListItemRecord[] => {
  return [
    summaryListTimeRealizationItem(timeRealizationTotal),
    {
      label: <FormattedMessage defaultMessage="Powtarzalność (%):" />,
      skeletonRows: 1,
      component: repetition,
    },
    {
      label: <FormattedMessage defaultMessage="Szacunkowo za tłumaczenie:" />,
      skeletonRows: 1,
      component: (
        <span>
          {isEstimatedPriceLoading ? (
            <Skeleton paragraph={false} active style={{ width: 50 }} />
          ) : (
            <CurrencyFormatter value={estimatedPrice} />
          )}
        </span>
      ),
    },
  ];
};
