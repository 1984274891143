import type { EditVacationValues } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { Translator } from 'ducks/translators/types';
import dayjs from 'dayjs';
import { DatePicker, Form, Switch } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import { useEffect, type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/ui/molecules/Modal';
import { useUpdateTranslatorVacationMutation } from 'ducks/translators/service';
import 'dayjs/locale/pl';

interface Props {
  data: Translator;
  isOpen: boolean;
  onClose: () => void;
  notify: NotificationInstance;
}

const { Item, useForm, useWatch } = Form;
const { RangePicker } = DatePicker;

const EditVacationModal: FC<Props> = ({ isOpen, onClose, data, notify }) => {
  const [form] = useForm<EditVacationValues>();
  const vacationRangeWatch = useWatch('vacationRange', form);
  const [updateTranslatorVacation, { isLoading }] =
    useUpdateTranslatorVacationMutation();
  const initialValues: EditVacationValues = {
    vacationRange:
      data.vacationFrom && data.vacationTo
        ? [dayjs(data.vacationFrom), dayjs(data.vacationTo)]
        : [],
    isAvailable: !(data.vacationFrom && data.vacationTo),
  };
  const isLackOfVacation = !vacationRangeWatch || !vacationRangeWatch?.length;

  const handleOnSubmit = async () => {
    if (isLoading) return;

    try {
      const { vacationRange } = await form.validateFields();
      const vacationFrom = vacationRange?.[0]?.format() ?? null;
      const vacationTo = vacationRange?.[1]?.format() ?? null;

      await updateTranslatorVacation({
        translatorId: data.id,
        vacationFrom,
        vacationTo,
      }).unwrap();

      notify.success({
        message: <FormattedMessage defaultMessage="Urlop został edytowany." />,
      });
      onClose();
      form.setFieldsValue({ vacationRange, isAvailable: !vacationRange });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.errorFields) return;

      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edycji urlopu." />
        ),
      });
    }
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleAvailableChange = (checked: boolean) => {
    if (checked) form.setFieldValue('vacationRange', null);
  };

  useEffect(() => {
    if (!isOpen) return;

    if (isLackOfVacation) {
      form.setFieldValue('isAvailable', true);
      return;
    }

    if (vacationRangeWatch) {
      form.setFieldValue('isAvailable', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, vacationRangeWatch, isLackOfVacation]);

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Edytuj urlop" />}
      isLoading={isLoading}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={handleClose}
      okText={<FormattedMessage defaultMessage="Edytuj" />}
      disableFormStyles
      disableModalStyles
    >
      <Form form={form} initialValues={initialValues} labelCol={{ span: 5 }}>
        <Item
          name="vacationRange"
          label={<FormattedMessage defaultMessage="Zakres urlopu" />}
        >
          <RangePicker locale={locale} />
        </Item>
        <Item
          name="isAvailable"
          label={<FormattedMessage defaultMessage="Dostępny" />}
          valuePropName="checked"
        >
          <Switch
            data-testid="isAvailableSwitch"
            disabled={isLackOfVacation}
            onChange={handleAvailableChange}
          />
        </Item>
      </Form>
    </Modal>
  );
};

export default EditVacationModal;
