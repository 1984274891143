import { notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { OrderStatus } from 'constants/orderStatuses';
import { useUpdateOrderStatusMutation } from 'ducks/orders/service';
import { useAppSelector } from 'ducks/store';
import OrderHeader from 'views/Orders/components/OrderHeader';
import { Routes } from 'views/Orders/Routing';
import { checkOrderClientSendRouteAvailable } from 'views/Orders/utils';
import { useOrderDetailsServices } from '../../useOrderDetailsServices';
import styles from './styles.module.scss';

const OrderDetailsHeader = () => {
  const [notify, notifyContext] = notification.useNotification();
  const { id = '' } = useParams();
  const orderStatus = useAppSelector(({ orders }) => orders.status);
  const { isCurrentlyEdited, orderTranslationFilesData } =
    useOrderDetailsServices();
  const [updateStatus, { isLoading }] = useUpdateOrderStatusMutation();

  const isAssigned = orderStatus === OrderStatus.ASSIGNED;
  const isPartiallyAssigned = orderStatus === OrderStatus.PARTIALLY_ASSIGNED;
  const isNew = orderStatus === OrderStatus.NEW;
  const isReady = orderStatus === OrderStatus.READY;
  const showAssignButton =
    isAssigned || isPartiallyAssigned || isNew || isReady;
  const isOrderClientSendRouteAvailable =
    !!orderStatus &&
    checkOrderClientSendRouteAvailable({
      status: orderStatus,
      orderTranslationFilesData,
    });

  const handleChangeStatus = async (status: number) => {
    try {
      await updateStatus({
        id,
        status,
      }).unwrap();
      notify.success({
        message: (
          <FormattedMessage defaultMessage="Status zmieniono poprawnie" />
        ),
      });
    } catch {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd, spróbuj ponownie" />
        ),
      });
    }
  };

  return (
    <OrderHeader showUnblockButton>
      {notifyContext}
      {!isCurrentlyEdited && (
        <>
          {isOrderClientSendRouteAvailable && (
            <Link to={Routes.ORDER_SEND_CLIENT.replace(':id', id)}>
              <Button className={styles.sendButton}>
                {orderStatus !== OrderStatus.DONE ? (
                  <FormattedMessage defaultMessage="Wyślij tłumaczenie" />
                ) : (
                  <FormattedMessage defaultMessage="Wyślij ponownie tłumaczenie" />
                )}
              </Button>
            </Link>
          )}
          {showAssignButton && (
            <Link to={Routes.ORDER_ASSIGN.replace(':id', id)}>
              <Button>
                <FormattedMessage defaultMessage="Zleć tłumaczenie" />
              </Button>
            </Link>
          )}
          {orderStatus === OrderStatus.DONE && (
            <Button
              onClick={() => handleChangeStatus(OrderStatus.VERIFICATION)}
              loading={isLoading}
            >
              <FormattedMessage defaultMessage="Weryfikacja" />
            </Button>
          )}
          {orderStatus !== OrderStatus.DONE && (
            <Link to={Routes.ORDER_TRANSLATION_FILES.replace(':id', id)}>
              <Button>
                <FormattedMessage defaultMessage="Wgraj pliki" />
              </Button>
            </Link>
          )}
        </>
      )}
    </OrderHeader>
  );
};

export default OrderDetailsHeader;
