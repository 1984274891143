/* eslint-disable react/jsx-props-no-spreading */
import type { EditTranslatorValues } from '../../config';
import type { FormInstance } from 'antd';
import type { FC } from 'react';
import { Col, Form, Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import { correspondenceInputs } from './config';

interface Props {
  form: FormInstance<EditTranslatorValues>;
}

const { Item, useWatch } = Form;

const CorrespondenceFormSection: FC<Props> = ({ form }) => {
  const countryCode = useWatch('country', form) ?? '';
  const items = correspondenceInputs({ countryCode, form });
  const formInputs = [
    {
      inputs: items.slice(0, 2),
      radioItemName: 'emailDefault',
    },
    {
      inputs: items.slice(2, 4),
      radioItemName: 'phoneDefault',
    },
  ];

  return (
    <Col span={24} md={8}>
      <h6>
        <FormattedMessage defaultMessage="Dane korespondencyjne" />
      </h6>
      {formInputs.map(({ inputs, radioItemName }) => (
        <Item key={radioItemName} name={radioItemName} style={{ margin: 0 }}>
          <Radio.Group className="width-100">
            {inputs.map(({ key, ...props }) => (
              <FormItem key={key} {...props} />
            ))}
          </Radio.Group>
        </Item>
      ))}
      {items.slice(4).map(({ key, ...props }) => (
        <FormItem key={key} {...props} />
      ))}
    </Col>
  );
};

export default CorrespondenceFormSection;
