import type { OrderServiceValue } from 'ducks/orders/types';
import type { PreorderVariantService } from 'ducks/preorders/types';
import type { OrderServiceState } from 'ducks/view/orders';
import type { PreorderVariantServiceSlice } from 'ducks/view/preorders';
import {
  ServiceAttributeNames,
  ServiceType,
  graphicTimeRealizationRange,
} from 'constants/services';

type Service =
  | PreorderVariantService
  | PreorderVariantServiceSlice
  | OrderServiceState;

type ServiceValue = Omit<OrderServiceValue, 'id' | 'orderServiceId'>;

const getSimplePages = (serviceValues: ServiceValue[]) =>
  serviceValues.find(
    ({ attributeName }) =>
      attributeName === ServiceAttributeNames.SIMPLE_PAGES_NUMBER,
  )?.attributeValue;

const getDifficultPages = (serviceValues: ServiceValue[]) =>
  serviceValues.find(
    ({ attributeName }) =>
      attributeName === ServiceAttributeNames.DIFFICULT_PAGES_NUMBER,
  )?.attributeValue;

/**
 * Retrieves the graphic day value based on thesum of
 * simple anddifficult pages
 * @returns
 */
const getGraphicTimeRealizationFromRange = (
  simplePages: number,
  difficultPages: number,
) => {
  const pagesTotal = simplePages + difficultPages;

  const range = Object.keys(graphicTimeRealizationRange).find((key) => {
    const [min, max] = key.split('-').map(Number);

    if (max) return pagesTotal > min && pagesTotal <= max;

    return pagesTotal > min;
  });

  return range ? graphicTimeRealizationRange[range] : 0;
};

/**
 * Calculates the delivery days for a graphic service based on
 * the number of simple pages and difficult pages.
 *
 * Checks if the graphic service exists and is active
 * Checks if the graphic service is active
 *
 * Default return 0
 */
export const calcServiceGraphicTimeRealization = (
  services: Service[],
  skipIsActiveCheck = false,
) => {
  const graphicService = services.find(
    ({ type }) => type === ServiceType.GRAPHIC_SERVICE,
  );

  if (!graphicService) return 0;

  if (
    !skipIsActiveCheck &&
    'isActive' in graphicService &&
    !graphicService.isActive
  )
    return 0;

  const simplePages = getSimplePages(
    'preorderVariantServiceValues' in graphicService
      ? graphicService.preorderVariantServiceValues
      : graphicService.orderServiceValues,
  );
  const difficultPages = getDifficultPages(
    'preorderVariantServiceValues' in graphicService
      ? graphicService.preorderVariantServiceValues
      : graphicService.orderServiceValues,
  );

  if (!simplePages || !difficultPages) return 0;

  return getGraphicTimeRealizationFromRange(
    Number(simplePages),
    Number(difficultPages),
  );
};
