import type { GetPaymentTransactionsFilter } from 'ducks/payments/types';
import type { FC } from 'react';
import { Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import styles from './styles.module.scss';

const AMOUNT_MIN_VALUE = 0;

type Props = {
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  filterBy: GetPaymentTransactionsFilter;
  onFilterByChange: (value: Partial<GetPaymentTransactionsFilter>) => void;
};

const SearchFilterToolbar: FC<Props> = ({
  searchValue,
  onSearchValueChange,
  filterBy,
  onFilterByChange,
}) => {
  const intl = useIntl();
  const amountToMinValue = filterBy?.amountFrom
    ? filterBy.amountFrom + 1
    : AMOUNT_MIN_VALUE;

  const handleChangeAmountFrom = (value: number | null) => {
    if (value && filterBy?.amountTo && value >= filterBy.amountTo) {
      onFilterByChange({ amountFrom: value, amountTo: value + 1 });

      return;
    }

    onFilterByChange({ amountFrom: value ?? undefined });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <span>
          <FormattedMessage defaultMessage="Wyszukaj" />
        </span>
        <Input
          value={searchValue}
          onChange={(e) => onSearchValueChange(e.target.value)}
          placeholder={intl.formatMessage({
            defaultMessage: 'nazwa klienta, numer faktury, numer zamówienia',
          })}
          allowClear
        />
      </div>
      <div className={styles.column}>
        <span>
          <FormattedMessage defaultMessage="Zakres kwot" />
        </span>
        <DecimalInputNumber
          placeholder={intl.formatMessage({ defaultMessage: 'od' })}
          value={filterBy?.amountFrom}
          onChange={handleChangeAmountFrom}
        />
        <span>-</span>
        <DecimalInputNumber
          placeholder={intl.formatMessage({ defaultMessage: 'do' })}
          value={filterBy?.amountTo}
          onChange={(value) =>
            onFilterByChange({ amountTo: value ?? undefined })
          }
          min={amountToMinValue}
        />
      </div>
    </div>
  );
};

export default SearchFilterToolbar;
