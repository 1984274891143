import type { HandleAddProps, ListItem } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type {
  EditTranslatorsPriceListsRequest,
  TranslatorsPriceListItem,
} from 'ducks/translators/types';
import { useState, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  useDeleteTranslatorsPriceListsMutation,
  useEditTranslatorsPriceListsMutation,
  useGetTranslatorsPriceListsQuery,
} from 'ducks/translators/service';
import { Routes } from 'views/Translators/Routing';
import { initialPriceListItemValues } from './config';

const useManagePriceList = (notify: NotificationInstance) => {
  const { id: translatorId = '' } = useParams();
  const [listItems, setListItems] = useState<ListItem[]>([]);
  const navigate = useNavigate();
  const {
    data = [],
    isLoading: isDataLoading,
    isFetching: isDataFetching,
  } = useGetTranslatorsPriceListsQuery({
    translatorId: Number(translatorId),
  });
  const [editTranslatorsPriceLists, { isLoading: isEditLoading }] =
    useEditTranslatorsPriceListsMutation();
  const [deleteTranslatorsPriceLists, { isLoading: isDeleteLoading }] =
    useDeleteTranslatorsPriceListsMutation();
  const isLoading =
    !isDataLoading && (isDataFetching || isEditLoading || isDeleteLoading);

  const handleAdd = (items: HandleAddProps) => {
    setListItems((prev) => {
      return [
        ...prev,
        ...items.map((props) => ({
          ...initialPriceListItemValues,
          ...props,
          id: v4(),
        })),
      ];
    });
  };

  const regularPriceList = useMemo(
    () => listItems.filter(({ isCertified }) => !isCertified),
    [listItems],
  );

  const certifiedPriceList = useMemo(
    () => listItems.filter(({ isCertified }) => isCertified),
    [listItems],
  );

  const handleSave = async () => {
    if (isLoading) return;

    const modifiedItems = listItems.map((item) =>
      typeof item.id === 'string' ? { ...item, id: null } : item,
    ) as EditTranslatorsPriceListsRequest['items'];

    try {
      await editTranslatorsPriceLists({
        translatorId: Number(translatorId),
        items: modifiedItems,
      }).unwrap();

      notify.success({
        message: <FormattedMessage defaultMessage="Cennik został edytowany" />,
      });

      navigate(Routes.TRANSLATOR_PROFILE.replace(':id', translatorId));
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania cennika." />
        ),
      });
    }
  };

  const handleEdit = (field: Record<number, TranslatorsPriceListItem>) => {
    if (isLoading) return;

    const id = Object.keys(field)[0];
    const newValues = Object.values(field)[0];

    setListItems((prev) =>
      prev.map((item) =>
        item.id.toString() === id ? { ...item, ...newValues } : item,
      ),
    );
  };

  const handleDelete = async (id: number | string) => {
    if (isLoading) return;

    if (typeof id === 'string') {
      setListItems((prev) => prev.filter((item) => item.id !== id));

      return;
    }

    try {
      await deleteTranslatorsPriceLists({
        translatorId: Number(translatorId),
        itemId: id,
      }).unwrap();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas usuwania." />
        ),
      });
    }
  };

  useEffect(() => {
    if (data && !isDataFetching) {
      setListItems(data);
    }
  }, [data, isDataFetching]);

  return {
    data,
    listItems,
    isDataLoading,
    isLoading,
    isDataFetching,
    handleAdd,
    handleSave,
    handleEdit,
    handleDelete,
    regularPriceList,
    certifiedPriceList,
  };
};

export default useManagePriceList;
