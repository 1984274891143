import type { FC } from 'react';
import { notification } from 'antd';
import Padlock from 'components/ui/molecules/Padlock';
import { useUnblockOrder } from 'views/Orders/useUnblockOrder';

type Props = {
  activeEditorId: number | undefined | null;
  showUnblockButton: boolean;
};

const OrderPadlock: FC<Props> = ({ activeEditorId, showUnblockButton }) => {
  const [notify, contextHolder] = notification.useNotification();
  const { handleUnblock, isLoading } = useUnblockOrder({
    notify,
    showSuccessNotify: true,
  });

  return (
    <>
      {contextHolder}
      <Padlock
        activeEditorId={activeEditorId}
        handleUnblock={handleUnblock}
        isLoading={isLoading}
        showPopover={false}
        showUnblockButton={showUnblockButton}
      />
    </>
  );
};

export default OrderPadlock;
