import { Space } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { resetFinancesState } from 'ducks/view/finances';
import ActionsSection from './components/ActionsSection';
import PaymentFileAndTransactionsTable from './components/PaymentFileAndTransactionsTable';
import UploadPaymentFile from './components/UploadPaymentFile';

const FinanceImport = () => {
  const dispatch = useAppDispatch();
  const parsedPaymentFile = useAppSelector(
    ({ finances }) => finances.parsedPaymentFile,
  );

  useEffect(() => {
    return () => {
      dispatch(resetFinancesState());
    };
  }, [dispatch]);

  return (
    <div className="layout-content mb-24">
      <Space direction="vertical" size="large" className="width-100">
        {parsedPaymentFile.length ? (
          <>
            <PaymentFileAndTransactionsTable />
            <ActionsSection />
          </>
        ) : (
          <UploadPaymentFile />
        )}
      </Space>
    </div>
  );
};

export default FinanceImport;
