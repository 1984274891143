export const convertArrayOfObjectsToObject = <
  T extends Record<string, unknown>,
>(
  array: T[],
) => {
  return array.reduce((result, currentObject) => {
    return { ...result, ...currentObject };
  }, {} as T);
};

export const createArray = (size: number) =>
  Array(size)
    .fill('')
    .map((_, i) => i + 1);

/**
 * This allows you to sort array of object in alphabetic order
 * @example
 * array.sort((a,b) => sortAlphabeticallyByProp(a,b,'name'))
 * */
export const sortAlphabeticallyByProp = <T>(a: T, b: T, propName: keyof T) => {
  if (a[propName] < b[propName]) {
    return -1;
  }
  if (a[propName] > b[propName]) {
    return 1;
  }
  return 0;
};

export const filterDuplicates = <T>(array: T[], field: keyof T) => {
  const uniqueValues = new Set();

  return array.filter((el) => {
    const value = el[field];

    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);

      return true;
    }

    return false;
  });
};
