import { type FC } from 'react';
import { useAppSelector } from 'ducks/store';
import { type PreorderVariantLangSlice } from 'ducks/view/preorders';
import AddLangButton from '../AddLangButton';
import Langs from './components/Langs';
import styles from './styles.module.scss';

interface Props {
  variantFileId: number | string;
  langs: Pick<PreorderVariantLangSlice, 'id' | 'langFrom' | 'langTo'>[];
}

const LangsColumn: FC<Props> = ({ variantFileId, langs }) => {
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);

  if (!langs.length && isEditable) {
    return (
      <div className={styles.addLangButtonPlaceholderWrapper}>
        <AddLangButton variantFileId={variantFileId} />
      </div>
    );
  }

  return (
    <div className={styles.multilineCell}>
      {langs.map(({ id, langFrom, langTo }) => (
        <Langs key={id} langFrom={langFrom} langTo={langTo} />
      ))}
      {isEditable && <AddLangButton variantFileId={variantFileId} />}
    </div>
  );
};

export default LangsColumn;
