import type { PreorderVariant } from 'ducks/preorders/types';
import type { FC } from 'react';
import { Tag } from 'antd';
import { useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import theme from 'components/layout/Main/styles/values.scss';
import Button from 'components/ui/atoms/Button';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { calcVariantTotalRealizationTime } from 'utilities/calcPreorderRealizationTime';
import { calcPreorderVariantPriceTotal } from 'utilities/calcPreorderTotals';
import { calcBrutto } from 'utilities/calcPrice';
import { generateWorkingDaysLabel } from 'utilities/workingDaysLabel';
import { useOrderDetailsServices } from 'views/Orders/views/OrderDetails';
import { Routes } from 'views/Preorders/Routing';
import styles from './styles.module.scss';

type Props = {
  variant: PreorderVariant;
};

const PanelHeader: FC<Props> = ({ variant }) => {
  const { orderData } = useOrderDetailsServices();
  const vatRate = orderData?.vatRate ?? 0;

  const [netto, brutto] = useMemo(
    () => [
      calcPreorderVariantPriceTotal(variant),
      calcBrutto(calcPreorderVariantPriceTotal(variant), vatRate),
    ],
    [variant, vatRate],
  );

  const timeRealizationTotal = useMemo(
    () => variant.timeRealization ?? calcVariantTotalRealizationTime(variant),
    [variant],
  );

  if (!orderData) return null;

  return (
    <div className={styles.variantHeader}>
      <div className={styles.column}>
        <FormattedMessage
          defaultMessage="Wariant {number}"
          values={{ number: variant.variantIdx }}
        />
        {orderData.preorderVariantId === variant.id && (
          <Tag
            className={styles.status}
            color={theme.colorBlue}
            data-testid="status"
          >
            <FormattedMessage defaultMessage="Realizowany" />
          </Tag>
        )}
      </div>

      <div className={styles.column}>
        <span>
          <FormattedMessage
            defaultMessage="{netto} netto ({brutto} brutto)"
            values={{
              netto: <CurrencyFormatter value={netto} />,
              brutto: <CurrencyFormatter value={brutto} />,
            }}
          />
        </span>
        {!!timeRealizationTotal && (
          <span>
            {generateWorkingDaysLabel({
              timeRealization: timeRealizationTotal,
            })}
          </span>
        )}
        <CopyToClipboard text={`${orderData?.onlinePaymentUrl}`}>
          <Button onClick={(e) => e.stopPropagation()} type="default">
            <FormattedMessage defaultMessage="Link do płatności" />
          </Button>
        </CopyToClipboard>

        <Link
          to={Routes.PREORDER_DETAILS.replace(
            ':id',
            `${orderData?.preorder?.id ?? ''}`,
          )}
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <FormattedMessage defaultMessage="Podgląd wyceny" />
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default PanelHeader;
