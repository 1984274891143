import { FormattedMessage } from 'react-intl';
import List from 'components/ui/molecules/List';
import { useRefHeightContext } from '../../useRefHeightContext';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import { listSkeletions } from '../../utils';
import CardCommon from '../CardCommon';
import styles from './styles.module.scss';

const SpecializationsCard = () => {
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const specializations = [
    ...new Set(translatorData?.specializations.map(({ name }) => name) ?? []),
  ];
  const { sectionHeight } = useRefHeightContext();
  const maxHeight = sectionHeight ?? 340;
  const items = isTranslatorFetching
    ? listSkeletions
    : specializations.map((name) => ({
        label: name,
        skeletonRows: 1,
      }));

  return (
    <CardCommon
      data-testid="specializationsCard"
      title={<FormattedMessage defaultMessage="Lista specjalizacji" />}
      isLoading={isTranslatorFetching}
      md={8}
      style={{
        maxHeight,
      }}
      className={styles.card}
    >
      <List
        className={styles.list}
        items={items}
        withoutMargin
        isLoading={isTranslatorFetching}
      />
    </CardCommon>
  );
};

export default SpecializationsCard;
