import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { Translator } from 'ducks/translators/types';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { getTranslatorName } from 'utilities/translator';

const { Text } = Typography;

export type TranslatorRow = TableRow & Translator;

type Props = {
  handleSelectTranslator: (id: number) => void;
  selectedTranslatorId: number | null;
};

export const columns = ({
  handleSelectTranslator,
  selectedTranslatorId,
}: Props): ColumnsType<TranslatorRow> => [
  {
    key: 'name',
    dataIndex: 'name',
    title: <FormattedMessage defaultMessage="Imię" />,
    render: (_, translator) => <span>{getTranslatorName(translator)}</span>,
  },
  {
    key: 'ordersInProgress',
    dataIndex: 'ordersInProgress',
    title: <FormattedMessage defaultMessage="Zlecenia w realizacji" />,
    align: 'center',
    render: (_, { orders }) => (
      <Text type="warning">{orders.inProgress ?? 0}</Text>
    ),
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    width: 75,
    render: (_, { id }) => (
      <Button
        onClick={() => handleSelectTranslator(id)}
        disabled={id === selectedTranslatorId}
      >
        <FormattedMessage defaultMessage="Wybierz" />
      </Button>
    ),
  },
];
