import type { AssignAllFilesModalValues } from './config';
import type { FileTranslationType } from 'constants/fileTranslation';
import { Form } from 'antd';
import { useEffect, type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { useAppDispatch } from 'ducks/store';
import { assignOrderAllFiles } from 'ducks/view/orders';
import { booleanToBinary } from 'utilities/boolean';
import { formItems, initialValues } from './config';

const { useForm, useWatch } = Form;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  type: FileTranslationType;
};

const AssignAllFilesModal: FC<Props> = ({ isOpen, onClose, type }) => {
  const dispatch = useAppDispatch();
  const [form] = useForm<AssignAllFilesModalValues>();
  const catId = useWatch('catId', form);

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(({ isPostedit, ...values }) => {
        dispatch(
          assignOrderAllFiles({
            ...values,
            isPostedit: booleanToBinary(isPostedit),
            type,
          }),
        );
        handleClose();
      })
      .catch((err) => err);
  };

  useEffect(() => {
    if (!catId) {
      form.setFieldValue('isPostedit', false);
    }
  }, [catId, form]);

  return (
    <Modal
      open={isOpen}
      title={<FormattedMessage defaultMessage="Zleć wszystkie pliki" />}
      onOk={handleSubmit}
      onCancel={handleClose}
      disableFormStyles
      disableModalStyles
    >
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        className="width-100"
      >
        {formItems({ catId }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default AssignAllFilesModal;
