import type { ReactElement } from 'react';
import { Layout, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Footer: AntFooter } = Layout;

const navItems: ReactElement[] = [];

const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <AntFooter>
      <Row>
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            <FormattedMessage
              defaultMessage="© {year}, {link} wszelkie prawa zastrzeżone"
              values={{
                year,
                link: (
                  <a
                    href="https://gnstudio.pl"
                    className="font-weight-bold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    gnStudio
                  </a>
                ),
              }}
            />
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>
              {navItems.map((NavItem, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={idx}>{NavItem}</li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
};

export default Footer;
