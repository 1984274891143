import type { OrderSendEmailsTranslatorState } from 'ducks/view/orders';
import type { FC } from 'react';
import type { OrderSendEmailsValues } from 'views/Orders/views/OrderSendEmails/config';
import { Form, Row } from 'antd';
import { memo, useEffect } from 'react';
import EmailTemplateTextEditor from 'components/ui/organisms/EmailTemplateTextEditor';
import { useAppDispatch } from 'ducks/store';
import { updateOrderSendEmailsTranslators } from 'ducks/view/orders';
import { initialOrderSendEmailValues } from 'views/Orders/views/OrderSendEmails/config';
import TranslatorPanelSwitches from './components/TranslatoPanelSwitches';
import TranslatorPanelForm from './components/TranslatorPanelForm';

interface Props {
  translatorEmail: OrderSendEmailsTranslatorState;
}

const { useForm } = Form;

const TranslatorPanelContent: FC<Props> = memo(({ translatorEmail }) => {
  const {
    isChecked,
    translatorId,
    selectedEmailTemplate,
    uploadedFiles,
    attachments,
  } = translatorEmail;
  const [form] = useForm<OrderSendEmailsValues>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    form.validateFields();
  }, [form, isChecked]);

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      initialValues={{ ...initialOrderSendEmailValues, ...translatorEmail }}
      onValuesChange={(field) =>
        dispatch(
          updateOrderSendEmailsTranslators({
            translatorId,
            field,
          }),
        )
      }
    >
      <Row gutter={[24, 24]}>
        <TranslatorPanelForm
          form={form}
          translatorId={translatorId}
          isChecked={isChecked}
          selectedEmailTemplate={selectedEmailTemplate}
        />
        <TranslatorPanelSwitches
          translatorId={translatorId}
          uploadedFiles={uploadedFiles}
          attachments={attachments}
        />
        <EmailTemplateTextEditor
          form={form}
          emailTemplate={selectedEmailTemplate}
          isRequired={isChecked}
        />
      </Row>
    </Form>
  );
});

export default TranslatorPanelContent;
