/* eslint-disable react/jsx-props-no-spreading */
import type { EditUserModalValues } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { User } from 'ducks/users/types';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Button, Form, Input } from 'antd';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Loader from 'components/ui/atoms/Loader/Loader';
import Modal from 'components/ui/molecules/Modal/Modal';
import { useEditUserMutation } from 'ducks/users/service';
import { EditIcon } from 'icons';
import styles from '../../styles.module.scss';
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal';
import LoginPermissionsSection from './components/LoginPermissionsSection';
import { editUserModalFormItems } from './config';

interface EditUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  userData: User;
  notify: NotificationInstance;
}

const { Item, useForm } = Form;

const EditUserModal: FC<EditUserModalProps> = ({
  isOpen,
  onClose,
  userData,
  notify,
}) => {
  const [form] = useForm<EditUserModalValues>();
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [editUser, { isLoading, isSuccess }] = useEditUserMutation();

  const handleOnSubmit = async () => {
    if (isLoading || isSuccess) return;

    const { allowTime, ...values } = await form.validateFields();

    try {
      const { id, number } = userData;
      const allowTimeFrom = allowTime?.[0].format('HH:mm') ?? null;
      const allowTimeTo = allowTime?.[1].format('HH:mm') ?? null;

      await editUser({
        ...values,
        id,
        number: number ?? 0,
        allowTimeFrom,
        allowTimeTo,
      }).unwrap();

      notify.success({
        message: (
          <FormattedMessage defaultMessage="Użytkownik został edytowany." />
        ),
      });

      onClose();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania użytkownika." />
        ),
      });
    }
  };

  const initialValues = useMemo((): EditUserModalValues => {
    const { allowWeekdays, allowTimeFrom, allowTimeTo } = userData;

    return {
      ...userData,
      allowWeekdays: allowWeekdays ?? [],
      allowTime: [dayjs(allowTimeFrom, 'HH"mm'), dayjs(allowTimeTo, 'HH"mm')],
    };
  }, [userData]);

  return (
    <>
      <Modal
        data-testid="editUserModal"
        title={<FormattedMessage defaultMessage="Edytuj użytkownika" />}
        isLoading={isLoading}
        open={isOpen}
        onOk={handleOnSubmit}
        onCancel={onClose}
        okText={<FormattedMessage defaultMessage="Edytuj" />}
        disableFormStyles
      >
        <Loader visible={isLoading}>
          <Form form={form} layout="vertical" initialValues={initialValues}>
            <div className={styles.columns}>
              {editUserModalFormItems.map(({ key, ...props }) => (
                <FormItem key={key} {...props} />
              ))}
            </div>
            <Item
              label={<FormattedMessage defaultMessage="Stopka e-mail (HTML)" />}
              name="emailFooter"
            >
              <Input.TextArea rows={4} />
            </Item>
            <LoginPermissionsSection />
            <Button
              icon={<EditIcon />}
              onClick={() => setIsChangePasswordModalOpen(true)}
            >
              <FormattedMessage defaultMessage="Zmień hasło" />
            </Button>
          </Form>
        </Loader>
      </Modal>
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={() => setIsChangePasswordModalOpen(false)}
        userData={userData}
        notify={notify}
      />
    </>
  );
};

export default EditUserModal;
