import type { FC } from 'react';
import { Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import ClientSelectorOption from '../ClientSelectorOption';
import styles from './styles.module.scss';

type Option = { value: number; label: string };

type Props = {
  options: Option[];
  selectedOption: Option | null;
  isFetching: boolean;
  onSelect: (option: Option) => void;
};

const ClientSelectorDropdown: FC<Props> = ({
  options,
  selectedOption,
  isFetching,
  onSelect,
}) => {
  return (
    <div className={styles.optionsWrapper}>
      {isFetching && <Spin size="small" />}
      {!isFetching && !options.length && (
        <FormattedMessage defaultMessage="Zacznij wpisywać dane klienta, aby znaleźć go w systemie" />
      )}
      {!isFetching &&
        options.map(({ value, label }) => (
          <ClientSelectorOption
            key={value}
            value={value}
            label={label}
            onSelect={onSelect}
            isSelected={value === selectedOption?.value}
          />
        ))}
    </div>
  );
};

export default ClientSelectorDropdown;
