import type { FormItemRecord } from './config';
import { Form, Input } from 'antd';
import cn from 'classnames';
import styles from './styles.module.scss';

const { Item } = Form;

type Props<T> = Omit<FormItemRecord<T>, 'key'> & {
  className?: string;
  withoutMargin?: boolean;
};

const FormItem = <T,>({
  label = '',
  rules,
  name,
  additionalItemProps,
  additionalInputProps,
  component,
  className,
  hidden = false,
  withoutMargin = false,
}: Props<T>) => {
  return hidden ? null : (
    <Item
      label={label}
      rules={rules}
      name={name}
      className={cn(styles.formItem, className, {
        [styles.withoutMargin]: withoutMargin,
      })}
      {...additionalItemProps}
    >
      {component || (
        <Input data-testid={`${name}Input`} {...additionalInputProps} />
      )}
    </Item>
  );
};

export default FormItem;
