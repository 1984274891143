export enum SmsTag {
  SMS_TEMPLATES = 'smsTemplates',
  SMS_SEND = 'sms',
}

export type GetSmsTemplatesResponse = SmsTemplate[];

export type GetSmsTemplateByIdResponse = SmsTemplate;

export type GetSmsTemplateByIdRequest = {
  id: string;
};

export type EditSmsTemplateByIdRequest = {
  id: string;
  title: string;
  content: string;
};

export type AddSmsTemplateByIdRequest = {
  title: string;
  content: string;
};

export type SmsTemplate = {
  id: number;
  title: string;
  content: string;
};

export type SmsSendRequest = {
  phone: string;
  message: string;
};
