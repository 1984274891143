import { Col, Empty, Row, Space, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import Notes from 'components/ui/organisms/Notes';
import { NoteType } from 'ducks/notes/types';
import BasicInfoCard from './components/BasicInfoCard';
import CatToolsCard from './components/CatToolsCard';
import ContactCard from './components/ContactCard';
import CorrespondenceCard from './components/CorrespondenceCard';
import InvoiceCard from './components/InvoiceCard';
import OrderList from './components/OrderList';
import PriceTable from './components/PriceTable';
import RateCard from './components/RateCard';
import SkillsCard from './components/SkillsCard';
import SpecializationsCard from './components/SpecializationsCard';
import StatusCard from './components/StatusCard';
import TranslatorHeader from './components/TranslatorHeader';
import TranslatorQualifications from './components/TranslatorQualifications';
import TranslatorTypeCard from './components/TranslatorTypeCard';
import { RefHeightProvider } from './useRefHeightContext';
import { useTranslatorProfileServices } from './useTranslatorProfileServices';

const TranslatorProfile = () => {
  const [notify, contextHolder] = notification.useNotification();
  const { isTranslatorEmpty, translatorId } = useTranslatorProfileServices();

  return (
    <div className="layout-content mb-24" data-testid="translatorProfile">
      {contextHolder}
      {isTranslatorEmpty ? (
        <Empty
          data-testid="translatorProfileEmpty"
          description={
            <FormattedMessage defaultMessage="Brak danych o tłumaczu" />
          }
        />
      ) : (
        <Space direction="vertical" size="large" className="width-100">
          <TranslatorHeader notify={notify} />
          <RefHeightProvider>
            <Row gutter={[24, 24]} align="top">
              <ContactCard />
              <SpecializationsCard />
              <SkillsCard />
            </Row>
          </RefHeightProvider>
          <Notes
            notify={notify}
            objectId={translatorId}
            noteType={NoteType.TRANSLATORS}
          />
          <PriceTable />
          <Row gutter={[24, 24]} align="top">
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <OrderList />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Row
                gutter={[24, 24]}
                style={{ marginLeft: 0, marginRight: 0 }}
                align="top"
              >
                <RateCard />
                <StatusCard notify={notify} />
              </Row>
            </Col>
          </Row>
          <Row gutter={[24, 24]} align="top">
            <BasicInfoCard />
            <CatToolsCard />
            <TranslatorTypeCard />
          </Row>
          <Row gutter={[24, 24]} align="top">
            <InvoiceCard />
            <CorrespondenceCard />
          </Row>
          <TranslatorQualifications />
        </Space>
      )}
    </div>
  );
};

export default TranslatorProfile;
