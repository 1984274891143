import type { GetInvoicesDataResponse } from 'ducks/invoices/types';
import type { IntlShape } from 'react-intl';
import { DefaultValue } from 'ducks/types';

export const generateInvoiceSettingsComment = (
  intl: IntlShape,
  orders: GetInvoicesDataResponse['orders'],
) =>
  orders
    .map(
      ({
        number,
        client: {
          name,
          email1,
          email2,
          emailDefault,
          phone1,
          phone2,
          phoneDefault,
        },
      }) =>
        [
          intl.formatMessage(
            {
              defaultMessage: 'Faktura do zlecenia nr: {number}',
            },
            { number },
          ),
          intl.formatMessage({
            defaultMessage: 'Osoba składająca zamówienie:',
          }),
          intl.formatMessage(
            {
              defaultMessage: '• {name} ({email} / {phone})',
            },
            {
              name,
              email: emailDefault === DefaultValue.FIRST ? email1 : email2,
              phone: phoneDefault === DefaultValue.FIRST ? phone1 : phone2,
            },
          ),
        ].join('\n'),
    )
    .join('\n\n');
