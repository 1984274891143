export enum UserRole {
  ADMIN = 1,
  EMPLOYEE = 2,
  BOOKKEEPING = 3,
}

export enum UsersTag {
  CurrentUser = 'CurrentUser',
  Users = 'Users',
}

export type CurrentUser = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  number: number;
  role: UserRole;
  is_active: boolean;
  allow_time_from: string | null;
  allow_time_to: string | null;
  allow_weekdays: number[] | null;
  email_footer: string | null;
  date_last_login: string;
};

export type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  number: number;
  role: UserRole;
  isActive: boolean;
  allowTimeFrom: string | null;
  allowTimeTo: string | null;
  allowWeekdays: number[] | null;
  emailFooter: string | null;
  dateLastLogin: string;
  createdAt: string;
};

export type GetUserDetailsRequest = { id: number };

export type AddUserRequest = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  role: UserRole;
  isActive: boolean;
  emailFooter: string | null;
};

export type EditUserRequest = Omit<
  AddUserRequest,
  'password' | 'passwordConfirmation'
> & {
  id: number;
  number: number;
  allowWeekdays: number[] | null;
  allowTimeFrom: string | null;
  allowTimeTo: string | null;
};

export type ChangeUserPasswordRequest = {
  id: number;
  password: string;
  passwordConfirmation: string;
};
