/* eslint-disable no-param-reassign */
import type {
  AddOrderFileLangPairPayload,
  AssignOrderAllFilesPayload,
  ChangeOrderFileSpecializationsPayload,
  OrderSendEmailsTranslatorState,
  OrdersSliceState,
  SetOrderSendEmailsTranslatorsPayload,
  ToggleOrderSendClientEmailUploadedFilesPayload,
  ToggleOrderSendEmailsAttachmentsPayload,
  ToggleOrderSendEmailsUploadedFilesPayload,
  UpdateOrderFilesPayload,
  UpdateOrderSendEmailsTranslatorsPayload,
  UpdateOrderServicesPayload,
} from './type';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Order, OrderFileLang } from 'ducks/orders/types';
import type { GetPreorderVariantsResponse } from 'ducks/preorders/types';
import dayjs from 'dayjs';
import { createSlice } from '@reduxjs/toolkit';
import { getNestedKeyValue } from 'utilities/object';
import {
  generateOrderFiles,
  generateOrderServices,
  getInitialOrderFileLangValues,
  getInitialOrderSendEmailsTranslatorValues,
} from './utils';

const initialState: OrdersSliceState = {
  isEditable: false,
  isAssigned: true,
  isImportant: false,
  status: null,
  vatRate: null,
  orderFiles: [],
  orderServices: [],
  variants: [],
  assign: {
    results: null,
    addLanguagesModal: {
      isOpen: false,
      orderFileId: null,
    },
  },
  sendEmails: {
    translatorEmails: [],
  },
  sendClientEmail: {
    emailTemplate: '',
    attachments: [],
    uploadedFiles: [],
  },
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrderIsEditable: (
      state,
      { payload }: PayloadAction<OrdersSliceState['isEditable']>,
    ) => {
      state.isEditable = payload;
    },
    setOrderIsAssigned: (
      state,
      { payload }: PayloadAction<OrdersSliceState['isAssigned']>,
    ) => {
      state.isAssigned = payload;
    },
    setOrderIsImportant: (
      state,
      { payload }: PayloadAction<Order['isImportant']>,
    ) => {
      state.isImportant = payload;
    },
    setOrderVariants: (
      state,
      { payload }: PayloadAction<GetPreorderVariantsResponse>,
    ) => {
      state.variants = payload;
    },
    setOrderFilesAndServices: (state, { payload }: PayloadAction<Order>) => {
      const {
        orderFiles,
        orderFileLangs,
        orderServices,
        orderServiceValues,
        isImportant,
        status,
        vatRate,
      } = payload;

      const generatedOrderFiles = generateOrderFiles(
        orderFiles,
        orderFileLangs,
      );

      const generatedOrderServices = generateOrderServices(
        orderServices,
        orderServiceValues,
      );

      state.orderFiles = generatedOrderFiles;
      state.orderServices = generatedOrderServices;
      state.isImportant = isImportant;
      state.status = status;
      state.vatRate = vatRate;
    },
    /**
     * Example of field:
     * [orderFileId, orderFileLangId, { catId: 1 }]
     */
    updateOrderFiles: (
      state,
      { payload: { field } }: PayloadAction<UpdateOrderFilesPayload>,
    ) => {
      const [orderFileId, value] = Object.entries(field)[0];

      state.orderFiles = state.orderFiles.map((orderFile) => {
        if (orderFile.id.toString() !== orderFileId) return orderFile;

        return {
          ...orderFile,
          orderFileLangs: orderFile.orderFileLangs.map((orderFileLang) => {
            const [orderFileLangId] = getNestedKeyValue(value);

            if (orderFileLang.id.toString() !== orderFileLangId)
              return orderFileLang;

            const [, orderFileLangField] = getNestedKeyValue(value);
            const [nestedKey, nestedValue] =
              getNestedKeyValue(orderFileLangField);

            switch (nestedKey) {
              case 'catId': {
                const isPosteditValue =
                  nestedValue === null ? 0 : orderFileLang.isPostedit;

                return {
                  ...orderFileLang,
                  catId: nestedValue ? Number(nestedValue) : null,
                  isPostedit: isPosteditValue,
                  translatorId: null,
                  isAccepted: false,
                };
              }
              case 'isPostedit': {
                const isPostedit = Number(
                  nestedValue,
                ) as OrderFileLang['isPostedit'];

                return { ...orderFileLang, isPostedit, translatorId: null };
              }
              case 'dateRealization': {
                return {
                  ...orderFileLang,
                  dateRealization: dayjs(nestedValue as string).format(),
                };
              }
              default:
                return { ...orderFileLang, ...value[orderFileLangId] };
            }
          }),
        };
      });
    },
    /**
     * Example of field:
     * [orderServiceId, { priceNetto: 500 }]
     */
    updateOrderServices: (
      state,
      { payload: { field } }: PayloadAction<UpdateOrderServicesPayload>,
    ) => {
      const [orderServiceId, value] = Object.entries(field)[0];

      state.orderServices = state.orderServices.map((orderService) => {
        if (orderService.id.toString() !== orderServiceId) return orderService;

        const [nestedKey, nestedValue] = getNestedKeyValue(value);

        if (nestedKey === 'priceNetto') {
          return {
            ...orderService,
            priceNetto: nestedValue === null ? 0 : Number(nestedValue),
          };
        }

        return { ...orderService, ...value };
      });
    },
    changeOrderFileSpecializations: (
      state,
      { payload }: PayloadAction<ChangeOrderFileSpecializationsPayload>,
    ) => {
      const { orderFileId, specializations } = payload;

      const orderFile = state.orderFiles.find(({ id }) => id === orderFileId);

      if (orderFile) {
        orderFile.specializations = specializations;
      }
    },
    addOrderFileLangPair: (
      state,
      { payload }: PayloadAction<AddOrderFileLangPairPayload>,
    ) => {
      const { orderFileId } = payload;

      const orderFile = state.orderFiles.find(({ id }) => id === orderFileId);

      if (!orderFile) return;

      orderFile.orderFileLangs.push(getInitialOrderFileLangValues(payload));
    },
    assignOrderAllFiles: (
      state,
      {
        payload: { type, catId, isPostedit, specializations },
      }: PayloadAction<AssignOrderAllFilesPayload>,
    ) => {
      const orderFilesByType = state.orderFiles.filter(
        (orderFile) => orderFile.type === type,
      );

      orderFilesByType.forEach((orderFile) => {
        orderFile.specializations = specializations ? [specializations] : [];

        orderFile.orderFileLangs.forEach((orderFileLang) => {
          orderFileLang.catId = catId;
          orderFileLang.isPostedit = isPostedit;
        });
      });
    },
    changeOrderAssignResults: (
      state,
      { payload }: PayloadAction<OrdersSliceState['assign']['results']>,
    ) => {
      state.assign.results = payload;
    },
    setOrderAssignAddLanguagesModal: (
      state,
      {
        payload,
      }: PayloadAction<OrdersSliceState['assign']['addLanguagesModal']>,
    ) => {
      state.assign.addLanguagesModal = payload;
    },
    setOrderSendEmailsTranslators: (
      state,
      {
        payload: { order, onInitialValuesChange },
      }: PayloadAction<SetOrderSendEmailsTranslatorsPayload>,
    ) => {
      const groupedTranslators: OrderSendEmailsTranslatorState[] =
        order.orderFileLangs.reduce<OrderSendEmailsTranslatorState[]>(
          (acc, item) => {
            const { langFrom, langTo, orderFileId, translator } = item;

            const existingGroup = acc.find(
              ({ translatorId }) => translatorId === item.translatorId,
            );

            if (existingGroup) {
              existingGroup.langs.push({
                langFrom,
                langTo,
              });

              return acc;
            }

            if (translator) {
              const files = order.orderFiles
                .filter(({ id }) => id === orderFileId)
                .map(({ file }) => file);

              acc.push(
                getInitialOrderSendEmailsTranslatorValues({
                  translator,
                  files,
                  langFrom,
                  langTo,
                  values: onInitialValuesChange(order),
                }),
              );
            }

            return acc;
          },
          [],
        );

      state.sendEmails.translatorEmails = groupedTranslators;
    },
    updateOrderSendEmailsTranslators: (
      state,
      { payload }: PayloadAction<UpdateOrderSendEmailsTranslatorsPayload>,
    ) => {
      const { translatorId, field } = payload;

      state.sendEmails.translatorEmails = state.sendEmails.translatorEmails.map(
        (translatorEmail) =>
          translatorEmail.translatorId === translatorId
            ? { ...translatorEmail, ...field }
            : translatorEmail,
      );
    },
    toggleOrderSendEmailsAttachments: (
      state,
      { payload }: PayloadAction<ToggleOrderSendEmailsAttachmentsPayload>,
    ) => {
      const translator = state.sendEmails.translatorEmails.find(
        ({ translatorId }) => translatorId === payload.translatorId,
      );

      if (!translator) return;

      const isAlreadyExists = translator.attachments.some(
        (attachment) => attachment === payload.id,
      );

      if (isAlreadyExists) {
        translator.attachments = translator.attachments.filter(
          (attachment) => attachment !== payload.id,
        );

        return;
      }

      translator.attachments.push(payload.id);
    },
    toggleOrderSendEmailsUploadedFiles: (
      state,
      { payload }: PayloadAction<ToggleOrderSendEmailsUploadedFilesPayload>,
    ) => {
      const { file } = payload;

      const translator = state.sendEmails.translatorEmails.find(
        ({ translatorId }) => translatorId === payload.translatorId,
      );

      if (!translator) return;

      const isAlreadyAdded = translator.uploadedFiles.some(
        ({ uid }) => uid === file.uid,
      );

      if (isAlreadyAdded) {
        translator.uploadedFiles = translator.uploadedFiles.filter(
          ({ uid }) => uid !== file.uid,
        );

        return;
      }

      translator.uploadedFiles.push(file);
    },
    setOrderSendClientEmailTemplate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.sendClientEmail.emailTemplate = payload;
    },
    updateOrderSendClientEmailsAttachments: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      const isAlreadyAdded = state.sendClientEmail.attachments.some(
        (atch) => atch === payload,
      );

      if (isAlreadyAdded) {
        state.sendClientEmail.attachments =
          state.sendClientEmail.attachments.filter((atch) => atch !== payload);

        return;
      }

      state.sendClientEmail.attachments.push(payload);
    },
    toggleOrderSendClientEmailUploadedFiles: (
      state,
      {
        payload,
      }: PayloadAction<ToggleOrderSendClientEmailUploadedFilesPayload>,
    ) => {
      const isAlreadyAdded = state.sendClientEmail.uploadedFiles.some(
        ({ uid }) => uid === payload.uid,
      );

      if (isAlreadyAdded) {
        state.sendClientEmail.uploadedFiles =
          state.sendClientEmail.uploadedFiles.filter(
            ({ uid }) => uid !== payload.uid,
          );

        return;
      }

      state.sendClientEmail.uploadedFiles.push(payload);
    },
    resetOrdersState: () => initialState,
  },
});

export const {
  setOrderIsEditable,
  setOrderIsAssigned,
  setOrderVariants,
  setOrderFilesAndServices,
  setOrderIsImportant,
  changeOrderAssignResults,
  updateOrderFiles,
  updateOrderServices,
  changeOrderFileSpecializations,
  addOrderFileLangPair,
  assignOrderAllFiles,
  setOrderAssignAddLanguagesModal,
  setOrderSendEmailsTranslators,
  updateOrderSendEmailsTranslators,
  toggleOrderSendEmailsAttachments,
  toggleOrderSendEmailsUploadedFiles,
  setOrderSendClientEmailTemplate,
  updateOrderSendClientEmailsAttachments,
  toggleOrderSendClientEmailUploadedFiles,
  resetOrdersState,
} = ordersSlice.actions;

export default ordersSlice.reducer;
