/* eslint-disable react/jsx-props-no-spreading */
import type { AddTranslatorModalValues } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { useAddTranslatorMutation } from 'ducks/translators/service';
import { Routes } from 'views/Translators/Routing';
import { inputs } from './config';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  notify: NotificationInstance;
}

const { useForm } = Form;

const AddTranslatorModal: FC<Props> = ({ isOpen, onClose, notify }) => {
  const [form] = useForm<AddTranslatorModalValues>();
  const navigate = useNavigate();
  const [addTranslator, { isLoading }] = useAddTranslatorMutation();

  const handleOnSubmit = async () => {
    try {
      const values = await form.validateFields();
      const translatorId = await addTranslator(values).unwrap();

      onClose();
      navigate(
        Routes.TRANSLATOR_PROFILE.replace(':id', translatorId.toString()),
      );
      form.resetFields();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania tłumacza." />
        ),
      });
    }
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Dodaj tłumacza" />}
      isLoading={isLoading}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={onClose}
      okText={<FormattedMessage defaultMessage="Dodaj" />}
    >
      <Form form={form} layout="vertical" autoComplete="off">
        {inputs.map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default AddTranslatorModal;
