import type { FC } from 'react';
import { Space } from 'antd';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import Empty from 'components/ui/atoms/Empty';
import { useAppSelector } from 'ducks/store';
import OrderTranslationsTable from './components/OrderTranslationsTable';
import styles from './styles.module.scss';

type Props = {
  isLoading: boolean;
  showAssignAllFiles?: boolean;
};

const OrderFilesAndServicesTable: FC<Props> = ({
  isLoading,
  showAssignAllFiles = false,
}) => {
  const { isEditable, orderFiles, orderServices } = useAppSelector(
    ({ orders }) => ({
      isEditable: orders.isEditable,
      orderFiles: orders.orderFiles,
      orderServices: orders.orderServices,
    }),
    shallowEqual,
  );
  const isOrderTranslationsEmpty =
    !orderFiles.length && !orderServices.length && !isLoading;

  if (isOrderTranslationsEmpty) {
    return (
      <Empty description={<FormattedMessage defaultMessage="Brak zleceń" />} />
    );
  }

  return (
    <Space
      className={cn(styles.wrapper, { [styles.wrapperEditable]: isEditable })}
      direction="vertical"
      size="large"
    >
      <OrderTranslationsTable
        isLoading={isLoading}
        showAssignAllFiles={showAssignAllFiles}
      />
    </Space>
  );
};

export default OrderFilesAndServicesTable;
