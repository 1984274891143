import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import HeaderCard from 'components/ui/organisms/HeaderCard';
import { headerCols, skeletonHeaderCols } from './config';

type Props = {
  startChildren?: ReactNode;
  children?: ReactNode;
  cols?: HeaderCardCol[];
  isLoading: boolean;
};

const InvoicesHeader: FC<Props> = ({
  startChildren,
  children,
  cols = [],
  isLoading,
}) => {
  const primaryCols = useMemo(
    () => (isLoading ? skeletonHeaderCols : headerCols()),
    [isLoading],
  );

  return (
    <HeaderCard
      startChildren={startChildren}
      cols={[...primaryCols, ...cols]}
      isLoading={isLoading}
    >
      {children}
    </HeaderCard>
  );
};

export default InvoicesHeader;
