import { Checkbox, Col, Form, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useGetTranslatorsCatsQuery } from 'ducks/translators/service';

const { Item } = Form;

const CatSection = () => {
  const { data = [] } = useGetTranslatorsCatsQuery();
  const mainCats = data.filter(({ isMain }) => isMain);

  return (
    <Col span={24} md={12} lg={12} xl={12}>
      <h6>
        <FormattedMessage defaultMessage="CAT" />
      </h6>
      <Item name="cats">
        <Checkbox.Group>
          <Row>
            {mainCats.map(({ id, name }) => (
              <Col key={id} span={12}>
                <Checkbox value={id}>{name}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Item>
    </Col>
  );
};

export default CatSection;
