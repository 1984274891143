import { Form, InputNumber } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import { MAX_VAT_NUMBER } from 'constants/formInputLengths';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setPreorderVatRate } from 'ducks/view/preorders';

const BasicDataVariantSection = () => {
  const dispatch = useAppDispatch();
  const vatRate = useAppSelector(({ preorders }) => preorders.vatRate);

  const handleSetPreorderVatRate = (value: number) =>
    dispatch(setPreorderVatRate(value));

  return (
    <CardCommon
      data-testid="invoiceSection"
      title={<FormattedMessage defaultMessage="Dane wariantu" />}
      xs={24}
      md={8}
    >
      <Form layout="vertical" autoComplete="off">
        <div className="gridForm">
          <FormItem
            label={<FormattedMessage defaultMessage="VAT:" />}
            component={
              <InputNumber
                data-testid="vatRateInput"
                value={vatRate}
                onChange={(value) => handleSetPreorderVatRate(value ?? 0)}
                min={0}
                max={MAX_VAT_NUMBER}
                addonAfter="%"
              />
            }
          />
        </div>
      </Form>
    </CardCommon>
  );
};

export default BasicDataVariantSection;
