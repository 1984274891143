import type { FormInstance } from 'antd';
import type { FC } from 'react';
import type { InvoiceSendValues } from 'views/Invoices/view/InvoiceSend/config';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import EmailTemplateTextEditor from 'components/ui/organisms/EmailTemplateTextEditor';
import { DayjsFormat } from 'constants/dayjsFormats';
import { NO_DATA } from 'constants/strings';
import {
  mappedTemplateSnippets,
  TemplateSnippet,
  templateSnippets,
} from 'constants/template';
import { useAppSelector } from 'ducks/store';
import { formatPrice } from 'utilities/price';
import { useInvoiceSendServices } from 'views/Invoices/view/InvoiceSend';

type SnippetValues = Record<TemplateSnippet, string>;

type Props = {
  form: FormInstance<InvoiceSendValues>;
};

const InvoiceEmailTemplateEdtiorSection: FC<Props> = ({ form }) => {
  const emailTemplate = useAppSelector(
    ({ invoices }) => invoices.emailTemplate,
  );
  const { invoiceVatData, isInvoiceVatFetching } = useInvoiceSendServices();

  const snippetValues = useMemo((): SnippetValues | undefined => {
    if (!invoiceVatData) return undefined;

    const { number, filename, dateSent, priceNetto, priceBrutto } =
      invoiceVatData;

    const netto = priceNetto ? formatPrice(priceNetto) : NO_DATA;
    const brutto = priceBrutto ? formatPrice(priceBrutto) : NO_DATA;
    const realizationDate = dayjs(dateSent).format(DayjsFormat.DATE);

    return {
      ...mappedTemplateSnippets,
      [TemplateSnippet.NUMBER]: number,
      [TemplateSnippet.FILE_LIST]: filename,
      [TemplateSnippet.NETTO_VALUE]: netto,
      [TemplateSnippet.BRUTTO_VALUE]: brutto,
      [TemplateSnippet.REALIZATION_DATE]: realizationDate,
    };
  }, [invoiceVatData]);

  const modifiedEmailTemplate = useMemo(() => {
    if (!snippetValues) return emailTemplate;

    return templateSnippets.reduce(
      (accumulator, currentValue) =>
        accumulator.replaceAll(currentValue, snippetValues[currentValue]),
      emailTemplate,
    );
  }, [emailTemplate, snippetValues]);

  return (
    <EmailTemplateTextEditor
      form={form}
      emailTemplate={modifiedEmailTemplate}
      isLoading={isInvoiceVatFetching}
    />
  );
};

export default InvoiceEmailTemplateEdtiorSection;
