import { FormattedMessage } from 'react-intl';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import List from 'components/ui/molecules/List';
import { useGetTranslatorsCatsQuery } from 'ducks/translators/service';
import { CAT_PHRASE_ID } from 'views/Translators/config';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import { listSkeletions } from '../../utils';
import CardCommon from '../CardCommon';
import { generateOtherCatObject } from './utils';

const CatToolsCard = () => {
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const { data: catsData = [], isLoading: isCatsLoading } =
    useGetTranslatorsCatsQuery();
  const selectedCatsTools = translatorData?.catsTools ?? [];
  const catsTools = catsData.filter(
    ({ id, isMain }) => !isMain || id === CAT_PHRASE_ID,
  );
  const otherCat = translatorData?.catOther;
  const isLoading = isTranslatorFetching || isCatsLoading;

  const otherCatObject = otherCat
    ? generateOtherCatObject({ cat: otherCat, id: catsTools.length + 1 })
    : undefined;

  const combinedSelectedCats = otherCatObject
    ? [...selectedCatsTools, otherCatObject]
    : selectedCatsTools;

  const combinedCatsTools = otherCatObject
    ? [...catsTools, otherCatObject]
    : catsTools;

  return (
    <CardCommon
      title={<FormattedMessage defaultMessage="Znajomość narzędzi CAT" />}
      isLoading={isLoading}
      data-testid="catToolsCard"
    >
      <List
        items={
          isLoading
            ? listSkeletions
            : combinedCatsTools.map(({ id, name }) => ({
                label: name,
                component: (
                  <CircleCheckbox
                    value={combinedSelectedCats.some((tool) => tool.id === id)}
                  />
                ),
                skeletonRows: 1,
              }))
        }
        withoutMargin
        isLoading={isLoading}
      />
    </CardCommon>
  );
};

export default CatToolsCard;
