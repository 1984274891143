import { FormattedMessage } from 'react-intl';
import theme from 'components/layout/Main/styles/values.scss';

type StatsArgs = {
  ordersCount: number | undefined;
  preordersCount: number | undefined;
  complaintsCount: number | undefined;
  ordersInProgressCount: number | undefined;
};

export const stats = ({
  ordersCount,
  preordersCount,
  complaintsCount,
  ordersInProgressCount,
}: StatsArgs) => [
  {
    id: 0,
    stat: preordersCount,
    title: <FormattedMessage defaultMessage="Liczba wycen" />,
    statColor: '#000',
  },
  {
    id: 1,
    stat: ordersCount,
    title: <FormattedMessage defaultMessage="Liczba zleceń" />,
    statColor: theme.colorLime,
  },
  {
    id: 2,
    stat: ordersInProgressCount,
    title: <FormattedMessage defaultMessage="Liczba zleceń w toku" />,
    statColor: theme.colorCandleLight,
  },
  {
    id: 3,
    stat: complaintsCount,
    title: <FormattedMessage defaultMessage="Reklamacje" />,
    statColor: theme.colorTorchRed,
  },
];
