import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useInvoiceAddServices } from '../../useInvoiceAddServices';
import { formItems } from './config';

const ClientBillingSection = () => {
  const { invoiceData, isInvoiceDataFetching } = useInvoiceAddServices();

  return (
    <CardCommon
      title={<FormattedMessage defaultMessage="Dane rozliczeniowe klienta" />}
      isLoading={isInvoiceDataFetching}
      xs={24}
      lg={14}
    >
      <Form layout="vertical">
        <div className="gridFormLarge">
          {invoiceData?.client &&
            formItems({ client: invoiceData.client }).map(
              ({ key, ...props }) => <FormItem key={key} {...props} />,
            )}
        </div>
      </Form>
    </CardCommon>
  );
};

export default ClientBillingSection;
