import type {
  AddPaymentRequest,
  AddPaymentResponse,
  AddPaymentTransactionsRequest,
  GetPaymentIndividualsRequest,
  GetPaymentIndividualsResponse,
  GetPaymentTransactionsRequest,
  GetPaymentTransactionsResponse,
  GetPaymentsRequest,
  GetPaymentsResponse,
  ParsePaymentFileRequest,
  ParsePaymentFileResponse,
  UpdatePaymentNoteRequest,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import { formatFilterParameter, formatSortParameter } from 'utilities/api';
import { convertKeysToSnakeCase } from 'utilities/object';
import { PaymentsTag } from './types';

export enum Endpoint {
  GetPayments = '/payments/:paymentObject/:id',
  AddPayment = '/payments/:paymentObject/:id',
  GetPaymentTransactions = '/payments/transactions',
  AddPaymentTransactions = '/payments/transactions',
  ParsePaymentFile = '/payments/parse',
  GetPaymentIndividuals = '/payments/individuals',
  UpdatePaymentNote = '/payments/:paymentObject/:id/note',
}

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: baseQuery(),
  tagTypes: [
    PaymentsTag.Payments,
    PaymentsTag.PaymentTransactions,
    PaymentsTag.PaymentIndividuals,
  ],
  endpoints: (build) => ({
    getPayments: build.query<GetPaymentsResponse, GetPaymentsRequest>({
      query: ({ paymentObject, id }) => ({
        url: Endpoint.GetPayments.replace(
          ':paymentObject',
          paymentObject,
        ).replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: [PaymentsTag.Payments],
    }),
    addPayment: build.mutation<AddPaymentResponse, AddPaymentRequest>({
      query: ({ paymentObject, id, ...data }) => ({
        url: Endpoint.AddPayment.replace(
          ':paymentObject',
          paymentObject,
        ).replace(':id', id.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) => (error ? [] : [PaymentsTag.Payments]),
    }),
    getPaymentTransactions: build.query<
      GetPaymentTransactionsResponse,
      GetPaymentTransactionsRequest
    >({
      query: ({ page, filter, searchTerm }) => ({
        url: Endpoint.GetPaymentTransactions,
        method: 'GET',
        params: {
          page,
          s: searchTerm,
          filter: formatFilterParameter(filter),
        },
      }),
      providesTags: [PaymentsTag.PaymentTransactions],
    }),
    addPaymentTransactions: build.mutation<void, AddPaymentTransactionsRequest>(
      {
        query: (data) => {
          return {
            url: Endpoint.AddPaymentTransactions,
            method: 'POST',
            data,
          };
        },
        invalidatesTags: (_, error) =>
          error ? [] : [PaymentsTag.PaymentTransactions],
      },
    ),
    parsePaymentFile: build.mutation<
      ParsePaymentFileResponse,
      ParsePaymentFileRequest
    >({
      query: ({ file }) => {
        const formData = new FormData();

        formData.append('file', file, file.name);

        return {
          url: Endpoint.ParsePaymentFile,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        };
      },
    }),
    getPaymentIndividuals: build.query<
      GetPaymentIndividualsResponse,
      GetPaymentIndividualsRequest
    >({
      query: ({ page, filter, sort }) => ({
        url: Endpoint.GetPaymentIndividuals,
        method: 'GET',
        params: {
          page,
          filter: formatFilterParameter(filter),
          sort: formatSortParameter(sort),
        },
      }),
      providesTags: [PaymentsTag.PaymentIndividuals],
    }),
    updatePaymentNote: build.mutation<void, UpdatePaymentNoteRequest>({
      query: ({ id, paymentObject, ...data }) => ({
        url: Endpoint.UpdatePaymentNote.replace(
          ':paymentObject',
          paymentObject,
        ).replace(':id', id.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [PaymentsTag.Payments, PaymentsTag.PaymentIndividuals],
    }),
  }),
});

export const {
  useGetPaymentsQuery,
  useAddPaymentMutation,
  useGetPaymentTransactionsQuery,
  useAddPaymentTransactionsMutation,
  useParsePaymentFileMutation,
  useGetPaymentIndividualsQuery,
  useUpdatePaymentNoteMutation,
} = paymentsApi;
