import type { VariantServiceRow } from '../../config';
import type { PreorderVariantServiceSlice } from 'ducks/view/preorders';
import type { FC } from 'react';
import { Checkbox } from 'antd';
import FormItem from 'components/ui/atoms/FormItem';
import { useAppSelector } from 'ducks/store';

type Props = Pick<VariantServiceRow, 'id' | 'isActive'>;

const ActiveCol: FC<Props> = ({ id, isActive }) => {
  const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);

  return isEditable ? (
    <FormItem<PreorderVariantServiceSlice>
      name={[id as number, 'isActive']}
      component={<Checkbox data-testid="isActiveCheckbox" />}
      additionalItemProps={{
        initialValue: isActive,
        valuePropName: 'checked',
      }}
    />
  ) : null;
};

export default ActiveCol;
