import type { FilterBy, ListItem } from './config';
import {
  Button,
  Card,
  Form,
  Skeleton,
  Space,
  Typography,
  notification,
} from 'antd';
import { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/ui/atoms/Loader';
import FormList from 'components/ui/molecules/FormList';
import { EditIcon, PlusIcon } from 'icons';
import AddLanguagePairModal from './components/AddLanguagePairModal';
import EditPriceListNameModal from './components/EditPriceNameModal';
import GeneralPriceChangeModal from './components/GeneralPriceChangeModal';
import GenerateLanguagePairsModal from './components/GenerateLanguagePairsModal';
import PriceListDetailsToolbar from './components/PriceListDetailsToolbar';
import { formListItems, initialFilters } from './config';
import styles from './styles.module.scss';
import useManagePriceList from './useManagePriceList';

const { useForm } = Form;
const { Title } = Typography;

const PriceDetails = () => {
  const [form] = useForm<ListItem>();
  const [notify, contextHolder] = notification.useNotification();
  const [isEditPriceListNameModalOpen, setIsEditPriceListNameModalOpen] =
    useState(false);
  const [isAddLanguagePairModalOpen, setIsAddLanguagePairModalOpen] =
    useState(false);
  const [
    isGenerateLanguagePairsModalOpen,
    setIsGenerateLanguagePairsModalOpen,
  ] = useState(false);
  const [isGeneralPriceChangeModalOpen, setIsGeneralPriceChangeModalOpen] =
    useState(false);
  const {
    data,
    listItems,
    isLoading,
    isInitialLoading,
    isDeleteLoading,
    isChanged,
    handleAdd,
    handleEdit,
    handleDelete,
    handleSave,
    handleGeneralPriceChange,
  } = useManagePriceList(notify);
  const [filterBy, setFilterBy] = useState<FilterBy>(initialFilters);

  const filteredListItems = useMemo(() => {
    if (!Object.values(filterBy).some((item) => item.length)) return listItems;

    return listItems.filter(
      ({ langFrom, langTo }) =>
        filterBy.langs.includes(langFrom) || filterBy.langs.includes(langTo),
    );
  }, [listItems, filterBy]);

  return (
    <div className="layout-content mb-24">
      {data && (
        <EditPriceListNameModal
          isOpen={isEditPriceListNameModalOpen}
          onClose={() => setIsEditPriceListNameModalOpen(false)}
          notify={notify}
          items={data.items}
        />
      )}
      <GenerateLanguagePairsModal
        isOpen={isGenerateLanguagePairsModalOpen}
        onClose={() => setIsGenerateLanguagePairsModalOpen(false)}
        listItems={listItems}
        onAdd={handleAdd}
      />
      <AddLanguagePairModal
        isOpen={isAddLanguagePairModalOpen}
        onClose={() => setIsAddLanguagePairModalOpen(false)}
        onAdd={handleAdd}
      />
      <GeneralPriceChangeModal
        isOpen={isGeneralPriceChangeModalOpen}
        onClose={() => setIsGeneralPriceChangeModalOpen(false)}
        onGeneralPriceChange={handleGeneralPriceChange}
      />
      {contextHolder}
      <Space className="width-100" direction="vertical" size="middle">
        <PriceListDetailsToolbar
          filterBy={filterBy}
          onFilterByChange={setFilterBy}
        />
        <Form form={form} onValuesChange={handleEdit} onFinish={handleSave}>
          <Card
            bordered={false}
            className={styles.card}
            title={
              <Space wrap>
                <Space className={styles.cardExtraTitleWrapper}>
                  <Skeleton
                    loading={isLoading}
                    round
                    paragraph={false}
                    active
                    style={{ width: '125px' }}
                  >
                    <Button
                      type="text"
                      size="small"
                      onClick={() => setIsEditPriceListNameModalOpen(true)}
                      disabled={!data}
                    >
                      <Title level={5} style={{ marginBottom: 0 }}>
                        {data?.name}
                      </Title>
                      <EditIcon />
                    </Button>
                  </Skeleton>
                </Space>
                <div className={styles.actionsWrapper}>
                  <Button
                    icon={<EditIcon />}
                    type="primary"
                    loading={isLoading}
                    htmlType="submit"
                    disabled={!isChanged}
                  >
                    <FormattedMessage defaultMessage="Zapisz zmiany" />
                  </Button>
                  <Button
                    icon={<PlusIcon />}
                    type="primary"
                    loading={isLoading}
                    onClick={() => setIsGenerateLanguagePairsModalOpen(true)}
                  >
                    <FormattedMessage defaultMessage="Generuj pary językowe" />
                  </Button>
                  <Button
                    icon={<PlusIcon />}
                    type="primary"
                    loading={isLoading}
                    onClick={() => setIsAddLanguagePairModalOpen(true)}
                  >
                    <FormattedMessage defaultMessage="Dodaj parę językową" />
                  </Button>
                  <Button
                    icon={<EditIcon />}
                    type="primary"
                    loading={isLoading}
                    onClick={() => setIsGeneralPriceChangeModalOpen(true)}
                  >
                    <FormattedMessage defaultMessage="Masowa zmiana cen" />
                  </Button>
                </div>
              </Space>
            }
          >
            <Loader
              visible={!isInitialLoading && isLoading}
              style={{ marginBottom: '30px' }}
            >
              <FormList<ListItem>
                form={form}
                data={filteredListItems}
                items={formListItems({
                  handleDelete,
                  isDeleteLoading,
                })}
                isLoading={isInitialLoading}
              />
            </Loader>
          </Card>
        </Form>
      </Space>
    </div>
  );
};

export default PriceDetails;
