import { FormattedMessage } from 'react-intl';
import Modal from 'components/ui/molecules/Modal';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  assignFinancesPaymentTransaction,
  setFinancesAssignPaymentTransactionModal,
} from 'ducks/view/finances';
import ParsedPaymentFileTable from './components/ParsedPaymentFileTable';
import PaymentTransactionsTable from './components/PaymentTransactionsTable';
import styles from './styles.module.scss';

const AssignPaymentTransactionModal = () => {
  const dispatch = useAppDispatch();
  const { isOpen, parsedPaymentId, selectedPaymentTransaction } =
    useAppSelector(({ finances }) => finances.assignPaymentTransactionModal);

  const handleClose = () =>
    dispatch(
      setFinancesAssignPaymentTransactionModal({
        isOpen: false,
        parsedPaymentId: null,
        selectedPaymentTransaction: null,
      }),
    );

  const handleSubmit = () => {
    if (!parsedPaymentId || !selectedPaymentTransaction) return;

    dispatch(
      assignFinancesPaymentTransaction({
        parsedPaymentId,
        paymentTransaction: selectedPaymentTransaction,
      }),
    );

    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleSubmit}
      onCancel={handleClose}
      okText={<FormattedMessage defaultMessage="Przypisz wpłatę" />}
      okButtonProps={{
        disabled: !selectedPaymentTransaction,
      }}
    >
      <div className={styles.wrapper}>
        <ParsedPaymentFileTable />
        <PaymentTransactionsTable />
      </div>
    </Modal>
  );
};

export default AssignPaymentTransactionModal;
