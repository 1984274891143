import type { PreorderRealizationValues } from '../../config';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import { FormattedMessage } from 'react-intl';

export const formItems: FormItemRecord<PreorderRealizationValues>[] = [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="E-mail" />,
    name: 'email',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Uzupełnij adres e-mail" />,
      },
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="Kopia e-mail" />,
    name: 'cc',
    rules: [
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
  {
    key: 3,
    label: <FormattedMessage defaultMessage="Temat" />,
    name: 'subject',
    rules: [
      {
        required: true,
        message: (
          <FormattedMessage defaultMessage="Uzupełnij temat wiadomości" />
        ),
      },
    ],
  },
];
