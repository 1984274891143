import type { ListItemRecord } from 'components/ui/molecules/List';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { generateWorkingDaysLabel } from 'utilities/workingDaysLabel';

export type SharedSummaryListItemsProps = {
  translationsTotal: number;
  specialTotal: number;
  correctionTotal: number;
  graphicTotal: number;
  shipmentTotal: number;
  preparatoryTotal: number;
  additionalTotal: number;
  total: number;
};

export const summaryListTimeRealizationItem = (
  timeRealization: number | undefined,
): ListItemRecord => ({
  label: <FormattedMessage defaultMessage="Czas realizacji:" />,
  skeletonRows: 1,
  component: (
    <span data-testid="timeRealizationTotal">
      {generateWorkingDaysLabel({ timeRealization })}
    </span>
  ),
});

export const sharedSummaryListItems = ({
  translationsTotal,
  specialTotal,
  correctionTotal,
  graphicTotal,
  shipmentTotal,
  preparatoryTotal,
  additionalTotal,
  total,
}: SharedSummaryListItemsProps): ListItemRecord[] => [
  {
    label: <FormattedMessage defaultMessage="Tłumaczenie:" />,
    skeletonRows: 1,
    component: (
      <span data-testid="translationsTotal">
        <CurrencyFormatter value={translationsTotal} />
      </span>
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Specjalistyczne:" />,
    skeletonRows: 1,
    hidden: !specialTotal,
    component: (
      <span data-testid="specializedTotal">
        <CurrencyFormatter value={specialTotal} />
      </span>
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Korekta:" />,
    skeletonRows: 1,
    hidden: !correctionTotal,
    component: (
      <span data-testid="correctionTotal">
        <CurrencyFormatter value={correctionTotal} />
      </span>
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Grafika:" />,
    skeletonRows: 1,
    hidden: !graphicTotal,
    component: (
      <span data-testid="graphicTotal">
        <CurrencyFormatter value={graphicTotal} />
      </span>
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Tłumaczenie ekspresowe" />,
    skeletonRows: 1,
    hidden: !additionalTotal,
    component: (
      <span data-testid="additionalTotal">
        <CurrencyFormatter value={additionalTotal} />
      </span>
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Wysyłka:" />,
    skeletonRows: 1,
    hidden: !shipmentTotal,
    component: (
      <span data-testid="shipmentTotal">
        <CurrencyFormatter value={shipmentTotal} />
      </span>
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Prace przygotowawcze:" />,
    skeletonRows: 1,
    hidden: !preparatoryTotal,
    component: (
      <span data-testid="preparatoryTotal">
        <CurrencyFormatter value={preparatoryTotal} />
      </span>
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Razem:" />,
    skeletonRows: 1,
    component: (
      <span data-testid="total">
        <CurrencyFormatter value={total} />
      </span>
    ),
  },
];
