import type { FC } from 'react';
import { Typography } from 'antd';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { NO_DATA } from 'constants/strings';
import { CurrencyType } from 'ducks/payments/types';

const { Text } = Typography;

type Props = {
  from: number;
  to: number;
  currency?: CurrencyType;
};

const CurrencyItem: FC<Props> = ({ from, to, currency = CurrencyType.PLN }) => {
  if (from === to && !from) return <Text>{NO_DATA}</Text>;

  if (from === to) return <CurrencyFormatter value={to} currency={currency} />;

  return (
    <>
      <CurrencyFormatter value={from} currency={currency} /> -{' '}
      <CurrencyFormatter value={to} currency={currency} />
    </>
  );
};

export default CurrencyItem;
