import type { Client } from 'ducks/clients/types';
import type { Notes, Order, OrderInvoice } from 'ducks/orders/types';
import type { CurrencyType, PaymentType } from 'ducks/payments/types';
import type {
  DefaultValue,
  PaginationRequest,
  PaginationResponse,
  SortRequest,
} from 'ducks/types';

export enum InvoicesTag {
  Invoices = 'Invoices',
  InvoicesData = 'InvoicesData',
  InvoicesOverdue = 'InvoicesOverdue',
  InvoicesVat = 'InvoicesVat',
  InvoicesAdvance = 'InvoicesAdvance',
  InvoicesFinal = 'InvoicesFinal',
  InvoiceDebtCollection = 'InvoiceDebtCollection',
  InvoiceLetterSummon = 'InvoiceLetterSummon',
  InvoiceAccountingNote = 'InvoiceAccountingNote',
}

export enum InvoiceType {
  PROFORMA = 1,
  VAT = 2,
}

export enum InvoiceOverdueType {
  PAPER = 1,
  DIGITAL = 2,
}

export enum InvoiceOverdueStatus {
  UNPAID_ON_TIME = 1,
  PAID_ON_TIME = 2,
  NOT_SENT = 3,
  SUMMONS = 4,
  THIRTY_DAYS = 5,
  KRD = 6,
  COURT = 7,
  PAID = 8,
  JUDGMENT = 9,
}

export enum DebtCollectionsStatus {
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export enum AccountingNotesStatus {
  PAID = 1,
  NOT_PAID = 2,
}

export enum AccountingNoteShipmentType {
  EMAIL = 1,
  POST = 2,
}

export type GetInvoicesFilter = Partial<{
  dateCreationBefore: string;
  dateCreationAfter: string;
  dateDueBefore: string;
  dateDueAfter: string;
  type: InvoiceType;
}>;

export type GetInvoicesSort = SortRequest<
  keyof Pick<
    Invoice,
    'id' | 'number' | 'priceNetto' | 'priceBrutto' | 'createdAt'
  >
>;

export type GetInvoicesRequest = PaginationRequest & {
  searchTerm?: string;
  filter?: GetInvoicesFilter;
  sort?: GetInvoicesSort | null;
};

export type GetInvoicesResponse = PaginationResponse<Invoice>;

export type GetInvoicesDataRequest = {
  ids: number[];
};

export enum InvoiceStatus {
  NOT_PAID = 1,
  PAID = 2,
}

export type GetInvoicesDataResponse = {
  sumPriceNetto: number;
  sumPriceBrutto: number;
  vatRate: number;
  paid: number;
  currency: CurrencyType;
  client: InvoiceClient;
  items: InvoiceDataItem[];
  orders: (Pick<Order, 'id' | 'number' | 'isMt' | 'status' | 'priceNetto'> & {
    client: Client;
    dateRealization: Date | null;
  })[];
};

export type AddInvoiceRequest = {
  orderId: number[];
  paymentMethod: PaymentType;
  createdAt: Date;
  dateDue: Date;
  dateSale: Date;
  comment: string | null;
};

export type AddInvoicePaymentRequest = {
  id: string | number;
  amount: number;
  datePayment: Date;
  type: PaymentType;
  currency: CurrencyType;
};

export type AddInvoiceResponse = {
  id: number;
  type: number;
  number: string;
  amount: number;
  clientData: InvoiceClient | null;
  clientId: number;
  comment: string;
  paymentMethod: PaymentType;
  dateDue: Date;
  dateSale: Date;
  createdAt: Date;
};

export type GetInvoicesVatFilter = Partial<{
  status: InvoiceStatus | null;
}>;

export type GetInvoicesVatResponse = PaginationResponse<InvoiceListItem>;

export type GetInvoicesVatRequest = PaginationRequest & {
  searchTerm?: string;
  filter?: GetInvoicesVatFilter;
};

export type GetInvoiceVatRequest = {
  id: number | string;
};

export type GetInvoiceVatResponse = InvoiceVat;

export type SendInvoiceVatRequest = {
  id: number | string;
  body: {
    email: string;
    name: string;
    cc: string;
    subject: string;
    body: string;
    isSms: boolean;
    smsText: string;
    invoices: number[];
  };
};

export type GetInvoicesAdvanceResponse = PaginationResponse<InvoiceListItem>;

export type GetInvoicesAdvanceRequest = GetInvoicesVatRequest;

export type GetInvoicesFinalResponse = PaginationResponse<InvoiceFinal>;

export type GetInvoicesFinalRequest = GetInvoicesVatRequest;

export type UpdateHasInvoiceAdvanceRequest = {
  id: number;
  hasInvoiceAdvance: boolean;
};

export type UpdateHasInvoiceFinalRequest = {
  id: number;
  hasInvoiceFinal: boolean;
};

export type GetInvoicesOverdueFilter = Partial<{
  hasDebtCollection: Binary;
  hasAccountingNote: Binary;
  status: InvoiceOverdueStatus;
  hasLetterSummon: Binary;
  isCourt: Binary;
}>;

export type GetInvoicesOverdueRequest = PaginationRequest & {
  searchTerm?: string;
  filter?: GetInvoicesOverdueFilter;
};

export type GetInvoicesOverdueResponse = PaginationResponse<InvoiceOverdue>;

export type GetInvoiceOverdueRequest = {
  id: string | number;
};

export type GetInvoiceOverdueResponse = InvoiceOverdue;

export type UpdateInvoiceOverdueRequest = {
  id: string | number;
} & Partial<{
  status: InvoiceOverdueStatus;
  dateLastContact: string;
  isCourt: boolean;
}>;

export type GetInvoiceDebtCollectionRequest = {
  id: string | number;
};

export type GetInvoiceDebtCollectionResponse = DebtCollections[];

export type AddInvoiceDebtCollectionRequest = {
  id: string | number;
  dateReceived: Date;
  number: string;
  price: number;
  status: DebtCollectionsStatus;
};

export type UpdateInvoiceDebtCollectionRequest =
  AddInvoiceDebtCollectionRequest & {
    itemId: number;
  };

export type GetInvoiceLetterSummonRequest = {
  id: string | number;
};

export type GetInvoiceLetterSummonResponse = LetterSummons[];

export type AddInvoiceLetterSummonRequest = {
  id: string | number;
  dateReceived: Date;
  dateSent: Date;
};

export type UpdateInvoiceLetterSummonRequest = AddInvoiceLetterSummonRequest & {
  itemId: number;
};

export type DeleteInvoiceLetterSummonRequest = {
  id: string | number;
  itemId: number;
};

export type GetInvoiceAccountingNotesRequest = {
  id: string | number;
};

export type GetInvoiceAccountingNotesResponse = AccountingNotes[];

export type AddInvoiceAccountingNoteRequest = Omit<
  WithoutNullableKeys<AccountingNotes>,
  'id'
> & {
  id: string | number;
};

export type UpdateInvoiceAccountingNoteRequest =
  AddInvoiceAccountingNoteRequest & {
    itemId: number;
  };

export type DeleteInvoiceAccountingNoteRequest = {
  id: string | number;
  itemId: number;
};

/* ——————————————————————————REGULAR TYPES—————————————————————————— */

export type InvoiceClient = {
  id: number;
  email: string | null;
  name: string | null;
  nip: string | null;
  street: string | null;
  postCode: string | null;
  city: string | null;
  country: string | null;
  phone: string | null;
  regon: string | null;
  clientName: string | null;
  invoiceAuto: boolean | null;
  invoiceType: DefaultValue | null;
  isCompany: boolean | null;
  invoiceEmail: string | null;
  isCollectiveInvoices: boolean;
};

export type Invoice = {
  id: number;
  type: InvoiceType;
  number: string;
  orders: { id: number; number: string }[];
  client: InvoiceClient | null;
  priceNetto: number;
  priceBrutto: number;
  payed: number;
  currentToPay: number;
  sum: number;
  dateDue: Date;
  createdAt: Date;
};

export type InvoiceDataItem = {
  id: number;
  name: string;
  unit: string;
  amount: number;
  netto: number;
  discount: number;
  vatRate: number;
  priceNetto: number;
  priceBrutto: number;
};

export type InvoiceOverdue = {
  id: number;
  type: InvoiceOverdueType;
  status: InvoiceOverdueStatus;
  clientId: number;
  clientData: InvoiceClient | null;
  debtCollections: DebtCollections | null;
  accountingNotes: AccountingNotes | null;
  letterSummons: LetterSummons | null;
  notes: Notes;
  isCourt: Binary;
  number: string;
  amount: number;
  filename: string;
  isPaid: boolean;
  paymentMethod: PaymentType;
  dateSent: Date | null;
  dateSale: Date;
  dateDue: Date;
  dateLastContact: Date | null;
  priceNetto: number | null;
  priceBrutto: number | null;
  paid: number;
  currentToPay: number;
  orders: { id: number; number: string }[];
  createdAt: Date;
};

export type InvoiceVat = {
  id: number;
  type: number;
  status: number;
  clientId: number;
  client: InvoiceClient | null;
  clientData: InvoiceClient | null;
  number: string;
  debtCollections: DebtCollections | null;
  accountingNotes: AccountingNotes | null;
  letterSummons: LetterSummons | null;
  filename: string;
  dateSent: Date;
  dateSale: Date;
  dateDue: Date;
  dateLastContact: Date | null;
  amount: number;
  priceNetto: number | null;
  priceBrutto: number | null;
  paid: number;
  currentToPay: number;
  paymentMethod: PaymentType;
  isPaid: boolean;
  isCourt: Binary;
  notes: Notes;
  orders: { id: number; number: string }[];
  createdAt: Date;
};

export type DebtCollections = {
  id: number;
  dateReceived: Date | null;
  number: string | null;
  price: number;
  status: DebtCollectionsStatus;
};

export type AccountingNotes = {
  id: number;
  dateSent: Date | null;
  number: string | null;
  shipmentType: AccountingNoteShipmentType;
  status: AccountingNotesStatus;
};

export type LetterSummons = {
  id: number;
  invoiceId: number;
  dateSent: Date;
  dateReceived: Date | null;
};

export type InvoiceListItem = {
  id: number;
  number: string;
  client: InvoiceClient;
  status: InvoiceStatus;
  priceNetto: number;
  priceBrutto: number;
  payed: number;
  hasInvoiceAdvance: Binary;
  hasInvoiceFinal: Binary;
  invoiceAuto: Binary;
  invoices: OrderInvoice[];
  dateSent: Date | null;
};

export type InvoiceFinal = InvoiceListItem;
