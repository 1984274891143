/* eslint-disable react/jsx-props-no-spreading */
import type { ColProps } from 'antd';
import type { FC, ReactNode } from 'react';
import { Card, Col, Skeleton, Space } from 'antd';
import cn from 'classnames';
import styles from './styles.module.scss';

interface Props extends Omit<ColProps, 'title'> {
  children: ReactNode;
  title?: string | ReactNode;
  extra?: ReactNode;
  isLoading?: boolean;
  customSkeleton?: boolean;
  disableListStyles?: boolean;
}

const CardCommon: FC<Props> = ({
  children,
  title,
  extra,
  isLoading = false,
  className,
  customSkeleton = false,
  disableListStyles = true,
  ...props
}) => (
  <Col
    span={24}
    className={cn(styles.cardWrapper, className, {
      [styles.cardlist]: disableListStyles,
    })}
    {...props}
  >
    <Card
      data-testid="cardCommon"
      bordered={false}
      className={cn('header-solid h-full', styles.card)}
      bodyStyle={{ paddingTop: 16, paddingBottom: 16 }}
      title={title}
      extra={extra}
      loading={!customSkeleton && isLoading}
    >
      {customSkeleton && isLoading ? (
        <Space className="width-100" direction="vertical" size="middle">
          <Skeleton loading={isLoading} active paragraph={false} />
          <Skeleton loading={isLoading} active paragraph={false} />
        </Space>
      ) : (
        children
      )}
    </Card>
  </Col>
);

export default CardCommon;
