import cn from 'classnames';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import { Routes } from 'components/main/App/Routing';
import { verifyAdmin } from 'utilities/authorization';
import LoginHistoryTable from './components/LoginHistoryTable/LoginHistoryTable';
import UsersTable from './components/UsersTable/UsersTable';
import styles from './styles.module.scss';

const Users = () => {
  const { user } = useSanctum();
  const navigate = useNavigate();
  const isAdmin = verifyAdmin(user);

  useEffect(() => {
    if (!isAdmin) {
      navigate(Routes.PROJECTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  if (!isAdmin) {
    return null;
  }

  return (
    <div className={cn(styles.wrapper, 'layout-content mb-24')}>
      <UsersTable />
      <LoginHistoryTable />
    </div>
  );
};

export default Users;
