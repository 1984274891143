import type { ColumnsType } from 'antd/es/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { Payment } from 'ducks/payments/types';
import type { IntlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import FormatDate from 'components/ui/atoms/FormatDate';
import { paymentTypesObject } from 'constants/payment';

export type PaymentRow = TableRow & Payment;

type Props = {
  intl: IntlShape;
};

export const columns = ({ intl }: Props): ColumnsType<PaymentRow> => [
  {
    title: <FormattedMessage defaultMessage="Metoda płatności" />,
    key: 'type',
    dataIndex: 'type',
    render: (_, { id, type }) => (
      <span data-testid={`type-${id}`}>
        {paymentTypesObject(intl)[type].label}
      </span>
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Kwota" />,
    key: 'amount',
    dataIndex: 'amount',
    render: (_, { id, amount, currency }) => (
      <span data-testid={`amount-${id}`}>
        <CurrencyFormatter value={amount} currency={currency} />
      </span>
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Data płatności" />,
    key: 'datePayment',
    dataIndex: 'datePayment',
    render: (_, { id, datePayment }) => (
      <FormatDate
        hideTime
        data-testid={`datePayment-${id}`}
        date={datePayment}
      />
    ),
  },
];
