import { PaymentType, type GetPaymentsResponse } from 'ducks/payments/types';

type Args = {
  paymentsData: GetPaymentsResponse;
};

export const calcPaymentsTotal = ({ paymentsData }: Args) => {
  const filteredPayments = paymentsData.filter(
    (curValue) => curValue.type !== PaymentType.REFUND,
  );

  const total = filteredPayments.reduce(
    (prevValue, curValue) => prevValue + curValue.amount,
    0,
  );

  return total;
};
