/* eslint-disable react/jsx-props-no-spreading */
import type { SelectProps } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import type { GetTranslatorsFilter } from 'ducks/translators/types';
import type { FC } from 'react';
import { Input, Select } from 'antd';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LanguageSelect from 'components/ui/atoms/LanguageSelect';
import { useGetTranslatorsCatsQuery } from 'ducks/translators/service';
import styles from './styles.module.scss';

type FilterOptions = Record<
  keyof Omit<GetTranslatorsFilter, 'orderId' | 'orderFileId'>,
  DefaultOptionType[]
>;

interface ClientsTableToolbarProps {
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  filterBy: GetTranslatorsFilter;
  onFilterByChange: (filter: Partial<GetTranslatorsFilter>) => void;
}

const TranslatorsTableToolbar: FC<ClientsTableToolbarProps> = memo(
  ({ searchValue, onSearchValueChange, filterBy, onFilterByChange }) => {
    const intl = useIntl();
    const { data = [], isLoading } = useGetTranslatorsCatsQuery();
    const mainCats = data.filter(({ isMain }) => isMain);
    const sharedSelectProps: SelectProps = {
      mode: 'multiple',
      allowClear: true,
      maxTagCount: 'responsive',
      style: { width: 120 },
    };

    const filterOptions: FilterOptions = {
      lang: [],
      specializations: [],
      priceType: [],
      catId: mainCats.map(({ id, name }) => ({ label: name, value: id })),
      type: [
        {
          label: <FormattedMessage defaultMessage="Postedycja" />,
          value: 'is_post_edit',
        },
        {
          label: <FormattedMessage defaultMessage="Tłumacz przysięgły" />,
          value: 'is_certified',
        },
        {
          label: <FormattedMessage defaultMessage="Tłumacz profesjonalny" />,
          value: 'is_professional',
        },
      ],
      status: [
        {
          label: <FormattedMessage defaultMessage="Nieaktywny" />,
          value: 0,
        },
        {
          label: <FormattedMessage defaultMessage="Aktywny" />,
          value: 1,
        },
        {
          label: <FormattedMessage defaultMessage="Wszyscy" />,
          value: null,
        },
      ],
    };

    return (
      <div className={styles.wrapper}>
        <Input
          value={searchValue}
          className={styles.item}
          onChange={(e) => onSearchValueChange(e.target.value)}
          placeholder={intl.formatMessage({
            defaultMessage: 'Szukaj',
          })}
          allowClear
        />
        <LanguageSelect
          mode="multiple"
          value={filterBy.lang}
          onChange={(value) => onFilterByChange({ lang: value as string[] })}
          className={styles.item}
          placeholder={<FormattedMessage defaultMessage="Język" />}
          maxTagCount="responsive"
          allowClear
        />
        <Select
          options={filterOptions.catId}
          value={filterBy.catId}
          onChange={(value) => onFilterByChange({ catId: value })}
          placeholder={<FormattedMessage defaultMessage="CAT" />}
          loading={isLoading}
          className={styles.item}
          {...sharedSelectProps}
        />
        <Select
          options={filterOptions.type}
          value={filterBy.type}
          onChange={(value) => onFilterByChange({ type: value })}
          placeholder={<FormattedMessage defaultMessage="Typ" />}
          {...sharedSelectProps}
          className={styles.item}
        />
        <Select
          options={filterOptions.status}
          value={filterBy.status}
          onChange={(value) => onFilterByChange({ status: value })}
          className={styles.item}
          placeholder={<FormattedMessage defaultMessage="Status" />}
        />
      </div>
    );
  },
);

export default TranslatorsTableToolbar;
