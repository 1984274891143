import type { Dayjs } from 'dayjs';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Form, notification } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { DayjsFormat } from 'constants/dayjsFormats';
import { useUpdateInvoiceOverdueMutation } from 'ducks/invoices/service';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';

const { useForm } = Form;

type Values = {
  dateLastContact: Dayjs;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  dateLastContact: Date | null;
};

const EditDateLastContactModal: FC<Props> = ({
  isOpen,
  onClose,
  dateLastContact,
}) => {
  const { id = '' } = useParams();
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm<Values>();
  const [updateInvoiceOverdue, { isLoading }] =
    useUpdateInvoiceOverdueMutation();
  const initialValues: Values = {
    dateLastContact: dayjs(dateLastContact ?? undefined),
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleUpdateDateLastContact = () => {
    if (isLoading) return;

    mutationFunctionWithForm<void, Values>({
      form,
      mutationFn: async (values) => {
        const formattedDateLastContact = dayjs(values.dateLastContact).format(
          DayjsFormat.YEAR_MONTH_DAY_DASHED,
        );

        await updateInvoiceOverdue({
          id,
          dateLastContact: formattedDateLastContact,
        }).unwrap();
      },
      onSuccess: handleClose,
      onError: () =>
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania ostatniego kontaktu." />
          ),
        }),
    });
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Ostatni kontakt" />}
      open={isOpen}
      onOk={handleUpdateDateLastContact}
      onCancel={handleClose}
      isLoading={isLoading}
      disableModalStyles
      disableFormStyles
    >
      {contextHolder}
      <Form<Values>
        form={form}
        initialValues={initialValues}
        autoComplete="off"
        layout="vertical"
      >
        <FormItem<Values>
          name="dateLastContact"
          label={<FormattedMessage defaultMessage="Data ostatniego kontaktu" />}
          component={
            <DatePicker
              data-testid="dateLastContactPicker"
              className="width-100"
              locale={locale}
              allowClear={false}
            />
          }
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage defaultMessage="Wybierz datę ostatniego kontaktu" />
              ),
            },
          ]}
        />
      </Form>
    </Modal>
  );
};

export default EditDateLastContactModal;
