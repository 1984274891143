import type { FC } from 'react';
import cn from 'classnames';
import { Link, type LinkProps } from 'react-router-dom';
import { PaymentDetailStatus } from 'constants/payment';
import { NO_DATA } from 'constants/strings';
import { useAppDispatch } from 'ducks/store';
import { setTriggerScrollToPayments } from 'ducks/view/preorders';
import { DollarIcon } from 'icons';
import styles from './styles.module.scss';

type Props = {
  paymentStatus: PaymentDetailStatus;
} & Pick<LinkProps, 'to'>;

const PaymentDollarCell: FC<Props> = ({ to, paymentStatus }) => {
  const dispatch = useAppDispatch();
  const noPayment = paymentStatus === PaymentDetailStatus.NO_PAYMENT;
  const isOverPaid = paymentStatus === PaymentDetailStatus.PAID_AND_OVERPAID;

  const handleTriggetScrollToPayments = () =>
    dispatch(setTriggerScrollToPayments(true));

  return (
    <Link
      className={styles.link}
      to={to}
      onClick={handleTriggetScrollToPayments}
    >
      {noPayment ? (
        <span>{NO_DATA}</span>
      ) : (
        <div
          className={cn(styles.iconWrapper, {
            [styles.greenDollar]: isOverPaid,
          })}
        >
          <DollarIcon />
        </div>
      )}
    </Link>
  );
};

export default PaymentDollarCell;
