import type { ListItemRecord } from 'components/ui/molecules/List';
import type { Client } from 'ducks/clients/types';
import { FormattedMessage } from 'react-intl';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CopyToClipboardWithText from 'components/ui/atoms/CopyToClipboard';
import { DefaultValue } from 'ducks/types';

export const contactListItems = (
  data: Client | undefined,
): ListItemRecord[] => [
  {
    label: <FormattedMessage defaultMessage="E-mail:" />,
    description: data?.email1 ? (
      <CopyToClipboardWithText type="secondary" text={data?.email1} />
    ) : (
      data?.email1
    ),
    avatar: (
      <CircleCheckbox value={data?.emailDefault === DefaultValue.FIRST} />
    ),
  },
  {
    label: <FormattedMessage defaultMessage="E-mail 2:" />,
    description: data?.email2 ? (
      <CopyToClipboardWithText type="secondary" text={data?.email2} />
    ) : (
      data?.email2
    ),
    avatar: (
      <CircleCheckbox value={data?.emailDefault === DefaultValue.SECOND} />
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Tel:" />,
    description: data?.phone1 ? (
      <CopyToClipboardWithText type="secondary" text={data?.phone1} />
    ) : (
      data?.phone1
    ),
    avatar: (
      <CircleCheckbox value={data?.phoneDefault === DefaultValue.FIRST} />
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Tel 2:" />,
    description: data?.phone2 ? (
      <CopyToClipboardWithText type="secondary" text={data?.phone2} />
    ) : (
      data?.phone2
    ),
    avatar: (
      <CircleCheckbox value={data?.phoneDefault === DefaultValue.SECOND} />
    ),
  },
];
