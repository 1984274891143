import type { IntlShape } from 'react-intl';
import { PaymentStatus, PaymentType } from 'ducks/payments/types';

export enum PaymentDetailStatus {
  NO_PAYMENT = 1,
  UNDERPAID = 2,
  PAID_AND_OVERPAID = 3,
}

type Option<T> = {
  value: T;
  label: string;
};

export const paymentTypes = (intl: IntlShape): Option<PaymentType>[] => [
  {
    value: PaymentType.ONLINE_TRANSFER,
    label: intl.formatMessage({ defaultMessage: 'Przelew online' }),
  },
  {
    value: PaymentType.BANK_TRANSFER,
    label: intl.formatMessage({ defaultMessage: 'Przelew bankowy' }),
  },
  {
    value: PaymentType.PAYPAL,
    label: intl.formatMessage({ defaultMessage: 'PayPal' }),
  },
  {
    value: PaymentType.REFUND,
    label: intl.formatMessage({ defaultMessage: 'Zwrot' }),
  },
];

export const getPaymentType = (intl: IntlShape, type: PaymentType) =>
  paymentTypes(intl).find(({ value }) => value === type);

export const paymentTypesObject = (intl: IntlShape) =>
  paymentTypes(intl).reduce((acc, { value, label }) => {
    acc[value] = {
      value,
      label,
    };

    return acc;
  }, {} as Record<PaymentType, Option<PaymentType>>);

export const paymentStatuses = (intl: IntlShape): Option<PaymentStatus>[] => [
  {
    value: PaymentStatus.SUCCESS,
    label: intl.formatMessage({ defaultMessage: 'Sukces' }),
  },
  {
    value: PaymentStatus.IN_PROGRESS,
    label: intl.formatMessage({ defaultMessage: 'W trakcie' }),
  },
  {
    value: PaymentStatus.FAILURE,
    label: intl.formatMessage({ defaultMessage: 'Błąd' }),
  },
  {
    value: PaymentStatus.CANCELED,
    label: intl.formatMessage({ defaultMessage: 'Anulowane' }),
  },
  {
    value: PaymentStatus.REJECTED,
    label: intl.formatMessage({ defaultMessage: 'Odrzucone' }),
  },
];

export const paymentStatusesObject = (intl: IntlShape) =>
  paymentStatuses(intl).reduce((acc, { value, label }) => {
    acc[value] = {
      value,
      label,
    };

    return acc;
  }, {} as Record<PaymentStatus, Option<PaymentStatus>>);
