import type { FC } from 'react';
import classNames from 'classnames';
import LanguageFlag from '../LanguageFlag';
import LanguageName from '../LanguageName';
import styles from './styles.module.scss';

type Props = {
  langFrom: string;
  langTo: string;
  showOnlyCodes?: boolean;
  className?: string;
};

const LanguagePair: FC<Props> = ({
  langFrom,
  langTo,
  showOnlyCodes = false,
  className,
}) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <LanguageFlag languageCode={langFrom} />
      {showOnlyCodes ? (
        langFrom
      ) : (
        <LanguageName countryCode={langFrom} showCode nowrap />
      )}
      <span>-</span>
      {showOnlyCodes ? (
        langTo
      ) : (
        <LanguageName countryCode={langTo} showCode nowrap />
      )}
      <LanguageFlag languageCode={langTo} />
    </div>
  );
};

export default LanguagePair;
