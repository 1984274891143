import type { FC } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSanctum } from 'react-sanctum';
import { UserRole } from 'ducks/users/types';
import { UnlockIcon } from 'icons';
import PadlockLockedButton from './PadlockLockedButton';
import PadlockPopoverContent from './PadlockPopoverContent';
import styles from './styles.module.scss';

type Size = 'small' | 'medium';

type Props = {
  activeEditorId: number | undefined | null;
  size?: Size;
  showPopover?: boolean;
  showUnblockButton?: boolean;
  handleUnblock: () => void;
  isLoading: boolean;
};

const Padlock: FC<Props> = ({
  activeEditorId,
  handleUnblock,
  size = 'medium',
  showUnblockButton = true,
  isLoading,
  showPopover = true,
}) => {
  const { user } = useSanctum();
  const isLocked = !!activeEditorId;
  const canUnblock =
    isLocked && (activeEditorId === user.id || user.role === UserRole.ADMIN);

  const lockBtnWithPopover = showPopover ? (
    <Popover
      content={
        activeEditorId && (
          <PadlockPopoverContent activeEditorId={activeEditorId} />
        )
      }
      title={<FormattedMessage defaultMessage="Edytowany przez" />}
      trigger="hover"
      placement="bottom"
      showArrow={false}
    >
      <PadlockLockedButton
        canUnblock={canUnblock}
        showUnblockButton={showUnblockButton}
        handleUnblock={handleUnblock}
        isLoading={isLoading}
      />
    </Popover>
  ) : (
    <PadlockLockedButton
      canUnblock={canUnblock}
      showUnblockButton={showUnblockButton}
      handleUnblock={handleUnblock}
      isLoading={isLoading}
    />
  );

  return (
    <div
      data-testid="padlock"
      className={cn(styles.padlockWrapper, {
        [styles.iconSmall]: size === 'small',
      })}
    >
      {isLocked ? lockBtnWithPopover : <UnlockIcon data-testid="unlockIcon" />}
    </div>
  );
};

export default Padlock;
