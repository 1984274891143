import type { NotificationInstance } from 'antd/es/notification/interface';
import type { Note, NoteType } from 'ducks/notes/types';
import type { FC, ReactElement } from 'react';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import Timeline from 'components/ui/molecules/Timeline/Timeline';
import { useGetNotesQuery } from 'ducks/notes/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setTriggerOpenAllNotes } from 'ducks/view/global';
import { ListTask, PlusIcon } from 'icons';
import NoteItem from './components/NoteItem/NoteItem';
import NoteModal from './components/NoteModal';
import ShowAllNotesModal from './components/ShowAllNotesModal';

type Props = {
  objectId: number | undefined;
  notify: NotificationInstance;
  noteType: NoteType;
  title?: ReactElement | string;
  isLoading?: boolean;
  skip?: boolean;
  onSave?: () => Promise<void>;
  isSaveLoading?: boolean;
};

const Notes: FC<Props> = ({
  objectId,
  notify,
  noteType,
  title,
  isLoading: isInitialLoading = false,
  skip = false,
  onSave,
  isSaveLoading = false,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAllNotesModalOpen, setIsAllNotesModalOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const triggerShowAllNotes = useAppSelector(
    ({ global }) => global.triggerOpenAllNotes,
  );
  const dispatch = useAppDispatch();
  const { data = [], isLoading: isNotesLoading } = useGetNotesQuery(
    {
      object: noteType,
      objectId: Number(objectId),
    },
    {
      skip: skip || !objectId,
    },
  );
  const isLoading = isInitialLoading || isNotesLoading;

  const handleCloseModal = () => {
    setSelectedNote(null);
    setIsModalVisible(false);
  };

  const handleCloseAllNotesModal = () => {
    setIsAllNotesModalOpen(false);
  };

  useEffect(() => {
    if (!triggerShowAllNotes) return;
    setIsAllNotesModalOpen(true);
    dispatch(setTriggerOpenAllNotes(false));
  }, [triggerShowAllNotes, dispatch]);

  // TODO: all those comments are as because this feature may be back soon
  // TODO: if not DELETE
  // const indexesOfShownNotes = useShownNotes({ notes: data });
  // const shouldShowExtendedNotes = data.length > indexesOfShownNotes.length;
  const shouldShowExtendedNotes = true;
  const numberOfAllNotes = data.length;

  const notesToShow = data.slice(0, 2);

  // const notesToShow = useMemo(
  //   () =>
  //     indexesOfShownNotes.map(({ index, numberOfCharactes }) => ({
  //       ...data[index],
  //       numberOfCharactes,
  //     })),
  //   [indexesOfShownNotes, data],
  // );

  return (
    <>
      {objectId && (
        <NoteModal
          object={noteType}
          objectId={objectId}
          isOpen={isModalVisible || !!selectedNote}
          onClose={handleCloseModal}
          data={selectedNote}
          notify={notify}
          onSave={onSave}
          isSaveLoading={isSaveLoading}
        />
      )}
      {objectId && shouldShowExtendedNotes && (
        <ShowAllNotesModal
          isOpen={isAllNotesModalOpen}
          isLoading={isNotesLoading}
          onClose={handleCloseAllNotesModal}
          data={data}
          objectId={objectId}
          noteType={noteType}
          notify={notify}
          setSelectedNote={setSelectedNote}
        />
      )}
      <Timeline
        data={notesToShow}
        isLoading={isLoading}
        title={
          <TitleWrapper>
            <span>
              {title ?? (
                <FormattedMessage
                  defaultMessage="Notatki ({numberOfAllNotes})"
                  values={{ numberOfAllNotes }}
                />
              )}
              {title && ` (${numberOfAllNotes})`}
            </span>
            {objectId && (
              <Button
                type="primary"
                icon={<PlusIcon />}
                onClick={() => setIsModalVisible(true)}
                loading={isLoading}
              >
                <FormattedMessage defaultMessage="Dodaj notatke" />
              </Button>
            )}
            {shouldShowExtendedNotes && (
              <Button
                type="primary"
                icon={<ListTask />}
                onClick={() => setIsAllNotesModalOpen(true)}
                loading={isLoading}
              >
                <FormattedMessage defaultMessage="Zobacz wszystkie notatki" />
              </Button>
            )}
          </TitleWrapper>
        }
        emptyText={<FormattedMessage defaultMessage="Brak notatek" />}
        renderItems={(note) =>
          objectId && (
            <NoteItem
              objectId={objectId}
              object={noteType}
              key={note.id}
              note={note}
              handleSelectNote={(editNote) => setSelectedNote(editNote)}
              notify={notify}
              limitCharacters={500}
            />
          )
        }
      />
    </>
  );
};

export default Notes;
