/* eslint-disable react/jsx-props-no-spreading */
import type { EmptyProps } from 'antd';
import type { FC } from 'react';
import { Empty as AntEmpty } from 'antd';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

interface Props extends EmptyProps {
  size?: 'small' | 'medium' | 'large';
}

const Empty: FC<Props> = ({ size = 'medium', className, ...props }) => (
  <AntEmpty
    className={cn(
      styles.empty,
      {
        [styles.emptySmall]: size === 'small',
        [styles.emptyLarge]: size === 'large',
      },
      className,
    )}
    data-testid="emptyState"
    description={<FormattedMessage defaultMessage="Brak danych" />}
    {...props}
  />
);

export default Empty;
