import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type {
  Translator,
  UpdateTranslatorRequest,
} from 'ducks/translators/types';
import type { IntlShape } from 'react-intl';
import { Select, Switch } from 'antd';
import { FormattedMessage } from 'react-intl';
import LanguageSelect from 'components/ui/atoms/LanguageSelect';
import { FormOfSettlement } from 'constants/formOfSettlements';
import { genders } from 'constants/genders';
import { translatorTitles } from 'constants/translatorTitles';

type BasicInputValues = Pick<
  UpdateTranslatorRequest,
  | 'firstName'
  | 'lastName'
  | 'gender'
  | 'title'
  | 'langNative'
  | 'hasAgreement'
  | 'name'
>;

type Props = {
  intl: IntlShape;
  formOfSettlement: Translator['formOfSettlement'];
};

export const basicInputs = ({
  intl,
  formOfSettlement,
}: Props): FormItemRecord<BasicInputValues>[] => {
  const isBill = formOfSettlement === FormOfSettlement.BILL;

  return [
    {
      key: 1,
      label: <FormattedMessage defaultMessage="Nazwa:" />,
      name: 'name',
    },
    {
      key: 2,
      label: <FormattedMessage defaultMessage="Imię:" />,
      name: 'firstName',
      rules: [
        {
          required: isBill,
          message: <FormattedMessage defaultMessage="Podaj imię" />,
        },
      ],
    },
    {
      key: 3,
      label: <FormattedMessage defaultMessage="Nazwisko:" />,
      name: 'lastName',
      rules: [
        {
          required: isBill,
          message: <FormattedMessage defaultMessage="Podaj imię" />,
        },
      ],
    },
    {
      key: 4,
      label: <FormattedMessage defaultMessage="Płeć:" />,
      name: 'gender',
      component: <Select options={genders(intl)} allowClear />,
    },
    {
      key: 5,
      label: <FormattedMessage defaultMessage="Tytuł:" />,
      name: 'title',
      component: <Select options={translatorTitles(intl)} allowClear />,
    },
    {
      key: 6,
      label: <FormattedMessage defaultMessage="Język ojczysty:" />,
      name: 'langNative',
      component: <LanguageSelect />,
    },
    {
      key: 7,
      label: <FormattedMessage defaultMessage="Umowa:" />,
      name: 'hasAgreement',
      additionalItemProps: { valuePropName: 'checked' },
      component: <Switch />,
    },
  ];
};
