import billingualWordIcon from 'assets/images/billingualWord.svg';
import phraseIcon from 'assets/images/phrase.svg';
import xliffIcon from 'assets/images/xliff.svg';

export const POST_EDIT_VALUE = 1;

export enum CAT_IDS {
  PHRASE = 1,
  TRADOS = 2,
  MEMO1 = 3,
  SMARTCAT = 4,
  XTM = 5,
  XLIFF = 6,
  BILLINGUAL_WORD = 7,
}

export const catIcons: { [K: number]: string } = {
  [CAT_IDS.PHRASE]: phraseIcon,
  [CAT_IDS.XLIFF]: xliffIcon,
  [CAT_IDS.BILLINGUAL_WORD]: billingualWordIcon,
};
