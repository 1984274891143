import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { Col } from 'antd';
import { useParams } from 'react-router-dom';
import PaymentsTable from 'components/ui/organisms/Tables/PaymentTable/PaymentsTable';
import { PaymentObject } from 'ducks/payments/types';
import { useUnblockPreorder } from 'views/Preorders/useUnblockPreorder';
import { usePreorderDetailsServices } from '../../usePreorderDetailsServices';

type Props = {
  notify: NotificationInstance;
};

const PaymentsTableSection: FC<Props> = ({ notify }) => {
  const { id: preorderId = '' } = useParams();
  const { preorderData, isPreorderFetching } = usePreorderDetailsServices();
  const { handleUnblock, isLoading: isUnblockLoading } = useUnblockPreorder({
    notify,
  });
  const isLoading = isPreorderFetching || isUnblockLoading;

  return (
    <Col md={12} xs={24}>
      <PaymentsTable
        id={preorderId}
        paymentObject={PaymentObject.PREORDERS}
        isLoading={isLoading}
        skip={!preorderData}
        onSave={handleUnblock}
        isSaveLoading={isUnblockLoading}
      />
    </Col>
  );
};

export default PaymentsTableSection;
