import { useMemo } from 'react';
import { generateSkeletonHeaderCols } from 'components/ui/organisms/HeaderCard';
import OrderHeader from 'views/Orders/components/OrderHeader';
import { useOrderClientSendServices } from '../../useOrderClientSendServices';
import { headerCols } from './config';

const OrderClientSendHeader = () => {
  const { orderData } = useOrderClientSendServices();

  const cols = useMemo(
    () =>
      orderData
        ? headerCols({ data: orderData })
        : generateSkeletonHeaderCols(5),
    [orderData],
  );

  return <OrderHeader cols={cols} hidePrimaryCols />;
};

export default OrderClientSendHeader;
