import type { NotificationInstance } from 'antd/es/notification/interface';
import type { FC } from 'react';
import { Button, Card, Col, Row, Skeleton, Space, Typography } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Routes } from 'views/Translators/Routing';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import EditVacationModal from './components/EditVacationModal';
import { translatorHeaderCols } from './config';
import styles from './styles.module.scss';

type Props = {
  notify: NotificationInstance;
};

const { Text } = Typography;

const TranslatorHeader: FC<Props> = ({ notify }) => {
  const { translatorId, translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();
  const [isEditVacationModalOpen, setIsEditVacationModalOpen] = useState(false);

  return (
    <>
      {translatorData && (
        <EditVacationModal
          isOpen={isEditVacationModalOpen}
          onClose={() => setIsEditVacationModalOpen(false)}
          data={translatorData}
          notify={notify}
        />
      )}
      <Card
        data-testid="translatorHeaderCard"
        className={styles.cardClientHead}
        bodyStyle={{ display: 'none' }}
        title={
          <div className={styles.cardTitleWrapper}>
            <Row
              className="width-100"
              justify="start"
              gutter={[80, 12]}
              align="middle"
            >
              {translatorHeaderCols({
                data: translatorData,
                openEditVacationModal: () => setIsEditVacationModalOpen(true),
              }).map(({ key, firstRow, secondRow, align = 'center' }) => (
                <Col key={key}>
                  <Space
                    direction="vertical"
                    align={isTranslatorFetching ? undefined : align}
                  >
                    <Text ellipsis>
                      <Skeleton
                        loading={isTranslatorFetching}
                        round
                        paragraph={false}
                        active
                        style={{ width: '75px' }}
                      >
                        {firstRow}
                      </Skeleton>
                    </Text>
                    <Skeleton
                      loading={isTranslatorFetching}
                      round
                      paragraph={false}
                      active
                    >
                      {secondRow}
                    </Skeleton>
                  </Space>
                </Col>
              ))}
            </Row>
            <Link
              to={Routes.TRANSLATOR_PROFILE_FORM.replace(
                ':id',
                translatorId.toString(),
              )}
              rel="noopener noreferrer"
            >
              <Button type="primary" loading={isTranslatorFetching}>
                <FormattedMessage defaultMessage="Edytuj dane" />
              </Button>
            </Link>
          </div>
        }
      />
    </>
  );
};

export default TranslatorHeader;
