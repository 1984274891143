import type { Translator, TranslatorPricing } from 'ducks/translators/types';
import { FileTranslationType } from 'constants/fileTranslation';

const SPECIAL_CORRECTION_MULTIPLIER = 0.6;

export type GetTranslatorMinPriceFromInput = {
  minFrom: Pick<
    Translator,
    'minNormalFrom' | 'minSpecialFrom' | 'minCorrectionFrom'
  >;
  isSpecial: boolean;
  isCorrection: boolean;
};

export type GetTranslatorPriceFromInput = {
  type: FileTranslationType;
  isSpecial: boolean;
  isCorrection: boolean;
} & Pick<TranslatorPricing, 'normalFrom' | 'specialFrom' | 'correctionFrom'>;

export const getTranslatorMinPriceFrom = ({
  minFrom: { minNormalFrom, minSpecialFrom, minCorrectionFrom },
  isCorrection,
  isSpecial,
}: GetTranslatorMinPriceFromInput) => {
  if (isCorrection && isSpecial && minSpecialFrom !== null) {
    return minSpecialFrom * SPECIAL_CORRECTION_MULTIPLIER;
  }

  if (isCorrection) {
    return minCorrectionFrom ?? 0;
  }

  if (isSpecial) {
    return minSpecialFrom ?? 0;
  }

  return minNormalFrom ?? 0;
};

export const getTranslatorPriceFrom = ({
  type,
  normalFrom,
  specialFrom,
  correctionFrom,
  isCorrection,
  isSpecial,
}: GetTranslatorPriceFromInput) => {
  if (type === FileTranslationType.NORMAL) {
    if (isSpecial && isCorrection) return specialFrom ?? 0;

    if (isSpecial) return specialFrom ?? 0;

    if (isCorrection) return correctionFrom ?? 0;

    return normalFrom ?? 0;
  }

  if (type === FileTranslationType.SWORN) {
    if (isSpecial) return specialFrom ?? 0;

    return normalFrom ?? 0;
  }

  if (type === FileTranslationType.CORRECTION) {
    return correctionFrom ?? 0;
  }

  return 0;
};
