import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetClientDetailsQuery } from 'ducks/clients/service';

export const useClientDetailsServices = () => {
  const { clientId: paramClientId = '' } = useParams();
  const clientId = Number(paramClientId);
  const { data: clientData, isFetching: isClientFetching } =
    useGetClientDetailsQuery({
      clientId,
    });
  const isClientEmpty = !clientData && !isClientFetching;

  const values = useMemo(
    () => ({ clientId, clientData, isClientFetching, isClientEmpty }),
    [clientId, clientData, isClientFetching, isClientEmpty],
  );

  return values;
};
