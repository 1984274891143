import type { IntlShape } from 'react-intl';

export type TranslatorTitle = {
  value: string | number;
  label: string;
};

export const translatorTitles = (intl: IntlShape): TranslatorTitle[] => [
  { value: 1, label: intl.formatMessage({ defaultMessage: 'bez tytułu' }) },
  { value: 2, label: intl.formatMessage({ defaultMessage: 'licencjat' }) },
  { value: 3, label: intl.formatMessage({ defaultMessage: 'inż.' }) },
  { value: 4, label: intl.formatMessage({ defaultMessage: 'mgr' }) },
  { value: 5, label: intl.formatMessage({ defaultMessage: 'mgr inż.' }) },
  { value: 6, label: intl.formatMessage({ defaultMessage: 'dr inż.' }) },
  { value: 7, label: intl.formatMessage({ defaultMessage: 'dr' }) },
  { value: 8, label: intl.formatMessage({ defaultMessage: 'dr hab.' }) },
  { value: 9, label: intl.formatMessage({ defaultMessage: 'doc.' }) },
  { value: 10, label: intl.formatMessage({ defaultMessage: 'prof.' }) },
  { value: 11, label: intl.formatMessage({ defaultMessage: 'inny' }) },
];
