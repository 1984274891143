import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { PriceItem } from 'ducks/prices/types';
import { FormattedMessage } from 'react-intl';
import LanguageSelect from 'components/ui/atoms/LanguageSelect';

export type AddLanguagePairModalValues = Pick<PriceItem, 'langFrom' | 'langTo'>;

export const initialValues: AddLanguagePairModalValues = {
  langFrom: '',
  langTo: '',
};

export const formItems: FormItemRecord<AddLanguagePairModalValues>[] = [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Tłumaczenie z języka" />,
    name: 'langFrom',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz język" />,
      },
    ],
    component: <LanguageSelect allowClear={false} />,
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="Tłumaczenie na język" />,
    name: 'langTo',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz język" />,
      },
    ],
    component: <LanguageSelect allowClear={false} />,
  },
];
