import type { FormInstance } from 'antd';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { PriceItem } from 'ducks/prices/types';
import { Switch } from 'antd';
import { FormattedMessage } from 'react-intl';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';

export type GeneralPriceChangeModalValues = Pick<PriceItem, 'normal'> & {
  isPercentage: boolean;
};

type Props = {
  form: FormInstance<GeneralPriceChangeModalValues>;
  isPercentage: boolean;
};

export const initialValues: GeneralPriceChangeModalValues = {
  normal: 0,
  isPercentage: false,
};

export const formItems = ({
  form,
  isPercentage,
}: Props): FormItemRecord<GeneralPriceChangeModalValues>[] => [
  {
    key: 1,
    label: (
      <FormattedMessage defaultMessage="Zmień cenę tłumaczenia zwykłego o wartość" />
    ),
    name: 'normal',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj wartość" />,
      },
    ],
    component: isPercentage ? (
      <DecimalInputNumber
        data-testid="generalPriceInput"
        className="width-100"
        min={-100}
        max={100}
        addonAfter="%"
        onChange={(value) => {
          if (Number(value) > 100) {
            form.setFieldValue('normal', 100);
          }
          if (Number(value) < -100) {
            form.setFieldValue('normal', -100);
          }
        }}
      />
    ) : (
      <DecimalInputNumber
        data-testid="generalPriceInput"
        className="width-100"
        min={undefined}
      />
    ),
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="Procentowe" />,
    name: 'isPercentage',
    additionalItemProps: { valuePropName: 'checked' },
    component: (
      <Switch
        onChange={(isChecked) => {
          if (isChecked) {
            if (form.getFieldValue('normal') > 100) {
              form.setFieldValue('normal', 100);
            }
            if (form.getFieldValue('normal') < -100) {
              form.setFieldValue('normal', -100);
            }
          }
        }}
      />
    ),
  },
];
