import type { FC } from 'react';
import { Skeleton } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import TranslatorsModal from 'components/ui/organisms/Modals/TranslatorsModal';
import { NO_DATA } from 'constants/strings';
import { useAppDispatch } from 'ducks/store';
import { useGetTranslatorDetailsQuery } from 'ducks/translators/service';
import {
  changeOrderTranslationFileTranslator,
  type OrderTranslationFilesState,
} from 'ducks/view/orderTranslationFiles';
import { ChevronDown } from 'icons';
import { getTranslatorName } from 'utilities/translator';
import styles from './styles.module.scss';

type Props = {
  orderFileId: number;
  orderTranslationFileId: number | string;
  translatorId: number | null;
  orderFileLang: OrderTranslationFilesState['orderFiles'][number]['orderFileLangs'][number];
};

const TranslatorColumn: FC<Props> = ({
  orderFileId,
  orderTranslationFileId,
  translatorId,
  orderFileLang,
}) => {
  const { id: orderId = '' } = useParams();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, isFetching } = useGetTranslatorDetailsQuery(
    {
      translatorId: Number(translatorId),
    },
    { skip: !translatorId },
  );
  const translatorName = getTranslatorName({
    ...data,
  });

  const handleChangeTranslationFileTranslator = (id: number) =>
    dispatch(
      changeOrderTranslationFileTranslator({
        orderFileId,
        orderFileLangId: orderFileLang.id,
        orderTranslationFileId,
        newTranslatorId: id,
      }),
    );

  return (
    <>
      {isFetching ? (
        <Skeleton.Button style={{ width: '100%' }} active />
      ) : (
        <Button
          className={styles.button}
          size="small"
          type="text"
          onClick={() => setIsModalOpen(true)}
        >
          <span>{data && translatorId ? translatorName : NO_DATA}</span>
          <ChevronDown />
        </Button>
      )}
      <TranslatorsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onChange={handleChangeTranslationFileTranslator}
        selectedTranslatorId={translatorId}
        orderFileLang={orderFileLang}
        filter={{
          orderId,
        }}
        autoSelectTranslator={!translatorId}
        skip={false}
      />
    </>
  );
};

export default TranslatorColumn;
