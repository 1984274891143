import type { ClientRow } from './config';
import type { Client } from 'ducks/clients/types';
import { Space } from 'antd';
import { useMemo, type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/ui/molecules/Modal';
import Table from 'components/ui/molecules/Table';
import { columns } from './config';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAccept?: () => void;
  onClientClick?: (client: Client) => void;
  duplicateClients: Client[];
  title?: string;
  okText?: string;
};

const DuplicateClientsModal: FC<Props> = ({
  isOpen,
  onClose,
  onAccept,
  onClientClick,
  duplicateClients,
  title,
  okText,
}) => {
  const handleOk = () => onAccept?.();

  const modifiedData = useMemo(
    (): ClientRow[] =>
      duplicateClients.map((client) => ({ ...client, key: client.id })),
    [duplicateClients],
  );

  return (
    <Modal
      data-testid="duplicateClientsModal"
      className={styles.modal}
      title={title ?? <FormattedMessage defaultMessage="Uwaga!" />}
      open={isOpen}
      onCancel={onClose}
      onOk={handleOk}
      okText={
        okText ?? (
          <FormattedMessage defaultMessage="Kontynuuj dodawanie nowego klienta" />
        )
      }
      cancelButtonProps={{ hidden: true }}
    >
      <Space className="width-100" direction="vertical" size="large">
        <FormattedMessage defaultMessage="W bazie znaleziono następujące wpisy:" />
        <Table
          data={modifiedData}
          columns={columns({ onClientClick })}
          onRow={({ id }) => {
            const testId = `duplicateClientRow-${id}`;

            return {
              'data-testid': testId,
              id: testId,
            };
          }}
        />
      </Space>
    </Modal>
  );
};

export default DuplicateClientsModal;
