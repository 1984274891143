import type { UploadedFile } from 'ducks/global/types';
import type { OrderSendEmailsTranslatorState } from 'ducks/view/orders';
import { Space, type UploadFile } from 'antd';
import { useEffect, type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import Switch from 'components/ui/atoms/Switch';
import TruncateText from 'components/ui/atoms/TruncateText';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import Upload, { UploadItem } from 'components/ui/molecules/Upload';
import { useAppDispatch } from 'ducks/store';
import {
  toggleOrderSendEmailsUploadedFiles,
  toggleOrderSendEmailsAttachments,
} from 'ducks/view/orders';

type Props = Pick<
  OrderSendEmailsTranslatorState,
  'translatorId' | 'attachments' | 'uploadedFiles'
> & {
  files: (UploadedFile | undefined)[] | undefined;
};

const AttachmentSwitches: FC<Props> = ({
  translatorId,
  attachments,
  files,
  uploadedFiles,
}) => {
  const dispatch = useAppDispatch();

  const handleToggleUploadedFiles = (file: UploadFile) =>
    dispatch(toggleOrderSendEmailsUploadedFiles({ translatorId, file }));

  const handleChangeSelectedAttachments = (id: number) =>
    dispatch(toggleOrderSendEmailsAttachments({ translatorId, id }));

  useEffect(() => {
    if (!files) return;
    files?.forEach((file) => {
      if (!file) return;

      const isChecked = attachments.some(
        (attachment) => attachment === file.id,
      );

      if (isChecked) return;

      dispatch(
        toggleOrderSendEmailsAttachments({ translatorId, id: file?.id }),
      );
    });
  }, [dispatch, translatorId, files, attachments]);

  return (
    <div>
      <TitleWrapper>
        <TruncateText strong maxLength={50}>
          <FormattedMessage defaultMessage="Załącznik" />
        </TruncateText>
        <Upload
          onChange={({ file }) => handleToggleUploadedFiles(file)}
          fullWidth={false}
        >
          <Button>
            <FormattedMessage defaultMessage="Dodaj" />
          </Button>
        </Upload>
      </TitleWrapper>
      <Space className="width-100" direction="vertical">
        {files?.length &&
          files?.map((file) => {
            if (!file) return null;

            const { id, filename } = file;
            return (
              <Switch
                key={id}
                checked={attachments.some((attachment) => attachment === id)}
                label={<TruncateText>{filename}</TruncateText>}
                onChange={() => handleChangeSelectedAttachments(id)}
              />
            );
          })}
        {uploadedFiles.map((file) => (
          <UploadItem
            key={file.uid}
            uploadFile={file}
            onDelete={handleToggleUploadedFiles}
          />
        ))}
      </Space>
    </div>
  );
};

export default AttachmentSwitches;
