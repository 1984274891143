/* eslint-disable react/jsx-props-no-spreading */
import type { EditTranslatorValues } from '../../config';
import type { FormInstance } from 'antd';
import type { GusReturnProps } from 'components/ui/atoms/GusButton';
import type { FC } from 'react';
import { Col, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import { invoiceInputs } from './config';

interface Props {
  form: FormInstance<EditTranslatorValues>;
}

const { useWatch } = Form;

const InvoiceSection: FC<Props> = ({ form }) => {
  const intl = useIntl();
  const formOfSettlement = useWatch('formOfSettlement', form) ?? null;
  const countryCode = useWatch('invoiceCountry', form) ?? '';
  const nip = useWatch('nip', form) ?? '';
  const invoiceCountry = useWatch('invoiceCountry', form);

  const handleCheckNipSuccessCallback = ({
    regon,
    name,
    city,
    post_code: invoicePostCode,
    addressNumbers,
    street,
  }: GusReturnProps) => {
    form.setFieldsValue({
      regon,
      invoiceName: name,
      invoiceAddress: `${street} ${addressNumbers.join(', ')}`,
      invoicePostCode,
      invoiceCity: city,
    });
  };

  return (
    <Col span={24} md={8}>
      <>
        <h6>
          <FormattedMessage defaultMessage="Dane rozliczeniowe" />
        </h6>
        {invoiceInputs({
          intl,
          form,
          formOfSettlement,
          nip,
          countryCode,
          handleCheckNipSuccessCallback,
          invoiceCountry,
        }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </>
    </Col>
  );
};

export default InvoiceSection;
