/* eslint-disable react/jsx-props-no-spreading */
import type { ListItemRecord } from './config';
import type { ComponentPropsWithoutRef } from 'react';
import { List as AntdList, Skeleton } from 'antd';
import cn from 'classnames';
import styles from './styles.module.scss';

interface Props
  extends Omit<ComponentPropsWithoutRef<typeof AntdList>, 'dataSource'> {
  items: ListItemRecord[];
  isLoading?: boolean;
  withoutMargin?: boolean;
  scrollable?: boolean;
}

const { Item } = AntdList;
const { Meta } = Item;

const List = ({
  items,
  isLoading = false,
  className,
  withoutMargin = false,
  scrollable = false,
  ...props
}: Props) => {
  return (
    <AntdList
      {...props}
      className={cn(className, styles.list, {
        [styles.listWithoutMargin]: withoutMargin,
        [styles.listScrollable]: scrollable,
      })}
      dataSource={items}
      renderItem={({
        label,
        avatar,
        description,
        component,
        skeletonRows = 2,
        hidden = false,
      }) => {
        const isDescription = typeof description !== 'undefined';
        const itemStyle =
          skeletonRows === 1 && isLoading
            ? {
                marginTop: '6px',
              }
            : {};
        const paragraphStyle =
          skeletonRows === 1
            ? {
                width: '100%',
              }
            : {};

        return (
          !hidden && (
            <Item style={itemStyle}>
              <Skeleton
                avatar={!!avatar}
                title={false}
                loading={isLoading}
                active
                paragraph={{
                  rows: skeletonRows,
                  ...paragraphStyle,
                }}
              >
                <Meta
                  avatar={avatar}
                  title={isDescription ? label : ''}
                  style={{ wordBreak: 'break-word' }}
                  description={isDescription ? description ?? '---' : label}
                />
                {component}
              </Skeleton>
            </Item>
          )
        );
      }}
    />
  );
};

export default List;
