import type { IntlShape } from 'react-intl';
import { ProformaType } from 'ducks/preorders/types';
import { LanguageCode } from './countryAndLanguageCodes';
import { FileTranslationType } from './fileTranslation';

export enum PreorderStatus {
  NEW = 0,
  NOT_SENT = 1,
  SENT = 2,
  IN_REALIZATION = 3,
}

export enum PreorderInvoiceType {
  PAPER = 1,
  DIGITAL = 2,
}

type PreorderDiscountRange = Record<
  FileTranslationType,
  Record<string, { [range: string]: number }>
>;

type PreorderRepetitionDiscountRange = { [range: string]: number };

type Option<T> = {
  value: T;
  label: string;
};

export const preorderInvoiceTypes = (
  intl: IntlShape,
): Option<PreorderInvoiceType>[] => [
  {
    value: 1,
    label: intl.formatMessage({ defaultMessage: 'Papierowa' }),
  },
  {
    value: 2,
    label: intl.formatMessage({ defaultMessage: 'Elektroniczna' }),
  },
];

export const preorderInvoiceTypesObject = (intl: IntlShape) =>
  preorderInvoiceTypes(intl).reduce((acc, { value, label }) => {
    acc[value] = {
      value,
      label,
    };

    return acc;
  }, {} as Record<PreorderInvoiceType, Option<PreorderInvoiceType>>);

export const preorderStatuses = (intl: IntlShape): Option<PreorderStatus>[] => [
  {
    value: PreorderStatus.NEW,
    label: intl.formatMessage({ defaultMessage: 'Nowa' }),
  },
  {
    value: PreorderStatus.NOT_SENT,
    label: intl.formatMessage({ defaultMessage: 'Niewysłana' }),
  },
  {
    value: PreorderStatus.SENT,
    label: intl.formatMessage({ defaultMessage: 'Wysłana' }),
  },
  {
    value: PreorderStatus.IN_REALIZATION,
    label: intl.formatMessage({ defaultMessage: 'Przyjęto do realizacji' }),
  },
];

export const preorderStatusesObject = (intl: IntlShape) =>
  preorderStatuses(intl).reduce((acc, { value, label }) => {
    acc[value] = {
      value,
      label,
    };

    return acc;
  }, {} as Record<PreorderStatus, Option<PreorderStatus>>);

export const proformaTypes = (intl: IntlShape): Option<ProformaType>[] => [
  {
    value: ProformaType.FULL_PAYMENT,
    label: intl.formatMessage({ defaultMessage: 'Proforma 100%' }),
  },
  {
    value: ProformaType.HALF_PAYMENT,
    label: intl.formatMessage({ defaultMessage: 'Proforma 50%' }),
  },
];

export const proformaTypesObject = (intl: IntlShape) =>
  proformaTypes(intl).reduce((acc, { value, label }) => {
    acc[value] = {
      value,
      label,
    };

    return acc;
  }, {} as Record<ProformaType, Option<ProformaType>>);

export const MAX_GENERATED_PREORDER_DISCOUNT = 35;

export const preorderDiscountRange: PreorderDiscountRange = {
  [FileTranslationType.NORMAL]: {
    [[
      LanguageCode.ENGLISH,
      LanguageCode.GERMAN,
      LanguageCode.UKRAINE,
      LanguageCode.RUSSIAN,
      LanguageCode.FRENCH,
      LanguageCode.ITALIAN,
      LanguageCode.SPANISH,
    ].join('-')]: {
      '200-499.99': 5,
      '500-999.99': 10,
      '1000-1999.99': 15,
      '2000-2999.99': 20,
      '3000-4999.99': 25,
      '5000': 30,
    },
    '': {
      '1000-1999.99': 5,
      '2000-2999.99': 10,
      '3000-4999.99': 15,
      '5000': 20,
    },
  },
  [FileTranslationType.SWORN]: {
    '': {
      '1000-1999.99': 5,
      '2000-2999.99': 10,
      '3000-4999.99': 15,
      '5000': 20,
    },
  },
  [FileTranslationType.CORRECTION]: {},
};

export const preorderRepetitionDiscountRange: PreorderRepetitionDiscountRange =
  {
    '30-39.99': 5,
    '40-49.99': 10,
    '50-59.99': 15,
    '60-69.99': 20,
    '70': 25,
  };
