import type { PreorderVariantLangSlice } from 'ducks/view/preorders';
import { Popconfirm } from 'antd';
import { memo, type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { useAppDispatch } from 'ducks/store';
import {
  deletePreorderVariantFile,
  deletePreorderVariantLang,
} from 'ducks/view/preorders';
import { TrashIcon } from 'icons';
import styles from './styles.module.scss';

type Props = {
  variantId: string | number;
  variantFileId: string | number;
  preorderVariantLangs: PreorderVariantLangSlice[];
};

const ActionsColumn: FC<Props> = memo(
  ({ variantId, variantFileId, preorderVariantLangs }) => {
    const dispatch = useAppDispatch();
    const isLackOfVariantLangs = !preorderVariantLangs.length;

    const handleDeleteFile = () =>
      dispatch(deletePreorderVariantFile({ variantId, variantFileId }));

    const handleDeleteLang = (variantLangId: string | number) =>
      dispatch(
        deletePreorderVariantLang({
          variantFileId,
          variantLangId,
        }),
      );

    return (
      <div className={styles.wrapper}>
        {isLackOfVariantLangs && (
          <Popconfirm
            title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
            okText={<FormattedMessage defaultMessage="Tak" />}
            cancelText={<FormattedMessage defaultMessage="Nie" />}
            onConfirm={handleDeleteFile}
          >
            <Button
              data-testid="deleteVariantFileButton"
              size="small"
              type="link"
              icon={<TrashIcon />}
              danger
            >
              <FormattedMessage defaultMessage="Usuń" />
            </Button>
          </Popconfirm>
        )}
        {preorderVariantLangs.map(({ id }) => (
          <Popconfirm
            key={id}
            title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
            okText={<FormattedMessage defaultMessage="Tak" />}
            cancelText={<FormattedMessage defaultMessage="Nie" />}
            onConfirm={() => handleDeleteLang(id)}
          >
            <Button
              data-testid={`deleteButton-${id}`}
              size="small"
              type="link"
              icon={<TrashIcon />}
              danger
            >
              <FormattedMessage defaultMessage="Usuń" />
            </Button>
          </Popconfirm>
        ))}
      </div>
    );
  },
);

export default ActionsColumn;
