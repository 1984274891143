import { InvoiceOverdueStatus } from 'ducks/invoices/types';
import styles from './styles.module.scss';

export const getRowColorByInvoiceOverdueStatus = (
  status: InvoiceOverdueStatus,
) => {
  switch (status) {
    case InvoiceOverdueStatus.SUMMONS:
      return styles.tableRowStatusSummons;
    case InvoiceOverdueStatus.THIRTY_DAYS:
      return styles.tableRowStatusThirtyDays;
    case InvoiceOverdueStatus.KRD:
      return styles.tableRowStatusKrd;
    case InvoiceOverdueStatus.COURT:
      return styles.tableRowStatusCourt;
    case InvoiceOverdueStatus.PAID:
      return styles.tableRowStatusPaid;
    case InvoiceOverdueStatus.JUDGMENT:
      return styles.tableRowStatusJudgment;
    case InvoiceOverdueStatus.NOT_SENT:
      return styles.tableRowStatusNotSent;
    default:
      return '';
  }
};
