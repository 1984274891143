import type {
  AddOrderFileLangPairPayload,
  OrderFileLangState,
  OrderFileState,
  OrderSendEmailsTranslatorState,
  OrderServiceState,
} from './type';
import type { ServiceAttributeNames } from 'constants/services';
import type { UploadedFile } from 'ducks/global/types';
import type {
  Order,
  OrderFile,
  OrderFileLang,
  OrderService,
  OrderServiceValue,
} from 'ducks/orders/types';
import type { Translator } from 'ducks/translators/types';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import { DefaultValue } from 'ducks/types';

type GetInitialEmailsTranslatorValuesInput = {
  translator: Translator;
  files: UploadedFile[];
  langFrom: string;
  langTo: string;
  values: Partial<OrderSendEmailsTranslatorState>;
};

const calculateDateRealzation = (
  timeRealization: string | undefined | null,
) => {
  const date = timeRealization
    ? dayjs(timeRealization)
    : dayjs().hour(7).minute(30).second(0).millisecond(0);

  return date.format();
};

export const getInitialOrderFileLangValues = ({
  orderFileId,
  dateTranslationRealizationDefault,
  langFrom,
  langTo,
}: AddOrderFileLangPairPayload): OrderFileLangState => ({
  id: v4(),
  orderFileId,
  catId: null,
  translatorId: null,
  translator: null,
  characters: 0,
  pages: 0,
  price: 0,
  pagesTranslator: 1,
  priceSpecialNetto: 0,
  priceCorrectionNetto: 0,
  translatorPriceNetto: 0,
  discount: 0,
  isPostedit: 0,
  isAccepted: false,
  langFrom,
  langTo,
  dateRealization: calculateDateRealzation(dateTranslationRealizationDefault),
  timeRealization: 0,
});

export const getInitialOrderSendEmailsTranslatorValues = ({
  translator,
  files,
  langFrom,
  langTo,
  values,
}: GetInitialEmailsTranslatorValuesInput): OrderSendEmailsTranslatorState => {
  const { id, firstName, lastName, email1, email2, emailDefault } = translator;

  const name = [firstName, lastName].join(' ');
  const email = emailDefault === DefaultValue.FIRST ? email1 : email2;

  return {
    translatorId: id,
    isChecked: true,
    selectedEmailTemplate: '',
    files,
    uploadedFiles: [],
    langs: [{ langFrom, langTo }],
    name,
    subject: '',
    email,
    cc: '',
    body: '',
    isSms: false,
    smsText: '',
    isReferenceFile: false,
    attachments: [],
    ...values,
  };
};

export const generateOrderFiles = (
  orderFiles: OrderFile[],
  orderFileLangs: OrderFileLang[],
): OrderFileState[] =>
  orderFiles
    .map((order) => ({
      ...order,
      specializations: order.specializations.map(({ id }) => id),
      orderFileLangs: orderFileLangs.filter(
        ({ orderFileId }) => orderFileId === order.id,
      ),
    }))
    .filter(({ orderFileLangs: langs }) => langs.length);

export const generateOrderServices = (
  orderServices: OrderService[],
  orderServiceValues: OrderServiceValue[],
): OrderServiceState[] =>
  orderServices.map((orderService) => ({
    ...orderService,
    orderServiceValues: orderServiceValues.filter(
      ({ orderServiceId }) => orderServiceId === orderService.id,
    ),
  }));

export const getTranslatorPriceNetto = (
  priceFrom: number,
  pagesTranslator: number,
) => Number((pagesTranslator * priceFrom).toFixed(2));

export const getTranslatorPriceNettoPerPage = (
  translatorPriceNetto: number,
  pagesTranslator: number,
) => Number((translatorPriceNetto / pagesTranslator).toFixed(2));

export const getOrderServiceInitialValue = (
  serviceValues: Order['orderServiceValues'],
  attribiuteName: ServiceAttributeNames,
) =>
  serviceValues.find(
    (serviceValue) => serviceValue.attributeName === attribiuteName,
  )?.attributeValue;
