export enum SettingsTag {
  SETTINGS = 'settings',
  EMAIL_TEMPLATES = 'emailTemplates',
}

export type GetSettingsRequest = {
  sectionId: string;
};

export type GetSettingsResponse = ResponseFormItem[];

export type SendPresentationRequest = {
  sectionId: string;
  file: File;
  key: string;
};

export type GetEmailTemplatesResponse = EmailTemplate[];

export type GetEmailTemplateRequest = {
  id: string;
};

export type GetEmailTemplateResponse = EmailTemplate;

export type AddEmailTemplateRequest = {
  title: string;
  content: string;
};

export type EditEmailTemplateRequest = AddEmailTemplateRequest & {
  id: string;
};

export type ResponseFormItem = {
  id: number;
  section: number;
  name: string;
  value: string | number | boolean | string[];
  type: string;
};

export type EmailTemplate = {
  id: number;
  title: string;
  content: string;
};
