import { Col, Empty, Row, Space, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import Notes from 'components/ui/organisms/Notes/Notes';
import { NoteType } from 'ducks/notes/types';
import ClientHeader from './components/ClientHeader';
import ContactCard from './components/ContactCard';
import ContactPersons from './components/ContactPersons';
import CorrespondenceCard from './components/CorrespondenceCard';
import InvoiceCard from './components/InvoiceCard';
import OrdersCard from './components/OrdersCard';
import { useClientDetailsServices } from './useClientDetailsServices';

const ClientDetails = () => {
  const [notify, contextHolder] = notification.useNotification();
  const { clientId, clientData, isClientFetching, isClientEmpty } =
    useClientDetailsServices();
  const sharedProps = {
    data: clientData,
    isLoading: isClientFetching,
    notify,
  };

  return (
    <div className="layout-content mb-24" data-testid="client-details">
      {contextHolder}
      {isClientEmpty ? (
        <Empty
          data-testid="clientDetailsEmpty"
          description={
            <FormattedMessage defaultMessage="Brak danych o kliencie" />
          }
        />
      ) : (
        <Space direction="vertical" size="large" className="width-100">
          <ClientHeader {...sharedProps} />
          <Row gutter={[24, 24]} align="top">
            <ContactCard {...sharedProps} />
            <InvoiceCard notify={notify} />
            <CorrespondenceCard {...sharedProps} />
          </Row>
          <Row gutter={[24, 24]} align="top">
            <Col span={24} md={12} xs={24}>
              <OrdersCard
                ordersCount={clientData?.ordersCount}
                preordersCount={clientData?.preordersCount}
                complaintsCount={clientData?.complaintsCount}
                ordersInProgressCount={clientData?.ordersInProgressCount}
              />
            </Col>
            <Col span={24} md={12} xs={24}>
              <ContactPersons notify={notify} />
            </Col>
          </Row>
          <Notes
            notify={notify}
            objectId={clientId}
            noteType={NoteType.CLIENTS}
          />
        </Space>
      )}
    </div>
  );
};

export default ClientDetails;
