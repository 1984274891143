import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { PaymentTransaction } from 'ducks/payments/types';
import { Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { NO_DATA } from 'constants/strings';
import { PaymentTransactionSource } from 'ducks/payments/types';

export type PaymentTransactionRow = TableRow & PaymentTransaction;

type Props = {
  selectedPaymentTransactionUid: string | undefined;
  handleChangePaymentTransaction: (
    paymentTransaction: PaymentTransaction,
  ) => void;
};

export const columns = ({
  selectedPaymentTransactionUid,
  handleChangePaymentTransaction,
}: Props): ColumnsType<PaymentTransactionRow> => [
  {
    key: 'radio',
    dataIndex: 'radio',
    title: <FormattedMessage defaultMessage="Wybierz" />,
    width: 20,
    align: 'center',
    render: (_, paymentTransaction) => {
      const { uid } = paymentTransaction;
      const checked = uid === selectedPaymentTransactionUid;

      return (
        <Radio
          data-testid={`radio-${uid}`}
          checked={checked}
          onChange={() => handleChangePaymentTransaction(paymentTransaction)}
        />
      );
    },
  },
  {
    key: 'clientName',
    dataIndex: 'clientName',
    title: <FormattedMessage defaultMessage="Nazwa klienta" />,
    render: (_, { uid, client: { name } }) => (
      <span data-testid={`clientName-${uid}`}>{name}</span>
    ),
  },
  {
    key: 'invoiceNumber',
    dataIndex: 'invoiceNumber',
    title: <FormattedMessage defaultMessage="Numer faktury" />,
    render: (_, { uid, objectType, number }) => (
      <span data-testid={`invoiceNumber-${uid}`}>
        {objectType === PaymentTransactionSource.INVOICES ? number : NO_DATA}
      </span>
    ),
  },
  {
    key: 'orderNumbers',
    dataIndex: 'orderNumbers',
    title: <FormattedMessage defaultMessage="Numery zamówień" />,
    render: (_, { uid, objectType, orders, number }) => {
      if (objectType === PaymentTransactionSource.INVOICES) {
        return (
          <span data-testid={`orderNumbers-${uid}`}>
            {orders.length
              ? orders.map((order) => order.number).join(', ')
              : NO_DATA}
          </span>
        );
      }

      return <span data-testid={`orderNumbers-${uid}`}>{number}</span>;
    },
  },
  {
    key: 'amount',
    dataIndex: 'amount',
    title: <FormattedMessage defaultMessage="Kwota faktury" />,
    render: (_, { uid, amount }) => (
      <span data-testid={`amount-${uid}`}>
        <CurrencyFormatter value={amount} />
      </span>
    ),
  },
];
