import type { PreorderVariantSlice } from 'ducks/view/preorders';
import type { FC } from 'react';
import { Col } from 'antd';
import { useMemo } from 'react';
import AllCurrenciesInfo from 'components/ui/molecules/AllCurrenciesInfo';
import List from 'components/ui/molecules/List';
import { sharedSummaryListItems } from 'constants/summaryList';
import {
  calcPreorderVariantFilesCorrectionTotal,
  calcPreorderVariantFilesPriceNettoTotal,
  calcPreorderVariantFilesSpecialTotal,
  calcPreorderVariantPriceTotal,
  calcPreorderVariantServicesPriceNettoTotal,
} from 'utilities/calcPreorderTotals';
import styles from '../../styles.module.scss';

type Props = {
  variant: PreorderVariantSlice;
};

const PreorderVariantSummaryPrices: FC<Props> = ({ variant }) => {
  const { preorderVariantFiles, preorderVariantServices } = variant;

  const { translationsTotal, correctionTotal, specialTotal } = useMemo(
    () => ({
      translationsTotal:
        calcPreorderVariantFilesPriceNettoTotal(preorderVariantFiles),
      correctionTotal:
        calcPreorderVariantFilesCorrectionTotal(preorderVariantFiles),
      specialTotal: calcPreorderVariantFilesSpecialTotal(preorderVariantFiles),
    }),
    [preorderVariantFiles],
  );

  const { graphicTotal, shipmentTotal, preparatoryTotal, additionalTotal } =
    useMemo(
      () => calcPreorderVariantServicesPriceNettoTotal(preorderVariantServices),
      [preorderVariantServices],
    );

  const { total, preorderVariantPriceTotal } = useMemo(
    () => ({
      total: calcPreorderVariantPriceTotal(variant),
      preorderVariantPriceTotal: calcPreorderVariantPriceTotal(variant),
    }),
    [variant],
  );

  return (
    <Col xs={24} md={12}>
      <List
        className={styles.listItem}
        items={[
          ...sharedSummaryListItems({
            translationsTotal,
            specialTotal,
            correctionTotal,
            graphicTotal,
            shipmentTotal,
            preparatoryTotal,
            additionalTotal,
            total,
          }),
        ]}
        withoutMargin
      />
      <AllCurrenciesInfo price={preorderVariantPriceTotal} />
    </Col>
  );
};

export default PreorderVariantSummaryPrices;
