import type { ContactPersonRow } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { ContactPerson } from 'ducks/clients/types';
import type { FC } from 'react';
import { Button, Form } from 'antd';
import { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import EditableTable from 'components/ui/molecules/EditableTable/EditableTable';
import { PlusIcon } from 'icons';
import AddContactPersonModal from './components/AddContactPersonModal/AddContactPersonModal';
import { columns } from './config';
import { useContactPersonsApiActions } from './useContactPersonsApiActions';

interface Props {
  notify: NotificationInstance;
}

const { useForm, useWatch } = Form;

const ContactPersons: FC<Props> = ({ notify }) => {
  const [form] = useForm<ContactPerson>();
  const isSms = useWatch('isSms', form);
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const {
    data,
    isLoading,
    handleUpdateContact,
    isUpdateLoading,
    handleDeleteContact,
    isDeleteLoading,
  } = useContactPersonsApiActions(notify);

  const modifiedData = useMemo((): ContactPersonRow[] => {
    return data.map(({ id, ...restProps }) => {
      return {
        id,
        key: id,
        ...restProps,
      };
    });
  }, [data]);

  return (
    <>
      <AddContactPersonModal
        isOpen={isAddContactModalOpen}
        onClose={() => setIsAddContactModalOpen(false)}
        notify={notify}
      />
      <Form form={form} component={false}>
        <EditableTable<ContactPersonRow>
          cardTitle={<FormattedMessage defaultMessage="Osoby kontaktowe" />}
          cardTitleExtra={
            <Button
              icon={<PlusIcon />}
              type="primary"
              onClick={() => setIsAddContactModalOpen(true)}
              disabled={isLoading}
            >
              <FormattedMessage defaultMessage="Dodaj kontakt" />
            </Button>
          }
          data={modifiedData}
          isLoading={isLoading}
          isSaveLoading={isUpdateLoading}
          isDeleteLoading={isDeleteLoading}
          columns={columns({ isSms })}
          onSave={handleUpdateContact}
          onDelete={handleDeleteContact}
          form={form}
        />
      </Form>
    </>
  );
};

export default ContactPersons;
