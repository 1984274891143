import type {
  AddPreorderVariantLangPayload,
  PreorderVariantFileSlice,
  PreorderVariantLangSlice,
  PreorderVariantServiceSlice,
  PreorderVariantServiceValueSlice,
  PreorderVariantSlice,
} from './type';
import type { UploadedFile } from 'ducks/global/types';
import type { PreorderService } from 'ducks/preorders/types';
import type { Price, PriceItem } from 'ducks/prices/types';
import { v4 } from 'uuid';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import { FileTranslationType } from 'constants/fileTranslation';
import { MIN_REPETITION_VALUE } from 'constants/formInputLengths';
import {
  MAX_GENERATED_PREORDER_DISCOUNT,
  preorderDiscountRange,
  preorderRepetitionDiscountRange,
} from 'constants/preorder';
import {
  PreorderConstants,
  PreorderGraphicServiceConstants,
} from 'constants/preorderConstants';
import { ServiceAttributeNames, ServiceType } from 'constants/services';
import { ProformaType } from 'ducks/preorders/types';
import { getNestedKeyValue } from 'utilities/object';

const MAX_PROFORMA_PRICE = 7000;

type Langs = { langFrom: string; langTo: string };

type ValidateProvidedLanguagesInput = Pick<
  AddPreorderVariantLangPayload,
  'onError'
> & {
  preorderVariantLangs: PreorderVariantLangSlice[];
} & Langs;

type GetProformaTypeInput = {
  variantPriceTotal: number;
  country: LanguageCode | undefined | null;
  isDebtor: boolean;
};

type FindPriceItemByLangs = Partial<Langs>;

type GetPricesFromPriceItemInput = {
  price: Price | null;
  type: FileTranslationType;
} & WithoutNullableKeys<FindPriceItemByLangs>;

type GetPricesFromPriceItemOutput = Pick<
  PreorderVariantLangSlice,
  'priceNetto' | 'priceSpecialNetto' | 'priceCorrectionNetto'
> | null;

type CalcServiceGraphicPriceNettoInput = {
  serviceValues: PreorderVariantServiceValueSlice[];
  newSimplePages?: number;
  newDifficultPages?: number;
};

export type GetPreorderDiscountInput = {
  type: FileTranslationType;
  repetition: number;
  calcedPriceNetto: number;
} & Langs;

export type GetPreorderPagesInput = {
  type: FileTranslationType;
  characters: number;
};

export const getInitialPreorderVariantValues = (
  preorderServices: PreorderService[],
): PreorderVariantSlice => {
  const preorderVariantId = v4();

  return {
    id: preorderVariantId,
    variantIdx: 1,
    preorderId: 0,
    onlinePaymentUrl: '',
    proformaType: null,
    number: 0,
    isNew: true,
    inRealization: false,
    comment: '',
    isCertified: false,
    timeRealization: 5,
    repetition: MIN_REPETITION_VALUE,
    preorderVariantFiles: [],
    preorderVariantServices: preorderServices.map(({ type, attributes }) => ({
      id: v4(),
      type,
      isActive: false,
      preorderVariantId,
      priceNetto: 0,
      vatRate: 0,
      preorderVariantServiceValues: attributes.map(({ name }) => ({
        id: null,
        preorderVariantServiceId: null,
        attributeName: name,
        attributeValue: '',
      })),
    })),
  };
};

export const getInitialPreorderVariantFile = (
  file: UploadedFile,
): PreorderVariantFileSlice => ({
  id: v4(),
  preorderVariantId: null,
  type: FileTranslationType.NORMAL,
  isNew: true,
  file,
  fileId: file.id,
  isActive: true,
  isCorrection: false,
  isSpecial: false,
  preorderVariantLangs: [],
});

export const getPreorderServiceInitialValue = (
  serviceValues: PreorderVariantServiceValueSlice[],
  attribiuteName: ServiceAttributeNames,
) =>
  serviceValues.find(
    (serviceValue) => serviceValue.attributeName === attribiuteName,
  )?.attributeValue;

export const getInitialPreorderVariantServiceShipmentValues = (
  serviceAttributes: PreorderService['attributes'],
): PreorderVariantServiceSlice => ({
  id: v4(),
  preorderVariantId: null,
  type: ServiceType.SHIPMENT,
  isActive: true,
  priceNetto: 0,
  vatRate: 0,
  preorderVariantServiceValues: serviceAttributes.map(({ name }) => ({
    id: null,
    preorderVariantServiceId: null,
    attributeName: name,
    attributeValue: '',
  })),
});

/**
 * Check the correctness of languages in preorderVariantLangs, based on:
 * - duplicates
 * @returns
 */
export const validateProvidedLanguages = ({
  preorderVariantLangs,
  langFrom,
  langTo,
  onError,
}: ValidateProvidedLanguagesInput) => {
  const isDuplicate = preorderVariantLangs.some(
    (preorderVariantLang) =>
      preorderVariantLang.langFrom === langFrom &&
      preorderVariantLang.langTo === langTo,
  );

  if (isDuplicate) {
    onError?.({ isDuplicate });

    return { error: true };
  }

  return { error: false };
};

/**
 * Determines the type of proforma based on the given inputs.
 *
 * @returns
 * ProformaType.FULL_PAYMENT
 *  - If the customer is from a country other than Poland
 *  - If the customer is marked as Debtor
 * @returns
 * ProformaType.HALF_PAYMENT
 *  - If the variant value >= 7000 PLN net and the customer is from Poland
 * @returns
 * null
 *  - When none of the criteria are met
 */
export const getProformaType = ({
  variantPriceTotal,
  country,
  isDebtor,
}: GetProformaTypeInput): ProformaType | null => {
  const isAboveMaxProformaPrice = variantPriceTotal >= MAX_PROFORMA_PRICE;
  const isFromPoland = country === LanguageCode.POLAND;

  if (!isFromPoland || isDebtor) return ProformaType.FULL_PAYMENT;

  if (isAboveMaxProformaPrice && isFromPoland) return ProformaType.HALF_PAYMENT;

  return null;
};

const getNormalPriceByFileType = (
  type: FileTranslationType,
  priceItem: PriceItem,
) => {
  const { normal, sworn, correction } = priceItem;

  switch (type) {
    case FileTranslationType.SWORN:
      return sworn;
    case FileTranslationType.CORRECTION:
      return correction;
    default:
      return normal;
  }
};

const getSpecializedPriceByFileType = (
  type: FileTranslationType,
  priceItem: PriceItem,
) => {
  const { normalSpecialized, swornSpecialized, correctionSpecialized } =
    priceItem;

  switch (type) {
    case FileTranslationType.SWORN:
      return swornSpecialized;
    case FileTranslationType.CORRECTION:
      return correctionSpecialized;
    default:
      return normalSpecialized;
  }
};

const findPriceItemByLangs = (
  price: Price | null,
  {
    langFrom = LanguageCode.POLAND,
    langTo = LanguageCode.POLAND,
  }: FindPriceItemByLangs,
) =>
  price?.items.find(
    (item) => item.langFrom === langFrom && item.langTo === langTo,
  );

/**
 * Get priceNetto and priceCorrectionNetto based on two identical languages
 * or a combination with PL (e.g. DE:PL, PL:DE) in the selected price list.
 * @returns
 */
export const getPricesFromPriceItem = ({
  price,
  type,
  langFrom,
  langTo,
}: GetPricesFromPriceItemInput): GetPricesFromPriceItemOutput => {
  const priceItem = findPriceItemByLangs(price, {
    langFrom,
    langTo,
  });

  if (priceItem) {
    const normalPrice = getNormalPriceByFileType(type, priceItem);
    const correctionPrice = priceItem.correction;
    const specializedPrice = getSpecializedPriceByFileType(type, priceItem);

    return {
      priceNetto: normalPrice,
      priceCorrectionNetto: correctionPrice,
      priceSpecialNetto: specializedPrice,
    };
  }

  const priceItemByLangFrom = findPriceItemByLangs(price, {
    langFrom,
  });
  const priceItemByLangTo = findPriceItemByLangs(price, {
    langTo,
  });

  if (priceItemByLangFrom && priceItemByLangTo) {
    const langFromNormalPrice = getNormalPriceByFileType(
      type,
      priceItemByLangFrom,
    );
    const langToNormalPrice = getNormalPriceByFileType(type, priceItemByLangTo);
    const langFromSpecializedPrice = getSpecializedPriceByFileType(
      type,
      priceItemByLangFrom,
    );
    const langToSpecializedPrice = getSpecializedPriceByFileType(
      type,
      priceItemByLangTo,
    );
    const normalPrice = langFromNormalPrice + langToNormalPrice;
    const correctionPrice =
      priceItemByLangFrom.correction + priceItemByLangTo.correction;
    const specializedPrice = langFromSpecializedPrice + langToSpecializedPrice;

    return {
      priceNetto: normalPrice,
      priceCorrectionNetto: correctionPrice,
      priceSpecialNetto: specializedPrice,
    };
  }

  return null;
};

const getServiceGraphicPages = (
  serviceValues: PreorderVariantServiceValueSlice[],
  pageAttributeName: ServiceAttributeNames,
) =>
  serviceValues.find(({ attributeName }) => attributeName === pageAttributeName)
    ?.attributeValue;

/**
 * Calculates priceNetto for the net price based on the number
 * of simple and difficult pages, and then calculates the total
 * price by multiplying the number of pages with predefined price
 * multipliers. If the total price is lower than a minimum price
 * constant, it returns the minimum price, otherwise it returns
 * the calculated sum.
 * @returns
 */
export const calcServiceGraphicPriceNetto = ({
  serviceValues,
  newSimplePages,
  newDifficultPages,
}: CalcServiceGraphicPriceNettoInput) => {
  const simplePages =
    newSimplePages ??
    getServiceGraphicPages(
      serviceValues,
      ServiceAttributeNames.SIMPLE_PAGES_NUMBER,
    );
  const difficultPages =
    newDifficultPages ??
    getServiceGraphicPages(
      serviceValues,
      ServiceAttributeNames.DIFFICULT_PAGES_NUMBER,
    );

  if (!simplePages || !difficultPages) return null;

  const sum =
    Number(simplePages) *
      PreorderGraphicServiceConstants.SIMPLE_PAGES_PRICE_MULTIPLIER +
    Number(difficultPages) *
      PreorderGraphicServiceConstants.DIFFICULT_PAGES_PRICE_MULTIPLIER;

  return PreorderGraphicServiceConstants.MIN_PRICE > sum
    ? PreorderGraphicServiceConstants.MIN_PRICE
    : sum;
};

const getPreorderRepetitionDiscount = (repetition: number) => {
  const range = Object.keys(preorderRepetitionDiscountRange).find((key) => {
    const [min, max] = key.split('-').map(Number);

    if (max) return repetition >= min && repetition <= max;

    return repetition >= min;
  });

  return range ? preorderRepetitionDiscountRange[range] : 0;
};

export const getPreorderDiscount = ({
  type,
  repetition,
  langFrom,
  langTo,
  calcedPriceNetto,
}: GetPreorderDiscountInput) => {
  const discountRangeByType = preorderDiscountRange[type];
  const repetitionDiscount = getPreorderRepetitionDiscount(repetition);

  if (!Object.keys(discountRangeByType).length) return repetitionDiscount;

  const [langsKey] = getNestedKeyValue(discountRangeByType);
  const isLangExist = langsKey
    .split('-')
    .find(
      (lang) =>
        (langFrom === LanguageCode.POLAND && lang === langTo) ||
        (langFrom === lang && langTo === LanguageCode.POLAND),
    );
  const lang = isLangExist ? langsKey : '';

  const discountRangeByLang = discountRangeByType[lang];

  const range = Object.keys(discountRangeByLang).find((key) => {
    const [min, max] = key.split('-').map(Number);

    if (max) return calcedPriceNetto >= min && calcedPriceNetto <= max;

    return calcedPriceNetto >= min;
  });

  if (!range) return repetitionDiscount;

  const discount = discountRangeByLang[range] + repetitionDiscount;

  return discount > MAX_GENERATED_PREORDER_DISCOUNT
    ? MAX_GENERATED_PREORDER_DISCOUNT
    : discount;
};

export const getPreorderPages = ({
  type,
  characters,
}: GetPreorderPagesInput) => {
  const pagesMultiplier =
    type === FileTranslationType.SWORN
      ? PreorderConstants.PAGES_SWORN_MULTIPLIER
      : PreorderConstants.PAGES_NORMAL_MULTIPLIER;
  const pages =
    type === FileTranslationType.SWORN
      ? Math.ceil(characters / pagesMultiplier)
      : Math.ceil((characters / pagesMultiplier) * 10) / 10;

  return pages;
};
