import type { PreorderTranslationRow } from './config';
import type {
  PreorderVariantFileSlice,
  PreorderVariantSlice,
} from 'ducks/view/preorders';
import type { FC } from 'react';
import { Space } from 'antd';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Empty from 'components/ui/atoms/Empty';
import Table from 'components/ui/molecules/Table';
import { FileTranslationType } from 'constants/fileTranslation';
import { useAppSelector } from 'ducks/store';
import TableCardTitle from './components/TableCardTitle';
import { preorderTranslationColumns } from './config';

type Props = {
  variantId: string | number;
  variantFiles: PreorderVariantFileSlice[];
} & Pick<PreorderVariantSlice, 'repetition'>;

const PreorderVariantTranslationsTable: FC<Props> = memo(
  ({ variantId, variantFiles, repetition }) => {
    const isEditable = useAppSelector(({ preorders }) => preorders.isEditable);
    const isLackOfVariantFiles = !variantFiles.length;

    const modifiedData = useMemo(
      (): PreorderTranslationRow[] =>
        variantFiles.map((order) => ({
          ...order,
          key: order.id,
        })),
      [variantFiles],
    );

    const [normalTranslations, swornTranslations, correctionTranslations] =
      useMemo(
        () => [
          modifiedData.filter(
            ({ type }) => type === FileTranslationType.NORMAL,
          ),
          modifiedData.filter(({ type }) => type === FileTranslationType.SWORN),
          modifiedData.filter(
            ({ type }) => type === FileTranslationType.CORRECTION,
          ),
        ],
        [modifiedData],
      );

    const columns = useMemo(
      () =>
        preorderTranslationColumns({
          variantId,
          isEditable,
        }),
      [variantId, isEditable],
    );

    return (
      <Space className="width-100" direction="vertical" size="large">
        {isLackOfVariantFiles && (
          <Empty
            description={
              <FormattedMessage defaultMessage="Brak plików do tłumaczenia" />
            }
          />
        )}
        {!!normalTranslations.length && (
          <Table
            hidePagination
            cardTitle={
              <TableCardTitle
                title={<FormattedMessage defaultMessage="Tłumaczenie zwykłe" />}
                repetition={repetition}
              />
            }
            columns={columns}
            data={normalTranslations}
          />
        )}
        {!!swornTranslations.length && (
          <Table
            hidePagination
            cardTitle={
              <TableCardTitle
                title={
                  <FormattedMessage defaultMessage="Tłumaczenie przysięgłe" />
                }
              />
            }
            columns={columns}
            data={swornTranslations}
          />
        )}
        {!!correctionTranslations.length && (
          <Table
            hidePagination
            cardTitle={
              <TableCardTitle
                title={<FormattedMessage defaultMessage="Korekta" />}
              />
            }
            columns={columns}
            data={correctionTranslations}
          />
        )}
      </Space>
    );
  },
);

export default PreorderVariantTranslationsTable;
