import type { UpdatePreorderValues } from './config';
import type { FC } from 'react';
import { Col, Form, Row, notification } from 'antd';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PreorderStatus } from 'constants/preorder';
import { useAppDispatch } from 'ducks/store';
import {
  setPreorderIsEditable,
  setPreorderSelectedPriceId,
  setPreorderVatRate,
  setPreorderVerifiedVies,
  resetPreordersState,
  setPreorderData,
} from 'ducks/view/preorders';
import PreorderCurrentStatus from 'views/Preorders/components/PreorderCurrentStatus';
import PreorderNotesSection from 'views/Preorders/components/PreorderNotesSection';
import { Routes } from 'views/Preorders/Routing';
import ActionsSection from './components/ActionsSection';
import ClientSection from './components/ClientSection';
import ContactPersonSection from './components/ContactPersonSection';
import InvoiceSection from './components/InvoiceSection';
import LangFilesTable from './components/LangFilesTable';
import OthersSection from './components/OthersSection';
import PaymentsTableSection from './components/PaymentsTableSection';
import PreorderDetailsHader from './components/PreorderDetailsHader';
import PricesSection from './components/PricesSection';
import UploadFilesSection from './components/UploadFilesSection';
import VariantsSection from './components/VariantsSection';
import { usePreorderDetailsServices } from './usePreorderDetailsServices';

const { useForm } = Form;

type Props = {
  isEditable: boolean;
};

const PreorderDetails: FC<Props> = ({ isEditable }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm<UpdatePreorderValues>();
  const { preorderData, refetch } = usePreorderDetailsServices();
  const isNotInRealization =
    preorderData?.status !== PreorderStatus.IN_REALIZATION;

  const initialValues = useMemo((): UpdatePreorderValues | undefined => {
    if (!preorderData) return undefined;
    const { phone, country, invoiceEmail } = preorderData;

    return {
      ...preorderData,
      phone: phone ?? '',
      country: country ?? '',
      invoiceEmail:
        invoiceEmail ??
        preorderData?.client?.[`email${preorderData?.client?.emailDefault}`] ??
        preorderData?.email ??
        '',
      name:
        preorderData.name ??
        preorderData?.client?.invoiceName ??
        preorderData?.client?.name ??
        preorderData.contactPerson ??
        '',
    };
  }, [preorderData]);

  useEffect(() => {
    if (!preorderData) return;

    const { id, status } = preorderData;

    const isInRealization = status === PreorderStatus.IN_REALIZATION;

    if (isInRealization && isEditable) {
      navigate(Routes.PREORDER_DETAILS.replace(':id', id.toString()));
    }
  }, [preorderData, isEditable, navigate]);

  useEffect(() => {
    dispatch(setPreorderIsEditable(isEditable));
  }, [isEditable, dispatch]);

  useEffect(() => {
    if (preorderData) {
      const { priceId, verifiedVies, vatRate } = preorderData;

      dispatch(setPreorderData(preorderData));
      dispatch(setPreorderSelectedPriceId(priceId));
      dispatch(setPreorderVerifiedVies(verifiedVies));
      dispatch(setPreorderVatRate(vatRate));
    }
  }, [preorderData, dispatch]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  useEffect(() => {
    refetch();
  }, [refetch, isEditable]);

  useEffect(() => {
    return () => {
      dispatch(resetPreordersState());
    };
  }, [refetch, dispatch]);

  return (
    <div className="layout-content mb-24">
      {contextHolder}
      <PreorderCurrentStatus>
        <PreorderDetailsHader />
        <Form form={form} layout="vertical" autoComplete="off">
          <Row gutter={[24, 24]} align="top">
            <Col xs={24} lg={12}>
              <Row gutter={[24, 24]} align="top">
                <ContactPersonSection />
                <ClientSection />
              </Row>
            </Col>
            <PreorderNotesSection notify={notify} />
          </Row>
        </Form>
        <Form form={form} layout="vertical" autoComplete="off">
          {isEditable && isNotInRealization && (
            <Row gutter={[24, 24]} align="top">
              <Col xs={24} md={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Row
                  gutter={[24, 24]}
                  style={{ marginLeft: 0, marginRight: 0 }}
                  align="top"
                >
                  <PricesSection notify={notify} />
                  <UploadFilesSection notify={notify} />
                </Row>
              </Col>
            </Row>
          )}
        </Form>
        <VariantsSection />
        <Row gutter={[24, 24]} align="top">
          <PaymentsTableSection notify={notify} />
          <OthersSection />
        </Row>
        <Form form={form} layout="vertical" autoComplete="off">
          <Row gutter={[24, 24]} align="top">
            <InvoiceSection form={form} />
            <Col md={12} xs={24}>
              <LangFilesTable preorderNumber={preorderData?.number} />
            </Col>
          </Row>
        </Form>
        {isEditable && isNotInRealization && (
          <ActionsSection form={form} notify={notify} />
        )}
      </PreorderCurrentStatus>
    </div>
  );
};

export default PreorderDetails;
