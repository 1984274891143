import type { IntlShape } from 'react-intl';

export enum FileTranslationType {
  NORMAL = 0,
  SWORN = 1,
  CORRECTION = 2,
}

type Option<T> = {
  value: T;
  label: string;
};

export const fileTranslationTypes = (
  intl: IntlShape,
  extendedLabel = false,
): Option<FileTranslationType>[] => [
  {
    value: FileTranslationType.NORMAL,
    label: extendedLabel
      ? intl.formatMessage({ defaultMessage: 'Tłumaczenie zwykłe' })
      : intl.formatMessage({ defaultMessage: 'Zwykłe' }),
  },
  {
    value: FileTranslationType.SWORN,
    label: extendedLabel
      ? intl.formatMessage({ defaultMessage: 'Tłumaczenie przysięgłe' })
      : intl.formatMessage({ defaultMessage: 'Przysięgłe' }),
  },
  {
    value: FileTranslationType.CORRECTION,
    label: intl.formatMessage({ defaultMessage: 'Korekta' }),
  },
];

export const fileTranslationTypesObject = (
  intl: IntlShape,
  extendedLabel = false,
) =>
  fileTranslationTypes(intl, extendedLabel).reduce((acc, { value, label }) => {
    acc[value] = {
      value,
      label,
    };

    return acc;
  }, {} as Record<FileTranslationType, Option<FileTranslationType>>);
