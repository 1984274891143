import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetInvoiceVatQuery } from 'ducks/invoices/service';

export const useInvoiceSendServices = () => {
  const { id = '' } = useParams();
  const { data: invoiceVatData, isFetching: isInvoiceVatFetching } =
    useGetInvoiceVatQuery({
      id,
    });
  const isInvoiceVatEmpty = !invoiceVatData && !isInvoiceVatFetching;

  const value = useMemo(
    () => ({
      invoiceVatData,
      isInvoiceVatFetching,
      isInvoiceVatEmpty,
    }),
    [invoiceVatData, isInvoiceVatFetching, isInvoiceVatEmpty],
  );

  return value;
};
