import type { FC } from 'react';
import dayjs from 'dayjs';
import { useState } from 'react';
import Button from 'components/ui/atoms/Button';
import { DayjsFormat } from 'constants/dayjsFormats';
import { NO_DATA } from 'constants/strings';
import { EditIcon } from 'icons';
import EditDateLastContactModal from './EditDateLastContactModal';

type Props = {
  dateLastContact: Date | null;
};

const DateLastContactRow: FC<Props> = ({ dateLastContact }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formattedDateLastContact = dayjs(dateLastContact).format(
    DayjsFormat.DATE,
  );

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <Button onClick={handleOpenModal} type="text" size="small">
        <b>{dateLastContact ? formattedDateLastContact : NO_DATA}</b>
        <EditIcon />
      </Button>
      <EditDateLastContactModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        dateLastContact={dateLastContact}
      />
    </>
  );
};

export default DateLastContactRow;
