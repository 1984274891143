import type { IntlShape } from 'react-intl';
import { CurrencyType } from 'ducks/payments/types';

type CurrencyTypesOption = {
  value: CurrencyType;
  label: string;
};

export const CURRENCY_TYPE_ARRAY = [
  CurrencyType.EUR,
  CurrencyType.USD,
  CurrencyType.GBP,
];

export const CURRENCY_PLN = 'PLN';
export const CURRENCY_EUR = 'EUR';
export const CURRENCY_USD = 'USD';
export const CURRENCY_GBP = 'GBP';

export const getCurrency = (currency: CurrencyType) => {
  switch (currency) {
    case CurrencyType.EUR:
      return CURRENCY_EUR;
    case CurrencyType.PLN:
      return CURRENCY_PLN;
    case CurrencyType.USD:
      return CURRENCY_USD;
    case CurrencyType.GBP:
      return CURRENCY_GBP;
    default:
      return '';
  }
};

export const currencyTypes = (intl: IntlShape): CurrencyTypesOption[] => [
  {
    value: CurrencyType.PLN,
    label: intl.formatMessage({ defaultMessage: 'PLN' }),
  },
  {
    value: CurrencyType.EUR,
    label: intl.formatMessage({ defaultMessage: 'EUR' }),
  },
  {
    value: CurrencyType.USD,
    label: intl.formatMessage({ defaultMessage: 'USD' }),
  },
  {
    value: CurrencyType.GBP,
    label: intl.formatMessage({ defaultMessage: 'GBP' }),
  },
];
