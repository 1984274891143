import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { EditPriceRequest } from 'ducks/prices/types';
import { FormattedMessage } from 'react-intl';

export type EditPriceNameModalValues = Pick<EditPriceRequest, 'name'>;

export const initialValues: EditPriceNameModalValues = {
  name: '',
};

export const formItems: FormItemRecord<EditPriceNameModalValues>[] = [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Nazwa" />,
    name: 'name',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj nazwę" />,
      },
    ],
  },
];
