import type {
  GetPreorderVariantsResponse,
  GetPreorderResponse,
  GetPreordersResponse,
  PreorderData,
  PreorderVariantFile,
  PreorderVariantService,
  GetPreorderServicesResponse,
  AddPreorderResponse,
  PreorderVariant,
} from 'ducks/preorders/types';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import { FileTranslationType } from 'constants/fileTranslation';
import { PaymentDetailStatus } from 'constants/payment';
import { PreorderInvoiceType, PreorderStatus } from 'constants/preorder';
import { ServiceAttributeNames, ServiceType } from 'constants/services';
import { FileType } from 'ducks/global/types';
import { ProformaType } from 'ducks/preorders/types';
import { mockedClient } from 'handlers/clients/mocks';
import { mockedPriceDetails } from 'handlers/prices/mocks';
import { mockedPagination } from 'handlers/utils';

export const mockedPreorderData: PreorderData = {
  id: 1,
  uuid: 't1SpDKnNs3',
  number: 'abc123',
  activeEditorId: null,
  isCompany: false,
  contactPerson: 'contactPerson',
  name: 'name',
  email: 'email@gmail.com',
  phone: '123123123',
  status: PreorderStatus.NEW,
  priceMin: 23,
  priceMax: 33,
  priceBruttoMax: 44,
  priceBruttoMin: 55,
  paymentStatus: PaymentDetailStatus.NO_PAYMENT,
  paidPayments: [],
  preorderVariants: [],
  onlinePaymentUrl: 'google.com',
  dateSent: null,
  dateCreation: new Date(),
};

export const mockedPreordersResponse: GetPreordersResponse =
  mockedPagination(mockedPreorderData);

export const mockedPreorder: GetPreorderResponse = {
  id: 1,
  uuid: 'uid-1',
  number: 'XXYYRZZ',
  clientId: 1,
  priceId: 1,
  isCompany: true,
  contactPerson: 'contact person',
  name: 'name',
  nip: '2314555368',
  email: 'test@example.com',
  emailCc: 'testcc@example.com',
  invoiceEmail: 'testinvoice@example.com',
  phone: '123123123',
  verifiedKrd: false,
  verifiedVies: false,
  vatRate: 3,
  referenceFile: '222',
  originalMessage: 'original message',
  expectedRealizationDate: new Date(),
  activeEditorId: null,
  country: LanguageCode.POLAND,
  dateCreation: new Date(),
  emailMessage: 'email message',
  dateSent: null,
  senderId: 1,
  status: PreorderStatus.SENT,
  client: mockedClient,
  order: null,
  isSms: false,
  address: 'address',
  city: 'city',
  postCode: 'postCode',
  invoiceType: PreorderInvoiceType.DIGITAL,
  invoiceAuto: true,
  hasInvoiceDataConvergence: false,
  canDelete: true,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const mockedPreorderVariantFile: PreorderVariantFile = {
  id: 1,
  preorderVariantId: 1,
  fileId: 12,
  type: FileTranslationType.NORMAL,
  isActive: true,
  isCorrection: true,
  isSpecial: false,
  file: {
    id: 12,
    file: '',
    filename: 'filename.jpg',
    object: FileType.PREORDERS,
    objectId: 1,
  },
  preorderVariantLangs: [
    {
      id: 20,
      preorderVariantFileId: 1,
      langFrom: mockedPriceDetails.items[0].langFrom,
      langTo: mockedPriceDetails.items[0].langTo,
      characters: 1397,
      pages: 0.87,
      discount: 45,
      priceNetto: 17.01,
      priceSpecialNetto: 15,
      priceCorrectionNetto: 10,
      priceFinalNetto: 42.01,
      timeRealization: 5,
    },
    {
      id: 30,
      preorderVariantFileId: 1,
      langFrom: mockedPriceDetails.items[2].langFrom,
      langTo: mockedPriceDetails.items[2].langTo,
      characters: 2400,
      pages: 1.5,
      discount: 30,
      priceNetto: 1,
      priceSpecialNetto: 2,
      priceCorrectionNetto: 0.7,
      priceFinalNetto: 3.07,
      timeRealization: 8,
    },
  ],
};

export const mockedPreorderVariantServices: PreorderVariantService[] = [
  {
    id: 1,
    preorderVariantId: 1,
    type: ServiceType.GRAPHIC_SERVICE,
    isActive: true,
    vatRate: 8,
    priceNetto: 450.0,
    preorderVariantServiceValues: [
      {
        id: 1,
        preorderVariantServiceId: 1,
        attributeName: ServiceAttributeNames.SIMPLE_PAGES_NUMBER,
        attributeValue: '2',
      },
      {
        id: 2,
        preorderVariantServiceId: 2,
        attributeName: ServiceAttributeNames.DIFFICULT_PAGES_NUMBER,
        attributeValue: '9',
      },
    ],
  },
  {
    id: 2,
    preorderVariantId: 1,
    type: ServiceType.ADDITIONAL,
    isActive: true,
    vatRate: 8,
    priceNetto: 0,
    preorderVariantServiceValues: [
      {
        id: 3,
        preorderVariantServiceId: 3,
        attributeName: ServiceAttributeNames.ADDITIONAL_TYPE,
        attributeValue: 'express',
      },
      {
        id: 4,
        preorderVariantServiceId: 4,
        attributeName: ServiceAttributeNames.ADDITIONAL_TIME,
        attributeValue: '11:00',
      },
    ],
  },
  {
    id: 23,
    preorderVariantId: 1,
    type: ServiceType.SHIPMENT,
    isActive: true,
    vatRate: 8,
    priceNetto: 20,
    preorderVariantServiceValues: [
      {
        id: 5,
        preorderVariantServiceId: 5,
        attributeName: ServiceAttributeNames.TYPE,
        attributeValue: 'DPD Pickup',
      },
      {
        id: 6,
        preorderVariantServiceId: 6,
        attributeName: ServiceAttributeNames.PACKAGES_NUMBER,
        attributeValue: '1',
      },
    ],
  },
];

export const mockedPreorderVariant: PreorderVariant = {
  id: 1,
  preorderId: 1,
  number: 1,
  proformaType: ProformaType.FULL_PAYMENT,
  isCertified: true,
  timeRealization: 10,
  comment: 'comment',
  repetition: 50,
  preorderVariantFiles: [mockedPreorderVariantFile],
  preorderVariantServices: mockedPreorderVariantServices,
  inRealization: false,
  variantIdx: 1,
  onlinePaymentUrl: 'payment-link.com/123123',
};

export const mockedGetPreorderVariantsResponse: GetPreorderVariantsResponse = [
  mockedPreorderVariant,
];

export const mockedPreorderServices: GetPreorderServicesResponse = {
  services: [
    {
      type: ServiceType.GRAPHIC_SERVICE,
      attributes: [
        {
          name: ServiceAttributeNames.SIMPLE_PAGES_NUMBER,
        },
        {
          name: ServiceAttributeNames.DIFFICULT_PAGES_NUMBER,
        },
      ],
    },
    {
      type: ServiceType.ADDITIONAL,
      attributes: [
        {
          name: ServiceAttributeNames.ADDITIONAL_TYPE,
          options: ['express', 'option2'],
        },
        {
          name: ServiceAttributeNames.ADDITIONAL_TIME,
        },
      ],
    },
    {
      type: ServiceType.SHIPMENT,
      attributes: [
        {
          name: ServiceAttributeNames.TYPE,
          options: ['DPD Pickup'],
        },
        {
          name: ServiceAttributeNames.PACKAGES_NUMBER,
        },
      ],
    },
    {
      type: ServiceType.PREPARATORY,
      attributes: [],
    },
  ],
};

export const mockedAddPreorderResponse: AddPreorderResponse = {
  id: 1,
  clientId: 1,
  companyName: 'companyName',
  email: 'test@example.com',
  createdAt: '',
  updatedAt: '',
};
