import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import Table from 'components/ui/molecules/Table';
import { useAppSelector } from 'ducks/store';
import { parsedPaymentFileColumns } from 'views/Finances/view/FinanceImport/components/PaymentFileAndTransactionsTable';

const ParsedPaymentFileTable = () => {
  const { parsedPaymentFile, parsedPaymentId } = useAppSelector(
    ({ finances }) => ({
      parsedPaymentFile: finances.parsedPaymentFile,
      parsedPaymentId: finances.assignPaymentTransactionModal.parsedPaymentId,
    }),
    shallowEqual,
  );
  const parsedPayment = parsedPaymentFile.find(
    ({ id }) => id === parsedPaymentId,
  );

  if (!parsedPayment) return null;

  return (
    <Table
      cardTitle={<FormattedMessage defaultMessage="Dane bankowe" />}
      columns={parsedPaymentFileColumns({ truncateFromName: false })}
      data={[{ ...parsedPayment, key: parsedPayment.id }]}
    />
  );
};

export default ParsedPaymentFileTable;
