import type { ListItemRecord } from 'components/ui/molecules/List';
import type { Translator } from 'ducks/translators/types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CopyToClipboardWithText from 'components/ui/atoms/CopyToClipboard';
import { DefaultValue } from 'ducks/types';
import styles from './styles.module.scss';

export const contactListItems = (
  data: Translator | undefined,
): ListItemRecord[] => [
  {
    label: <FormattedMessage defaultMessage="E-mail:" />,
    description: data?.email1 ? (
      <CopyToClipboardWithText type="secondary" text={data?.email1} />
    ) : (
      data?.email1
    ),
    avatar: (
      <CircleCheckbox
        data-testid="emailCheckbox1"
        value={data?.emailDefault === DefaultValue.FIRST}
      />
    ),
  },
  {
    label: <FormattedMessage defaultMessage="E-mail 2:" />,
    description: data?.email2 ? (
      <CopyToClipboardWithText type="secondary" text={data?.email2} />
    ) : (
      data?.email2
    ),
    avatar: (
      <CircleCheckbox
        data-testid="emailCheckbox2"
        value={data?.emailDefault === DefaultValue.SECOND}
      />
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Tel:" />,
    description: data?.phone1 ? (
      <CopyToClipboardWithText type="secondary" text={data?.phone1} />
    ) : (
      data?.phone1
    ),
    avatar: (
      <CircleCheckbox
        data-testid="phoneCheckbox1"
        value={data?.phoneDefault === DefaultValue.FIRST}
      />
    ),
  },
  {
    label: <FormattedMessage defaultMessage="Tel 2:" />,
    description: data?.phone2 ? (
      <CopyToClipboardWithText type="secondary" text={data?.phone2} />
    ) : (
      data?.phone2
    ),
    avatar: (
      <CircleCheckbox
        data-testid="phoneCheckbox2"
        value={data?.phoneDefault === DefaultValue.SECOND}
      />
    ),
  },
  {
    label: (
      <div className={styles.externalUrlLabel}>
        <FormattedMessage defaultMessage="Profil zewnętrzny:" />
        {data?.externalUrl && (
          <CopyToClipboard text={data.externalUrl}>
            <Button type="link" size="small">
              <FormattedMessage defaultMessage="Kopiuj" />
            </Button>
          </CopyToClipboard>
        )}
      </div>
    ),
  },
];
