import { FormattedMessage } from 'react-intl';
import List from 'components/ui/molecules/List';
import { useTranslatorProfileServices } from '../../useTranslatorProfileServices';
import CardCommon from '../CardCommon';
import { invoiceCardListItems } from './config';

const InvoiceCard = () => {
  const { translatorData, isTranslatorFetching } =
    useTranslatorProfileServices();

  return (
    <CardCommon
      data-testid="invoiceCard"
      title={<FormattedMessage defaultMessage="Dane rozliczeniowe" />}
      isLoading={isTranslatorFetching}
      md={12}
    >
      <List
        items={invoiceCardListItems(translatorData)}
        isLoading={isTranslatorFetching}
      />
    </CardCommon>
  );
};

export default InvoiceCard;
