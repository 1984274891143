import type { OrderFilesForList } from 'ducks/orders/types';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import LanguagePair from 'components/ui/atoms/LanguagePair';
import { DayjsFormat } from 'constants/dayjsFormats';
import { getTranslatorName } from 'utilities/translator';
import { Routes as TranslatorRoutes } from 'views/Translators/Routing';
import styles from './styles.module.scss';
import { generateTranslatorColor, generateTypeLabel } from './utils';

type Props = Pick<
  OrderFilesForList,
  | 'langFrom'
  | 'langTo'
  | 'translator'
  | 'indicator'
  | 'dateRealization'
  | 'type'
>;

const TranslationCell: FC<Props> = ({
  langFrom,
  langTo,
  translator,
  indicator,
  type,
  dateRealization,
}) => {
  const translatorColor = generateTranslatorColor(indicator);
  const formattedDateRealization = dayjs(dateRealization).format(
    DayjsFormat.DATE_AND_TIME,
  );
  const label = generateTypeLabel(type);
  const translatorName = getTranslatorName({
    firstName: translator?.firstName,
    lastName: translator?.lastName,
  });

  return (
    <div className={styles.wrapper}>
      <LanguagePair
        langFrom={langFrom}
        langTo={langTo}
        showOnlyCodes
        className={styles.langPairWrapper}
      />
      <span>({label})</span>
      {translator?.id && (
        <Link
          onClick={(e) => e.stopPropagation()}
          to={TranslatorRoutes.TRANSLATOR_PROFILE.replace(
            ':id',
            `${translator.id}`,
          )}
        >
          <div className={translatorColor}>{translatorName}</div>
        </Link>
      )}
      <div>{translator?.id && formattedDateRealization}</div>
    </div>
  );
};

export default TranslationCell;
