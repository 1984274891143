import type { ChangePasswordModalValues } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { User } from 'ducks/users/types';
import type { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/ui/molecules/Modal/Modal';
import { useChangeUserPasswordMutation } from 'ducks/users/service';
import {
  validatePasswordConfirmationRule,
  validatePasswordRule,
} from 'utilities/antdFormRules';

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  userData: User;
  notify: NotificationInstance;
}

const { Item, useForm } = Form;

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  isOpen,
  onClose,
  userData,
  notify,
}) => {
  const [form] = useForm<ChangePasswordModalValues>();
  const [changeUserPassword, { isLoading, isSuccess }] =
    useChangeUserPasswordMutation();

  const handleOnSubmit = async () => {
    if (isLoading || isSuccess) return;

    const values = await form.validateFields();

    try {
      await changeUserPassword({
        ...values,
        id: userData.id,
      }).unwrap();

      notify.success({
        message: <FormattedMessage defaultMessage="Hasło zostało zmienione." />,
      });

      form.resetFields();
      onClose();
    } catch (err) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas zmiany hasła." />
        ),
      });
    }
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Zmień hasło" />}
      isLoading={isLoading}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={onClose}
      disableModalStyles
      disableFormStyles
    >
      <Form form={form} layout="vertical">
        <Item
          label={<FormattedMessage defaultMessage="Hasło" />}
          name="password"
          rules={[
            {
              required: true,
              message: <FormattedMessage defaultMessage="Podaj hasło" />,
            },
            validatePasswordRule(),
          ]}
        >
          <Input.Password />
        </Item>
        <Item
          label={<FormattedMessage defaultMessage="Powtórz hasło" />}
          name="passwordConfirmation"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage defaultMessage="Uzupełnij pole powtórz hasło" />
              ),
            },
            validatePasswordConfirmationRule(),
          ]}
        >
          <Input.Password />
        </Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
