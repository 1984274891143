import dayjs from 'dayjs';
import { Typography } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual } from 'react-redux';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { DayjsFormat } from 'constants/dayjsFormats';
import { PreorderStatus, preorderStatusesObject } from 'constants/preorder';
import { useAppSelector } from 'ducks/store';
import { calcVariantTotalRealizationTime } from 'utilities/calcPreorderRealizationTime';
import { calcPreorderVariantPriceTotal } from 'utilities/calcPreorderTotals';
import styles from '../styles.module.scss';
import { usePreordersHeaderServices } from '../usePreordersHeaderServices';

const { Text } = Typography;

const PreordersHeaderInformations = () => {
  const intl = useIntl();
  const variants = useAppSelector(
    ({ preorders }) => preorders.variants,
    shallowEqual,
  );
  const { preorderData } = usePreordersHeaderServices();
  const variant = variants[0];
  const showDateSent =
    preorderData?.status === PreorderStatus.SENT && !!preorderData?.dateSent;

  const { priceTotal, totalRealizationTime } = useMemo(
    () => ({
      priceTotal: variant ? calcPreorderVariantPriceTotal(variant) : null,
      totalRealizationTime: variant
        ? variant.timeRealization || calcVariantTotalRealizationTime(variant)
        : null,
    }),
    [variant],
  );

  if (!preorderData) return null;

  return (
    <>
      <div className={styles.column}>
        <Text>{preorderData.number}</Text>
        <Text>
          {preorderStatusesObject(intl)[preorderData.status].label}{' '}
          {showDateSent &&
            dayjs(preorderData.dateSent).format(DayjsFormat.DATE_AND_TIME)}
        </Text>
      </div>
      {variants.length === 1 && (
        <div className={styles.column}>
          {typeof priceTotal === 'number' && (
            <span>
              <FormattedMessage
                defaultMessage="Cena: {price}"
                values={{
                  price: <CurrencyFormatter value={priceTotal} />,
                }}
              />
            </span>
          )}
          {!!totalRealizationTime && (
            <Text>
              <FormattedMessage
                defaultMessage="Termin: {days} dni robocze"
                values={{
                  days: totalRealizationTime,
                }}
              />
            </Text>
          )}
        </div>
      )}
    </>
  );
};

export default PreordersHeaderInformations;
