import type { FormListItemRecord } from 'components/ui/molecules/FormList';
import type { PriceItem } from 'ducks/prices/types';
import { Button, Popconfirm, Popover, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import LanguageFlag from 'components/ui/atoms/LanguageFlag';
import LanguageName from 'components/ui/atoms/LanguageName';
import TruncateText from 'components/ui/atoms/TruncateText';
import {
  PRICE_LIST_CORRECTION_MULTIPLIER,
  PRICE_LIST_REDACTION_MULTIPLIER,
  PRICE_LIST_SWORN_MULTIPLIER,
  PRICE_LIST_VERIFICATION_MULTIPLIER,
} from 'constants/priceListMultipliers';
import { TrashIcon } from 'icons';
import styles from './styles.module.scss';

export type ListItem = Omit<PriceItem, 'id'> & {
  id: number | string;
  isNew?: boolean;
};

export type HandleAddProps = {
  langFrom: string;
  langTo: string;
}[];

type FormListItem = FormListItemRecord<ListItem>;

type Props = {
  handleDelete: (id: number | string, isNew: boolean) => void;
  isDeleteLoading: boolean;
};

export type FilterBy = {
  langs: string[];
};

export const initialFilters: FilterBy = {
  langs: [],
};

export const initialListItemValues: ListItem = {
  id: '',
  isNew: true,
  priceId: 0,
  langFrom: '',
  langTo: '',
  normal: 0,
  normalSpecialized: 0,
  sworn: 0,
  swornSpecialized: 0,
  correction: 0,
  correctionSpecialized: 0,
  redaction: 0,
  redactionSpecialized: 0,
  verification: 0,
  verificationSpecialized: 0,
};

export const sharedRules: ReturnType<
  FormListItem['cellItems']
>[number]['rules'] = [
  {
    required: true,
    message: <FormattedMessage defaultMessage="Podaj wartość" />,
  },
];

export const formListItems = ({
  handleDelete,
  isDeleteLoading,
}: Props): FormListItem[] => [
  {
    key: 1,
    title: (
      <FormattedMessage defaultMessage="Tłumaczenie z języka - Tłumaczenie na język" />
    ),
    cellItems: ({ langFrom, langTo }) => {
      return [
        {
          render: (
            <div
              data-testid="languagesCell"
              className={styles.languagesWrapper}
            >
              <LanguageFlag languageCode={langFrom} />
              <LanguageName countryCode={langFrom} showCode />
              <TruncateText className={styles.languagesDash} ellipsis>
                -
              </TruncateText>
              <LanguageName countryCode={langTo} showCode />
              <LanguageFlag languageCode={langTo} />
            </div>
          ),
        },
      ];
    },
  },
  {
    key: 2,
    title: (
      <Popover
        content={
          <Space direction="vertical">
            <FormattedMessage
              defaultMessage="Tłumaczenie przysięgłe = {value}% tłumaczenia zwykłego"
              values={{ value: PRICE_LIST_SWORN_MULTIPLIER * 100 }}
            />
            <FormattedMessage
              defaultMessage="Korekta = {value}% tłumaczenia zwykłego"
              values={{ value: PRICE_LIST_CORRECTION_MULTIPLIER * 100 }}
            />
            <FormattedMessage
              defaultMessage="Redakcja = {value}% tłumaczenia zwykłego"
              values={{ value: PRICE_LIST_REDACTION_MULTIPLIER * 100 }}
            />
            <FormattedMessage
              defaultMessage="Weryfikacja = {value}% tłumaczenia zwykłego"
              values={{ value: PRICE_LIST_VERIFICATION_MULTIPLIER * 100 }}
            />
          </Space>
        }
      >
        <span>
          <FormattedMessage defaultMessage="Tłumaczenie zwykłe (?)" />
        </span>
      </Popover>
    ),
    cellItems: () => [
      {
        name: 'normal',
        render: <DecimalInputNumber />,
        rules: [...sharedRules],
      },
    ],
  },
  {
    key: 8,
    title: <FormattedMessage defaultMessage="Specjalistyczne" />,
    cellItems: () => [
      {
        name: 'normalSpecialized',
        render: <DecimalInputNumber />,
        rules: [...sharedRules],
      },
    ],
  },
  {
    key: 3,
    title: <FormattedMessage defaultMessage="Tłumaczenie przysięgłe" />,
    cellItems: () => [
      {
        name: 'sworn',
        render: <DecimalInputNumber />,
        rules: [...sharedRules],
      },
    ],
  },
  {
    key: 9,
    title: <FormattedMessage defaultMessage="Specjalistyczne" />,
    cellItems: () => [
      {
        name: 'swornSpecialized',
        render: <DecimalInputNumber />,
        rules: [...sharedRules],
      },
    ],
  },
  {
    key: 4,
    title: <FormattedMessage defaultMessage="Korekta" />,
    cellItems: () => [
      {
        name: 'correction',
        render: <DecimalInputNumber />,
        rules: [...sharedRules],
      },
    ],
  },
  {
    key: 10,
    title: <FormattedMessage defaultMessage="Specjalistyczne" />,
    cellItems: () => [
      {
        name: 'correctionSpecialized',
        render: <DecimalInputNumber />,
        rules: [...sharedRules],
      },
    ],
  },
  {
    key: 5,
    title: <FormattedMessage defaultMessage="Redakcja" />,
    cellItems: () => [
      {
        name: 'redaction',
        render: <DecimalInputNumber />,
        rules: [...sharedRules],
      },
    ],
  },
  {
    key: 11,
    title: <FormattedMessage defaultMessage="Specjalistyczne" />,
    cellItems: () => [
      {
        name: 'redactionSpecialized',
        render: <DecimalInputNumber />,
        rules: [...sharedRules],
      },
    ],
  },
  {
    key: 6,
    title: <FormattedMessage defaultMessage="Weryfikacja" />,
    cellItems: () => [
      {
        name: 'verification',
        render: <DecimalInputNumber />,
        rules: [...sharedRules],
      },
    ],
  },
  {
    key: 12,
    title: <FormattedMessage defaultMessage="Specjalistyczne" />,
    cellItems: () => [
      {
        name: 'verificationSpecialized',
        render: <DecimalInputNumber />,
        rules: [...sharedRules],
      },
    ],
  },
  {
    key: 7,
    title: '',
    cellItems: ({ id, isNew }) => [
      {
        render: (
          <Popconfirm
            title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
            okText={<FormattedMessage defaultMessage="Tak" />}
            cancelText={<FormattedMessage defaultMessage="Nie" />}
            onConfirm={() => handleDelete(id, !!isNew)}
            disabled={isDeleteLoading}
          >
            <Button
              data-testid={`deleteButton${id}`}
              size="small"
              type="link"
              icon={<TrashIcon />}
              danger
              className={styles.deleteButton}
            >
              <FormattedMessage defaultMessage="Usuń" />
            </Button>
          </Popconfirm>
        ),
      },
    ],
  },
];
