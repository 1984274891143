import type { PreorderVariantTableValues } from 'components/ui/organisms/Tables/PreorderVariantTable';
import type { FileTranslationType } from 'constants/fileTranslation';
import type { FC } from 'react';
import { Select } from 'antd';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import {
  fileTranslationTypes,
  fileTranslationTypesObject,
} from 'constants/fileTranslation';
import { useAppSelector } from 'ducks/store';

interface Props {
  id: number | string;
  type: FileTranslationType;
}

const TypeSelectColumn: FC<Props> = memo(({ id, type }) => {
  const intl = useIntl();
  const { isEditable, selectedPrice } = useAppSelector(({ preorders }) => ({
    isEditable: preorders.isEditable,
    selectedPrice: preorders.selectedPrice,
  }));
  const isLackOfPriceListItems = !selectedPrice?.items.length;
  const dataTestid = `type-${id}`;

  return isEditable ? (
    <FormItem<PreorderVariantTableValues>
      name={[id as number, 'type']}
      component={
        <Select
          data-testid={dataTestid}
          options={fileTranslationTypes(intl)}
          disabled={isLackOfPriceListItems}
        />
      }
      additionalItemProps={{
        initialValue: type,
      }}
    />
  ) : (
    <span key={id} data-testid={dataTestid}>
      {fileTranslationTypesObject(intl)[type].label}
    </span>
  );
});

export default TypeSelectColumn;
