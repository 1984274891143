import type { PreorderRow } from './config';
import type {
  GetPreordersFilter,
  GetPreordersSort,
} from 'ducks/preorders/types';
import { Button } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import Table from 'components/ui/molecules/Table';
import { useGetPreordersQuery } from 'ducks/preorders/service';
import useTablePagination from 'hooks/useTablePagination';
import { PlusIcon } from 'icons';
import { Routes } from 'views/Preorders/Routing';
import PreordersTableToolbar from './components/PreordersTableToolbar';
import { columns, initialFilters } from './config';
import styles from './styles.module.scss';

const PreordersTable = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    page,
    handlePageChange,
    searchValue,
    searchTerm,
    handleSearchValue,
    filter,
    filterBy,
    handleFilterBy,
    handleTableChange,
    sortBy,
  } = useTablePagination<PreorderRow, GetPreordersFilter>({ initialFilters });
  // this exception is needed because difference of column name and sorting name
  const sort = sortBy?.order
    ? {
        [sortBy.columnKey === 'price'
          ? 'price_min'
          : (sortBy.columnKey as keyof GetPreordersSort)]: sortBy.order,
      }
    : {
        ['status' as keyof GetPreordersSort]: 'ascend',
      };
  const { data, isLoading, isFetching } = useGetPreordersQuery(
    {
      page,
      searchTerm,
      filter,
      sort,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true },
  );
  const pageSize = data?.perPage;
  const total = isFetching ? 0 : data?.total;

  const modifiedData = useMemo(
    (): PreorderRow[] =>
      (data?.data ?? []).map((preorder) => ({
        ...preorder,
        key: preorder.id,
      })),
    [data],
  );

  return (
    <div className="layout-content mb-24">
      <div className={styles.wrapper}>
        <PreordersTableToolbar
          searchValue={searchValue}
          onSearchValueChange={handleSearchValue}
          filterBy={filterBy}
          onFilterByChange={handleFilterBy}
        />
        <Table
          pagination={{
            current: page,
            pageSize,
            total,
            onChange: handlePageChange,
          }}
          locale={{
            triggerDesc: intl.formatMessage({
              defaultMessage: 'Sortuj malejąco',
            }),
            triggerAsc: intl.formatMessage({
              defaultMessage: 'Sortuj rosnąco',
            }),
            cancelSort: intl.formatMessage({
              defaultMessage: 'Anuluj sortowanie',
            }),
          }}
          columns={columns({ intl })}
          onChange={handleTableChange}
          isLoading={isFetching}
          data={modifiedData}
          onRow={({ id }) => ({
            onClick: () =>
              navigate(Routes.PREORDER_DETAILS.replace(':id', id.toString())),
          })}
          cardTitle={<FormattedMessage defaultMessage="Lista wycen" />}
          cardTitleExtra={
            <Link to={Routes.PREORDER_CREATE}>
              <Button icon={<PlusIcon />} type="primary" disabled={isLoading}>
                <FormattedMessage defaultMessage="Dodaj" />
              </Button>
            </Link>
          }
        />
      </div>
    </div>
  );
};

export default PreordersTable;
