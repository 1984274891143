import type { UploadOnChangeParam } from 'components/ui/molecules/Upload';
import { notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import Upload from 'components/ui/molecules/Upload';
import {
  useUpdateOrderTranslationFilesMutation,
  useUploadOrderTranslationFilesMutation,
} from 'ducks/orders/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setGlobalIsLoading } from 'ducks/view/global';
import { useOrderTranslationFilesServices } from 'views/Orders/views/OrderTranslationFiles/useOrderTranslationFilesServices';
import {
  generateOrderTranslationFilesBody,
  validateUpdateOrderTranslationBody,
} from 'views/Orders/views/OrderTranslationFiles/utils';

const TranslationFileListTitleUploadAction = () => {
  const [notify, contextHolder] = notification.useNotification();
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();
  const orderFiles = useAppSelector(
    ({ orderTranslationFiles }) => orderTranslationFiles.orderFiles,
    shallowEqual,
  );
  const { isLoading: isServicesLoading } = useOrderTranslationFilesServices();
  const [updateOrderTranslationFiles, { isLoading: isUpdateLoading }] =
    useUpdateOrderTranslationFilesMutation();
  const [uploadOrderTranslationFiles, { isLoading: isUploadLoading }] =
    useUploadOrderTranslationFilesMutation();
  const isLoading = isServicesLoading || isUploadLoading || isUpdateLoading;
  /**
   * isUploadingFiles variable is there to prevent strange repetition of props
   * from onChange in Antd Upload set to multiple true.
   * Without it, Antd Upload will return an array with
   * the uploaded files 3 times when we upload 3 files to it.
   */
  let isUploadingFiles = false;

  const handleUpdate = async () => {
    try {
      const body = generateOrderTranslationFilesBody(orderFiles);

      const invalidBodyItems = validateUpdateOrderTranslationBody(body);

      if (invalidBodyItems.length) {
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Przypisz wszystkich tłumaczy do par językowych." />
          ),
        });

        return { error: true };
      }

      await updateOrderTranslationFiles({
        id,
        body,
      }).unwrap();

      return { error: false };
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas próby przypisania do zamówienia." />
        ),
      });

      return { error: true };
    }
  };

  const handleUpload = async ({ fileList }: UploadOnChangeParam) => {
    if (isLoading || isUploadingFiles) return;

    isUploadingFiles = true;

    const files = fileList.map(({ originFileObj }) => originFileObj as File);

    dispatch(setGlobalIsLoading(true));

    try {
      const { error } = await handleUpdate();

      if (error) return;

      await uploadOrderTranslationFiles({
        id,
        files,
      }).unwrap();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd poczas wgrywania plików." />
        ),
      });
    } finally {
      isUploadingFiles = false;
      dispatch(setGlobalIsLoading(false));
    }
  };

  return (
    <>
      {contextHolder}
      <Upload onChange={handleUpload} disabled={isLoading} fullWidth={false}>
        <Button loading={isLoading}>
          <FormattedMessage defaultMessage="Wgraj pliki" />
        </Button>
      </Upload>
    </>
  );
};

export default TranslationFileListTitleUploadAction;
