import type {
  AddTranslatorRequest,
  AddTranslatorResponse,
  AddTranslatorsPriceListsRequest,
  DeleteTranslatorsPriceListsRequest,
  EditTranslatorsPriceListsRequest,
  GetTranslatorDetailsRequest,
  GetTranslatorsCatsResponse,
  GetTranslatorsPriceListsRequest,
  GetTranslatorsPriceListsResponse,
  GetTranslatorsQualificationsRequest,
  GetTranslatorsRequest,
  GetTranslatorsResponse,
  GetTranslatorsSpecializationsResponse,
  GetTranslatorsTypesResponse,
  Translator,
  GetTranslatorsQualificationsResponse,
  UpdateTranslatorRequest,
  UpdateTranslatorStatusRequest,
  UpdateTranslatorVacationRequest,
  DeleteTranslatorsQualificationsRequest,
  EditTranslatorsQualificationsRequest,
  GetTranslatorsOrdersRequest,
  GetTranslatorsOrdersResponse,
  GetTranslatorsPricingResponse,
  GetTranslatorsPricingRequest,
  EditTranslatorOrderRateRequest,
} from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import { formatFilterParameter, formatSortParameter } from 'utilities/api';
import { convertKeysToSnakeCase } from 'utilities/object';
import { TranslatorsTag } from './types';

export enum Endpoint {
  GetTranslators = '/translators',
  AddTranslator = '/translators',
  GetTranslatorsCats = '/translators/cats',
  GetTranslatorsSpecializations = '/translators/specializations',
  GetTranslatorsTypes = '/translators/types',
  GetTranslatorsPricing = '/translators/pricing',
  UpdateTranslator = '/translators/:id',
  GetTranslatorDetails = '/translators/:id',
  UpdateTranslatorVacation = '/translators/:id/vacation',
  UpdateTranslatorStatus = '/translators/:id/status',
  GetTranslatorsPriceLists = '/translators/:id/translations-price-lists',
  AddTranslatorsPriceLists = '/translators/:id/translations-price-lists',
  EditTranslatorsPriceLists = '/translators/:id/translations-price-lists',
  DeleteTranslatorsPriceLists = '/translators/:id/translations-price-lists/:itemId',
  GetTranslatorsQualifications = '/translators/:id/translators-qualifications',
  EditTranslatorsQualifications = '/translators/:id/translators-qualifications',
  DeleteTranslatorsQualification = '/translators/:id/translators-qualifications/:itemId',
  GetTranslatorsOrders = '/translators/:id/orders',
  EditTranslatorOrderRate = '/translators/:id/orders/:orderId/rate',
}

export const translatorsApi = createApi({
  reducerPath: 'translatorsApi',
  baseQuery: baseQuery(),
  tagTypes: [
    TranslatorsTag.Translators,
    TranslatorsTag.TranslatorsPriceLists,
    TranslatorsTag.TranslatorsQualifications,
    TranslatorsTag.TranslatorsOrders,
  ],
  endpoints: (build) => ({
    getTranslators: build.query<GetTranslatorsResponse, GetTranslatorsRequest>({
      query: ({ page, searchTerm, sort, filter }) => ({
        url: Endpoint.GetTranslators,
        method: 'GET',
        params: {
          page,
          s: searchTerm,
          filter: formatFilterParameter(filter),
          sort: formatSortParameter(sort),
        },
      }),
      providesTags: [TranslatorsTag.Translators],
    }),
    addTranslator: build.mutation<AddTranslatorResponse, AddTranslatorRequest>({
      query: (data) => ({
        url: Endpoint.AddTranslator,
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [TranslatorsTag.Translators],
    }),
    getTranslatorsCats: build.query<GetTranslatorsCatsResponse, void>({
      query: () => ({
        url: Endpoint.GetTranslatorsCats,
        method: 'GET',
      }),
    }),
    getTranslatorsSpecializations: build.query<
      GetTranslatorsSpecializationsResponse,
      void
    >({
      query: () => ({
        url: Endpoint.GetTranslatorsSpecializations,
        method: 'GET',
      }),
    }),
    getTranslatorsTypes: build.query<GetTranslatorsTypesResponse, void>({
      query: () => ({
        url: Endpoint.GetTranslatorsTypes,
        method: 'GET',
      }),
    }),
    getTranslatorsPricing: build.query<
      GetTranslatorsPricingResponse,
      GetTranslatorsPricingRequest
    >({
      query: ({ filter }) => ({
        url: Endpoint.GetTranslatorsPricing,
        method: 'GET',
        params: {
          filter: formatFilterParameter(filter),
        },
      }),
    }),
    updateTranslator: build.mutation<void, UpdateTranslatorRequest>({
      query: ({ translatorId, ...data }) => ({
        url: Endpoint.UpdateTranslator.replace(':id', translatorId.toString()),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [TranslatorsTag.Translators],
    }),
    getTranslatorDetails: build.query<Translator, GetTranslatorDetailsRequest>({
      query: ({ translatorId }) => ({
        url: Endpoint.GetTranslatorDetails.replace(
          ':id',
          translatorId.toString(),
        ),
        method: 'GET',
      }),
      providesTags: [TranslatorsTag.Translators],
    }),
    updateTranslatorVacation: build.mutation<
      void,
      UpdateTranslatorVacationRequest
    >({
      query: ({ translatorId, ...data }) => ({
        url: Endpoint.UpdateTranslatorVacation.replace(
          ':id',
          translatorId.toString(),
        ),
        method: 'PATCH',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [TranslatorsTag.Translators],
    }),
    updateTranslatorStatus: build.mutation<void, UpdateTranslatorStatusRequest>(
      {
        query: ({ translatorId, ...data }) => ({
          url: Endpoint.UpdateTranslatorStatus.replace(
            ':id',
            `${translatorId}`,
          ),
          method: 'PATCH',
          data: convertKeysToSnakeCase(data),
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [TranslatorsTag.Translators],
      },
    ),
    getTranslatorsPriceLists: build.query<
      GetTranslatorsPriceListsResponse,
      GetTranslatorsPriceListsRequest
    >({
      query: ({ translatorId }) => ({
        url: Endpoint.GetTranslatorsPriceLists.replace(
          ':id',
          translatorId.toString(),
        ),
        method: 'GET',
      }),
      providesTags: [TranslatorsTag.TranslatorsPriceLists],
    }),
    addTranslatorsPriceLists: build.mutation<
      void,
      AddTranslatorsPriceListsRequest
    >({
      query: ({ translatorId, items }) => ({
        url: Endpoint.AddTranslatorsPriceLists.replace(
          ':id',
          translatorId.toString(),
        ),
        method: 'POST',
        data: items,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [TranslatorsTag.TranslatorsPriceLists],
    }),
    editTranslatorsPriceLists: build.mutation<
      void,
      EditTranslatorsPriceListsRequest
    >({
      query: ({ translatorId, items }) => ({
        url: Endpoint.EditTranslatorsPriceLists.replace(
          ':id',
          translatorId.toString(),
        ),
        method: 'PATCH',
        data: items,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [TranslatorsTag.TranslatorsPriceLists],
    }),
    deleteTranslatorsPriceLists: build.mutation<
      void,
      DeleteTranslatorsPriceListsRequest
    >({
      query: ({ translatorId, itemId }) => ({
        url: Endpoint.DeleteTranslatorsPriceLists.replace(
          ':id',
          translatorId.toString(),
        ).replace(':itemId', itemId.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [TranslatorsTag.TranslatorsPriceLists],
    }),
    getTranslatorsQualifications: build.query<
      GetTranslatorsQualificationsResponse,
      GetTranslatorsQualificationsRequest
    >({
      query: ({ translatorId }) => ({
        url: Endpoint.GetTranslatorsQualifications.replace(
          ':id',
          `${translatorId}`,
        ),
        method: 'GET',
      }),
      providesTags: [TranslatorsTag.TranslatorsQualifications],
    }),
    editTranslatorsQualifications: build.mutation<
      void,
      EditTranslatorsQualificationsRequest
    >({
      query: ({ translatorId, items }) => {
        const formData = new FormData();

        items.forEach(
          ({ file, sectionId, field1, field2, field3, field4 }, index) => {
            formData.append(
              `items[${index}][section_id]`,
              sectionId.toString(),
            );
            formData.append(`items[${index}][field_1]`, field1);
            formData.append(`items[${index}][field_2]`, field2);
            formData.append(`items[${index}][field_3]`, field3);
            formData.append(`items[${index}][field_4]`, field4);
            if (file) {
              formData.append(
                `items[${index}][file]`,
                new Blob([file]),
                file.name,
              );
            }
          },
        );

        return {
          url: Endpoint.EditTranslatorsQualifications.replace(
            ':id',
            translatorId.toString(),
          ),
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      invalidatesTags: (_, error) =>
        error ? [] : [TranslatorsTag.TranslatorsQualifications],
    }),
    deleteTranslatorsQualification: build.mutation<
      void,
      DeleteTranslatorsQualificationsRequest
    >({
      query: ({ translatorId, itemId }) => ({
        url: Endpoint.DeleteTranslatorsQualification.replace(
          ':id',
          translatorId.toString(),
        ).replace(':itemId', itemId.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [TranslatorsTag.TranslatorsQualifications],
    }),
    getTranslatorsOrders: build.query<
      GetTranslatorsOrdersResponse,
      GetTranslatorsOrdersRequest
    >({
      query: ({ translatorId }) => ({
        url: Endpoint.GetTranslatorsOrders.replace(
          ':id',
          translatorId.toString(),
        ),
      }),
      providesTags: [TranslatorsTag.TranslatorsOrders],
    }),
    editTranslatorOrderRate: build.mutation<
      void,
      EditTranslatorOrderRateRequest
    >({
      query: ({ translatorId, orderId, ...data }) => ({
        url: Endpoint.EditTranslatorOrderRate.replace(
          ':id',
          translatorId.toString(),
        ).replace(':orderId', orderId.toString()),
        method: 'POST',
        data: convertKeysToSnakeCase(data),
      }),
      invalidatesTags: (_, error) =>
        error
          ? []
          : [TranslatorsTag.TranslatorsOrders, TranslatorsTag.Translators],
    }),
  }),
});

export const {
  useGetTranslatorsQuery,
  useAddTranslatorMutation,
  useGetTranslatorsCatsQuery,
  useGetTranslatorsSpecializationsQuery,
  useGetTranslatorsTypesQuery,
  useGetTranslatorsPricingQuery,
  useUpdateTranslatorMutation,
  useGetTranslatorDetailsQuery,
  useUpdateTranslatorVacationMutation,
  useUpdateTranslatorStatusMutation,
  useGetTranslatorsPriceListsQuery,
  useAddTranslatorsPriceListsMutation,
  useEditTranslatorsPriceListsMutation,
  useDeleteTranslatorsPriceListsMutation,
  useGetTranslatorsQualificationsQuery,
  useEditTranslatorsQualificationsMutation,
  useDeleteTranslatorsQualificationMutation,
  useGetTranslatorsOrdersQuery,
  useEditTranslatorOrderRateMutation,
} = translatorsApi;
