import type { FC } from 'react';
import Modal from 'components/ui/molecules/Modal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const IssueInvoiceModal: FC<Props> = ({ isOpen, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={isOpen} onCancel={handleClose} disableModalStyles>
      <div />
    </Modal>
  );
};

export default IssueInvoiceModal;
