/* eslint-disable import/no-extraneous-dependencies */
import type { TableRow } from './config';
import type { TablePaginationConfig } from 'antd';
import type { TableProps } from 'antd/lib/table';
import type { ReactElement } from 'react';
import { Card, Table as AntdTable } from 'antd';
import cn from 'classnames';
import { useMemo } from 'react';
import styles from './styles.module.scss';
import TitleWrapper from './TitleWrapper';
import { getTableSkeleton } from './utils';

interface Props<T> extends TableProps<T> {
  data: T[];
  isLoading?: boolean;
  cardHeader?: ReactElement | ReactElement[];
  emptyText?: ReactElement | string | undefined;
  pagination?: TablePaginationConfig;
  cardExtra?: ReactElement | ReactElement[];
  cardTitleExtra?: ReactElement | ReactElement[];
  cardTitle?: ReactElement | ReactElement[];
  isCompact?: boolean;
  cardFooter?: ReactElement | null;
  hidePagination?: boolean;
}

const Table = <T extends TableRow>({
  data,
  columns,
  cardHeader,
  rowSelection,
  onRow,
  rowClassName,
  pagination,
  isLoading = false,
  showHeader = true,
  cardTitle,
  cardTitleExtra,
  cardExtra,
  cardFooter,
  isCompact = true,
  hidePagination = false,
  ...props
}: Props<T>): JSX.Element => {
  const skeletonData = useMemo(() => {
    const columnNames = columns?.map(({ key }) => key as string) || [];
    return getTableSkeleton(columnNames, 3);
  }, [columns]);

  const rawColumns = useMemo(() => {
    return (
      columns?.map((column) => {
        if (column.render) {
          const columnCopy = { ...column };
          delete columnCopy.render;
          return columnCopy;
        }

        return column;
      }) || []
    );
  }, [columns]);

  return (
    <Card
      bordered={false}
      className={cn('criclebox cardbody h-full', styles.card)}
      title={
        cardTitleExtra ? (
          <TitleWrapper>
            {cardTitle} {cardTitleExtra}
          </TitleWrapper>
        ) : (
          cardTitle
        )
      }
      extra={cardExtra}
    >
      {cardHeader && <div className="project-ant">{cardHeader}</div>}
      <div className="ant-list-box table-responsive">
        <AntdTable
          className={cn(
            'ant-border-space',
            {
              [styles.rowPointer]: !!onRow,
            },
            isCompact ? styles.compactTable : '',
          )}
          rowClassName={rowClassName ?? styles.row}
          dataSource={isLoading ? (skeletonData as T[]) : data}
          columns={isLoading ? rawColumns : columns}
          rowSelection={rowSelection}
          showHeader={showHeader}
          pagination={
            hidePagination
              ? false
              : {
                  hideOnSinglePage: true,
                  showSizeChanger: false,
                  position: ['bottomCenter'],
                  ...pagination,
                }
          }
          onRow={onRow}
          data-testid="table"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </div>
      {cardFooter}
    </Card>
  );
};

export default Table;
