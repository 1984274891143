import type { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Empty from 'components/ui/atoms/Empty';
import { useOrdersServices } from 'views/Orders/useOrdersServices';
import OrderHeader from '../OrderHeader';
import OrderWrapper from '../OrderWrapper';

type Props = {
  children: ReactNode;
};

const OrderCurrentStatus: FC<Props> = ({ children }) => {
  const { isOrderEmpty, isCurrentlyEdited, isOrderLoading } =
    useOrdersServices();

  if (isOrderEmpty)
    return (
      <Empty
        description={
          <FormattedMessage defaultMessage="Brak danych o zleceniu" />
        }
      />
    );

  if (isCurrentlyEdited && !isOrderLoading)
    return (
      <OrderWrapper>
        <OrderHeader />
        <Empty
          data-testid="currentlyEditedState"
          description={
            <FormattedMessage defaultMessage="Zlecenie jest aktualnie w edycji przez innego użytkownika" />
          }
        />
      </OrderWrapper>
    );

  return <OrderWrapper>{children}</OrderWrapper>;
};

export default OrderCurrentStatus;
