import type { SortOrder } from 'antd/lib/table/interface';
import type { AxiosResponse } from 'axios';
import { isObject, isArray, reduce, snakeCase } from 'lodash';
import { convertArrayOfObjectsToObject } from './array';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const formatObjectKeys = (
  obj:
    | Record<string, unknown>
    | ArrayBuffer
    | Array<Record<string, unknown>>
    | AxiosResponse<unknown>
    | URLSearchParams,
  formatFn: (param: string) => string,
): Record<string, unknown> | Array<unknown> | URLSearchParams | ArrayBuffer => {
  if (!isObject(obj) || obj instanceof ArrayBuffer) return obj;
  if (isArray(obj)) return obj.map((v) => formatObjectKeys(v, formatFn));
  if (obj instanceof URLSearchParams) return obj;

  return reduce(
    obj,
    (r, v, k) => {
      return {
        ...r,
        ...(typeof v === 'undefined'
          ? {}
          : {
              [k.includes('-') ? k : formatFn(k)]: formatObjectKeys(
                v,
                formatFn,
              ),
            }),
      };
    },
    {},
  );
};

export const formatSortParameter = (
  param: Record<string, SortOrder> | null | undefined,
) => {
  if (param) {
    const key = Object.keys(param)[0];
    const value = Object.values(param)[0];
    const sortOrder = value === 'ascend' ? '' : '-';

    if (key.includes(',')) {
      const formattedArrayOfKeys = key.split(',').map((el) => snakeCase(el));
      const stringWithSortOrderPrefix = formattedArrayOfKeys.join(
        `,${sortOrder}`,
      );

      return `${sortOrder}${stringWithSortOrderPrefix}`;
    }

    return `${sortOrder}${snakeCase(key)}`;
  }

  return undefined;
};

export const formatFilterParameter = (
  param: Record<string, unknown> | null | undefined,
) => {
  if (!param) return undefined;

  return convertArrayOfObjectsToObject(
    Object.entries(param).map(([key, value]) => {
      const snakeCaseKey = snakeCase(key);

      return {
        [snakeCaseKey]: Array.isArray(value) ? value.join(',') : value,
      };
    }),
  );
};

export const getStorageUrl = () => BASE_URL.replace('api', 'storage');

export const getExportUrl = (type: string) => `${BASE_URL}/exports/${type}`;
