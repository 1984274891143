/* eslint-disable react/jsx-props-no-spreading */
import { Button } from 'antd';
import cn from 'classnames';
import {
  useEffect,
  type ComponentPropsWithoutRef,
  type FC,
  type ReactElement,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from 'ducks/auth/service';

interface LogoutButtonProps
  extends Pick<ComponentPropsWithoutRef<typeof Button>, 'type' | 'icon'> {
  children?: ReactElement | ReactElement[];
  className?: string;
}

const LogoutButton: FC<LogoutButtonProps> = ({
  children,
  className,
  type,
  icon,
  ...props
}) => {
  const navigate = useNavigate();
  const [logout, { isLoading, isSuccess }] = useLogoutMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Button
      data-testid="logout"
      type={type ?? 'link'}
      icon={icon}
      className={cn({ 'text-muted': !type }, className)}
      disabled={isLoading}
      {...props}
      onClick={() => logout()}
    >
      <span>
        {children || <FormattedMessage defaultMessage="Wyloguj się" />}
      </span>
    </Button>
  );
};

export default LogoutButton;
