import { Form } from 'antd';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import Empty from 'components/ui/atoms/Empty';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import { EditIcon } from 'icons';
import styles from '../../styles.module.scss';
import { useInvoiceOverdueDetailsServices } from '../../useInvoiceOverdueDetailsServices';
import { formItems } from './config';
import EditDebtCollectionsModal from './EditDebtCollectionsModal';

const DebtCollectionsSection = () => {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { invoiceOverdueData, isInvoiceOverdueFetching } =
    useInvoiceOverdueDetailsServices();
  const debtCollection = invoiceOverdueData?.debtCollections;
  const disableEditButton = !isInvoiceOverdueFetching && !debtCollection;

  const handleOpenModal = () => !disableEditButton && setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <CardCommon
        title={
          <div className={styles.cardCommonTitleWrapper}>
            <FormattedMessage defaultMessage="Windykacja" />
            <Button
              type="primary"
              icon={<EditIcon />}
              onClick={handleOpenModal}
              loading={isInvoiceOverdueFetching}
              disabled={disableEditButton}
            >
              <FormattedMessage defaultMessage="Edytuj" />
            </Button>
          </div>
        }
        isLoading={isInvoiceOverdueFetching}
      >
        {!debtCollection ? (
          <Empty
            description={
              <FormattedMessage defaultMessage="Proces windykacji nie został rozpoczęty" />
            }
          />
        ) : (
          <Form autoComplete="off" layout="vertical">
            <div className="gridForm">
              {formItems({ intl, debtCollection }).map(({ key, ...props }) => (
                <FormItem key={key} {...props} />
              ))}
            </div>
          </Form>
        )}
      </CardCommon>
      {debtCollection && (
        <EditDebtCollectionsModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          debtCollection={debtCollection}
        />
      )}
    </>
  );
};

export default DebtCollectionsSection;
