import type { EditAccountingNotesModalValues } from './config';
import type { AccountingNotes } from 'ducks/invoices/types';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Form, notification } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { useUpdateInvoiceAccountingNoteMutation } from 'ducks/invoices/service';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';
import { formItems } from './config';

const { useForm } = Form;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  accountingNotes: AccountingNotes;
};

const EditAccountingNotesModal: FC<Props> = ({
  isOpen,
  onClose,
  accountingNotes,
}) => {
  const { id = '' } = useParams();
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm<EditAccountingNotesModalValues>();
  const [updateInvoiceAccountingNote, { isLoading }] =
    useUpdateInvoiceAccountingNoteMutation();
  const initialValues: EditAccountingNotesModalValues = {
    ...accountingNotes,
    number: accountingNotes.number ?? '',
    dateSent: dayjs(accountingNotes.dateSent ?? undefined),
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleUpdateAccountingNote = () => {
    if (isLoading || !id) return;

    mutationFunctionWithForm<void, EditAccountingNotesModalValues>({
      form,
      mutationFn: async ({ dateSent, ...values }) => {
        const formattedDateSent = dayjs(dateSent).format() as unknown as Date;

        await updateInvoiceAccountingNote({
          id,
          itemId: accountingNotes.id,
          ...values,
          dateSent: formattedDateSent,
        }).unwrap();
      },
      onSuccess: handleClose,
      onError: () =>
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania noty księgowej." />
          ),
        }),
    });
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Nota księgowa" />}
      open={isOpen}
      onOk={handleUpdateAccountingNote}
      onCancel={handleClose}
      isLoading={isLoading}
      disableModalStyles
      disableFormStyles
    >
      {contextHolder}
      <Form
        form={form}
        initialValues={initialValues}
        autoComplete="off"
        layout="vertical"
      >
        {formItems({ intl }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default EditAccountingNotesModal;
