import type { FC } from 'react';
import { Rate, Space, Typography } from 'antd';

interface Props {
  rate: number | null | undefined;
}

const { Text } = Typography;

const RateItem: FC<Props> = ({ rate }) => {
  return rate ? (
    <Space>
      <Text>{rate ?? ''}/5</Text>
      <Rate disabled allowHalf defaultValue={1} count={1} />
    </Space>
  ) : (
    <>---</>
  );
};

export default RateItem;
