import type {
  PreorderVariantFileSlice,
  PreorderVariantLangSlice,
  PreorderVariantSlice,
} from 'ducks/view/preorders';
import { FileTranslationType } from 'constants/fileTranslation';
import { ServiceType } from 'constants/services';
import { calcDiscount } from './calcPrice';

type CalcPreorderVariantLangPriceNettoProps = Pick<
  PreorderVariantLangSlice,
  'pages' | 'priceNetto' | 'priceSpecialNetto' | 'priceCorrectionNetto'
> &
  Pick<PreorderVariantFileSlice, 'type' | 'isSpecial' | 'isCorrection'> & {
    discount?: number;
  };
type CalcProps = PreorderVariantLangSlice &
  Omit<PreorderVariantFileSlice, 'preorderVariantLangs'>;

const calculatePreorderVariantFilesTotal = (
  preorderVariantFiles: PreorderVariantFileSlice[],
  calc: (props: CalcProps) => number,
) => {
  const activeVariantFiles = preorderVariantFiles.filter(
    ({ isActive }) => isActive,
  );

  const total = activeVariantFiles.reduce(
    (acc, { preorderVariantLangs, ...variantFile }) => {
      const innerSum = preorderVariantLangs.reduce(
        (innerAcc, variantLang) =>
          innerAcc + calc({ ...variantLang, ...variantFile }),
        0,
      );

      return acc + innerSum;
    },
    0,
  );

  return Number(total.toFixed(2));
};

export const calcPreorderVariantLangPriceNetto = ({
  type,
  pages,
  priceNetto,
  priceSpecialNetto,
  priceCorrectionNetto,
  isSpecial,
  isCorrection,
  discount,
}: CalcPreorderVariantLangPriceNettoProps) => {
  const minPageValue = (() => {
    if (
      type === FileTranslationType.NORMAL ||
      type === FileTranslationType.CORRECTION
    )
      return pages;

    if (pages === 0) return 0;

    return pages < 1 ? 1 : pages;
  })();
  const normalPrice = minPageValue * priceNetto;
  const specialPrice = minPageValue * priceSpecialNetto;
  const correctionPrice = isCorrection
    ? minPageValue * priceCorrectionNetto
    : 0;
  const finalPrice = (isSpecial ? specialPrice : normalPrice) + correctionPrice;

  return discount
    ? calcDiscount(finalPrice, discount)
    : Number(finalPrice.toFixed(2));
};

export const calcPreorderVariantFilesPriceNettoTotal = (
  preorderVariantFiles: PreorderVariantFileSlice[],
) =>
  calculatePreorderVariantFilesTotal(
    preorderVariantFiles,
    ({
      type,
      isSpecial,
      isCorrection,
      pages,
      priceNetto,
      priceSpecialNetto,
      priceCorrectionNetto,
      discount,
    }) =>
      calcPreorderVariantLangPriceNetto({
        type,
        pages,
        priceNetto,
        priceSpecialNetto,
        priceCorrectionNetto,
        isSpecial,
        isCorrection,
        discount,
      }),
  );

export const calcPreorderVariantFilesCorrectionTotal = (
  preorderVariantFiles: PreorderVariantFileSlice[],
) =>
  calculatePreorderVariantFilesTotal(
    preorderVariantFiles,
    ({ isCorrection, pages, priceCorrectionNetto, discount }) =>
      isCorrection
        ? calcDiscount(pages * priceCorrectionNetto, discount, false)
        : 0,
  );

export const calcPreorderVariantFilesSpecialTotal = (
  preorderVariantFiles: PreorderVariantFileSlice[],
) =>
  calculatePreorderVariantFilesTotal(
    preorderVariantFiles,
    ({ isSpecial, pages, priceSpecialNetto, discount }) =>
      isSpecial ? calcDiscount(pages * priceSpecialNetto, discount, false) : 0,
  );

export const calcPreorderVariantServicesPriceNettoTotal = (
  preorderVariantServices: PreorderVariantSlice['preorderVariantServices'],
) => {
  const activeServices = preorderVariantServices.filter(
    ({ isActive }) => isActive,
  );

  const calcTotalServiceByType = (serviceType: ServiceType) =>
    activeServices
      .filter(({ type }) => type === serviceType)
      .reduce((acc, { priceNetto }) => acc + priceNetto, 0);

  const graphicTotal = calcTotalServiceByType(ServiceType.GRAPHIC_SERVICE);
  const additionalTotal = calcTotalServiceByType(ServiceType.ADDITIONAL);
  const shipmentTotal = calcTotalServiceByType(ServiceType.SHIPMENT);
  const preparatoryTotal = calcTotalServiceByType(ServiceType.PREPARATORY);
  const serviceTotal =
    graphicTotal + additionalTotal + shipmentTotal + preparatoryTotal;

  return {
    graphicTotal,
    additionalTotal,
    shipmentTotal,
    preparatoryTotal,
    serviceTotal,
  };
};

export const calcPreorderVariantPriceTotal = (
  preorderVariant: PreorderVariantSlice,
) => {
  const { preorderVariantFiles, preorderVariantServices } = preorderVariant;

  const filesPriceNettoTotal =
    calcPreorderVariantFilesPriceNettoTotal(preorderVariantFiles);
  const { serviceTotal } = calcPreorderVariantServicesPriceNettoTotal(
    preorderVariantServices,
  );

  return Number((filesPriceNettoTotal + serviceTotal).toFixed(2));
};
