import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type {
  GetTranslatorsPricingFilter,
  TranslatorPricing,
} from 'ducks/translators/types';
import type { OrderFileLangState } from 'ducks/view/orders';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { getTranslatorName } from 'utilities/translator';

const { Text } = Typography;

export type TranslatorPricingRow = TableRow &
  TranslatorPricing & {
    priceFrom: number;
  };

export const initialFilters: GetTranslatorsPricingFilter = {
  type: undefined,
  status: 1,
  lang: undefined,
  priceType: undefined,
  catId: undefined,
  specializations: undefined,
  isPostedit: undefined,
};

type Props = {
  handleSelectTranslator: (id: number, priceFrom: number) => void;
} & Pick<OrderFileLangState, 'translatorId'>;

export const columns = ({
  handleSelectTranslator,
  translatorId,
}: Props): ColumnsType<TranslatorPricingRow> => [
  {
    key: 'name',
    dataIndex: 'name',
    title: <FormattedMessage defaultMessage="Nazwa" />,
    render: (_, translator) => <span>{getTranslatorName(translator)}</span>,
  },
  {
    key: 'ordersInProgress',
    dataIndex: 'ordersInProgress',
    title: <FormattedMessage defaultMessage="Zlecenia w realizacji" />,
    align: 'center',
    render: (_, { orders }) => (
      <Text type="warning">{orders.inProgress ?? 0}</Text>
    ),
  },
  {
    key: 'minNormalFrom',
    dataIndex: 'minNormalFrom',
    title: <FormattedMessage defaultMessage="Przedział cenowy" />,
    align: 'center',
    render: (_, { priceFrom }) => <CurrencyFormatter value={priceFrom} />,
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    width: 75,
    render: (_, { id, priceFrom }) => (
      <Button
        onClick={() => handleSelectTranslator(id, priceFrom)}
        disabled={id === translatorId}
      >
        <FormattedMessage defaultMessage="Wybierz" />
      </Button>
    ),
  },
];
