/* eslint-disable react/style-prop-object */
import type { FC } from 'react';
import { FormattedNumber } from 'react-intl';
import { CurrencyType } from 'ducks/payments/types';

type Props = {
  value: number;
  currency?: CurrencyType;
};

const CurrencyFormatter: FC<Props> = ({
  value,
  currency = CurrencyType.PLN,
}) => {
  return <FormattedNumber value={value} style="currency" currency={currency} />;
};

export default CurrencyFormatter;
