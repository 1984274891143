import { Form } from 'antd';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import Empty from 'components/ui/atoms/Empty';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import { EditIcon } from 'icons';
import styles from '../../styles.module.scss';
import { useInvoiceOverdueDetailsServices } from '../../useInvoiceOverdueDetailsServices';
import { formItems } from './config';
import EditAccountingNotesModal from './EditAccountingNotesModal';

const AccountingNotesSection = () => {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { invoiceOverdueData, isInvoiceOverdueFetching } =
    useInvoiceOverdueDetailsServices();
  const accountingNotes = invoiceOverdueData?.accountingNotes;
  const disableEditButton = !isInvoiceOverdueFetching && !accountingNotes;

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <CardCommon
        title={
          <div className={styles.cardCommonTitleWrapper}>
            <FormattedMessage defaultMessage="Nota księgowa" />
            <Button
              type="primary"
              icon={<EditIcon />}
              onClick={handleOpenModal}
              loading={isInvoiceOverdueFetching}
              disabled={disableEditButton}
            >
              <FormattedMessage defaultMessage="Edytuj" />
            </Button>
          </div>
        }
        isLoading={isInvoiceOverdueFetching}
      >
        {!accountingNotes ? (
          <Empty
            description={
              <FormattedMessage defaultMessage="Nota księgowa nie została wystawiona" />
            }
          />
        ) : (
          <Form autoComplete="off" layout="vertical">
            <div className="gridForm">
              {formItems({ intl, accountingNotes }).map(({ key, ...props }) => (
                <FormItem key={key} {...props} />
              ))}
            </div>
          </Form>
        )}
      </CardCommon>
      {accountingNotes && (
        <EditAccountingNotesModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          accountingNotes={accountingNotes}
        />
      )}
    </>
  );
};

export default AccountingNotesSection;
