import type { SendClientEmailsValues } from '../../config';
import type { FormInstance } from 'antd';
import type { FC } from 'react';
import Loader from 'components/ui/atoms/Loader';
import CardCommon from 'components/ui/molecules/CardCommon';
import SendEmailForm from 'components/ui/organisms/Forms/SendEmailForm/SendEmailForm';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setOrderSendClientEmailTemplate } from 'ducks/view/orders';
import { useOrderClientSendServices } from '../../useOrderClientSendServices';

type Props = {
  form: FormInstance<SendClientEmailsValues>;
};

const TemplateSettingsSection: FC<Props> = ({ form }) => {
  const dispatch = useAppDispatch();
  const emailTemplate = useAppSelector(
    ({ orders }) => orders.sendClientEmail.emailTemplate,
  );
  const { isOrderFetching } = useOrderClientSendServices();

  const handleEmailTemplateChange = (value: string) =>
    dispatch(setOrderSendClientEmailTemplate(value));

  return (
    <CardCommon data-testid="templateSettingsSection" xs={24} lg={14} xl={15}>
      <Loader visible={isOrderFetching}>
        <SendEmailForm
          form={form}
          emailTemplate={emailTemplate}
          onEmailTemplateChange={handleEmailTemplateChange}
        />
      </Loader>
    </CardCommon>
  );
};

export default TemplateSettingsSection;
