import type { GetInvoicesFilter, InvoiceType } from 'ducks/invoices/types';
import type { FC } from 'react';
import type { RangeDateChange } from 'utilities/date';
import { DatePicker, Input, Select } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import { useIntl } from 'react-intl';
import { invoiceTypes } from 'constants/invoice';
import { generateRangeDateValue } from 'utilities/date';
import styles from './styles.module.scss';

const { RangePicker } = DatePicker;

type Props = {
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  filterBy: GetInvoicesFilter;
  onFilterByChange: (filter: GetInvoicesFilter) => void;
};

const FinanceDocumentListToolbar: FC<Props> = ({
  searchValue,
  onSearchValueChange,
  filterBy: {
    dateCreationBefore,
    dateCreationAfter,
    dateDueBefore,
    dateDueAfter,
    type,
  },
  onFilterByChange,
}) => {
  const intl = useIntl();
  const dateSentValue = generateRangeDateValue(
    dateCreationBefore,
    dateCreationAfter,
  );
  const dateDuePaymentValue = generateRangeDateValue(
    dateDueBefore,
    dateDueAfter,
  );
  const typeOptions = invoiceTypes(intl);

  const handleDateCreationChange = ([after, before]: RangeDateChange) =>
    onFilterByChange({
      dateCreationAfter: after,
      dateCreationBefore: before,
    });

  const handleDateDueChange = ([after, before]: RangeDateChange) =>
    onFilterByChange({
      dateDueAfter: after,
      dateDueBefore: before,
    });

  const handleTypeChange = (value: InvoiceType) =>
    onFilterByChange({
      type: value,
    });

  return (
    <div className={styles.wrapper}>
      <Input
        value={searchValue}
        onChange={({ target }) => onSearchValueChange(target.value)}
        placeholder={intl.formatMessage({
          defaultMessage: 'Szukaj',
        })}
        allowClear
      />
      <RangePicker
        locale={locale}
        value={dateSentValue}
        onChange={(_, values) => handleDateCreationChange(values)}
        placeholder={[
          intl.formatMessage({ defaultMessage: 'Data wystawienia od' }),
          intl.formatMessage({ defaultMessage: 'Data wystawienia do' }),
        ]}
      />
      <RangePicker
        locale={locale}
        value={dateDuePaymentValue}
        onChange={(_, values) => handleDateDueChange(values)}
        placeholder={[
          intl.formatMessage({ defaultMessage: 'Data terminu płatności od' }),
          intl.formatMessage({ defaultMessage: 'Data terminu płatności do' }),
        ]}
      />
      <Select
        options={typeOptions}
        value={type}
        onChange={handleTypeChange}
        placeholder={intl.formatMessage({ defaultMessage: 'Rodzaj dokumentu' })}
        allowClear
      />
    </div>
  );
};

export default FinanceDocumentListToolbar;
