import type { DefaultOptionType } from 'antd/es/select';
import type { GetClientsFilter } from 'ducks/clients/types';
import type { FC } from 'react';
import { Input, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles.module.scss';

interface ClientsTableToolbarProps {
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  filterBy: GetClientsFilter;
  onFilterByChange: (value: Partial<GetClientsFilter>) => void;
}

const ClientsTableToolbar: FC<ClientsTableToolbarProps> = ({
  searchValue,
  onSearchValueChange,
  filterBy,
  onFilterByChange,
}) => {
  const intl = useIntl();

  const filterOptions: Record<keyof GetClientsFilter, DefaultOptionType[]> = {
    is_company: [
      {
        label: <FormattedMessage defaultMessage="Osoba fizyczna" />,
        value: 0,
      },
      {
        label: <FormattedMessage defaultMessage="Firma" />,
        value: 1,
      },
    ],
  };

  return (
    <div className={styles.wrapper}>
      <Input
        value={searchValue}
        className={styles.item}
        onChange={(e) => onSearchValueChange(e.target.value)}
        placeholder={intl.formatMessage({
          defaultMessage: 'Szukaj',
        })}
        allowClear
      />
      <Select
        options={filterOptions.is_company}
        value={filterBy.is_company}
        onChange={(value) => onFilterByChange({ is_company: value })}
        placeholder={<FormattedMessage defaultMessage="Filtry" />}
        className={styles.item}
        allowClear
      />
    </div>
  );
};

export default ClientsTableToolbar;
