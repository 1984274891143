import type { IntlShape } from 'react-intl';

export enum FormOfSettlement {
  BILL = 0,
  INVOICE = 1,
  USE_ME = 2,
}

type FormOfSettlementOption = {
  value: FormOfSettlement;
  label: string;
};

export const formOfSettlements = (
  intl: IntlShape,
): FormOfSettlementOption[] => [
  {
    value: FormOfSettlement.BILL,
    label: intl.formatMessage({ defaultMessage: 'Rachunek' }),
  },
  {
    value: FormOfSettlement.INVOICE,
    label: intl.formatMessage({ defaultMessage: 'Faktura' }),
  },
  {
    value: FormOfSettlement.USE_ME,
    label: intl.formatMessage({ defaultMessage: 'Useme' }),
  },
];
