import { Form } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import { PlusIcon } from 'icons';
import styles from '../../styles.module.scss';
import { useInvoiceOverdueDetailsServices } from '../../useInvoiceOverdueDetailsServices';
import AddPaymentModal from './components/AddPaymentModal';
import { formItems } from './config';

const ObligationDataSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { invoiceOverdueData, isInvoiceOverdueFetching } =
    useInvoiceOverdueDetailsServices();

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <CardCommon
        title={
          <div className={styles.cardCommonTitleWrapper}>
            <FormattedMessage defaultMessage="Dane zobowiązania" />
            <Button
              icon={<PlusIcon />}
              loading={isInvoiceOverdueFetching}
              onClick={handleOpenModal}
            >
              <FormattedMessage defaultMessage="Dodaj wpłatę" />
            </Button>
          </div>
        }
        isLoading={isInvoiceOverdueFetching}
        lg={16}
      >
        <Form autoComplete="off" layout="vertical">
          <div className="gridForm">
            {invoiceOverdueData &&
              formItems({ data: invoiceOverdueData }).map(
                ({ key, ...props }) => <FormItem key={key} {...props} />,
              )}
          </div>
        </Form>
      </CardCommon>
      <AddPaymentModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default ObligationDataSection;
