/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import type {
  AddPreorderVariantLangPayload,
  AddPreorderVariantPayload,
  PreordersSliceState,
  UpdatePreorderVariantPayload,
  UpdatePreorderVariantServicePayload,
  PreorderVariantSlice,
  AddPreorderVariantServiceShipmentPayload,
  RemovePreorderVariantServiceShipment,
  CopyPreorderVariantPayload,
  SetPreorderSelectedPricePayload,
  UpdatePreorderVariantFilePayload,
  PreorderVariantFileSlice,
  PreorderVariantLangSlice,
  DeletePreorderVariantLangPayload,
  UpdatePreorderVariantProformaTypePayload,
  UpdatePreorderVariantDiscountsPayload,
  AddPreorderVariantLangToAllFilesPayload,
  DeletePreorderVariantFilePayload,
} from './type';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Dayjs } from 'dayjs';
import type {
  GetPreorderVariantsResponse,
  Preorder,
} from 'ducks/preorders/types';
import { createSlice } from '@reduxjs/toolkit';
import { groupBy, mapValues } from 'lodash';
import { v4 } from 'uuid';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import { FileTranslationType } from 'constants/fileTranslation';
import { ServiceAttributeNames, ServiceType } from 'constants/services';
import { calcTotalRealizationTimeForOneLang } from 'utilities/calcPreorderRealizationTime';
import {
  calcPreorderVariantLangPriceNetto,
  calcPreorderVariantPriceTotal,
} from 'utilities/calcPreorderTotals';
import { getNestedKeyValue } from 'utilities/object';
import {
  calcServiceGraphicPriceNetto,
  getInitialPreorderVariantFile,
  getInitialPreorderVariantServiceShipmentValues,
  getInitialPreorderVariantValues,
  getPreorderDiscount,
  getPreorderPages,
  getPricesFromPriceItem,
  getProformaType,
  validateProvidedLanguages,
} from './utils';

const initialState: PreordersSliceState = {
  isEditable: false,
  isSendMode: false,
  isDraftCalculation: false,
  preorder: null,
  selectedPriceId: null,
  selectedPrice: null,
  verifiedVies: false,
  vatRate: 0,
  variants: [],
  selectedVariantIds: [],
  emailTemplate: '',
  uploadedFiles: [],
  modals: {
    addLanguagePairModal: {
      isOpen: false,
      variantFileId: null,
    },
  },
  triggerSubmitForm: false,
  triggerScrollToPayments: false,
  scrollToVariant: {
    index: -1,
    id: '',
  },
  langsVariantColumnWidth: 'auto',
  triggerCurrentUserFilter: false,
};

const preordersSlice = createSlice({
  name: 'preorders',
  initialState,
  reducers: {
    setTriggerCurrentUserFilter: (
      state,
      {
        payload,
      }: PayloadAction<PreordersSliceState['triggerCurrentUserFilter']>,
    ) => {
      state.triggerCurrentUserFilter = payload;
    },
    setLangsVariantColumnWidth: (
      state,
      {
        payload,
      }: PayloadAction<PreordersSliceState['langsVariantColumnWidth']>,
    ) => {
      if (
        payload >= state.langsVariantColumnWidth ||
        state.langsVariantColumnWidth === 'auto'
      ) {
        state.langsVariantColumnWidth = payload;
      }
    },
    setTriggerScrollToPayments: (
      state,
      {
        payload,
      }: PayloadAction<PreordersSliceState['triggerScrollToPayments']>,
    ) => {
      state.triggerScrollToPayments = payload;
    },
    setTriggerFormSubmit: (
      state,
      { payload }: PayloadAction<PreordersSliceState['triggerSubmitForm']>,
    ) => {
      state.triggerSubmitForm = payload;
    },
    setTriggerScrollToVariant: (
      state,
      { payload }: PayloadAction<PreordersSliceState['scrollToVariant']>,
    ) => {
      state.scrollToVariant = payload;
    },
    setPreorderIsEditable: (
      state,
      { payload }: PayloadAction<PreordersSliceState['isEditable']>,
    ) => {
      state.isEditable = payload;
    },
    setPreorderIsSendMode: (
      state,
      { payload }: PayloadAction<PreordersSliceState['isSendMode']>,
    ) => {
      state.isSendMode = payload;
    },
    setPreorderIsDraftCalculation: (
      state,
      { payload }: PayloadAction<PreordersSliceState['isDraftCalculation']>,
    ) => {
      state.isDraftCalculation = payload;
    },
    setPreorderData: (state, { payload }: PayloadAction<Preorder>) => {
      state.preorder = payload;
    },
    setPreorderSelectedPriceId: (
      state,
      { payload }: PayloadAction<PreordersSliceState['selectedPriceId']>,
    ) => {
      state.selectedPriceId = payload;
    },
    setPreorderSelectedPrice: (
      state,
      {
        payload: { price, onError },
      }: PayloadAction<SetPreorderSelectedPricePayload>,
    ) => {
      const missingLangs: [string, string][] = [];

      if (state.variants.length) {
        const arrayOfVariantLangs = state.variants.flatMap(
          ({ preorderVariantFiles }) =>
            preorderVariantFiles.map(
              ({ preorderVariantLangs }) => preorderVariantLangs,
            ),
        );
        const priceItems = price.items.map((item) => item);

        if (priceItems.length) {
          arrayOfVariantLangs.forEach((variantLangs) =>
            variantLangs.forEach(({ langFrom, langTo }) => {
              const langPairExist = priceItems.find(
                (item) => item.langFrom === langFrom && item.langTo === langTo,
              );
              const langFromExist = priceItems.find(
                (item) =>
                  item.langFrom === langFrom &&
                  item.langTo === LanguageCode.POLAND,
              );

              if (!langPairExist && !langFromExist) {
                missingLangs.push([langFrom, langTo]);
              }
            }),
          );
        }
      }

      if (missingLangs.length) {
        onError?.({ missingLangs });

        state.selectedPrice = null;

        return;
      }

      state.selectedPrice = price;
    },
    setPreorderVerifiedVies: (
      state,
      { payload }: PayloadAction<PreordersSliceState['verifiedVies']>,
    ) => {
      state.verifiedVies = payload;
    },
    setPreorderVatRate: (
      state,
      { payload }: PayloadAction<PreordersSliceState['vatRate']>,
    ) => {
      state.vatRate = payload ?? 0;
    },
    setPreorderVariants: (
      state,
      { payload }: PayloadAction<GetPreorderVariantsResponse>,
    ) => {
      state.variants = payload;
    },
    copyPreorderVariant: (
      state,
      { payload }: PayloadAction<CopyPreorderVariantPayload>,
    ) => {
      const variant = state.variants.find(({ id }) => id === payload);

      if (!variant) return;

      const variantId = v4();

      const modifiedVariantValues: PreorderVariantSlice = {
        ...variant,
        id: variantId,
        preorderId: 0,
        isNew: true,
        preorderVariantFiles: variant.preorderVariantFiles.map(
          (preorderVariantFile) => {
            const variantFileId = v4();

            return {
              ...preorderVariantFile,
              id: variantFileId,
              preorderVariantId: variantId,
              isNew: true,
              preorderVariantLangs:
                preorderVariantFile.preorderVariantLangs.map(
                  (preorderVariantLang) => {
                    return {
                      ...preorderVariantLang,
                      preorderVariantFileId: variantFileId,
                      id: v4(),
                    };
                  },
                ),
            };
          },
        ),
        preorderVariantServices: variant.preorderVariantServices.map(
          (service) => {
            const serviceId = v4();

            return {
              ...service,
              id: serviceId,
              preorderVariantId: variantId,
              preorderVariantServiceValues:
                service.preorderVariantServiceValues.map((serviceValue) => ({
                  ...serviceValue,
                  id: null,
                  preorderVariantServiceId: serviceId,
                })),
            };
          },
        ),
      };

      state.variants.push(modifiedVariantValues);
    },
    updatePreorderVariant: (
      state,
      { payload: { id, field } }: PayloadAction<UpdatePreorderVariantPayload>,
    ) => {
      state.variants = state.variants.map((variant) =>
        variant.id === id
          ? {
              ...variant,
              ...field,
            }
          : variant,
      );
    },
    deletePreorderVariant: (
      state,
      { payload }: PayloadAction<string | number>,
    ) => {
      state.variants = state.variants.filter(({ id }) => id !== payload);
    },
    addPreorderVariantFile: (
      state,
      {
        payload: { file, preorderServices },
      }: PayloadAction<AddPreorderVariantPayload>,
    ) => {
      if (!state.variants.length) {
        state.variants.push(getInitialPreorderVariantValues(preorderServices));
      }

      state.variants.map((variant) =>
        variant.preorderVariantFiles.push(getInitialPreorderVariantFile(file)),
      );
    },
    deletePreorderVariantFile: (
      state,
      {
        payload: { variantId, variantFileId },
      }: PayloadAction<DeletePreorderVariantFilePayload>,
    ) => {
      const variant = state.variants.find(({ id }) => id === variantId);

      if (!variant) return;

      variant.preorderVariantFiles = variant.preorderVariantFiles.filter(
        ({ id }) => id !== variantFileId,
      );
    },
    updatePreorderVariantProformaType: (
      { preorder, variants },
      {
        payload: { variantId, proformaType },
      }: PayloadAction<UpdatePreorderVariantProformaTypePayload>,
    ) => {
      const variant = variants.find(({ id }) => id === variantId);

      if (!variant || !preorder) return;

      const { client, country } = preorder;
      const variantPriceTotal = calcPreorderVariantPriceTotal(variant);
      const isDebtor = !!client?.isDebtor;
      const proformaTypeDefault = getProformaType({
        variantPriceTotal,
        country,
        isDebtor,
      });

      variant.proformaType = proformaType ?? proformaTypeDefault;
    },
    updatePreorderVariantDiscounts: (
      { variants },
      {
        payload: { variantId },
      }: PayloadAction<UpdatePreorderVariantDiscountsPayload>,
    ) => {
      const variant = variants.find(({ id }) => id === variantId);

      if (!variant) return;

      const { repetition } = variant;

      const filteredVariantFiles = variant.preorderVariantFiles
        .filter(({ type }) => type !== FileTranslationType.CORRECTION)
        .map(({ preorderVariantLangs, ...variantFile }) => ({
          ...variantFile,
          preorderVariantLangs: preorderVariantLangs.filter(
            ({ langFrom, langTo }) =>
              langFrom === LanguageCode.POLAND ||
              langTo === LanguageCode.POLAND,
          ),
        }));

      const groupedByType = groupBy(filteredVariantFiles, 'type');

      const groupedByLang = mapValues(groupedByType, (variantFiles) => {
        const variantLangs = groupBy(
          variantFiles.flatMap(
            ({ preorderVariantLangs }) => preorderVariantLangs,
          ),
          ({ langFrom, langTo }) => {
            const langPair = [langFrom, langTo].sort().join('-');
            return langPair;
          },
        );

        return {
          variantFiles,
          variantLangs,
        };
      });

      Object.entries(groupedByLang).forEach(([type, group]) =>
        Object.values(group.variantLangs).forEach((variantLangs) => {
          const langsPriceNettoTotal = variantLangs.reduce(
            (
              acc,
              {
                pages,
                preorderVariantFileId,
                priceNetto,
                priceSpecialNetto,
                priceCorrectionNetto,
              },
            ) => {
              const files = group.variantFiles.find(
                ({ id }) => id === preorderVariantFileId,
              );

              if (!files) return acc;

              const calcedPriceNetto = calcPreorderVariantLangPriceNetto({
                type: files.type,
                pages,
                priceNetto,
                priceSpecialNetto,
                priceCorrectionNetto,
                isSpecial: files.isSpecial,
                isCorrection: files.isCorrection,
              });

              return acc + calcedPriceNetto;
            },
            0,
          );

          variantLangs.forEach((variantLang) => {
            const { langFrom, langTo } = variantLang;

            const discount = getPreorderDiscount({
              type: type as unknown as FileTranslationType,
              repetition,
              calcedPriceNetto: langsPriceNettoTotal,
              langFrom,
              langTo,
            });

            variantLang.discount = discount;
          });
        }),
      );
    },
    /**
     * Function that handles all possible changes of preorderVariant.
     * @example
     * [variantFileId, variantLangId, { characters: 50000 }]
     * @example
     * [variantFileId, { active: true }]
     */
    updatePreorderVariantFile: (
      state,
      {
        payload: { variantId, field },
      }: PayloadAction<UpdatePreorderVariantFilePayload>,
    ) => {
      const [variantFileId, value] = getNestedKeyValue(field);
      const [langIdOrVariantFileFields] = getNestedKeyValue(value);

      const variant = state.variants.find(({ id }) => id === variantId);

      if (!variant) return;

      const variantFile = variant.preorderVariantFiles.find(
        ({ id }) => id.toString() === variantFileId,
      );

      if (!variantFile) return;

      const [, nestedValue] =
        getNestedKeyValue<PreorderVariantLangSlice>(value);

      const variantLang = variantFile.preorderVariantLangs.find(
        ({ id }) => id.toString() === langIdOrVariantFileFields,
      );

      if (variantLang) {
        const [, variantLangField] = getNestedKeyValue(value);
        const [nestedKey, nestedValue] =
          getNestedKeyValue<PreorderVariantLangSlice>(
            variantLangField as Partial<PreorderVariantLangSlice>,
          );

        switch (nestedKey) {
          case 'characters': {
            const { type, isSpecial, isCorrection } = variantFile;
            const { langFrom, langTo } = variantLang;
            const characters = Number(nestedValue ?? 0);
            const pages = getPreorderPages({ type, characters });

            const timeRealization = calcTotalRealizationTimeForOneLang({
              numberOfPages: pages,
              langFrom,
              langTo,
              isSworn: type === FileTranslationType.SWORN,
              isSpecialized: isSpecial,
              isCorrection,
            });

            const prices = getPricesFromPriceItem({
              price: state.selectedPrice,
              type,
              langFrom,
              langTo,
            });

            if (prices) {
              const { priceNetto, priceCorrectionNetto, priceSpecialNetto } =
                prices;

              variantLang.priceNetto = priceNetto;
              variantLang.priceSpecialNetto = priceSpecialNetto;
              variantLang.priceCorrectionNetto = priceCorrectionNetto;
            }

            variantLang.characters = characters;
            variantLang.pages = pages;
            variantLang.timeRealization = timeRealization;

            break;
          }
          default: {
            // eslint-disable-next-line
            // @ts-ignore
            variantLang[nestedKey] = nestedValue;
          }
        }

        return;
      }

      switch (langIdOrVariantFileFields as keyof PreorderVariantFileSlice) {
        /**
         * During the type change, isCorrection should be changed to false
         * and priceNetto, priceCorrectionNetto and timeRealization
         * should be updated based on the new type.
         *
         * To set a new priceNetto, first need to find a price list
         * based on two identical languages or a combination with
         * PL (e.g. DE:PL, PL:DE) in the selected price list.
         */
        case 'type': {
          const newType = nestedValue as FileTranslationType;
          const newIsSpecial = false;
          const newIsCorrection = false;

          variantFile.preorderVariantLangs.forEach((variantLang) => {
            const { langFrom, langTo, characters } = variantLang;
            const pages = getPreorderPages({ type: newType, characters });

            const timeRealization = calcTotalRealizationTimeForOneLang({
              numberOfPages: pages,
              langFrom,
              langTo,
              isSworn: newType === FileTranslationType.SWORN,
              isSpecialized: newIsSpecial,
              isCorrection: newIsCorrection,
            });

            const prices = getPricesFromPriceItem({
              price: state.selectedPrice,
              type: newType,
              langFrom,
              langTo,
            });

            if (!prices) return;

            const { priceNetto, priceCorrectionNetto, priceSpecialNetto } =
              prices;

            variantLang.pages = pages;
            variantLang.priceNetto = priceNetto;
            variantLang.priceSpecialNetto = priceSpecialNetto;
            variantLang.priceCorrectionNetto = priceCorrectionNetto;
            variantLang.timeRealization = timeRealization;
          });

          variantFile.type = newType;
          variantFile.isSpecial = newIsSpecial;
          variantFile.isCorrection = newIsCorrection;
          break;
        }
        case 'isSpecial': {
          const newIsSpecial = !!nestedValue;

          variantFile.preorderVariantLangs.forEach((lang) => {
            const { type, isCorrection } = variantFile;
            const { pages, langFrom, langTo } = lang;

            const timeRealization = calcTotalRealizationTimeForOneLang({
              numberOfPages: pages,
              langFrom,
              langTo,
              isSworn: type === FileTranslationType.SWORN,
              isSpecialized: newIsSpecial,
              isCorrection,
            });

            const prices = getPricesFromPriceItem({
              price: state.selectedPrice,
              type,
              langFrom,
              langTo,
            });

            if (!prices) return;

            const { priceNetto, priceCorrectionNetto, priceSpecialNetto } =
              prices;

            lang.priceNetto = priceNetto;
            lang.priceSpecialNetto = priceSpecialNetto;
            lang.priceCorrectionNetto = priceCorrectionNetto;
            lang.timeRealization = timeRealization;
          });

          variantFile.isSpecial = newIsSpecial;
          break;
        }
        case 'isCorrection': {
          const newIsCorrection = !!nestedValue;

          variantFile.preorderVariantLangs.forEach((lang) => {
            const { type, isSpecial } = variantFile;
            const { pages, langFrom, langTo } = lang;

            const timeRealization = calcTotalRealizationTimeForOneLang({
              numberOfPages: pages,
              langFrom,
              langTo,
              isSworn: type === FileTranslationType.SWORN,
              isSpecialized: isSpecial,
              isCorrection: newIsCorrection,
            });

            const prices = getPricesFromPriceItem({
              price: state.selectedPrice,
              type,
              langFrom,
              langTo,
            });

            if (!prices) return;

            const { priceNetto, priceCorrectionNetto, priceSpecialNetto } =
              prices;

            lang.priceNetto = priceNetto;
            lang.priceSpecialNetto = priceSpecialNetto;
            lang.priceCorrectionNetto = priceCorrectionNetto;
            lang.timeRealization = timeRealization;
          });

          variantFile.isCorrection = newIsCorrection;
          break;
        }

        default: {
          // eslint-disable-next-line
          // @ts-ignore
          variantFile[langIdOrVariantFileFields] = nestedValue;
        }
      }
    },
    /**
     * Adds a new preorder variant lang to all files
     * for a specific variant based on the provided type.
     *
     * It calculates prices and time realizations for the new language
     * based on the selected price and existing variant data.
     *
     * It also updates existing preorder variant langs for
     * the same variant file with new price and time realization values.
     *
     * If the variant or variant file is not found
     * it triggers an error callback function.
     */
    addPreorderVariantLangToAllFiles: (
      state,
      {
        payload: {
          variantId,
          type,
          langFrom,
          langTo,
          isCorrection,
          isSpecial,
          onError,
        },
      }: PayloadAction<AddPreorderVariantLangToAllFilesPayload>,
    ) => {
      const variant = state.variants.find(({ id }) => id === variantId);

      if (!variant) return;

      const variantFilesByType = variant.preorderVariantFiles.filter(
        (variantFile) => variantFile.type === type,
      );

      if (!variantFilesByType.length) {
        onError?.({ isEmpty: true });

        return;
      }

      variantFilesByType.forEach((variantFile) => {
        const prices = getPricesFromPriceItem({
          price: state.selectedPrice,
          type,
          langFrom,
          langTo,
        });

        if (!prices) {
          onError?.({ isLackOfPrice: true });

          return;
        }

        const { priceNetto, priceSpecialNetto, priceCorrectionNetto } = prices;

        const initialValues: PreorderVariantLangSlice = {
          id: v4(),
          preorderVariantFileId: variantFile.id,
          langFrom,
          langTo,
          pages: 0,
          characters: 0,
          discount: 0,
          priceNetto,
          priceCorrectionNetto,
          priceSpecialNetto,
          timeRealization: 0,
        };

        // Update all preorder variant langs for the specific variant file
        variantFile.preorderVariantLangs.forEach((variantLang) => {
          const timeRealization = calcTotalRealizationTimeForOneLang({
            numberOfPages: variantLang.pages,
            langFrom,
            langTo,
            isSworn: type === FileTranslationType.SWORN,
            isSpecialized: isSpecial,
            isCorrection,
          });
          const prices = getPricesFromPriceItem({
            price: state.selectedPrice,
            type,
            langFrom: variantLang.langFrom,
            langTo: variantLang.langTo,
          });

          if (!prices) return;

          const { priceNetto, priceCorrectionNetto, priceSpecialNetto } =
            prices;

          variantLang.priceNetto = priceNetto;
          variantLang.priceSpecialNetto = priceSpecialNetto;
          variantLang.priceCorrectionNetto = priceCorrectionNetto;
          variantLang.timeRealization = timeRealization;
        });

        variantFile.isCorrection = isCorrection;
        variantFile.isSpecial = isSpecial;
        variantFile.preorderVariantLangs.push(initialValues);
      });
    },
    addPreorderVariantLang: (
      state,
      {
        payload: {
          variantId,
          variantFileId,
          langFrom,
          langTo: langsTo,
          onError,
        },
      }: PayloadAction<AddPreorderVariantLangPayload>,
    ) => {
      /**
       * We are checking whether the given lang pair exists in the selectedPrice
       * If it does, we can add it right away.
       * If not, we need to proceed to the next stage, namely
       * checkingwhether one of the provided languages in combination with PL
       * (e.g., DE:PL, PL:DE) has its counterpart in the selected price list.
       */

      const variant = state.variants.find(({ id }) => id === variantId);

      if (!variant) return;

      const variantFile = variant.preorderVariantFiles.find(
        ({ id }) => id === variantFileId,
      );

      if (!variantFile) return;

      langsTo.forEach((langTo) => {
        const validateAndAddVariantLang = (
          priceNetto: number,
          priceCorrectionNetto: number,
          priceSpecialNetto: number,
        ) => {
          const initialValues: PreorderVariantLangSlice = {
            id: v4(),
            preorderVariantFileId: variantFile.id,
            langFrom,
            langTo,
            pages: 0,
            characters: 0,
            discount: 0,
            priceNetto,
            priceCorrectionNetto,
            priceSpecialNetto,
            timeRealization: 0,
          };

          const { error } = validateProvidedLanguages({
            preorderVariantLangs: variantFile.preorderVariantLangs,
            langFrom,
            langTo,
            onError,
          });

          if (error) return;

          variantFile.preorderVariantLangs.push(initialValues);
        };

        if (!state.selectedPrice) return;

        const prices = getPricesFromPriceItem({
          price: state.selectedPrice,
          type: variantFile.type,
          langFrom,
          langTo,
        });

        if (!prices) {
          onError?.({ isDuplicate: false });
          return;
        }

        const { priceNetto, priceCorrectionNetto, priceSpecialNetto } = prices;

        validateAndAddVariantLang(
          priceNetto,
          priceCorrectionNetto,
          priceSpecialNetto,
        );
      });
    },
    deletePreorderVariantLang: (
      state,
      {
        payload: { variantFileId, variantLangId },
      }: PayloadAction<DeletePreorderVariantLangPayload>,
    ) => {
      const variant = state.variants.find(({ preorderVariantFiles }) =>
        preorderVariantFiles.find(({ id }) => id === variantFileId),
      );

      if (!variant) return;

      const variantFile = variant.preorderVariantFiles.find(
        ({ id }) => id === variantFileId,
      );

      if (!variantFile) return;

      variantFile.preorderVariantLangs =
        variantFile.preorderVariantLangs.filter(
          ({ id }) => id !== variantLangId,
        );
    },
    /**
     * Example of field
     * [serviceId, { isActive: true }]
     * or
     * [serviceId, { attributeName,AttributeNames.SIMPLE_PAGES_NUMBER: 2 }]
     */
    updatePreorderVariantService: (
      state,
      {
        payload: { variantId, field },
      }: PayloadAction<UpdatePreorderVariantServicePayload>,
    ) => {
      const [serviceId, value] = getNestedKeyValue(field);
      const [nestedKey, nestedValue] =
        getNestedKeyValue<Record<string, string | number | boolean>>(value);

      const variant = state.variants.find(({ id }) => id === variantId);

      if (!variant) return;

      const variantService = variant.preorderVariantServices.find(
        ({ id }) => id.toString() === serviceId,
      );

      if (!variantService) return;

      if (nestedKey.includes(',')) {
        const nestedKeys = nestedKey.split(',');
        const variantServiceValue =
          variantService.preorderVariantServiceValues.find(
            ({ attributeName }) => attributeName === nestedKeys[1],
          );

        /**
         * On SIMPLE_PAGES_NUMBER update:
         * - priceNetto (based on newSimplePages and currentDifficultPages)
         * - attributeValue
         *
         * On DIFFICULT_PAGES_NUMBER update:
         * - priceNetto (based on newDifficultPages and currentSimplePages)
         * - attributeValue
         *
         * On ADDITIONAL_TIME update:
         * - attributeValue (need to put formatted date)
         *
         *  On PACKAGES_NUMBER update:
         * - priceNetto (shipment price * packages number)
         * - attributeValue
         */
        if (variantServiceValue) {
          const { preorderVariantServiceValues } = variantService;

          if (nestedKey.includes(ServiceAttributeNames.SIMPLE_PAGES_NUMBER)) {
            const priceNetto = calcServiceGraphicPriceNetto({
              serviceValues: preorderVariantServiceValues,
              newSimplePages: Number(nestedValue),
            });

            if (priceNetto) {
              variantService.priceNetto = priceNetto;
            }

            variantServiceValue.attributeValue =
              nestedValue === null ? '0' : String(nestedValue);

            return;
          }

          if (
            nestedKey.includes(ServiceAttributeNames.DIFFICULT_PAGES_NUMBER)
          ) {
            const priceNetto = calcServiceGraphicPriceNetto({
              serviceValues: preorderVariantServiceValues,
              newDifficultPages: Number(nestedValue),
            });

            if (priceNetto) {
              variantService.priceNetto = priceNetto;
            }

            variantServiceValue.attributeValue =
              nestedValue === null ? '0' : String(nestedValue);

            return;
          }

          if (nestedKey.includes(ServiceAttributeNames.ADDITIONAL_TIME)) {
            const formattedTime = nestedValue
              ? (nestedValue as unknown as Dayjs).format('HH:mm')
              : '';

            variantServiceValue.attributeValue = formattedTime;

            return;
          }

          variantServiceValue.attributeValue =
            nestedValue === null ? '' : String(nestedValue);
        }

        return;
      }

      // eslint-disable-next-line
      // @ts-ignore
      variantService[nestedKey] = nestedValue;
    },
    deletePreorderVariantService: (
      state,
      {
        payload: { variantId, id },
      }: PayloadAction<RemovePreorderVariantServiceShipment>,
    ) => {
      const variant = state.variants.find(({ id }) => id === variantId);

      if (!variant) return;

      variant.preorderVariantServices = variant.preorderVariantServices.filter(
        (preorderVariantService) => preorderVariantService.id !== id,
      );
    },
    addPreorderVariantServiceShipment: (
      state,
      {
        payload: { variantId, preorderServices },
      }: PayloadAction<AddPreorderVariantServiceShipmentPayload>,
    ) => {
      const variant = state.variants.find(({ id }) => id === variantId);

      if (!variant) return;

      const shipmentAttributes = preorderServices.find(
        ({ type }) => type === ServiceType.SHIPMENT,
      )?.attributes;

      if (shipmentAttributes) {
        variant.preorderVariantServices.push(
          getInitialPreorderVariantServiceShipmentValues(shipmentAttributes),
        );
      }
    },
    setPreorderEmailTemplate: (
      state,
      { payload }: PayloadAction<PreordersSliceState['emailTemplate']>,
    ) => {
      state.emailTemplate = payload;
    },
    setPreorderSelectedVariantIds: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      const isAlreadyAdded = state.selectedVariantIds.some(
        (id) => id === payload,
      );

      if (isAlreadyAdded) {
        state.selectedVariantIds = state.selectedVariantIds.filter(
          (id) => id !== payload,
        );

        return;
      }

      state.selectedVariantIds.push(payload);
    },
    togglePreorderUploadedFile: (
      state,
      { payload }: PayloadAction<PreordersSliceState['uploadedFiles'][number]>,
    ) => {
      const isAlreadyAdded = state.uploadedFiles.some(
        ({ uid }) => uid === payload.uid,
      );

      if (isAlreadyAdded) {
        state.uploadedFiles = state.uploadedFiles.filter(
          ({ uid }) => uid !== payload.uid,
        );

        return;
      }

      state.uploadedFiles.push(payload);
    },
    setPreorderAddLanguagePairModal: (
      state,
      {
        payload,
      }: PayloadAction<PreordersSliceState['modals']['addLanguagePairModal']>,
    ) => {
      state.modals.addLanguagePairModal = payload;
    },
    resetPreordersState: () => initialState,
  },
});

export const {
  setTriggerCurrentUserFilter,
  setLangsVariantColumnWidth,
  setTriggerFormSubmit,
  setTriggerScrollToVariant,
  setTriggerScrollToPayments,
  setPreorderIsEditable,
  setPreorderIsSendMode,
  setPreorderIsDraftCalculation,
  setPreorderData,
  setPreorderSelectedPriceId,
  setPreorderSelectedPrice,
  setPreorderVerifiedVies,
  setPreorderVatRate,
  setPreorderVariants,
  copyPreorderVariant,
  updatePreorderVariant,
  deletePreorderVariant,
  addPreorderVariantFile,
  deletePreorderVariantFile,
  updatePreorderVariantProformaType,
  updatePreorderVariantDiscounts,
  updatePreorderVariantFile,
  addPreorderVariantLangToAllFiles,
  addPreorderVariantLang,
  deletePreorderVariantLang,
  updatePreorderVariantService,
  deletePreorderVariantService,
  addPreorderVariantServiceShipment,
  setPreorderEmailTemplate,
  setPreorderSelectedVariantIds,
  togglePreorderUploadedFile,
  setPreorderAddLanguagePairModal,
  resetPreordersState,
} = preordersSlice.actions;

export default preordersSlice.reducer;
