import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { InvoiceFinal } from 'ducks/invoices/types';
import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { DayjsFormat } from 'constants/dayjsFormats';
import { invoiceStatusesObject } from 'constants/invoice';
import { NO_DATA } from 'constants/strings';
import { InvoiceStatus } from 'ducks/invoices/types';
import ActionsCell from './components/ActionsCell';

export type InvoiceFinalRow = TableRow & InvoiceFinal;

type Props = {
  intl: IntlShape;
};

export const columns = ({ intl }: Props): ColumnsType<InvoiceFinalRow> => [
  {
    key: 'number',
    dataIndex: 'number',
    title: <FormattedMessage defaultMessage="Numer faktury" />,
    width: 60,
  },
  {
    key: 'dateSent',
    dataIndex: 'dateSent',
    title: <FormattedMessage defaultMessage="Data wystawienia faktury" />,
    render: (_, { dateSent }) =>
      dateSent ? dayjs(dateSent).format(DayjsFormat.DATE) : NO_DATA,
  },
  {
    key: 'clientName',
    dataIndex: 'clientName',
    title: <FormattedMessage defaultMessage="Nazwa klienta" />,
    render: (_, { client }) => <span>{client.name ?? NO_DATA}</span>,
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: <FormattedMessage defaultMessage="Status" />,
    render: (_, { status }) =>
      invoiceStatusesObject(intl)[
        status in InvoiceStatus ? status : InvoiceStatus.NOT_PAID
      ].label,
  },
  {
    key: 'priceNetto',
    dataIndex: 'priceNetto',
    title: <FormattedMessage defaultMessage="Wartość zamówienia" />,
    render: (_, { priceNetto, priceBrutto }) => (
      <span>
        <CurrencyFormatter value={priceNetto} /> (
        <CurrencyFormatter value={priceBrutto} />)
      </span>
    ),
  },
  {
    key: 'payed',
    dataIndex: 'payed',
    title: <FormattedMessage defaultMessage="Zapłacono" />,
    render: (_, { payed }) => <CurrencyFormatter value={payed} />,
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    title: <FormattedMessage defaultMessage="Akcje" />,
    width: 100,
    align: 'center',
    render: (_, { id, hasInvoiceFinal }) => (
      <ActionsCell id={id} hasInvoiceFinal={hasInvoiceFinal} />
    ),
  },
];
