import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { OrderTranslationFilesState } from 'ducks/view/orderTranslationFiles';
import { FormattedMessage } from 'react-intl';
import ActionsColumn from './components/ActionsColumn';
import LangsColumn from './components/LangsColumn';
import SourceFilesColumn from './components/SourceFilesColumn';
import TranslationFilesColumn from './components/TranslationFilesColumn';
import TranslatorColumn from './components/TranslatorColumn';
import styles from './styles.module.scss';

export type OrderTranslationFileRow = TableRow &
  OrderTranslationFilesState['orderFiles'][number];

export const columns = (): ColumnsType<OrderTranslationFileRow> => [
  {
    key: 'sourceFile',
    dataIndex: 'sourceFile',
    title: <FormattedMessage defaultMessage="Plik źródłowy" />,
    width: 200,
    render: (_, { id, fileId, orderFileLangs }) => (
      <SourceFilesColumn
        key={id}
        fileId={fileId}
        orderFileLangs={orderFileLangs}
      />
    ),
  },
  {
    key: 'translationFile',
    dataIndex: 'translationFile',
    title: <FormattedMessage defaultMessage="Plik docelowy" />,
    render: (_, { orderFileLangs }) => (
      <TranslationFilesColumn orderFileLangs={orderFileLangs} />
    ),
  },
  {
    key: 'translator',
    dataIndex: 'translator',
    title: <FormattedMessage defaultMessage="Tłumacz" />,
    width: 200,
    render: (_, { id: orderFileId, orderFileLangs }) => (
      <div className={styles.multilineCell}>
        {orderFileLangs.map((orderFileLang) =>
          orderFileLang.orderTranslationFiles.map(({ id, translatorId }) => (
            <TranslatorColumn
              key={id}
              orderFileId={orderFileId}
              orderTranslationFileId={id}
              translatorId={translatorId}
              orderFileLang={orderFileLang}
            />
          )),
        )}
      </div>
    ),
  },
  {
    key: 'langs',
    dataIndex: 'langs',
    title: <FormattedMessage defaultMessage="Język" />,
    width: 400,
    render: (_, { id, orderFileLangs }) => (
      <LangsColumn orderFileId={id} orderFileLangs={orderFileLangs} />
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Akcje" />,
    width: 100,
    align: 'center',
    render: (_, { id, orderFileLangs }) => (
      <ActionsColumn orderFileId={id} orderFileLangs={orderFileLangs} />
    ),
  },
];
