import { Collapse, Space } from 'antd';
import Empty from 'components/ui/atoms/Empty';
import Loader from 'components/ui/atoms/Loader';
import PreorderVariantTable from 'components/ui/organisms/Tables/PreorderVariantTable';
import { useOrderDetailsServices } from '../../useOrderDetailsServices';
import PanelHeader from './components/PanelHeader';
import styles from './styles.module.scss';

const PreordersTab = () => {
  const { orderVariantData, isOrderVariantLoading, isOrderVariantEmpty } =
    useOrderDetailsServices();

  if (isOrderVariantEmpty) return <Empty />;

  return (
    <Space
      data-testid="preordersTab"
      className={styles.wrapper}
      direction="vertical"
      size="large"
    >
      <Loader visible={isOrderVariantLoading}>
        {orderVariantData && (
          <Collapse
            className={styles.collapse}
            defaultActiveKey={orderVariantData.id}
            bordered={false}
          >
            <Collapse.Panel
              key={orderVariantData.id}
              header={<PanelHeader variant={orderVariantData} />}
            >
              <PreorderVariantTable variant={orderVariantData} />
            </Collapse.Panel>
          </Collapse>
        )}
      </Loader>
    </Space>
  );
};

export default PreordersTab;
