import { Row, Space } from 'antd';
import { useEffect } from 'react';
import { DEFAULT_VAT_NUMBER } from 'constants/formInputLengths';
import { useAppDispatch } from 'ducks/store';
import {
  resetPreordersState,
  setPreorderIsEditable,
  setPreorderIsDraftCalculation,
  setPreorderVatRate,
} from 'ducks/view/preorders';
import BasicDataVariantSection from './BasicDataVariantSection';
import PricesSection from './PricesSection';
import VariantSection from './VariantSection';

const PreorderCalculation = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPreorderIsEditable(true));
    dispatch(setPreorderIsDraftCalculation(true));
    dispatch(setPreorderVatRate(DEFAULT_VAT_NUMBER));

    return () => {
      dispatch(resetPreordersState());
    };
  }, [dispatch]);

  return (
    <Space
      className="layout-content width-100 mb-24"
      size="large"
      direction="vertical"
    >
      <Row gutter={[24, 24]} align="top">
        <BasicDataVariantSection />
        <PricesSection />
      </Row>
      <VariantSection />
    </Space>
  );
};

export default PreorderCalculation;
