import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { AccountingNotes } from 'ducks/invoices/types';
import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { DayjsFormat } from 'constants/dayjsFormats';
import {
  getAccountingNoteShipmentTypes,
  getAccountingNoteStatuses,
} from 'constants/invoice';
import { NO_DATA } from 'constants/strings';

type Props = {
  intl: IntlShape;
  accountingNotes: AccountingNotes;
};

export const formItems = ({
  intl,
  accountingNotes: { number, dateSent, shipmentType, status },
}: Props): FormItemRecord<AccountingNotes>[] => {
  const dateSentValue = dateSent
    ? dayjs(dateSent).format(DayjsFormat.DATE)
    : NO_DATA;
  const shipmentTypeValue =
    getAccountingNoteShipmentTypes(intl, shipmentType)?.label ?? NO_DATA;
  const statusValue = getAccountingNoteStatuses(intl, status)?.label ?? NO_DATA;

  return [
    {
      key: 'number',
      label: <FormattedMessage defaultMessage="Numer:" />,
      component: <strong data-testid="number">{number ?? NO_DATA}</strong>,
    },
    {
      key: 'dateSent',
      label: <FormattedMessage defaultMessage="Data wysyłki:" />,
      component: <strong data-testid="dateSent">{dateSentValue}</strong>,
    },
    {
      key: 'shipmentType',
      label: <FormattedMessage defaultMessage="Typ wysyłki:" />,
      component: (
        <strong data-testid="shipmentType">{shipmentTypeValue}</strong>
      ),
    },
    {
      key: 'status',
      label: <FormattedMessage defaultMessage="Status:" />,
      component: <strong data-testid="status">{statusValue}</strong>,
    },
  ];
};
