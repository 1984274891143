import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { EditOrderRequest } from 'ducks/orders/types';
import type { FC } from 'react';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { useEditOrderMutation } from 'ducks/orders/service';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setGlobalIsLoading } from 'ducks/view/global';
import { Routes } from 'views/Orders/Routing';
import { useOrderAssignServices } from '../../useOrderAssignServices';
import styles from './styles.module.scss';

type Props = {
  notify: NotificationInstance;
};

const ActionsSection: FC<Props> = ({ notify }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { orderFiles, orderServices, isImportant } = useAppSelector(
    ({ orders }) => ({
      orderFiles: orders.orderFiles,
      orderServices: orders.orderServices,
      isImportant: orders.isImportant,
    }),
    shallowEqual,
  );
  const { orderData, isOrderLoading } = useOrderAssignServices();
  const [editOrder, { isLoading: isEditLoading }] = useEditOrderMutation();
  const isLoading = isOrderLoading || isEditLoading;

  const handleSave = async (send = false) => {
    if (!orderData) return;

    try {
      dispatch(setGlobalIsLoading(true));

      const orderFilesBody: EditOrderRequest['orderFiles'] = orderFiles.map(
        ({ specializations, orderFileLangs, ...orderFile }) => ({
          id: orderFile.id,
          specializations,
          orderFileLangs: orderFileLangs.map(
            ({
              id,
              catId,
              translatorId,
              langFrom,
              langTo,
              isPostedit,
              isAccepted,
              dateRealization,
              price,
              translatorPriceNetto,
            }) => ({
              id: typeof id === 'string' ? null : id,
              catId,
              translatorId,
              langFrom,
              langTo,
              isPostedit,
              isAccepted,
              dateRealization,
              price,
              translatorPriceNetto,
            }),
          ),
        }),
      );

      await editOrder({
        id: orderData.id,
        isImportant,
        orderFiles: orderFilesBody,
        orderServices,
      }).unwrap();

      const orderId = orderData.id.toString();

      if (send) {
        navigate(Routes.ORDER_SEND_EMAILS.replace(':id', orderId));

        return;
      }

      navigate(Routes.ORDER_DETAILS.replace(':id', orderId));
    } catch (err) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edycji." />
        ),
      });
    } finally {
      dispatch(setGlobalIsLoading(false));
    }
  };

  return (
    <Space className={styles.space} data-testid="actionsSection">
      <Button onClick={() => handleSave()} type="primary" loading={isLoading}>
        <FormattedMessage defaultMessage="Zapisz" />
      </Button>
      <Button
        onClick={() => handleSave(true)}
        type="primary"
        loading={isLoading}
      >
        <FormattedMessage defaultMessage="Zapisz i wyślij" />
      </Button>
    </Space>
  );
};

export default ActionsSection;
