import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';
import type { Order } from 'ducks/orders/types';
import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { DayjsFormat } from 'constants/dayjsFormats';
import { NO_DATA } from 'constants/strings';
import { generateWorkingDaysLabel } from 'utilities/workingDaysLabel';

type Props = {
  intl: IntlShape;
  data: Order;
};

export const headerCols = ({ data }: Props): HeaderCardCol[] => {
  const { createdAt, dateRealization, preorder, dateSent, timeRealization } =
    data;
  const formattedCreatedAt = preorder?.createdAt
    ? dayjs(preorder.createdAt).format(DayjsFormat.DATE_AND_TIME)
    : NO_DATA;

  const formattedPreorderDateSent = preorder?.dateSent
    ? dayjs(preorder.dateSent).format(DayjsFormat.DATE_AND_TIME)
    : NO_DATA;

  const formattedPreorderCreatedAt = dayjs(createdAt).format(
    DayjsFormat.DATE_AND_TIME,
  );
  const formattedTimeRealization = generateWorkingDaysLabel({
    timeRealization,
  });

  const formattedDateRealization = dateRealization
    ? dayjs(dateRealization).format(DayjsFormat.DATE)
    : NO_DATA;

  const formattedDateSent = dateSent
    ? dayjs(dateSent).format(DayjsFormat.DATE_AND_TIME)
    : NO_DATA;

  return [
    {
      key: 'dates1',
      firstRow: (
        <span>
          <b>
            <FormattedMessage defaultMessage="Data utworzenia:" />
          </b>{' '}
          {formattedCreatedAt}
        </span>
      ),
      secondRow: (
        <span>
          <b>
            <FormattedMessage defaultMessage="Data wysłania:" />
          </b>{' '}
          {formattedPreorderDateSent}
        </span>
      ),
    },
    {
      key: 'dates2',
      firstRow: (
        <span>
          <b>
            <FormattedMessage defaultMessage="Data przyjęcia do realizacji:" />
          </b>{' '}
          {formattedPreorderCreatedAt}
        </span>
      ),
      secondRow: (
        <span>
          <b>
            <FormattedMessage defaultMessage="Termin realizacji:" />
          </b>{' '}
          {formattedTimeRealization} | {formattedDateRealization}
        </span>
      ),
    },
    {
      key: 'dates3',
      firstRow: (
        <span>
          <b>
            <FormattedMessage defaultMessage="Data realizacji:" />
          </b>{' '}
          {formattedDateSent}
        </span>
      ),
    },
  ];
};
