/* eslint-disable react/jsx-props-no-spreading */
import type { AddTranslationModalValues } from './config';
import type { HandleAddProps } from '../../config';
import type { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { formItems, initialValues } from './config';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (props: HandleAddProps) => void;
}

const { useForm } = Form;

const AddTranslationModal: FC<Props> = ({ isOpen, onClose, onAdd }) => {
  const [form] = useForm<AddTranslationModalValues>();

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleOnSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onAdd([values]);
        handleClose();
      })
      .catch((err) => err);
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Dodaj tłumaczenie" />}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={handleClose}
      okText={<FormattedMessage defaultMessage="Dodaj" />}
      disableFormStyles
      disableModalStyles
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        {formItems.map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default AddTranslationModal;
