import type { IntlShape } from 'react-intl';

export enum ServiceAttributeNames {
  SIMPLE_PAGES_NUMBER = 'simple_pages_number',
  DIFFICULT_PAGES_NUMBER = 'difficult_pages_number',
  ADDITIONAL_TYPE = 'additional_type',
  ADDITIONAL_TIME = 'additional_time',
  TYPE = 'type',
  PACKAGES_NUMBER = 'packages_number',
}

export enum ServiceType {
  GRAPHIC_SERVICE = 1,
  ADDITIONAL = 2,
  SHIPMENT = 3,
  PREPARATORY = 4,
}

type ServicerOption = {
  value: ServiceType;
  label: string;
};

type GraphicTimeRealizationRange = {
  [range: string]: number;
};

export const services = (intl: IntlShape): ServicerOption[] => [
  {
    value: ServiceType.GRAPHIC_SERVICE,
    label: intl.formatMessage({ defaultMessage: 'Usługa graficzna' }),
  },
  {
    value: ServiceType.ADDITIONAL,
    label: intl.formatMessage({ defaultMessage: 'Tłumaczenie ekspresowe' }),
  },
  {
    value: ServiceType.SHIPMENT,
    label: intl.formatMessage({ defaultMessage: 'Wysyłka' }),
  },
  {
    value: ServiceType.PREPARATORY,
    label: intl.formatMessage({ defaultMessage: 'Prace przygotowawcze' }),
  },
];

export const servicesObject = (intl: IntlShape) =>
  services(intl).reduce((acc, { value, label }) => {
    acc[value] = {
      value,
      label,
    };

    return acc;
  }, {} as Record<ServiceType, ServicerOption>);

export const graphicTimeRealizationRange: GraphicTimeRealizationRange = {
  '0-9': 3,
  '9-30': 6,
  '30': 9,
};
