import type { EditTranslatorOrderRateModalValues } from './config';
import type { TranslatorsOrders } from 'ducks/translators/types';
import type { FC } from 'react';
import { Form, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { useEditTranslatorOrderRateMutation } from 'ducks/translators/service';
import { mutationFunctionWithForm } from 'utilities/mutationFunction';
import { formItems, initialValues } from './config';
import EditTranslatorOrderRateModalSubtitle from './EditTranslatorOrderRateModalSubtitle';

const { useForm } = Form;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  translatorOrder: TranslatorsOrders;
};

const EditTranslatorOrderRateModal: FC<Props> = ({
  isOpen,
  onClose,
  translatorOrder: {
    orderId,
    orderNumber,
    orderFileLangFrom,
    orderFileLangTo,
    orderFile,
    orderTranslationFiles,
    orderRatesAvg,
    orderRatesContactAvg,
    orderRatesContentAvg,
    orderRatesPriceAvg,
    orderRatesPunctualityAvg,
    orderRatesTechnicalAvg,
  },
}) => {
  const { id = '' } = useParams();
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm<EditTranslatorOrderRateModalValues>();
  const [editTranslatorOrderRate, { isLoading }] =
    useEditTranslatorOrderRateMutation();
  const isEditMode = orderRatesAvg !== null;
  const modifiedInitialValues: EditTranslatorOrderRateModalValues = isEditMode
    ? {
        punctuality: orderRatesPunctualityAvg ?? 1,
        price: orderRatesPriceAvg ?? 1,
        contact: orderRatesContactAvg ?? 1,
        technical: orderRatesTechnicalAvg ?? 1,
        content: orderRatesContentAvg ?? 1,
      }
    : initialValues;

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleOnSubmit = async () => {
    if (isLoading) return;

    mutationFunctionWithForm<void, EditTranslatorOrderRateModalValues>({
      form,
      mutationFn: async (values) => {
        const translatorId = Number(id);

        await editTranslatorOrderRate({
          ...values,
          translatorId,
          orderId,
        }).unwrap();
      },
      onSuccess: () => {
        handleClose();
      },
      onError: () => {
        notify.error({
          message: (
            <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania oceny." />
          ),
        });
      },
    });
  };

  return (
    <Modal
      title={
        <FormattedMessage
          defaultMessage="Ocena do zlecenia {number}"
          values={{ number: orderNumber }}
        />
      }
      subtitle={
        <EditTranslatorOrderRateModalSubtitle
          orderFile={orderFile}
          orderFileLangFrom={orderFileLangFrom}
          orderFileLangTo={orderFileLangTo}
          orderTranslationFiles={orderTranslationFiles}
        />
      }
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={handleClose}
      isLoading={isLoading}
      disableFormStyles
      disableModalStyles
      destroyOnClose
    >
      {contextHolder}
      <Form form={form} initialValues={modifiedInitialValues} layout="vertical">
        {formItems.map(({ key, ...props }) => (
          <FormItem<EditTranslatorOrderRateModalValues> key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default EditTranslatorOrderRateModal;
