import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { Client, GetClientsFilter } from 'ducks/clients/types';
import { FormattedMessage } from 'react-intl';
import theme from 'components/layout/Main/styles/values.scss';
import CircleCheckbox from 'components/ui/atoms/CircleCheckbox/CircleCheckbox';
import CopyToClipboardWithText from 'components/ui/atoms/CopyToClipboard';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import ClientAvatar from 'components/ui/molecules/ClientAvatar';
import { Routes } from 'views/Clients/Routing';

export type ClientRow = TableRow &
  Pick<
    Client,
    | 'isCompany'
    | 'id'
    | 'name'
    | 'address'
    | 'nip'
    | 'preordersCount'
    | 'ordersCount'
    | 'discount'
    | 'balance'
    | 'mt'
    | 'isDebtor'
  > & {
    email: string;
  };

export const initialFilters: GetClientsFilter = {
  is_company: undefined,
};

export const columns = (): ColumnsType<ClientRow> => [
  {
    title: <FormattedMessage defaultMessage="Klient" />,
    key: 'name',
    dataIndex: 'name',
    sorter: true,
    render: (_, { id, isCompany, name }) => {
      return (
        <ClientAvatar
          link={`${Routes.CLIENT_DETAILS.replace(':clientId', id.toString())}`}
          isCompany={isCompany}
          name={name}
          preferredName={name}
        />
      );
    },
  },
  {
    title: <FormattedMessage defaultMessage="Email" />,
    key: 'email',
    dataIndex: 'email',
    render: (email) => (
      <CopyToClipboardWithText type="secondary" text={email} />
    ),
  },
  {
    title: <FormattedMessage defaultMessage="NIP" />,
    key: 'nip',
    dataIndex: 'nip',
    render: (_, { isCompany, nip }) =>
      isCompany ? nip : <FormattedMessage defaultMessage="brak" />,
  },
  {
    title: <FormattedMessage defaultMessage="Wyceny" />,
    key: 'preordersCount',
    dataIndex: 'preordersCount',
    align: 'center',
    sorter: true,
  },
  {
    title: <FormattedMessage defaultMessage="Zamówienia" />,
    key: 'ordersCount',
    dataIndex: 'ordersCount',
    align: 'center',
    sorter: true,
  },
  {
    title: <FormattedMessage defaultMessage="%" />,
    key: 'efficiency',
    dataIndex: 'efficiency',
    align: 'center',
    sorter: true,
    render: (_, { ordersCount, preordersCount }) => {
      const percentage = Math.floor((ordersCount / preordersCount) * 100);
      const percentageLabel =
        Number.isNaN(percentage) || percentage === Infinity || !percentage
          ? '-'
          : `${percentage}%`;
      return percentageLabel;
    },
  },
  {
    title: <FormattedMessage defaultMessage="Reklamacje" />,
    key: 'complaintsCount',
    dataIndex: 'complaintsCount',
    align: 'center',
    sorter: true,
  },
  {
    title: <FormattedMessage defaultMessage="Rabat" />,
    key: 'discount',
    dataIndex: 'discount',
    align: 'center',
    sorter: true,
    render: (_, { discount }) => `${discount}%`,
  },
  {
    title: <FormattedMessage defaultMessage="Dłużnik" />,
    key: 'isDebtor',
    dataIndex: 'isDebtor',
    align: 'center',
    width: 60,
    render: (_, { isDebtor }) => (
      <CircleCheckbox value={isDebtor} colorTrue={theme.colorTorchRed} />
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Saldo" />,
    key: 'balance',
    dataIndex: 'balance',
    align: 'center',
    sorter: true,
    render: (_, { balance }) => <CurrencyFormatter value={balance} />,
  },
  {
    title: <FormattedMessage defaultMessage="TM" />,
    key: 'mt',
    dataIndex: 'mt',
    align: 'center',
    width: 60,
    render: (_, { mt }) => (
      <CircleCheckbox value={mt} colorTrue={theme.colorViridian} />
    ),
  },
];
