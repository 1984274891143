import type { CurrentUser } from 'ducks/users/types';
import { UserRole } from 'ducks/users/types';

export const verifyAdmin = (user: CurrentUser) => user?.role === UserRole.ADMIN;

export const verifyEmployee = (user: CurrentUser) =>
  user?.role === UserRole.EMPLOYEE;

/**
 * Check if the provided activeEditorId and currentUser id are
 * valid and not null.
 * @returns
 * true - if has not access
 * @returns
 * false - if has access
 */
export const checkIfCurrentlyEdited = (
  activeEditorId: number | null | undefined,
  currentUser: CurrentUser | undefined,
) => {
  if (!currentUser) return true;

  if (currentUser.role === UserRole.ADMIN) return false;

  if (activeEditorId === null) return false;

  return activeEditorId !== currentUser.id;
};
