/* eslint-disable react/jsx-props-no-spreading */
import { BsFillLockFill } from 'react-icons/bs';

type Props = {
  size?: number;
};

const LockIcon: React.FC<Props> = ({ size, ...props }) => (
  <BsFillLockFill size={size} {...props} />
);

export default LockIcon;
