import type { DefaultOptionType } from 'antd/es/select';
import type { GetPreordersFilter } from 'ducks/preorders/types';
import type { RangeDateChange } from 'utilities/date';
import { DownloadOutlined } from '@ant-design/icons';
import { Input, Select, Button, DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import { useEffect, type FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSanctum } from 'react-sanctum';
import { preorderStatuses } from 'constants/preorder';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { useGetUsersQuery } from 'ducks/users/service';
import { setTriggerCurrentUserFilter } from 'ducks/view/preorders';
import { getExportUrl } from 'utilities/api';
import { downloadFile } from 'utilities/downloadFile';
import styles from './styles.module.scss';
import 'dayjs/locale/pl';

const { RangePicker } = DatePicker;

type FilterOptions = Record<
  keyof GetPreordersFilter,
  DefaultOptionType[] | string
>;

interface Props {
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  filterBy: GetPreordersFilter;
  onFilterByChange: (filter: Partial<GetPreordersFilter>) => void;
}

const PreordersTableToolbar: FC<Props> = ({
  searchValue,
  onSearchValueChange,
  filterBy,
  onFilterByChange,
}) => {
  const { data = [] } = useGetUsersQuery();
  const intl = useIntl();
  const triggerCurrentUserFilter = useAppSelector(
    ({ preorders }) => preorders.triggerCurrentUserFilter,
  );
  const dispatch = useAppDispatch();
  const { user } = useSanctum();

  const filterOptions: FilterOptions = {
    status: preorderStatuses(intl),
    dateCreationBefore: '',
    dateCreationAfter: '',
    senderId: data.flatMap(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    })),
  };

  const handleDateCreationChange = ([after, before]: RangeDateChange) =>
    onFilterByChange({
      dateCreationAfter: after,
      dateCreationBefore: before,
    });

  const handleDownload = async () => {
    downloadFile(
      getExportUrl(
        `preorders?${new URLSearchParams(
          JSON.stringify({ ...filterBy, s: searchValue }),
        )}`,
      ),
      'export.csv',
    );
  };

  useEffect(() => {
    if (!triggerCurrentUserFilter) return;

    onFilterByChange({ senderId: user.id });
    dispatch(setTriggerCurrentUserFilter(false));
  }, [triggerCurrentUserFilter, onFilterByChange, user.id, dispatch]);

  return (
    <div className={styles.wrapper}>
      <Input
        value={searchValue}
        className={styles.item}
        onChange={(e) => onSearchValueChange(e.target.value)}
        placeholder={intl.formatMessage({
          defaultMessage: 'Szukaj',
        })}
        allowClear
      />
      <Select
        options={filterOptions.senderId as DefaultOptionType[]}
        value={filterBy.senderId}
        onChange={(value) => onFilterByChange({ senderId: value })}
        className={styles.item}
        placeholder={<FormattedMessage defaultMessage="Użytkownik" />}
        allowClear
        mode="multiple"
        maxTagCount="responsive"
        filterOption={(input: string, option: DefaultOptionType | undefined) =>
          ((option?.label ?? '') as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      />
      <Select
        options={filterOptions.status as DefaultOptionType[]}
        value={filterBy.status}
        onChange={(value) => onFilterByChange({ status: value })}
        className={styles.item}
        placeholder={<FormattedMessage defaultMessage="Status" />}
        allowClear
        mode="multiple"
        maxTagCount="responsive"
      />
      <RangePicker
        className={styles.item}
        locale={locale}
        onChange={(_, values) => handleDateCreationChange(values)}
      />
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        onClick={handleDownload}
      >
        <FormattedMessage defaultMessage="Eksport" />
      </Button>
    </div>
  );
};

export default PreordersTableToolbar;
