import type {
  PreorderVariantFileSlice,
  PreorderVariantSlice,
} from 'ducks/view/preorders';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import { FileTranslationType } from 'constants/fileTranslation';
import {
  LanguagePopularityStatus,
  minTranslationLangsDays,
  pagePerDay,
  popularLanguages,
  wages,
} from 'constants/language';
import { calcServiceGraphicTimeRealization } from './calcServiceGraphicTimeRealization';

type Langs = {
  langFrom: string;
  langTo: string;
};

type GetPagePerDayValue = {
  popularLangsLabel: LanguagePopularityStatus;
  numberOfPages: number;
};

type IsGreaterThanMinValArgs = {
  popularLangsLabel: LanguagePopularityStatus;
  totalFileTranslationDays: number;
};

type CalcTotalRealizationTimeForOneLangArgs = Langs & {
  numberOfPages: number;
  isSworn: boolean;
  isSpecialized: boolean;
  isCorrection: boolean;
  polishTranslationTime?: number | undefined;
};

type CalcSameTranslationsPages = {
  variants: PreorderVariantSlice | undefined;
};

type TranslationPagesObject = {
  [key: string]: {
    pages: number;
    isSworn: boolean;
    isSpecialized: boolean;
    isCorrection: boolean;
    polishTranslationTime?: number | undefined;
    langFrom: string;
    langTo: string;
  };
};

/**
 * .includes checks for one element only, so it can not be placed in one call
 */
const checkIfLangsArePopular = ({ langFrom, langTo }: Langs) =>
  popularLanguages.includes(langFrom) && popularLanguages.includes(langTo);

const getPagePerDayValue = ({
  popularLangsLabel,
  numberOfPages,
}: GetPagePerDayValue) => {
  const indexOfThreshold = pagePerDay.threshold.findIndex((threshold) => {
    if (threshold >= numberOfPages) return true;
    return false;
  });
  const arrayOfPages = pagePerDay[popularLangsLabel];

  // for last threshold (above 100) threshold won't be found -> take last item
  const indexOfThresholdWithLastItem =
    indexOfThreshold < 0
      ? pagePerDay[popularLangsLabel].length - 1
      : indexOfThreshold;

  const paceOfTranslation = arrayOfPages[indexOfThresholdWithLastItem];

  return paceOfTranslation;
};

const isGreaterThanMinValue = ({
  totalFileTranslationDays,
  popularLangsLabel,
}: IsGreaterThanMinValArgs) => {
  return totalFileTranslationDays >= minTranslationLangsDays[popularLangsLabel]
    ? totalFileTranslationDays
    : minTranslationLangsDays[popularLangsLabel];
};

/**
 * order of calcs:
 * 1. pages translated per day
 * 2. multiplier by popularity - it is required now to not interfere
 *    isGreaterThanMinValue
 * 3. unpopular langs min day value is higher than popular
 *    it is called now, to not give unpopular penalty twice (from wages and now)
 * 4. sworn translation multiplier
 * 5. specalized translation multiplier
 * 6. correction translation multiplier
 * 7. include polish translation
 */
export const calcTotalRealizationTimeForOneLang = ({
  numberOfPages,
  langFrom,
  langTo,
  isSworn,
  isSpecialized,
  isCorrection,
  polishTranslationTime = 0,
}: CalcTotalRealizationTimeForOneLangArgs) => {
  const isPopularLangs = checkIfLangsArePopular({
    langFrom,
    langTo,
  });
  const popularLangsLabel = isPopularLangs
    ? LanguagePopularityStatus.POPULAR
    : LanguagePopularityStatus.UNPOPULAR;

  const paceOfPagesPerDay = getPagePerDayValue({
    popularLangsLabel,
    numberOfPages,
  });

  const daysToTranslateAllPages = Math.ceil(numberOfPages / paceOfPagesPerDay);

  const daysWithUnpopularLangMultiplier = Math.ceil(
    daysToTranslateAllPages * wages[popularLangsLabel],
  );

  const daysWithMinValueBackup = isGreaterThanMinValue({
    totalFileTranslationDays: daysWithUnpopularLangMultiplier,
    popularLangsLabel,
  });

  const daysWithSwornMultiplier = Math.ceil(
    isSworn ? daysWithMinValueBackup * wages.isSworn : daysWithMinValueBackup,
  );

  const daysWithSpecializedMultiplier = Math.ceil(
    isSpecialized
      ? daysWithSwornMultiplier * wages.isSpecialized
      : daysWithSwornMultiplier,
  );

  const daysWithCorrectionMultiplier = Math.ceil(
    isCorrection
      ? daysWithSpecializedMultiplier * wages.isCorrection
      : daysWithSpecializedMultiplier,
  );

  return Math.ceil(
    daysWithCorrectionMultiplier +
      (langTo === LanguageCode.POLAND ? 0 : polishTranslationTime),
  );
};

/**
 * create new array with day values for each translation
 */
const createArrayWithTotalDayValues = (
  preorderVariantFiles: PreorderVariantFileSlice[],
) =>
  preorderVariantFiles
    .map(({ preorderVariantLangs }) =>
      preorderVariantLangs.map(({ timeRealization }) => timeRealization),
    )
    .flat();

/**
 * Creates object with specific key for each possible translation type
 * firstly creates flattened array of files
 * secondly creates object with keys and summarized realization time
* @example calcPagesOfSameTranslation({
    variants: preorderVariants,
  })
 * @returns
 * FRDEfalsetruefalse: 10
 */
export const calcPagesOfSameTranslation = ({
  variants,
}: CalcSameTranslationsPages) => {
  const flattenedVariantsLangs = variants?.preorderVariantFiles.flatMap(
    (file) => {
      return file.preorderVariantLangs.flatMap((lang) => ({
        isCorrection: file.isCorrection,
        isSpecial: file.isSpecial,
        isSworn: file.type === FileTranslationType.SWORN,
        ...lang,
      }));
    },
  );

  const summarizedPages = flattenedVariantsLangs?.reduce(
    (prevValue, curValue) => {
      const translationKey = `${curValue.langFrom}${curValue.langTo}${curValue.isCorrection}${curValue.isSpecial}${curValue.isSworn}`;

      return {
        ...prevValue,
        [translationKey]: {
          pages: (prevValue?.[translationKey]?.pages ?? 0) + curValue.pages,
          langFrom: curValue.langFrom,
          langTo: curValue.langTo,
          isCorrection: curValue.isCorrection,
          isSpecialized: curValue.isSpecial,
          isSworn: curValue.isSworn,
        },
      };
    },
    {} as TranslationPagesObject,
  );

  return summarizedPages ?? {};
};

/**
 * Calculates the total realization time for a preorder variant by
 * summing the highest day value from an array of days and
 * the graphic delivery time.
 */
export const calcVariantTotalRealizationTime = (
  preorderVariants: PreorderVariantSlice,
) => {
  const { preorderVariantServices } = preorderVariants;

  const graphicTimeRealization = calcServiceGraphicTimeRealization(
    preorderVariantServices,
  );

  const summarizedSameTranslationsPages = calcPagesOfSameTranslation({
    variants: preorderVariants,
  });

  const arrayOfSummarizedTranslations = Object.values(
    summarizedSameTranslationsPages,
  );

  const summarizedTranslationsDays = arrayOfSummarizedTranslations.map(
    ({ pages, isCorrection, isSpecialized, isSworn, langFrom, langTo }) => {
      const toPolishTranslation =
        langFrom !== LanguageCode.POLAND && langTo !== LanguageCode.POLAND
          ? calcTotalRealizationTimeForOneLang({
              numberOfPages: pages,
              isCorrection,
              isSpecialized,
              isSworn,
              langFrom,
              langTo: LanguageCode.POLAND,
            })
          : 0;

      return calcTotalRealizationTimeForOneLang({
        numberOfPages: pages,
        isCorrection,
        isSpecialized,
        isSworn,
        langFrom: toPolishTranslation ? LanguageCode.POLAND : langFrom,
        langTo,
        polishTranslationTime: toPolishTranslation,
      });
    },
  );

  const highestDay = Math.max(
    summarizedTranslationsDays.length
      ? Math.max(...summarizedTranslationsDays)
      : 0,
  );

  return highestDay + graphicTimeRealization;
};

/**
 * calculate sum of total day values for each translation,
 */
export const calcSummarizedRealizationTime = (
  preorderVariantFiles: PreorderVariantFileSlice[],
) =>
  createArrayWithTotalDayValues(preorderVariantFiles).reduce(
    (acc, fileTime) => acc + fileTime,
    0,
  );
