import type { ColumnsType } from 'antd/es/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { OrderFile, OrderFileLang } from 'ducks/orders/types';
import type { OrderFile as OrderTranslationFile } from 'ducks/view/orderTranslationFiles/type';
import { FormattedMessage } from 'react-intl';
import LanguageFlag from 'components/ui/atoms/LanguageFlag';
import LanguageName from 'components/ui/atoms/LanguageName';
import DownloadFileButton from 'components/ui/molecules/DownloadFileButton';
import TranslationFilesColumn from './components/TranslationFilesColumn';
import styles from './styles.module.scss';

export type LangFileRow = TableRow & OrderFile;

type Props = {
  orderFiles: OrderTranslationFile[];
  orderLangs: OrderFileLang[];
};

export const columns = ({
  orderFiles,
  orderLangs,
}: Props): ColumnsType<LangFileRow> => [
  {
    title: <FormattedMessage defaultMessage="Plik klienta" />,
    key: 'file',
    dataIndex: 'file',
    render: (_, { file }) => <DownloadFileButton file={file} />,
  },
  {
    title: <FormattedMessage defaultMessage="Para językowa" />,
    key: 'file',
    dataIndex: 'file',
    render: (_, { id: fileID }) => (
      <div className={styles.multilineCell}>
        {orderLangs
          .filter(({ orderFileId }) => orderFileId === fileID)
          .map(({ id, langFrom, langTo }) => (
            <div key={id} className={styles.languagesWrapper}>
              <LanguageFlag languageCode={langFrom} />
              <LanguageName
                data-testid={`langFrom-${id}`}
                countryCode={langFrom}
                showCode
                ellipsis
              />
              <span>-</span>
              <LanguageName
                data-testid={`langTo-${id}`}
                countryCode={langTo}
                showCode
                ellipsis
              />
              <LanguageFlag languageCode={langTo} />
            </div>
          ))}
      </div>
    ),
  },
  // TODO: backend
  {
    title: <FormattedMessage defaultMessage="Plik tłumacza" />,
    key: 'file',
    dataIndex: 'file',
    render: (_, { fileId: currentFileId }) => {
      const orderFile = orderFiles.find(
        ({ fileId }) => fileId === currentFileId,
      );

      return orderFile ? (
        <TranslationFilesColumn orderFileLangs={orderFile.orderFileLangs} />
      ) : (
        <span />
      );
    },
  },
];
