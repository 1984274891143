import type { FileTranslationType } from 'constants/fileTranslation';
import type { CurrencyType } from 'ducks/payments/types';

export enum GlobalTag {
  Global = 'Global',
  Languages = 'Languages',
  EXCHANGE_RATES = 'Exchange_rates',
}

export enum FileType {
  QUALIFICATIONS = 'qualifications',
  PREORDERS = 'preorders',
  ORDERS = 'orders',
  EMAIL_PREORDERS = 'email_preorders',
  EMAIL_ORDERS = 'email_orders',
  TRANSLATIONS_FILES = 'translations_files',
}

export type CheckNipRequest = {
  nip: string;
};

export type CheckNipResponse = {
  nip: string;
  regon: string;
  name: string;
  province: string;
  city: string;
  propertyNumber: string;
  apartmentNumber: string;
  zipCode: string;
  street: string;
};

export type CheckVatRequest = {
  vat: string;
};

export type CheckVatResponse = {
  isValid: boolean;
};

export type GetFileRequest = {
  id: number | string;
  object: FileType;
};

export type AddFileRequest = {
  object: FileType;
  id: number;
  files: File[];
};

export type AddFileResponse = UploadedFile[];

export type DeleteFileRequest = {
  object: FileType;
  id: number;
};

export type GetFileResponse = Blob;

export type GetLanguagesResponse = Language[];

export type CalcTranslationsRequest = {
  isCorrection: boolean;
  isSpecial: boolean;
  type: FileTranslationType;
  langFrom: string;
  langTo: string;
  characters: number;
}[];

export type CalcTranslationsResponse = {
  price: number;
};

export type UploadedFile = {
  file: string;
  filename: string;
  id: number;
  object: FileType;
  objectId: number;
};

export type Language = {
  id: number;
  code: string;
  name: string;
};

export type ExchangeRatesRequest = {
  currencies: {
    currency: CurrencyType;
    value: number;
  }[];
};

export type ExchangeRatesResponse = {
  currency: CurrencyType;
  value: number;
  rate: number;
  result: number;
}[];
