import { Tabs, Card } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderStatus } from 'constants/orderStatuses';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setOrderVariants } from 'ducks/view/orders';
import { useOrderDetailsServices } from '../../useOrderDetailsServices';
import OrdersTab from '../OrdersTab';
import PreordersTab from '../PreordersTab';

const ORDER_TAB_KEY = '1';
const PREORDER_TAB_KEY = '2';

const TabsSection = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(({ orders }) => orders.status);
  const { orderVariantData } = useOrderDetailsServices();
  const defaultActiveKey =
    status === OrderStatus.NEW ? PREORDER_TAB_KEY : ORDER_TAB_KEY;

  useEffect(() => {
    if (orderVariantData) {
      dispatch(setOrderVariants([orderVariantData]));
    }
  }, [orderVariantData, dispatch]);

  return (
    <Card
      data-testid="tabsSection"
      bordered={false}
      bodyStyle={{ paddingTop: 16, paddingBottom: 16 }}
    >
      <Tabs data-testid="tabs" defaultActiveKey={defaultActiveKey}>
        <Tabs.TabPane
          key={ORDER_TAB_KEY}
          tab={<FormattedMessage defaultMessage="Zlecenie" />}
        >
          <OrdersTab />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={PREORDER_TAB_KEY}
          tab={<FormattedMessage defaultMessage="Wycena" />}
        >
          <PreordersTab />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default TabsSection;
