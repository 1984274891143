import type { ResponseFormItem } from 'ducks/settings/types';
import { Col, Row, notification } from 'antd';
import { useGetSettingsQuery } from 'ducks/settings/service';
import BankForm from 'views/Settings/components/SettingsForm/BankForm';
import { FormsIds } from 'views/Settings/components/SettingsForm/config';
import ContactForm from 'views/Settings/components/SettingsForm/ContactForm';
import InvoiceForm from 'views/Settings/components/SettingsForm/InvoiceForm';
import PresentationForm from 'views/Settings/components/SettingsForm/PresentationForm';

const queryFormFilter = ({
  queryFormData,
  sectionId,
}: {
  queryFormData: ResponseFormItem[];
  sectionId: number;
}) => queryFormData.filter(({ section }) => section === sectionId);

const Company: React.FC = () => {
  const [notify, contextHolder] = notification.useNotification();

  const { data: queryFormData = [], isLoading } = useGetSettingsQuery({
    sectionId: `${FormsIds.BANK_FORM},${FormsIds.INVOICE_FORM},${FormsIds.CONTACT_FORM},${FormsIds.PRESENTATION_FORM}`,
  });

  return (
    <Row gutter={[24, 24]}>
      {contextHolder}
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <InvoiceForm
          notify={notify}
          queryFormData={queryFormFilter({
            queryFormData,
            sectionId: Number(FormsIds.INVOICE_FORM),
          })}
          isLoading={isLoading}
        />
        <BankForm
          notify={notify}
          queryFormData={queryFormFilter({
            queryFormData,
            sectionId: Number(FormsIds.BANK_FORM),
          })}
          isLoading={isLoading}
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <ContactForm
          notify={notify}
          queryFormData={queryFormFilter({
            queryFormData,
            sectionId: Number(FormsIds.CONTACT_FORM),
          })}
          isLoading={isLoading}
        />
        <PresentationForm
          notify={notify}
          queryFormData={queryFormFilter({
            queryFormData,
            sectionId: Number(FormsIds.PRESENTATION_FORM),
          })}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
};

export default Company;
