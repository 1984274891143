import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { useOrderDetailsServices } from '../../useOrderDetailsServices';
import styles from './styles.module.scss';

const ActionsSection = () => {
  const { isOrderLoading } = useOrderDetailsServices();

  return (
    <div className={styles.wrapper}>
      <Link to="/#">
        <Button
          type="primary"
          className={styles.cancelButton}
          loading={isOrderLoading}
        >
          <FormattedMessage defaultMessage="Anuluj realizację" />
        </Button>
      </Link>
    </div>
  );
};

export default ActionsSection;
