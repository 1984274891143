import type { MenuProps } from 'antd';
import type { Key, ReactElement, ReactNode } from 'react';
import type { IntlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ClientsIcon } from 'assets/images/clients_icon.svg';
import { ReactComponent as FinanceIcon } from 'assets/images/finance_icon.svg';
import { ReactComponent as LogOutIcon } from 'assets/images/log_out_icon.svg';
import { ReactComponent as OrderIcon } from 'assets/images/orders_icon.svg';
import { ReactComponent as PreorderIcon } from 'assets/images/preorder_icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings_icon.svg';
import { ReactComponent as SmsIcon } from 'assets/images/sms_icon.svg';
import { ReactComponent as TranslatorIcon } from 'assets/images/translators_icon.svg';
import { Routes } from 'components/main/App/Routing';
import { LogoutButton } from 'components/main/AuthProvider';
import { UserRole } from 'ducks/users/types';
import { Routes as DraftRoutes } from 'views/Draft/Routing';
import { Routes as FinancesRoutes } from 'views/Finances/Routing';
import { Routes as InvoicesRoutes } from 'views/Invoices/Routing';
import { Routes as SettingsRoutes } from 'views/Settings/Routing';

export type SidenavMenuItem = {
  key: Key;
  children?: ReactNode;
  label?: string;
  icon?: ReactElement;
  url?: string;
  roleAccess?: number[];
  subSection?: {
    url: string;
    label: string;
    roleAccess?: number[];
  }[];
};

type MenuItem = Required<MenuProps>['items'][number];

const filterMenu = (menu: SidenavMenuItem[], userRole?: UserRole) => {
  return menu.filter((item) => {
    const { roleAccess, subSection } = item;

    if (roleAccess && userRole)
      return roleAccess.some((role) => role === userRole);

    if (userRole && subSection) {
      subSection.filter((subs) => {
        const { roleAccess: subRoleAccess } = subs;

        if (subRoleAccess)
          return subRoleAccess.some((role) => role === userRole);

        return subs;
      });
    }

    return item;
  });
};

export const getMenu = (
  intl: IntlShape,
  userRole?: UserRole,
): SidenavMenuItem[] =>
  filterMenu(
    [
      {
        key: Routes.PROJECTS,
        label: intl.formatMessage({ defaultMessage: 'Projekty' }),
        icon: <OrderIcon />,
        url: Routes.PROJECTS,
      },
      {
        key: Routes.PREORDERS,
        label: intl.formatMessage({ defaultMessage: 'Wyceny' }),
        icon: <PreorderIcon />,
        url: Routes.PREORDERS,
      },
      {
        key: DraftRoutes.DRAFT_PREORDER_CALCULATION,
        label: intl.formatMessage({ defaultMessage: 'Oszacowanie' }),
        icon: <PreorderIcon />,
        url: DraftRoutes.DRAFT_PREORDER_CALCULATION,
      },
      {
        key: Routes.TRANSLATORS,
        label: intl.formatMessage({ defaultMessage: 'Tłumacze' }),
        icon: <TranslatorIcon />,
        url: Routes.TRANSLATORS,
      },
      {
        key: Routes.CLIENTS,
        label: intl.formatMessage({ defaultMessage: 'Klienci' }),
        icon: <ClientsIcon />,
        url: Routes.CLIENTS,
      },
      {
        key: Routes.SMS,
        label: intl.formatMessage({ defaultMessage: 'SMS' }),
        icon: <SmsIcon />,
        url: Routes.SMS,
      },
      {
        key: Routes.SETTINGS,
        label: intl.formatMessage({ defaultMessage: 'Ustawienia' }),
        icon: <SettingsIcon />,
        url: Routes.SETTINGS,
        roleAccess: [UserRole.ADMIN, UserRole.BOOKKEEPING],
        subSection: [
          {
            label: intl.formatMessage({ defaultMessage: 'Dane firmy' }),
            url: `${Routes.SETTINGS}${SettingsRoutes.COMPANY}`,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Integracje zewnętrzne',
            }),
            url: `${Routes.SETTINGS}${SettingsRoutes.EXTERNAL_INTEGRATIONS}`,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Import wycen',
            }),
            url: `${Routes.SETTINGS}${SettingsRoutes.QUOTATION_EXCEPTIONS}`,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Lista pracowników',
            }),
            url: Routes.USERS,
            roleAccess: [UserRole.ADMIN],
          },
          {
            label: intl.formatMessage({ defaultMessage: 'Cennik' }),
            url: Routes.PRICES,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Szablony e-mail',
            }),
            url: `${Routes.SETTINGS}${SettingsRoutes.EMAIL_TEMPLATES}`,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Szablony SMS',
            }),
            url: `${Routes.SETTINGS}${SettingsRoutes.SMS_TEMPLATES}`,
          },
        ],
      },
      {
        key: Routes.FINANCES,
        label: intl.formatMessage({ defaultMessage: 'Finanse' }),
        icon: <FinanceIcon />,
        url: Routes.FINANCES,
        roleAccess: [UserRole.ADMIN, UserRole.BOOKKEEPING],
        subSection: [
          {
            label: intl.formatMessage({
              defaultMessage: 'Lista dokumentów',
            }),
            url: FinancesRoutes.FINANCES_DOCUMENT_LIST,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Import wyciągu bankowego',
            }),
            url: FinancesRoutes.FINANCES_IMPORT,
          },
        ],
      },
      {
        key: Routes.INVOICES,
        label: intl.formatMessage({ defaultMessage: 'Faktury' }),
        icon: <FinanceIcon />,
        url: Routes.INVOICES,
        roleAccess: [UserRole.ADMIN, UserRole.BOOKKEEPING],
        subSection: [
          {
            label: intl.formatMessage({
              defaultMessage: 'Faktury VAT',
            }),
            url: InvoicesRoutes.INVOICES_VAT,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Faktury końcowe',
            }),
            url: InvoicesRoutes.INVOICES_FINAL,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Faktury zaliczkowe',
            }),
            url: InvoicesRoutes.INVOICES_ADVANCE,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Faktury przeterminowane',
            }),
            url: InvoicesRoutes.INVOICES_OVERDUE,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Wpłaty od osób fizycznych',
            }),
            url: InvoicesRoutes.INVOICES_PAYMENT_INDIVIDUALS,
          },
        ],
      },

      {
        key: 'others',
        label: intl.formatMessage({ defaultMessage: 'Inne' }),
      },
      {
        key: 9,
        children: (
          <LogoutButton>
            <span className="icon">
              <LogOutIcon />
            </span>
            <span className="label">
              <FormattedMessage defaultMessage="Wyloguj się" />
            </span>
          </LogoutButton>
        ),
      },
    ],
    userRole,
  );

export const getItem = (
  label: ReactNode,
  key: Key,
  children?: MenuItem[],
): MenuItem => {
  return {
    key,
    children,
    label,
  };
};
