import type { CheckNipResponse } from 'ducks/global/types';
import type { ComponentPropsWithoutRef, FC } from 'react';
import { Button, notification } from 'antd';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useCheckNipMutation } from 'ducks/global/service';
import { isValidNip } from 'utilities/isValid';
import styles from './styles.module.scss';

export interface GusReturnProps
  extends Pick<CheckNipResponse, 'name' | 'city' | 'street' | 'nip' | 'regon'> {
  addressNumbers: string[];
  post_code: string;
}

interface GusButtonProps extends ComponentPropsWithoutRef<typeof Button> {
  value: string | undefined;
  onSuccessCallback: (data: GusReturnProps) => void;
  withoutMargin?: boolean;
  isInternational?: boolean;
}

const GusButton: FC<GusButtonProps> = ({
  value,
  onSuccessCallback,
  className,
  withoutMargin = false,
  isInternational = false,
  ...restButtonProps
}) => {
  const [checkNip, { isLoading: isCheckNipLoading }] = useCheckNipMutation();
  const [notify] = notification.useNotification();

  const handleCheckNip = async () => {
    if (isCheckNipLoading || !value) return;

    try {
      const {
        nip,
        regon,
        name,
        city,
        propertyNumber,
        street,
        apartmentNumber,
        zipCode,
      } = await checkNip({
        nip: value,
      }).unwrap();
      const addressNumbers = [apartmentNumber, propertyNumber].filter(
        (number) => number,
      );

      onSuccessCallback({
        nip,
        regon,
        name,
        city,
        street,
        addressNumbers,
        post_code: zipCode,
      });
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas pobierania danych z GUS." />
        ),
      });
    }
  };

  return (
    <Button
      loading={isCheckNipLoading}
      data-testid="gus-button"
      className={cn(styles.gusButton, className, {
        [styles.withoutMargin]: withoutMargin,
      })}
      size="small"
      disabled={!isValidNip(value ?? '', isInternational)}
      onClick={handleCheckNip}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restButtonProps}
    >
      <FormattedMessage defaultMessage="Pobierz z GUS" />
    </Button>
  );
};

export default GusButton;
