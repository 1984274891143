import { Endpoint } from 'ducks/auth/service';
import { Endpoint as UsersEndpoint } from 'ducks/users/service';

export const sanctumConfig = {
  apiUrl: process.env.REACT_APP_API_BASE_URL,
  csrfCookieRoute: 'sanctum/csrf-cookie', // not used but required by react-sanctum
  signInRoute: Endpoint.LogIn,
  signOutRoute: Endpoint.LogOut.substring(1),
  userObjectRoute: UsersEndpoint.GetCurrentUser.substring(1),
  twoFactorChallengeRoute: Endpoint.TwoFactorChallenge.substring(1),
};
