import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { AddPreorderVariantLangToAllFilesPayload } from 'ducks/view/preorders';
import type { IntlShape } from 'react-intl';
import { Checkbox, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import LanguageSelect from 'components/ui/atoms/LanguageSelect';
import {
  FileTranslationType,
  fileTranslationTypes,
} from 'constants/fileTranslation';

export type AllFilesModalValues = Omit<
  AddPreorderVariantLangToAllFilesPayload,
  'variantId'
>;

type Props = {
  intl: IntlShape;
  type: FileTranslationType;
};

export const initialValues: Partial<AllFilesModalValues> = {
  isCorrection: false,
  isSpecial: false,
  langFrom: '',
  langTo: '',
};

export const formItems = ({
  intl,
  type,
}: Props): FormItemRecord<AllFilesModalValues>[] => [
  {
    key: 'type',
    label: <FormattedMessage defaultMessage="Typ tłumaczenia" />,
    name: 'type',
    component: <Select options={fileTranslationTypes(intl)} />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz typ" />,
      },
    ],
  },
  {
    key: 'langFrom',
    label: <FormattedMessage defaultMessage="Tłumaczenie z języka" />,
    name: 'langFrom',
    component: <LanguageSelect />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz język" />,
      },
    ],
  },
  {
    key: 'langTo',
    label: <FormattedMessage defaultMessage="Tłumaczenie na język" />,
    name: 'langTo',
    component: <LanguageSelect />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz język" />,
      },
    ],
  },
  {
    key: 'isSpecial',
    label: <FormattedMessage defaultMessage="Specjalistyczny" />,
    name: 'isSpecial',
    component: <Checkbox />,
    additionalItemProps: {
      valuePropName: 'checked',
    },
    hidden: type === FileTranslationType.CORRECTION,
  },
  {
    key: 'isCorrection',
    label: <FormattedMessage defaultMessage="Korekta" />,
    name: 'isCorrection',
    component: <Checkbox />,
    additionalItemProps: {
      valuePropName: 'checked',
    },
    hidden:
      type === FileTranslationType.CORRECTION ||
      type === FileTranslationType.SWORN,
  },
];
