import type { PaymentIndividual } from 'ducks/payments/types';
import { useState, type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import EditNoteModal from '../EditNoteModal';

type Props = Pick<PaymentIndividual, 'id' | 'note'>;

const ActionsCell: FC<Props> = ({ id, note }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <Button onClick={handleOpenModal}>
        <FormattedMessage defaultMessage="Edytuj" />
      </Button>
      <EditNoteModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        id={id}
        note={note}
      />
    </>
  );
};

export default ActionsCell;
