import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useInvoiceOverdueDetailsServices } from '../../useInvoiceOverdueDetailsServices';
import { formItems } from './config';

const ClientDataSection = () => {
  const { invoiceOverdueData, isInvoiceOverdueFetching } =
    useInvoiceOverdueDetailsServices();

  return (
    <CardCommon
      title={<FormattedMessage defaultMessage="Dane klienta" />}
      isLoading={isInvoiceOverdueFetching}
    >
      <Form autoComplete="off" layout="vertical">
        <div className="gridForm">
          {invoiceOverdueData &&
            formItems({ clientData: invoiceOverdueData.clientData }).map(
              ({ key, ...props }) => <FormItem key={key} {...props} />,
            )}
        </div>
      </Form>
    </CardCommon>
  );
};

export default ClientDataSection;
