import { Popover } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import TranslationFileListTitleUploadAction from './TranslationFileListTitleUploadAction';

const TranslationFileListTitle = () => {
  return (
    <div className={styles.wrapper}>
      <Popover
        content={
          <FormattedMessage
            defaultMessage="Aby system mógł automatycznie dopasować pliki tłumaczeń, ich nazwa powinna {br} być według schematu: <strong>NAZWA_PLIKU_KLIENTA_PL_EN.pdf</strong>, {br}{br} przykładowo: <strong>plik1_PL_EN.pdf</strong>"
            values={{
              // eslint-disable-next-line react/no-unstable-nested-components
              strong: (...chunks) => <strong>{chunks}</strong>,
              br: <br />,
            }}
          />
        }
      >
        <span>
          <FormattedMessage defaultMessage="Wgrane pliki (?):" />
        </span>
      </Popover>
      <TranslationFileListTitleUploadAction />
    </div>
  );
};

export default TranslationFileListTitle;
