/* eslint-disable react/jsx-props-no-spreading */
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { Client } from 'ducks/clients/types';
import type { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal/Modal';
import { useUpdateClientDiscountMutation } from 'ducks/clients/service';
import { discountModalInputs, type DiscountModalValues } from './config';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: Client;
  notify: NotificationInstance;
}

const { useForm } = Form;

const DiscountModal: FC<Props> = ({ isOpen, onClose, notify, data }) => {
  const [form] = useForm<DiscountModalValues>();
  const [updateClientDiscount, { isLoading }] =
    useUpdateClientDiscountMutation();

  const handleOnSubmit = async () => {
    if (isLoading) return;

    const values = await form.validateFields();

    try {
      await updateClientDiscount({
        discount: values.discount,
        clientId: data.id,
      }).unwrap();
      notify.success({
        message: <FormattedMessage defaultMessage="Rabat został edytowany." />,
      });

      onClose();
      form.resetFields();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania rabatu." />
        ),
      });
    }
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Edytuj rabat" />}
      isLoading={isLoading}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={onClose}
      okText={<FormattedMessage defaultMessage="Edytuj" />}
      disableModalStyles
      disableFormStyles
    >
      <Form form={form} layout="vertical" initialValues={data}>
        {discountModalInputs.map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default DiscountModal;
