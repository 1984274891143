import type { OrderListElement } from 'ducks/orders/types';
import { Popover, Skeleton, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useGetUserDetailsQuery } from 'ducks/users/service';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = Pick<OrderListElement, 'activeEditorId'>;

const ActiveEditorDotCell: React.FC<Props> = ({ activeEditorId }) => {
  const { data, isLoading } = useGetUserDetailsQuery(
    {
      id: activeEditorId ?? 0,
    },
    {
      skip: !activeEditorId,
    },
  );
  const activeEditorName = [data?.firstName, data?.lastName].join(' ');

  if (!activeEditorId) return <span />;

  return isLoading ? (
    <div className={styles.skeletonWrapper}>
      <Skeleton.Avatar size="small" />
    </div>
  ) : (
    <Popover
      content={<Text>{activeEditorName}</Text>}
      title={<FormattedMessage defaultMessage="Edytowany przez" />}
      trigger="hover"
      placement="bottom"
      showArrow={false}
    >
      <div className={styles.activeEditorDot} />
    </Popover>
  );
};

export default ActiveEditorDotCell;
