/* eslint-disable react/jsx-props-no-spreading */
import type { AddPriceModalValues } from './config';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import type { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal';
import { useAddPriceMutation } from 'ducks/prices/service';
import { Routes } from 'views/Prices/Routing';
import { formItems, initialValues } from './config';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  notify: NotificationInstance;
}

const { useForm } = Form;

const AddPriceModal: FC<Props> = ({ isOpen, onClose, notify }) => {
  const navigate = useNavigate();
  const [form] = useForm<AddPriceModalValues>();
  const [addPrice, { isLoading }] = useAddPriceMutation();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleOnSubmit = async () => {
    if (isLoading) return;

    try {
      const values = await form.validateFields();

      const { id } = await addPrice(values).unwrap();

      handleClose();
      form.resetFields();
      navigate(Routes.PRICE_DETAILS.replace(':id', id.toString()));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.errorFields) return;

      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania." />
        ),
      });
    }
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Dodaj cennik" />}
      isLoading={isLoading}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={handleClose}
      okText={<FormattedMessage defaultMessage="Dodaj" />}
      disableFormStyles
      disableModalStyles
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
      >
        {formItems.map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default AddPriceModal;
