/* eslint-disable react/jsx-props-no-spreading */
import type { FormListData, FormListItemRecord } from '../../config';
import type { ComponentPropsWithoutRef } from 'react';
import { Skeleton } from 'antd';
import FormListItem from './components/FormListItem';
import styles from './styles.module.scss';

interface Props<TData> extends ComponentPropsWithoutRef<'tr'> {
  data: FormListData<TData>;
  items: FormListItemRecord<TData>[];
  isLoading: boolean;
  isFirstRow?: boolean;
  itemClassName?: string;
}

export const FormListRow = <TData,>({
  data,
  items,
  isLoading,
  isFirstRow = false,
  itemClassName,
  ...props
}: Props<TData>) => {
  return (
    <tr {...props}>
      {items.map(({ key, width, rowSpan, ...restItemProps }) =>
        // eslint-disable-next-line no-nested-ternary
        isFirstRow && typeof rowSpan !== 'undefined' ? (
          <td
            key={key}
            style={{
              width: width ?? 'auto',
              minWidth: width ?? 'auto',
              paddingBottom: 0,
            }}
            rowSpan={rowSpan}
          >
            <Skeleton
              loading={isLoading}
              round
              paragraph={false}
              active
              className={styles.skeleton}
            >
              <FormListItem
                key={key}
                data={data}
                className={itemClassName}
                {...restItemProps}
              />
            </Skeleton>
          </td>
        ) : !isFirstRow && typeof rowSpan !== 'undefined' ? null : (
          <td
            key={key}
            style={{ width: width ?? 'auto', minWidth: width ?? 'auto' }}
            rowSpan={rowSpan}
          >
            <Skeleton
              loading={isLoading}
              round
              paragraph={false}
              active
              className={styles.skeleton}
            >
              <FormListItem
                key={key}
                data={data}
                className={itemClassName}
                {...restItemProps}
              />
            </Skeleton>
          </td>
        ),
      )}
    </tr>
  );
};
