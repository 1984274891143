import type { NotificationInstance } from 'antd/lib/notification/interface';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useUnblockPreorderMutation } from 'ducks/preorders/service';

type Props = {
  notify: NotificationInstance;
  showSuccessNotify?: boolean;
};

export const useUnblockPreorder = ({
  notify,
  showSuccessNotify = false,
}: Props) => {
  const { id = '' } = useParams();
  const [unblockPreorder, { isLoading }] = useUnblockPreorderMutation();

  const handleUnblock = useCallback(async () => {
    if (!id || isLoading) return;

    const preorderId = Number(id);

    try {
      await unblockPreorder({
        preorderId,
      }).unwrap();

      if (showSuccessNotify) {
        notify.success({
          message: (
            <FormattedMessage defaultMessage="Poprawnie odblokowano wycenę." />
          ),
        });
      }
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas odblokowywania wyceny." />
        ),
      });
    }
  }, [id, unblockPreorder, isLoading, showSuccessNotify, notify]);

  return { handleUnblock, isLoading };
};
