/* eslint-disable react/jsx-props-no-spreading */
import type { UploadFile } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import { Button, Typography } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Upload from 'components/ui/molecules/Upload';
import { UploadIcon } from 'icons';

const { Text } = Typography;

type Props = {
  onChange?: (value: UploadChangeParam<UploadFile>) => void;
};

const UploadInput: React.FC<Props> = ({ onChange, ...addictionalProps }) => {
  const [uploadedFileName, setUploadedFileName] = useState('');

  return (
    <>
      <Upload
        {...addictionalProps}
        maxCount={1}
        onChange={(info) => {
          if (onChange) onChange(info);
          setUploadedFileName(info.file.name);
        }}
      >
        <Button size="small" type="link" icon={<UploadIcon />}>
          <FormattedMessage defaultMessage="Dodaj plik" />
        </Button>
      </Upload>
      <Text>{uploadedFileName}</Text>
    </>
  );
};

export default UploadInput;
