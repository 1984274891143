import type { PaginationResponse } from 'ducks/types';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const basePath = (path: string) => `${BASE_URL}${path}`;

export const mockedPagination = <TData>(
  data: TData,
): PaginationResponse<TData> => {
  return {
    currentPage: 1,
    firstPageUrl: '',
    from: 1,
    lastPage: 3,
    lastPageUrl: '',
    nextPageUrl: '',
    path: '',
    perPage: 1,
    prevPageUrl: null,
    to: 1,
    total: 1,
    data: [data],
  };
};
