import type { FC } from 'react';
import ReactCountryFlag from 'react-country-flag';
import cataloniaFlag from 'assets/images/catalonia.svg';
import englishFlag from 'assets/images/english.png';
import { LanguageCode } from 'constants/countryAndLanguageCodes';
import styles from './styles.module.scss';

interface Props {
  languageCode: string;
}

const flagMapping: { [key: string]: string } = {
  [LanguageCode.ENGLISH]: englishFlag,
  [LanguageCode.CATALONIA]: cataloniaFlag,
};

const LanguageFlag: FC<Props> = ({ languageCode }) => {
  if (Object.keys(flagMapping).includes(languageCode))
    return (
      <img
        className={styles.imgFlag}
        src={flagMapping[languageCode]}
        alt={languageCode}
      />
    );

  return (
    <ReactCountryFlag
      countryCode={languageCode}
      svg
      className={styles.flag}
      alt={languageCode}
    />
  );
};

export default LanguageFlag;
