import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type {
  GetOrdersFilter,
  Order,
  OrderListElement,
} from 'ducks/orders/types';
import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { Button, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import theme from 'components/layout/Main/styles/values.scss';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import ActiveEditorDotCell from 'components/ui/atoms/TableCells/ActiveEditorDotCell';
import NotesCell from 'components/ui/atoms/TableCells/NotesCell';
import PaymentDollarCell from 'components/ui/atoms/TableCells/PaymentDollarCell';
import ClientAvatar from 'components/ui/molecules/ClientAvatar';
import { OrderStatus, orderStatusesObject } from 'constants/orderStatuses';
import { NO_DATA } from 'constants/strings';
import { EditIcon } from 'icons';
import ExclamationIcon from 'icons/ExclamationIcon';
import { Routes as ClientRoutes } from 'views/Clients/Routing';
import { Routes as OrderRoutes, Routes } from 'views/Orders/Routing';
import CatCell from './CatCell';
import styles from './styles.module.scss';
import TranslationCell from './TranslationCell';

export type OrderRow = TableRow & OrderListElement;

type Props = {
  intl: IntlShape;
};

export const initialFilters: GetOrdersFilter = {
  status: undefined,
  dateCreationBefore: undefined,
  dateCreationAfter: undefined,
  senderId: undefined,
};

type Args = Pick<Order, 'dateRealization' | 'status'>;

const generateBackgroundColor = ({ dateRealization, status }: Args) => {
  if (status === OrderStatus.DONE) return theme.colorRealized;
  if (status === OrderStatus.COMPLAINT) return theme.colorComplaint;

  if (dayjs().diff(dayjs(dateRealization), 'day') === 0)
    return theme.colorTodayRealization;

  return '';
};

export const columns = ({ intl }: Props): ColumnsType<OrderRow> => [
  {
    title: <span />,
    key: 'edited',
    dataIndex: 'edited',
    render: (_, { activeEditorId }) => (
      <ActiveEditorDotCell activeEditorId={activeEditorId} />
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Data" />,
    key: 'createdAt',
    dataIndex: 'createdAt',
    align: 'center',
    render: (_, { createdAt }) =>
      createdAt ? dayjs(createdAt).format('DD.MM') : NO_DATA,
  },
  {
    title: <FormattedMessage defaultMessage="Numer" />,
    key: 'number',
    dataIndex: 'number',
    className: styles.numberCell,
    render: (_, { dateRealization, status, number }) => {
      return {
        props: {
          style: {
            background: generateBackgroundColor({ dateRealization, status }),
          },
        },
        children: <span>{number}</span>,
      };
    },
  },
  {
    title: <FormattedMessage defaultMessage="Płatność" />,
    key: 'isPaid',
    dataIndex: 'isPaid',
    sorter: true,
    width: 20,
    render: (_, { id, paymentStatus }) => (
      <PaymentDollarCell
        to={Routes.ORDER_DETAILS.replace(':id', id.toString())}
        paymentStatus={paymentStatus}
      />
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Tłumaczenie" />,
    key: 'translation',
    dataIndex: 'translation',
    render: (_, { orderFiles }) =>
      orderFiles.length ? (
        <div className={styles.mappedCellWrapper}>
          {orderFiles.map(
            (
              {
                langFrom,
                langTo,
                translator,
                type,
                indicator,
                dateRealization,
              },
              index,
            ) => (
              <TranslationCell
                key={[langFrom, langTo, type, index].join('')}
                type={type}
                langFrom={langFrom}
                langTo={langTo}
                translator={translator}
                indicator={indicator}
                dateRealization={dateRealization}
              />
            ),
          )}
        </div>
      ) : (
        NO_DATA
      ),
  },
  {
    key: 'isAccepted',
    dataIndex: 'isAccepted',
    title: <FormattedMessage defaultMessage="Przyjęto" />,
    width: 40,
    render: (_, { orderFiles }) => (
      <div className={styles.mappedCellWrapperCenter}>
        {orderFiles.map(({ isAccepted }, index) => (
          <CircleCheckbox
            key={[isAccepted, index].join('')}
            value={isAccepted}
            iconSize="small"
          />
        ))}
      </div>
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Realizacja" />,
    key: 'dateRealization',
    dataIndex: 'dateRealization',
    align: 'center',
    render: (dateRealization) =>
      dateRealization ? dayjs(dateRealization).format('DD.MM') : NO_DATA,
  },
  {
    title: <FormattedMessage defaultMessage="CAT" />,
    key: 'cat',
    dataIndex: 'cat',
    render: (_, { orderFiles }) =>
      orderFiles.length ? (
        <div className={styles.mappedCellWrapper}>
          {orderFiles.map(({ catId }, index) => (
            <CatCell key={[catId, index].join('')} catId={catId} />
          ))}
        </div>
      ) : (
        NO_DATA
      ),
  },
  {
    title: <FormattedMessage defaultMessage="PE" />,
    key: 'pe',
    dataIndex: 'pe',
    render: (_, { orderFiles }) => (
      <div className={styles.mappedCellWrapperCenter}>
        {orderFiles.map(({ isPostedit }, index) => (
          <CircleCheckbox
            key={[isPostedit, index].join('')}
            value={isPostedit}
            iconSize="small"
          />
        ))}
      </div>
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Notatki" />,
    key: 'notes',
    dataIndex: 'notes',
    render: (_, { id, notes }) => <NotesCell orderId={id} notes={notes} />,
  },
  {
    title: <FormattedMessage defaultMessage="Pilne" />,
    key: 'isImportant',
    dataIndex: 'isImportant',
    render: (_, { isImportant }) =>
      isImportant ? (
        <div className={styles.importantWrapper}>
          <ExclamationIcon />
        </div>
      ) : (
        <span />
      ),
  },
  {
    title: <FormattedMessage defaultMessage="Strony" />,
    key: 'pages',
    dataIndex: 'pages',
    align: 'center',
    render: (_, { orderFiles }) => (
      <div className={styles.mappedCellWrapperCenter}>
        {orderFiles.map(({ pagesTranslator }, index) => (
          <span key={[pagesTranslator, index].join('')}>{pagesTranslator}</span>
        ))}
      </div>
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Status" />,
    key: 'status',
    dataIndex: 'status',
    render: (_, { status }) => orderStatusesObject(intl)[status].label,
  },
  {
    title: <FormattedMessage defaultMessage="Klient" />,
    key: 'client',
    dataIndex: 'client',
    render: (_, { isCompany, companyName, contactPerson, clientId }) => (
      <ClientAvatar
        link={ClientRoutes.CLIENT_DETAILS.replace(
          ':clientId',
          clientId?.toString(),
        )}
        isCompany={isCompany}
        name={companyName}
        contactPerson={contactPerson}
      />
    ),
  },
  {
    title: <FormattedMessage defaultMessage="FV" />,
    key: 'hasInvoiceDataConvergence',
    dataIndex: 'hasInvoiceDataConvergence',
    render: (_, { hasInvoiceDataConvergence }) =>
      hasInvoiceDataConvergence ? (
        <div className={styles.importantWrapper}>
          <ExclamationIcon />
        </div>
      ) : (
        <span />
      ),
  },
  {
    title: <FormattedMessage defaultMessage="Akcje" />,
    key: 'actions',
    width: 150,
    align: 'center',
    render: ({ id, status }) =>
      status !== OrderStatus.DONE ? (
        <Space className={styles.tableActions}>
          <Link
            onClick={(e) => e.stopPropagation()}
            to={OrderRoutes.ORDER_ASSIGN.replace(':id', id.toString())}
          >
            <Button type="link" icon={<EditIcon />}>
              <FormattedMessage defaultMessage="Edytuj" />
            </Button>
          </Link>
        </Space>
      ) : (
        <span />
      ),
  },
];
