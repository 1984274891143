import type { PreorderVariantSlice } from 'ducks/view/preorders';
import type { FC } from 'react';
import { Popconfirm, Typography } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/ui/atoms/Button';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setPreorderVariants } from 'ducks/view/preorders';
import { calcVariantTotalRealizationTime } from 'utilities/calcPreorderRealizationTime';
import { calcPreorderVariantPriceTotal } from 'utilities/calcPreorderTotals';
import { calcBrutto } from 'utilities/calcPrice';
import { generateWorkingDaysLabel } from 'utilities/workingDaysLabel';

const { Text } = Typography;

type Props = {
  variant: PreorderVariantSlice;
};

const VariantSectionExtraTitle: FC<Props> = ({ variant }) => {
  const dispatch = useAppDispatch();
  const vatRate = useAppSelector(({ preorders }) => preorders.vatRate);
  const intl = useIntl();

  const handleReset = () => dispatch(setPreorderVariants([]));

  const [netto, brutto] = useMemo(
    () => [
      calcPreorderVariantPriceTotal(variant),
      calcBrutto(calcPreorderVariantPriceTotal(variant), vatRate),
    ],
    [variant, vatRate],
  );

  const systemTimeRealizationTotal = useMemo(
    () => calcVariantTotalRealizationTime(variant),
    [variant],
  );

  return (
    <>
      <Popconfirm
        title={
          <FormattedMessage defaultMessage="Na pewno zresetować wariant?" />
        }
        okText={<FormattedMessage defaultMessage="Tak" />}
        cancelText={<FormattedMessage defaultMessage="Nie" />}
        onConfirm={handleReset}
      >
        <Button danger>
          <FormattedMessage defaultMessage="Reset" />
        </Button>
      </Popconfirm>
      <Text>
        <FormattedMessage
          defaultMessage="{netto} netto ({brutto} brutto)"
          values={{
            netto: <CurrencyFormatter value={netto} />,
            brutto: <CurrencyFormatter value={brutto} />,
          }}
        />
      </Text>
      <Text>
        {!!systemTimeRealizationTotal && (
          <span>
            {generateWorkingDaysLabel({
              timeRealization: systemTimeRealizationTotal,
              suffixLabel: intl.formatMessage({
                defaultMessage: ' (obliczony przez system)',
              }),
            })}
          </span>
        )}
      </Text>
    </>
  );
};

export default VariantSectionExtraTitle;
