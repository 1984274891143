import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { Client } from 'ducks/clients/types';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import TruncateText from 'components/ui/atoms/TruncateText';
import { Routes } from 'views/Clients/Routing';

const { Text } = Typography;

export type ClientRow = TableRow & Client;

type Props = {
  onClientClick?: (client: Client) => void;
};

export const columns = ({ onClientClick }: Props): ColumnsType<ClientRow> => [
  {
    key: 'name',
    dataIndex: 'name',
    title: <FormattedMessage defaultMessage="Nazwa" />,
    render: (_, { id, name }) => (
      <Link
        to={Routes.CLIENT_DETAILS.replace(':clientId', id.toString())}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text>{name}</Text>
      </Link>
    ),
  },
  {
    key: 'phone1',
    dataIndex: 'phone1',
    title: <FormattedMessage defaultMessage="Nr telefonu 1" />,
    render: (_, { phone1 }) => <Text>{phone1}</Text>,
  },
  {
    key: 'phone2',
    dataIndex: 'phone2',
    title: <FormattedMessage defaultMessage="Nr telefonu 2" />,
    render: (_, { phone2 }) => <Text>{phone2}</Text>,
  },
  {
    key: 'address',
    dataIndex: 'address',
    title: <FormattedMessage defaultMessage="Adres" />,
    render: (_, { address }) => (
      <TruncateText maxLength={50}>{address}</TruncateText>
    ),
  },
  {
    key: 'postCode',
    dataIndex: 'postCode',
    title: <FormattedMessage defaultMessage="Kod pocztowy" />,
    render: (_, { postCode }) => <Text>{postCode}</Text>,
  },
  {
    key: 'city',
    dataIndex: 'city',
    title: <FormattedMessage defaultMessage="Miasto" />,
    render: (_, { city }) => <Text>{city}</Text>,
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    width: onClientClick ? 75 : 1,
    render: (_, client) =>
      onClientClick && (
        <Button onClick={() => onClientClick(client)}>
          <FormattedMessage defaultMessage="Wybierz klienta" />
        </Button>
      ),
  },
];
