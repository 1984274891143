import type { ListItem } from './config';
import { Button, Card, Form, Space, notification } from 'antd';
import { isEqual } from 'lodash';
import { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/ui/atoms/Loader';
import FormList from 'components/ui/molecules/FormList';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import { EditIcon, PlusIcon } from 'icons';
import AddPriceItemModal from './components/AddTranslationModal';
import GeneratePriceItemModal from './components/GenerateTranslationsModal';
import { certifiedFormListItems, regularFormListItems } from './config';
import styles from './styles.module.scss';
import useManagePriceList from './useManagePriceList';

const { useForm } = Form;

const PriceList = () => {
  const [form] = useForm<ListItem>();
  const [notify, contextHolder] = notification.useNotification();
  const [isGeneratePriceItemModalOpen, setIsGeneratePriceItemModalOpen] =
    useState(false);
  const [isAddPriceItemModalOpen, setIsAddPriceItemModalOpen] = useState(false);
  const {
    data,
    listItems,
    isDataLoading,
    isLoading,
    handleAdd,
    handleSave,
    handleEdit,
    handleDelete,
    regularPriceList,
    certifiedPriceList,
  } = useManagePriceList(notify);
  const isChanged = useMemo(
    () => !isLoading && !isEqual(listItems, data),
    [listItems, isLoading, data],
  );

  return (
    <>
      <GeneratePriceItemModal
        isOpen={isGeneratePriceItemModalOpen}
        onClose={() => setIsGeneratePriceItemModalOpen(false)}
        listItems={listItems}
        onAdd={handleAdd}
      />
      <AddPriceItemModal
        isOpen={isAddPriceItemModalOpen}
        onClose={() => setIsAddPriceItemModalOpen(false)}
        onAdd={handleAdd}
      />
      <Form form={form} onValuesChange={handleEdit} onFinish={handleSave}>
        {contextHolder}
        <Space direction="vertical" size="large" className="width-100">
          <Card
            bordered={false}
            className={styles.card}
            title={
              <TitleWrapper>
                <FormattedMessage defaultMessage="Cennik" />
                <Space>
                  <Button
                    icon={<EditIcon />}
                    type="primary"
                    loading={isLoading}
                    htmlType="submit"
                    disabled={!isChanged}
                  >
                    <FormattedMessage defaultMessage="Zapisz zmiany" />
                  </Button>
                  <Button
                    type="primary"
                    loading={isLoading}
                    onClick={() => setIsGeneratePriceItemModalOpen(true)}
                  >
                    <FormattedMessage defaultMessage="Generuj tłumaczenia" />
                  </Button>
                  <Button
                    icon={<PlusIcon />}
                    type="primary"
                    loading={isLoading}
                    onClick={() => setIsAddPriceItemModalOpen(true)}
                  >
                    <FormattedMessage defaultMessage="Dodaj" />
                  </Button>
                </Space>
              </TitleWrapper>
            }
          >
            <Loader visible={isLoading} style={{ marginBottom: '30px' }}>
              <Space direction="vertical" size="large" className="width-100">
                <FormList<ListItem>
                  title={
                    <FormattedMessage defaultMessage="Tłumaczenie zwykłe" />
                  }
                  form={form}
                  data={regularPriceList}
                  items={regularFormListItems({ handleDelete })}
                  isLoading={isDataLoading}
                />
                <FormList<ListItem>
                  title={
                    <FormattedMessage defaultMessage="Tłumaczenie przysięgłe" />
                  }
                  form={form}
                  data={certifiedPriceList}
                  items={certifiedFormListItems({ handleDelete })}
                  isLoading={isDataLoading}
                />
              </Space>
            </Loader>
          </Card>
        </Space>
      </Form>
    </>
  );
};

export default PriceList;
