import type { TranslatorRow } from './config';
import type { OrderFileLang } from 'ducks/orders/types';
import type { GetTranslatorsFilter } from 'ducks/translators/types';
import type { FC } from 'react';
import { Input } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/ui/molecules/Modal';
import Table from 'components/ui/molecules/Table';
import { useGetTranslatorsQuery } from 'ducks/translators/service';
import useTablePagination from 'hooks/useTablePagination';
import { columns } from './config';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedTranslatorId: number | null;
  orderFileLang: Pick<
    OrderFileLang,
    'id' | 'langFrom' | 'langTo' | 'isPostedit' | 'catId' | 'orderFileId'
  >;
  filter?: Pick<GetTranslatorsFilter, 'orderId'>;
  onChange: (translatorId: number) => void;
  skip?: boolean;
  autoSelectTranslator?: boolean;
};

const TranslatorsModal: FC<Props> = ({
  isOpen,
  onClose,
  selectedTranslatorId,
  orderFileLang,
  onChange,
  filter,
  skip,
  autoSelectTranslator = false,
}) => {
  const { langFrom, langTo, isPostedit, catId, orderFileId } = orderFileLang;
  const intl = useIntl();
  const { page, handlePageChange, searchValue, searchTerm, handleSearchValue } =
    useTablePagination<TranslatorRow, GetTranslatorsFilter>();
  const { data, isFetching } = useGetTranslatorsQuery(
    {
      page,
      searchTerm,
      filter: {
        ...filter,
        lang: [langFrom, langTo],
        catId: catId ? [catId] : undefined,
        type: isPostedit ? ['is_post_edit'] : undefined,
        status: 1,
        orderFileId,
      },
      sort: null,
    },
    {
      skip: typeof skip === 'undefined' ? !isOpen : skip,
    },
  );
  const pageSize = data?.perPage;
  const total = isFetching ? 0 : data?.total;

  const modifiedData = useMemo(
    (): TranslatorRow[] =>
      (data?.data ?? []).map((translator) => ({
        ...translator,
        key: translator.id,
      })),
    [data?.data],
  );

  const handleSelectTranslator = useCallback(
    (id: number) => {
      onChange(id);

      onClose();
    },
    [onChange, onClose],
  );

  useEffect(() => {
    if (!autoSelectTranslator || modifiedData.length !== 1) return;

    handleSelectTranslator(modifiedData[0].id);
  }, [autoSelectTranslator, modifiedData, handleSelectTranslator]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={<FormattedMessage defaultMessage="Wybierz tłumacza" />}
      okButtonProps={{
        hidden: true,
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.toolbar}>
          <Input
            value={searchValue}
            onChange={(e) => handleSearchValue(e.target.value)}
            placeholder={intl.formatMessage({
              defaultMessage: 'Szukaj',
            })}
            allowClear
          />
        </div>
        <Table
          data={modifiedData}
          columns={columns({
            handleSelectTranslator,
            selectedTranslatorId,
          })}
          isLoading={isFetching}
          pagination={{
            current: page,
            pageSize,
            total,
            onChange: handlePageChange,
          }}
        />
      </div>
    </Modal>
  );
};

export default TranslatorsModal;
