import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { AddPreorderRequest } from 'ducks/preorders/types';
import { FormattedMessage } from 'react-intl';

export type CreatePreorderValues = AddPreorderRequest;

export const initialValues: CreatePreorderValues = {
  email: '',
  companyName: '',
  contactPerson: '',
  clientId: null,
};

export const formItems: FormItemRecord<CreatePreorderValues>[] = [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Imię i nazwisko:" />,
    name: 'contactPerson',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj imię i nazwisko" />,
      },
    ],
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="Email:" />,
    name: 'email',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj email" />,
      },
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
  {
    key: 3,
    label: <FormattedMessage defaultMessage="Nazwa firmy:" />,
    name: 'companyName',
  },
];
