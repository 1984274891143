import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';
import type { ReactNode, FC } from 'react';
import { Tag } from 'antd';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import theme from 'components/layout/Main/styles/values.scss';
import HeaderCard, {
  generateSkeletonHeaderCols,
} from 'components/ui/organisms/HeaderCard';
import { orderStatusesObject } from 'constants/orderStatuses';
import { NO_DATA } from 'constants/strings';
import { useOrdersServices } from 'views/Orders/useOrdersServices';
import { headerCols } from './config';
import OrderPadlock from './OrderPadlock';
import styles from './styles.module.scss';

type Props = {
  children?: ReactNode;
  cols?: HeaderCardCol[];
  hidePrimaryCols?: boolean;
  showUnblockButton?: boolean;
};

const OrderHeader: FC<Props> = ({
  children,
  cols = [],
  hidePrimaryCols = false,
  showUnblockButton = false,
}) => {
  const intl = useIntl();
  const { orderData, isOrderLoading } = useOrdersServices();
  const showStatus = orderData?.status !== undefined;
  const status = showStatus
    ? orderStatusesObject(intl)[orderData.status].label
    : NO_DATA;

  const primaryCols = useMemo(
    () =>
      orderData
        ? headerCols({ intl, data: orderData })
        : generateSkeletonHeaderCols(4),
    [intl, orderData],
  );

  return (
    <HeaderCard
      cols={hidePrimaryCols ? cols : [...primaryCols, ...cols]}
      isLoading={isOrderLoading}
      startChildren={
        <div className={styles.preColumnChildrenWrapper}>
          <span className={styles.padlockIconWrapper}>
            <OrderPadlock
              activeEditorId={orderData?.activeEditorId}
              showUnblockButton={showUnblockButton}
            />
          </span>
          <span className={styles.number} data-testid="number">
            {orderData?.number ?? NO_DATA}
          </span>
          <Tag
            className={styles.status}
            color={theme.colorBlue}
            data-testid="status"
          >
            {status}
          </Tag>
        </div>
      }
    >
      {children}
    </HeaderCard>
  );
};

export default OrderHeader;
