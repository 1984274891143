import { notification } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DownloadPreorderZipFilesButton from 'components/ui/molecules/DownloadPreorderZipFilesButton';
import Table from 'components/ui/molecules/Table';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import { useOrderTranslationFilesServices } from 'views/Orders/views/OrderTranslationFiles';
import { useOrderDetailsServices } from '../../useOrderDetailsServices';
import { columns } from './config';

const LangFilesTable = () => {
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const { orderData, isOrderLoading } = useOrderDetailsServices();
  const { orderTranslationFilesData } = useOrderTranslationFilesServices();
  const preorderId = orderData?.preorder?.id;

  const modifiedData = useMemo(
    () =>
      orderData?.orderFiles.map((variantFile) => ({
        key: variantFile.id,
        ...variantFile,
      })) ?? [],
    [orderData?.orderFiles],
  );

  return (
    <>
      {contextHolder}
      <Table
        data-testid="langFilesTable"
        cardTitle={
          <TitleWrapper>
            <FormattedMessage defaultMessage="Pliki" />
            {!!modifiedData.length && (
              <DownloadPreorderZipFilesButton
                notify={notify}
                preorderId={preorderId}
                fileName={intl.formatMessage(
                  {
                    defaultMessage: 'zamowienie_{name}',
                  },
                  { name: orderData?.number ?? '' },
                )}
              />
            )}
          </TitleWrapper>
        }
        columns={columns({
          orderFiles: orderTranslationFilesData?.orderFiles ?? [],
          orderLangs: orderData?.orderFileLangs ?? [],
        })}
        data={modifiedData}
        isLoading={isOrderLoading}
        hidePagination
      />
    </>
  );
};

export default LangFilesTable;
