import type { SendClientEmailsValues } from '../../views/OrderClientSend/config';
import type { FormInstance } from 'antd';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import EmailTemplateTextEditor from 'components/ui/organisms/EmailTemplateTextEditor';
import { DayjsFormat } from 'constants/dayjsFormats';
import {
  mappedTemplateSnippets,
  TemplateSnippet,
  templateSnippets,
} from 'constants/template';
import { useGetLanguagesQuery } from 'ducks/global/service';
import { useAppSelector } from 'ducks/store';
import { calcBrutto } from 'utilities/calcPrice';
import {
  generateEmailSnippetAdditionalServices,
  generateEmailSnippetOrderFileList,
  generateEmailSnippetPreorderVariantsNumber,
  generateEmailSnippetTranslatorFileList,
  getEmailSnippetRealizationDate,
} from 'utilities/emailTemplateSnippetValues';
import { formatPrice } from 'utilities/price';
import { useOrderClientSendServices } from '../../views/OrderClientSend/useOrderClientSendServices';

type SnippetValues = Record<TemplateSnippet, string>;

type Props = {
  form: FormInstance<SendClientEmailsValues>;
};

const OrderEmailTemplateEdtiorSection: FC<Props> = ({ form }) => {
  const intl = useIntl();
  const { emailTemplate, orderFiles, orderServices } = useAppSelector(
    ({ orders }) => ({
      emailTemplate: orders.sendClientEmail.emailTemplate,
      orderFiles: orders.orderFiles,
      orderServices: orders.orderServices,
    }),
  );
  const { orderData, isLoading, orderTranslationFilesData } =
    useOrderClientSendServices();
  const { data: languages = [] } = useGetLanguagesQuery();

  const translationsAndOrdersFiles = useMemo(
    () => orderTranslationFilesData?.translationsAndOrdersFiles ?? [],
    [orderTranslationFilesData?.translationsAndOrdersFiles],
  );

  const filteredOrderFiles = useMemo(() => {
    if (!orderTranslationFilesData?.orderFiles) return [];

    return orderTranslationFilesData.orderFiles.map(
      ({ orderFileLangs, ...orderFile }) => ({
        ...orderFile,
        orderFileLangs: orderFileLangs.filter(
          ({ orderTranslationFiles }) => !!orderTranslationFiles.length,
        ),
      }),
    );
  }, [orderTranslationFilesData?.orderFiles]);

  const totalPriceOfFilteredOrderFiles = useMemo(
    () =>
      filteredOrderFiles.reduce(
        (acc, { orderFileLangs }) =>
          orderFileLangs.reduce(
            (acc2, { orderTranslationFiles, translatorPriceNetto }) =>
              orderTranslationFiles.reduce(
                (acc3) => acc3 + translatorPriceNetto,
                acc2,
              ),
            acc,
          ),
        0,
      ),
    [filteredOrderFiles],
  );

  const snippetValues = useMemo((): SnippetValues | undefined => {
    if (!orderData) return undefined;
    const {
      number,
      vatRate,
      priceNetto,
      dateRealization,
      onlinePaymentUrl,
      preorderVariant,
    } = orderData;
    const nettoValue = formatPrice(priceNetto);
    const bruttoValue = formatPrice(calcBrutto(priceNetto, vatRate));
    const translatorFileList = generateEmailSnippetTranslatorFileList(
      intl,
      filteredOrderFiles,
      translationsAndOrdersFiles,
    );
    const fileList = generateEmailSnippetOrderFileList({
      intl,
      vatRate,
      orderFiles,
      languages,
    });
    const additionalServices = generateEmailSnippetAdditionalServices({
      intl,
      from: 'order',
      services: orderServices,
    });
    const realizationDate = dayjs(dateRealization).format(DayjsFormat.DATE);
    const preorderRealizationDate = getEmailSnippetRealizationDate(
      intl,
      preorderVariant?.timeRealization ?? 0,
    );
    const preorderVariantsNumber = generateEmailSnippetPreorderVariantsNumber(
      intl,
      1,
    );

    return {
      ...mappedTemplateSnippets,
      [TemplateSnippet.NUMBER]: number,
      [TemplateSnippet.PAYMENT_LINK]: onlinePaymentUrl,
      [TemplateSnippet.FILE_LIST]: fileList,
      [TemplateSnippet.NETTO_VALUE]: nettoValue,
      [TemplateSnippet.BRUTTO_VALUE]: bruttoValue,
      [TemplateSnippet.REALIZATION_DATE]: realizationDate,
      [TemplateSnippet.TRANSLATOR_FILE_LIST]: translatorFileList,
      [TemplateSnippet.TRANSLATOR_NETTO_VALUE]: formatPrice(
        totalPriceOfFilteredOrderFiles,
      ),
      [TemplateSnippet.ADDITIONAL_SERVICES]: additionalServices,
      [TemplateSnippet.PREORDER_REALIZATION_DATE]: preorderRealizationDate,
      [TemplateSnippet.PREORDER_VARIANTS_NUMBER]: preorderVariantsNumber,
    };
  }, [
    orderData,
    filteredOrderFiles,
    translationsAndOrdersFiles,
    totalPriceOfFilteredOrderFiles,
    orderFiles,
    orderServices,
    languages,
    intl,
  ]);

  const modifiedEmailTemplate = useMemo(() => {
    if (!snippetValues) return emailTemplate;

    return templateSnippets.reduce(
      (accumulator, currentValue) =>
        accumulator.replaceAll(currentValue, snippetValues[currentValue]),
      emailTemplate,
    );
  }, [emailTemplate, snippetValues]);

  return (
    <EmailTemplateTextEditor
      form={form}
      emailTemplate={modifiedEmailTemplate}
      isLoading={isLoading}
    />
  );
};

export default OrderEmailTemplateEdtiorSection;
