/* eslint-disable react-hooks/exhaustive-deps */
import type { EditTranslatorValues } from '../../config';
import type { FormInstance } from 'antd';
import type { FC } from 'react';
import { Checkbox, Col, Form, Row, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/ui/atoms/FormItem';
import { useGetTranslatorsCatsQuery } from 'ducks/translators/service';
import { CAT_PHRASE_ID } from 'views/Translators/config';
import styles from './styles.module.scss';

interface Props {
  form: FormInstance<EditTranslatorValues>;
}

const { Item, useWatch } = Form;

const CatToolsSection: FC<Props> = ({ form }) => {
  const { data: catsData = [] } = useGetTranslatorsCatsQuery();
  const catTools = catsData.filter(
    ({ id, isMain }) => !isMain || id === CAT_PHRASE_ID,
  );
  const otherCheckbox = !!useWatch('otherCheckbox', form);

  return (
    <Col span={24} md={12} lg={12} xl={12} className={styles.col}>
      <h6>
        <FormattedMessage defaultMessage="Znajomość narzędzi CAT" />
      </h6>
      <Space direction="vertical">
        <Space direction="vertical" size={0}>
          <Item name="catsTools">
            <Checkbox.Group>
              <Row>
                {catTools.map(({ id, name }) => (
                  <Col key={id} span={12}>
                    <Checkbox value={id}>{name}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Item>
          <Row>
            <Col span={12}>
              <Item name="otherCheckbox" valuePropName="checked">
                <Checkbox>Inne</Checkbox>
              </Item>
            </Col>
          </Row>
        </Space>
        {otherCheckbox && (
          <FormItem
            label={<FormattedMessage defaultMessage="Inne" />}
            name="catOther"
          />
        )}
      </Space>
    </Col>
  );
};

export default CatToolsSection;
