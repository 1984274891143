import { round } from 'lodash';
import {
  PRICE_LIST_CORRECTION_MULTIPLIER,
  PRICE_LIST_REDACTION_MULTIPLIER,
  PRICE_LIST_SWORN_MULTIPLIER,
  PRICE_LIST_VERIFICATION_MULTIPLIER,
} from 'constants/priceListMultipliers';

type MultiplierPricesProps = {
  normalPrice: number;
};

const SPECIALIZED_MULTIPLIER = 1.4;
const TO_FIXED = 2;
export const SPECIALIZED = 'Specialized';

export const multiplierPrices = ({ normalPrice }: MultiplierPricesProps) => {
  const sworn = round(normalPrice * PRICE_LIST_SWORN_MULTIPLIER, TO_FIXED);
  const correction = round(
    normalPrice * PRICE_LIST_CORRECTION_MULTIPLIER,
    TO_FIXED,
  );
  const redaction = round(
    normalPrice * PRICE_LIST_REDACTION_MULTIPLIER,
    TO_FIXED,
  );
  const verification = round(
    normalPrice * PRICE_LIST_VERIFICATION_MULTIPLIER,
    TO_FIXED,
  );

  return {
    sworn,
    correction,
    redaction,
    verification,
  };
};

export const generateSpecializedPrice = (price: number) => {
  return Number((price * SPECIALIZED_MULTIPLIER).toFixed(TO_FIXED));
};
