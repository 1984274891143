import { memo } from 'react';
import { MAX_DISCOUNT_VALUE } from 'constants/formInputLengths';
import { useAppDispatch } from 'ducks/store';
import { updatePreorderVariantFile } from 'ducks/view/preorders';
import SelectorInput, { SelectorWideType } from '../SelectorInput';
import { generateDiscountOptions } from './utils';

type Props = {
  variantId: string | number;
  variantFileId: string | number;
  variantLangId: string | number;
  value: number;
};

const DiscountSelector = memo(
  ({ variantId, variantFileId, variantLangId, value }: Props) => {
    const dispatch = useAppDispatch();
    const generatedOptions = generateDiscountOptions();
    const options = [
      // Filter is for showing characters value instead of label
      ...generatedOptions.filter((option) => option.value !== value),
      { value, label: value },
    ];

    const handleUpdateDiscount = (discount: number) => {
      dispatch(
        updatePreorderVariantFile({
          variantId,
          field: {
            [variantFileId]: {
              [variantLangId]: { discount },
            },
          },
        }),
      );
    };

    const handleSelectSearch = (newValue: string) => {
      const numberValue = Number(newValue);

      if (
        !newValue ||
        Number.isNaN(numberValue) ||
        numberValue > MAX_DISCOUNT_VALUE
      )
        return;

      handleUpdateDiscount(numberValue);
    };

    const handleSelect = (newValue: number) => handleUpdateDiscount(newValue);

    return (
      <SelectorInput
        dataTestId={`discountInput-${variantLangId}`}
        value={value}
        type={SelectorWideType.TWO_DIGIT}
        onSearch={handleSelectSearch}
        onSelect={handleSelect}
        options={options}
      />
    );
  },
);

export default DiscountSelector;
