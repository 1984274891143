/* eslint-disable react/jsx-props-no-spreading */
import type { FC, ComponentPropsWithoutRef } from 'react';

import { Button as AntdButton } from 'antd';
import cn from 'classnames';
import styles from './styles.module.scss';

interface Props extends ComponentPropsWithoutRef<typeof AntdButton> {
  fullWidth?: boolean;
  withoutTruncate?: boolean;
}

const Button: FC<Props> = ({
  children,
  type = 'primary',
  fullWidth = false,
  withoutTruncate = false,
  className,
  ...props
}) => {
  return (
    <AntdButton
      type={type}
      className={cn(
        styles.button,
        { [styles.buttonTruncate]: !withoutTruncate, 'width-100': fullWidth },
        className,
      )}
      {...props}
    >
      <span>{children}</span>
    </AntdButton>
  );
};

export default Button;
