import { TranslatorsFilterPriceTypes } from 'ducks/translators/types';

export const generatePriceListFilters = (
  isSpecial: boolean,
  isCorrection: boolean,
): TranslatorsFilterPriceTypes[] => {
  const filtersArray = [TranslatorsFilterPriceTypes.NORMAL];

  if (isCorrection) filtersArray.push(TranslatorsFilterPriceTypes.CORRECTION);

  if (isSpecial) filtersArray.push(TranslatorsFilterPriceTypes.SPECIAL);

  return filtersArray;
};
