import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';

export const skeletonHeaderCols: HeaderCardCol[] = [
  {
    key: 1,
    firstRow: <span />,
    secondRow: <span />,
  },
  {
    key: 2,
    firstRow: <span />,
    secondRow: <span />,
  },
  {
    key: 3,
    firstRow: <span />,
  },
];

export const headerCols = (): HeaderCardCol[] => [];
