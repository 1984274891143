import { useEffect } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import ClientsRouting from 'views/Clients/Routing';
import PreorderCalculation from 'views/Draft/views/PreorderCalculation';
import FinancesRouting from 'views/Finances/Routing';
import InvoicesRouting from 'views/Invoices/Routing';
import OrdersRouting from 'views/Orders/Routing';
import PreordersRouting from 'views/Preorders/Routing';
import PricesRouting from 'views/Prices/Routing';
import SettingsRouting from 'views/Settings/Routing';
import Sms from 'views/Sms';
import TranslatorsRouting from 'views/Translators/Routing';
import Users from 'views/Users';

export enum Routes {
  PROJECTS = '/projects',
  PREORDERS = '/preorders',
  TRANSLATORS = '/translators',
  CLIENTS = '/clients',
  USERS = '/users',
  PRICES = '/prices',
  SMS = '/sms',
  SETTINGS = '/settings',
  FINANCES = '/finances',
  INVOICES = '/invoices',
  DRAFT = '/draft',
}

const Routing = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AnimatedRoutes pathLevelWatch={1}>
      <Route path={`${Routes.PROJECTS}/*`} element={<OrdersRouting />} />
      <Route path={`${Routes.PREORDERS}/*`} element={<PreordersRouting />} />
      <Route
        path={`${Routes.TRANSLATORS}/*`}
        element={<TranslatorsRouting />}
      />
      <Route path={`${Routes.USERS}/*`} element={<Users />} />
      <Route path={`${Routes.CLIENTS}/*`} element={<ClientsRouting />} />
      <Route path={`${Routes.PRICES}/*`} element={<PricesRouting />} />
      <Route path={`${Routes.SMS}/*`} element={<Sms />} />
      <Route path={`${Routes.FINANCES}/*`} element={<FinancesRouting />} />
      <Route path={`${Routes.SETTINGS}/*`} element={<SettingsRouting />} />
      <Route path={`${Routes.INVOICES}/*`} element={<InvoicesRouting />} />
      <Route path={`${Routes.DRAFT}/*`} element={<PreorderCalculation />} />
      <Route index element={<Navigate replace to={Routes.PROJECTS} />} />
    </AnimatedRoutes>
  );
};

export default Routing;
