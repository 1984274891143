import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetInvoiceLetterSummonQuery,
  useGetInvoiceOverdueQuery,
} from 'ducks/invoices/service';

export const useInvoiceOverdueDetailsServices = () => {
  const { id = '' } = useParams();
  const {
    data: invoiceOverdueData,
    isLoading: isInvoiceOverdueLoading,
    isFetching: isInvoiceOverdueFetching,
  } = useGetInvoiceOverdueQuery({
    id,
  });
  const {
    data: invoiceLetterSummonData = [],
    isFetching: isInvoiceLetterSummonFetching,
  } = useGetInvoiceLetterSummonQuery(
    {
      id,
    },
    { skip: !invoiceOverdueData },
  );
  const isInvoiceOverdueDataEmpty =
    !invoiceOverdueData && !isInvoiceOverdueFetching;

  const value = useMemo(
    () => ({
      invoiceOverdueData,
      isInvoiceOverdueLoading,
      isInvoiceOverdueFetching,
      isInvoiceOverdueDataEmpty,
      invoiceLetterSummonData,
      isInvoiceLetterSummonFetching,
    }),
    [
      invoiceOverdueData,
      isInvoiceOverdueLoading,
      isInvoiceOverdueFetching,
      isInvoiceOverdueDataEmpty,
      invoiceLetterSummonData,
      isInvoiceLetterSummonFetching,
    ],
  );

  return value;
};
