import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import { Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { validateDiscountRule } from 'utilities/antdFormRules';

export type DiscountModalValues = {
  discount: number;
};

export type DiscountModalInput = FormItemRecord<DiscountModalValues>;

export const discountModalInputs: DiscountModalInput[] = [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Rabat" />,
    name: 'discount',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj rabat" />,
      },
      validateDiscountRule(),
    ],
    component: <Input type="number" addonAfter="%" />,
  },
];
