/* eslint-disable no-plusplus */
export const NIP_REGEX = /^\d{10}$/;
export const INTERNATIONAL_NIP_REGEX = /.+/;
export const REGON_REGEX = /^[0-9]{9,14}$/u;
export const PESEL_REGEX = /^[0-9]{11}$/u;

export const isValidNip = (nip: string, isInternational = false) => {
  const nipRegex = isInternational ? INTERNATIONAL_NIP_REGEX : NIP_REGEX;

  if (isInternational && nipRegex.test(nip)) return true;
  if (!nipRegex.test(nip)) return false;

  const cleanedNIP = nip.replace(/\D/g, '');
  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  const weightCount = weight.length;
  let sum = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(cleanedNIP.charAt(i), 10) * weight[i];
  }

  const controlNumber = sum % 11 === 10 ? 0 : sum % 11;
  const lastDigit = parseInt(cleanedNIP.charAt(9), 10);

  return controlNumber === lastDigit;
};

const checksum = (number: string, weight: number[]) => {
  const max = number.length - 1;
  let sum = 0;

  for (let i = 0; i < max; ++i) {
    const n = parseInt(number.charAt(i), 10);
    sum += n * (weight[i] as number);
  }

  const control = sum % 11;
  const resultSum = control === 10 ? 0 : control;
  const lastDigit = parseInt(number.slice(-1), 10);

  return resultSum === lastDigit;
};

export const isValidRegon = (regon: string) => {
  if (!REGON_REGEX.test(regon)) {
    return false;
  }

  const weight9 = [8, 9, 2, 3, 4, 5, 6, 7];

  if (regon.length === 9) {
    return checksum(regon, weight9);
  }

  const weight14 = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];

  return checksum(regon.slice(0, 9), weight14) && checksum(regon, weight14);
};

export const isValidPesel = (pesel: string) => {
  const monthWithCentury = Number(pesel.substring(2, 4));

  if (!monthWithCentury || monthWithCentury % 20 > 12) {
    return false;
  }

  const day = Number(pesel.substring(4, 6));
  if (!day || day < 1 || day > 31) {
    return false;
  }

  if (!PESEL_REGEX.test(pesel)) {
    return false;
  }

  const times = [1, 3, 7, 9];

  const digits = `${pesel}`.split(``).map((digit) => {
    return parseInt(digit, 10);
  });

  const [dig11] = digits.splice(-1);

  const control =
    digits.reduce((previousValue, currentValue, index) => {
      return previousValue + currentValue * (times[index % 4] as number);
    }) % 10;

  return 10 - (control === 0 ? 10 : control) === dig11;
};
