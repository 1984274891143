import type { NotificationInstance } from 'antd/es/notification/interface';
import type { FC } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import List from 'components/ui/molecules/List';
import { useClientDetailsServices } from '../../useClientDetailsServices';
import CardCommon from '../CardCommon';
import EditInvoiceModal from './components/EditInvoiceModal';
import { invoiceListItems } from './config';

type Props = {
  notify: NotificationInstance;
};

const InvoiceForm: FC<Props> = ({ notify }) => {
  const [isEditInvoiceModalOpen, setIsEditInvoiceModalOpen] = useState(false);
  const { clientData, isClientFetching } = useClientDetailsServices();

  return (
    <>
      {clientData && (
        <EditInvoiceModal
          isOpen={isEditInvoiceModalOpen}
          onClose={() => setIsEditInvoiceModalOpen(false)}
          data={clientData}
          notify={notify}
        />
      )}
      <CardCommon
        title={<FormattedMessage defaultMessage="Dane rozliczeniowe" />}
        isLoading={isClientFetching}
        openEditModal={() => setIsEditInvoiceModalOpen(true)}
      >
        <List
          items={invoiceListItems(clientData)}
          isLoading={isClientFetching}
        />
      </CardCommon>
    </>
  );
};

export default InvoiceForm;
