import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrderTranslationFilesQuery } from 'ducks/orders/service';
import { useOrdersServices } from 'views/Orders/useOrdersServices';

export const useOrderClientSendServices = () => {
  const { id = '' } = useParams();
  const { orderData, isOrderFetching, refetch } = useOrdersServices();
  const {
    data: orderTranslationFilesData,
    isFetching: isOrderTranslationFilesFetching,
  } = useGetOrderTranslationFilesQuery({
    id,
  });
  const isOrderTranslationFilesEmpty =
    !orderTranslationFilesData &&
    !isOrderTranslationFilesFetching &&
    !isOrderFetching;
  const isLoading = isOrderFetching || isOrderTranslationFilesFetching;

  const value = useMemo(
    () => ({
      orderData,
      isOrderFetching,
      refetch,
      orderTranslationFilesData,
      isOrderTranslationFilesFetching,
      isOrderTranslationFilesEmpty,
      isLoading,
    }),
    [
      orderData,
      isOrderFetching,
      refetch,
      orderTranslationFilesData,
      isOrderTranslationFilesFetching,
      isOrderTranslationFilesEmpty,
      isLoading,
    ],
  );

  return value;
};
