import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import { Input, Select, Switch } from 'antd';
import { capitalize } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { UserRole, type AddUserRequest } from 'ducks/users/types';
import {
  validatePasswordConfirmationRule,
  validatePasswordRule,
} from 'utilities/antdFormRules';
import { mapEnum } from 'utilities/mapEnum';

export type AddUserModalValues = AddUserRequest;

export type AddUserModalFormItem = FormItemRecord<AddUserModalValues>;

export const addUserModalFormItems: AddUserModalFormItem[] = [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Imię" />,
    name: 'firstName',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj imię" />,
      },
    ],
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="Nazwisko" />,
    name: 'lastName',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj nazwisko" />,
      },
    ],
  },
  {
    key: 3,
    label: <FormattedMessage defaultMessage="E-mail" />,
    name: 'email',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj email" />,
      },
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
  {
    key: 4,
    label: <FormattedMessage defaultMessage="Hasło" />,
    name: 'password',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj hasło" />,
      },
      validatePasswordRule(),
    ],
    component: <Input.Password />,
  },
  {
    key: 5,
    label: <FormattedMessage defaultMessage="Powtórz hasło" />,
    name: 'passwordConfirmation',
    rules: [
      {
        required: true,
        message: (
          <FormattedMessage defaultMessage="Uzupełnij pole powtórz hasło" />
        ),
      },
      validatePasswordConfirmationRule(),
    ],
    component: <Input.Password />,
  },
  {
    key: 6,
    label: <FormattedMessage defaultMessage="Rola pracownika" />,
    name: 'role',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz rolę" />,
      },
    ],
    component: (
      <Select
        options={mapEnum(UserRole).map(({ key, value }) => ({
          value,
          label: capitalize(key),
        }))}
        allowClear
      />
    ),
  },
  {
    key: 7,
    label: <FormattedMessage defaultMessage="Aktywność" />,
    name: 'isActive',
    additionalItemProps: { valuePropName: 'checked' },
    component: <Switch />,
  },
];
