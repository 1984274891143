import type { ModalProps } from 'antd';
import type { FC, ReactElement } from 'react';
import { Modal, Space, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/ui/atoms/Loader';

const MODAL_WIDTH = 420;

const { Text } = Typography;

type Props = ModalProps & {
  description?: ReactElement | string;
  isLoading?: boolean;
  onClose: () => void;
  onOk: () => void;
};

const ConfirmModal: FC<Props> = ({
  children,
  description,
  isLoading = false,
  onClose,
  onOk,
  okButtonProps,
  cancelButtonProps,
  ...props
}) => {
  return (
    <Modal
      closable={false}
      width={MODAL_WIDTH}
      okText={<FormattedMessage defaultMessage="Zapisz" />}
      okButtonProps={{ disabled: isLoading, ...okButtonProps }}
      onOk={onOk}
      cancelText={<FormattedMessage defaultMessage="Anuluj" />}
      cancelButtonProps={{ disabled: isLoading, ...cancelButtonProps }}
      onCancel={onClose}
      {...props}
    >
      <Loader visible={isLoading}>
        <Space className="width-100" direction="vertical" size="middle">
          {description && <Text type="secondary">{description}</Text>}
          {children}
        </Space>
      </Loader>
    </Modal>
  );
};

export default ConfirmModal;
