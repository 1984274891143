import type { IntlShape } from 'react-intl';
import { PreorderConstants } from 'constants/preorderConstants';
import { createArray } from 'utilities/array';

type GenerateCharacterOptionsInput = {
  isSworn?: boolean;
  intl: IntlShape;
};

export const generateCharacterOptions = ({
  isSworn = false,
  intl,
}: GenerateCharacterOptionsInput) => {
  const charactersPerPage = isSworn
    ? PreorderConstants.PAGES_SWORN_MULTIPLIER
    : PreorderConstants.PAGES_NORMAL_MULTIPLIER;

  const options = createArray(10).map((number) => {
    const value = number * charactersPerPage;

    return {
      label: intl.formatMessage(
        { defaultMessage: 'Stron {number}' },
        { number },
      ),
      value,
    };
  });

  return options;
};
