import type { FC } from 'react';
import { Popconfirm } from 'antd';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import { PreorderStatus } from 'constants/preorder';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import {
  copyPreorderVariant,
  deletePreorderVariant,
} from 'ducks/view/preorders';
import { TrashIcon } from 'icons';
import { Routes } from 'views/Preorders/Routing';
import { usePreorderDetailsServices } from 'views/Preorders/views/PreorderDetails';

type Props = {
  preorderVariantId: number | string;
};

const VariantPanelHeaderActions: FC<Props> = memo(({ preorderVariantId }) => {
  const { id: preorderId = '' } = useParams();
  const dispatch = useAppDispatch();
  const { isEditable, isSendMode } = useAppSelector((state) => ({
    isEditable: state.preorders.isEditable,
    isSendMode: state.preorders.isSendMode,
  }));
  const { preorderData } = usePreorderDetailsServices();
  const isSentStatus = preorderData?.status === PreorderStatus.SENT;

  const handleCopyPreorderVariant = () =>
    dispatch(copyPreorderVariant(preorderVariantId));

  const handleDeleteVariant = () =>
    dispatch(deletePreorderVariant(preorderVariantId));

  if (isSendMode) return null;

  if (isEditable) {
    return (
      <>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleCopyPreorderVariant();
          }}
        >
          <FormattedMessage defaultMessage="Skopiuj wariant" />
        </Button>
        <Popconfirm
          title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
          okText={<FormattedMessage defaultMessage="Tak" />}
          cancelText={<FormattedMessage defaultMessage="Nie" />}
          onConfirm={(e) => {
            e?.stopPropagation();
            handleDeleteVariant();
          }}
        >
          <Button
            onClick={(e) => e.stopPropagation()}
            icon={<TrashIcon />}
            danger
          >
            <FormattedMessage defaultMessage="Usuń wariant" />
          </Button>
        </Popconfirm>
      </>
    );
  }

  if (isSentStatus && !isEditable) {
    return (
      <Link
        to={Routes.PREORDER_REALIZATION.replace(':id', preorderId).replace(
          ':variantId',
          preorderVariantId.toString(),
        )}
      >
        <Button>
          <FormattedMessage defaultMessage="Przejdź do realizacji" />
        </Button>
      </Link>
    );
  }

  return null;
});

export default VariantPanelHeaderActions;
