import type { FC, ReactNode } from 'react';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import Empty from 'components/ui/atoms/Empty';
import { usePreordersServices } from 'views/Preorders/usePreordersServices';
import PreorderHeader from '../PreorderHeader';

type Props = {
  children: ReactNode;
};

const PreorderCurrentStatus: FC<Props> = ({ children }) => {
  const { isPreorderEmpty, isPreorderFetching, isCurrentlyEdited } =
    usePreordersServices();

  if (isPreorderEmpty)
    return (
      <Empty
        data-testid="noDataState"
        description={
          <FormattedMessage defaultMessage="Brak danych o wycenie" />
        }
      />
    );

  if (isCurrentlyEdited && !isPreorderFetching) {
    return (
      <Space direction="vertical" size="large" className="width-100">
        <PreorderHeader />
        <Empty
          data-testid="currentlyEditedState"
          description={
            <FormattedMessage defaultMessage="Wycena jest aktualnie w edycji przez innego użytkownika" />
          }
        />
      </Space>
    );
  }

  return (
    <Space direction="vertical" size="large" className="width-100">
      {children}
    </Space>
  );
};

export default PreorderCurrentStatus;
