/* eslint-disable react/jsx-props-no-spreading */
import type { FormListData, FormListItemRecord } from './config';
import type { FormInstance } from 'antd';
import type { ReactElement, ReactNode } from 'react';
import { Empty, Pagination, Space, Typography } from 'antd';
import { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { createArray } from 'utilities/array';
import { FormListRow } from './components/FormListRow';
import styles from './styles.module.scss';

interface Props<TData> {
  children?: (items: FormListItemRecord<TData>[]) => ReactNode;
  form: FormInstance<TData>;
  data: FormListData<TData>[];
  isLoading?: boolean;
  items: FormListItemRecord<TData>[];
  title?: ReactElement | string;
  skeletonRows?: number;
  showEmpty?: boolean;
  showPagination?: boolean;
  withoutHead?: boolean;
}

export const PAGE_SIZE = 10;

const { Text } = Typography;

const FormList = <TData,>({
  children,
  form,
  data,
  isLoading = false,
  items,
  title,
  showEmpty = true,
  skeletonRows = 2,
  showPagination = false,
  withoutHead = false,
  ...props
}: Props<TData>) => {
  const [page, setPage] = useState(1);
  const isEmpty = !data.length && !isLoading;
  const isFetched = !!data.length && !isLoading;
  const dataSource = isLoading
    ? (createArray(skeletonRows).map((number) => ({
        id: number,
      })) as FormListData<TData>[])
    : data;
  const isPaginationVisible =
    showPagination && isFetched && dataSource.length > PAGE_SIZE;

  const dataPerPage = useMemo(() => {
    const firstPageIndex = (page - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;

    if (!showPagination) return dataSource;

    return dataSource.slice(firstPageIndex, lastPageIndex);
  }, [dataSource, page, showPagination]);

  return (
    <Space {...props} direction="vertical" size="middle" className="width-100">
      {title && <Text strong>{title}</Text>}
      <Space direction="vertical" size="large" className={styles.formSpace}>
        <table className={styles.table}>
          {!withoutHead && (
            <thead className={styles.thead}>
              <tr>
                {items.map(({ key, title: itemTitle }) => (
                  <th key={key} scope="col">
                    <Text
                      strong
                      type="secondary"
                      ellipsis
                      className={styles.colTitle}
                    >
                      {itemTitle}
                    </Text>
                  </th>
                ))}
              </tr>
            </thead>
          )}
          {!isEmpty && children?.(items)}
          {!isEmpty && !children && (
            <tbody>
              {dataPerPage.map((item) => {
                const { id } = item;
                form.setFieldValue(id, item);

                return (
                  <FormListRow
                    key={id}
                    data={item}
                    items={items}
                    isLoading={isLoading}
                  />
                );
              })}
            </tbody>
          )}
        </table>
        {isEmpty && showEmpty && (
          <Empty
            className={styles.empty}
            description={<FormattedMessage defaultMessage="Brak danych" />}
          />
        )}
      </Space>
      {isPaginationVisible && (
        <div className={styles.paginationWrapper}>
          <Pagination
            current={page}
            onChange={(pageSize) => setPage(pageSize)}
            pageSize={PAGE_SIZE}
            defaultCurrent={1}
            total={data.length}
          />
        </div>
      )}
    </Space>
  );
};

export default FormList;
