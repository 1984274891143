import { Row, notification } from 'antd';
import { useEffect } from 'react';
import OrderFilesAndServicesTable from 'components/ui/organisms/Tables/OrderFilesAndServicesTable';
import { useAppDispatch } from 'ducks/store';
import {
  setOrderIsEditable,
  resetOrdersState,
  setOrderFilesAndServices,
  setOrderIsImportant,
} from 'ducks/view/orders';
import OrderCurrentStatus from 'views/Orders/components/OrderCurrentStatus';
import OrderNotesSection from 'views/Orders/components/OrderNotesSection';
import ActionsSection from './components/ActionsSection';
import OrderAssignHeader from './components/OrderAssignHeader';
import UploadFileAndSummarySection from './components/UploadFileAndSummarySection';
import { useOrderAssignServices } from './useOrderAssignServices';

const OrderAssign = () => {
  const [notify, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const { orderData, isOrderLoading, refetch } = useOrderAssignServices();

  useEffect(() => {
    dispatch(setOrderIsEditable(true));

    if (orderData) {
      const { isImportant } = orderData;

      dispatch(setOrderFilesAndServices(orderData));
      dispatch(setOrderIsImportant(isImportant));
    }

    return () => {
      dispatch(resetOrdersState());
    };
  }, [orderData, dispatch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="layout-content mb-24">
      {contextHolder}
      <OrderCurrentStatus>
        <OrderAssignHeader />
        <Row gutter={[24, 24]}>
          <UploadFileAndSummarySection notify={notify} />
          <OrderNotesSection
            notify={notify}
            colProps={{ lg: 10 }}
            scroll={false}
          />
        </Row>
        <OrderFilesAndServicesTable
          isLoading={isOrderLoading}
          showAssignAllFiles
        />
        <ActionsSection notify={notify} />
      </OrderCurrentStatus>
    </div>
  );
};

export default OrderAssign;
