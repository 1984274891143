import type { NotificationInstance } from 'antd/es/notification/interface';
import type { FC } from 'react';
import { Button, Popconfirm, Timeline, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSanctum } from 'react-sanctum';
import theme from 'components/layout/Main/styles/values.scss';
import FormatDate from 'components/ui/atoms/FormatDate/FormatDate';
import Loader from 'components/ui/atoms/Loader/Loader';
import { useDeleteNoteMutation } from 'ducks/notes/service';
import { NotesCategory, type Note, type NoteType } from 'ducks/notes/types';
import { EditIcon, TrashIcon } from 'icons';
import { isUserAuthorized } from '../../utils';
import styles from './styles.module.scss';

interface Props {
  note: Note;
  handleSelectNote: (note: Note) => void;
  notify: NotificationInstance;
  object: NoteType;
  objectId: number;
  limitCharacters?: number;
}

const { Title, Text } = Typography;

const generateContent = (content: string, limitCharacters?: number) => {
  if (!limitCharacters) return content;
  return content.length >= limitCharacters
    ? `${content.substring(0, limitCharacters)}...`
    : content;
};

const NoteItem: FC<Props> = ({
  note,
  handleSelectNote,
  notify,
  object,
  objectId,
  limitCharacters,
}) => {
  const { id, userId, title, content, createdAt, user: author, type } = note;
  const { user } = useSanctum();
  const [deleteClientNote, { isLoading: isDeleteLoading }] =
    useDeleteNoteMutation();
  const isAuthorized = isUserAuthorized(user, userId);

  const handleDeleteNote = async () => {
    if (!isAuthorized) return;

    try {
      await deleteClientNote({
        objectId,
        object,
        noteId: id,
      }).unwrap();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas usuwania notatki." />
        ),
      });
    }
  };

  return (
    <Loader visible={isDeleteLoading}>
      <Timeline.Item
        color={
          type === NotesCategory.GENERAL ? theme.colorBlack : theme.colorLime
        }
      >
        <div id={`note-${note.id}`} className={styles.timelineItemTitleRow}>
          <Title level={5}>{title}</Title>
          {isAuthorized && (
            <div className={styles.timelineItemActions}>
              <Popconfirm
                title={<FormattedMessage defaultMessage="Na pewno usunąć?" />}
                okText={<FormattedMessage defaultMessage="Tak" />}
                cancelText={<FormattedMessage defaultMessage="Nie" />}
                onConfirm={handleDeleteNote}
              >
                <Button type="link" icon={<TrashIcon />} danger>
                  <FormattedMessage defaultMessage="Usuń" />
                </Button>
              </Popconfirm>
              <Button
                type="link"
                icon={<EditIcon />}
                onClick={() => handleSelectNote(note)}
              >
                <FormattedMessage defaultMessage="Edytuj" />
              </Button>
            </div>
          )}
        </div>
        <p>{generateContent(content, limitCharacters)}</p>
        <Text>
          <FormatDate date={createdAt} />,{' '}
          {`${author?.lastName} ${author?.firstName}`}
        </Text>
      </Timeline.Item>
    </Loader>
  );
};

export default NoteItem;
