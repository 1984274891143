import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

type RefHeightContext = {
  sectionHeight: number | undefined;
  setSectionHeight: Dispatch<SetStateAction<number | undefined>>;
};

const RefHeightContext = createContext<RefHeightContext>(
  {} as RefHeightContext,
);

type Props = {
  children: ReactNode;
};

export const RefHeightProvider: React.FC<Props> = ({ children }) => {
  const [sectionHeight, setSectionHeight] = useState<number | undefined>();

  const value = useMemo(
    () => ({
      sectionHeight,
      setSectionHeight,
    }),
    [sectionHeight],
  );

  return (
    <RefHeightContext.Provider value={value}>
      {children}
    </RefHeightContext.Provider>
  );
};

export const useRefHeightContext = () => useContext(RefHeightContext);
