import type { UpdateOrderValues } from '../../config';
import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Order } from 'ducks/orders/types';
import { FormattedMessage } from 'react-intl';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CopyToClipboardWithText from 'components/ui/atoms/CopyToClipboard';
import TruncateText from 'components/ui/atoms/TruncateText';
import { NO_DATA } from 'constants/strings';

type Props = {
  data: Order;
};

export const formItems = ({
  data,
}: Props): FormItemRecord<UpdateOrderValues>[] => {
  const { contactPerson, email, phone, isSms } = data;

  return [
    {
      key: 'contactPerson',
      label: <FormattedMessage defaultMessage="Osoba kontaktowa:" />,
      component: (
        <TruncateText data-testid="contactPerson" strong>
          {contactPerson ?? NO_DATA}
        </TruncateText>
      ),
    },
    {
      key: 'email',
      label: <FormattedMessage defaultMessage="E-mail:" />,
      component: email ? (
        <CopyToClipboardWithText
          data-testid="email"
          text={email}
          truncate
          truncateSize="medium"
          truncateMaxLength={16}
          strong
        />
      ) : (
        <span>{NO_DATA}</span>
      ),
    },
    {
      key: 'phone',
      label: <FormattedMessage defaultMessage="Telefon:" />,
      component: phone ? (
        <CopyToClipboardWithText
          data-testid="phone"
          text={phone}
          truncate
          truncateSize="medium"
          strong
        />
      ) : (
        <span>{NO_DATA}</span>
      ),
    },
    {
      key: 'isSms',
      label: <FormattedMessage defaultMessage="SMS:" />,
      component: <CircleCheckbox data-testid="isSms" value={isSms} />,
    },
  ];
};
