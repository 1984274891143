import type { ListItemRecord } from 'components/ui/molecules/List';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import CardCommon from 'components/ui/molecules/CardCommon';
import List from 'components/ui/molecules/List';
import { createArray } from 'utilities/array';
import { useOrderTranslationFilesServices } from '../../useOrderTranslationFilesServices';
import styles from './styles.module.scss';
import TranslationFileItem from './TranslationFileItem';
import TranslationFileListTitle from './TranslationFileListTitle';
import { useTranslationFilesHelpers } from './useTranslationFilesHelpers';

const skeletonItems: ListItemRecord[] = createArray(4).map(() => ({
  label: '',
  skeletonRows: 1,
}));

const TranslationFileList = () => {
  const { isLoading } = useOrderTranslationFilesServices();
  const { modifiedTranslationFiles } = useTranslationFilesHelpers();

  const sortedTranslationFiles = useMemo(
    () =>
      orderBy(
        modifiedTranslationFiles,
        [({ isAssigned }) => isAssigned],
        'asc',
      ),
    [modifiedTranslationFiles],
  );

  const items: ListItemRecord[] = sortedTranslationFiles.map(
    (translationFile) => ({
      label: <TranslationFileItem translationFile={translationFile} />,
    }),
  );

  return (
    <CardCommon title={<TranslationFileListTitle />}>
      <List
        className={styles.list}
        items={isLoading ? skeletonItems : items}
        isLoading={isLoading}
      />
    </CardCommon>
  );
};

export default TranslationFileList;
