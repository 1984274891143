import type { FC } from 'react';
import { useEffect, useState } from 'react';
import LanguagePair from 'components/ui/atoms/LanguagePair';
import { useAppSelector, useAppDispatch } from 'ducks/store';
import { setLangsVariantColumnWidth } from 'ducks/view/preorders';
import styles from '../../styles.module.scss';

type Props = {
  langFrom: string;
  langTo: string;
};

const Langs: FC<Props> = ({ langFrom, langTo }) => {
  const langsVariantColumnWidth = useAppSelector(
    ({ preorders }) => preorders.langsVariantColumnWidth,
  );
  const dispatch = useAppDispatch();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const refWidth = ref?.getBoundingClientRect().width ?? 0;

  useEffect(() => {
    if (!refWidth) return;

    dispatch(setLangsVariantColumnWidth(refWidth));
  }, [dispatch, refWidth]);

  return (
    <div
      data-testid="langs"
      ref={setRef}
      className={styles.langsWrapper}
      style={{
        width: langsVariantColumnWidth,
      }}
    >
      <LanguagePair langFrom={langFrom} langTo={langTo} />
    </div>
  );
};

export default Langs;
