/* eslint-disable no-nested-ternary */
import type { ReactElement, ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Card, Timeline as AntdTimeline, Skeleton, Spin } from 'antd';
import cn from 'classnames';
import styles from './styles.module.scss';

interface Props<TData> {
  data: TData[];
  isLoading: boolean;
  title?: string | ReactElement;
  emptyText: string | ReactElement;
  renderItems: (data: TData) => ReactNode;
  extra?: ReactElement;
  noCard?: boolean;
}

const Timeline = <TData,>({
  data,
  isLoading,
  title,
  emptyText,
  renderItems,
  extra,
  noCard = false,
}: Props<TData>) => {
  const isEmpty = !data.length && !isLoading;

  const timeline = isLoading ? (
    <AntdTimeline className="timelinelist">
      <AntdTimeline.Item
        dot={
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        }
      >
        <Skeleton loading={isLoading} active />
      </AntdTimeline.Item>
    </AntdTimeline>
  ) : isEmpty ? (
    <Empty description={emptyText} />
  ) : (
    <AntdTimeline data-testid="timeline" className="timelinelist">
      {data.map((item) => renderItems(item))}
    </AntdTimeline>
  );

  if (noCard) return timeline;

  return (
    <Card
      bordered={false}
      className={cn('header-solid h-full', styles.card)}
      bodyStyle={{ paddingTop: 25, paddingBottom: 16 }}
      title={title}
      extra={extra}
    >
      {timeline}
    </Card>
  );
};

export default Timeline;
