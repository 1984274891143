import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Client } from 'ducks/clients/types';
import type { InvoiceClient } from 'ducks/invoices/types';
import { FormattedMessage } from 'react-intl';
import CountryName from 'components/ui/atoms/CountryName';
import { NO_DATA } from 'constants/strings';

type Props = {
  client: InvoiceClient;
};

export const formItems = ({ client }: Props): FormItemRecord<Client>[] => {
  const { name, nip, street, postCode, country, city } = client;

  return [
    {
      key: 'name',
      label: <FormattedMessage defaultMessage="Nazwa firmy:" />,
      component: <strong data-testid="name">{name || NO_DATA}</strong>,
    },
    {
      key: 'nip',
      label: <FormattedMessage defaultMessage="NIP:" />,
      component: <strong data-testid="nip">{nip || NO_DATA}</strong>,
    },
    {
      key: 'street',
      label: <FormattedMessage defaultMessage="Ulica / Numer:" />,
      component: <strong data-testid="street">{street || NO_DATA}</strong>,
    },
    {
      key: 'postCode',
      label: <FormattedMessage defaultMessage="Kod pocztowy:" />,
      component: <strong data-testid="postCode">{postCode || NO_DATA}</strong>,
    },
    {
      key: 'country',
      label: <FormattedMessage defaultMessage="Miasto:" />,
      component: <strong data-testid="city">{city || NO_DATA}</strong>,
    },
    {
      key: 'city',
      label: <FormattedMessage defaultMessage="Państwo:" />,
      component: (
        <strong data-testid="country">
          {country ? <CountryName countryCode={country} /> : NO_DATA}
        </strong>
      ),
    },
  ];
};
