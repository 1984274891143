import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Dayjs } from 'dayjs';
import type { AddInvoicePaymentRequest } from 'ducks/invoices/types';
import type { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import { DatePicker, Select } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import { FormattedMessage } from 'react-intl';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import { currencyTypes } from 'constants/currency';
import { paymentTypes } from 'constants/payment';
import { CurrencyType } from 'ducks/payments/types';

export type AddPaymentModalValues = ReplaceType<
  Omit<AddInvoicePaymentRequest, 'id'>,
  Date,
  Dayjs
>;

type Props = {
  intl: IntlShape;
};

export const initialValues: Partial<AddPaymentModalValues> = {
  datePayment: dayjs(),
  currency: CurrencyType.PLN,
};

export const formItems = ({
  intl,
}: Props): FormItemRecord<AddPaymentModalValues>[] => [
  {
    key: 'datePayment',
    name: 'datePayment',
    label: <FormattedMessage defaultMessage="Data płatności" />,
    component: (
      <DatePicker
        data-testid="datePaymentPicker"
        className="width-100"
        locale={locale}
        allowClear={false}
      />
    ),
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz datę płatności" />,
      },
    ],
  },
  {
    key: 'amount',
    name: 'amount',
    label: <FormattedMessage defaultMessage="Kwota" />,
    component: <DecimalInputNumber data-testid="amountInput" fullWidth />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj kwotę" />,
      },
    ],
  },
  {
    key: 'currency',
    name: 'currency',
    label: <FormattedMessage defaultMessage="Waluta" />,
    component: (
      <Select data-testid="currencySelect" options={currencyTypes(intl)} />
    ),
  },
  {
    key: 'type',
    name: 'type',
    label: <FormattedMessage defaultMessage="Metoda płatności" />,
    component: <Select data-testid="typeSelect" options={paymentTypes(intl)} />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz metodę płatności" />,
      },
    ],
  },
];
