import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetPaymentsQuery } from 'ducks/payments/service';
import { PaymentObject } from 'ducks/payments/types';
import { useGetPreorderVariantsQuery } from 'ducks/preorders/service';
import { Routes } from 'views/Preorders/Routing';
import { usePreordersServices } from 'views/Preorders/usePreordersServices';

export const usePreorderDetailsServices = () => {
  const { id: preorderId = '' } = useParams();
  const { pathname } = useLocation();
  const isEditable =
    pathname === Routes.PREORDER_EDIT.replace(':id', preorderId);
  const {
    preorderData,
    isPreorderLoading,
    isPreorderFetching,
    isCurrentlyEdited,
    refetch,
  } = usePreordersServices();
  const {
    data: preorderVariantsData = [],
    isLoading: isPreorderVariantsLoading,
  } = useGetPreorderVariantsQuery(
    {
      id: Number(preorderData?.id),
    },
    {
      skip: !preorderData,
    },
  );
  const { data: paymentsData = [], isLoading: isPaymentsLoading } =
    useGetPaymentsQuery(
      {
        paymentObject: PaymentObject.PREORDERS,
        id: Number(preorderData?.id),
      },
      {
        skip: !preorderData || isEditable,
      },
    );

  const value = useMemo(
    () => ({
      preorderData,
      isPreorderLoading,
      isPreorderFetching,
      refetch,
      isCurrentlyEdited,
      preorderVariantsData,
      isPreorderVariantsLoading:
        isPreorderFetching || isPreorderVariantsLoading,
      paymentsData,
      isPaymentsLoading: isPreorderFetching || isPaymentsLoading,
    }),
    [
      preorderData,
      isPreorderLoading,
      isPreorderFetching,
      refetch,
      isCurrentlyEdited,
      preorderVariantsData,
      isPreorderVariantsLoading,
      paymentsData,
      isPaymentsLoading,
    ],
  );

  return value;
};
