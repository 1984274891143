/* eslint-disable no-param-reassign */
import type { InvoicesSliceState } from './type';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState: InvoicesSliceState = {
  emailTemplate: '',
  selectedInvoicesVat: [],
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoiceEmailTemplate: (
      state,
      { payload }: PayloadAction<InvoicesSliceState['emailTemplate']>,
    ) => {
      state.emailTemplate = payload;
    },
    toggleSelectedInvoicesVat: (
      state,
      {
        payload,
      }: PayloadAction<InvoicesSliceState['selectedInvoicesVat'][number]>,
    ) => {
      const isAlreadySelected = state.selectedInvoicesVat.some(
        ({ id }) => id === payload.id,
      );

      if (isAlreadySelected) {
        state.selectedInvoicesVat = state.selectedInvoicesVat.filter(
          ({ id }) => id !== payload.id,
        );

        return;
      }

      state.selectedInvoicesVat.push(payload);
    },
    clearSelectedInvoicesVat: (state) => {
      state.selectedInvoicesVat = [];
    },
    resetInvoicesState: () => initialState,
  },
});

export const {
  setInvoiceEmailTemplate,
  toggleSelectedInvoicesVat,
  clearSelectedInvoicesVat,
  resetInvoicesState,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;
