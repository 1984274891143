import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { GusReturnProps } from 'components/ui/atoms/GusButton';
import type { AddClientRequest } from 'ducks/clients/types';
import { Switch } from 'antd';
import { FormattedMessage } from 'react-intl';
import CountrySelect from 'components/ui/atoms/CountrySelect';
import GusButton from 'components/ui/atoms/GusButton';
import {
  LanguageCode,
  POLAND_COUNTRY_CODE,
} from 'constants/countryAndLanguageCodes';
import {
  validateNipRule,
  validatePhoneNumberRule,
  validatePostalCodeRule,
} from 'utilities/antdFormRules';

export type AddClientModalValues = AddClientRequest;

export type AddClientModalFormItem = FormItemRecord<AddClientModalValues>;

type AddClientModalInputProps = {
  nipValue: string | undefined;
  handleCheckNip: (data: GusReturnProps) => void;
  country: string | null;
};

export const initialValues: AddClientModalValues = {
  isCompany: false,
  name: '',
  email1: '',
  phone1: '',
  nip: '',
  address: '',
  city: '',
  postCode: '',
  country: LanguageCode.POLAND,
};

export const addClientModalFormItems = ({
  nipValue,
  handleCheckNip,
  country,
}: AddClientModalInputProps): AddClientModalFormItem[] => [
  {
    key: 1,
    label: <FormattedMessage defaultMessage="Typ klienta:" />,
    additionalItemProps: { valuePropName: 'checked' },
    name: 'isCompany',
    component: (
      <Switch
        checkedChildren={<FormattedMessage defaultMessage="Firma" />}
        unCheckedChildren={<FormattedMessage defaultMessage="Osoba fizyczna" />}
      />
    ),
  },
  {
    key: 10,
    label: <FormattedMessage defaultMessage="Kraj:" />,
    name: 'country',
    component: <CountrySelect />,
  },
  {
    key: 2,
    label: <FormattedMessage defaultMessage="NIP" />,
    name: 'nip',
    rules: [
      validateNipRule({ isInternational: country !== POLAND_COUNTRY_CODE }),
    ],
  },
  {
    key: 3,
    component: (
      <GusButton onSuccessCallback={handleCheckNip} value={nipValue} />
    ),
    hidden: country !== POLAND_COUNTRY_CODE,
  },
  {
    key: 4,
    label: <FormattedMessage defaultMessage="Nazwa" />,
    name: 'name',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj nazwę" />,
      },
    ],
  },
  {
    key: 5,
    label: <FormattedMessage defaultMessage="E-mail" />,
    name: 'email1',
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj email" />,
      },
      {
        type: 'email',
        message: (
          <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
        ),
      },
    ],
  },
  {
    key: 6,
    label: <FormattedMessage defaultMessage="Telefon" />,
    name: 'phone1',
    rules: [validatePhoneNumberRule()],
  },
];

export const hiddenInputs: AddClientModalFormItem[] = [
  {
    key: 7,
    name: 'address',
  },
  { key: 8, name: 'city' },
  {
    key: 9,
    name: 'postCode',
    rules: [validatePostalCodeRule()],
  },
];
