import { Form } from 'antd';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import Empty from 'components/ui/atoms/Empty';
import FormItem from 'components/ui/atoms/FormItem';
import CardCommon from 'components/ui/molecules/CardCommon';
import TitleWrapper from 'components/ui/molecules/Table/TitleWrapper';
import { OrderStatus } from 'constants/orderStatuses';
import { useAppDispatch } from 'ducks/store';
import { setInvoicesOrderIds } from 'ducks/view/invoicesAdd';
import { Routes } from 'views/Invoices/Routing';
import { useOrderDetailsServices } from '../../useOrderDetailsServices';
import { formItems } from './config';
import styles from './styles.module.scss';

const InvoicesSection = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { orderData, isOrderLoading } = useOrderDetailsServices();
  const invoices = orderData?.invoices ?? [];
  const isInvoiceAddDisabled =
    orderData?.status !== OrderStatus.DONE || !!invoices.length;

  const handleInvoiceAdd = () => {
    if (!orderData || isInvoiceAddDisabled) return;

    navigate(Routes.INVOICE_ADD);
    dispatch(setInvoicesOrderIds([orderData.id]));
  };

  return (
    <CardCommon
      data-testid="invoicesSection"
      title={
        <TitleWrapper>
          <FormattedMessage defaultMessage="Faktury" />
          <Button onClick={handleInvoiceAdd} disabled={isInvoiceAddDisabled}>
            <FormattedMessage defaultMessage="Wystaw rachunek" />
          </Button>
        </TitleWrapper>
      }
      isLoading={isOrderLoading}
      xs={24}
      lg={12}
    >
      <Form layout="vertical" autoComplete="off">
        {!invoices.length && (
          <Empty
            description={<FormattedMessage defaultMessage="Brak faktur" />}
          />
        )}
        <div className={styles.invoiceList}>
          {invoices.map((orderInvoice) => (
            <div
              key={orderInvoice.id}
              className={cn(styles.invoiceItem, 'gridForm')}
            >
              {orderData &&
                formItems({
                  orderInvoice,
                }).map(({ key, ...props }) => (
                  <FormItem key={key} {...props} />
                ))}
            </div>
          ))}
        </div>
      </Form>
    </CardCommon>
  );
};

export default InvoicesSection;
