/* eslint-disable react/jsx-props-no-spreading */
import type { AddContactPersonModalValues } from './config';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import FormItem from 'components/ui/atoms/FormItem';
import Modal from 'components/ui/molecules/Modal/Modal';
import { useAddClientContactPersonMutation } from 'ducks/clients/service';
import { addContactPersonModalInputs } from './config';

const { useWatch } = Form;
interface Props {
  isOpen: boolean;
  onClose: () => void;
  notify: NotificationInstance;
}

const { useForm } = Form;

const AddContactPersonModal: FC<Props> = ({ isOpen, onClose, notify }) => {
  const { clientId } = useParams();
  const [form] = useForm<AddContactPersonModalValues>();
  const isSms = useWatch('isSms', form);
  const [addClientContactPerson, { isLoading }] =
    useAddClientContactPersonMutation();
  const initialValues: AddContactPersonModalValues = {
    name: '',
    email: '',
    phone: '',
    isSms: true,
  };

  const handleOnSubmit = async () => {
    if (isLoading && !clientId) return;

    const values = await form.validateFields().catch((err) => err);

    if (values?.errorFields?.length) return;

    try {
      await addClientContactPerson({
        ...values,
        clientId: Number(clientId),
      }).unwrap();

      notify.success({
        message: <FormattedMessage defaultMessage="Kontakt został dodany." />,
      });

      onClose();
      form.resetFields();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas dodawania kontaktu." />
        ),
      });
    }
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Dodaj kontakt" />}
      isLoading={isLoading}
      open={isOpen}
      onOk={handleOnSubmit}
      onCancel={onClose}
      okText={<FormattedMessage defaultMessage="Dodaj" />}
    >
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        autoComplete="off"
      >
        {addContactPersonModalInputs({ isSms }).map(({ key, ...props }) => (
          <FormItem key={key} {...props} />
        ))}
      </Form>
    </Modal>
  );
};

export default AddContactPersonModal;
