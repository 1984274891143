import { Space } from 'antd';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Empty from 'components/ui/atoms/Empty';
import FormItem from 'components/ui/atoms/FormItem';
import Switch, { SwitchSkeleton } from 'components/ui/atoms/Switch';
import TruncateText from 'components/ui/atoms/TruncateText';
import CardCommon from 'components/ui/molecules/CardCommon';
import { useAppDispatch, useAppSelector } from 'ducks/store';
import { setPreorderSelectedVariantIds } from 'ducks/view/preorders';
import PreorderAttachmentSwitches from 'views/Preorders/components/PreorderAttachmentSwitches';
import { usePreorderSendServices } from '../../usePreorderSendServices';

const SwitchesSection = () => {
  const dispatch = useAppDispatch();
  const selectedVariantIds = useAppSelector(
    ({ preorders }) => preorders.selectedVariantIds,
  );
  const {
    preorderData,
    preorderVariantsData,
    isPreorderVariantsEmpty,
    isLoading,
  } = usePreorderSendServices();

  const handleChangeSelectedVariantIds = useCallback(
    (id: number) => dispatch(setPreorderSelectedVariantIds(id)),
    [dispatch],
  );

  useEffect(() => {
    if (!preorderData || !preorderVariantsData.length) return;

    if (!preorderData.isCompany) return;

    preorderVariantsData.forEach(({ id }) =>
      handleChangeSelectedVariantIds(id),
    );
  }, [preorderData, preorderVariantsData, handleChangeSelectedVariantIds]);

  return (
    <CardCommon data-testid="switchesSection" xs={24} lg={10} xl={9}>
      <Space className="width-100" direction="vertical" size="middle">
        <PreorderAttachmentSwitches isLoading={isLoading} />
        <div>
          <TruncateText strong maxLength={50}>
            <FormattedMessage defaultMessage="Oferta PDF" />
          </TruncateText>
          {isLoading && <SwitchSkeleton />}
          {isPreorderVariantsEmpty ? (
            <Empty
              description={<FormattedMessage defaultMessage="Brak wariantów" />}
            />
          ) : (
            !isLoading &&
            preorderVariantsData.map(({ id, variantIdx }) => (
              <Switch
                data-testid={`variantSwitch-${id}`}
                key={id}
                checked={selectedVariantIds.some(
                  (selectedVariantId) => selectedVariantId === id,
                )}
                label={
                  <FormattedMessage
                    defaultMessage="Wariant {index}"
                    values={{ index: variantIdx }}
                  />
                }
                onChange={() => handleChangeSelectedVariantIds(id)}
              />
            ))
          )}
        </div>
        <div>
          {isLoading ? (
            <SwitchSkeleton />
          ) : (
            <FormItem
              name="isPresentation"
              component={
                <Switch
                  label={<FormattedMessage defaultMessage="Prezentacja" />}
                />
              }
              additionalItemProps={{
                valuePropName: 'checked',
              }}
            />
          )}
        </div>
      </Space>
    </CardCommon>
  );
};

export default SwitchesSection;
