import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { OrderFileState } from 'ducks/view/orders';
import { FormattedMessage } from 'react-intl';
import { CircleCheckbox } from 'components/ui/atoms/CircleCheckbox';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import LanguagePair from 'components/ui/atoms/LanguagePair';
import TruncateText from 'components/ui/atoms/TruncateText';
import styles from '../../styles.module.scss';
import AcceptedColumn from './components/AcceptedColumn';
import AddLangButton from './components/AddLangButton';
import CatColumn from './components/CatColumn';
import DateRealizationColumn from './components/DateRealizationColumn';
import PosteditColumn from './components/PosteditColumn';
import SpecializationColumn from './components/SpecializationColumn';
import TranslatorColumn from './components/TranslatorColumn';
import TranslatorPriceNettoColumn from './components/TranslatorPriceNettoColumn';

export type OrderTranslationRow = TableRow & OrderFileState;

type Props = {
  isEditable: boolean;
};

export const orderTranslationColumns = ({
  isEditable,
}: Props): ColumnsType<OrderTranslationRow> => [
  {
    key: 'file',
    dataIndex: 'file',
    title: <FormattedMessage defaultMessage="Pliki" />,
    render: (_, { file }) => (
      <TruncateText nowrap maxLength={25}>
        {file.filename}
      </TruncateText>
    ),
  },
  {
    key: 'langs',
    dataIndex: 'langs',
    title: <FormattedMessage defaultMessage="Język" />,
    render: (_, { id: orderFileId, orderFileLangs }) => (
      <div className={styles.multilineCell}>
        {orderFileLangs.map(({ id, langFrom, langTo }) => (
          <div key={id} className={styles.cell}>
            <LanguagePair langFrom={langFrom} langTo={langTo} showOnlyCodes />
          </div>
        ))}
        {isEditable && <AddLangButton orderFileId={orderFileId} />}
      </div>
    ),
  },
  {
    key: 'pages',
    dataIndex: 'pages',
    title: <FormattedMessage defaultMessage="Strony" />,
    render: (_, { orderFileLangs }) => (
      <div className={styles.multilineCell}>
        {orderFileLangs.map(({ id, pagesTranslator }) => (
          <span key={id} className={styles.cell}>
            {pagesTranslator}
          </span>
        ))}
      </div>
    ),
  },
  {
    key: 'isSpecial',
    dataIndex: 'isSpecial',
    title: <FormattedMessage defaultMessage="Specjalistyczny" />,
    render: (_, { isSpecial }) => (
      <div className={styles.centerWrapper}>
        <CircleCheckbox
          data-testid="isSpecialCircleCheckbox"
          value={isSpecial}
        />
      </div>
    ),
  },
  {
    key: 'specializations',
    dataIndex: 'specializations',
    title: <FormattedMessage defaultMessage="Specjalizacje" />,
    render: (_, { id, specializations }) => (
      <SpecializationColumn
        orderFileId={id}
        specializations={specializations}
      />
    ),
  },
  {
    key: 'isCorrection',
    dataIndex: 'isCorrection',
    title: <FormattedMessage defaultMessage="Korekta" />,
    render: (_, { isCorrection }) => (
      <div className={styles.centerWrapper}>
        <CircleCheckbox
          data-testid="isCorrectionCircleCheckbox"
          value={isCorrection}
        />
      </div>
    ),
  },
  {
    key: 'cat',
    dataIndex: 'cat',
    title: <FormattedMessage defaultMessage="CAT" />,
    render: (_, { id: orderFileId, orderFileLangs }) => (
      <div className={styles.multilineCell}>
        {orderFileLangs.map(({ id, catId }) => (
          <CatColumn
            key={id}
            orderFileId={orderFileId}
            orderFileLangId={id}
            catId={catId}
          />
        ))}
      </div>
    ),
  },
  {
    key: 'pe',
    dataIndex: 'pe',
    title: <FormattedMessage defaultMessage="PE" />,
    render: (_, { id: orderFileId, orderFileLangs }) => (
      <div className={styles.multilineCell}>
        {orderFileLangs.map(({ id, isPostedit, catId }) => (
          <PosteditColumn
            key={id}
            orderFileId={orderFileId}
            orderFileLangId={id}
            isPostedit={isPostedit}
            catId={catId}
          />
        ))}
      </div>
    ),
  },
  {
    key: 'translator',
    dataIndex: 'translator',
    title: <FormattedMessage defaultMessage="Tłumacz" />,
    render: (_, { id, orderFileLangs, isCorrection, isSpecial, type }) => (
      <div className={styles.multilineCell}>
        {orderFileLangs.map((orderFileLang) => (
          <TranslatorColumn
            key={orderFileLang.id}
            orderFileId={id}
            isCorrection={isCorrection}
            isSpecial={isSpecial}
            orderFileLang={orderFileLang}
            type={type}
          />
        ))}
      </div>
    ),
  },
  {
    key: 'dateRealization',
    dataIndex: 'dateRealization',
    title: <FormattedMessage defaultMessage="Termin" />,
    width: 190,
    render: (_, { id: orderFileId, orderFileLangs }) => (
      <div className={styles.multilineCell}>
        {orderFileLangs.map(({ id, dateRealization }) => (
          <DateRealizationColumn
            key={id}
            orderFileId={orderFileId}
            orderFileLangId={id}
            dateRealization={dateRealization}
          />
        ))}
      </div>
    ),
  },
  {
    key: 'isAccepted',
    dataIndex: 'isAccepted',
    title: <FormattedMessage defaultMessage="Przyjęto" />,
    render: (_, { id: orderFileId, orderFileLangs }) => (
      <div className={styles.multilineCell}>
        {orderFileLangs.map(({ id, translatorId, isAccepted }) => (
          <AcceptedColumn
            isEditable={isEditable}
            isAccepted={isAccepted}
            isDisabled={!translatorId}
            id={Number(id)}
            orderFileId={orderFileId}
          />
        ))}
      </div>
    ),
  },
  {
    key: 'translatorPriceNetto',
    dataIndex: 'translatorPriceNetto',
    title: isEditable ? (
      <FormattedMessage defaultMessage="Tłumacz (netto)" />
    ) : (
      <FormattedMessage defaultMessage="Tłumacz" />
    ),
    render: (_, { id: orderFileId, orderFileLangs }) => (
      <div className={styles.multilineCell}>
        {orderFileLangs.map(({ id, translatorPriceNetto }) => (
          <TranslatorPriceNettoColumn
            key={id}
            orderFileId={orderFileId}
            orderFileLangId={id}
            translatorPriceNetto={translatorPriceNetto}
          />
        ))}
      </div>
    ),
  },
  {
    key: 'clientPrice',
    dataIndex: 'clientPrice',
    title: isEditable ? (
      <FormattedMessage defaultMessage="Klient (netto)" />
    ) : (
      <FormattedMessage defaultMessage="Klient" />
    ),
    render: (_, { orderFileLangs }) => (
      <div className={styles.multilineCell}>
        {orderFileLangs.map(({ id, price }) => (
          <span key={id} className={styles.cell}>
            <CurrencyFormatter value={price} />
          </span>
        ))}
      </div>
    ),
  },
];
