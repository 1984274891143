import type { FileTranslationType } from 'constants/fileTranslation';
import type { Translator } from 'ducks/translators/types';
import type { FC } from 'react';
import { Typography } from 'antd';
import { memo } from 'react';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import { getTranslatorName } from 'utilities/translator';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
  data: Translator;
  translatorPriceNettoPerPage: number;
  isCorrection: boolean;
  isSpecial: boolean;
  type: FileTranslationType;
};

const TranslatorColumnPlaceholder: FC<Props> = memo(
  ({ data, translatorPriceNettoPerPage }) => {
    const { orders } = data;
    const translatorName = getTranslatorName({
      ...data,
    });
    const inProgress = orders.inProgress ?? 0;

    return (
      <span className={styles.wrapper}>
        {translatorName}
        <span className={styles.rightColumn}>
          <Text type="warning">{inProgress}</Text>
          <span>
            <CurrencyFormatter value={translatorPriceNettoPerPage} />
          </span>
        </span>
      </span>
    );
  },
);

export default TranslatorColumnPlaceholder;
