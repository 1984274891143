import type { HeaderCardCol } from 'components/ui/organisms/HeaderCard';
import { Switch } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

type Props = {
  isImportant: boolean;
  handleOnImportantChange: (value: boolean) => void;
  isCurrentlyEdited: boolean;
};

export const headerCols = ({
  isImportant,
  handleOnImportantChange,
  isCurrentlyEdited,
}: Props): HeaderCardCol[] =>
  !isCurrentlyEdited
    ? [
        {
          key: 'important',
          firstRow: (
            <div className={styles.actionCol}>
              <Switch
                checked={isImportant}
                onChange={handleOnImportantChange}
                checkedChildren={<FormattedMessage defaultMessage="Pilne" />}
              />
            </div>
          ),
        },
      ]
    : [];
