import type { InvoiceOverdueStatus } from 'ducks/invoices/types';
import type { FC } from 'react';
import { Select, notification } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { invoiceOverdueStatuses } from 'constants/invoice';
import { useUpdateInvoiceOverdueMutation } from 'ducks/invoices/service';

type Props = {
  status: InvoiceOverdueStatus;
};

const StatusRow: FC<Props> = ({ status }) => {
  const { id = '' } = useParams();
  const intl = useIntl();
  const [notify, contextHolder] = notification.useNotification();
  const [updateInvoiceOverdue, { isLoading }] =
    useUpdateInvoiceOverdueMutation();
  const options = invoiceOverdueStatuses(intl);

  const handleUpdateStatus = async (newStatus: InvoiceOverdueStatus) => {
    if (isLoading) return;

    try {
      await updateInvoiceOverdue({ id, status: newStatus }).unwrap();
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas edytowania statusu." />
        ),
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Select
        data-testid="statusSelect"
        placeholder={intl.formatMessage({ defaultMessage: 'Status' })}
        options={options}
        value={status}
        onChange={(value) => handleUpdateStatus(value)}
        disabled={isLoading}
        loading={true}
      />
    </>
  );
};

export default StatusRow;
