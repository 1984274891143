import { useMemo } from 'react';
import HeaderCard, {
  generateSkeletonHeaderCols,
} from 'components/ui/organisms/HeaderCard';
import { useInvoiceSendServices } from '../../useInvoiceSendServices';
import { headerCols } from './config';

const InvoiceSendHeader = () => {
  const { invoiceVatData, isInvoiceVatFetching } = useInvoiceSendServices();

  const cols = useMemo(() => {
    if (isInvoiceVatFetching) return generateSkeletonHeaderCols(5);

    if (!invoiceVatData) return [];

    return headerCols(invoiceVatData);
  }, [invoiceVatData, isInvoiceVatFetching]);

  return <HeaderCard cols={cols} isLoading={isInvoiceVatFetching} />;
};

export default InvoiceSendHeader;
