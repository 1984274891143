export enum PreorderConstants {
  PAGES_NORMAL_MULTIPLIER = 1600,
  PAGES_SWORN_MULTIPLIER = 1125,
}

export enum PreorderGraphicServiceConstants {
  SIMPLE_PAGES_PRICE_MULTIPLIER = 15,
  DIFFICULT_PAGES_PRICE_MULTIPLIER = 22.5,
  MIN_PRICE = 100,
}
