import type { ComponentPropsWithoutRef } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useGetLanguagesQuery } from 'ducks/global/service';

type Props = ComponentPropsWithoutRef<typeof Select> & {
  filterByCountryCode?: (arg: { countryShortCode: string }) => void;
};

const LanguageSelect: React.FC<Props> = ({ filterByCountryCode, ...props }) => {
  const { data = [], isLoading } = useGetLanguagesQuery();

  const modifiedData = filterByCountryCode
    ? data.filter(({ code }) => filterByCountryCode({ countryShortCode: code }))
    : data;

  return (
    <Select
      placeholder={<FormattedMessage defaultMessage="Wybierz kraj" />}
      showSearch
      options={modifiedData.map(({ code, name }) => ({
        value: code,
        label: name,
      }))}
      optionFilterProp="label"
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
      loading={isLoading}
      allowClear
      {...props}
    />
  );
};

export default LanguageSelect;
