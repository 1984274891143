import type { ColumnsType } from 'antd/lib/table';
import type { IntlShape } from 'react-intl';
import type { InvoiceListItemRow } from 'views/Invoices/config';
import dayjs from 'dayjs';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import CurrencyFormatter from 'components/ui/atoms/CurrencyFormatter';
import DownloadFvButton from 'components/ui/molecules/DownloadFvButton';
import { DayjsFormat } from 'constants/dayjsFormats';
import { invoiceStatusesObject } from 'constants/invoice';
import { NO_DATA } from 'constants/strings';
import { InvoiceStatus } from 'ducks/invoices/types';
import { Routes } from 'views/Invoices/Routing';
import { Routes as OrderRoutes } from 'views/Orders/Routing';
import CheckboxCell from './components/CheckboxCell';
import ClientNameCell from './components/ClientNameCell';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
  intl: IntlShape;
  handleSetInvoicesOrderIds: (orderId: number) => void;
};

export const columns = ({
  intl,
  handleSetInvoicesOrderIds,
}: Props): ColumnsType<InvoiceListItemRow> => [
  {
    key: 'checkbox',
    dataIndex: 'checkbox',
    title: <span />,
    width: 20,
    render: (_, invoiceVat) => <CheckboxCell invoiceVat={invoiceVat} />,
  },
  {
    key: 'number',
    dataIndex: 'number',
    title: <FormattedMessage defaultMessage="Numer zamówienia" />,
    width: 60,
    render: (_, { id, number }) => (
      <Link to={OrderRoutes.ORDER_DETAILS.replace(':id', id.toString())}>
        <Text>{number}</Text>
      </Link>
    ),
  },
  {
    key: 'clientName',
    dataIndex: 'clientName',
    title: <FormattedMessage defaultMessage="Nazwa klienta" />,
    render: (_, { client }) => <ClientNameCell client={client} />,
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: <FormattedMessage defaultMessage="Status" />,
    render: (_, { status }) =>
      invoiceStatusesObject(intl)[
        status in InvoiceStatus ? status : InvoiceStatus.NOT_PAID
      ].label,
  },
  {
    key: 'dateSent',
    dataIndex: 'dateSent',
    title: <FormattedMessage defaultMessage="Data realizacji zamówienia" />,
    render: (_, { dateSent }) =>
      dateSent ? dayjs(dateSent).format(DayjsFormat.DATE) : NO_DATA,
  },
  {
    key: 'price',
    dataIndex: 'price',
    title: <FormattedMessage defaultMessage="Wartość zamówienia" />,
    render: (_, { priceNetto, priceBrutto }) => (
      <span>
        <CurrencyFormatter value={priceNetto} /> (
        <CurrencyFormatter value={priceBrutto} />)
      </span>
    ),
  },
  {
    key: 'payed',
    dataIndex: 'payed',
    title: <FormattedMessage defaultMessage="Zapłacono" />,
    render: (_, { payed }) => <CurrencyFormatter value={payed} />,
  },
  {
    key: 'downloadFv',
    dataIndex: 'downloadFv',
    title: (
      <FormattedMessage defaultMessage="Przycisk wystawiania faktury VAT" />
    ),
    render: (_, { invoices }) =>
      invoices.length ? (
        <div className={styles.downloadFvButtonsWrapper}>
          {invoices.map(({ id, number, hash, filename }) => (
            <DownloadFvButton
              key={id}
              number={number}
              hash={hash}
              filename={filename}
            />
          ))}
        </div>
      ) : (
        NO_DATA
      ),
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    title: <FormattedMessage defaultMessage="Akcje" />,
    width: 125,
    align: 'center',
    render: (_, { id }) => (
      <Link
        to={Routes.INVOICE_ADD}
        onClick={() => handleSetInvoicesOrderIds(id)}
      >
        <Button>
          <FormattedMessage defaultMessage="Wystaw FV" />
        </Button>
      </Link>
    ),
  },
];
