import type { ColumnsType } from 'antd/lib/table';
import type { TableRow } from 'components/ui/molecules/Table';
import type { LogRecord } from 'ducks/logs/types';
import { Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormatDate from 'components/ui/atoms/FormatDate/FormatDate';

export type LoginHistoryRow = TableRow &
  Pick<LogRecord, 'id' | 'action' | 'ip' | 'date'> & {
    fullName: string;
  };

export const columns = (): ColumnsType<LoginHistoryRow> => [
  {
    title: <FormattedMessage defaultMessage="Imię i nazwisko" />,
    key: 'fullName',
    dataIndex: 'fullName',
  },
  {
    title: <FormattedMessage defaultMessage="Stan" />,
    key: 'action',
    dataIndex: 'action',
    render: (_, { action }) => <Tag>{action}</Tag>,
  },
  {
    title: <FormattedMessage defaultMessage="Adres IP" />,
    key: 'ip',
    dataIndex: 'ip',
  },
  {
    title: <FormattedMessage defaultMessage="Data i godzina" />,
    key: 'date',
    dataIndex: 'date',
    render: (_, { date }) => <FormatDate date={date} />,
  },
];
