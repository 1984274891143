import { createArray } from 'utilities/array';

export const generateDiscountOptions = () => {
  const increment = 5;

  const options = createArray(7).map((number) => {
    const value = increment * (number - 1);

    return {
      label: value,
      value,
    };
  });

  return options;
};
