import { notification } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/ui/atoms/Button';
import ConfirmModal from 'components/ui/molecules/ConfirmModal';
import { useDeletePreorderMutation } from 'ducks/preorders/service';
import { Routes } from 'views/Preorders/Routing';

const DeletePreorderButton = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const [notify, contextHolder] = notification.useNotification();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [deletePreorder, { isLoading }] = useDeletePreorderMutation();

  const handleOpenConfirmModal = () => setIsConfirmModalOpen(true);

  const handleCloseConfirmModal = () => setIsConfirmModalOpen(false);

  const handleDelete = async () => {
    if (isLoading) return;

    try {
      await deletePreorder({ id }).unwrap();

      handleCloseConfirmModal();
      navigate(Routes.PREORDERS);
    } catch (error) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas usuwania wyceny." />
        ),
      });
    }
  };

  return (
    <>
      {contextHolder}
      <ConfirmModal
        open={isConfirmModalOpen}
        title={<FormattedMessage defaultMessage="Usuń wycenę" />}
        description={
          <FormattedMessage defaultMessage="Czy na pewno chcesz usunąć wycenę?" />
        }
        isLoading={isLoading}
        okText={<FormattedMessage defaultMessage="Usuń" />}
        okButtonProps={{
          danger: true,
        }}
        onOk={handleDelete}
        onClose={handleCloseConfirmModal}
      />
      <Button
        type="primary"
        onClick={handleOpenConfirmModal}
        loading={isLoading}
        danger
      >
        <FormattedMessage defaultMessage="Usuń wycenę" />
      </Button>
    </>
  );
};

export default DeletePreorderButton;
