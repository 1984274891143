import { Route } from 'react-router-dom';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import OrderAssign from './views/OrderAssign';
import OrderClientSend from './views/OrderClientSend';
import OrderDetails from './views/OrderDetails';
import OrderSendEmails from './views/OrderSendEmails';
import OrdersList from './views/OrdersList';
import OrderTranslationFiles from './views/OrderTranslationFiles';

export enum Routes {
  ORDERS = '/projects',
  ORDER_DETAILS = '/projects/:id',
  ORDER_ASSIGN = '/projects/:id/assign',
  ORDER_SEND_EMAILS = '/projects/:id/send-emails',
  ORDER_SEND_CLIENT = '/projects/:id/send-client',
  ORDER_TRANSLATION_FILES = '/projects/:id/translation-files',
}

const OrdersRouting = () => {
  return (
    <AnimatedRoutes pathLevelWatch={2}>
      <Route
        path={Routes.ORDERS.replace(Routes.ORDERS, '')}
        element={<OrdersList />}
      />
      <Route
        path={`${Routes.ORDER_DETAILS.replace(Routes.ORDERS, '')}`}
        element={<OrderDetails />}
      />
      <Route
        path={`${Routes.ORDER_ASSIGN.replace(Routes.ORDERS, '')}`}
        element={<OrderAssign />}
      />
      <Route
        path={`${Routes.ORDER_SEND_EMAILS.replace(Routes.ORDERS, '')}`}
        element={<OrderSendEmails />}
      />
      <Route
        path={`${Routes.ORDER_SEND_CLIENT.replace(Routes.ORDERS, '')}`}
        element={<OrderClientSend />}
      />
      <Route
        path={`${Routes.ORDER_TRANSLATION_FILES.replace(Routes.ORDERS, '')}`}
        element={<OrderTranslationFiles />}
      />
    </AnimatedRoutes>
  );
};

export default OrdersRouting;
