import type { FormItemRecord } from 'components/ui/atoms/FormItem';
import type { Dayjs } from 'dayjs';
import type { UpdateInvoiceAccountingNoteRequest } from 'ducks/invoices/types';
import type { IntlShape } from 'react-intl';
import { DatePicker, Select } from 'antd';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import { FormattedMessage } from 'react-intl';
import DecimalInputNumber from 'components/ui/atoms/DecimalInputNumber';
import {
  accountingNoteShipmentTypes,
  accountingNoteStatuses,
} from 'constants/invoice';

export type EditAccountingNotesModalValues = ReplaceType<
  Omit<UpdateInvoiceAccountingNoteRequest, 'id' | 'itemId'>,
  WithoutNullableKeys<Date>,
  Dayjs
>;

type Props = {
  intl: IntlShape;
};

export const initialValues: Partial<EditAccountingNotesModalValues> = {};

export const formItems = ({
  intl,
}: Props): FormItemRecord<EditAccountingNotesModalValues>[] => [
  {
    key: 'number',
    name: 'number',
    label: <FormattedMessage defaultMessage="Numer" />,
    component: <DecimalInputNumber data-testid="numberInput" fullWidth />,
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Podaj number" />,
      },
    ],
  },
  {
    key: 'dateSent',
    name: 'dateSent',
    label: <FormattedMessage defaultMessage="Data wysyłki" />,
    component: (
      <DatePicker
        data-testid="dateSentPicker"
        className="width-100"
        locale={locale}
        allowClear={false}
      />
    ),
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz datę wysyłki" />,
      },
    ],
  },
  {
    key: 'shipmentType',
    name: 'shipmentType',
    label: <FormattedMessage defaultMessage="Typ wysyłki" />,
    component: (
      <Select
        data-testid="shipmentTypeSelect"
        options={accountingNoteShipmentTypes(intl)}
      />
    ),
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz typ wysyłki" />,
      },
    ],
  },
  {
    key: 'status',
    name: 'status',
    label: <FormattedMessage defaultMessage="Status" />,
    component: (
      <Select
        data-testid="statusSelect"
        options={accountingNoteStatuses(intl)}
      />
    ),
    rules: [
      {
        required: true,
        message: <FormattedMessage defaultMessage="Wybierz status" />,
      },
    ],
  },
];
